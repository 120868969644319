import React from "react";
import "./css/AddEquipmentDeplyementForm.css";
import { Form, Input, Button, Select, Table, TimePicker } from "antd";
import { useState, useEffect } from "react";
import {
  createEquipmentDeploymentSummaryList,
  getAllEquipment,
} from "../../../../redux-core/resources/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { useSelector, useDispatch } from "react-redux";
import { selectorgetEquipmentRagisterState } from "../../../../redux-core/resources/selectors";

const actionDispatch = (dispatch) => ({
  createEquipmentDeploymentSummaryList: (data) =>
    dispatch(createEquipmentDeploymentSummaryList(data)),
  getAllEquipment: (orgId, userId) => dispatch(getAllEquipment(orgId, userId)),
});

const AddEquipmentDeploymentForm = (props) => {
  // console.log("props", props)
  const { createEquipmentDeploymentSummaryList, getAllEquipment } =
    actionDispatch(useDispatch());
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const [data, setData] = useState([]);
  const [showTables, setShowTables] = useState(false);
  const [form] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const allEquipmentRagister = useSelector(selectorgetEquipmentRagisterState);

  //  console.log("data",data)

  useEffect(() => {
    getAllEquipment(orgId, userId);
  }, []);

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };
  function convert12HourTo24Hour(timeString) {
    // Split the time string into hours, minutes, and AM/PM parts
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");

    // Convert hours to an integer
    let hour = parseInt(hours);

    // If it's PM and not already 12 PM, add 12 to the hour
    if (period === "PM" && hour !== 12) {
      hour += 12;
    }

    // Format the hour and return the time in 24-hour format
    return `${hour.toString().padStart(2, "0")}:${minutes}`;
  }
  const onEndChange = (time, timeString) => {
    setDate(convert12HourTo24Hour(timeString));
    // console.log(convert12HourTo24Hour(timeString))
  };
  const onStartChange = (time, timeString) => {
    setStartDate(convert12HourTo24Hour(timeString));
    // console.log(convert12HourTo24Hour(timeString))
  };

  const columns = [
    // {
    //   title: 'Equipment Deployment Header Id',
    //   dataIndex: 'equipment_deployment_header_id',
    // },
    {
      title: "Equipment control person Name",
      dataIndex: "control_person_name",
    },
    {
      title: "Equipment Name",
      dataIndex: "equipment_name",
    },
    {
      title: "Equipment Start Time",
      dataIndex: "equipment_start_time",
    },
    {
      title: "Equipment End Time",
      dataIndex: "equipment_end_time",
    },
    {
      title: "Output Qnty",
      dataIndex: "output_qnty",
    },
    {
      title: "Equipment Start Readings",
      dataIndex: "equipment_start_readings",
    },
    {
      title: "Equipment End Readings",
      dataIndex: "equipment_end_readings",
    },
    {
      title: "Fuel Consumed",
      dataIndex: "fuel_consumed",
    },
    {
      title: "Fuel Price",
      dataIndex: "fuel_price",
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    // }
  ];

  const onFinish = async (value) => {
    // console.log(value)
    setShowTables(true);
    // setIsDisable(true)
    let data = {
      ...value,
      ...{
        equipment_deployment_header_id: props.data,
        project_id: props.projectId,
        equipment_start_time: startDate,
        equipment_end_time: date,
        orgID: logindetails?.organisation?.orgID || "",
      },
    };
    // console.log("data", data)
    let response = await createEquipmentDeploymentSummaryList(data);
    if (response) {
      handelAddData([data]);
    }
    clear_all_field();
    //  console.log("response", response)
  };

  const onFinishFailed = (err) => {
    console.log("err", err);
  };

  const handelAddData = (responseData) => {
    if (!Array.isArray(responseData)) {
      return;
    }
    for (let i = 0; i < responseData.length; i++) {
      const startTime = responseData[i]?.equipment_start_time;
      const endTime = responseData[i]?.equipment_end_time;

      if (startTime && endTime) {
        const startTimeParts = startTime.split(":");
        const hours = parseInt(startTimeParts[0]);
        const minutes = parseInt(startTimeParts[1]);
        // console.log("hours",hours)

        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedStartTime = `${formattedHours}:${formattedMinutes} ${period}`;

        const endTimeParts = endTime.split(":");
        const hour = parseInt(endTimeParts[0]);
        const minute = parseInt(endTimeParts[1]);

        const periods = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const formattedMinute = minute.toString().padStart(2, "0");
        const formattedEndTime = `${formattedHour}:${formattedMinute} ${periods}`;

        let newData = responseData.map((item, index) => ({
          key: index + 1,
          project_id: item.project_id,
          equipment_deployment_header_id: item.equipment_deployment_header_id,
          control_person_name: item.control_person_name,
          equipment_name: item.equipment_name,
          equipment_start_time: formattedStartTime,
          equipment_end_time: formattedEndTime,
          output_qnty: item.output_qnty,
          equipment_start_readings: item.equipment_start_readings,
          equipment_end_readings: item.equipment_end_readings,
          fuel_consumed: item.fuel_consumed,
          fuel_price: item.fuel_price,
        }));

        setData((prevData) => [...prevData, ...newData]);
      }
    }
  };

  const clear_all_field = () => {
    form.setFieldsValue({
      control_person_name: "",
      equipment_name: [],
      equipment_start_time: "",
      equipment_end_time: "",
      output_qnty: "",
      equipment_start_readings: "",
      equipment_end_readings: "",
      fuel_consumed: "",
      fuel_price: "",
    });
  };

  return (
    <>
      <Form
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        style={{
          borderTop: "2px solid black",
          marginTop: "20px",
          borderBottom: "2px solid black",
        }}
      >
        <Form.Item
          name="control_person_name"
          label="Control Person Name"
          rules={[
            {
              required: true,
              message: "Please enter Equipment control person Name!",
            },
          ]}
          allowClear
          style={{ width: "40%", marginTop: "10px" }}
          className="input-field"
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          {/* <Select>
        
          </Select> */}
        </Form.Item>
        <Form.Item
          name="equipment_name"
          label="Equipment Name"
          rules={[
            {
              required: true,
              message: "Please select Equipment Name!",
            },
          ]}
          // allowClear
          style={{ width: "40%", marginTop: "10px" }}
          className="input-field"
        >

          <Select
          // disabled={isDisable}
          >
            {allEquipmentRagister.map((item) => (
              <option key={item.id} value={item.equipment_name}>
                {item.equipment_name}
              </option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="equipment_start_time"
          label="Equipment Start Time"
          rules={[
            { required: true, message: "Please enter Equipment Start Time!" },
          ]}
          className="input-field"
        >
          {/* <DatePicker onChange={onChange} /> */}
          <TimePicker format="h:mm A" onChange={onStartChange} />
        </Form.Item>

        <Form.Item
          name="equipment_end_time"
          label="Equipment End Time"
          rules={[
            { required: true, message: "Please enter Equipment End Time!" },
          ]}
          className="input-field"
        >
          {/* <DatePicker onChange={onChange} /> */}
          <TimePicker
            format="h:mm A"
            // required

            onChange={onEndChange}
          />
          
        </Form.Item>
        <Form.Item
          name="output_qnty"
          label="Output Qnty"
          rules={[{ required: true, message: "Please enter Output Qnty!" }]}
          className="input-field"
          allowClear
          style={{ width: "20%" }}
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="equipment_start_readings"
          label="Equipment Start Readings"
          rules={[{ required: true }]}
          className="input-field"
          allowClear
          style={{ width: "25%" }}
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="equipment_end_readings"
          label="Equipment End Readings"
          rules={[{ required: true }]}
          className="input-field"
          allowClear
          style={{ width: "25%" }}
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="fuel_consumed"
          label="Fuel Consumptions (Ltr.)"
          rules={[{ required: false }]}
          className="input-field"
          allowClear
          style={{ width: "20%" }}
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="fuel_price"
          label="Fuel Price (Rs.)"
          rules={[{ required: false }]}
          className="input-field"
          allowClear
          style={{ width: "20%" }}
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item>
          <div className="btn-labour">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              onClick={clear_all_field}
              style={{ borderRadius: "10px" }}
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
      {showTables ? (
        <Table
          dataSource={data}
          columns={columns}
          style={{ marginTop: "20px" }}
          scroll={{ x: "90vw" }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddEquipmentDeploymentForm;
