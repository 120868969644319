import "antd/dist/antd.min.css";
import "./css/UserForm.css";
import { Form, Input, Button, Select, notification } from "antd";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  countAction,
  getAllProj,
} from "../../../../redux-core/settings/actions";
import { redirectAction } from "../../../../redux-core/login/actions";
import {
  countActionUserManagement,
  newUserCreate,
} from "../../../../redux-core/userManagement/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { BiHide, BiShow } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  newUser: (user) => dispatch(newUserCreate(user)),
  count: (orgId) => dispatch(countAction(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});
const { Option } = Select;

const UserForm = ({ setShowAddUser }) => {
  const { getProj, newUser, count } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;

  const options = [
    { value: "SITEENGINEER", label: "Site Engineer" },
    { value: "SITEINCHARGE", label: "Site InCharge" },
    { value: "SUPERVISOR", label: "Supervisor" },
    { value: "DRAWINGARCHITECTURE", label: "Drawing Architecture" },
    { value: "BILLINGENGINEER", label: "Billing Engineer" },
    { value: "QCENGINEER", label: "Quality Control Engineer" },
    { value: "PROCUREMENTMANAGER", label: "Procurement Manager" },
    { value: "PLANNINGENGINEER", label: "Planning Engineer" },
  ];

  useEffect(() => {
    getProj(orgId, userId);
  }, []);
  const allProjData = useSelector(makeSelectAllProj);
  const [form] = Form.useForm();

  // final function
  const onAddUserFinish = async (e) => {
    e.preventDefault();
    let data = {
      ...formData,
      orgID: orgId,
      attempt: "4",
      createdBy: userId,
      del_status: 0,
      status: "Active",
    };

    console.log("Data before submission: ", data);
    if (formData.confirm_password != formData.password) {
      toast.error("Password and confirmpassword do not match..");
      return;
    }

    if (!formData.project_id.length) {
      toast.error("Plese select atleast one project..");
      return;
    }
    // return;
    try {
      let response = await newUser(data);
      if (response.success) {
        console.log("res: ", response);
        setShowAddUser(false);
        setFormData({
          name: "",
          address: "",
          username: "",
          password: "",
          confirm_password: "",
          email: "",
          phone: "",
          role: "",
          project_id: [],
        });
        await clear_fields();
        await count(orgId);
      }
    } catch (err) {
      toast.error("Failed..");
      console.log("Error: ", err);
    }
  };

  const onAddUserFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const children = [];
  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option key={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function clear_fields() {
    form.setFieldsValue({
      name: "",
      address: "",
      username: "",
      password: "",
      confirm_password: "",
      email: "",
      phone: "",
      role: "",
      project_id: [],
    });
  }
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    username: "",
    password: "",
    confirm_password: "",
    email: "",
    phone: "",
    role: "",
    project_id: [],
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    console.log("FormData: ", formData);
  }, [formData, setFormData]);

  return (
    <>
      {/* <Form
        form={form}
        name="add-User"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddUserFinish}
        onFinishFailed={onAddUserFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter  Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input placeholder="Name" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          className="input-field"
          rules={[
            {
              type: "email",
              message: "This input is not valid E-mail !",
            },
            {
              required: true,
              message: "Please enter E-mail !",
            },
          ]}
        >
          <Input placeholder="Email" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>

  

        <Form.Item
          name="password"
          label="Password"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter  password",
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/,
              message:
                "Password must contain at least  uppercase letter,  lowercase letter, and  number, and be at least 6 to 12 characters long",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="Password"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label="Confirm Password"
          className="input-field"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm  password!",
            },

            validateConfirmPassword,
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { required: true, message: "Please enter  Phone number!" },
            {
              pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
              // pattern: /^[\d]{10}$/,
              // message: "Contact number should be 10 digits",
              message: "Please Enter The Correct Contact Number",
            },
          ]}
          className="input-field"
        >
          <Input
            type="number"
            placeholder="Phone number"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please enter  Address !",
            },
            {
              pattern: /^(.{0,300})$/,
              message: "please enter less than 300 characters only",
            },
          ]}
          className="input-field"
        >
          <Input placeholder="Address" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="role"
          label="User Role"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please Select Role!",
            },
          ]}
        >
          <Select
            placeholder="Please select Role"
            value={options.value}
            options={options}
            defaultValue={options[""]}
          ></Select>
        </Form.Item>

        <Form.Item
          name="project_id"
          label="Select Projects"
          rules={[
            { required: true, message: "Please select one or more Projects !" },
          ]}
          className="input-field"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Projects"
            onChange={handleChange}
          >
            {children}
          </Select>
        </Form.Item>
        <Form.Item>
          <div className="btn-user">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_fields} className="btn-cancel">
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form> */}

      {/* new form shivam kashyap - 3 june 2024 */}
      <form
        className="font-montserrat flex flex-col items-center  gap-y-3"
        onSubmit={onAddUserFinish}
      >
        {/* 1a  */}
        <div className="flex justify-evenly w-[90vw]">
          {/* 1 */}
          <div className="">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Name
            </label>
            <div className="mt- w-[300px]">
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  });

                  // HandeleCreateProjectCode(e.target.value);
                }}
                placeholder="Enter User Name"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
          {/* 2  */}
          <div className="">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Email
            </label>
            <div className="mt- w-[300px]">
              <input
                required
                type="email"
                name="name"
                value={formData.email}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });

                  // HandeleCreateProjectCode(e.target.value);
                }}
                placeholder="Enter email address"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
          {/* 3 date */}
          <div className="">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Phone
            </label>
            <div className="mt- w-[300px]">
              <input
                required
                type="number"
                name="phone"
                value={formData.phone}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phone: e.target.value,
                  });

                  // HandeleCreateProjectCode(e.target.value);
                }}
                placeholder="Enter phone number"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
        </div>
        {/* 2a  */}
        <div className="flex justify-evenly w-[90vw]">
          {/* 1 */}
          <div className="">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Password
            </label>
            <div className="mt- w-[300px] relative">
              <input
                required
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  });

                  // HandeleCreateProjectCode(e.target.value);
                }}
                placeholder="Enter password"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />{" "}
              <div
                onClick={() =>
                  showPassword === true
                    ? setShowPassword(false)
                    : setShowPassword(true)
                }
                className=" cursor-pointer select-none absolute top-3 text-xs   right-2 text-pure-greys-300"
              >
                {showPassword === true ? (
                  <div className="">
                    {" "}
                    <BiHide size={20} />
                  </div>
                ) : (
                  <div className="">
                    <BiShow size={20} />
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
          {/* 2  */}
          <div className="">
            <label
              htmlFor="confirm_password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Confirm Password
            </label>
            <div className="mt- w-[300px] relative">
              <input
                required
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={formData.confirm_password}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    confirm_password: e.target.value,
                  });

                  // HandeleCreateProjectCode(e.target.value);
                }}
                placeholder="Enter password"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />{" "}
              <div
                onClick={() =>
                  showConfirmPassword === true
                    ? setShowConfirmPassword(false)
                    : setShowConfirmPassword(true)
                }
                className=" cursor-pointer select-none absolute top-3 text-xs   right-2 text-pure-greys-300"
              >
                {showConfirmPassword === true ? (
                  <div className="">
                    {" "}
                    <BiHide size={20} />
                  </div>
                ) : (
                  <div className="">
                    <BiShow size={20} />
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
          {/* 3  */}
          <div className="">
            <label
              htmlFor="address"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Address
            </label>
            <div className="mt- w-[300px]">
              <input
                required
                type="text"
                name="address"
                value={formData.address}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  });

                  // HandeleCreateProjectCode(e.target.value);
                }}
                placeholder="Enter address"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
        </div>
        {/* 3a  */}
        <div className="flex justify-evenly w-[90vw]">
          {/* 1 */}
          <div className="mt w-[300px]">
            <label
              htmlFor="role"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              User Role
            </label>
            <select
              required
              name="role"
              onChange={(e) => {
                // console.log("setting values");
                setFormData({ ...formData, role: e.target.value });
              }}
              value={formData.role}
              className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              placeholder="select type of area"
            >
              <option value="">Select</option>
              {options &&
                options.map((item, key) => (
                  <option value={item.value}>{item.label}</option>
                ))}
            </select>
          </div>
          {/* 2  */}
          <div className="mt w-[300px]">
            <label
              htmlFor="project_id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Select Projects
            </label>
            <select
              // required
              name="project_id"
              onChange={(e) => {
                // console.log("setting values");
                setFormData({
                  ...formData,
                  project_id: [...formData.project_id, Number(e.target.value)],
                });
              }}
              value={formData.project_id}
              className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              placeholder="select type of area"
            >
              <option value="">Select</option>
              {children &&
                children.map((item, key) => (
                  <option value={item.props.children[2]}>
                    {item.props.children[0] + " - " + item.props.children[2]}
                  </option>
                ))}
            </select>
          </div>
          {/* 3 selected projects list */}
          <div className="mt w-[300px] flex flex-col gap-y-1">
            <label
              htmlFor="project_id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Selected Projects
            </label>
            {formData.project_id.map((item, key) => (
              <div className=" flex gap-x-3">
                <div> {item}</div>{" "}
                <a
                  onClick={() => {
                    setFormData({
                      ...formData,
                      project_id: [
                        ...formData.project_id.filter((val) => val != item),
                      ],
                    });
                    toast.success("Deleted..");
                  }}
                  href="#"
                  class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                  data-te-toggle="tooltip"
                  title="Delete"
                >
                  <MdDelete
                    className=" text-top_nav_blue-300 "
                    size={18}
                    // onClick={() => deleteRow(item)}
                  />
                  {/* <RiBillLine
                         
                        /> */}
                </a>{" "}
              </div>
            ))}
          </div>
        </div>
        {/* buttons  */}
        <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
          <button
            onClick={() => {
              setFormData({
                name: "",
                address: "",
                username: "",
                password: "",
                confirm_password: "",
                email: "",
                phone: "",
                role: "",
                project_id: [],
              });
            }}
            type="button"
            class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            data-te-ripple-init
          >
            Reset
          </button>
          <button
            type="submit"
            data-te-ripple-init
            data-te-ripple-color="light"
            class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default UserForm;
