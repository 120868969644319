import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCheckListByProject } from '../../../redux-core/qcc/actions'; // Adjust the import
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';
import { selectorCheckListByPro } from '../../../redux-core/qcc/selectors'; // Adjust the import
import Slider from 'react-input-slider';

const ActivityRating = ({ rating }) => {
  const renderRating = () => {
    const filledStars = Array.from({ length: rating }, (_, index) => index + 1);

    return (
      <>
        {filledStars.map(star => (
          <span key={star} style={{ color: 'gold' }}>⭐</span>
        ))}
      </>
    );
  };

  return (
    <div style={{ marginLeft: '10px' }}>
      {renderRating()}
    </div>
  );
};

const ActivityProgress = ({ isDone }) => {
  const getStatus = () => {
    if (isDone === 1) {
      return { text: 'Completed', color: 'green', progress: 100 };
    } else {
      return { text: 'Pending', color: 'red', progress: 50 };
    }
  };

  const status = getStatus();

  const sliderStyles = {
    track: {
      background: `linear-gradient(to right, ${status.color} ${status.progress}%, gray ${status.progress}%)`,
      height: '10px'
    },
    active: {
      backgroundColor: status.color,
      height: '10px'
    },
    thumb: {
      width: '0',
      height: '0',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderTop: `5px solid ${status.color}`
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', color: status.color }}>
      <Slider
        axis="x"
        x={status.progress}
        styles={sliderStyles}
        disabled
      />
      <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{status.text}</span>
    </div>
  );
};

const QCC = ({ projectId, onBack }) => {
  const dispatch = useDispatch();
  const activities = useSelector(selectorCheckListByPro); // Adjust the selector
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  console.log('project id:',projectId);
  useEffect(() => {
    //if (orgId && projectId) {
      dispatch(getAllCheckListByProject( orgId,projectId)); // Adjust the action
    //}
  }, [dispatch, orgId, projectId]);
  console.log('activity:',activities);
  return (
    <div style={{ padding: '0px', position: 'relative' }}>
      <h1 style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', fontWeight: 'bold', fontSize: '2em' }}>QCC</h1>
      {/* <button
        onClick={onBack}
        style={{
          position: 'absolute',
          top: '20px',
          left: '0px',
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}
      >
        Back
      </button> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '100px', padding: '0 50px' }}>
        <h1 style={{ marginBottom: '20px', flex: '1', textAlign: 'center',marginLeft:'180px' }}>Activity Ratings</h1>
        <h1 style={{ marginBottom: '20px', flex: '1', textAlign: 'center' }}>Activity Progress</h1>
      </div>
      <div>
        {activities && activities.length > 0 ? (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {activities.map((activity) => (
              <li key={activity.id} style={{ display: 'flex', marginBottom: '20px', alignItems: 'left' }}>
                <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <p >{activity.description}</p>
                </div>
                <div style={{ flex: 1}}>
                  <ActivityRating rating={activity.rating} />
                </div>
                <div style={{ flex: 1 }}>
                  <ActivityProgress isDone={activity.isDone} />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No activities found.</p>
        )}
      </div>
    </div>
  );
};

export default QCC;
