import "./css/Checklist.css";
import "antd/dist/antd.min.css";
import { Form, Button, Select } from "antd";

const { Option } = Select;

function Checklist() {
  // ------------------------------CHECKLIST---------------------------------------
  const [form] = Form.useForm();

  const onChecklistFinish = (values) => {
    console.log("Success:", values);
  };

  const onChecklistFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const clear_field = () =>
  // {
  //     form.setFieldsValue({
  //         project: '',
  //         password: '',
  //     });
  // };

  return (
    <>
      <div className="root">
        {/* -------------------------CHECKLIST------------------------------- */}
        <div className="master-task">
          <div className="sub-title">Checklist</div>

          <Form
            form={form}
            name="master-task"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onChecklistFinish}
            onFinishFailed={onChecklistFinishFailed}
            //autoComplete="off"
          >
            <Form.Item
              name="project"
              label="Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter input field",
                },
              ]}
            >
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="project_details"
              label="Project Details"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter input field",
                },
              ]}
            >
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="task"
              label="Tasks"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter input field",
                },
              ]}
            >
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  Generate
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Checklist;
