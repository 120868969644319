// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checklist-image{
    width: 20px;
 }
 .checklist-container{
    border: '1px solid black';
    padding: 10px
 }
 .image-checklist{
     border: 1px solid black;
     padding: 10px;
     margin-right: 10px;
   
 }
 .img-chk{
    width: 150px;
    height: auto;
 }
 .checklist-detail{
    float: left;
     width: 50%;
 }
 .checklist-table{
    margin-top: 10px
 }
 .checklist-print{
    margin-top: 20px;
    text-align: center;
 
 }

 @media print {
   .add-file {
      box-shadow: 5px 10px 18px #534040;
      border-radius: 10px;
      padding: 20px;
   }
   /* Add any other styles for printing as needed */
 }`, "",{"version":3,"sources":["webpack://./src/components/materials/content/css/ShowIssueReportForm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;CACd;CACA;IACG,yBAAyB;IACzB;CACH;CACA;KACI,uBAAuB;KACvB,aAAa;KACb,kBAAkB;;CAEtB;CACA;IACG,YAAY;IACZ,YAAY;CACf;CACA;IACG,WAAW;KACV,UAAU;CACd;CACA;IACG;CACH;CACA;IACG,gBAAgB;IAChB,kBAAkB;;CAErB;;CAEA;GACE;MACG,iCAAiC;MACjC,mBAAmB;MACnB,aAAa;GAChB;GACA,gDAAgD;CAClD","sourcesContent":[".checklist-image{\n    width: 20px;\n }\n .checklist-container{\n    border: '1px solid black';\n    padding: 10px\n }\n .image-checklist{\n     border: 1px solid black;\n     padding: 10px;\n     margin-right: 10px;\n   \n }\n .img-chk{\n    width: 150px;\n    height: auto;\n }\n .checklist-detail{\n    float: left;\n     width: 50%;\n }\n .checklist-table{\n    margin-top: 10px\n }\n .checklist-print{\n    margin-top: 20px;\n    text-align: center;\n \n }\n\n @media print {\n   .add-file {\n      box-shadow: 5px 10px 18px #534040;\n      border-radius: 10px;\n      padding: 20px;\n   }\n   /* Add any other styles for printing as needed */\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
