// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-upload-list-picture .ant-upload-list-item {
    width:30%
}

.assign__task{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.sub-title{
    font-size: x-large;
    font-weight: bold;
    /* font-style: italic; */
}


@media screen and (max-width:600px){
    .assign__task{
        box-shadow: none;
        padding: 0px;
        /* border-radius: 10px;
        padding: 20px;
        margin-bottom: 30px; */
    }
}`, "",{"version":3,"sources":["webpack://./src/components/qcc/content/css/ActivityPrediction.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;AAC5B;;;AAGA;IACI;QACI,gBAAgB;QAChB,YAAY;QACZ;;8BAEsB;IAC1B;AACJ","sourcesContent":[".ant-upload-list-picture .ant-upload-list-item {\n    width:30%\n}\n\n.assign__task{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n    margin-bottom: 30px;\n}\n\n.sub-title{\n    font-size: x-large;\n    font-weight: bold;\n    /* font-style: italic; */\n}\n\n\n@media screen and (max-width:600px){\n    .assign__task{\n        box-shadow: none;\n        padding: 0px;\n        /* border-radius: 10px;\n        padding: 20px;\n        margin-bottom: 30px; */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
