import React from 'react';
import { FundProjectionScreenOutlined, LikeOutlined, FileAddOutlined, InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import './css/Dashboard.css';
import { useSelector } from 'react-redux';
import { selectorCount } from '../../../redux-core/userManagement/selectors';


function Dashboard() {
    const countData = useSelector(selectorCount);
    return (
        <>
            {/* {countData[1].no_of_drawing_users} */}
            <div className="dashboard-root1">
                <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData[1].no_of_drawing_users}</span>
                        <span className="right__icon"><FundProjectionScreenOutlined /></span>
                    </div>
                    <div className="bottom__title">No of Drawing Users</div>
                </div>
                {/* {countData[0].no_of_users} */}
                <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData[0].no_of_users}</span>
                        <span className="right__icon"><LikeOutlined /></span>
                    </div>
                    <div className="bottom__title">No of Users</div>
                </div>
            </div>
        </>
    );
}

export default React.memo(Dashboard);