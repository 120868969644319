import { Table, Input, Button } from "antd";
import { useState, useEffect } from "react";
import "./css/DeploymentSummaryTable.css";
import { useDispatch, useSelector } from "react-redux";

import { selectorgetAllIdDeploymentHeaderState } from "../../../../redux-core/resources/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

const DeploymentSummaryTable = () => {
  // console.log("Show Data",  )

  const allIdDeploymentHeader = useSelector(
    selectorgetAllIdDeploymentHeaderState
  );
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [dataSource, setDataSource] = useState();

  const columns = [
    {
      title: "Subcontractor Name",
      dataIndex: "sub_contractor_name",
      key: "sub_contractor_name",
      render: (text, record, index) => {
        return <Input defaultValue={text} disabled />;
      },
    },
    {
      title: "Work Category",
      dataIndex: "category_name",
      key: "category_name",
      render: (text, record, index) => {
        return <Input defaultValue={text} disabled />;
      },
    },
    {
      title: "Skilled",
      dataIndex: "skilled",
      key: "skilled",
      render: (text, record, index) => {
        return <Input defaultValue={text} disabled />;
      },
    },
    {
      title: "Unskilled",
      dataIndex: "unskilled",
      key: "unskilled",
      render: (text, record, index) => {
        return <Input defaultValue={text} disabled />;
      },
    },
    {
      title: "Others",
      dataIndex: "others",
      key: "others",
      render: (text, record, index) => {
        return <Input defaultValue={text} disabled />;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => {
        const skilled = parseFloat(record.skilled || 0);
        const unskilled = parseFloat(record.unskilled || 0);
        const others = parseFloat(record.others || 0);
        const total = skilled + unskilled + others;
        return <Input value={total} disabled />;
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={allIdDeploymentHeader}
        pagination={false}
        scroll={{ x: "90vw" }}
      />

      <br />
      {/* <div className='btl-deployment'>
        <Button className='btn-cancel'>Cancel</Button>
    </div> */}
    </div>
  );
};

export default DeploymentSummaryTable;
