import React, { useState } from "react";
import "./css/ActivityPrediction.css";
import { Form, Button, Input, Select, Image, Row, Col, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import { qccApi } from "../../../cognisite-api";
import axios from "axios";

const moduledata = [
  { value: "Hist1Canny", label: "Object Comparison" },
  { value: "SVM", label: "Defect Identification" },
  { value: "Yolo", label: "Object Identification" },
];
const { activityprediction } = qccApi;
function ActivityPrediction(props) {
  const [form] = Form.useForm();
  const [refimage, setRefimage] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const userId = logindetails.user.id;
  const { Option } = Select;
  const children_module = [];
  for (let i = 0; i < moduledata.length; i++) {
    children_module.push(
      <Option value={moduledata[i].value}>{moduledata[i].label}</Option>
    );
  }
  // const clear_fields = () => {
  //     console.log("djb")
  // }
  const onActivityPredictionFinish = (values) => {
    const formData = new FormData();
    formData.append(
      "reference_image",
      values.reference_image ? values.reference_image.file.originFileObj : ""
    );
    formData.append("image", props.image.image);
    formData.append("id", props.image.activity_id);
    formData.append("admin_id", userId);
    formData.append("module_id", values.module_id);
    const response = activityprediction(formData);
  };
  const handlechange = (e) => {
    if (e === "Hist1Canny") {
      setRefimage(true);
    } else {
      setRefimage(false);
    }
    form.setFieldsValue({
      module_id: e,
      image: props.image.image,
    });
  };
  const onActivityDetailsFinishFailed = () => {
    console.log("error");
  };

  const clear_field = () => {
    form.setFieldsValue({
      module_id: [],
      image: "",
    });
    setRefimage(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    // var file = e.file.originFileObj;
    // var r = new FileReader();
    // r.readAsBinaryString(file);
    return e;
  };

  return (
    <div className="assign__task">
      <div className="sub-title">Activity Image Prediction</div>
      <Form
        form={form}
        name="activity_prediction"
        size="medium"
        initialValues={{
          remember: true,
        }}
        onFinish={onActivityPredictionFinish}
        onFinishFailed={onActivityDetailsFinishFailed}
        //autoComplete="off"
      >
        <Form.Item
          name="module_id"
          label="Module"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Module !",
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Please select Module"
            onChange={(e) => handlechange(e)}
          >
            {children_module}
          </Select>
        </Form.Item>

        <Form.Item name="image" label="Activity Image" className="input-field">
          <Image
            style={{ width: "100%", height: "200px" }}
            src={props.image.image}
          ></Image>
        </Form.Item>
        {refimage && (
          <div style={{ position: "relative", top: "-170px" }}>
            <Form.Item
              name="reference_image"
              label="Reference_image"
              getValueFromEvent={normFile}
            >
              <Upload
                name="referenceimage"
                listType="picture"
                accept=".jpg,.jpeg,.png"
                maxCount="1"
              >
                <Button icon={<UploadOutlined />}>Upload file</Button>
              </Upload>
            </Form.Item>
          </div>
        )}

        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_field} className="btn-cancel">
              <strong>Reset</strong>
            </Button>

            <Button onClick={props.back} className="btn-cancel">
              <strong>Back</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ActivityPrediction;
