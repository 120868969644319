// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_img{
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 5px;
   margin-top: 0;
}

.logoImg{
   max-width: 25%;
   max-height: 25%;
   /* border-radius: 50%; */
}

.field{
   font-weight: bold;
   font-family:Georgia, 'Times New Roman', Times, serif;
   font-size: 13px;
   background-color: #adebeb;
   border-radius: 0 8px 8px 0;
}

.field_item{
   font-size: 12px;
   font-weight: normal;
   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/settings/topnavbar/UserModal.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,kBAAkB;GAClB,aAAa;AAChB;;AAEA;GACG,cAAc;GACd,eAAe;GACf,wBAAwB;AAC3B;;AAEA;GACG,iBAAiB;GACjB,oDAAoD;GACpD,eAAe;GACf,yBAAyB;GACzB,0BAA0B;AAC7B;;AAEA;GACG,eAAe;GACf,mBAAmB;GACnB,4EAA4E;GAC5E,gBAAgB;AACnB","sourcesContent":[".logo_img{\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   margin-bottom: 5px;\n   margin-top: 0;\n}\n\n.logoImg{\n   max-width: 25%;\n   max-height: 25%;\n   /* border-radius: 50%; */\n}\n\n.field{\n   font-weight: bold;\n   font-family:Georgia, 'Times New Roman', Times, serif;\n   font-size: 13px;\n   background-color: #adebeb;\n   border-radius: 0 8px 8px 0;\n}\n\n.field_item{\n   font-size: 12px;\n   font-weight: normal;\n   font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;\n   margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
