// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-title{
    font-size: x-large;
    font-weight: bold;
    /* font-style: italic; */
    /* border-bottom: 0.5px solid rgb(190, 228, 22);
    border-width: medium;
    width:fit-content; */
    /* border-radius: 5px; */
}

.labour-deployment,.add-labour{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 10px;
}
.add-labour{
    margin-top: 50px;
}

@media screen and (max-width:600px){
    .labour-deployment,.add-labour{
        box-shadow: none;
    }
}

main.ant-layout-content.site-layout-background{
    /* margin: 24px 16px;
    padding: 24px;
    min-height: 280px; */
    /* padding: 0px; */
    border-radius: 20px
}
`, "",{"version":3,"sources":["webpack://./src/components/resources/content/css/LabourDeployment.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;IACxB;;wBAEoB;IACpB,wBAAwB;AAC5B;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;;wBAEoB;IACpB,kBAAkB;IAClB;AACJ","sourcesContent":[".sub-title{\n    font-size: x-large;\n    font-weight: bold;\n    /* font-style: italic; */\n    /* border-bottom: 0.5px solid rgb(190, 228, 22);\n    border-width: medium;\n    width:fit-content; */\n    /* border-radius: 5px; */\n}\n\n.labour-deployment,.add-labour{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 10px;\n}\n.add-labour{\n    margin-top: 50px;\n}\n\n@media screen and (max-width:600px){\n    .labour-deployment,.add-labour{\n        box-shadow: none;\n    }\n}\n\nmain.ant-layout-content.site-layout-background{\n    /* margin: 24px 16px;\n    padding: 24px;\n    min-height: 280px; */\n    /* padding: 0px; */\n    border-radius: 20px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
