import "./css/UserCreate.css";
import React from "react";
import UserForm from "./Forms/UserForm";
import { useSelector } from "react-redux";
import { Spin } from "antd";
function UserCreate({setShowAddUser}) {
  const Spindata = useSelector((item) => item.spinreducer);
  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className=" font-montserrat">
          {/* <div className="sub__title">Add User re</div> */}
          <div className=" font-semibold  text-2xl">Add User</div>
          <br />
          <UserForm setShowAddUser={setShowAddUser}/>
        </div>
      </Spin>
    </>
  );
}

export default React.memo(UserCreate);
