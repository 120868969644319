import "antd/dist/antd.css";
import "./css/AddEquipmentRegisterForm.css";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Space,
  DatePicker,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllcategory,
  createNewEquipment,
  getAllcontrator,
} from "../../../../redux-core/resources/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  selectorgetAllSubContractorState,
  selectorgetAllWorkState,
} from "../../../../redux-core/resources/selectors";

const actionDispatch = (dispatch) => ({
  newEquipmentRagister: (createRagister) =>
    dispatch(createNewEquipment(createRagister)),
  getAllCategory: (orgId, userId) => dispatch(getAllcategory(orgId, userId)),
  getAllcontrator: (orgId, userId) => dispatch(getAllcontrator(orgId, userId)),
});

const AddEquipmentRegisterForm = () => {
  const { newEquipmentRagister, getAllCategory, getAllcontrator } =
    actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const AllWorkCategory = useSelector(selectorgetAllWorkState);
  const allSubContractor = useSelector(selectorgetAllSubContractorState);
  const [form] = Form.useForm();
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);

  // console.log("vendor", allSubContractor)

  const handlePreview = async (file) => {
    // console.log("file.preview",!file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewimage(file.url || file.preview);

    setPreviewvisible(true);
  };
  const handleCancel = () => setPreviewvisible(false);
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    getAllcontrator(logindetails.organisation.orgID, logindetails.user.id);
  }, []);
  // console.log("action", allSubContractor)
  // Upload File

  const normFile = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onAddEquipmentRagisterFinish = async (values) => {
    let data = {
      ...values,
      ...{
        orgID: logindetails.organisation.orgID,
        created_by: logindetails.user.id,
        calib_due_date: values.calib_due_date,
      },
    };
    //   console.log("first",data)
    let response = await newEquipmentRagister(data);
    if (response) {
      await clear_fields();
    }

    console.log("success: "    ,values)
  };

  // Clear Fields

  const clear_fields = () => {
    form.setFieldsValue({
      equipment_name: "",
      work_category_id: "",
      supplier_id: "",
      calib_due_date: "",
      image: [],
    });
  };

  const [selectCateId, setSelectCateId] = useState(null);

  const handelCategory = (categoryId) => {
    // console.log("categoryId",categoryId)

    setSelectCateId(categoryId);
  };
  // console.log("selectCateId", selectCateId)

  return (
    <>
      <Form
        form={form}
        name="equipment-register"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddEquipmentRagisterFinish}
        onFinishFailed={(error) => {
          console.log({ error });
        }}
      >
        <Form.Item
          name="equipment_name"
          label="Equipment Name"
          rules={[{ required: true, message: "Please enter Equipment Name!" }]}
          className="input-field"
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="work_category_id"
          label="Work Category Name"
          rules={[
            { required: true, message: "Please select Work Category Name!" },
          ]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            onChange={(categoryId) => handelCategory(categoryId)}
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Work Category Name"
          >
            {AllWorkCategory.filter(
              (item) => item.resource_type === "Equipment"
            ).map((getAllCategory) => (
              <option key={getAllCategory.id} value={getAllCategory.id}>
                {getAllCategory.category_name}
              </option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="supplier_id"
          label="Vendor Name"
          rules={[{ required: true, message: "Please enter Vendor Name!" }]}
          className="input-field"
        >
          {/* <Input placeholder="" onKeyDown={(e) => onKeydown(e)} /> */}
          <Select
            // mode="multiple"

            style={{ width: "100%" }}
            placeholder="Please select Vendor Name"
          >
            {allSubContractor
              .filter((item) => item.category_id == selectCateId)
              .map((item) => (
                <option key={item.id} value={item.id}>
                  {item.sub_contractor_name}
                </option>
              ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
                    name='purchase_Equipment_date'
                    label="Purchase Equipment Date"
                    rules={[{ required: true, message: 'Please enter Purchase Equipment Date ' }]}
                    className="input-field">
                    <DatePicker  format="DD-MM-YYYY" id="calib_due_date" style={{ width: '100%' }} type="date" name="calib_due_date" onKeyDown={(e) => onKeydown(e)} />
                </Form.Item> */}
        <Form.Item
          name="calib_due_date"
          label="Calibration Date"
          rules={[{ required: true, message: "Please enter Calibration Date " }]}
          className="input-field"
        >
          {/* <Space direction="vertical" style={{ width: '100%' }}>
                        <DatePicker status="error" style={{ width: '100%' }} onKeyDown={(e) => onKeydown(e)} />
                    </Space> */}
          <DatePicker
          format= "DD-MM--YYYY"
            style={{ width: "100%" }}
            name="calib_due_date"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="image"
          label="Equipment Photo"
          rules={[{ required: true, message: "Please upload Equipment Photo!" }]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="input-field"
        >
          {/* action="/upload.do" */}
          <Upload
            name="logo"
            listType="picture"
            accept=".jpg,.jpeg,.png"
            beforeUpload={(file, fileList) => {
              return false;
            }}
            onPreview={handlePreview}
            maxCount={"1"}
          >
            <Button icon={<UploadOutlined />}>Choosen File</Button>
          </Upload>
        </Form.Item>

        {/* <Form.Item
                    name="image"
                    label="Invoice Photo"
                    rules={[{ required: true, message: 'Please enter Equipment Invoice!' }]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    className="input-field">
                    action="/upload.do"
                    <Upload name="logo" listType="picture" accept=".jpg,.jpeg,.png, .pdf"
                    >
                        <Button icon={<UploadOutlined />}>Choosen File</Button>
                    </Upload>
                </Form.Item> */}

        <Form.Item>
          <div className="btn-equipment">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_fields} className="btn-cancel">
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default AddEquipmentRegisterForm;
