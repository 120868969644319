import "./css/EhsCreate.css";
import EhsCreateForm from "./Forms/EhsCreateForm";
import React from "react";
function EhsCreate() {
  return (
    <>
      <div className=" bg-white">
        <div className="sub-title1">Site Observation Create</div>
        <br />
        <EhsCreateForm />
      </div>
    </>
  );
}

export default React.memo(EhsCreate);
