import React, { useEffect, useState } from "react";
import { Input, Button, Modal, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import {
  getIndentDetailsForm,
  getIndentForm,
  updateIndentStatus,
} from "../../../../redux-core/materials/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorGetIndent,
  selectorGetIndentDetails,
} from "../../../../redux-core/materials/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  formatDate,
  formatNumberWithCommas,
} from "../../../../utils/functions";
import { FaEye } from "react-icons/fa";

const actionDispatch = (dispatch) => ({
  getIndentDetailsForm: (orgID, indent_header_id) =>
    dispatch(getIndentDetailsForm(orgID, indent_header_id)),
  getIndentForm: (orgID, project_id) =>
    dispatch(getIndentForm(orgID, project_id)),
  updateIndentStatus: (approveData) =>
    dispatch(updateIndentStatus(approveData)),
});

const ListShowIndent = (props) => {
  const { getIndentDetailsForm, getIndentForm, updateIndentStatus } =
    actionDispatch(useDispatch());

  const [buttonText, setButtonText] = useState("Check");
  const [isDisabled, setIsDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [storeRecordData, setStoreRecordData] = useState(null);
  const [isApproveClicked, setIsApproveClicked] = useState(false);
  const [buttonRemove, setButtonRemove] = useState(true);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const allSelectorGetIndent = useSelector(selectorGetIndent);
  const allSelectorGetIndentDetails = useSelector(selectorGetIndentDetails);
  // console.log("allSelectorGetIndent", allSelectorGetIndent)
  console.log("allSelectorGetIndentDetails", allSelectorGetIndentDetails);
  // console.log("props.projectID",props.projectID)
  useEffect(() => {
    getIndentForm(orgId, props.projectID);
  }, []);

  const tableData = [];

  if (logindetails.user.role === "SITEENGINEER") {
    for (let i = 0; i < allSelectorGetIndent.length; i++) {
      tableData.push({
        indent_header_id: allSelectorGetIndent[i].id,
        indent_no: allSelectorGetIndent[i].indent_no,
        indent_date: allSelectorGetIndent[i].indent_date.split("T")[0],
        work_order_no: allSelectorGetIndent[i].work_order_no,
        last_purchase_order_no: allSelectorGetIndent[i].last_purchase_order_no,
        last_purchase_order_date:
          allSelectorGetIndent[i].last_purchase_order_date.split("T")[0],
        is_approve: allSelectorGetIndent[i].is_approve,
      });
    }
  } else if (logindetails.user.role === "SITEINCHARGE") {
    for (let i = 0; i < allSelectorGetIndent.length; i++) {
      tableData.push({
        indent_header_id: allSelectorGetIndent[i].id,
        indent_no: allSelectorGetIndent[i].indent_no,
        indent_date: allSelectorGetIndent[i].indent_date.split("T")[0],
        work_order_no: allSelectorGetIndent[i].work_order_no,
        last_purchase_order_no: allSelectorGetIndent[i].last_purchase_order_no,
        last_purchase_order_date:
          allSelectorGetIndent[i].last_purchase_order_date.split("T")[0],
        is_approve: allSelectorGetIndent[i].is_approve,
      });
    }
  } else if (logindetails.user.role === "PROCUREMENTMANAGER") {
    for (let i = 0; i < allSelectorGetIndent.length; i++) {
      if (allSelectorGetIndent[i].is_approve === 1) {
        tableData.push({
          indent_header_id: allSelectorGetIndent[i].id,
          indent_no: allSelectorGetIndent[i].indent_no,
          indent_date: allSelectorGetIndent[i].indent_date.split("T")[0],
          work_order_no: allSelectorGetIndent[i].work_order_no,
          last_purchase_order_no:
            allSelectorGetIndent[i].last_purchase_order_no,
          last_purchase_order_date:
            allSelectorGetIndent[i].last_purchase_order_date.split("T")[0],
          is_approve: allSelectorGetIndent[i].is_approve,
        });
      }
    }
  }
  //
  // console.log("tableData", tableData)
  // Now, tableData will only be populated if the user's role is "SUPERVISOR"

  //

  const columns = [
    {
      title: "Indent No",
      dataIndex: "indent_no",
    },
    {
      title: "Date",
      dataIndex: "indent_date",
    },
    {
      title: "Work Order No",
      dataIndex: "work_order_no",
    },
    {
      title: "Last Purchase Order No",
      dataIndex: "last_purchase_order_no",
    },
    {
      title: "Last Purchase Order Date",
      dataIndex: "last_purchase_order_date",
    },
    {
      title: "Aprove Status",
      dataIndex: "is_approve",
      render: (record) => (record === 1 ? "Approved" : "Not Approved"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => viewModal(record)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
    },
    {
      title: "Sheet",
      dataIndex: "sheet",
    },
    {
      title: "Work Order Sheet Description",
      dataIndex: "description",
    },
    {
      title: "Work Order BOQ Item No.",
      dataIndex: "work_order_boq_item_no",
    },

    {
      title: "Item Description",
      dataIndex: "item_description",
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
    {
      title: "BOQ Quantity",
      dataIndex: "boq_quantity",
    },
    {
      title: "Item Specification",
      dataIndex: "item_specification",
    },
    {
      title: "Quantity Ordered so far",
      dataIndex: "quantity_ordered_so_far",
    },
    {
      title: "Quantity in stock",
      dataIndex: "quantity_in_stock",
    },
    {
      title: "Quantity required now",
      dataIndex: "quantity_required_now",
    },
    {
      title: "Required Date",
      dataIndex: "required_date",
    },
  ];

  const backToAllProject = () => {
    props.backProject();
  };

  const handleButtonText = () => {
    if (buttonText === "Check") {
      setButtonText("Checked");
      setIsDisabled(true);
    }
  };

  const tableData2 = [];
  for (let i = 0; i < allSelectorGetIndentDetails?.length; i++) {
    //BOQ Quantity //
    const boqQuantity = allSelectorGetIndentDetails?.[i]?.boq_quantity;
    const formattedBoqQuantity = boqQuantity === null ? "0" : boqQuantity;
    // UOM //
    const uOM = allSelectorGetIndentDetails?.[i]?.uom;
    const formattedUom = uOM === null ? "0" : uOM;
    // Quantity in Stock //
    const quantitiStock = allSelectorGetIndentDetails?.[i]?.quantity_in_stock;
    const formattedQuanitiStock = quantitiStock === null ? "0" : quantitiStock;

    tableData2.push({
      sl_no: i + 1,
      id: allSelectorGetIndentDetails?.[i]?.id,
      sheet: allSelectorGetIndentDetails?.[i]?.sheet,
      work_order_boq_item_no:
        allSelectorGetIndentDetails?.[i]?.work_order_boq_item_no,
      description: allSelectorGetIndentDetails?.[i]?.description,
      item_description:
        allSelectorGetIndentDetails?.[i]?.Item_name === null
          ? allSelectorGetIndentDetails?.[i]?.item_description
          : allSelectorGetIndentDetails?.[i]?.Item_name,
      uom: formattedUom,
      boq_quantity: formattedBoqQuantity,
      item_specification: allSelectorGetIndentDetails?.[i]?.item_specification,
      quantity_ordered_so_far:
        allSelectorGetIndentDetails?.[i]?.quantity_ordered_so_far,
      quantity_in_stock: formattedQuanitiStock,
      quantity_required_now:
        allSelectorGetIndentDetails?.[i]?.quantity_required_now,
      required_date:
        allSelectorGetIndentDetails?.[i]?.required_date.split("T")[0],
    });
  }
  const viewModal = (value) => {
    getIndentDetailsForm(orgId, value.indent_header_id);
    setStoreRecordData(value);
    setOpenModal(true);
  };
  console.log("storeRecordData", storeRecordData);

  const handleCancel = () => {
    setOpenModal(false);
  };

  const HandleApprove = async () => {
    const data = {
      id: storeRecordData?.indent_header_id,
      is_approve: 1,
    };
    await updateIndentStatus(data);
    setIsApproveClicked(true);
    setButtonRemove(false);
    console.log("data", data);
    getIndentForm(orgId, props.projectID);
  };

  // useEffect(() => {
  //   console.log("tableData: ", tableData);
  // }, []);

  return (
    <>
      {/* <Button onClick={() => backToAllProject()}>All Projects</Button>
      <br /> <br />
      <div>
        <div className="title-show-project"> List of Indent</div>

        <br />
      </div> */}
      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="   font-montserrat">
            <button
              onClick={() => backToAllProject()}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              All Projects
            </button>
          </div>
          <div className=" font-semibold  text-2xl">List of Indent</div>
          <div></div>
        </div>
      </div>

      {/* new table shivam kashyap 26 june 2024  */}

      <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
        <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
          <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
            <div className="flex flex-col items-start w-[30%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Indent Number
              </p>

              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Date
              </p>
            </div>
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Work order Number
              </p>
            </div>
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Last Purchase order Number
              </p>
              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Last Purchase order Date
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Approve Status
              </p>
            </div>
            <div className="flex flex-col items-start w-[10%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Action
              </p>{" "}
            </div>
          </li>
        </ul>{" "}
        <ul
          role="list"
          className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
        >
          {tableData && tableData.length && tableData[0] != null
            ? tableData.map((item, key) => (
                <li
                  key={key}
                  className={`flex justify-between items-center px-5 py-2 ${
                    key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                  }`}
                >
                  <div className="flex flex-col items-start w-[30%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.indent_no ? item.indent_no : "-"}
                    </p>

                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      {item?.indent_date ? formatDate(item.indent_date) : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.work_order_no ? item.work_order_no : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.last_purchase_order_no
                        ? item.last_purchase_order_no
                        : "-"}
                    </p>
                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      {item?.last_purchase_order_date
                        ? formatDate(item.last_purchase_order_date)
                        : "-"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.is_approve
                        ? item.is_approve == 0
                          ? "Not Approved"
                          : "Approved"
                        : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[10%]">
                    <a
                      onClick={() => viewModal(item)}
                      href="#"
                      class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                      data-te-toggle="tooltip"
                      title="View"
                    >
                      <FaEye
                        className=" text-top_nav_blue-300 "
                        size={18}
                        // onClick={() => deleteRow(item)}
                      />
                    </a>{" "}
                  </div>
                </li>
              ))
            : "No records found..."}
        </ul>
      </div>

      {/* <Table
        scroll={{ x: "50vw" }}
        columns={columns}
        dataSource={tableData}
        pagination={10}
      /> */}
      {/* view button */}
      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={null}
        width={1450}
      >
        {logindetails.user.role === "SITEINCHARGE" ? (
          <div className="approve_btn">
            {buttonRemove && storeRecordData?.is_approve !== 1 ? (
              <Button
                className="button-29"
                onClick={HandleApprove}
                disabled={
                  storeRecordData?.is_approve === 1 || isApproveClicked === true
                    ? true
                    : false
                }
              >
                {storeRecordData?.is_approve === 1 || isApproveClicked === true
                  ? `Approved`
                  : `Approve`}
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {/* <Table
          className="table"
          columns={columns2}
          dataSource={tableData2}
          pagination={10}
          scroll={{ x: "90vw" }}
        /> */}

        <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
              <div className="flex flex-col items-start w-[10%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Sl No.
                </p>
              </div>
              <div className="flex flex-col items-start w-[30%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Sheet
                </p>

                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  Work Order Sheet Description
                </p>
              </div>
              <div className="flex flex-col items-start w-[20%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Description
                </p>

                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  Work Order BOQ Item Number
                </p>
              </div>
              <div className="flex flex-col items-start w-[20%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  BOQ Quantity
                </p>
                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  UOM
                </p>
              </div>{" "}
              <div className="flex flex-col items-start w-[20%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Specification
                </p>
              </div>
              <div className="flex flex-col items-start w-[25%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Quantity Ordered so Far
                </p>

                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  Quantity in Stock
                </p>
              </div>
              <div className="flex flex-col items-start w-[25%]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Quantity Required Now
                </p>

                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  Required Date
                </p>
              </div>
            </li>
          </ul>{" "}
          <ul
            role="list"
            className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
          >
            {tableData2 && tableData2.length && tableData2[0] != null
              ? tableData2.map((item, key) => (
                  <li
                    key={key}
                    className={`flex justify-between items-center px-5 py-2 ${
                      key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }`}
                  >
                    <div className="flex flex-col items-start w-[10%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.sl_no ? item.sl_no : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[30%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.sheet ? item.sheet : "-"}
                      </p>

                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.description ? item.description : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[20%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.item_description ? item.item_description : "-"}
                      </p>

                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.id ? item.id : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[20%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.boq_quantity
                          ? formatNumberWithCommas(item.boq_quantity)
                          : "-"}
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.uom ? item.uom : "-"}
                      </p>
                    </div>{" "}
                    <div className="flex flex-col items-start w-[20%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.item_specification
                          ? item.item_specification
                          : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[25%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.quantity_ordered_so_far
                          ? formatNumberWithCommas(item.quantity_ordered_so_far)
                          : "-"}
                      </p>

                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.quantity_in_stock
                          ? formatNumberWithCommas(item.quantity_in_stock)
                          : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[25%]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.quantity_required_now
                          ? formatNumberWithCommas(item.quantity_required_now)
                          : "-"}
                      </p>

                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.required_date
                          ? formatDate(item.required_date)
                          : "-"}
                      </p>
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ListShowIndent;
