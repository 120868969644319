import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Layout, Menu, Spin } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DesktopOutlined,
  LineChartOutlined,
  ContainerOutlined,
  ClockCircleOutlined,
  FileAddOutlined,
  FormOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import "./SideNavContract.css";
import "antd/dist/antd.min.css";
import TopNavbar from "../../settings/topnavbar/TopNavbar";
import MediaQuery from "react-responsive";
import Dashboard from "../content/Dashboard";
// import Checklist from '../content/Checklist';
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import { GrProjects } from "react-icons/gr";
import { useLocation, useParams } from "react-router";

const MyProjects = React.lazy(() => import("../content/MyProjects"));

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

function SideNavContracts() {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const location = useLocation();
  useEffect(() => {
    console.log(" contens", content);
    console.log(" dataspin", dataspin);
    console.log(" my projects", MyProjects);
    console.log("login details", logindetails);
    console.log("prams are------- ", location.pathname);
  }, []);

  const [content, setContent] = useState("1");

  const onMenuClick = useCallback((value) => {
    setContent(value);
  }, []);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        {/* <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minWidth: "100px" }}
          class="custom-sider"
        
        >
          <div className="logo">
            {collapsed ? (
              <MenuUnfoldOutlined
                style={{ paddingTop: 18 }}
                className="trigger"
                onClick={toggle}
              />
            ) : logindetails.organisation.image === null ? (
              <h4>{logindetails.organisation.orgName}</h4>
            ) : (
              <img
                width="70px"
                src={logindetails.organisation.image}
                // src= "cognisie_logo_new.png"
                alt={logindetails.organisation.orgName}
              />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => onMenuClick(e.key)}
            class="menuitem"
          >
            <Menu.Item>
              {" "}
              <span className=" flex min-h-[40px] gap-x-3 font-medium justify-between items-center ">
                <GrProjects size={20} className="text-black" /> My Projects
              </span>
            </Menu.Item>
           
          </Menu>
        </Sider> */}

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn ">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger"
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt="logo"
                />
              )}
            </div>
          </MediaQuery>
          {/* <TopNavbar name="Contracts" collapsed={!collapsed} toggle={toggle} /> */}

          <Content
            className="site-layout-background"
            style={{
              margin: "16px 16px",
              padding: 24,
              minHeight: 280,
              overflow: "scroll",
            }}
          >
            {/* {content === "1" ? <Dashboard /> : <></>} */}
            {content === "1" ? (
              <Suspense
              // className=" bg-pink-300 "
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <MyProjects className=" font-montserrat" />
              </Suspense>
            ) : (
              <></>
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default SideNavContracts;
