import "./css/EhsShow.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  message,
  Spin,
  DatePicker,
  Image,
  Space,
  Upload,
  Badge,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";
import {
  getEhs,
  deleteByEhsId,
  updateByEhsId,
  riskCountAction,
  getUpdateResponseIssueData,
} from "../../../redux-core/risk/actions";
import { selectorGetEhsState } from "../../../redux-core/risk/selectors";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../redux-core/settings/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../redux-core/settings/selectors";
import moment from "moment";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";
import { IoIosAdd, IoMdClose } from "react-icons/io";
import { MdDelete, MdEdit, MdOutlineRateReview } from "react-icons/md";
import { FaEye, FaReply } from "react-icons/fa";
import EhsCreate from "./EhsCreate";
import EhsCreateForm from "./Forms/EhsCreateForm";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getEhs: (orgId, userId) => dispatch(getEhs(orgId, userId)),
  deleteByEhsId: (deleteEhsId) => dispatch(deleteByEhsId(deleteEhsId)),
  updateByEhsId: (updateEhsId) => dispatch(updateByEhsId(updateEhsId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projectId, orgId, userId) =>
    dispatch(getAllProjDetails(projectId, orgId, userId)),
  riskCountAction: (orgId) => dispatch(riskCountAction(orgId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getUpdateResponseIssueData: (responseData) =>
    dispatch(getUpdateResponseIssueData(responseData)),
});

const { Option } = Select;

const EhsShow = () => {
  const getColumnSearchProps = useSearch();
  const {
    getAllProj,
    getAllProjDetails,
    getEhs,
    deleteByEhsId,
    updateByEhsId,
    riskCountAction,
    getAllAssignProjectId,
    getUpdateResponseIssueData,
  } = actionDispatch(useDispatch());
  const getEhsData = useSelector(selectorGetEhsState);
  const logindetails = useSelector(makeSelectLoginDetail);
  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [filteredData, setFilteredData] = useState([]);
  const [keys, setKeys] = useState();
  const [ehsRow, setEhsRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const children = [];
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const Spindata = useSelector((item) => item.spinreducer);
  const [activedata, setActivedata] = useState(true);
  const [responseModal, setResponeModal] = useState(false);
  const [responseDate, setResponseDate] = useState();
  const [issueId, setIssueId] = useState();
  const [responseEditDate, setResponseEditDate] = useState();
  const [targetEditDate, setTargetEditDate] = useState();
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [newSiteObservation, setNewSiteObservation] = useState(false);
  const handlePreview = async (file) => {
    // console.log("file.preview",!file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewimage(file.url || file.preview);

    setPreviewvisible(true);
  };

  useEffect(() => {
    getEhs(orgId, userId);
    getAllProj(orgId, userId);
    getAllAssignProjectId(orgId, userId);
  }, []);

  const [sliderImage, setSliderImage] = useState("");
  const [showImages, setShowImagesModal] = useState(false);
  const [imageFile, setImageFile] = useState([]);

  const [ehsEditValuse, setEhsEditValues] = useState({
    project_id: "",
    project_detail_id: "",
    problem_area: "",
    correction_action: "",
    response: "",
    response_date: "",
    target_date: "",
    remark: "",
    status: "",
  });

  const [submitResponseData, setSubmitResponseData] = useState({
    resolve_date: "",
    comment: "",
  });

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });

  // console.log("getEhsData", getEhsData);
  // console.log("userId", userId);
  let tableData = [];
  for (let i = 0; i < getEhsData.length; i++) {
    let startDate = new Date(getEhsData[i].resolve_date);
    // const responseDate = new Date(getEhsData[i].response_date);
    // const timeZoneOffset = responseDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // responseDate.setMinutes(responseDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedResponseDate = responseDate.toISOString().slice(0, 10);

    // const targetDate = new Date(getEhsData[i].target_date);
    // const timeZoneOffsets = targetDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // targetDate.setMinutes(targetDate.getMinutes() - timeZoneOffsets); // Adjust the date by the offset
    // const formattedTargetDate = targetDate.toISOString().slice(0, 10);

    if (getEhsData[i].response == userId) {
      tableData.push({
        sl: i + 1,
        key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    } else if (getEhsData[i].created_by == userId) {
      tableData.push({
        sl: i + 1,
        key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    }
  }

  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };
  //---------------- modal and forms-----------------------------

  const onEditEhsFinish = async (e) => {
    e.preventDefault();
    console.log("Ehs row: ", ehsRow);
    // console.log("form values : ", values);
    console.log("form new values : ", ehsEditValuse);
    // return;
    //conversion of array of string to array of numbers

    let data = {
      id: ehsRow.key,
      project_id: ehsRow.project_id,
      project_detail_id: ehsRow.project_detail_id,
      problem_area: ehsEditValuse.problem_area,
      correction_action: ehsEditValuse.correction_action,
      // response: values.response,
      response_date: ehsEditValuse.response_date,
      target_date: ehsEditValuse.target_date,
      remark: ehsEditValuse.remark,
      status: ehsEditValuse.status,
    };
    // console.log("Data--", data);
    try {
      let response = await updateByEhsId(data);
      if (response.success == true) {
        // console.log("res: ", response);
        toast.success("Success..");
        // await clear_fields();
        await getEhs(orgId, userId);
        await setIsModalVisible(false);
      }
    } catch (err) {
      console.log("some error occurred while editing esh: ", err);
    }

    // clear_fields();
  };

  const onEditEhsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log("responseEditDate", responseEditDate);
  // console.log("targetEditDate", targetEditDate);
  const showModal = (data1) => {
    setEhsRow(data1);

    // const [ehsEditValuse, setEhsEditValues] = useState({
    //   project_id: "",
    //   project_detail_id: "",
    //   problem_area: "",
    //   correction_action: "",
    //   response: "",
    //   response_date: "",
    //   target_date: "",
    //   remark: "",
    //   status: "",
    // });

    setEhsEditValues({
      problem_area: data1.problem_area,
      correction_action: data1.correction_action,
      remark: data1.remark,
      status: data1.status,
      response_date: data1.response_date,
      resolve_date: data1.resolve_date,
    });

    console.log("Ehs row: ", data1);

    setResponseEditDate(moment(data1.response_date));

    setTargetEditDate(moment(data1.target_date));

    setIsModalVisible(true);
    form.setFieldsValue({
      project_id: data1.project_id,
      project_detail_id: data1.project_detail_id,
      problem_area: data1.problem_area,
      correction_action: data1.correction_action,
      response: data1.response,
      response_date: moment(data1.response_date, "DD-MM-YYYY"),
      target_date: moment(data1.target_date, "DD-MM-YYYY"),
      remark: data1.remark,
    });
  };

  const handleCancels = () => setPreviewvisible(false);

  const handleCancel = () => {
    setIsModalVisible(false);

    setResponeModal(false);
  };
  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const clear_fields = () => {
    form.setFieldsValue({
      project_id: ehsRow.project_id,
      project_detail_id: ehsRow.project_detail_id,
      problem_area: ehsRow.problem_area,
      correction_action: ehsRow.correction_action,
      response: ehsRow.response,
      response_date: moment(ehsRow.response_date, "DD-MM-YYYY"),
      target_date: moment(ehsRow.target_date, "DD-MM-YYYY"),
      remark: ehsRow.remark,
    });
  };

  //  for Excel

  let tableExcel = [];
  for (let i = 0; i < getEhsData.length; i++) {
    let startDate = new Date(getEhsData[i].resolve_date);
    // const responseDate = new Date(getEhsData[i].response_date);
    // const timeZoneOffset = responseDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // responseDate.setMinutes(responseDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedResponseDate = responseDate.toISOString().slice(0, 10);

    // const targetDate = new Date(getEhsData[i].target_date);
    // const timeZoneOffsets = targetDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // targetDate.setMinutes(targetDate.getMinutes() - timeZoneOffsets); // Adjust the date by the offset
    // const formattedTargetDate = targetDate.toISOString().slice(0, 10);

    if (getEhsData[i].response == userId) {
      tableExcel.push({
        sl: i + 1,
        // key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    } else if (getEhsData[i].created_by == userId) {
      tableExcel.push({
        sl: i + 1,
        // key: getEhsData[i].id,
        project_id: getEhsData[i].project_id,
        project_detail_id: getEhsData[i].project_detail_id,
        problem_area: getEhsData[i].problem_area,
        correction_action: getEhsData[i].correction_action,
        response: getEhsData[i].response,
        // response_date: getEhsData[i].response_date.slice(0, 10),
        // target_date: getEhsData[i].target_date.slice(0, 10),
        response_date: moment(getEhsData[i].response_date).format("DD-MM-YYYY"),
        target_date: moment(getEhsData[i].target_date).format("DD-MM-YYYY"),
        remark: getEhsData[i].remark,
        project_name: getEhsData[i].project_name,
        name: getEhsData[i].name,
        create_person: getEhsData[i].create_person,
        image: getEhsData[i].image,
        resolve_date:
          getEhsData[i].resolve_date === null
            ? ""
            : startDate.toLocaleDateString("en-GB"),
        resolve_image: getEhsData[i].resolve_image,
        comment: getEhsData[i].comment,
        status: getEhsData[i].status,
      });
    }
  }

  const columns = [
    {
      title: "Sl No.",
      dataIndex: "sl",
      // ...getColumnSearchProps("sl"),
    },
    {
      title: "Project ",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
    },
    {
      title: "Project Location ",
      dataIndex: "project_detail_id",
      //  ...getColumnSearchProps("project_detail_id"),
    },
    {
      title: "Problem ",
      dataIndex: "problem_area",
      ...getColumnSearchProps("problem_area"),
    },
    {
      title: "Problem Raised Person  ",
      dataIndex: "create_person",
      ...getColumnSearchProps("create_person"),
    },
    {
      title: "Correction ",
      dataIndex: "correction_action",
      //  ...getColumnSearchProps("correction_action"),
    },
    {
      title: "Assign Responsible Person",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      // width: '20%',
    },
    {
      title: "Response Date",
      dataIndex: "response_date",
      //...getColumnSearchProps("response_date"),
    },
    {
      title: "Target Date",
      dataIndex: "target_date",
      //  ...getColumnSearchProps("target_date"),
    },
    {
      title: "Description",
      dataIndex: "remark",
      // ...getColumnSearchProps("remark"),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Response Date",
      dataIndex: "resolve_date",
      // ...getColumnSearchProps("remark"),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      // ...getColumnSearchProps("remark"),
    },
    {
      title: "Resolve Issue Image",
      dataIndex: "resolve_image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "7%",
      render: (text) => {
        let statusColor;
        switch (text) {
          case "OPEN":
            statusColor = "blue";
            break;
          case "PENDING":
            statusColor = "red";
            break;
          case "CLOSE":
            statusColor = "green";
            break;
          default:
            statusColor = "default";
        }

        return <Badge status={statusColor} text={text} />;
      },
    },

    {
      title: "Responses",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => HandeRespone(record)}>
              <EditOutlined />
              Response
            </Button>
          </div>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };
  const onConfirm = async (key) => {
    await deleteByEhsId(key);
    await getEhs(orgId, userId);
    await riskCountAction(orgId);
    // count(orgID);
  };

  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjDetails(projectId, orgId, userId);
  };

  const HandeRespone = (data) => {
    // console.log("data", data.key);
    setIssueId(data.key);
    setResponeModal(true);
    form1.setFieldsValue({
      problem_area: data.problem_area,
      target_date: moment(data.target_date, "DD-MM-YYYY"),
    });
  };

  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const clear_value = () => {
    form1.setFieldsValue({
      resolve_date: "",
      resolve_image: [],
      comment: "",
    });
  };

  const handelResponceDate = (value, dateString) => {
    console.log("dateString", dateString);
    setResponseDate(dateString);
  };

  const handelSubmitResponce = async (e) => {
    e.preventDefault();
    console.log("Ehs row: ", ehsRow);
    console.log("value", submitResponseData);

    let imageBase64String = "";
    if (imageFile) {
      try {
        imageBase64String = await getBase64(imageFile);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error(
          "An error occurred while processing the image. Please try again."
        );
        return;
      }
    }

    const imageObject = {
      lastModified: imageFile.lastModified,
      lastModifiedDate: imageFile.lastModifiedDate,
      name: imageFile.name,
      originFileObj: imageFile,
      percent: 0,
      size: imageFile.size,
      thumbUrl: imageBase64String,
      type: imageFile.type,
      uid: "rc-upload-" + Date.now(),
    };
    imageObject.thumbUrl = imageObject.thumbUrl.replace(
      "data:image/jpeg;",
      "data:image/png;"
    );
    // return;
    const data = {
      id: issueId,
      resolve_date: submitResponseData.resolve_date,
      resolve_image: [imageObject],
      comment: submitResponseData.comment,
    };

    try {
      let res = await getUpdateResponseIssueData(data);
      console.log("Res", res);
      clear_value();
      setResponeModal(false);
      setSubmitResponseData({
        resolve_date: "",
        comment: "",
      });
      setImageFile({});
      toast.success("Success..");
    } catch (err) {
      console.log("some error occurred: ", err);
    }
    handleCancel();
    getEhs(orgId, userId);
  };

  const options = [
    { value: "OPEN", label: "Open" },
    { value: "PENDING", label: "Pending" },
    { value: "CLOSE", label: "Close" },
  ];

  // select date YYYY-MM-DD format convert function
  const handelSelectResponseDate = (value, dateString) => {
    setResponseEditDate(dateString);
  };

  const handelSelectTargetDate = (value, dateString) => {
    setTargetEditDate(dateString);
  };
  const validateTargetDate = async (_, targetDate) => {
    const responseDate = form.getFieldValue("response_date");

    if (moment(targetDate).isSameOrBefore(responseDate)) {
      throw new Error("Target Date must be greater than Response Date");
    }
  };

  useEffect(() => {
    console.log("tableData: ", tableData);
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    // console.log("i am date: ", dateString);
    const date = moment.utc(dateString, "DD-MM-YYYY");
    return date.format("DD MMM YYYY");
  };

  const handleClick = async (item) => {
    try {
      await HandeRespone(item);
      await setEhsRow(item);
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* <Spin spinning={Spindata.spin} size={"large"}> */}
      {/* <div className="title-show-project">Show Site Observation</div> */}

      {/* <div className="btn__">
          <Button className="pdf">Export to PDF</Button>
          <Button className="excel">
            <CSVLink
              filename={"Site Obseravation.csv"}
              data={tableExcel}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          <Button className="print">Print</Button>
                <Button className="copy">Copy</Button>
        </div> */}
      {/* <Table
        className="table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        onChange={(pagination, filter, sorter, currentTable) =>
          onTableChange(filter, sorter, currentTable)
        }
        pagination={10}
        scroll={{ x: "110vw" }}
      /> */}

      {/* new table shivam kashyap 30 april 2024 */}
      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="  invisible font-montserrat">
            {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

            <button
              // onClick={onUploadWorkBtn}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <span>
                <IoIosAdd size={20} />
              </span>
              New RFI
            </button>
          </div>
          <div className=" font-semibold  text-2xl">Show Site Observation</div>
          <div className=" font-montserrat">
            {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

            <button
              onClick={() => setNewSiteObservation(true)}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <span>
                <IoIosAdd size={20} />
              </span>
              New Site Observation
            </button>
          </div>
        </div>

        {/* table  */}
        <div className=" font-montserrat mt-5 items-start ml-4    flex flex-col  justify-start">
          <ul role="list" className="divide-y w-[1215px] divide-[#F5F5F5]">
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[120px]">
                <p className="text-sm font-semibold text-gray-900">
                  {" "}
                  Serial Number
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[180px]">
                <p className="text-sm font-semibold text-gray-900">Project</p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Project Location
                </p>
              </div>
              <div className="flex flex-col blue-200 items-start mr-4 w-[270px]">
                <p className="text-sm font-semibold text-gray-900">Problem</p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Description
                </p>
                {/* <p className="mt-1 text-xs  leading-5 text-gray-500">Image</p> */}
              </div>
              <div className="flex flex-col blue-200 items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Target Date
                </p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Problem Raised Person
                </p>
              </div>
              <div className="flex flex-col items-start green-200  w-[140px]">
                <p className="text-sm font-semibold text-gray-900">Status</p>
                {/* <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Response
                </p> */}
              </div>
              <div className="flex flex-col items-start green-200   w-[190px]">
                <p className="text-sm font-semibold text-gray-900">
                  Asign Responsible Person
                </p>
                <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Response Date
                </p>
              </div>
              <div className="flex flex-col items-start green-200  w-[140px]">
                <p className="text-sm font-semibold text-gray-900">
                  Correction
                </p>
                <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Comment
                </p>
                <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Resolve Issue Image
                </p>
              </div>

              <div className="flex richblue-200 flex-col items-start w-[150px]">
                <p className="text-sm  font-semibold text-gray-900">Action</p>
              </div>
            </li>
          </ul>
          {/* Child Items */}
          <div className=" overflow-y-auto max-h-[400px]   ">
            {tableData && tableData.length
              ? tableData.map((item, key) => (
                  <ul
                    role="list"
                    className="divide-y w-[1200px] divide-[#F5F5F5]"
                  >
                    <li
                      className={`flex justify-between ${
                        key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }  items-start px-5  py-2`}
                    >
                      <div className="flex flex-col items-start -yellow-200 w-[130px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.sl ? item.sl : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col blue-200 items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.project_name ? item.project_name : "-"}
                        </p>
                        <p className="mt-1 text-xs  leading-5 text-gray-500">
                          {item.project_detail_id
                            ? item.project_detail_id
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col blue-200 items-start w-[300px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.problem_area ? item.problem_area : "-"}
                        </p>
                        <p className="mt-1 text-xs flex  leading-5 text-gray-500">
                          {item.remark ? item.remark : "-"}{" "}
                          {item.image ? (
                            <a
                              // onClick={() => onBillingWorkOrder(item)}
                              href="#"
                              class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                              data-te-toggle="tooltip"
                              title="View Images"
                            >
                              <FaEye
                                onClick={() => {
                                  setSliderImage(item.image),
                                    setShowImagesModal(true);
                                }}
                                className="text-top_nav_blue-300 cursor-pointer  ml-1"
                                size={18}
                              />
                            </a>
                          ) : (
                            "-"
                          )}
                        </p>
                        {/* <p className="mt-1 text-xs  leading-5 text-gray-500">
                          {item.image ? (
                            <FaEye
                              onClick={() => {
                                setSliderImage(item.image),
                                  setShowImagesModal(true);
                              }}
                              className="text-top_nav_blue-300 cursor-pointer "
                              size={18}
                            />
                          ) : (
                            "-"
                          )}
                        </p> */}
                      </div>
                      <div className="flex flex-col blue-200 items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.target_date
                            ? formatDate(item.target_date)
                            : "-"}
                        </p>
                        <p className="mt-1 text-xs  leading-5 text-gray-500">
                          {item.create_person ? item.create_person : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start ml-2 green-200  w-[160px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.status == "OPEN" ? (
                            <div className=" flex  items-center">
                              {" "}
                              {item.status}
                              <span class="flex ml-1 w-2 h-2 bg-yellow-300 rounded-full"></span>
                            </div>
                          ) : item.status == "CLOSE" ? (
                            <div className=" flex  items-center">
                              {" "}
                              {item.status}
                              <span class="flex ml-1 w-2 h-2  bg-green-400 rounded-full"></span>
                            </div>
                          ) : (
                            <div className=" flex  items-center">
                              {item.status}
                              <span class="flex ml-1 w-2 h-2 bg-red-500 rounded-full"></span>
                            </div>
                          )}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          <a
                            // onClick={() => onBillingWorkOrder(item)}
                            href="#"
                            class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="Respond to observation"
                          >
                            <FaReply
                              className="text-top_nav_blue-300 cursor-pointer "
                              size={18}
                              onClick={() => handleClick(item)}
                            />
                          </a>
                        </p>
                      </div>
                      <div className="flex flex-col items-start green-200  w-[210px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.name ? item.name : "-"}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          {item.response_date
                            ? formatDate(item.response_date)
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start green-200  w-[150px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item.correction_action
                            ? item.correction_action
                            : "-"}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          {item.comment ? item.comment : "-"}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          {item.resolve_image ? (
                            <a
                              // onClick={() => onBillingWorkOrder(item)}
                              href="#"
                              class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                              data-te-toggle="tooltip"
                              title="View response images"
                            >
                              <FaEye
                                onClick={() => {
                                  setSliderImage(item.resolve_image),
                                    setShowImagesModal(true);
                                }}
                                className="text-top_nav_blue-300 cursor-pointer "
                                size={18}
                              />
                            </a>
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      {/* btns  */}
                      <div className="flex richblue-300   gap-x-2 items-start w-[130px]">
                        <a
                          // onClick={() => onBillingWorkOrder(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Edit"
                        >
                          <MdEdit
                            className=" text-top_nav_blue-300 "
                            size={18}
                            onClick={() => {
                              showModal(item);
                            }}
                          />
                          {/* <RiBillLine
                         
                        /> */}
                        </a>{" "}
                        <a
                          onClick={() => deleteRow(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Delete"
                        >
                          <MdDelete
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={() => deleteRow(item)}
                          />

                          {/* <FaIndianRupeeSign
                         
                        /> */}
                        </a>
                      </div>
                    </li>
                  </ul>
                ))
              : "No records found..."}
          </div>
        </div>
      </div>

      {/* new table end  */}
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1400}
        centered={true}
        footer={null}
      >
        <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
          <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
            Edit Site Observation
          </div>

          <form className="font-montserrat" onSubmit={onEditEhsFinish}>
            {/* 1a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1  */}
              <div className="">
                <label
                  htmlFor="problem_area"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Problem Area
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="problem_area"
                    value={ehsEditValuse.problem_area}
                    onChange={(e) => {
                      setEhsEditValues({
                        ...ehsEditValuse,
                        problem_area: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2  */}
              <div className="">
                <label
                  htmlFor="problem_area"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Correction Action
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="problem_area"
                    value={ehsEditValuse.correction_action}
                    onChange={(e) => {
                      setEhsEditValues({
                        ...ehsEditValuse,
                        correction_action: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 3  */}
              <div className="">
                <label
                  htmlFor="problem_area"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Remark
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="problem_area"
                    value={ehsEditValuse.remark}
                    onChange={(e) => {
                      setEhsEditValues({
                        ...ehsEditValuse,
                        remark: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
            </div>
            {/* 2a  */}
            <div className="flex my-3 justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="task_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Status
                </label>
                <select
                  required
                  name="task_id"
                  value={ehsEditValuse.status}
                  onChange={(e) => {
                    setEhsEditValues({
                      ...ehsEditValuse,
                      status: e.target.value,
                    });

                    // HandelSelectTask(e.target.value);
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                >
                  <option value="">Select</option>{" "}
                  <option value="OPEN">Open</option>{" "}
                  <option value="PENDING">Pending</option>{" "}
                  <option value="CLOSE">Close</option>
                </select>
              </div>

              {/* 2 date */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="response_date"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Response Date
                </label>
                <input
                  required
                  type="date"
                  name="response_date"
                  value={ehsEditValuse.response_date}
                  // onChange={handleInputChange}
                  onChange={(e) =>
                    setEhsEditValues({
                      ...ehsEditValuse,
                      response_date: e.target.value,
                    })
                  }
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>

              {/* 3 date */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="target_date"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Resolve Date
                </label>
                <input
                  required
                  type="date"
                  name="target_date"
                  value={ehsEditValuse.resolve_date}
                  // onChange={handleInputChange}
                  onChange={(e) =>
                    setEhsEditValues({
                      ...ehsEditValuse,
                      target_date: e.target.value,
                    })
                  }
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>
            </div>
            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
              <button
                // onClick={resetAllValue}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        {/* <Form
          form={form}
          name="add-ehs"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onEditEhsFinish}
          onFinishFailed={onEditEhsFinishFailed}
        >
          {logindetails.user.role === "ADMIN" ? (
            <Form.Item
              name="project_id"
              label="Select Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Name !",
                },
              ]}
            >
              <Select
                className="input-field"
                //   onKeyDown={(e) => onKeydown(e)}
                // onChange={(projectId) => setSelectedProjectId(projectId)}
                onChange={(projectId) => handelProjectId(projectId)}
              >
                {allProjects.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              name="project_id"
              label="Select Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project!",
                },
              ]}
            >
              <Select
                className="input-field"
                // onChange={(projectId) => setSelectedProjectId(projectId)}
                onChange={(projectId) => handelProjectId(projectId)}
              >
                {projectData.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="project_detail_id"
            label="Project Location"
            rules={[
              {
                required: true,
                message: "Please Select Project Location !",
              },
            ]}
            className="input-field"
          >
            <Select className="input-field">
              {allProjDetails
                .filter(
                  (getAllProjDetails) =>
                    getAllProjDetails.project_id === selectedProjectId
                )
                .map((getAllProjDetails) => (
                  <Option
                    key={getAllProjDetails.project_details_id}
                    value={getAllProjDetails.block}
                  >
                    {getAllProjDetails.block}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="problem_area"
            label="Problem Area"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Problem Area!",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="correction_action"
            label="Correction Action"
            className="input-field"
            rules={
              [
                // {
                //   required: true,
                //   message: "Please enter Currection Action!",
                // },
              ]
            }
          >
            <Input placeholder="" />
          </Form.Item>
       
          <Form.Item
            name="response_date"
            label="Response Date"
            rules={[
              {
                required: true,
                message: "Please select Response Date!",
              },
              {
                pattern: /^(.{0,300})$/,
                message: "please enter less than 300 characters only",
              },
            ]}
            className="input-field"
          >
            <DatePicker
              format="DD-MM-YYYY"
              onChange={handelSelectResponseDate}
            />
          </Form.Item>
          <Form.Item
            name="target_date"
            label="Target Date"
            rules={[
              {
                required: true,
                message: "Please select Target Date!",
              },
              {
                validator: validateTargetDate, // Use the custom validation function
              },
            ]}
            className="input-field"
          >
            <DatePicker format="DD-MM-YYYY" onChange={handelSelectTargetDate} />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: "Please Select Status!",
              },
            ]}
            className="input-field"
          >
            <Select
              placeholder="Please select Status"
              value={options.value}
              options={options}
              defaultValue={options[""]}
            ></Select>
          </Form.Item>
          <Form.Item name="remark" label="Remark" className="input-field">
            <Input placeholder="" />
          </Form.Item>

          <Form.Item>
            <div className="btn-ehs">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>

              <Button onClick={clear_fields} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
            </div>
          </Form.Item>
        </Form> */}
      </Modal>

      {/* modal 2 */}
      <Modal
        visible={responseModal}
        onCancel={handleCancel}
        // width={1400}
        width={1500}
        centered={true}
        footer={null}
      >
        <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
          <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
            Site Observation give Response
          </div>

          {/* form  */}
          <form className="font-montserrat" onSubmit={handelSubmitResponce}>
            <div className="flex my-3 justify-evenly w-[90vw]">
              {/* 1 */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="resolve_date"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Issue Resolve Date
                </label>
                <input
                  required
                  type="date"
                  name="resolve_date"
                  value={submitResponseData.resolve_date}
                  onChange={(e) => {
                    setSubmitResponseData({
                      ...submitResponseData,
                      resolve_date: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>
              {/* 2 */}
              <div className="">
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Comment
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="comment"
                    value={submitResponseData.comment}
                    onChange={(e) => {
                      setSubmitResponseData({
                        ...submitResponseData,
                        comment: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 3  */}
              <div className="w-[300px] ">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Resolve Problem Image
                </label>
                <input
                  required
                  class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="image"
                  type="file"
                  accept="image/svg+xml, image/png, image/jpeg, image/gif"
                  onChange={(event) => {
                    const fileInput = event.target;
                    const file = fileInput.files[0];

                    if (file) {
                      setImageFile(file);
                      // console.log("filse set: ", imageFile);
                    }
                  }}
                />
                <p
                  class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  SVG, PNG, JPG or GIF .
                </p>
              </div>
            </div>

            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
              <button
                // onClick={resetAllValue}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        {/* <Form form={form1} onFinish={handelSubmitResponce}>
          <Form.Item
            name="problem_area"
            label="Problem"
            className="input-field"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="target_date"
            label="Target Date"
            className="input-field"
          >
            <DatePicker format="DD-MM-YYYY" disabled />
          </Form.Item>

          <Form.Item
            name="resolve_date"
            label="Issue Resolve Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select Date!",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" onChange={handelResponceDate} />
          </Form.Item>
          <Form.Item
            name="resolve_image"
            label="Resolve Problem Image"
            className="input-field"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Please upload Resolve image!",
              },
            ]}
          >
            <Upload
              name="logo"
              listType="picture"
              accept=".jpg,.jpeg,.png"
              beforeUpload={(file, fileList) => {
                return false;
              }}
              onPreview={handlePreview}
            >
              <Button icon={<UploadOutlined />}>Choosen File</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="comment" label="Comments" className="input-field">
            <Input.TextArea
              style={{ maxHeight: "70px", minHeight: "70px" }}
              placeholder="Comments"
            />
          </Form.Item>
          <Form.Item>
            <div className="btn-ehs">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>

              <Button onClick={clear_value} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
            </div>
          </Form.Item>
        </Form> */}
      </Modal>
      <br />
      {/* {getEhsData.length > 0 && (
        <Button
          style={{ display: activedata ? "" : "none" }}
          className="deleteallbutton copy"
          onClick={deleteSelected}
        >
          Delete All Selected
        </Button>
      )} */}
      <Modal open={previewVisible} footer={null} onCancel={handleCancels}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      {/* </Spin> */}
      {showImages && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-lg   ">
          <IoMdClose
            size={22}
            onClick={() => setShowImagesModal(false)}
            className=" cursor-pointer absolute top-14  right-64"
          />
          <div>
            <img height={400} width={600} src={sliderImage} />
          </div>
        </div>
      )}
      {newSiteObservation && (
        <div className="fixed  select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-lg   ">
          <IoMdClose
            size={22}
            onClick={() => setNewSiteObservation(false)}
            className=" cursor-pointer absolute  top-28   right-10"
          />
          <EhsCreateForm setNewSiteObservation={setNewSiteObservation} />
        </div>
      )}
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(EhsShow);
