// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gutter-box {
    padding: 8px 0;
    background: #00a0e9;    
  }

p{
    font-size: small;
}
.gutter-btn{

  text-align: center;

}`, "",{"version":3,"sources":["webpack://./src/components/IconsNotification/IconsNotification.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mBAAmB;EACrB;;AAEF;IACI,gBAAgB;AACpB;AACA;;EAEE,kBAAkB;;AAEpB","sourcesContent":[".gutter-box {\n    padding: 8px 0;\n    background: #00a0e9;    \n  }\n\np{\n    font-size: small;\n}\n.gutter-btn{\n\n  text-align: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
