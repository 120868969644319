import React, { useEffect, useState } from "react";
import "./css/UsersShow.css";
import { Form, Input, Button, Select, Modal, Table } from "antd";
import {
  createNewTypesOfProject,
  getAllTypeOfProject,
} from "../../../redux-core/settings/actions";
import { selectorGetTypeOfProject } from "../../../redux-core/settings/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import useSearch from "../../../hooks/useSearch";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosAdd, IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => getAllProj(orgId, userId),
  typeOfProject: (projectType) =>
    dispatch(createNewTypesOfProject(projectType)),
  getAllTypesProject: (project_id, orgId) =>
    dispatch(getAllTypeOfProject(project_id, orgId)),
});

const TypesOfProjectTable = (props) => {
  const { getAllTypesProject, typeOfProject, getProj } = actionDispatch(
    useDispatch()
  );
  const getAllTypesOfProjects = useSelector(selectorGetTypeOfProject);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const [selectedKeys, setSelectedKeys] = useState();
  const userId = logindetails.user.id;
  const getColumnSearchProps = useSearch();
  // const { getProj, typeOfProject, getAllTypesProject } = actionDispatch(
  //   useDispatch()
  // );
  // const actionDispatch = (dispatch) => ({
  //   getProj: (orgId, userId) => getAllProj(orgId, userId),
  //   typeOfProject: (projectType) =>
  //     dispatch(createNewTypesOfProject(projectType)),
  //   getAllTypesProject: (project_id, orgId) =>
  //     dispatch(getAllTypeOfProject(project_id, orgId)),
  // });

  useEffect(() => {
    getAllTypesProject(props.projectID, orgId);
    // console.log("Props: ", props)
  }, []);

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      key: "sl_no",
      ...getColumnSearchProps("project_details_id"),
      width: "10%",
    },
    {
      title: "Project ID",
      dataIndex: "Project_id",
      key: "Project_id",
      ...getColumnSearchProps("project_details_id"),
      width: "10%",
    },
    {
      title: "Buildup Area",
      dataIndex: "buildup_area",
      key: "buildup_area",
      ...getColumnSearchProps("buildup_area"),
      width: "10%",
    },
    {
      title: "Total Buildup Area",
      dataIndex: "total_buildup_area",
      key: "total_buildup_area",
      ...getColumnSearchProps("total_buildup_area"),
      width: "10%",
    },
    {
      title: "Total Saleable Area",
      dataIndex: "total_saleable_area",
      key: "total_saleable_area",
      ...getColumnSearchProps("total_saleable_area"),
      width: "10%",
    },
    {
      title: "UOM",
      dataIndex: "select_UOM",
      key: "select_UOM",
      ...getColumnSearchProps("select_UOM"),
      width: "10%",
    },
    {
      title: "Basement",
      dataIndex: "basement",
      key: "basement",
      ...getColumnSearchProps("basement"),
      width: "10%",
    },
    {
      title: "Ground Level",
      dataIndex: "ground",
      key: "ground",
      ...getColumnSearchProps("ground"),
      width: "10%",
    },
    {
      title: "No of Floors",
      dataIndex: "floor",
      key: "floor",
      ...getColumnSearchProps("floor"),
      width: "10%",
    },

    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (text, record) => (
    //     <span>{`${record.description1 || ''} ${record.description2 || ''} ${record.description3 || ''} ${record.description4 || ''} ${record.description5 || ''}`}</span>
    //   ),
    // },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   width:"10%",
    //   render: ()=>{
    //     return(
    //       <div>
    //           <Button
    //           onClick={showButton}
    //           >
    //           Add More
    //           </Button>
    //       </div>
    //     )
    //   }
    // },
  ];

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "description1",
        dataIndex: "description1",
        key: "description1",
      },
      {
        title: "description2",
        dataIndex: "description2",
        key: "description2",
      },
      {
        title: "description3",
        dataIndex: "description3",
        key: "description3",
      },
      {
        title: "description4",
        dataIndex: "description4",
        key: "description4",
      },
      {
        title: "description5",
        dataIndex: "description5",
        key: "description5",
      },
      {
        title: "description6",
        dataIndex: "description6",
        key: "description6",
      },
      {
        title: "description7",
        dataIndex: "description7",
        key: "description7",
      },
      {
        title: "description8",
        dataIndex: "description8",
        key: "description8",
      },
      {
        title: "description9",
        dataIndex: "description9",
        key: "description9",
      },
      {
        title: "description10",
        dataIndex: "description10",
        key: "description10",
      },
    ];

    return <Table columns={columns} dataSource={[record]} pagination={false} />;
  };
  const data = [];
  for (let i = 0; i < getAllTypesOfProjects.length; i++) {
    data.push({
      key: getAllTypesOfProjects[i].id,
      sl_no: i + 1,
      Project_id: getAllTypesOfProjects[i].project_id,
      buildup_area: getAllTypesOfProjects[i].buildup_area,
      total_buildup_area: getAllTypesOfProjects[i].total_buildup_area,
      total_saleable_area: getAllTypesOfProjects[i].total_saleable_area,
      select_UOM: getAllTypesOfProjects[i].select_UOM,
      basement: getAllTypesOfProjects[i].basement,
      ground: getAllTypesOfProjects[i].ground,
      floor: getAllTypesOfProjects[i].floor,
      description1: getAllTypesOfProjects[i].description1,
      description2: getAllTypesOfProjects[i].description2,
      description3: getAllTypesOfProjects[i].description3,
      description4: getAllTypesOfProjects[i].description4,
      description5: getAllTypesOfProjects[i].description5,
      description6: getAllTypesOfProjects[i].description6,
      description7: getAllTypesOfProjects[i].description7,
      description8: getAllTypesOfProjects[i].description8,
      description9: getAllTypesOfProjects[i].description9,
      description10: getAllTypesOfProjects[i].description10,
    });
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange,
  };

  const defaultExpandedRowKeys = data
    .filter((record, index) => index % 2 === 0)
    .map((record) => record.key.toString());

  const [showDisc, setShowDisc] = useState(false);
  const [modatData, setModalData] = useState(null);
  const [addProject, setAddProject] = useState(false);

  // useEffect(() => {
  //   console.log("data: ", data);
  // }, [data]);

  function formatNumberWithCommas(number) {
    if (typeof number === "string") {
      const parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
        return parsedNumber.toLocaleString("en-US");
      } else {
        return "-";
      }
    } else if (typeof number === "number") {
      return number.toLocaleString("en-US");
    } else {
      return "-";
    }
  }

  const [showTables, setShowTables] = useState(false);
  const [showProjectTypesModal, setShowProjectTypesModal] = useState(false);
  const [formData, setFormData] = useState({
    buildup_area: "",
    // total_saleable_area: "",
    // total_buildup_area: "",
    total_area: "",
    basement: "",
    select_UOM: "",
    ground: "",
    floor: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    description6: "",
    description7: "",
    description8: "",
    description9: "",
    description10: "",
  });

  const [descriptionCount, setDescriptionCount] = useState(0);
  // const orgId = logindetails.organisation.orgID;

  const typesOfProjectFinish = async (e) => {
    e.preventDefault();
    setShowTables(true);
    setShowProjectTypesModal(false);
    // setMakeHide(false)
    // setIsDisableInput(true)
    let data = {
      ...formData,
      type_of_project: "building",
      orgID: orgId,
      createdBy: userId,
      Project_id: props.projectID,
    };

    if (data.buildup_area == "totalbuilduparea") {
      data.total_buildup_area = data.total_area;
      delete data.total_area;
    } else {
      data.total_saleable_area = data.total_area;
      delete data.total_area;
    }
    // console.log("data before submission", data);
    // return;

    try {
      // console.log("data form : ", data, "props: ", props);
      // return;
      let res = await typeOfProject(data);
      if (res.success) {
        setAddProject(false);
        toast.success("Success..");
        console.log("Res: ", res);
        setDescriptionCount(0);
        setFormData({
          buildup_area: "",
          // total_saleable_area: "",
          // total_buildup_area: "",
          total_area: "",
          basement: "",
          select_UOM: "",
          ground: "",
          floor: "",
          description1: "",
          description2: "",
          description3: "",
          description4: "",
          description5: "",
          description6: "",
          description7: "",
          description8: "",
          description9: "",
          description10: "",
        });
      }

      // form.resetFields();
      // setAdditionalFields([]);
      getAllTypeOfProject(props.projectId, orgId);
      // form1.setFieldsValue({
      //   type_of_project: "",
      // });
    } catch (err) {
      toast.error("Failed..");
      console.log("Err: ", err);
    }
  };

  return (
    <>
      <div className=" flex  justify-between font-montserrat">
        <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
          Types Of Project
        </div>
        <button
          onClick={() => setAddProject(true)}
          type="button"
          class="flex  justify-evenly mr-28 items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
        >
          <span>
            <IoIosAdd size={20} />
          </span>
          Add 
        </button>
      </div>

      {/* <Table
        dataSource={data}
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys, // Set the defaultExpandedRowKeys to the keys of the rows you want to expand initially
        }}
        style={{ marginTop: "20px" }}
        scroll={{ x: "90vw" }}
        // rowKey="key"
        // rowSelection={rowSelection}
      /> */}

      {/* new  table shivam kashyap 27 may 2024  */}

      <div className=" font-montserrat mt-5  ml-4    flex flex-col items-center  justify-center -green-300">
        <ul role="list" className="divide-y w-[1200px] divide-[#F5F5F5]">
          <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
            {/* Header Content */}
            <div className="flex flex-col items-start -yellow-200 w-[200px]">
              <p className="text-sm font-semibold text-gray-900">Sr no.</p>
            </div>

            {/* <div className="flex flex-col blue-200 items-start w-[300px]">
              <p className="text-sm font-semibold text-gray-900">
                {" "}
                Builtup Area
              </p>
              <p className="mt-1 text-xs  leading-5 text-gray-500">UOM</p>
            </div> */}
            <div className="flex flex-col blue-200 items-start w-[300px]">
              <p className="text-sm font-semibold text-gray-900">
                {" "}
                Total Builtup Area
              </p>
              <p className="mt-1 text-xs  leading-5 text-gray-500">UOM</p>
            </div>
            <div className="flex flex-col blue-200 items-start w-[300px]">
              <p className="text-sm font-semibold text-gray-900">
                {" "}
                Total Saleable Area
              </p>
              <p className="mt-1 text-xs  leading-5 text-gray-500">UOM</p>
            </div>
            <div className="flex flex-col items-start -yellow-200 w-[200px]">
              <p className="text-sm font-semibold text-gray-900">Basement</p>
            </div>
            <div className="flex flex-col items-start -yellow-200 w-[200px]">
              <p className="text-sm font-semibold text-gray-900">
                Ground Level
              </p>
            </div>
            <div className="flex flex-col items-start -yellow-200 w-[200px]">
              <p className="text-sm font-semibold text-gray-900">
                Number of Floors
              </p>
            </div>

            <div className="flex richblue-200 flex-col items-start w-[160px]">
              <p className="text-sm  font-semibold text-gray-900">Action</p>
            </div>
          </li>
        </ul>
        {/* data  */}
        <ul
          role="list"
          className="divide-y w-[1200px]  divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[62vh] "
        >
          {data && data.length
            ? data.map((item, key) => (
                <li
                  className={`flex justify-between ${
                    key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                  }  items-start px-5  py-2`}
                >
                  <div className="flex flex-col items-start -yellow-200 w-[200px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {key + 1}
                    </p>
                  </div>

                  {/* <div className="flex flex-col blue-200 items-start w-[300px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {" "}
                      {item.buildup_area ? item.buildup_area : "-"}
                    </p>
                    <p className="mt-1 text-xs  leading-5 text-gray-500">
                      {" "}
                      {item.select_UOM ? item.select_UOM : "-"}
                    </p>
                  </div> */}
                  <div className="flex flex-col blue-200 items-start w-[300px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {" "}
                      {item.total_buildup_area
                        ? formatNumberWithCommas(
                            Number(item.total_buildup_area)
                          )
                        : "-"}
                    </p>
                    {item.total_buildup_area && (
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {" "}
                        {item.select_UOM ? item.select_UOM : "-"}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col blue-200 items-start w-[300px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {" "}
                      {item.total_saleable_area
                        ? formatNumberWithCommas(
                            Number(item.total_saleable_area)
                          )
                        : "-"}
                    </p>
                    {item.total_saleable_area && (
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {" "}
                        {item.select_UOM ? item.select_UOM : "-"}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col items-start -yellow-200 w-[200px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {item.basement
                        ? formatNumberWithCommas(Number(item.basement))
                        : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start -yellow-200 w-[200px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {item.ground ? item.ground : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start -yellow-200 w-[200px]">
                    <p className="text-sm font-semibold text-gray-900">
                      {item.floor ? item.floor : "-"}
                    </p>
                  </div>

                  <div className="flex richblue-300   gap-x-2 items-start w-[160px]">
                    <a
                      // onClick={() => onBillingWorkOrder(item)}
                      href="#"
                      class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                      data-te-toggle="tooltip"
                      title="Description"
                    >
                      <FaInfoCircle
                        className=" text-top_nav_blue-300 "
                        size={18}
                        onClick={() => {
                          setShowDisc(true);
                          setModalData(item);
                        }}
                      />
                      {/* <RiBillLine
                         
                        /> */}
                    </a>{" "}
                  </div>
                </li>
              ))
            : "No records found..."}
        </ul>
      </div>

      <Modal
        visible={showDisc}
        footer={null}
        onCancel={() => setShowDisc(false)}
        width={550}
      >
        <div className=" font-montserrat mt-5  ml-4  bg-white   flex flex-col items-center  justify-center -green-300">
          <ul role="list" className="divide-y w-[500px] divide-[#F5F5F5]">
            <li className="flex justify-between  items-start px-5 bg-[#aca7a7] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">Name</p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900"> Value</p>
                {/* <p className="mt-1 text-xs  leading-5 text-gray-500">UOM</p> */}
              </div>
            </li>
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 1
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description1 ? modatData?.description1 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 2
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description2 ? modatData?.description2 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 3
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description3 ? modatData?.description3 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 4
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description4 ? modatData?.description4 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 5
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description5 ? modatData?.description5 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 6
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description6 ? modatData?.description6 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 7
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description7 ? modatData?.description7 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 8
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description8 ? modatData?.description8 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 9
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description9 ? modatData?.description9 : "-"}
                </p>
              </div>
            </li>{" "}
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description 10
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {modatData?.description10 ? modatData?.description10 : "-"}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </Modal>
      <Modal
        width="90%"
        visible={addProject}
        onCancel={() => setAddProject(false)}
        footer={null}
      >
        {/* <Form
            form={form}
            onFinish={typesOfProjectFinish}
            onFinishFailed={typesOfProjectFinishFaild}
          >
            <Form.Item
              name="buildup_area"
              label="Build Up Areas"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Area Type",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select Type of Area"
                onChange={handlebuilduparea}
                // disabled={isDisableInput}
              >
                <option value="totalbuilduparea">BuildUp Area</option>
                <option value="totalsaleablearea">Saleable Area</option>
              </Select>
            </Form.Item>
            {buildArea === "totalbuilduparea" && (
              <Form.Item
                className="input-field"
                name="total_buildup_area"
                label="Total BuilUp Area"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Total Buildup Area",
                  },
                ]}
              >
                <InputNumber type="number" style={{ width: "100%" }} />
              </Form.Item>
            )}
            {buildArea === "totalsaleablearea" && (
              <Form.Item
                className="input-field"
                name="total_saleable_area"
                label="Total Saleable Area"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Total Saleable Area",
                  },
                ]}
              >
                <InputNumber type="number" style={{ width: "100%" }} />
              </Form.Item>
            )}

            <Form.Item
              name="select_UOM"
              label="Select UOM"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Total Saleable Area",
                },
              ]}
            >
              <Select
                placeholder="Select UOM"
                // disabled={isDisableInput}
              >
                <option value="sq.mt">Sq.mt</option>
                <option value="sq.ft">Sq.ft</option>
              </Select>
            </Form.Item>
            <Form.Item
              name="basement"
              label="Basement"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Basement No",
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Enter the Basement No"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="ground"
              label="Ground Level"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Ground No",
                },
              ]}
            >
         
              <Select>
                <option value="ground">Ground</option>
                <option value="stilt">Stilt</option>
              </Select>
            </Form.Item>
            <Form.Item
              name="floor"
              label="No of Floors"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Floor No",
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Enter No of Floors"
                style={{ width: "100%" }}
              />
            </Form.Item>
            {addInfo && (
              <>
                {additionalFields.map((_, index) => (
                  <Form.Item
                    key={index}
                    className="input-field"
                    name={`description${index + 1}`} // Use a dynamic name to avoid conflicts
                    label={`Description ${index + 1}`}
                    rules={[
                      {
                        required: false,
                        message: `Please Enter field${index + 1}`,
                      },
                    ]}
                  >
                    <Input placeholder={`Enter description ${index + 1}`} />
                  </Form.Item>
                ))}
              </>
            )}
            <Form.Item>
              {additionalFields.length < maxFields && (
                <Button
                  type="primary"
                  className="btn-submit"
                  onClick={addMoreInfo}
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Add More Details</strong>
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  // disabled={isDisableInput}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}

        {/* new form - shivam kashyap 27 may 2024  */}
        <div className=" font-semibold  text-2xl font-montserrat">
          Add Building details
        </div>
        <br />

        <form
          className="font-montserrat flex flex-col items-center "
          onSubmit={typesOfProjectFinish}
        >
          {/* 1a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="buildup_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Build Up Areas
              </label>
              <select
                required
                name="buildup_area"
                onChange={(e) => {
                  // console.log("setting values");
                  setFormData({ ...formData, buildup_area: e.target.value });
                }}
                // value={formData.buildup_area}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                placeholder="select type of area"
              >
                <option value="">Select</option>
                <option value="totalbuilduparea">BuildUp Area</option>
                <option value="totalsaleablearea">Saleable Area</option>
              </select>
            </div>
            {/* 2  */}
            <div className="">
              <label
                htmlFor="total_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Total Area
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="number"
                  name="total_area"
                  value={formData.total_area}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      total_area: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 3 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="select_UOM"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Select UOM
              </label>
              <select
                required
                name="select_UOM"
                onChange={(e) => {
                  // console.log("setting values");
                  setFormData({ ...formData, select_UOM: e.target.value });
                }}
                // value={formData.buildup_area}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                placeholder="select type of area"
              >
                <option value="">Select</option>
                <option value="sq.mt">Sq.mt</option>
                <option value="sq.ft">Sq.ft</option>
              </select>
            </div>
          </div>
          {/* 2a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            <div className="">
              <label
                htmlFor="basement"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Basement No.
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="number"
                  name="basement"
                  value={formData.basement}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      basement: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="select_UOM"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Ground Level
              </label>
              <select
                required
                name="ground"
                onChange={(e) => {
                  // console.log("setting values");
                  setFormData({ ...formData, ground: e.target.value });
                }}
                value={formData.ground}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                placeholder="select type of area"
              >
                <option value="">Select</option>
                <option value="ground">Ground</option>
                <option value="stilt">Stilt</option>
              </select>
            </div>
            {/* 3  */}
            <div className="">
              <label
                htmlFor="floor"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                No of Floor
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="number"
                  name="floor"
                  value={formData.floor}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      floor: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
          </div>
          {/* 3a descriptions  */}
          {/* 3a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            {descriptionCount > 0 && (
              <div className="">
                <label
                  htmlFor="description1"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 1
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description1"
                    value={formData.description1}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description1: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
            {/* 2  */}
            {descriptionCount > 1 && (
              <div className="">
                <label
                  htmlFor="description1"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 2
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description2"
                    value={formData.description2}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description2: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}

            {/* 3  */}
            {descriptionCount > 2 && (
              <div className="">
                <label
                  htmlFor="description3"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 3
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description3"
                    value={formData.description3}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description3: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
          </div>
          {/* 4a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            {descriptionCount > 3 && (
              <div className="">
                <label
                  htmlFor="description4"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 4
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description4"
                    value={formData.description4}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description4: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
            {/* 2  */}
            {descriptionCount > 4 && (
              <div className="">
                <label
                  htmlFor="description5"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 5
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description5"
                    value={formData.description5}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description5: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}

            {/* 3  */}
            {descriptionCount > 5 && (
              <div className="">
                <label
                  htmlFor="description6"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 6
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description6"
                    value={formData.description6}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description6: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
          </div>
          {/* 5a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            {descriptionCount > 6 && (
              <div className="">
                <label
                  htmlFor="description7"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 7
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description7"
                    value={formData.description7}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description7: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
            {/* 2  */}
            {descriptionCount > 7 && (
              <div className="">
                <label
                  htmlFor="description8"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 8
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description8"
                    value={formData.description8}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description8: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}

            {/* 3  */}
            {descriptionCount > 8 && (
              <div className="">
                <label
                  htmlFor="description9"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 9
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description9"
                    value={formData.description9}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description9: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
          </div>
          {/* 6a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            {descriptionCount > 9 && (
              <div className="">
                <label
                  htmlFor="description10"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description 10
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="description10"
                    value={formData.description10}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        description10: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
          </div>
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
            {descriptionCount < 10 && (
              <button
                onClick={() => {
                  setDescriptionCount((prev) => prev + 1);
                }}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Add description {descriptionCount + 1}
              </button>
            )}

            <button
              onClick={() => {
                setFormData({
                  buildup_area: "",
                  total_area: "",
                  basement: "",
                  select_UOM: "",
                  ground: "",
                  floor: "",
                  description1: "",
                  description2: "",
                  description3: "",
                  description4: "",
                  description5: "",
                  description6: "",
                  description7: "",
                  description8: "",
                  description9: "",
                  description10: "",
                });
              }}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>

        {/* {showTables ? (
<Table  dataSource={data} columns={columns} style={{marginTop:"20px"}}
scroll={{x:"90vw"}} />
      ) : (
        ""
      )} */}
      </Modal>
    </>
  );
};

export default TypesOfProjectTable;
