import "./css/TaskCreate.css";
import React, { useState } from "react";
import TaskForm from "./Forms/TaskForm";
import StageForm from "./Forms/StageForm";
import { useSelector } from "react-redux";
import { Modal, Spin } from "antd";
import UploadTaskExcelForm from "./Forms/UploadTaskExcelForm";
import { IoIosAdd } from "react-icons/io";
function TaskCreate({ setshowCreateTaskModal, getTasks, orgID }) {
  const Spindata = useSelector((item) => item.spinreducer);
  //   ------------------------------UPLOAD------------------------------------------------
  // const [formUpload] = Form.useForm();

  // const onUploadFinish = (values) => {
  //     console.log('Success:', values);
  // };

  // const onUploadFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };
  const [openModal, setOpenModal] = useState(false);
  const openStage = () => {
    setOpenModal(true);
  };
  const handleCancelmodal = () => setOpenModal(false);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className=" bg-white w-[1000px]">
          {/* -------------------------STAGE DETAILS-------------------------------- */}
          {/* <h2>Add Stage</h2> */}
          {/* <div className="stage">
            <div className="sub__title">Stage Details</div>
            <br />
            <StageForm />
          </div> */}

          {/* -------------------------TASK DETAILS-------------------------------- */}
          {/* <h2>Add Task</h2> */}

          <div className="task">
            <div className=" flex justify-between mt-3  mx-11">
              <div className="font-semibold  text-2xl">Task Create</div>
              <button
                onClick={openStage}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <span>
                  <IoIosAdd size={20} />
                </span>
                Create Stage
              </button>
            </div>

            <br />
            <div className=" w-full">
              <TaskForm
                getTasks={getTasks}
                orgID={orgID}
                setshowCreateTaskModal={setshowCreateTaskModal}
              />
            </div>
          </div>

          {/* commented on 10 may 2024 - shivam kashyap  */}
          {/* <div className="upload-file task">
            <div className="font-semibold  text-2xl">
              Upload Tasks From Excel File
            </div>
            <br />
            <UploadTaskExcelForm
              setshowCreateTaskModal={setshowCreateTaskModal}
            />
          </div> */}
          {/* --------------------UPLOAD TASKS FROM EXCEL FILE---------------------------------------
                <div className="master-task">

                    <div className="sub-title">Upload Tasks From Excel File</div>

                    <Form
                        form={formUpload}
                        name="stage-details"
                        size="large"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onUploadFinish}
                        onFinishFailed={onUploadFinishFailed}
                    //autoComplete="off"
                    >

                        <Form.Item
                            name="stage-name"
                            label="Upload Task"
                            className="input-field"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the field',
                                },
                            ]}
                        >
                            <Upload name="file" >
                                <Button icon={<UploadOutlined />} >Choose file</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item>
                            <div className="btn-project">
                                <Button type="primary" htmlType="submit" className="btn-submit">
                                    Upload
                                </Button>

                                <Button onClick={clear_field} className="btn-cancel">Sample</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div> */}
        </div>
      </Spin>
      <Modal visible={openModal} footer={null} onCancel={handleCancelmodal}>
        <StageForm />
      </Modal>
    </>
  );
}

export default React.memo(TaskCreate);
