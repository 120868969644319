import React, { useState } from "react";
import { ExpandOutlined } from "@ant-design/icons";
import { Tooltip, Space } from "antd";
function Expand() {
  const elem = document.documentElement;
  const [checkscreen, setCheckscreen] = React.useState(false);
  const expandscreen = (value) => {
    if (value) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
      setCheckscreen(!checkscreen);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      setCheckscreen(!checkscreen);
    }
  };
  return (
    // <Tooltip title="Expand">
        <Space>
        <ExpandOutlined onClick={() => expandscreen(!checkscreen)} />
              <span style={{fontSize:"13px", cursor:"pointer"}} onClick={() => expandscreen(!checkscreen)}>Expand</span>
              </Space>
    // </Tooltip>
  );
}

export default Expand;
