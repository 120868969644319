import "./css/TaskForm.css";
import "antd/dist/antd.min.css";
import { Form, Input, Button, Select, Upload, Modal } from "antd";
import {
  makeSelectAllProj,
  selectorFindProjDetailsById,
} from "../../../../redux-core/settings/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProj,
  findProjDetailById,
} from "../../../../redux-core/settings/actions";
import { findSupervisorDetailById } from "../../../../redux-core/userManagement/actions";
import { selectorSupervisorDetailById } from "../../../../redux-core/userManagement/selectors";
import {
  countActionQCC,
  findStageDetailById,
  newStageCreate,
  newTaskCreate,
} from "../../../../redux-core/qcc/actions";
import { selectorStageDetailById } from "../../../../redux-core/qcc/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import StageForm from "./StageForm";
import { PlusCircleOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  supervisorById: (projId, orgId) =>
    dispatch(findSupervisorDetailById(projId, orgId)),
  getProjDetailsById: (projId, orgID) =>
    dispatch(findProjDetailById(projId, orgID)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
  newTask: (task) => dispatch(newTaskCreate(task)),
  count: (orgId) => dispatch(countActionQCC(orgId)),
});

const TaskForm = ({ setshowCreateTaskModal, getTasks, orgID }) => {
  const [formTaskDetails] = Form.useForm();
  const {
    getProj,
    getProjDetailsById,
    supervisorById,
    count,
    getStageById,
    newTask,
  } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  useEffect(() => {
    getProj(orgId, userId);
    // getStageById(orgId);
  }, []);

  const allProjData = useSelector(makeSelectAllProj);
  const supervisorData = useSelector(selectorSupervisorDetailById);
  const projDetailsData = useSelector(selectorFindProjDetailsById);
  const stageDetailsData = useSelector(selectorStageDetailById);
  const children = [];
  const children_projDetail = [];
  const children_supervisor = [];
  const children_stages = [];
  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option key={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  for (let i = 0; i < projDetailsData.length; i++) {
    children_projDetail.push(
      <Option key={projDetailsData[i].project_details_id}>
        {projDetailsData[i].block}-{projDetailsData[i].project_details_id}
      </Option>
    );
  }

  for (let i = 0; i < supervisorData.length; i++) {
    children_supervisor.push(
      <Option key={supervisorData[i].supervisor_id}>
        {supervisorData[i].name}-{supervisorData[i].supervisor_id}
      </Option>
    );
  }

  for (let i = 0; i < stageDetailsData.length; i++) {
    children_stages.push(
      <Option key={stageDetailsData[i].stage_id}>
        {stageDetailsData[i].stage_name}-{stageDetailsData[i].stage_id}
      </Option>
    );
  }

  function handleProjChange(value) {
    formTaskDetails.setFieldsValue({
      // task_name: '',
      project_details_id: [],
      supervisor_id: [],
      stage_id: [],
    });
    getProjDetailsById(parseInt(value), orgId);
    supervisorById(parseInt(value), orgId);
    getStageById(parseInt(value), orgId);
  }

  function allStages() {
    getStageById(orgId, "");
  }

  const onTaskDetailsFinish = async (e) => {
    e.preventDefault();
    const uniqueStageIds = [...new Set(formData.stage_id)];
    setFormData({ ...formData, stage_id: uniqueStageIds });

    let data = {
      ...formData,
      ...{
        orgID: orgId,
        createdBy: userId,
        level: 4,
      },
    };
    // console.log("create task form data: ", data);
    // return;
    try {
      let response = await newTask(data);
      if (response.success) {
        toast.success("Task created..");
        console.log("res: ", response);
        await count(orgId);
        await clear_field2();
        setshowCreateTaskModal(false);
        await getTasks(orgID);
      }
    } catch (err) {
      console.log("Eror while creating task: ", err);
      toast.error("Failed..");
    }
  };

  const onTaskDetailsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_field2 = () => {
    formTaskDetails.setFieldsValue({
      task_name: "",
      project_id: [],
      project_details_id: [],
      supervisor_id: [],
      stage_id: [],
    });
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const openStage = () => {
    setOpenModal(true);
  };

  const handleCancelmodal = () => setOpenModal(false);

  const [formData, setFormData] = useState({
    stage_id: [],
    task_name: "",
  });

  // useEffect(() => {
  //   console.log("Form data: ", formData);
  //   console.log("cs: ", children_stages);
  // }, [formData, setFormData]);

  return (
    <>
      <Form
        form={formTaskDetails}
        name="task_details"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onTaskDetailsFinish}
        onFinishFailed={onTaskDetailsFinishFailed}
      >
        {/* <Form.Item
          name="task_name"
          label="Task Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the Task Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input placeholder="Task Name" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item> */}

        {/* <Form.Item
                    name="project_id"
                    label="Select Projects"
                    rules={[{ required: true, message: 'Please select a Project !' }]}
                    className="input-field">
                    <Select
                        allowClear
                        placeholder="Please Select Projects"
                        onChange={handleProjChange}
                    >
                        {children}
                    </Select>
                </Form.Item> */}

        {/* <Form.Item
                    name="project_details_id"
                    label="Select Project Details"
                    className="input-field"
                    rules={[
                        {
                            required: true,
                            message: 'Please select one or more  Project Details',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        placeholder="Please select Select Project Details"
                    >
                        {children_projDetail}
                    </Select>
                </Form.Item> */}

        {/* <Form.Item
                    name="supervisor_id"
                    label="Select Supervisor"
                    className="input-field"
                    rules={[
                        {
                            required: true,
                            message: 'Please select one or more Supervisors !',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select Supervisors"
                    >
                        {children_supervisor}
                    </Select>
                </Form.Item> */}

        {/* <Form.Item
          name="stage_id"
          label="Select Stages"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select one or more Stages !",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            onFocus={allStages}
            style={{ width: "100%" }}
            placeholder="Please select Stages"
          >
            {children_stages}
          </Select>
        </Form.Item> */}

        {/* <Form.Item>
          <Button
            type="primary"
            className="btn-submit"
            style={{ borderRadius: "10px", height: "35px", float: "right" }}
            onClick={openStage}
          >
            Create Stage <PlusCircleOutlined />
          </Button>
        </Form.Item> */}

        {/* <Form.Item
                            name="stage-name"
                            label="Upload Help"
                            className="input-field"
                        >
                            <Upload name="file" >
                                <Button icon={<UploadOutlined />} >Choose file</Button>
                            </Upload>
                        </Form.Item> */}

        {/* <Form.Item>
          <div className="btn-project">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
              // className="button-3"
            >
              <strong>Submit</strong>
            </Button>

            <Button
              onClick={clear_field2}
              className="btn-cancel"
              //  className="button-reset"
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item> */}

        <Modal visible={openModal} footer={null} onCancel={handleCancelmodal}>
          <StageForm />
        </Modal>
      </Form>

      <form className="font-montserrat" onSubmit={onTaskDetailsFinish}>
        {/* 1a */}
        <div className="flex  justify-start ml-10 gap-x-10 w-[90vw]">
          {/* 1 */}
          <div className="">
            <label
              htmlFor="task_name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Task Name
            </label>
            <div className="w-[300px]">
              <input
                required
                // disabled
                type="text"
                title="Please enter text only"
                name="task_name"
                // value={task_name.rfi_code}
                onChange={(e) => {
                  setFormData({ ...formData, task_name: e.target.value });
                }}
                // pattern="[0-9]*"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
          {/* 2  */}
          <div className="mt w-[300px]">
            <label
              htmlFor="stage_id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Select Stage(Multiple)
            </label>
            <select
              required
              name="stage_id"
              // value={formData.project_id}
              // onChange={handleInputChange}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  stage_id: [...formData.stage_id, e.target.value],
                });
              }}
              onFocus={allStages}
              className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
            >
              <option  value="">
                Select
              </option>
              {children_stages &&
                children_stages.map((stage) => (
                  <option
                    value={stage.props.children[2]}
                    key={stage.props.children[2]}
                  >
                    {stage.props.children[0]}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {/* buttons  */}
        <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
          <button
            // onClick={resetAllValue}
            type="button"
            class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"

            data-te-ripple-init
          >
            Reset
          </button>
          <button
            type="submit"
            data-te-ripple-init
            data-te-ripple-color="light"
            class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default TaskForm;
