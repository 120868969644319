import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Select } from "antd";
import "../css/ShowMaterialStock.css";
import WithOutPoMaterialRecieveCreateFrom from "./WithOutPoMaterialRecieveCreateFrom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorGetInvoiceWithOutPo,
  selectorGetSiteTransferData,
  selectorGetReplacePoItemsData,
  selectorGetSiteTransferDataGrnCreate,
} from "../../../../redux-core/materials/selectors";
import MaterialQtySiteTransferForm from "./MaterialQtySiteTransferForm";
import { EyeOutlined } from "@ant-design/icons";
import ViewTransferItemsFroms from "./ViewTransferItemsFroms";
import {
  getAllSiteTransferItem,
  getPoReplacementItemItems,
  getSiteTransferDataTransferNumberWise,
  getCountGrnNumber,
  getCountTarnsferNumber,
  getAllInvoiceWithOutPo,
} from "../../../../redux-core/materials/actions";
import PoItemsReplaceFrom from "./PoItemsReplaceFrom";
import PoReplaceItemsFrom from "./PoReplaceItemsFrom";
import CreateSiteTransferItemGrn from "./CreateSiteTransferItemGrn";
import { selectorCountGrnNumber } from "../../../../redux-core/materials/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { IoIosAdd } from "react-icons/io";
import { FaEye, FaFileInvoice } from "react-icons/fa";

const actionDispatch = (dispatch) => ({
  getAllSiteTransferItem: (transferNumber) =>
    dispatch(getAllSiteTransferItem(transferNumber)),
  getSiteTransferDataTransferNumberWise: (transferNumber) =>
    dispatch(getSiteTransferDataTransferNumberWise(transferNumber)),
  getPoReplacementItemItems: (replaceGrnNo) =>
    dispatch(getPoReplacementItemItems(replaceGrnNo)),
  getCountGrnNumber: (projectID) => dispatch(getCountGrnNumber(projectID)),
  getProjectByprojectId: (projectID) =>
    dispatch(getProjectByprojectId(projectID)),
  getCountTarnsferNumber: (projectID) =>
    dispatch(getCountTarnsferNumber(projectID)),
  getAllInvoiceWithOutPo: (projectID) =>
    dispatch(getAllInvoiceWithOutPo(projectID)),
});

const ShowReceiptMaterials = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [siteTransferModalVisible, setSiteTransferModalVisible] =
    useState(false);
  const getInvoiceWithOutPo = useSelector(selectorGetInvoiceWithOutPo);
  const GetSiteTransferData = useSelector(selectorGetSiteTransferData);
  const showReplacePoItemsData = useSelector(selectorGetReplacePoItemsData);
  const GetSiteTransferDataForGrn = useSelector(
    selectorGetSiteTransferDataGrnCreate
  );
  const [showTransferItems, setShowTransferItems] = useState(false);
  const {
    getAllSiteTransferItem,
    getPoReplacementItemItems,
    getSiteTransferDataTransferNumberWise,
    getCountGrnNumber,
    getProjectByprojectId,
    getCountTarnsferNumber,
    getAllInvoiceWithOutPo,
  } = actionDispatch(useDispatch());
  const [showReplacement, setShowReplacement] = useState(false);
  const [showReplacementItem, setShowReplacementItem] = useState(false);
  const [showSiteTransferGrn, setShowSiteTransferGrn] = useState(false);
  const [passTransferNumber, setPassTransferNumber] = useState();
  const countGrnNumber = useSelector(selectorCountGrnNumber);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [showUplodatPo, setShowUploadPo] = useState();

  useEffect(() => {
    getCountGrnNumber(props.projectID);
    getProjectByprojectId(props.projectID);
    getCountTarnsferNumber(props.projectID);
    getAllInvoiceWithOutPo(props.projectID);
  }, [props.projectID]);

  const TableSiteTransfer = [];
  for (let i = 0; i < GetSiteTransferDataForGrn?.length; i++) {
    let dueDate = new Date(GetSiteTransferDataForGrn[i].transfer_date);
    //dueDate.setDate(dueDate.getDate() + 1);
    TableSiteTransfer.push({
      id: GetSiteTransferDataForGrn[i].id,
      project_name: GetSiteTransferDataForGrn[i].project_name,
      transfer_number: GetSiteTransferDataForGrn[i].transfer_number,
      transfer_date: dueDate.toLocaleDateString("en-GB"),
    });
  }

  const Tabledata = [];
  for (let i = 0; i < GetSiteTransferData?.length; i++) {
    let dueDate = new Date(GetSiteTransferData[i].transfer_date);
    //dueDate.setDate(dueDate.getDate() + 1);
    Tabledata.push({
      id: GetSiteTransferData[i].id,
      project_name: GetSiteTransferData[i].project_name,
      transfer_number: GetSiteTransferData[i].transfer_number,
      transfer_date: dueDate.toLocaleDateString("en-GB"),
    });
  }

  const data = [];
  for (let i = 0; i < getInvoiceWithOutPo?.length; i++) {
    let dueDate = new Date(getInvoiceWithOutPo[i].invoice_date);
    //dueDate.setDate(dueDate.getDate() + 1);
    data.push({
      id: getInvoiceWithOutPo[i].id,
      invoice_number: getInvoiceWithOutPo[i].invoice_number,
      invoice_date: dueDate.toLocaleDateString("en-GB"),
      suppiler_name: getInvoiceWithOutPo[i].suppiler_name,
      suppiler_number: getInvoiceWithOutPo[i].suppiler_number,
      supplier_address: getInvoiceWithOutPo[i].supplier_address,
      gst_number: getInvoiceWithOutPo[i].gst_number,
      taxable_value: getInvoiceWithOutPo[i].taxable_value,
      gst_value: getInvoiceWithOutPo[i].gst_value,
      total_value: getInvoiceWithOutPo[i].total_value,
      upload_invoice: getInvoiceWithOutPo[i]?.upload_invoice,
    });
  }

  const DataTable = [];
  for (let i = 0; i < showReplacePoItemsData?.length; i++) {
    let dueDate = new Date(showReplacePoItemsData[i].replace_date);
    // dueDate.setDate(dueDate.getDate() + 1);
    DataTable.push({
      id: showReplacePoItemsData[i].id,
      replace_grn_no: showReplacePoItemsData[i].replace_grn_no,
      po_number: showReplacePoItemsData[i].po_number,
      replace_date: dueDate.toLocaleDateString("en-GB"),
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
    },
    {
      title: "Invoice",
      dataIndex: "upload_invoice",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => handePdfView(text)}>
              <EyeOutlined />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
    },
    {
      title: "Supplier Name",
      dataIndex: "suppiler_name",
    },
    {
      title: "Invoice Taxable Amount",
      dataIndex: "taxable_value",
    },
    {
      title: "Invoice GST Amount",
      dataIndex: "gst_value",
    },
    {
      title: "Invoice Total Amount",
      dataIndex: "total_value",
    },
  ];

  const handePdfView = (value) => {
    setShowUploadPo(value);
    setOpenPdfModal(true);
  };

  const viewSiteTransferItems = (value) => {
    setShowTransferItems(true);
    getAllSiteTransferItem(value.transfer_number);
  };

  const columns2 = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Transfer Items",
      dataIndex: "transfer_number",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              className="button-29"
              onClick={() => viewSiteTransferItems(record)}
            >
              View Transfer Items{" "}
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Transfer Number",
      dataIndex: "transfer_number",
    },
    {
      title: "Transfer Project",
      dataIndex: "project_name",
    },
    {
      title: "Transfer Date",
      dataIndex: "transfer_date",
    },
  ];
  const handelWithOutPoModalOpen = () => {
    setIsModalVisible(true);
  };

  const HandelSiteTransfer = () => {
    setSiteTransferModalVisible(true);
  };

  const HandelPoItemsReplace = () => {
    setShowReplacement(true);
  };

  const viewReplaceItems = (value) => {
    setShowReplacementItem(true);
    getPoReplacementItemItems(value.replace_grn_no);
  };

  const columns3 = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "View Replace Item",
      dataIndex: "replace_grn_no",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              className="button-29"
              onClick={() => viewReplaceItems(record)}
            >
              View Replace Item{" "}
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Replace GRN Number",
      dataIndex: "replace_grn_no",
    },
    {
      title: "Replace Date",
      dataIndex: "replace_date",
    },
    {
      title: "Purchase Order Number",
      dataIndex: "po_number",
    },
  ];

  const HandelSiteTransferItemsGrn = (value) => {
    setShowSiteTransferGrn(true);
    getAllSiteTransferItem(value.transfer_number);
    getSiteTransferDataTransferNumberWise(value.transfer_number);
    setPassTransferNumber(value.transfer_number);
  };

  const columns4 = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Transfer Items",
      dataIndex: "transfer_number",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              className="button-29"
              onClick={() => HandelSiteTransferItemsGrn(record)}
            >
              View Transfer Items{" "}
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Transfer Number",
      dataIndex: "transfer_number",
    },
    {
      title: "Recieve Project",
      dataIndex: "project_name",
    },
    {
      title: "Transfer Date",
      dataIndex: "transfer_date",
    },
  ];
  const modalClose = () => {
    setIsModalVisible(false);
    setSiteTransferModalVisible(false);
    setShowTransferItems(false);
    setShowReplacement(false);
    setShowReplacementItem(false);
    setShowSiteTransferGrn(false);
    setOpenPdfModal(false);
  };
  const { Option } = Select;
  const [cardType, setCardType] = useState("");
  const handleCardTypeChange = (value) => {
    setCardType(value);
  };

  const backToAllProject = () => {
    props.backProject();
  };

  useEffect(() => {
    console.log("Data: ", data);
  }, [data]);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  function formatNumberWithCommas(number) {
    if (typeof number === "string") {
      const parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
        return parsedNumber.toLocaleString("en-US");
      } else {
        return "-";
      }
    } else if (typeof number === "number") {
      return number.toLocaleString("en-US");
    } else {
      return "-";
    }
  }

  useEffect(() => {
    console.log("TableSiteTransfer: ", TableSiteTransfer);
  }, [TableSiteTransfer]);

  return (
    <>
      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="   font-montserrat flex flex-col gap-y-2">
            <button
              onClick={() => backToAllProject()}
              type="button"
              class="flex  w-fit justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              All Projects
            </button>

            {cardType === "without_po" && (
              <button
                onClick={handelWithOutPoModalOpen}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                New Local Purchase Entry
              </button>
            )}
            {cardType === "item_replace" && (
              <button
                onClick={HandelPoItemsReplace}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Po Items Replacement
              </button>
            )}
            {cardType === "site_transfer" && (
              <button
                onClick={HandelSiteTransfer}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Item Site Transfer
              </button>
            )}
          </div>
          <div className=" font-semibold  text-2xl">
            Select the way of material manage Process
          </div>
          <div className=" font-montserrat">
            <div className="mt w-[300px]">
              <label
                htmlFor=""
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Select the process
              </label>
              <select
                // required
                name=""
                placeholder="Select the process"
                // value={formData.po_status}
                onChange={(e) => {
                  setCardType(e.target.value);
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option defaultChecked value="">
                  Select
                </option>
                <option value="without_po">Local Purchase</option>
                <option value="item_replace">Po Items Replacement</option>
                <option value="site_transfer">Item Site Transfer</option>
                <option value="site_transfer_grn_create">
                  Item Site Transfer GRN Create
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="title-show-project">
        Select the way of material manage Process
      </div> */}
      {/* <Button style={{ float: "right" }} onClick={() => backToAllProject()}>
        All Projects
      </Button>
      <br />
      <Select
        onChange={handleCardTypeChange}
        className="input-field"
        placeholder="Select The Process"
      >
        <Option value="without_po">Local Purchase</Option>
        <Option value="item_replace">Po Items Replacement</Option>
        <Option value="site_transfer">Item Site Transfer</Option>
        <Option value="site_transfer_grn_create">
          Item Site Transfer GRN Create
        </Option>

        // old btns 
      </Select> */}
      {/* <div className="BtnContainer">
        {cardType === "without_po" && (
          <Button className="button-61" onClick={handelWithOutPoModalOpen}>
            <b>New Local Purchase Entry</b>
          </Button>
        )}
        {cardType === "item_replace" && (
          <Button className="button-61" onClick={HandelPoItemsReplace}>
            <b>Po Items Replacement </b>
          </Button>
        )}
        {cardType === "site_transfer" && (
          <Button className="button-61" onClick={HandelSiteTransfer}>
            <b>Item Site Transfer</b>
          </Button>
        )}
      </div> */}

      {/* tables  */}
      {cardType === "without_po" && (
        // <Table
        //   scroll={{ x: "100vw" }}
        //   columns={columns}
        //   dataSource={data}
        //   pagination={10}
        // />

        // new table shibvam kashyap 7 june 2024
        // <div className=" font-montserrat mt-5  ml-4    flex flex-col items-center  justify-center -green-300">
        //   <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
        //     <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
        //       {/* Header Content */}
        //       <div className="flex flex-col items-start -yellow-200 w-[150px]">
        //         <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">ID</p>
        //       </div>

        //       <div className="flex flex-col blue-200 items-start w-[250px]">
        //         <p className="text-sm font-semibold text-gray-900 w-full">
        //           {" "}
        //           Invoice Number
        //         </p>
        //         <p className="mt-1 text-xs  leading-5 text-gray-500 w-full bg-pink-200">
        //           Invoice Date
        //         </p>
        //       </div>
        //       <div className="flex flex-col blue-200 items-start  w-[150px] ">
        //         <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">Invoice</p>
        //       </div>

        //       <div className="flex flex-col blue-200 items-start w-[200px]">
        //         <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">
        //           Supplier Name
        //         </p>
        //       </div>
        //       <div className="flex flex-col blue-200 items-start w-[250px]">
        //         <p className="text-sm font-semibold text-gray-900">
        //           {" "}
        //           Invoice GST Amount
        //         </p>
        //         <p className="mt-1 text-xs  leading-5 text-gray-500 w-full bg-pink-200 w-full bg-pink-200">
        //           Invoice Taxable Amount
        //         </p>
        //       </div>

        //       <div className="flex richblue-200 flex-col items-start w-[250]">
        //         <p className="text-sm  font-semibold text-gray-900 w-full bg-pink-200">
        //           Invoice Total Amount
        //         </p>
        //       </div>
        //     </li>
        //   </ul>{" "}
        //   <ul
        //     role="list"
        //     className="divide-y w-[1200px]  divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
        //   >
        //     {data && data.length
        //       ? data.map((item, key) => (
        //           <li
        //             className={`flex justify-between ${
        //               key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
        //             }  items-start px-5  py-2`}
        //           >
        //             {/* Header Content */}
        //             <div className="flex flex-col items-start -yellow-200 w-[150px]">
        //               <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">
        //                 {" "}
        //                 {item?.id ? item.id : "-"}
        //               </p>
        //             </div>

        //             <div className="flex flex-col blue-200 items-start w-[250px]">
        //               <p className="text-sm font-semibold text-gray-900 w-full b">
        //                 {" "}
        //                 {item?.invoice_number ? item.invoice_number : "-"}
        //               </p>
        //               <p className="mt-1 text-xs  leading-5 text-gray-500 w-full bg-pink-200">
        //                 {item?.invoice_date ? item.invoice_date : "-"}
        //               </p>
        //             </div>
        //             <div className="flex flex-col blue-200 items-start  w-[150px]">
        //               <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">
        //                 Invoice
        //               </p>
        //             </div>

        //             <div className="flex flex-col blue-200 items-start w-[200px]">
        //               <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">
        //                 {item?.suppiler_name ? item.suppiler_name : "-"}
        //               </p>
        //             </div>
        //             <div className="flex flex-col blue-200 items-start w-[250px]">
        //               <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">
        //                 {" "}
        //                 {item?.gst_value ? item.gst_value : "-"}
        //               </p>
        //               <p className="mt-1 text-xs  leading-5 text-gray-500 w-full bg-pink-200">
        //                 {item?.taxable_value ? item.taxable_value : "-"}
        //               </p>
        //             </div>

        //             <div className="flex flex-col blue-200 items-start w-[250px]">
        //               <p className="text-sm font-semibold text-gray-900 w-full bg-pink-200">
        //                 {" "}
        //                 {item?.total_value ? item.total_value : "-"}
        //               </p>
        //             </div>
        //           </li>
        //         ))
        //       : "No records found..."}
        //   </ul>
        // </div>

        <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
          {/* Header Section */}
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
              <div className="flex flex-col items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900 w-full">ID</p>
              </div>

              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Invoice Number
                </p>
                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  Invoice Date
                </p>
              </div>

              <div className="flex flex-col items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Invoice
                </p>
              </div>

              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Supplier Name
                </p>
              </div>

              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Invoice GST Amount
                </p>
                <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                  Invoice Taxable Amount
                </p>
              </div>

              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Invoice Total Amount
                </p>
              </div>
            </li>
          </ul>

          {/* Content Section */}
          <ul
            role="list"
            className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh]"
          >
            {data && data.length
              ? data.map((item, key) => (
                  <li
                    key={key}
                    className={`flex justify-between items-center px-5 py-2 ${
                      key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }`}
                  >
                    <div className="flex flex-col items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.id ? item.id : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.invoice_number ? item.invoice_number : "-"}
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.invoice_date
                          ? formatDate(item.invoice_date)
                          : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[150px]">
                      <a
                        onClick={() => handePdfView(item.upload_invoice)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Invoice"
                      >
                        <FaFileInvoice
                          className=" text-top_nav_blue-300 "
                          size={18}
                        />
                      </a>
                    </div>

                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.suppiler_name ? item.suppiler_name : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.gst_value
                          ? formatNumberWithCommas(item.gst_value)
                          : "-"}
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                        {item?.taxable_value
                          ? formatNumberWithCommas(item.taxable_value)
                          : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.total_value
                          ? formatNumberWithCommas(item.total_value)
                          : "-"}
                      </p>
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>
      )}
      {cardType === "item_replace" && (
        // <Table
        //   scroll={{ x: "70vw" }}
        //   columns={columns3}
        //   dataSource={DataTable}
        //   pagination={10}
        // />

        // new table shivam kashyap - 11 june 2024
        <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
              <div className="flex flex-col items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900 w-full">ID</p>
              </div>

              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  View Replace Item
                </p>
              </div>

              <div className="flex flex-col items-start w-[350px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Replace GRN Number
                </p>
              </div>

              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Replace Date
                </p>
              </div>

              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Purchase Order Number
                </p>
              </div>
            </li>
          </ul>
          {/* Content Section */}
          <ul
            role="list"
            className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh]"
          >
            {DataTable && DataTable.length
              ? DataTable.map((item, key) => (
                  <li
                    key={key}
                    className={`flex justify-between items-center px-5 py-2 ${
                      key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }`}
                  >
                    <div className="flex flex-col items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.id ? item.id : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[200px]">
                      <a
                        onClick={() => viewReplaceItems(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="View Replace Item"
                      >
                        <FaEye
                          className=" text-top_nav_blue-300 "
                          size={18}
                          // onClick={() => deleteRow(item)}
                        />
                      </a>{" "}
                    </div>

                    <div className="flex flex-col items-start w-[350px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.replace_grn_no ? item.replace_grn_no : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.replace_date
                          ? formatDate(item.replace_date)
                          : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.po_number ? item.po_number : "-"}
                      </p>
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>
      )}
      {cardType === "site_transfer" && (
        <div className=" flex m-0 p-0 flex-col">
          {/* <Table
            scroll={{ x: "60vw" }}
            columns={columns2}
            dataSource={Tabledata}
            pagination={10}
          /> */}
          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    ID
                  </p>
                </div>

                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Items
                  </p>
                </div>

                <div className="flex flex-col items-start w-[350px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Number
                  </p>
                </div>

                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Project
                  </p>
                </div>

                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Date
                  </p>
                </div>
              </li>
            </ul>{" "}
            {/* Content Section */}
            <ul
              role="list"
              className="divide-y w-[1215px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] ml-[15px]"
            >
              {Tabledata && Tabledata.length
                ? Tabledata.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.id ? item.id : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col items-start w-[200px]">
                        <a
                          onClick={() => viewSiteTransferItems(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="View Transfer Item"
                        >
                          <FaEye
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={() => deleteRow(item)}
                          />
                        </a>{" "}
                      </div>

                      <div className="flex flex-col items-start w-[350px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.transfer_number ? item.transfer_number : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.project_name ? item.project_name : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.transfer_date
                            ? formatDate(item.transfer_date)
                            : "-"}
                        </p>
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>
        </div>
      )}
      {/* to be completed after data comes in table - shivam kashyap 19 june 2024 */}
      {cardType === "site_transfer_grn_create" && (
        <div className=" flex m-0 p-0 flex-col">
          {/* <Table
            scroll={{ x: "60vw" }}
            columns={columns4}
            dataSource={TableSiteTransfer}
            pagination={10}
          /> */}
          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    ID
                  </p>
                </div>

                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Items
                  </p>
                </div>

                <div className="flex flex-col items-start w-[350px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Number
                  </p>
                </div>

                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Recieve Project
                  </p>
                </div>

                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Transfer Date
                  </p>
                </div>
              </li>
            </ul>{" "}
          </div>
        </div>
      )}

      {/* modals  */}
      <Modal
        visible={
          isModalVisible === true
            ? isModalVisible
            : siteTransferModalVisible === true
            ? siteTransferModalVisible
            : showTransferItems === true
            ? showTransferItems
            : showReplacement === true
            ? showReplacement
            : showReplacementItem === true
            ? showReplacementItem
            : showSiteTransferGrn
        }
        onCancel={modalClose}
        // width={1950}
        width={1350}
        footer={null}
      >
        {isModalVisible === true ? (
          <WithOutPoMaterialRecieveCreateFrom projectID={props.projectID} />
        ) : siteTransferModalVisible === true ? (
          <MaterialQtySiteTransferForm projectID={props.projectID} />
        ) : showTransferItems === true ? (
          <ViewTransferItemsFroms />
        ) : showReplacement === true ? (
          <PoItemsReplaceFrom projectID={props.projectID} />
        ) : showReplacementItem === true ? (
          <PoReplaceItemsFrom />
        ) : (
          showSiteTransferGrn && (
            <CreateSiteTransferItemGrn
              projectID={props.projectID}
              TransferNumber={passTransferNumber}
            />
          )
        )}
      </Modal>

      <Modal
        open={openPdfModal}
        footer={null}
        width={1500}
        onCancel={modalClose}
      >
        <iframe
          className={"application/pdf"}
          width="100%"
          height="700"
          frameborder="10"
          src={showUplodatPo}
        />
      </Modal>
    </>
  );
};

export default ShowReceiptMaterials;
