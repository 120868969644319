import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Table } from "antd";
import "../css/WithOutPoInvoceCreateFrom.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllProj } from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  makeSelectAllProj,
  selectorGetProjByProjId,
} from "../../../../redux-core/settings/selectors";
import {
  getCurrentStockProjectIdWise,
  createSiteTransfer,
  insertSiteTransferItemsInStock,
  insertSiteTransferItems,
  getAllSiteTransferData,
  updateStockInSiteTransferQty,
  getCountTarnsferNumber,
} from "../../../../redux-core/materials/actions";
import {
  selectorGetItemInCurrentStockProjectWise,
  selectorCountTransferNumber,
} from "../../../../redux-core/materials/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import toast from "react-hot-toast";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getAllProject: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getCurrentStockProjectIdWise: (projeID) =>
    dispatch(getCurrentStockProjectIdWise(projeID)),
  getAllSiteTransferData: (projeID) =>
    dispatch(getAllSiteTransferData(projeID)),
  createSiteTransfer: (transferData) =>
    dispatch(createSiteTransfer(transferData)),
  insertSiteTransferItems: (transferItems) =>
    dispatch(insertSiteTransferItems(transferItems)),
  insertSiteTransferItemsInStock: (transferItems) =>
    dispatch(insertSiteTransferItemsInStock(transferItems)),
  updateStockInSiteTransferQty: (transferItems) =>
    dispatch(updateStockInSiteTransferQty(transferItems)),
  getProjectByprojectId: (projeID) => dispatch(getProjectByprojectId(projeID)),
  getCountTarnsferNumber: (projeID) =>
    dispatch(getCountTarnsferNumber(projeID)),
});

function MaterialQtySiteTransferForm(props) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const {
    getAllProject,
    getCurrentStockProjectIdWise,
    createSiteTransfer,
    insertSiteTransferItems,
    getAllSiteTransferData,
    insertSiteTransferItemsInStock,
    updateStockInSiteTransferQty,
    getProjectByprojectId,
    getCountTarnsferNumber,
  } = actionDispatch(useDispatch());
  const allProjects = useSelector(makeSelectAllProj);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCurrentProj, setsSlectedCurrentProj] = useState(null);
  const [selectedTransferProj, setSelectedTransferProj] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState();
  const [transferNumber, setTransferNumber] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const countTransferNumber = useSelector(selectorCountTransferNumber);

  useEffect(() => {
    getAllProject(orgId, userId);
    getCurrentStockProjectIdWise(props.projectID);
    getProjectByprojectId(props.projectID);
    getCountTarnsferNumber(props.projectID);
  }, []);

  const [itemFormData, setItemFormData] = useState({
    item_code: "",
    item_id: "",
    remark: "",
    total_qty: "",
    transfer_qty: "",
  });

  //--------write the functionality for give search option in select dropdown---------------------
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // ----------write here date picker Function for string to nemerial convert ------------
  const handleTransferDate = (value, dateString) => {
    //setSelectedDate(dateString);
  };

  const itemCode = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.item_code);

  const itemQty = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.total_qty);

  const transferQty = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.transfer_to_other_site);

  const itemName = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.Item_name);

  const itemId = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.id);

  const item_rate = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.item_rate);

  const transfer_value = getShowItemInCurrentStockProjectWise
    .filter((item) => item.id == itemFormData.item_id)
    .map((item) => item.transfer_value);

  // avarage of select item value
  const avarageValue = Number(item_rate?.[0]) / Number(itemQty[0]);

  //-------UseEffect use for input initialvalue
  useEffect(() => {
    form2.setFieldsValue({
      item_code: itemCode[0],
      total_qty: itemQty[0],
      transfer_number: transferNumber,
    });

    console.log(
      "Codeeeeeeeeeeeeee: ",
      itemCode,
      itemQty,
      transferNumber,
      "ID=: ",
      selectedItemId
    );

    setItemFormData({
      ...itemFormData,
      item_code: itemCode[0],
      total_qty: itemQty[0],
      transfer_number: transferNumber,
    });
  }, [itemCode[0], itemQty[0], transferNumber, itemFormData.item_id]);

  //reset all Input Field
  const resetAllValue = () => {
    form.resetFields();
    form2.resetFields();

    setIsDisable(false);
    setShowItems(false);
    getCountTarnsferNumber(props.projectID);
  };

  let count_number =
    countTransferNumber[0].no_of_Transfer_no === 0
      ? "001"
      : (Number(countTransferNumber[0].no_of_Transfer_no) + 1)
          .toString()
          .padStart(3, "0");
  const TRANSFER_NUMBER =
    "IST/" + getProjectByProjId[0].project_code + "/" + count_number;

  useEffect(() => {
    form.setFieldsValue({
      transfer_number: TRANSFER_NUMBER,
    });

    setFormData({
      ...formData,
      transfer_number: TRANSFER_NUMBER,
    });
  }, [TRANSFER_NUMBER]);

  //-------------------Handel submit action write here for form 1-----------------
  const onProjectSelect = async (e) => {
    e.preventDefault();
    console.log("values: ", formData);
    // return;
    setsSlectedCurrentProj(formData.current_project_id);
    setSelectedTransferProj(formData.transfer_project_id);
    setTransferNumber(formData.transfer_number);
    setSelectedDate(formData.transfer_date);
    const transferProject = {
      current_project_id: formData.current_project_id,
      transfer_project_id: formData.transfer_project_id,
      transfer_date: formData.transfer_date,
      transfer_number: formData.transfer_number,
      orgID: orgId,
      created_by: userId,
    };
    let response = await createSiteTransfer(transferProject);
    if (response) {
      if (response.success === true) {
        toast.success("Success..");
        setIsDisable(true);
        setShowItems(true);
        getAllSiteTransferData(props.projectID);
      } else {
        toast.error("Failed..");
        setIsDisable(false);
        setShowItems(false);
      }
    }
  };

  const [formData, setFormData] = useState({
    current_project_id: "",
    transfer_date: "",
    transfer_number: "",
    transfer_project_id: "",
  });

  //-----------Handel Submit action write here for form 2-------------------
  const HandelSiteTransferItem = async (e) => {
    e.preventDefault();
    console.log("final values: ", itemFormData);
    if (itemFormData.total_qty < Number(itemFormData.transfer_qty)) {
      toast.error("Qty can not excede total Qty");
      return;
    }
    let transferItemValue =
      Number(avarageValue) * Number(itemFormData.transfer_qty);

    const transferItem = {
      current_project_id: selectedCurrentProj,
      transfer_project_id: selectedTransferProj,
      transfer_date: selectedDate,
      item_id: itemFormData.item_id,
      item_code: itemFormData.item_code,
      current_qty: itemFormData.total_qty,
      transfer_qty: itemFormData.transfer_qty,
      remark: itemFormData.remark,
      orgID: orgId,
      created_by: userId,
      transfer_number: transferNumber,
      item_rate: transferItemValue,
    };
    const response = await insertSiteTransferItems(transferItem);

    const Items = {
      item_code: itemCode[0],
      Item_name: itemName[0],
      project_id: selectedTransferProj,
    };

    const updateItems = {
      id: itemId[0],
      total_qty: Number(itemQty[0]) - Number(itemFormData.transfer_qty),
      transfer_to_other_site:
        Number(transferQty[0]) + Number(itemFormData.transfer_qty),
      item_rate: Number(item_rate?.[0]) - Number(transferItemValue),
      transfer_value: Number(transfer_value?.[0]) + Number(transferItemValue),
    };
    if (response) {
      if (response.success === true) {
        toast.success("Success..");
        await insertSiteTransferItemsInStock(Items);
        await updateStockInSiteTransferQty(updateItems);
      }
    }
  };

  useEffect(() => {
    console.log("itemFormData: ", itemFormData);
  }, [itemFormData, setItemFormData]);

  return (
    <>
      <div className=" font-montserrat">
        {/* <Form
          name="site_transfer"
          form={form}
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onProjectSelect}
        >
          <Form.Item
            name="current_project_id"
            label="Current Project"
            rules={[{ required: true, message: "Please select Project!" }]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Project Name"
              // onChange={(itemID) => setSelectedItemId(itemID)}
              // filterOption={handleSearch}
              disabled={isDisable}
            >
              {allProjects
                .filter((item) => item.project_id === props.projectID)
                .map((item) => (
                  <Option key={item.project_id} value={item.project_id}>
                    {item.project_name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="transfer_project_id"
            label="Tranfer Project"
            rules={[{ required: true, message: "Please select Project!" }]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Project Name"
              //  onChange={(projID) => setSelectedProjectId(projID)}
              filterOption={handleSearch}
              disabled={isDisable}
            >
              {allProjects.map((getAllProject) => (
                <Option
                  key={getAllProject.project_id}
                  value={getAllProject.project_id}
                >
                  {getAllProject.project_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="transfer_date"
            label="Transfer Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Invoice Date",
              },
            ]}
          >
            <DatePicker
              name="transfer_date"
              format="DD-MM-YYYY"
              onChange={handleTransferDate}
              placeholder="Enter Date"
              disabled={isDisable}
            />
          </Form.Item>
          <Form.Item
            name="transfer_number"
            label="Transfer Number Create"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Transfer Number Create",
              },
            ]}
            initialValue={TRANSFER_NUMBER}
          >
            <Input placeholder="Enter Transfer Number Create" disabled />
          </Form.Item>

          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
                disabled={isDisable}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form> */}

        {/* new form shivam kashyap 13 june 2024  */}
        <div className=" font-semibold  text-2xl mb-4">
          New Item Site Transfer
        </div>

        <form
          className="font-montserrat flex flex-col items-center  gap-y-3"
          onSubmit={onProjectSelect}
        >
          {/* 1a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 */}
            <div className=" cursor-not-allowed">
              <label
                htmlFor="transfer_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Transfer Number Create.
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="transfer_number"
                  value={formData.transfer_number}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transfer_number: e.target.value,
                    });
                  }}
                  disabled
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="current_project_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Current Project
              </label>
              <select
                required
                name="current_project_id"
                value={formData.current_project_id}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    current_project_id: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option defaultChecked value="">
                  Select
                </option>
                {allProjects
                  .filter((item) => item.project_id === props.projectID)
                  .map((item) => (
                    <option key={item.project_id} value={item.project_id}>
                      {item.project_name}
                    </option>
                  ))}
              </select>
            </div>
            {/* 3 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="transfer_project_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Tranfer Project
              </label>
              <select
                required
                name="transfer_project_id"
                value={formData.transfer_project_id}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    transfer_project_id: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option defaultChecked value="">
                  Select
                </option>
                {allProjects.map((getAllProject) => (
                  <option
                    key={getAllProject.project_id}
                    value={getAllProject.project_id}
                  >
                    {getAllProject.project_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* 2a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 date */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="transfer_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Tranfer Date
              </label>
              <input
                required
                type="date"
                name="transfer_date"
                value={formData.transfer_date}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    transfer_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                // placeholder="Select a date"
              />
            </div>
            {/* 2 */}
            <div className="mt w-[300px]"></div>
            {/* 3 */}
            <div className="mt w-[300px]"></div>
          </div>

          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
            <button
              onClick={() => {
                setFormData({
                  ...formData,

                  current_project_id: "",
                  transfer_date: "",
                  transfer_project_id: "",
                });
                // setImageFile([]);
              }}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {showItems && (
        <div className=" font-montserrat">
          {/* <Form
            name="site_transfer_item"
            form={form2}
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={HandelSiteTransferItem}
          >
            <Form.Item
              name="item_id"
              label="Item Name"
              rules={[{ required: true, message: "Please select Item!" }]}
              className="input-field1"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Item Name"
                onChange={(itemID) => setSelectedItemId(itemID)}
                filterOption={handleSearch}
              >
                {getShowItemInCurrentStockProjectWise.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.Item_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="item_code"
              label="Item Code"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter item Code",
                },
              ]}
              initialValue={itemCode[0]}
            >
              <Input placeholder="Enter Item Code" disabled />
            </Form.Item>

            <Form.Item
              name="total_qty"
              label="Current QTY"
              className="input-field"
              initialValue={itemQty[0]}
            >
              <Input type="number" placeholder="Enter Item Qty" disabled />
            </Form.Item>

            <Form.Item
              name="transfer_qty"
              label="Transfer QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Transfer Item Qty",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const totalQty = getFieldValue("total_qty");
                    if (
                      !totalQty ||
                      parseFloat(value) <= parseFloat(totalQty)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Transfer Qty cannot exceed Current Qty"
                    );
                  },
                }),
              ]}
            >
              <Input type="number" placeholder="Enter Transfer Item  Qty" />
            </Form.Item>

            <Form.Item name="remark" label="Remark" className="input-field">
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}
          {/* <div className="btn-project">
            <Button
              onClick={() => resetAllValue()}
              className="btn-cancel"
              style={{ marginRight: 25 }}
            >
              <strong>Reset</strong>
            </Button>
          </div> */}
          {/* new table shivam kashyap 13 june 2024  */}

          <div className=" font-semibold  text-2xl mb-4">
            Select Item and Quantity for Site Transfer
          </div>
          <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={HandelSiteTransferItem}
          >
            {/* 1a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="item_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Item Name
                </label>
                <select
                  required
                  name="item_id"
                  value={itemFormData.item_id}
                  onChange={(e) => {
                    setItemFormData({
                      ...itemFormData,
                      item_id: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                >
                  <option defaultChecked value="">
                    Select
                  </option>
                  {getShowItemInCurrentStockProjectWise.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.Item_name}
                    </option>
                  ))}
                </select>
              </div>
              {/* 2 */}
              <div className=" cursor-not-allowed">
                <label
                  htmlFor="transfer_number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Item Code
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="transfer_number"
                    value={itemFormData.item_code}
                    onChange={(e) => {
                      setItemFormData({
                        ...itemFormData,
                        item_code: e.target.value,
                      });
                    }}
                    disabled
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 3 */}
              <div className=" cursor-not-allowed">
                <label
                  htmlFor="transfer_number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Current QTY
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="transfer_number"
                    value={itemFormData.total_qty}
                    onChange={(e) => {
                      setItemFormData({
                        ...itemFormData,
                        total_qty: e.target.value,
                      });
                    }}
                    disabled
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
            </div>
            {/* 2a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className=" cursor-not-allowed">
                <label
                  htmlFor="transfer_number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Transfer QTY
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="number"
                    name="transfer_number"
                    value={itemFormData.transfer_qty}
                    onChange={(e) => {
                      setItemFormData({
                        ...itemFormData,
                        transfer_qty: e.target.value,
                      });
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2 */}
              <div className=" cursor-not-allowed">
                <label
                  htmlFor="remark"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Remark
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="remark"
                    value={itemFormData.remark}
                    onChange={(e) => {
                      setItemFormData({
                        ...itemFormData,
                        remark: e.target.value,
                      });
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 3 */}
              <div className="mt w-[300px]"></div>
            </div>
            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
              <button
                onClick={() => {
                  setItemFormData({
                    item_code: "",
                    item_id: "",
                    remark: "",
                    total_qty: "",
                    transfer_qty: "",
                  });
                  // setImageFile([]);
                }}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default MaterialQtySiteTransferForm;
