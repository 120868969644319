import { Button, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectorGetEquipmentDeployList } from "../../../redux-core/resources/selectors";

// import { useDispatch, useSelector } from 'react-redux';
// import { getAllEquipmentDeployHeader } from '../../../redux-core/resources/actions';
// import { selectorGetEquipmentDeployList } from '../../../redux-core/resources/selectors';

const actionDispatch = (dispatch) => ({
  //  getEquipmentList:()=> dispatch(getAllEquipmentDeployHeader)
});

const ShowEquipmentDeployTable = () => {
  // const{getEquipmentList}= actionDispatch(useDispatch());
  const equipmentDeployList = useSelector(selectorGetEquipmentDeployList);
  // console.log("selector",selectorGetEquipmentDeployList)

  const tableData = [];
  for (let i = 0; i < equipmentDeployList.length; i++) {
    const startTime = equipmentDeployList[i]?.equipment_start_time;
    const endTime = equipmentDeployList[i]?.equipment_end_time;

    // Check if startTime and endTime are not null or undefined
    if (startTime && endTime) {
      const startTimeParts = startTime.split(":");
      const hours = parseInt(startTimeParts[0]);
      const minutes = parseInt(startTimeParts[1]);
      // console.log("hours",hours)

      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedStartTime = `${formattedHours}:${formattedMinutes} ${period}`;

      const endTimeParts = endTime.split(":");
      const hour = parseInt(endTimeParts[0]);
      const minute = parseInt(endTimeParts[1]);

      const periods = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      const formattedMinute = minute.toString().padStart(2, "0");
      const formattedEndTime = `${formattedHour}:${formattedMinute} ${periods}`;

      //console.log("formattedStartTime",formattedStartTime)
      // console.log("formattedEndTime",formattedEndTime)

      tableData.push({
        id: equipmentDeployList[i].id,
        project_id: equipmentDeployList[i].project_id,
        control_person_name: equipmentDeployList[i].control_person_name,
        equipment_name: equipmentDeployList[i].equipment_name,
        equipment_start_time: formattedStartTime,
        equipment_end_time: formattedEndTime,
        output_qnty: equipmentDeployList[i].output_qnty,
        equipment_start_readings:
          equipmentDeployList[i].equipment_start_readings,
        equipment_end_readings: equipmentDeployList[i].equipment_end_readings,
        fuel_consumed: equipmentDeployList[i].fuel_consumed,
        fuel_price: equipmentDeployList[i].fuel_price,
      });
    }
  }

  // console.log("tableData",tableData)

  const columns = [
    // {
    //   title: 'Equipment Deployment Header Id',
    //   dataIndex: 'equipment_deployment_header_id',
    // },
    {
      title: "Equipment control person Name",
      dataIndex: "control_person_name",
    },
    {
      title: "Equipment Name",
      dataIndex: "equipment_name",
    },
    {
      title: "Equipment Start Time",
      dataIndex: "equipment_start_time",
    },
    {
      title: "Equipment End Time",
      dataIndex: "equipment_end_time",
    },
    {
      title: "Output Qnty",
      dataIndex: "output_qnty",
    },
    {
      title: "Equipment Start Readings",
      dataIndex: "equipment_start_readings",
    },
    {
      title: "Equipment End Readings",
      dataIndex: "equipment_end_readings",
    },
    {
      title: "Fuel Consumed",
      dataIndex: "fuel_consumed",
    },
    {
      title: "Fuel Price",
      dataIndex: "fuel_price",
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    // }
  ];

  return (
    <>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={true}
        scroll={{ x: "90vw" }}
      />
      <br />
      {/* <Button 
        style={{textAlign:'center'}} 
        className='btn-cancel' 
        htmlType="submit" 
      //   onClick={PageReload}
        >Cancel</Button> */}
    </>
  );
};

export default ShowEquipmentDeployTable;
