// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import "antd/dist/antd.css"; */
/* body{
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji'
} */

.title__settings{
    font-size: 30px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* color: bisque; */
    position: relative;
    top:6px;
    
}
.menuicon{
    font-size: x-large;
}

.home-app span{
    font-size: x-large;
    margin:20px;
}
.main-div{
    display:block;
    text-align:center;
    /* position: fixed; */
}


@media screen and (max-width: 600px){
    .main-div{
        text-align:right;
    }
    .title{
        font-size: .5em;
    }
    .expand{
        padding-left: 50px;
        padding-top: 2px;
    }
}

@media screen and (max-width:425px){
    .main-div{
        text-align:center
    }
    .title__settings{
        position: relative;
        top:6px;
        font-size: 17px;
        /* display: flex; */
    }
    .expand{
        padding-left: 50px;
        padding-top: 2px;
        display: flex;
    }
    .home-app span{
        font-size: x-large;
        margin:5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/settings/topnavbar/TopNavbar.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;;;;GAIG;;AAEH;IACI,eAAe;IACf,iBAAiB;IACjB,sEAAsE;IACtE,mBAAmB;IACnB,kBAAkB;IAClB,OAAO;;AAEX;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,qBAAqB;AACzB;;;AAGA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,eAAe;IACnB;IACA;QACI,kBAAkB;QAClB,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI;IACJ;IACA;QACI,kBAAkB;QAClB,OAAO;QACP,eAAe;QACf,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,gBAAgB;QAChB,aAAa;IACjB;IACA;QACI,kBAAkB;QAClB,UAAU;IACd;AACJ","sourcesContent":["/* @import \"antd/dist/antd.css\"; */\n/* body{\nfont-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n  'Noto Color Emoji'\n} */\n\n.title__settings{\n    font-size: 30px;\n    font-weight: bold;\n    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;\n    /* color: bisque; */\n    position: relative;\n    top:6px;\n    \n}\n.menuicon{\n    font-size: x-large;\n}\n\n.home-app span{\n    font-size: x-large;\n    margin:20px;\n}\n.main-div{\n    display:block;\n    text-align:center;\n    /* position: fixed; */\n}\n\n\n@media screen and (max-width: 600px){\n    .main-div{\n        text-align:right;\n    }\n    .title{\n        font-size: .5em;\n    }\n    .expand{\n        padding-left: 50px;\n        padding-top: 2px;\n    }\n}\n\n@media screen and (max-width:425px){\n    .main-div{\n        text-align:center\n    }\n    .title__settings{\n        position: relative;\n        top:6px;\n        font-size: 17px;\n        /* display: flex; */\n    }\n    .expand{\n        padding-left: 50px;\n        padding-top: 2px;\n        display: flex;\n    }\n    .home-app span{\n        font-size: x-large;\n        margin:5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
