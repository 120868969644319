import { DatePicker, Radio, Table } from "antd";
import React, { useEffect, useState } from "react";
import useSearch from "../../../hooks/useSearch";
import {
  getAllFinishDateMsProject,
  getAllProj,
  getAllStartDateMsProject,
  getAllUploadMmpFile,
} from "../../../redux-core/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  selectorGetFinishDateWiseMsProject,
  selectorGetStartDateWiseMsProject,
  selectorGetUploadFile,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { Form, Button, Upload, Select } from "antd";
import "../contents/Forms/css/ShowMsProject.css";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";
import moment from "moment";
import { IoIosAdd } from "react-icons/io";
import RequestForInspection from "../../qcc/content/RequestForInspection";
import { IoMdClose } from "react-icons/io";
import UploadMsProject from "../../settings/contents/UploadMsProject";
import { FaEye } from "react-icons/fa";
import SimpleImageSlider from "react-simple-image-slider";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";

const actionDispatch = (dispatch) => ({
  getUploadMmp: (project_id, orgId) =>
    dispatch(getAllUploadMmpFile(project_id, orgId)),
  getAllStartDateMsProject: (startDate, endDate, project_id, orgId) =>
    dispatch(getAllStartDateMsProject(startDate, endDate, project_id, orgId)),
  getAllFinishDateMsProject: (startDate, endDate, project_id, orgId) =>
    dispatch(getAllFinishDateMsProject(startDate, endDate, project_id, orgId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
});

const ShowMsprojectFile = () => {
  const children = [];
  const getColumnSearchProps = useSearch();
  const {
    getUploadMmp,
    getAllStartDateMsProject,
    getAllFinishDateMsProject,
    getAllProj,
    getAllAssignProjectId,
  } = actionDispatch(useDispatch());
  const allUploadMmpFile = useSelector(selectorGetUploadFile);
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const logindetails = useSelector(makeSelectLoginDetail);
  const { Option } = Select;
  const [selectStartDate, setSelectStartDate] = useState();
  const [selectFinishDate, setSelectFinishDate] = useState();
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectRadioValue, setSelectRadioValue] = useState();
  const [showImages, setShowImagesModal] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);

  const getStartDateMsProjectData = useSelector(
    selectorGetStartDateWiseMsProject
  );
  const getFinishDateMsProjectData = useSelector(
    selectorGetFinishDateWiseMsProject
  );
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const [showUploadScheduleModal, setShowUploadScheduleModal] = useState(false);

  useEffect(() => {
    getAllProj(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjSelectorData.filter(
      (items) => items.project_id == item.Project_id
    );
  });

  //     console.log("projectData",projectData)
  // console.log("allUploadMmpFile---------------", allUploadMmpFile);
  // console.log("allProjSelectorData",allProjSelectorData)
  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }
  const handleViewMmpFile = async (value) => {
    // console.log("value", value);
    setSelectedProjectId(value);
  };

  const columns = [
    {
      title: "SL No.",
      dataIndex: "sl_no",
      //   ...getColumnSearchProps('sl_no'),
    },
    // {
    //     title: 'Project ID',
    //     dataIndex: 'project_id',
    //     ...getColumnSearchProps('project_id'),
    // },
    {
      title: "Task Name",
      dataIndex: "task_name",
      ...getColumnSearchProps("task_name"),
    },
    {
      title: "duration",
      dataIndex: "duration",
      ...getColumnSearchProps("duration"),
    },
    {
      title: "start",
      dataIndex: "start",
      ...getColumnSearchProps("start"),
    },
    {
      title: "finish",
      dataIndex: "finish",
      ...getColumnSearchProps("finish"),
      // width: '20%',
    },
    {
      title: "predecessors",
      dataIndex: "predecessors",
      ...getColumnSearchProps("predecessors"),
    },
    {
      title: "successors",
      dataIndex: "successors",
      ...getColumnSearchProps("successors"),
    },
    {
      title: "resources name",
      dataIndex: "resources_name",
      ...getColumnSearchProps("resources_name"),
    },
  ];

  let tableDataBySelected = [];

  if (selectRadioValue === "PROJECT") {
    tableDataBySelected = allUploadMmpFile;
  } else if (selectRadioValue === "START") {
    tableDataBySelected = getStartDateMsProjectData;
  } else if (selectRadioValue === "FINISH") {
    tableDataBySelected = getFinishDateMsProjectData;
  }
  // console.log("tableDataBySelected--", tableDataBySelected);
  const data = [];
  for (let i = 0; i < tableDataBySelected.length; i++) {
    let startDate = new Date(tableDataBySelected[i].start);
    let finishDate = new Date(tableDataBySelected[i].finish);
    data.push({
      sl_no: i + 1,
      project_id: tableDataBySelected[i].project_id,
      task_name: tableDataBySelected[i].task_name,
      duration: tableDataBySelected[i].duration,
      start: startDate.toLocaleDateString("en-GB"),
      finish: finishDate.toLocaleDateString("en-GB"),
      predecessors: tableDataBySelected[i].predecessors,
      successors: tableDataBySelected[i].successors,
      resources_name: tableDataBySelected[i].resources_name,
      progress: tableDataBySelected[i].progress,
      images: tableDataBySelected[i].images,
    });
  }
  const HandelRadioValue = async (value) => {
    // console.log("Value", value.target.value);
    setSelectRadioValue(value.target.value);
    // console.log("first",selectStartDate,selectFinishDate,selectedProjectId)
    if (value.target.value === "PROJECT") {
      await getUploadMmp(selectedProjectId, logindetails.organisation.orgID);
    } else if (value.target.value === "START") {
      // console.log("START",selectStartDate,selectFinishDate,selectedProjectId)
      getAllStartDateMsProject(
        selectStartDate,
        selectFinishDate,
        selectedProjectId,
        logindetails.organisation.orgID
      );
    } else if (value.target.value === "FINISH") {
      // console.log("FINISH",selectStartDate,selectFinishDate,selectedProjectId)
      getAllFinishDateMsProject(
        selectStartDate,
        selectFinishDate,
        selectedProjectId,
        logindetails.organisation.orgID
      );
    }
  };

  const HandelStartDate = (value) => {
    console.log(moment(value).format("YYYY-MM-DD"));

    setSelectStartDate(moment(value).format("YYYY-MM-DD"));
  };
  const HamdelFinishDate = (value) => [
    setSelectFinishDate(moment(value).format("YYYY-MM-DD")),
  ];

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    // console.log("i am date: ", dateString);
    const date = moment.utc(dateString, "DD-MM-YYYY");
    return date.format("DD MMM YYYY");
  };

  // useEffect(() => {
  //   console.log("Slider images: ", sliderImages);
  // }, [sliderImages, setSliderImages]);

  const [isChecked, setIsChecked] = useState(false);
  const [taskDuration, setTaskDuration] = useState("Month");

  // useEffect(() => {
  //   console.log("toggle: ", isChecked);
  // }, [isChecked, setIsChecked]);
  // let tasks = [
  //   {
  //     start: new Date(2024, 1, 1),
  //     end: new Date(2024, 1, 2),
  //     name: "Idea",
  //     id: "Task 0",
  //     type: "task",
  //     progress: 10,
  //     isDisabled: true,
  //     styles: { progressColor: "#ffbb54", progressSelectedColor: "#ff9e0d" },
  //   },
  //   {
  //     start: new Date(2024, 1, 2),
  //     end: new Date(2024, 1, 3),
  //     name: "Dinner",
  //     id: "Task 0",
  //     type: "task",
  //     progress: 65,
  //     isDisabled: true,
  //     styles: { progressColor: "#3EA51D", progressSelectedColor: "#ff9e0d" },
  //   },
  //   {
  //     start: new Date(2024, 1, 3),
  //     end: new Date(2024, 1, 4),
  //     name: "Swimming",
  //     id: "Task 0",
  //     type: "task",
  //     progress: 35,
  //     isDisabled: false,
  //     styles: { progressColor: "#1573C7", progressSelectedColor: "#ff9e0d" },
  //   },
  // ];
  let tasks = [
    // {
    //   start: new Date(2024, 1, 1),
    //   end: new Date(2024, 1, 2),
    //   name: "Idea",
    //   id: "Task 1",
    //   type: "task",
    //   progress: 10,
    //   isDisabled: true,
    //   styles: { progressColor: "#ffbb54", progressSelectedColor: "#ff9e0d" },
    // },
    // {
    //   start: new Date(2024, 1, 2),
    //   end: new Date(2024, 1, 3),
    //   name: "Dinner",
    //   id: "Task 2",
    //   type: "task",
    //   progress: 65,
    //   isDisabled: true,
    //   dependencies: "Task 1",
    //   styles: { progressColor: "#3EA51D", progressSelectedColor: "#ff9e0d" },
    // },
    // {
    //   start: new Date(2024, 1, 3),
    //   end: new Date(2024, 1, 4),
    //   name: "Swimming",
    //   id: "Task 3",
    //   type: "task",
    //   progress: 35,
    //   isDisabled: false,
    //   dependencies: "Task 2",
    //   styles: { progressColor: "#1573C7", progressSelectedColor: "#ff9e0d" },
    // },
  ];

  for (let i = 0; i < data.length; i++) {
    //if (i == 9) {
    //  break;
    //}
    let obj = {};
    if (i > 0) {
      obj.dependencies = String(data[i - 1].successors);
    }
    console.log("Dataaa: ", data);

    obj.start = new Date(
      Number(data[i].start.split("/")[2]),
      Number(data[i].start.split("/")[1]),
      Number(data[i].start.split("/")[0])
    );
    obj.end = new Date(
      Number(data[i].finish.split("/")[2]),
      Number(data[i].finish.split("/")[1]),
      Number(data[i].finish.split("/")[0])
    );
    obj.name = data[i].task_name;
    obj.id = String(data[i].sl_no);
    obj.type = "task";
    obj.progress = data[i].progress;
    obj.isDisabled = false;
    obj.styles = {
      progressColor: "#1573C7",
      progressSelectedColor: "#ff9e0d",
    };

    if (!(obj.start == undefined) && !(obj.end == undefined)) {
      tasks.push(obj);
    }
  }

  useEffect(() => {
    console.log("Task data: ", tasks);
  }, [tasks]);

  return (
    <>
      {/* <div className="title-show-project">Show Schedule</div> */}
      <br />
      {/* <Form size="large">
        <Form.Item
          name="Project_id"
          label="Select Project"
          className="firstRow"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please Select The Project",
          //   },
          // ]}
        >
          <Select
            allowClear
            placeholder="Select Project Name"
            // disabled={isDisableInput}
            onChange={(project_id) => handleViewMmpFile(project_id)}
          >
            {logindetails.user.role === "ADMIN"
              ? allProjSelectorData.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))
              : projectData.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item label="From Start Date" className="secondRow">
          <DatePicker format="DD-MM-YYYY" onChange={HandelStartDate} />
        </Form.Item>
        <Form.Item label="To Start Date" className="thirdRow">
          <DatePicker format="DD-MM-YYYY" onChange={HamdelFinishDate} />
        </Form.Item>
        <Radio.Group
          // defaultValue="c"
          onChange={HandelRadioValue}
          buttonStyle="solid"
          className="fourthRow"
        >
          <Radio.Button value="START" onClick={HandelRadioValue}>
            Start Date
          </Radio.Button>
          <Radio.Button value="FINISH" onClick={HandelRadioValue}>
            Finish Date
          </Radio.Button>
          <Radio.Button value="PROJECT" onClick={HandelRadioValue}>
            Project Wise
          </Radio.Button>
        </Radio.Group>
      </Form> */}

      {/* <Table
        className="table"
        //   rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        //   onChange={(pagination, filter, sorter, currentTable) => onTableChange(filter, sorter, currentTable)}
        pagination={20}
        scroll={{ x: "100vw", y: 700 }}
      /> */}

      {/* .................................. */}
      <div className=" font-montserrat -mt-4    bg-white rounded-2xl   ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className=" flex flex-col m-0 p-0">
            <div className="w-[250px] -mt-2">
              <label
                htmlFor="project_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Project
              </label>
              <select
                required
                name="project_id"
                // getUploadMmp(selectedProjectId, logindetails.organisation.orgID);
                // onChange={(project_id) => handleViewMmpFile(project_id)}
                onChange={(project_id) => {
                  setSelectRadioValue("PROJECT");
                  // console.log("Project id: ", project_id.target.value);
                  getUploadMmp(
                    project_id.target.value,
                    logindetails.organisation.orgID
                  );
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>
                {logindetails.user.role === "ADMIN"
                  ? allProjSelectorData.map((getAllProj) => (
                      <option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </option>
                    ))
                  : projectData.map((getAllProj) => (
                      <option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </option>
                    ))}
              </select>
            </div>

            
          </div>

          {/* .  */}
          <div className=" font-semibold  text-2xl">Show Schedule</div>
          <div className=" font-montserrat">
            {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

            <button
              // onClick={handleOpenDocument}
              onClick={() => setShowUploadScheduleModal(true)}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <span>
                <IoIosAdd size={20} />
              </span>
              Upload Schedule
            </button>
          </div>
        </div>

        {/* toggle  */}
        <div className=" flex justify-between mt-1   mx-11">
              <label class="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  value=""
                  class="sr-only peer"
                />
                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Show Gant Chart
                </span>
              </label>

              {isChecked && (
                <div className="mt w-[300px] flex">
                  <label
                    htmlFor="taskDuration"
                    className="block text-sm font-medium leading-6 text-gray-900  mt-1 mr-4"
                  >
                    <span className="text-red-600">*</span>
                    Time
                  </label>
                  <select
                    required
                    name="taskDuration"
                    onChange={async (e) => {
                      // console.log("etv: ", e.target.value, taskDuration),
                      await setTaskDuration(e.target.value);
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  >
                    <option disabled value="">Select</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                    {/* <option value="Year">Year</option> */}
                  </select>
                </div>
              )}
            </div>

        {/* new table + gant chart  */}
        {isChecked == true ? (
          <div className=" overflow-x-auto  items-center mt-4 ml-4 w-[1200px] w-[80vw] overflow-y-auto max-h-[450px] max-h-[70vh] ">
            {data && data.length ? (
              // <Gantt
              // fontFamily="montserrat"
              // arrowIndent="10"
              // arrowColor="blue"
              //   barFill={40}
              //   viewMode={taskDuration}
              //   listCellWidth=""
              //   tasks={tasks}
              // />
              <Gantt
                headerHeight={50}
                columnWidth={70}
                rowHeight={40}
                barCornerRadius={5}
                barFill={60}
                handleWidth={10}
                fontFamily="montserrat"
                fontSize="12px"
                listCellWidth=""
                barBackgroundColor="#e0e0e0"
                barBackgroundSelectedColor="#d0d0d0"
                arrowColor="#9C9D9D"
                arrowIndent={10}
                todayColor="#f0f0f0"
                viewMode={taskDuration}
                tasks={tasks}
              />
            ) : (
              "Select an project"
            )}
          </div>
        ) : (
          <div className=" font-montserrat    mt-4 items-start items-center ml-4    flex flex-col justify-center ">
            <ul role="list" className="divide-y w-[1215px] divide-[#F5F5F5]">
              <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
                {/* Header Content */}
                <div className="flex flex-col items-start -yellow-200 w-[220px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Task Name
                  </p>
                  <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                    Serial Number
                  </p>
                </div>

                <div className="flex flex-col blue-200  items-start w-[140px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Start Date
                  </p>
                  <p className="mt-1 text-xs  leading-5 text-gray-500">
                    End Date
                  </p>
                </div>
                <div className="flex   pink-25 items-start w-[170px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Duration
                  </p>
                </div>
                <div className="flex   pink-25 items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Progress
                  </p>
                </div>
                <div className="flex purple-200   flex-col items-start  w-[120px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Predecessors
                  </p>
                </div>
                <div className="flex flex-col items-start green-200  w-[130px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Successors
                  </p>
                </div>
                <div className="flex  flex-col pink-300 items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Resources Name
                  </p>
                </div>
              </li>
            </ul>

            {/* Child Items */}
            <div className=" overflow-y-auto max-h-[430px]    max-h-[65vh]">
              {data && data.length
                ? data.map((item, key) => (
                    <ul
                      role="list"
                      className="divide-y w-[1200px] divide-[#F5F5F5]"
                    >
                      <li
                        className={`flex justify-between ${
                          key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                        }  items-start px-5  py-2`}
                      >
                        {/* Header Content */}
                        <div className="flex flex-col yellow-200 items-start w-[220px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item?.task_name}
                          </p>
                          <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                            {key + 1}
                          </p>
                        </div>

                        <div className="flex flex-col blue-200 items-start w-[140px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {formatDate(item?.start)}
                          </p>
                          <p className="mt-1 text-xs leading-5 text-gray-500">
                            {formatDate(item?.finish)}
                          </p>
                        </div>
                        <div className="flex flex-col pink-200 items-start w-[180px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item?.duration}
                          </p>
                        </div>
                        <div className="flex flex-col pink-200 items-start w-[170px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item?.progress?.toString()
                              ? item?.progress?.toString() + "%"
                              : "-"}
                          </p>
                          <p className="mt-1 flex  gap-x-2 text-xs max-w-[300px] leading-5 text-gray-500">
                            <div>
                              {" "}
                              {item.images
                                ? JSON.parse(item.images).length + " images "
                                : "-"}
                            </div>
                            <div>
                              {item.images && (
                                <FaEye
                                  onClick={() => {
                                    setSliderImages(JSON.parse(item.images));
                                    setShowImagesModal(true);
                                  }}
                                  className="text-top_nav_blue-300 cursor-pointer "
                                  size={18}
                                />
                              )}
                            </div>
                          </p>
                        </div>
                        <div className="flex flex-col green-300 items-start  w-[120px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item?.predecessors ? item?.predecessors : "-"}
                          </p>
                        </div>
                        <div className="flex orange-300 flex-col items-start  w-[120px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item?.successors}
                          </p>
                        </div>
                        <div className="flex pink-200  flex-col items-start w-[240px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item?.resources_name ? item?.resources_name : "-"}
                          </p>
                        </div>
                      </li>
                    </ul>
                  ))
                : "Select an project"}
            </div>
          </div>
        )}


      </div>
      {showUploadScheduleModal && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setShowUploadScheduleModal(false)}
            className=" cursor-pointer absolute top-48  right-96"
          />
          <UploadMsProject
            setShowUploadScheduleModal={setShowUploadScheduleModal}
          />
        </div>
      )}
      {showImages && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-lg   ">
          <IoMdClose
            size={22}
            onClick={() => setShowImagesModal(false)}
            className=" cursor-pointer absolute top-14  right-64"
          />
          <div>
            <SimpleImageSlider
              width={896}
              height={504}
              images={sliderImages}
              showBullets={true}
              showNavs={true}
              navStyle={2}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShowMsprojectFile;
