import {
  FundProjectionScreenOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "./css/Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { selectorCount } from "../../../redux-core/settings/selectors";
import React, { useState } from "react";
import ShowProjects from "./ShowProjects";
import { Button } from "antd";
// import SiteInChargeShow from "./SiteInChargeShow";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import ShowOrganization from "./ShowOrganization";
import ShowAdmin from "./showAdmin";
import UsersShow from "./UsersShow";

function Dashboard() {
  const countData = useSelector(selectorCount);
  const [showProject, setShowProject] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [showOrganization, setShowOrganization] = useState(false);
  // const [showSiteincharge, setShowSiteincharge] = useState(false)
  const [hideDashboard, setHideDashboard] = useState(true);
  const [hideBackbutton, setHideBackbutton] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const handelClick = () => {
    // <ShowProjects />;
    // console.log(value);
    setShowProject(true);
    setHideDashboard(false);
    setHideBackbutton(true);
  };

  const handelClickOrganization = () => {
    setShowOrganization(true);
    setHideDashboard(false);
    setHideBackbutton(true);
  };
  const handelClickAdmin = () => {
    setShowAdmin(true);
    setHideDashboard(false);
    setHideBackbutton(true);
  };

  const handelClickUsers = () => {
    setShowUser(true);
    setHideDashboard(false);
    setHideBackbutton(true);
  };

  // const handelClickSiteinCharge = ()=>{
  //   setShowSiteincharge(true)
  //   setHideDashboard(false);
  //   setHideBackbutton(true);
  // }
  const handelBack = () => {
    setShowProject(false);
    setHideDashboard(true);
    setHideBackbutton(false);
    // setShowSiteincharge(false);
    setShowOrganization(false);
    setShowAdmin(false);
    setShowUser(false);
  };
  // console.log("countData", countData)
  return (
    <>
      {/* {countData || countData[1].no_of_projects} */}
      {hideBackbutton && (
        <Button
          type="primary"
          style={{ borderRadius: "10px", width: "7%" }}
          onClick={() => handelBack()}
        >
          Back
        </Button>
      )}
      {hideDashboard && (
        <div className="dashboard-root1">
          {logindetails.user.role === "ADMIN" && (
            <>
              <div className="sub-main" onClick={() => handelClick()}>
                <div className="top">
                  <span className="left__value">
                    {countData.length > 0 && countData[1]?.no_of_projects}
                  </span>
                  <span className="right__icon">
                    <FileProtectOutlined />
                  </span>
                </div>
                <div className="bottom__title">No of Projects</div>
                {/* <span className="left__value">{countData.length>0 && countData[0]?.no_of_project_details}</span>
                    <div className="bottom__title">No of Project Details</div> */}
              </div>
              {/* {countData || countData[0].no_of_project_details} */}
              {/* <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData.length>0 && countData[0]?.no_of_project_details}</span>
                        <span className="right__icon"><FileProtectOutlined /></span>
                    </div>
                    <div className="bottom__title">No of Project Details</div>
                </div> */}

              {/* <div className="sub-main" onClick={() => handelClickSiteinCharge()}>
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[2]?.site_incharge}
              </span>
              <span className="right__icon">
                <FileProtectOutlined />
              </span>
            </div>
            <div className="bottom__title">No of Site Incharges</div>
          </div> */}

              {/* <div className="sub-main">
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[3]?.supervisor}
              </span>
              <span className="right__icon">
                <FileProtectOutlined />
              </span>
            </div>
            <div className="bottom__title">No of Supervisors</div>
          </div> */}

              {/* <div className="sub-main">
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[4]?.drawing}
              </span>
              <span className="right__icon">
                <FileProtectOutlined />
              </span>
            </div>
            <div className="bottom__title">No of Drawing User</div>
          </div> */}

              <div className="sub-main" onClick={() => handelClickUsers()}>
                <div className="top">
                  <span className="left__value">
                    {countData.length > 0 && countData[7]?.no_of_users}
                  </span>
                  <span className="right__icon">
                    <FileProtectOutlined />
                  </span>
                </div>
                <div className="bottom__title">No of Users</div>
              </div>
            </>
          )}
          {logindetails.user.role === "SUPERADMIN" && (
            <>
              <div
                className="sub-main"
                onClick={() => handelClickOrganization()}
              >
                <div className="top">
                  <span className="left__value">
                    {countData.length > 0 && countData[5]?.no_of_organisation}
                  </span>
                  <span className="right__icon">
                    <FileProtectOutlined />
                  </span>
                </div>
                <div className="bottom__title">No of Organization</div>
              </div>

              <div className="sub-main" onClick={() => handelClickAdmin()}>
                <div className="top">
                  <span className="left__value">
                    {countData.length > 0 && countData[6]?.no_of_admin}
                  </span>
                  <span className="right__icon">
                    <FileProtectOutlined />
                  </span>
                </div>
                <div className="bottom__title">No of Admin</div>
              </div>
            </>
          )}

          {/* <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData.length>0 && countData[5]?.no_of_users}</span>
                        <span className="right__icon"><FileProtectOutlined /></span>
                    </div>
                    <div className="bottom__title">No of Users</div>
                </div> */}
          {/* <div className="sub-main">
                <div className="top">
                    <span className="left">14</span>
                    <span className="right"><InboxOutlined /></span>
                </div>
                <div className="bottom">No of Bomb Product</div>
            </div>
            
            <div className="sub-main">
                <div className="top">
                    <span className="left">4</span>
                    <span className="right"><QuestionCircleOutlined /></span>
                </div>
                <div className="bottom">No of Questions</div>
            </div> */}
        </div>
      )}
      {showProject && <ShowProjects />}
      {showOrganization && <ShowOrganization />}
      {showAdmin && <ShowAdmin />}
      {showUser && <UsersShow />}
      {/* {showSiteincharge && <SiteInChargeShow />} */}
    </>
  );
}

export default React.memo(Dashboard);
