/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
// import moment from 'moment';
import { MdDraw } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { MdOutlineRateReview } from "react-icons/md";
import React, { useEffect, useState } from "react";
import {
  delectRequestForInspection,
  findStageDetailById,
  findTaskDetailById,
  getAllActivities,
  getRequestForInspection,
  updateRequestForInspection,
} from "../../../../redux-core/qcc/actions";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  selectorActivityDetail,
  selectorGateRequestForInspection,
  selectorStageDetailById,
  selectorTaskDetailById,
} from "../../../../redux-core/qcc/selectors";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { ShowDeleteConfirm } from "../../../popupmodal";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import {
  getAllAssignProjectId,
  userDetails,
} from "../../../../redux-core/userManagement/actions";
import { getProjectIdByUsers } from "../../../../redux-core/risk/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../../redux-core/settings/selectors";
import {
  selectorGetAllUserWiseAssignProjects,
  selectorUserDetail,
} from "../../../../redux-core/userManagement/selectors";
import { selectorUsersProjectIdWiseState } from "../../../../redux-core/risk/selectors";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import RequestForInspection from "../RequestForInspection";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getRequestForInspection: (orgId) => dispatch(getRequestForInspection(orgId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projID, orgId, userId) =>
    dispatch(getAllProjDetails(projID, orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getProjectIdByUsers: (projectId, orgID) =>
    dispatch(getProjectIdByUsers(projectId, orgID)),
  getActivities: (orgId) => dispatch(getAllActivities(orgId)),
  updateRequestForInspection: (updateData) =>
    dispatch(updateRequestForInspection(updateData)),
  delectRequestForInspection: (delectid) =>
    dispatch(delectRequestForInspection(delectid)),
  getUsers: (orgID) => dispatch(userDetails(orgID)),
  taskById: (projId, orgId) => dispatch(findTaskDetailById(projId, orgId)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
});
const { Option } = Select;
function ShowRfiTable() {
  const {
    getRequestForInspection,
    getAllProj,
    getAllProjDetails,
    getAllAssignProjectId,
    getProjectIdByUsers,
    getActivities,
    updateRequestForInspection,
    delectRequestForInspection,
    getUsers,
    taskById,
    getStageById,
  } = actionDispatch(useDispatch());
  const loginDetails = useSelector(makeSelectLoginDetail);
  const getAllRequestForInpection = useSelector(
    selectorGateRequestForInspection
  );
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [keys, setKeys] = useState([]);
  const [activedata, setActivedata] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [form] = Form.useForm();
  const allProjects = useSelector(makeSelectAllProj);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const [selectedProjectId, setSelectedProjectId] = useState();
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const getUserProjectWise = useSelector(selectorUsersProjectIdWiseState);
  const allActvityData = useSelector(selectorActivityDetail);
  const [selectDate, setSelectDate] = useState();
  const [projectDetailId, setProjectDetailId] = useState();
  const [qcEngineerId, setQcEnginnerId] = useState();
  const [taskId, setTaskId] = useState();
  const [stageId, setStageId] = useState();
  const [rowId, setRowId] = useState();
  const allUsersData = useSelector(selectorUserDetail);
  const taskDetailsData = useSelector(selectorTaskDetailById);
  const stageDetailsData = useSelector(selectorStageDetailById);
  const [showCreateRfiModal, setShowCreateRfiModal] = useState(false);
  const [showEditRfiModal, setShoweditRfiModal] = useState(false);
  const [rifRowDetails, setRfiRowDetails] = useState({});

  // console.log("selectDate", selectDate);
  const qcEngineerList = allUsersData.filter(
    (item) => item.role === "QCENGINEER"
  );

  const qcEngineerUser = getUserProjectWise.filter((qcUser) =>
    qcEngineerList.some((engineer) => engineer.admin_id == qcUser.user_id)
  );

  useEffect(() => {
    getRequestForInspection(loginDetails.organisation.orgID);
    getAllProj(loginDetails.organisation.orgID, loginDetails.user.id);
    getAllAssignProjectId(
      loginDetails.organisation.orgID,
      loginDetails.user.id
    );
    getActivities(loginDetails.organisation.orgID);
    getUsers(loginDetails.organisation.orgID);
    taskById(loginDetails.organisation.orgID);
    console.log("Show rfi data: ", Data);
  }, []);

  // push project id
  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  //user id wise assign project
  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });

  // search function in select dropdown
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjDetails(
      projectId,
      loginDetails.organisation.orgID,
      loginDetails.user.id
    );
    getProjectIdByUsers(projectId, loginDetails.organisation.orgID);
  };

  // console.log("getAllRequestForInpection", getAllRequestForInpection);
  const Data = [];

  for (let i = 0; i < getAllRequestForInpection.length; i++) {
    // const RFIDate = new Date(getAllRequestForInpection[i]?.rfi_date);
    // const timeZoneOffsets = RFIDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // RFIDate.setMinutes(RFIDate.getMinutes() - timeZoneOffsets); // Adjust the date by the offset
    // const formattedDate = RFIDate.toISOString().slice(0, 10);
    Data.push({
      sl_no: i + 1,
      id: getAllRequestForInpection[i]?.id,
      rfi_code: getAllRequestForInpection[i]?.rfi_code,
      rfi_date: moment(getAllRequestForInpection[i]?.rfi_date).format(
        "DD-MM-YYYY"
      ),
      project_name: getAllRequestForInpection[i]?.project_name,
      project_id: getAllRequestForInpection[i]?.project_id,
      block: getAllRequestForInpection[i]?.block,
      project_detail_id: getAllRequestForInpection[i]?.project_detail_id,
      name: getAllRequestForInpection[i]?.name,
      qc_engineer: getAllRequestForInpection[i]?.qc_engineer,
      description: getAllRequestForInpection[i]?.description,
      drawing_file: getAllRequestForInpection[i]?.drawing_file,
      task_id: getAllRequestForInpection[i]?.task_id,
      task: getAllRequestForInpection[i]?.task,
      stage_id: getAllRequestForInpection[i]?.stage_id,
      stage_name: getAllRequestForInpection[i]?.stage_name,
    });
  }

  const colunm = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
    },
    {
      title: "RFI Code",
      dataIndex: "rfi_code",
    },
    {
      title: "RFI Date",
      dataIndex: "rfi_date",
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
    },
    {
      title: "Project Location",
      dataIndex: "block",
    },
    {
      title: "QC Engineer",
      dataIndex: "name",
    },
    {
      title: "Task",
      dataIndex: "task",
    },
    {
      title: "Stage",
      dataIndex: "stage_name",
    },
    {
      title: "Drawing",
      dataIndex: "drawing_file",
      render: (text, record) => (
        <>
          <Space size="middle">
            <Button onClick={() => HandelView(record.drawing_file)}>
              <EyeOutlined />
            </Button>
          </Space>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Edit">
              <Button onClick={() => showModal(record)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delect">
              <Button
                onClick={() => deleteRow(record)}
                style={{ marginLeft: "5px" }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const HandelObserveModal = (value) => {
    console.log("value", value);
  };

  // drawing view modal open
  const HandelView = (value) => {
    setSelectedDrawing(value);
    setIsOpenModal(true);
  };

  // modal close function
  const handelCancel = () => {
    setIsOpenModal(false);
    setShowEditModal(false);
  };

  // Edit modal open Function
  const showModal = async (data1) => {
    await setRfiRowDetails(data1);
    getStageById(loginDetails.organisation.orgID, data1.task_id);
    console.log("first", data1);
    getProjectIdByUsers(data1.project_id, loginDetails.organisation.orgID);
    setShowEditModal(true);
    setSelectDate(moment(data1.rfi_date));
    setProjectDetailId(data1.project_detail_id);
    setQcEnginnerId(data1.qc_engineer);
    setTaskId(data1.task_id);
    setStageId(data1.stage_id);
    setRowId(data1.id);
    form.setFieldsValue({
      project_id: data1.project_id,
      project_detail_id: data1.block,
      qc_engineer: data1.name,
      rfi_date: moment(data1.rfi_date, "DD-MM-YYYY"),
      description: data1.description,
      rfi_code: data1.rfi_code,
      task_id: data1.task,
      stage_id: data1.stage_name,
    });
    setShoweditRfiModal(true);
  };

  // delect dunction
  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.id]) });
  };
  const onConfirm = async (key) => {
    delectRequestForInspection(key);
    getRequestForInspection(loginDetails.organisation.orgID);
  };

  // bulk delect function
  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  //row select value
  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };
  // for delect check box select
  const rowSelection = {
    selectedRowKeys: keys,
    onChange: onSelectChange,
  };

  //table pagination
  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const [editRfiFormData, setEditRfiFormData] = useState({
    project_id: "",
    project_detail_id: "",
    qc_engineer: "",
    activity_id: "",
    rfi_date: "",
    description: "",
    rfi_code: "",
    created_by: "",
    stage_id: "",
    task_id: "",
  });

  // edit form submit function
  const handelEditFromSubmit = async (e) => {
    e.preventDefault();
    // console.log("Edit rfi data: ", value);
    // console.log("row rfi data: ", rifRowDetails);
    // console.log("new rfi data: ", editRfiFormData);
    // return;
    const updateData = {
      id: rowId,
      project_id: rifRowDetails.project_id,
      project_detail_id: projectDetailId,
      qc_engineer: qcEngineerId,
      task_id: taskId,
      stage_id: stageId,
      rfi_date: editRfiFormData.rfi_date,
      description: editRfiFormData.description,
    };

    console.log("update data: ", updateData);
    // return;
    try {
      let response = await updateRequestForInspection(updateData);

      // console.log("response", response);
      if (response.success == true) {
        toast.success("Success..");
        getRequestForInspection(loginDetails.organisation.orgID);
        setShowEditModal(false);
        // showEditRfiModal;
        // setShowEditModal(false);
        setShoweditRfiModal(false);
      }
    } catch (err) {
      toast.error("Something went wrong..");
      console.log("some error occurred: ", err);
    }
  };

  const HandelDateSelect = (value, dateString) => {
    setSelectDate(dateString, "DD-MM-YYYY");
  };

  // Handel select task for stage found
  const HandelSelectTask = (value) => {
    getStageById(loginDetails.organisation.orgID, value);
    setTaskId(value);
  };

  // handel stage function

  const HadelSelectStage = (value) => {
    console.log("value", value);
    setStageId(value);
  };

  // const formatDate = (dateString) => {
  //   if (!dateString) return "-";

  //   const date = new Date(dateString);
  //   const day = date.getDate();
  //   const month = date.toLocaleString("default", { month: "short" });
  //   const year = date.getFullYear();
  //   return `${day} ${month} ${year}`;
  // };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    // console.log("i am date: ", dateString);
    const date = moment.utc(dateString, "DD-MM-YYYY");
    return date.format("DD MMM YYYY");
  };

  const handleOpenDocument = (e) => {
    e.preventDefault();
    setShowCreateRfiModal(true);
    // console.log("i am clicked");
  };

  useEffect(() => {
    console.log("All rfi data: ", Data);
  }, []);
  return (
    <>
      {/* <div className="title-show-project">View Of Request for Inspection</div> */}
      {/* <Table
        className=" max-w-[1000px]"
        columns={colunm}
        scroll={{ x: "90vw" }}
        dataSource={Data}
        rowSelection={rowSelection}
        pagination={10}
        onChange={(pagination, filter, sorter, currentTable) =>
          onTableChange(filter, sorter, currentTable)
        }
        rowKey="id" // Set the rowKey to a unique identifier
      /> */}

      {/* <Modal
        className=" font-montserrat"
        title="Drawing Preview"
        visible={isOpenModal}
        footer={null}
        width={1500}
        onCancel={handelCancel}
      >
        <iframe
          className={"application/pdf"}
          width="100%"
          height="500"
          frameborder="10"
          src={selectedDrawing}
        />
      </Modal>
      <Modal
        className=" font-montserrat"
        title="Edit Request for Inspection"
        visible={showEditModal}
        footer={null}
        width={900}
        onCancel={handelCancel}
      >
        <Form
          name="edit_request_for_inspection"
          form={form}
          onFinish={handelEditFromSubmit}
          className=" font-montserrat"
        >
          <Form.Item name="rfi_code" label="RFI Code" className="input-field">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="project_id"
            label="Project"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select Project Name !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              onChange={(projectId) => handelProjectId(projectId)}
              placeholder="Search or select Project"
            >
              {loginDetails.user.role === "ADMIN"
                ? allProjects.map((getAllProj) => (
                    <Option
                      key={getAllProj.project_id}
                      value={getAllProj.project_id}
                    >
                      {getAllProj.project_name}
                    </Option>
                  ))
                : projectData.map((getAllProj) => (
                    <Option
                      key={getAllProj.project_id}
                      value={getAllProj.project_id}
                    >
                      {getAllProj.project_name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="project_detail_id"
            label="Project Location"
            rules={[
              {
                required: true,
                message: "Please Select  Project Location !",
              },
            ]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select Project Location"
              onChange={(project_details_id) =>
                setProjectDetailId(project_details_id)
              }
            >
              {allProjDetails.map((getAllProjDetails) => (
                <Option
                  key={getAllProjDetails.project_details_id}
                  value={getAllProjDetails.project_details_id}
                >
                  {getAllProjDetails.block}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="qc_engineer"
            label="QC Engineer"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select QC Engineer !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select QC Engineer"
              onChange={(user_id) => setQcEnginnerId(user_id)}
            >
              {qcEngineerUser.map((item) => (
                <Option key={item.user_id} value={item.user_id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="task_id"
            label="Task"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please Select Task !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select Task"
              onChange={HandelSelectTask}
            >
              {taskDetailsData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.task}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="stage_id"
            label="Stage"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please select Stage  !",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              filterOption={handleSearch}
              placeholder="Search or select Stage"
              onChange={HadelSelectStage}
            >
              {stageDetailsData.map((item) => (
                <Option key={item.stage_id} value={item.stage_id}>
                  {item.stage_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <Form.Item
            name="rfi_date"
            label="Date"
            className="firstRow1"
            rules={[
              {
                required: true,
                message: "Please Select Date !",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" onChange={HandelDateSelect} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            className="input-field"
            rules={[
              {
                pattern: /^(.{0,300})$/,
                message: "please enter less than 300 charactersonly",
              },
            ]}
          >
            <Input.TextArea
              style={{ maxHeight: "130px", minHeight: "100px" }}
            />
          </Form.Item>
          <Form.Item>
            <div className="btn-ehs">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal> */}

      {/* new table shivam kashyap 19 april 2024 */}
      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="  invisible font-montserrat">
            {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

            <button
              // onClick={onUploadWorkBtn}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <span>
                <IoIosAdd size={20} />
              </span>
              New RFI
            </button>
          </div>
          <div className=" font-semibold  text-2xl">RFI Details</div>
          <div className=" font-montserrat">
            {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

            <button
              onClick={handleOpenDocument}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <span>
                <IoIosAdd size={20} />
              </span>
              New RFI
            </button>
          </div>
        </div>

        {/* new table  */}
        <div className=" font-montserrat mt-5 items-start ml-4    flex flex-col  justify-start">
          <ul role="list" className="divide-y w-[1215px] divide-[#F5F5F5]">
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[170px]">
                <p className="text-sm font-semibold text-gray-900">RFI Code</p>
                <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Serial Number
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Project Name
                </p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Project Location
                </p>
              </div>
              <div className="flex   pink-25 items-start w-[180px]">
                <p className="text-sm font-semibold text-gray-900">RFI Date</p>
              </div>
              <div className="flex purple-200   flex-col items-start  w-[160px]">
                <p className="text-sm font-semibold text-gray-900">
                  QC Engineer
                </p>
              </div>
              <div className="flex flex-col items-start green-200  w-[150px]">
                <p className="text-sm font-semibold text-gray-900">Task</p>
                <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Stage
                </p>
              </div>
              <div className="flex  flex-col pink-300 items-start w-[240px]">
                <p className="text-sm font-semibold text-gray-900">
                  Description
                </p>
              </div>

              <div className="flex richblue-200 flex-col items-start w-[150px]">
                <p className="text-sm  font-semibold text-gray-900">Action</p>
              </div>
            </li>
          </ul>
          {/* Child Items */}
          <div className=" overflow-y-auto max-h-[430px]   ">
            {Data && Data.length
              ? Data.map((item, key) => (
                  <ul
                    role="list"
                    className="divide-y w-[1200px] divide-[#F5F5F5]"
                  >
                    <li
                      className={`flex justify-between ${
                        key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }  items-start px-5  py-2`}
                    >
                      {/* Header Content */}
                      <div className="flex flex-col yellow-200 items-start w-[170px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.rfi_code}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          {key + 1}
                        </p>
                      </div>

                      <div className="flex flex-col blue-200 items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.project_name}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                          {item?.block}
                        </p>
                      </div>
                      <div className="flex flex-col pink-200 items-start w-[180px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {formatDate(item?.rfi_date)}
                        </p>
                      </div>
                      <div className="flex flex-col green-300 items-start  w-[160px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.name}
                        </p>
                      </div>
                      <div className="flex orange-300 flex-col items-start  w-[150px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.task}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          {item?.stage_name}
                        </p>
                      </div>
                      <div className="flex pink-200  flex-col items-start w-[240px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.description}
                        </p>
                      </div>

                      <div className="flex richblue-300   gap-x-2 items-start w-[150px]">
                        <a
                          // onClick={() => onViewWorkOrder(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Drawing"
                        >
                          <MdOutlineRateReview
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={}
                            onClick={() => HandelView(item.drawing_file)}
                          />
                        </a>{" "}
                        <a
                          // onClick={() => onBillingWorkOrder(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Edit"
                        >
                          <MdEdit
                            className=" text-top_nav_blue-300 "
                            size={18}
                            onClick={() => showModal(item)}
                          />
                          {/* <RiBillLine
                         
                        /> */}
                        </a>{" "}
                        <a
                          // onClick={() => handelAdvanceRequest(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Delete"
                        >
                          <MdDelete
                            className=" text-top_nav_blue-300 "
                            size={18}
                            onClick={() => deleteRow(item)}
                          />

                          {/* <FaIndianRupeeSign
                         
                        /> */}
                        </a>
                      </div>
                    </li>
                  </ul>
                ))
              : "No records found..."}
          </div>
        </div>
      </div>

      {showCreateRfiModal && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setShowCreateRfiModal(false)}
            className=" cursor-pointer absolute top-28  right-10"
          />

          <RequestForInspection
            showCreateRfiModal={showCreateRfiModal}
            setShowCreateRfiModal={setShowCreateRfiModal}
          />
        </div>
      )}

      {/* edit rfi  */}

      {showEditRfiModal && (
        <div className="fixed m-6 select-none    inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setShoweditRfiModal(false)}
            className=" cursor-pointer absolute top-28  right-10"
          />
          {/* 
          <Form
            name="edit_request_for_inspection"
            form={form}
            onFinish={handelEditFromSubmit}
            className=" font-montserrat bg-white"
          >
            <Form.Item name="rfi_code" label="RFI Code" className="input-field">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="project_id"
              label="Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Name !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                onChange={(projectId) => handelProjectId(projectId)}
                placeholder="Search or select Project"
              >
                {loginDetails.user.role === "ADMIN"
                  ? allProjects.map((getAllProj) => (
                      <Option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </Option>
                    ))
                  : projectData.map((getAllProj) => (
                      <Option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="project_detail_id"
              label="Project Location"
              rules={[
                {
                  required: true,
                  message: "Please Select  Project Location !",
                },
              ]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Project Location"
                onChange={(project_details_id) =>
                  setProjectDetailId(project_details_id)
                }
              >
                {allProjDetails.map((getAllProjDetails) => (
                  <Option
                    key={getAllProjDetails.project_details_id}
                    value={getAllProjDetails.project_details_id}
                  >
                    {getAllProjDetails.block}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="qc_engineer"
              label="QC Engineer"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select QC Engineer !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select QC Engineer"
                onChange={(user_id) => setQcEnginnerId(user_id)}
              >
                {qcEngineerUser.map((item) => (
                  <Option key={item.user_id} value={item.user_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="task_id"
              label="Task"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Task !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Task"
                onChange={HandelSelectTask}
              >
                {taskDetailsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.task}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="stage_id"
              label="Stage"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please select Stage  !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Stage"
                onChange={HadelSelectStage}
              >
                {stageDetailsData.map((item) => (
                  <Option key={item.stage_id} value={item.stage_id}>
                    {item.stage_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="rfi_date"
              label="Date"
              className="firstRow1"
              rules={[
                {
                  required: true,
                  message: "Please Select Date !",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" onChange={HandelDateSelect} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="input-field"
              rules={[
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 charactersonly",
                },
              ]}
            >
              <Input.TextArea
                style={{ maxHeight: "130px", minHeight: "100px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-ehs">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}

          <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
            <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
              Edit RFI
            </div>{" "}
            <form className="font-montserrat" onSubmit={handelEditFromSubmit}>
              {/* 1a */}
              <div className="flex justify-evenly w-[90vw]">
                {/* 1 */}
                <div className="">
                  <label
                    htmlFor="rfi_code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    RFI Code
                  </label>
                  <div className="mt- cursor-not-allowed w-[300px]">
                    <p className="block border w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      {rifRowDetails.rfi_code}
                    </p>
                  </div>
                </div>{" "}
                {/* 2 */}
                <div className="">
                  <label
                    htmlFor="rfi_code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Project
                  </label>
                  <div className="mt- cursor-not-allowed w-[300px]">
                    <p className="block border w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      {rifRowDetails.project_name}
                    </p>
                  </div>
                </div>{" "}
                {/* 3  */}
                <div className="">
                  <label
                    htmlFor="rfi_code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Location
                  </label>
                  <div className="mt- cursor-not-allowed w-[300px]">
                    <p className="block border w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      {rifRowDetails.block}
                    </p>
                  </div>
                </div>{" "}
              </div>

              {/* 2a  */}
              <div className="flex my-3 justify-evenly w-[90vw]">
                {/* 1 */}
                <div className="mt w-[300px]">
                  <label
                    htmlFor="qc_engineer"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    QCC Engineer
                  </label>
                  <select
                    required
                    name="qc_engineer"
                    // value={formData.project_id}
                    // onChange={handleInputChange}
                    onChange={(e) =>
                      setEditRfiFormData({
                        ...editRfiFormData,
                        qc_engineer: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  >
                    <option value="">Select</option>
                    {qcEngineerUser.map((item) => (
                      <option key={item.user_id} value={item.user_id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 2  */}
                <div className="mt w-[300px]">
                  <label
                    htmlFor="task_id"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Task
                  </label>
                  <select
                    required
                    name="task_id"
                    // value={formData.task_id}

                    onChange={(e) => {
                      setEditRfiFormData({
                        ...editRfiFormData,
                        task_id: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  >
                    <option value="">Select</option>

                    {taskDetailsData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.task}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 3 */}

                <div className="mt w-[300px]">
                  <label
                    htmlFor="stage_id"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Stage
                  </label>
                  <select
                    required
                    name="stage_id"
                    // value={formData.task_id}

                    onChange={(e) => {
                      setEditRfiFormData({
                        ...editRfiFormData,
                        stage_id: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  >
                    <option value="">Select</option>

                    {stageDetailsData.map((item) => (
                      <option key={item.stage_id} value={item.stage_id}>
                        {item.stage_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* 3a  */}
              <div className="flex my-3 justify-evenly w-[90vw]">
                {/* 1  */}
                <div class="relative  w-[300px] h-[35px]">
                  <label
                    for="rfi_date"
                    class="block text-sm mb- font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    RFI Date
                  </label>
                  <input
                    required
                    type="date"
                    name="rfi_date"
                    // value={formData.completion_date}
                    // onChange={handleInputChange}
                    onChange={(e) =>
                      setEditRfiFormData({
                        ...editRfiFormData,
                        rfi_date: e.target.value,
                      })
                    }
                    class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    placeholder="Select rfi date"
                  />
                </div>
                {/* 2 */}
                <div className="">
                  <label
                    htmlFor="rfi_code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      // disabled
                      type="text"
                      // title="Please enter only numbers."
                      name="rfi_code"
                      value={editRfiFormData.description}
                      onChange={(e) =>
                        setEditRfiFormData({
                          ...editRfiFormData,
                          description: e.target.value,
                        })
                      }
                      // pattern="[0-9]*"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>{" "}
                {/* 3 */}
                <div className="mt opacity-0 w-[300px]"></div>
              </div>
              {/* buttons  */}
              <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
                <button
                  // onClick={resetAllValue}
                  type="button"
                  class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  data-te-ripple-init
                >
                  Reset
                </button>
                <button
                  type="submit"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ShowRfiTable;
