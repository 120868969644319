import React, { useEffect, useState } from "react";
import { MdOutlineHighQuality } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";
import { GiProgression } from "react-icons/gi";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { FaFileContract } from "react-icons/fa6";
import { GiTruck } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../redux-core/login/selectors";
import { useNavigate } from "react-router";
import { RxDashboard } from "react-icons/rx";
import { PiDotOutlineFill } from "react-icons/pi";

const Sidebar = ({ updateSharedData }) => {
  const navigate = useNavigate();
  const [showQcc, setShowQcc] = useState("hidden");
  const [showResources, setShowResources] = useState("hidden");
  const [showProgress, setShowProgress] = useState("hidden");
  const [showSettings, setShowSettings] = useState("hidden");
  const [showDocuments, setShowDocument] = useState("hidden");
  const [showContracts, setShowContracts] = useState("hidden");
  const [showMaterials, setShowMaterials] = useState("hidden");
  let icon1 = document.getElementById("icon1");
  let menu1 = document.getElementById("menu1");

  const logindetails = useSelector(makeSelectLoginDetail);
  useEffect(() => {
    console.log("Login details", logindetails);
  }, []);

  const showMenu1 = (flag) => {
    if (showQcc == "") {
      setShowQcc("hidden");
      //   icon1.classList.toggle("rotate-180");
      //   menu1.classList.toggle("hidden");
    } else {
      setShowQcc("");
    }
  };

  const showResourcesMenu = () => {
    if (showResources == "") {
      setShowResources("hidden");
      //   icon1.classList.toggle("rotate-180");
      //   menu1.classList.toggle("hidden");
    } else {
      setShowResources("");
    }
  };

  const showProgressMenu = () => {
    if (showProgress == "") {
      setShowProgress("hidden");
      //   icon1.classList.toggle("rotate-180");
      //   menu1.classList.toggle("hidden");
    } else {
      setShowProgress("");
    }
  };

  const showSettingsMenu = () => {
    if (showSettings == "") {
      setShowSettings("hidden");
      //   icon1.classList.toggle("rotate-180");
      //   menu1.classList.toggle("hidden");
    } else {
      setShowSettings("");
    }
  };

  const showDocumentsMenu = () => {
    if (showDocuments == "") {
      setShowDocument("hidden");
      //   icon1.classList.toggle("rotate-180");
      //   menu1.classList.toggle("hidden");
    } else {
      setShowDocument("");
    }
  };
  const showContractsMenu = () => {
    // if (showContracts == "") {
    //   setShowContracts("hidden");
    //   navigate("v1/contracts");
    //   //   icon1.classList.toggle("rotate-180");
    //   //   menu1.classList.toggle("hidden");
    // } else {
    //   setShowContracts("");
    // }
    navigate("v1/contracts");
  };
  const showMaterialsMenu = () => {
    if (showMaterials == "") {
      setShowMaterials("hidden");

      //   icon1.classList.toggle("rotate-180");
      //   menu1.classList.toggle("hidden");
    } else {
      setShowMaterials("");
    }
  };

  let icon2 = document.getElementById("icon2");

  const showMenu2 = (flag) => {
    if (flag) {
      icon2.classList.toggle("rotate-180");
    }
  };
  let icon3 = document.getElementById("icon3");

  const showMenu3 = (flag) => {
    if (flag) {
      icon3.classList.toggle("rotate-180");
    }
  };

  let Main = document.getElementById("Main");
  let open = document.getElementById("open");
  let close = document.getElementById("close");

  const showNav = (flag) => {
    if (flag) {
      Main.classList.toggle("-translate-x-full");
      Main.classList.toggle("translate-x-0");
      open.classList.toggle("hidden");
      close.classList.toggle("hidden");
    }
  };

  // props function
  const handleSettingClick = () => {
    // Simulating some data to pass to the content div
    // const data = "settings";
    // updateSharedData(data);
    navigate("v1/settings");
  };
  const handleReportClick = () => {
    // Simulating some data to pass to the content div
    // const data = "reports";
    // updateSharedData(data);
    //navigate("v1/reports");
     navigate("v1/comingsoon");
  };

  return (
    <div className=" w-[300px]  h-auto bg-[#F5F5F5] ">
      <div
        id="Main"
        class="  h-fit min-h-screen rounded-r-3xl text-richblack-25  transform   xl:translate-x-0  ease-in-out transition duration-500 flex justify-start items-start   w-full sm:w-64 bg-top_nav_blue-500 flex-col"
      >
        <div class="mt-6 gap-y-3 flex flex-col justify-start items-center  pl-4 w-full border-gray-600 border-b space-y-2 pb-5 ">
          {/* 1 */}
          <div class="flex     mb-3 jusitfy-between items-center space-x-20 w-full  focus:outline-none   focus:font-semibold  text-richblack-25 rounded ">
            {logindetails.organisation.image ? (
              <img
                className=" ml-14  h-16  "
                src={logindetails.organisation.image}
              />
            ) : (
              <p class=" font-extrabold  font-montserrat text-2xl leading-4 ">
                {logindetails.organisation.orgName}
              </p>
            )}

            {/* setttings */}
            {/* {(logindetails.user.role === "ADMIN" ||
              logindetails.user.role === "SUPERADMIN") && (
              <a
                // onClick={() => EditBill(item)}
                // href="#"
                class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                data-te-toggle="tooltip"
                title="Settings"
              >
                <button
                  onClick={handleSettingClick}
                  class="flex text-white hover:text-white       "
                >
                  <IoSettingsSharp size={20} />
                </button>
              </a>
            )} */}
          </div>
          {/* settings dropdown  */}
          {(logindetails.user.role === "ADMIN" ||
            logindetails.user.role === "SUPERADMIN") && (
            <div class="flex flex-col justify-end items-center   w-full  ">
              <button
                onClick={() => showSettingsMenu()}
                class={`focus:outline-none ${
                  showSettings == ""
                    ? "font-semibold bg-top_nav_blue-300 text-white"
                    : ""
                } hover:bg-top_nav_blue-300  mr-3 rounded-xl hover:text-white focus:font-semibold text-left  text-richblack-25 flex justify-between items-center w-full space-x-14  `}
                // class="focus:outline-none hover:bg-top_nav_blue-300 mr-3 rounded-xl hover:text-white focus:font-semibold text-left text-richblack-25 flex justify-between items-center w-full space-x-14"
              >
                <div className="my-2 mx-2 flex justify-between items-center w-full">
                  <div className=" flex items-center gap-x-[10px]">
                    <IoSettingsSharp className=" h-[18px] w-[18px]" />
                    <p class="text-sm leading-5 font-montserrat uppercase">
                      Settings
                    </p>
                  </div>
                  <div class="text-richblack-25 focus:font-semibold">
                    <FaChevronDown
                      size={12}
                      className={` ${
                        showSettings == "" ? "rotate-270" : "-rotate-90"
                      }`}
                    />
                  </div>
                </div>
              </button>

              {/* sub menu  */}

              <div
                id="menu1"
                class={`flex ${showSettings} justify-start  flex-col w-full md:w-auto items-start pb-1 `}
              >
                <button
                  onClick={() => navigate("v1/settings/project")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Project
                  </p>
                </button>
                <button
                  onClick={() => navigate("v1/settings/user")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> User
                  </p>
                </button>

                {logindetails.user.role === "SUPERADMIN" && (
                  <button
                    onClick={() => navigate("v1/settings/organization")}
                    class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                  >
                    <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                      <PiDotOutlineFill /> Organization
                    </p>
                  </button>
                )}
                {logindetails.user.role === "SUPERADMIN" && (
                  <button
                    onClick={() => navigate("v1/settings/admin")}
                    class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                  >
                    <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                      <PiDotOutlineFill /> Admin
                    </p>
                  </button>
                )}
              </div>
            </div>
          )}
          {/* 4 */}
          {/* progress dropdown  */}
          {logindetails.user.role !== "SUPERADMIN" && (
            <div class="flex flex-col justify-end items-center   w-full  ">
              <button
                onClick={() => showProgressMenu()}
                class={`focus:outline-none ${
                  showProgress == ""
                    ? "font-semibold bg-top_nav_blue-300 text-white"
                    : ""
                } hover:bg-top_nav_blue-300  mr-3 rounded-xl hover:text-white focus:font-semibold text-left  text-richblack-25 flex justify-between items-center w-full space-x-14  `}
                // class="focus:outline-none hover:bg-top_nav_blue-300 mr-3 rounded-xl hover:text-white focus:font-semibold text-left text-richblack-25 flex justify-between items-center w-full space-x-14"
              >
                <div className="my-2 mx-2 flex justify-between items-center w-full">
                  <div className=" flex items-center gap-x-[10px]">
                    <GiProgression className=" h-[18px] w-[18px]" />
                    <p class="text-sm leading-5 font-montserrat uppercase">
                      Progress
                    </p>
                  </div>
                  <div class="text-richblack-25 focus:font-semibold">
                    <FaChevronDown
                      size={12}
                      className={` ${
                        showProgress == "" ? "rotate-270" : "-rotate-90"
                      }`}
                    />
                  </div>
                </div>
              </button>

              <div
                id="menu1"
                class={`flex ${showProgress} justify-start  flex-col w-full md:w-auto items-start pb-1 `}
              >
                {/* <button
                  onClick={() => navigate("v1/risk")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Dashboard
                  </p>
                </button> */}

                <button
                  onClick={() => navigate("v1/risk/status")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Status Monitor
                  </p>
                </button>

                <button
                  onClick={() => navigate("v1/risk/schedule")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Project Schedule
                  </p>
                </button>

                <button
                  onClick={() => navigate("v1/risk/rfi")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Rfi
                  </p>
                </button>
              </div>
            </div>
          )}
          {/* qcc dropdown  */}
          {logindetails.user.role !== "SUPERADMIN" && (
            <div class="flex flex-col justify-end items-center   w-full  ">
              <button
                //   onclick=""
                onClick={() => showMenu1()}
                class={`focus:outline-none ${
                  showQcc == ""
                    ? "font-semibold bg-top_nav_blue-300 text-white"
                    : ""
                } hover:bg-top_nav_blue-300  mr-3 rounded-xl hover:text-white focus:font-semibold text-left  text-richblack-25 flex justify-between items-center w-full space-x-14  `}
              >
                <div className=" my-2 mx-2  flex justify-between items-center  w-full ">
                  <div className=" flex items-center gap-x-[10px]">
                    {" "}
                    <MdOutlineHighQuality className=" h-[18px] w-[18px]" />{" "}
                    <p class="text-sm leading-5 font-montserrat uppercase">
                      Qcc
                    </p>
                  </div>
                  <div className="  text-richblack-25 focus:font-semibold ">
                    <FaChevronDown
                      size={12}
                      className={`  ${
                        showQcc == "" ? "rotate-270" : "-rotate-90"
                      }  `}
                    />
                  </div>
                </div>
              </button>
              <div
                id="menu1"
                class={`flex ${showQcc} justify-start  flex-col w-full md:w-auto items-start pb-1 `}
              >
                <button
                  onClick={() => navigate("v1/qcc/task")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Task
                  </p>
                </button>

                <button
                  onClick={() => navigate("v1/qcc/activity")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1  md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Activity
                  </p>
                </button>

                <button
                  onClick={() => navigate("v1/qcc/checklist")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1  md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Check List Reports
                  </p>
                </button>
              </div>
            </div>
          )}
          {/* contracts   */}
          {logindetails.user.role !== "SUPERADMIN" && (
            // <button
            //   onClick={() => showContractsMenu()}
            //   class="flex ml-1  hover:bg-top_nav_blue-300 mr-3 gap-x-[10px] py-2 px-1 rounded-xl hover:text-white jusitfy-start items-center w-full   focus:outline-none text-richblack-25 focus:font-semibold    "
            // >
            //   <FaFileContract className=" h-[18px] w-[18px]" />
            //   <p class="text-sm leading-5 font-montserrat  uppercase ">
            //     Contracts
            //   </p>

            // </button>
            <button
              onClick={showContractsMenu}
              class="flex ml-1  hover:bg-top_nav_blue-300 mr-3 gap-x-[10px] py-2 px-1 rounded-xl hover:text-white jusitfy-start items-center w-full   focus:outline-none text-richblack-25 focus:font-semibold    "
            >
              <FaFileContract className=" h-[18px] w-[18px]" />
              <p class="text-sm leading-5 font-montserrat  uppercase ">
                Contracts
              </p>
            </button>
          )}
          {/* material doropdown */}
          {logindetails.user.role !== "SUPERADMIN" && (
            <div class="flex flex-col justify-end items-center   w-full  ">
              <button
                onClick={() => showMaterialsMenu()}
                class={`focus:outline-none ${
                  showMaterials == ""
                    ? "font-semibold bg-top_nav_blue-300 text-white"
                    : ""
                } hover:bg-top_nav_blue-300  mr-3 rounded-xl hover:text-white focus:font-semibold text-left  text-richblack-25 flex justify-between items-center w-full space-x-14  `}
                // class="focus:outline-none hover:bg-top_nav_blue-300 mr-3 rounded-xl hover:text-white focus:font-semibold text-left text-richblack-25 flex justify-between items-center w-full space-x-14"
              >
                <div className="my-2 mx-2 flex justify-between items-center w-full">
                  <div className=" flex items-center gap-x-[10px]">
                    <GiTruck className=" h-[18px] w-[18px]" />
                    <p class="text-sm leading-5 font-montserrat uppercase">
                      Materials
                    </p>
                  </div>
                  <div class="text-richblack-25 focus:font-semibold">
                    <FaChevronDown
                      size={12}
                      className={` ${
                        showMaterials == "" ? "rotate-270" : "-rotate-90"
                      }`}
                    />
                  </div>
                </div>
              </button>

              <div
                id="menu1"
                class={`flex ${showMaterials} justify-start  flex-col w-full md:w-auto items-start pb-1 `}
              >
                <button
                  onClick={() => navigate("v1/materials/receive")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Material Receive
                  </p>
                </button>

                <button
                  onClick={() => navigate("v1/materials/store")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Store Management
                  </p>
                </button>

                <button
                  onClick={() => navigate("v1/materials/indent")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Indent
                  </p>
                </button>
              </div>
            </div>
          )}
          {/* resources dropdown  */}
          {logindetails.user.role !== "SUPERADMIN" && (
            <div class="flex  flex-col justify-end items-center   w-full  ">
              <button
                onClick={() => showResourcesMenu()}
                // class="focus:outline-none hover:bg-top_nav_blue-300 mr-3 rounded-xl hover:text-white focus:font-semibold text-left text-richblack-25 flex justify-between items-center w-full space-x-14"
                class={`focus:outline-none ${
                  showResources == ""
                    ? "font-semibold bg-top_nav_blue-300 text-white"
                    : ""
                } hover:bg-top_nav_blue-300  mr-3 rounded-xl hover:text-white focus:font-semibold text-left  text-richblack-25 flex justify-between items-center w-full space-x-14  `}
              >
                <div className="my-2 mx-2 flex justify-between items-center w-full">
                  <div className=" flex items-center gap-x-[10px]">
                    <HiTemplate className=" h-[18px] w-[18px]" />
                    <p class="text-sm leading-5 font-montserrat uppercase">
                      Resources
                    </p>
                  </div>
                  <div className="text-richblack-25 focus:font-semibold">
                    <FaChevronDown
                      size={12}
                      className={` ${
                        showResources == "" ? "rotate-270" : "-rotate-90"
                      }`}
                    />
                  </div>
                </div>
              </button>

              <div
                id="menu1"
                class={`flex ${showResources} justify-start  flex-col w-full md:w-auto items-start pb-1 `}
              >
                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Dashboard
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Work Category
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Vendor
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Labour Register
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Equipment Register
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Labour Deployment
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/resources")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Equipment Deployment
                  </p>
                </button>
              </div>
            </div>
          )}
          {/* document dropdown  */}
          {logindetails.user.role !== "SUPERADMIN" && (
            <div class="flex flex-col justify-end items-center   w-full  ">
              <button
                onClick={() => showDocumentsMenu()}
                class={`focus:outline-none ${
                  showDocuments == ""
                    ? "font-semibold bg-top_nav_blue-300 text-white"
                    : ""
                } hover:bg-top_nav_blue-300  mr-3 rounded-xl hover:text-white focus:font-semibold text-left  text-richblack-25 flex justify-between items-center w-full space-x-14  `}
                // class="focus:outline-none hover:bg-top_nav_blue-300 mr-3 rounded-xl hover:text-white focus:font-semibold text-left text-richblack-25 flex justify-between items-center w-full space-x-14"
              >
                <div className="my-2 mx-2 flex justify-between items-center w-full">
                  <div className=" flex items-center gap-x-[10px]">
                    <MdOutlineDocumentScanner className=" h-[18px] w-[18px]" />
                    <p class="text-sm leading-5 font-montserrat uppercase">
                      Documents
                    </p>
                  </div>
                  <div class="text-richblack-25 focus:font-semibold">
                    <FaChevronDown
                      size={12}
                      className={` ${
                        showDocuments == "" ? "rotate-270" : "-rotate-90"
                      }`}
                    />
                  </div>
                </div>
              </button>

              <div
                id="menu1"
                class={`flex ${showDocuments} justify-start  flex-col w-full md:w-auto items-start pb-1 `}
              >
                <button
                  // onClick={() => navigate("v1/drawings")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Dashboard
                  </p>
                </button>

                <button
                  // onClick={() => navigate("v1/drawings")}
                  onClick={() => navigate("v1/comingsoon")}
                  class="flex hover:bg-top_nav_blue-300 w-[150px] justify-start items-center space-x-6 hover:text-richblack-25 focus:text-white focus:font-semibold text-richblack-25 rounded px-2 py-1 mt-1 md:w-52"
                >
                  <p class="text-sm font-montserrat text-[14px] flex items-center leading-4">
                    <PiDotOutlineFill /> Drawings
                  </p>
                </button>
              </div>
            </div>
          )}
          {/* reports */}
          <button
            onClick={handleReportClick}
            class="flex ml-1  hover:bg-top_nav_blue-300 mr-3 gap-x-[10px] py-2 px-1 rounded-xl hover:text-white jusitfy-start items-center w-full   focus:outline-none text-richblack-25 focus:font-semibold    "
          >
            <TbReportAnalytics className=" h-[18px] w-[18px]" />
            <p class="text-sm leading-5 font-montserrat  uppercase ">Reports</p>
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
