import "./css/UsersShow.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Input,
  notification,
  Form,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  countActionUserManagement,
  deleteUserById,
  updateUsersById,
  updateUsersPassword,
  userDetails,
} from "../../../redux-core/userManagement/actions";
import { redirectAction } from "../../../redux-core/login/actions";
import { selectorUserDetail } from "../../../redux-core/userManagement/selectors";
import { countAction, getAllProj } from "../../../redux-core/settings/actions";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";
import { IoIosAdd } from "react-icons/io";
import { MdDelete, MdEdit, MdOutlineLockReset } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaFileUpload } from "react-icons/fa";
import { LuClipboardType } from "react-icons/lu";
import UserCreate from "./UserCreate";
import toast from "react-hot-toast";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  updateUser: (id) => dispatch(updateUsersById(id)),
  updateUsersPassword: (id) => dispatch(updateUsersPassword(id)),
  delUser: (id) => dispatch(deleteUserById(id)),
  getProj: (orgID, userId) => dispatch(getAllProj(orgID, userId)),
  getUsers: (orgID) => dispatch(userDetails(orgID)),
  count: (orgID) => dispatch(countAction(orgID)),
});

const UsersShow = () => {
  const getColumnSearchProps = useSearch();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState();
  const [userRow, setUserRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const allProjData = useSelector(makeSelectAllProj);
  const allUsersData = useSelector(selectorUserDetail);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const children = [];
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgID = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [activedata, setActivedata] = useState(true);
  const Spindata = useSelector((item) => item.spinreducer);
  const [resetModal, setResetModal] = useState(false);
  const [rowAdminId, setRowAdminId] = useState();
  const [rowName, setRowName] = useState();
  const [rowMail, setRowMail] = useState();

  const { getUsers, getProj, delUser, count, updateUser, updateUsersPassword } =
    actionDispatch(useDispatch());

  const options = [
    { value: "SITEENGINEER", label: "Site Engineer" },
    { value: "SITEINCHARGE", label: "Site InCharge" },
    { value: "SUPERVISOR", label: "Supervisor" },
    { value: "DRAWINGARCHITECTURE", label: "Drawing Architecture" },
    { value: "BILLINGENGINEER", label: "Billing Engineer" },
    { value: "QCENGINEER", label: "Quality Control Engineer" },
    { value: "PROCUREMENTMANAGER", label: "Procurement Manager" },
    { value: "PLANNINGENGINEER", label: "Planning Engineer" },
  ];

  useEffect(() => {
    getUsers(orgID);
  }, []);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
      width: "100px",
    },
    // {
    //   title: "User ID",
    //   dataIndex: "admin_id",
    //   ...getColumnSearchProps("admin_id"),
    // },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Address",
      dataIndex: "address",
      ...getColumnSearchProps("address"),
    },
    // {
    //   title: "Username",
    //   dataIndex: "username",
    //   ...getColumnSearchProps("username"),
    // },
    {
      title: "Role",
      dataIndex: "role",
      ...getColumnSearchProps("role"),
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
    },
    {
      title: "Reset Password",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => resetPasword(record)}>
              Reset
              <EllipsisOutlined />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "200px",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const resetPasword = (data) => {
    setResetModal(true);
    setRowAdminId(data.admin_id);
    setRowName(data.name);
    setRowMail(data.email);
  };
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });
  useEffect(() => {
    form2.setFieldsValue({
      name: rowName,
      email: rowMail,
    });
  }, [rowName, rowMail]);

  // reset password form
  const onResetPasswordFinish = async (e) => {
    e.preventDefault();

    if (
      resetpasswordFormData.password != resetpasswordFormData.confirmPassword
    ) {
      toast.error("Password and Resetpassword not matching..");
      return;
    }

    let data = {
      admin_id: rowAdminId,
      password: resetpasswordFormData.password,
    };

    console.log("Reset password form data: ", data);
    // return;
    try {
      const response = await updateUsersPassword(data);
      if (response.success) {
        toast.success("Success..");
        console.log("Rwesponce: ", response);
        form2.resetFields();
        setResetModal(false);
      }
    } catch (err) {
      toast.error("Failed..");
      console.log("Errror: ", err);
    }
  };
  const onResetPasswordFinishFailed = (error) => {
    console.log("ERROR", error);
  };

  const onEditUserFinish = async (e) => {
    e.preventDefault();

    let data = {
      ...editUserFormData,

      password: "", //for bug not showing so this one hard core send

      username: editUserFormData.username,
      orgID: orgID,
      createdBy: userId,
      del_status: 0,
      admin_id: userRow.admin_id,
    };
    try {
      let response = await updateUser(data);
      if (response) {
        setEditUserFormData({
          name: "",
          address: "",
          username: "",

          email: "",
          phone: "",
          role: "",
          project_id: [],
        });
        toast.success("Updated..");
        await setIsModalVisible(false);
        await getUsers(orgID);
      }
    } catch (err) {
      toast.error("Failed..");
      console.log("Error: ", err);
    }
  };

  const [editUserFormData, setEditUserFormData] = useState({
    name: "",
    address: "",
    username: "",
    password: "",
    confirm_password: "",
    email: "",
    phone: "",
    role: "",
    project_id: [],
  });

  const onEditUserFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /**
   * modal form on edit
   * @param {*} data
   */
  const showModal = (data) => {
    getProj(orgID, userId);
    // setFilteredData([]);
    setUserRow(data);

    // Check if data.project_id is null or undefined, and handle the case
    if (data.project_id === null || data.project_id === undefined) {
      console.error("data.project_id is null or undefined");
      // You can choose to handle this case by showing an error message or taking appropriate action.
      // For example, you might return early to prevent the modal from visibleing:
      setIsModalVisible(true);
      setFilteredData([]);
      return;
    }

    const tempFilteredData = [];

    if (data.project_id) {
      const projID = data.project_id.split(",");
      projID.forEach((item) => {
        const matchingProject = allProjData.find(
          (project) => project.project_id == item
        );
        if (matchingProject) {
          tempFilteredData.push(`${matchingProject.project_id}`);
        }
      });
    }

    setFilteredData(tempFilteredData);
    setIsModalVisible(true);
    form.setFieldsValue({
      name: data.name,
      email: data.email,
      // username: data.username,
      //password: data.password,
      phone: data.phone,
      address: data.address,
      role: data.role,
      project_id: filteredData,

      // image: projRow.image
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      project_id: filteredData,
    });
  }, [filteredData]);

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };
  const onConfirm = async (key) => {
    await delUser(key);
    await getUsers(orgID);
    await count(orgID);
  };

  const deleteSelected = () => {
    if (selectedKeys === undefined || selectedKeys.length === 0)
      notification.visible({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option key={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setResetModal(false);
  };

  const clear_fields = () => {
    getProj(orgID, userId);
    form.setFieldsValue({
      name: userRow.name,
      email: userRow.email,
      // username: userRow.username,
      // password: userRow.password,
      phone: userRow.phone,
      address: userRow.address,
      role: userRow.role,
      project_id: [userRow.project_id],
    });
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  // for excel export
  const datatable = [];

  for (let i = 0; i < allUsersData.length; i++) {
    if (allUsersData[i].role !== "ADMIN") {
      datatable.push({
        sl_no: i + 1,
        // key: allUsersData[i].admin_id,
        admin_id: allUsersData[i].admin_id,
        name: allUsersData[i].name,
        email: allUsersData[i].email,
        phone: allUsersData[i].phone,
        address: allUsersData[i].address,
        // username: allUsersData[i].username,
        // project_id: allUsersData[i].project_id,
        role: allUsersData[i].role,
        project_name: allUsersData[i].project_name,
      });
    }
  }

  //  for table data
  const data = [];
  for (let i = 0; i < allUsersData.length; i++) {
    if (allUsersData[i].role !== "ADMIN") {
      data.push({
        sl_no: i + 1,
        key: allUsersData[i].admin_id,
        admin_id: allUsersData[i].admin_id,
        name: allUsersData[i].name,
        email: allUsersData[i].email,
        phone: allUsersData[i].phone,
        address: allUsersData[i].address,
        // username: allUsersData[i].username,
        project_id: allUsersData[i].project_id,
        role: allUsersData[i].role,
        project_name: allUsersData[i].project_name,
      });
    }
  }

  const [showAddUser, setShowAddUser] = useState(false);

  // adding colsole log - shivam kashyap 19 march testing
  useEffect(() => {
    console.log("users fro users-----", data);
    console.log("all user data -----", allUsersData);
  }, []);

  const [resetpasswordFormData, setResetPasswordfFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    console.log("FD: ", editUserFormData);
  }, [setEditUserFormData, editUserFormData]);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        {/* <Table
          className="table"
          scroll={{ x: "130vw", y: 550 }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          pagination={10}
        /> */}

        {/* new table shivam kashyap 3 june 2024  */}
        <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
          <div className=" flex justify-between mt-3  mx-11 ">
            <div className="   font-montserrat">
              <button
                // onClick={onUploadWorkBtn}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <CSVLink
                  filename={"Users_Table.csv"}
                  data={datatable}
                  className=""
                  onClick={() => {
                    toast.success("The file is downloading..");
                    message.success("The file is downloading");
                  }}
                >
                  Export to Excel
                </CSVLink>
              </button>
            </div>
            <div className=" font-semibold  text-2xl">User Details</div>
            <div className=" font-montserrat">
              <button
                onClick={() => setShowAddUser(true)}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <span>
                  <IoIosAdd size={20} />
                </span>
                Add User
              </button>
            </div>
          </div>
        </div>

        {/* table  */}
        <div className=" font-montserrat mt-5  ml-4    flex flex-col items-center  justify-center -green-300">
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[100px]">
                <p className="text-sm font-semibold text-gray-900">Sr No.</p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900"> Name</p>
              </div>
              <div className="flex flex-col blue-200 items-start  w-[200px]">
                <p className="text-sm font-semibold text-gray-900">Email</p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">Phone</p>
              </div>
              <div className="flex flex-col blue-200 items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900">Address</p>
              </div>
              <div className="flex flex-col blue-200 items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900">Project</p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">Role</p>
              </div>

              <div className="flex richblue-200 flex-col items-start w-[160px]">
                <p className="text-sm  font-semibold text-gray-900">Action</p>
              </div>
            </li>
          </ul>
          <ul
            role="list"
            className="divide-y w-[1200px]  divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
          >
            {data && data.length
              ? data.map((item, key) => (
                  <li
                    className={`flex justify-between ${
                      key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }  items-start px-5  py-2`}
                  >
                    <div className="flex flex-col items-start -yellow-200 w-[100px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {key + 1}
                      </p>
                    </div>

                    <div className="flex flex-col blue-200 items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.name ? item.name : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.email ? item.email : "-"}
                      </p>
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {item?.phone ? item.phone : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.address ? item.address : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.project_name ? item.project_name : "-"}
                      </p>
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {item?.role ? item.role : "-"}
                      </p>
                    </div>

                    <div className="flex richblue-300   gap-x-2 items-start w-[160px]">
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Reset Password"
                      >
                        <MdOutlineLockReset
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => resetPasword(item)}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Edit User"
                      >
                        <MdEdit
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => {
                            showModal(item);
                            console.log("item: ", item);
                            setEditUserFormData({
                              name: item.name,
                              address: item.address,
                              email: item.email,
                              phone: item.phone,
                              role: item.role,
                              project_id: item.project_id
                                .split(",")
                                .map((item) => Number(item)),
                            });
                          }}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Delete User"
                      >
                        <MdDelete
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => deleteRow(item)}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>
        {/* modals  */}
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={1350}
          centered={true}
          footer={null}
        >
          <div className=" font-semibold mb-4  text-2xl font-montserrat">
            Edit User Details
          </div>
          {/* <Form
            form={form}
            name="add-User"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditUserFinish}
            onFinishFailed={onEditUserFinishFailed}
          >
            <Form.Item
              name="name"
              label="Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter  name!",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              className="input-field"
              rules={[
                {
                  type: "email",
                  message: "This input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter  E-mail!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please enter  phone number!" },
                {
                  pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                  // pattern: /^[\d]{10}$/,
                  // message: "Contact number should be 10 digits",
                  message: "Please Enter The Correct Contact Number",
                },
              ]}
              className="input-field"
            >
              <Input type={"number"} placeholder="Phone number" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter  address!",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 charactera only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Form.Item
              name="role"
              label="User Role"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Role!",
                },
              ]}
            >
              <Select
                placeholder="Please select Role"
                value={options.value}
                options={options}
                defaultValue={options[""]}
              ></Select>
            </Form.Item>

            <Form.Item
              name="project_id"
              label="Select Projects"
              rules={[
                {
                  required: true,
                  message: "Please select one or more projects!",
                },
              ]}
              className="input-field"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Projects"
                onChange={handleChange}
              >
                {children}
              </Select>
            </Form.Item>
            <Form.Item>
              <div className="btn-user">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_fields} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}

          {/* new form shivam kashyap - 4 june 2024 */}
          <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={onEditUserFinish}
          >
            {/* 1a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Name
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="name"
                    value={editUserFormData.name}
                    onChange={(e) => {
                      setEditUserFormData({
                        ...editUserFormData,
                        name: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    placeholder="Enter User Name"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2  */}
              <div className="">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Email
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="email"
                    name="name"
                    value={editUserFormData.email}
                    onChange={(e) => {
                      setEditUserFormData({
                        ...editUserFormData,
                        email: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    placeholder="Enter email address"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 3 date */}
              <div className="">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Phone
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="number"
                    name="phone"
                    value={editUserFormData.phone}
                    onChange={(e) => {
                      setEditUserFormData({
                        ...editUserFormData,
                        phone: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    placeholder="Enter phone number"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
            </div>
            {/* 2a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  User Role
                </label>
                <select
                  required
                  name="role"
                  onChange={(e) => {
                    // console.log("setting values");
                    setEditUserFormData({
                      ...editUserFormData,
                      role: e.target.value,
                    });
                  }}
                  value={editUserFormData.role}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  placeholder="select type of area"
                >
                  <option value="">Select</option>
                  {options &&
                    options.map((item, key) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                </select>
              </div>
              {/* 2  */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="project_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Select Projects
                </label>
                <select
                  // required
                  name="project_id"
                  onChange={(e) => {
                    // console.log("setting values");
                    setEditUserFormData({
                      ...editUserFormData,
                      project_id: [
                        ...editUserFormData.project_id,
                        Number(e.target.value),
                      ],
                    });
                  }}
                  value={editUserFormData.project_id}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  placeholder="select type of area"
                >
                  <option value="">Select</option>
                  {children &&
                    children.map((item, key) => (
                      <option value={item.props.children[2]}>
                        {item.props.children[0] +
                          " - " +
                          item.props.children[2]}
                      </option>
                    ))}
                </select>
              </div>
              {/* 2 selected projects list */}
              <div className="">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Address
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="address"
                    value={editUserFormData.address}
                    onChange={(e) => {
                      setEditUserFormData({
                        ...editUserFormData,
                        address: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    placeholder="Enter address"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
            </div>{" "}
            {/* 3a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              {/* 2 selected projects list */}
              <div className="mt w-[300px] flex flex-col gap-y-1">
                <label
                  htmlFor="project_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Selected Projects
                </label>
                {editUserFormData.project_id.map((item, key) => (
                  <div className=" flex gap-x-3">
                    <div> {item}</div>{" "}
                    <a
                      onClick={() => {
                        setEditUserFormData({
                          ...editUserFormData,
                          project_id: [
                            ...editUserFormData.project_id.filter(
                              (val) => val != item
                            ),
                          ],
                        });
                        toast.success("Deleted..");
                      }}
                      href="#"
                      class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                      data-te-toggle="tooltip"
                      title="Delete"
                    >
                      <MdDelete
                        className=" text-top_nav_blue-300 "
                        size={18}
                        // onClick={() => deleteRow(item)}
                      />
                    </a>{" "}
                  </div>
                ))}
              </div>
              {/* 2 */}
              <div className="  w-[300px]"></div>{" "}
              <div className="  w-[300px]"></div>
              {/* 3 */}
            </div>
            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
              <button
                onClick={() => {
                  setEditUserFormData({
                    name: "",
                    address: "",
                    username: "",
                    password: "",
                    confirm_password: "",
                    email: "",
                    phone: "",
                    role: "",
                    project_id: [],
                  });
                }}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        {/* reset password form  */}
        <Modal
          visible={resetModal}
          footer={null}
          onCancel={handleCancel}
          width={800}
        >
          <div className=" font-semibold mb-4  text-2xl font-montserrat">
            Reset User Password
          </div>
          {/* <div className="edit__user">Reset User Password</div> */}
          {/* <Form
            form={form2}
            name="reset_password"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onResetPasswordFinish}
            onFinishFailed={onResetPasswordFinishFailed}
          >
            <Form.Item
              name="password"
              label="Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/,
                  message:
                    "Password must contain at least  uppercase letter,  lowercase letter, and  number, and be at least 6 to 12 characters long",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                validateConfirmPassword,
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
              <div className="btn-user">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}
          <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={onResetPasswordFinish}
          >
            {" "}
            {/* 1a  */}
            <div className="flex justify-evenly w-[60vw]">
              {/* 1 */}
              <div className="">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Password
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="password"
                    value={resetpasswordFormData.password}
                    onChange={(e) => {
                      setResetPasswordfFormData({
                        ...resetpasswordFormData,
                        password: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    placeholder="Enter password"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2  */}
              <div className="">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Reset Password
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="confirmPassword"
                    value={resetpasswordFormData.confirmPassword}
                    onChange={(e) => {
                      setResetPasswordfFormData({
                        ...resetpasswordFormData,
                        confirmPassword: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    placeholder="Enter reset password"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
            </div>
            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
              <button
                onClick={() => {
                  setResetPasswordfFormData({
                    password: "",
                    confirmPassword: "",
                  });
                }}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          visible={showAddUser}
          footer={null}
          onCancel={() => setShowAddUser(false)}
          width={1350}
        >
          <UserCreate setShowAddUser={setShowAddUser} />
        </Modal>
      </Spin>
    </>
  );
};

export default React.memo(UsersShow);
