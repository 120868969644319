import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import "./css/AddProjectDetailsForm.css";
import { Form, Input, Button, Upload, Select, Modal, Dropdown } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import {
  getAllProj,
  createProjDetails,
  countAction,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => getAllProj(orgId, userId),
  projDetails: (projDetails) => dispatch(createProjDetails(projDetails)),
  count: (orgId) => dispatch(countAction(orgId)),
  getAllProjDetails: (projectId, orgId, userId) =>
    dispatch(getAllProjDetails(projectId, orgId, userId)),
});

const AddProjectDetailsForm = (props) => {
  const { getProj, projDetails, count, getAllProjDetails } = actionDispatch(
    useDispatch()
  );
  const [formProjectDetails] = Form.useForm();
  const [imagecard, setImagecard] = useState(false);
  const children = [];
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getProj(orgId, userId);
  }, []);

  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}-
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }

  const onProjectDetailFinish = async (e) => {
    e.preventDefault();
    // setIsDisableInput(true);
    let data = {
      ...formData,
      ...imageFile,
      orgID: orgId,
      project_id: props.projectId,
    };
    if (data.image_1 && data.image_1.length < 1) delete data.image_1;
    // if (data.image_2 && data.image_2.length < 1) delete data.image_2;
    // if (data.image_3 && data.image_3.length < 1) delete data.image_3;
    // if (data.image_4 && data.image_4.length < 1) delete data.image_4;
    // if (data.image_5 && data.image_5.length < 1) delete data.image_5;
    console.log("data before submission: ", data);
    // return;

    await projDetails(data);
    await clear_ProjectDetails_field();
    await count(orgId);
    getAllProjDetails(props.projectId, orgId, userId);
    props.setShowAddLocationModal(false);
  };

  const onProjectDetailFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFileForImg1 = (e) => {
    if (e.fileList.length > 0) setImagecard({ ...imagecard, image_1: true });
    else setImagecard({ ...imagecard, image_1: false });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // const normFileForImg2 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_2: true });
  //   else setImagecard({ ...imagecard, image_2: false });
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg3 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_3: true });
  //   else setImagecard({ ...imagecard, image_3: false });
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg4 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_4: true });
  //   else setImagecard({ ...imagecard, image_4: false });

  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg5 = (e) => {
  //   if (e.fileList.length > 0) setImagecard({ ...imagecard, image_5: true });
  //   else setImagecard({ ...imagecard, image_5: false });
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  const clear_ProjectDetails_field = () => {
    setIsDisableInput(false);
    formProjectDetails.setFieldsValue({
      // project_id: [],
      block: "",
      image_1: [],
      // image_2: [],
      // image_3: [],
      // image_4: [],
      // image_5: [],
    });
    setImagecard({
      image_1: false,
      // image_2: false,
      // image_3: false,
      // image_4: false,
      // image_5: false,
    });
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.url || file.preview);
    setPreviewvisible(true);
  };

  // const modalCancel = () =>{
  //   setShowProjectTypesModal(false)
  // }

  // const [typeProject, setTypeProject] = useState("")
  // const [showProjectTypesModal, setShowProjectTypesModal] = useState(false)

  // const handleProjectTypes = (value) =>{
  //   setTypeProject(value)
  //   setShowProjectTypesModal(true)
  // }
  const [formData, setFormData] = useState({
    block: "",
  });
  const [imageFile, setImageFile] = useState([]);

  return (
    <>
      {/* <Form
        form={formProjectDetails}
        name="project-details"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onProjectDetailFinish}
        onFinishFailed={onProjectDetailFinishFailed}
      >
      

        <Form.Item
          className="input-field-showLevel1"
          label="Description"
          name="block"
          rules={[
            {
              required: false,
              message: "Please enter Description !",
            },
            {
              pattern: /^(.{0,300})$/,
              message: "please enter less than 300 characters only",
            },
          ]}
        >
          <Input
            placeholder="Ex: Level 1:foundation, Level2: Slab"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="image_1"
          label="Upload"
          valuePropName="fileList"
          getValueFromEvent={normFileForImg1}
          className="input-field-images"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file, fileList) => {
              return false;
            }}
          >
            {!imagecard.image_1 && uploadButton}
          </Upload>
        </Form.Item>


        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_ProjectDetails_field} className="btn-cancel">
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form> */}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>

      {/* new form shivam kashyap 28 may 2024  */}
      <div className="font-semibold  mb-5 text-2xl font-montserrat">
        Add Project Location
      </div>
      <form
        className="font-montserrat flex flex-col items-center "
        onSubmit={onProjectDetailFinish}
      >
        {/* 1a */}
        <div className="flex justify-evenly w-[60vw]">
          {/* 1   */}
          <div className="">
            <label
              htmlFor="problem_area"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Description
            </label>
            <div className="mt- w-[300px]">
              <input
                required
                type="text"
                name="problem_area"
                value={formData.description}
                onChange={(e) => {
                  setFormData({ ...formData, block: e.target.value });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
          {/* 2 image */}
          <div className="w-[300px] ">
            <label
              htmlFor="image"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {/* <span className="text-red-600">*</span> */}
              Upload
            </label>
            <input
              // required
              class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="file_input_help"
              id="image"
              type="file"
              accept="image/svg+xml, image/png, image/jpeg, image/gif"
              onChange={async (event) => {
                const fileInput = event.target;
                const file = fileInput.files[0];

                if (file) {
                  setImageFile(file);
                  // console.log("filse set: ", imageFile);
                  let imageBase64String = "";
                  if (imageFile) {
                    try {
                      imageBase64String = await getBase64(file);
                    } catch (error) {
                      console.error("Error converting image to base64:", error);
                      toast.error(
                        "An error occurred while processing the image. Please try again."
                      );
                      return;
                    }
                  }

                  const imageObject = {
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    name: file.name,
                    originFileObj: file,
                    percent: 0, // Set percent as needed
                    size: file.size,
                    thumbUrl: imageBase64String, // Set thumbUrl as needed
                    type: file.type,
                    uid: "rc-upload-" + Date.now(), // Generate unique uid
                  };
                  imageObject.thumbUrl = imageObject.thumbUrl.replace(
                    "data:image/jpeg;",
                    "data:image/png;"
                  );

                  setImageFile({
                    image_1: [imageObject],
                  });
                }
              }}
            />
            <p
              class="mt-1 text-sm text-gray-500 dark:text-gray-300"
              id="file_input_help"
            >
              SVG, PNG, JPG or GIF .
            </p>
          </div>
        </div>
        {/* buttons  */}
        <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
          <button
            // onClick={resetAllValue}
            type="button"
            class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            data-te-ripple-init
          >
            Reset
          </button>
          <button
            type="submit"
            data-te-ripple-init
            data-te-ripple-color="light"
            class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default AddProjectDetailsForm;
