import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorgetAllGrnItemCodeWise,
  selectorGetItemInCurrentStock,
  selectorGetItemInCurrentStockItemCodeWise,
} from "../../../../redux-core/materials/selectors";
import {
  getCurrentStockItemCodeWise,
  updateCurrentStockItem,
} from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { formatDate } from "../../../../utils/functions";

const actionDispatch = (dispatch) => ({
  updateCurrentStockItem: (itemCurrentStock) =>
    dispatch(updateCurrentStockItem(itemCurrentStock)),
  getCurrentStockItemCodeWise: (itemCode, projectID) =>
    dispatch(getCurrentStockItemCodeWise(itemCode, projectID)),
});

const ItemReceiptsStatement = (props) => {
  const { updateCurrentStockItem, getCurrentStockItemCodeWise } =
    actionDispatch(useDispatch());
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const getGrnItemCodeWise = useSelector(selectorgetAllGrnItemCodeWise);
  const [array, setArray] = useState("");
  const getItemInCurrentStock = useSelector(selectorGetItemInCurrentStock);
  const getShowItemInCurrentStockItemCodeWise = useSelector(
    selectorGetItemInCurrentStockItemCodeWise
  );

  useEffect(() => {
    getCurrentStockItemCodeWise(props.itemCode, props.projectID);
  }, [props.itemCode]);

  const tableData = [];
  let rcvQtySum = 0;
  let accQtySum = 0;
  let sumOfRate = 0;
  let sumOfCgst = 0;
  let sumOfSgst = 0;
  let sumOfIgst = 0;
  let sumOfTotal = 0;
  let sumOfTotalAcceptedAmount = 0;

  for (let i = 0; i < getGrnItemCodeWise.length; i++) {
    const item = getGrnItemCodeWise[i];

    const grnDateObject = new Date(item.grn_date);

    const totalItemValue = Number(item.item_rate) * Number(item.accept_qty);
    const gstAmount =
      Number(item.c_gst) + Number(item.c_gst) + Number(item.i_gst);
    const totalgst = Number(gstAmount) * Number(item.accept_qty);

    const TotalAcceptedAmount = Number(totalItemValue) + Number(totalgst);

    tableData.push({
      index: i + 1,
      item_code: item.item_code,
      material_name: item.material_name,
      po_number: item.po_number,
      po_date: item.po_date,
      grn_no: item.grn_no,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      rcv_qty: item.rcv_qty,
      accept_qty: item.accept_qty,
      item_rate: item.item_rate,
      c_gst: item.c_gst,
      s_gst: item.s_gst,
      i_gst: item.i_gst,
      total_amount: totalItemValue,
      total_accepted_amount: TotalAcceptedAmount,
    });

    rcvQtySum += item.rcv_qty;
    accQtySum += item.accept_qty;
    sumOfRate += item.item_rate;
    sumOfCgst += item.c_gst;
    sumOfSgst += item.s_gst;
    sumOfIgst += item.i_gst;
    sumOfTotal += totalItemValue;
    sumOfTotalAcceptedAmount += TotalAcceptedAmount;
  }

  // Add sum row
  tableData.push({
    index: "Total",
    rcv_qty: rcvQtySum,
    accept_qty: accQtySum,
    item_rate: sumOfRate,
    c_gst: sumOfCgst,
    s_gst: sumOfSgst,
    i_gst: sumOfIgst,
    total_amount: sumOfTotal,
    total_accepted_amount: sumOfTotalAcceptedAmount,
  });

  const columns = [
    {
      title: "Sl No.",
      dataIndex: "index",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
    },
    {
      title: "PO Date",
      dataIndex: "po_date",
    },
    {
      title: "GRN NO.",
      dataIndex: "grn_no",
    },
    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Quantity Received",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quantity Accepted",
      dataIndex: "accept_qty",
    },
    {
      title: "Amount",
      dataIndex: "item_rate",
    },
    {
      title: "C GST",
      dataIndex: "c_gst",
    },
    {
      title: "S GST",
      dataIndex: "s_gst",
    },
    {
      title: "I GST",
      dataIndex: "i_gst",
    },
    {
      title: "Total Rate Of Amount",
      dataIndex: "total_amount",
    },
    {
      title: "Total",
      dataIndex: "total_accepted_amount",
    },
  ];

  const localpurchaseqty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.without_po_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.without_po_qty;
  const repleaceqty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.replace_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.replace_qty;
  const transferQty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_to_other_site ===
    undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_to_other_site;
  const recieveeqty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.site_transfer_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.site_transfer_qty;
  const issueQty =
    getShowItemInCurrentStockItemCodeWise?.[0]?.issue_qty === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.issue_qty;
  const Issue_values =
    getShowItemInCurrentStockItemCodeWise?.[0]?.Issue_qty_value === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.Issue_qty_value;
  const localpurchaseqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.local_purchase_value ===
    undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.local_purchase_value;
  const recieveqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_value === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.transfer_value;
  const transferqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.recieve_transfer_qty ===
    undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.recieve_transfer_qty;
  const replaceqtyValue =
    getShowItemInCurrentStockItemCodeWise?.[0]?.replace_value === undefined
      ? 0
      : getShowItemInCurrentStockItemCodeWise?.[0]?.replace_value;

  const HandelUpdateQtyInStore = async () => {
    let StoreData = {
      id: getItemInCurrentStock[0].id,
      project_id: props.projectID,
      // item_code: tableData[tableData.length - 2].item_code,
      Item_name: tableData[tableData.length - 2].material_name,
      rcv_qty: tableData[tableData.length - 1].rcv_qty,
      accept_qty: tableData[tableData.length - 1].accept_qty,
      total_qty:
        Number(tableData[tableData.length - 1].accept_qty) +
        Number(localpurchaseqty) +
        Number(repleaceqty) +
        Number(recieveeqty) -
        Number(issueQty) -
        Number(transferQty),
      isChecked: 1,
      orgID: orgId,
      item_rate:
        Number(tableData[tableData.length - 1].total_amount) +
        Number(localpurchaseqtyValue) +
        Number(recieveqtyValue) +
        Number(replaceqtyValue) -
        Number(Issue_values) -
        Number(transferqtyValue),
    };
    await updateCurrentStockItem(StoreData);
  };

  return (
    <>
      <div className="BtnContainer1">
        {/* <Button className="button-61" onClick={HandelUpdateQtyInStore}>
          Quantity Update In Store
        </Button> */}

        <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
          <div className=" flex justify-between mt-3  mx-11 ">
            <div className="   font-montserrat flex flex-col gap-y-2">
              <button
                onClick={HandelUpdateQtyInStore()}
                type="button"
                class="flex  w-fit justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Quantity Update In Store
              </button>
            </div>
            <div className=" font-semibold  text-2xl"></div>
            <div className=" font-montserrat"></div>
          </div>
        </div>
      </div>
      {/* <Table
        className="custom-table"
        dataSource={Array.isArray(array) ? array : tableData}
        columns={columns}
        pagination={10}
        scroll={{ x: "90vw" }}
        bordered
        size="large"
      /> */}

      {/* new table shivam kashyap 20 june 2024  */}
      <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
        <ul
          role="list"
          className="divide-y w-[1450px] overflow-x-auto divide-[#6b6060]"
        >
          <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
            <div className="flex flex-col items-start w-[100px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Sl No.
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Item Code
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Item Name
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                PO Number
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                PO Date
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                GRN NO.
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                GRN Date
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Quantity Received
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Quantity Accepted
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Amount
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                C GST
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                S GST
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                I GST
              </p>
            </div>
            <div className="flex flex-col items-start w-[250px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Total Rate Of Amount
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Total
              </p>
            </div>
          </li>
        </ul>{" "}
        <ul
          role="list"
          className="divide-y w-[1450px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[60vh] "
        >
          {tableData && tableData.length
            ? tableData.map((item, key) => (
                <li
                  key={key}
                  className={`flex justify-between items-center px-5 py-2 ${
                    key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                  }`}
                >
                  {" "}
                  <div className="flex flex-col items-start w-[100px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {key + 1}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.item_code ? item.item_code : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.material_name ? item.material_name : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.po_number ? item.po_number : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.po_date ? formatDate(item.po_date) : "-"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.grn_no ? item.grn_no : "-"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.grn_date ? formatDate(item.grn_date) : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.rcv_qty ? item.rcv_qty : "0"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.accept_qty ? item.accept_qty : "0"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.total_accepted_amount
                        ? item.total_accepted_amount
                        : "0"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.c_gst ? item.c_gst : "0"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.s_gst ? item.s_gst : "0"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.i_gst ? item.i_gst : "0"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[250px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.item_rate ? item.item_rate : "0"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.total_amount ? item.total_amount : "0"}
                    </p>
                  </div>
                </li>
              ))
            : "No records found..."}
        </ul>
      </div>
    </>
  );
};

export default ItemReceiptsStatement;
