import "./css/AddWorkCategory.css";
import "antd/dist/antd.min.css";
import React from "react";
import AddWorkCategoryForm from "./Forms/AddWorkCategoryForm";

function AddWorkCategory() {
  return (
    <>
      <div className="work-category">
        <div className="sub-title">Add Work Category</div>
        <br />
        <AddWorkCategoryForm />
      </div>
    </>
  );
}

export default React.memo(AddWorkCategory);
