import "./css/ListLabourRegister.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  message,
  Upload,
  Space,
  Image,
} from "antd";
import { ShowDeleteConfirm } from "../../popupmodal";
import { UploadOutlined } from "@ant-design/icons";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { redirectAction } from "../../../redux-core/login/actions";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import AddLabourRegister from "./Forms/AddLabourRegisterForm";
import {
  getAllLabour,
  updateByLabourId,
  deleteByLabourId,
  getAllcategory,
  getAllcontrator,
  getState,
} from "../../../redux-core/resources/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import {
  selectorgetLabourRagisterState,
  selectorgetAllWorkState,
  selectorgetAllSubContractorState,
  selectorgetAllStateState,
} from "../../../redux-core/resources/selectors";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getAllLabourRagister: (orgId, userId) =>
    dispatch(getAllLabour(orgId, userId)),
  updateByLabourRagisterId: (labourId) => dispatch(updateByLabourId(labourId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
  delecteLabour: (id) => dispatch(deleteByLabourId(id)),
  //count: (orgId) => dispatch(countAction(orgId)),
  getAllCategory: (orgId, userId) => dispatch(getAllcategory(orgId, userId)),
  getAllcontrator: (orgId, userId) => dispatch(getAllcontrator(orgId, userId)),
  getState: () => dispatch(getState()),
});

const ListLabourregister = () => {
  // console.log("List Labour Register");
  const getColumnSearchProps = useSearch();
  //const [filteredData, setFilteredData] = useState([]);
  const {
    getAllLabourRagister,
    updateByLabourRagisterId,
    delecteLabour,
    getAllCategory,
    getAllcontrator,
    getState,
  } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const allState = useSelector(selectorgetAllStateState);
  const allLabourRagister = useSelector(selectorgetLabourRagisterState);
  const AllWorkCategory = useSelector(selectorgetAllWorkState);
  const allSubContractor = useSelector(selectorgetAllSubContractorState);
  const [keys, setKeys] = useState();
  const [labourRow, setlabourRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectVendor, setSelectVendor] = useState();
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  useEffect(() => {
    getAllLabourRagister(logindetails.organisation.orgID, logindetails.user.id);
    getAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    getAllcontrator(logindetails.organisation.orgID, logindetails.user.id);
    getState();
  }, []);

  const children = [];
  for (let i = 0; i < allState.length; i++) {
    children.push(
      <Option key={allState[i].id} value={allState[i].state}>
        {allState[i].state}
      </Option>
    );
  }

  // const rowSelection = {
  //     onChange: (selectedRowKeys, selectedRows) => {
  //       console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //       //console.log(selectedRowKeys);
  //     },
  //     getCheckboxProps: (record) => ({
  //       disabled: record.name === 'Disabled User',
  //       // Column configuration not to be checked
  //       name: record.name,
  //     }),

  //   };

  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  // model of Add Labour reg

  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    // console.log('Form values:', values);
    setVisible(false);
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };
  //---------------- modal and forms-----------------------------

  const onEditInLabourRegisterFinish = async (values) => {
    //conversion of array of string to array of numbers
    //console.log("first",values.category_id)
    let data = {
      labour_name: values.labour_name,
      sub_contractor_id: selectVendor,
      category_id: selectEditVendor,
      commnication_address: values.commnication_address,
      city_town: values.city_town,
      state_id: values.state_id,
      contact: values.contact,
      alter_contact: values.alter_contact,
      pan_no: values.pan_no,
      aadhaar_no: values.aadhaar_no,
      labour_qual: values.labour_qual,
      id: labourRow.key,
    };
    if (values.image && values.image.length < 1) {
      delete data.image;
    } else {
      data.image = values.image[0].size
        ? values.image
        : values.image[0].thumbUrl;
    }

    let response = await updateByLabourRagisterId(data);
    if (response) {
      await setlabourRow(data);
      await setIsModalVisible(false);
      await getAllLabourRagister(
        logindetails.organisation.orgID,
        logindetails.user.id
      );
    }

    // console.log("data", data)

    //console.log('Success:', response);
  };
  const onEditInLabourRegisterFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // console.log("allLabourRagister",allLabourRagister)
  let tableData = [];
  for (let i = 0; i < allLabourRagister.length; i++) {
    tableData.push({
      key: allLabourRagister[i].id,
      labour_name: allLabourRagister[i].labour_name,
      sub_contractor_id: allLabourRagister[i].sub_contractor_id,
      commnication_address: allLabourRagister[i].commnication_address,
      city_town: allLabourRagister[i].city_town,
      category_id: allLabourRagister[i].category_id,
      state_id: allLabourRagister[i].state_id,
      contact: allLabourRagister[i].contact,
      alter_contact: allLabourRagister[i].alter_contact,
      pan_no: allLabourRagister[i].pan_no,
      aadhaar_no: allLabourRagister[i].aadhaar_no,
      image: allLabourRagister[i].image,
      labour_qual: allLabourRagister[i].labour_qual,
      sub_contractor_name: allLabourRagister[i].sub_contractor_name,
      category_name: allLabourRagister[i].category_name,
    });
  }
  // console.log("tableData",tableData)

  let tableExcel = [];
  for (let i = 0; i < allLabourRagister.length; i++) {
    tableExcel.push({
      // key: allLabourRagister[i].id,
      labour_name: allLabourRagister[i].labour_name,
      sub_contractor_id: allLabourRagister[i].sub_contractor_id,
      commnication_address: allLabourRagister[i].commnication_address,
      city_town: allLabourRagister[i].city_town,
      category_id: allLabourRagister[i].category_id,
      state_id: allLabourRagister[i].state_id,
      contact: allLabourRagister[i].contact,
      alter_contact: allLabourRagister[i].alter_contact,
      pan_no: allLabourRagister[i].pan_no,
      aadhaar_no: allLabourRagister[i].aadhaar_no,
      image: allLabourRagister[i].image,
      labour_qual: allLabourRagister[i].labour_qual,
      sub_contractor_name: allLabourRagister[i].sub_contractor_name,
      category_name: allLabourRagister[i].category_name,
    });
  }
  // console.log("tableExcel", tableExcel)
  const showModal = (data1) => {
    // console.log("data1",data1)
    setSelectEditVendor(data1.category_id);

    setSelectVendor(data1.sub_contractor_id);
    setlabourRow(data1);
    setIsModalVisible(true);
    form.setFieldsValue({
      id: data1.key,
      labour_name: data1.labour_name,
      category_id: data1.category_name,
      sub_contractor_id: data1.sub_contractor_name,
      commnication_address: data1.commnication_address,
      city_town: data1.city_town,
      state_id: data1.state_id,
      contact: data1.contact,
      alter_contact: data1.alter_contact,
      pan_no: data1.pan_no,
      aadhaar_no: data1.aadhaar_no,
      labour_qual: data1.labour_qual,
      image: data1.image ? [{ thumbUrl: data1.image }] : [],
    });
  };
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  const handleCancel = () => {
    form.setFieldsValue({});
    setIsModalVisible(false);
  };

  const clear_fields = () => {
    console.log( labourRow.image
      ? [{ thumbUrl: labourRow.image }]
      : [],)
    form.setFieldsValue({
      labour_name: labourRow.labour_name,
      sub_contractor_id: labourRow.sub_contractor_name,
      category_id: labourRow.category_name,
      commnication_address: labourRow.commnication_address,
      city_town: labourRow.city_town,
      state_id: labourRow.state_id,
      contact: labourRow.contact,
      alter_contact: labourRow.alter_contact,
      pan_no: labourRow.pan_no,
      aadhaar_no: labourRow.aadhaar_no,
      labour_qual: labourRow.labour_qual,
      image: labourRow.image
        ? [{ thumbUrl: labourRow.image }]
        : [],
    });
  };

  const normFiles = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  form.setFieldsValue({
    labour_name: labourRow.labour_name,
    sub_contractor_id: labourRow.sub_contractor_name,
    category_id: labourRow.category_name,
    commnication_address: labourRow.commnication_address,
    city_town: labourRow.city_town,
    state_id: labourRow.state_id,
    contact: labourRow.contact,
    alter_contact: labourRow.alter_contact,
    pan_no: labourRow.pan_no,
    aadhaar_no: labourRow.aadhaar_no,
    labour_qual: labourRow.labour_qual,
    sub_contractor_name: labourRow.sub_contractor_name,
    category_name: labourRow.category_name,
  });

  const columns = [
    {
      title: "SL Number",
      dataIndex: "key",
      ...getColumnSearchProps("key"),
    },
    {
      title: "Labour Name",
      dataIndex: "labour_name",
      ...getColumnSearchProps("labour_name"),
    },
    {
      title: "Vendor Name",
      dataIndex: "sub_contractor_name",
      ...getColumnSearchProps("sub_contractor_name"),
    },
    {
      title: "State",
      dataIndex: "state_id",
      ...getColumnSearchProps("state_id"),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      ...getColumnSearchProps("contact"),
    },
    {
      title: "Work Category",
      dataIndex: "category_name",
      ...getColumnSearchProps("category_name"),
    },
    {
      title: "Labour Qualification",
      dataIndex: "labour_qual",
      ...getColumnSearchProps("Labour_Qual"),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
    // console.log(record);
  };
  const onConfirm = async (keys) => {
    await delecteLabour(keys);
    await getAllLabourRagister(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
    // await count(logindetails.organisation.orgID)
  };

  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else if (keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  // const data = []
  // for (let i = 0; i < allLabourRagister.length; i++) {
  //     data.push({
  //         key: allLabourRagister[i].id,
  //         id: allLabourRagister[i].id,
  //         labour_name: allLabourRagister[i].labour_name,
  //         sub_contractor_id: allLabourRagister[i].sub_contractor_id,
  //         category_id: allLabourRagister[i].category_id,
  //         commnication_address: allLabourRagister[i].commnication_address,
  //         city_town: allLabourRagister[i].city_town,
  //         state_id: allLabourRagister[i].state_id,
  //         contact: allLabourRagister[i].contact,
  //         alter_contact: allLabourRagister[i].alter_contact,
  //         pan_no: allLabourRagister[i].pan_no,
  //         aadhaar_no: allLabourRagister[i].aadhaar_no,
  //     })
  // }

  const [selectEditVendor, setSelectEditVendor] = useState(null);

  const handelEditVendor = (vendorId) => {
    setSelectEditVendor(vendorId);
  };

  

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const handleCancelmodal = () => setPreviewvisible(false);

  return (
    <>
      <div className="title-list-labour">List Labour Register</div>
      <br />
      <div className="btn__">
        <Button className="excel">
          <CSVLink
            filename={"list_labour.csv"}
            data={tableExcel}
            className="excel"
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to Excel
          </CSVLink>
        </Button>
        {/* <div>
                <Button className='btn-add' onClick={handleShowModal}>Add Labour Register</Button>
                    <Modal visible={visible} onCancel={handleHideModal} footer={null}     
                      width="75%"
                    style={{ top: 80 }}>
                        <AddLabourRegister onFinish={handleFinish} />
                    </Modal>
                </div> */}
        {/* <Button className="btn-bulk">+ Bulk Labour Register</Button> */}
      </div>
      <Table
        className="table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={10}
        scroll={{ x: "90vw" }}
      />
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        width={700}
        centered={true}
        footer={null}
      >
        <div className="edit-in__labour">Edit Labour Register</div>
        <br />
        <Form
          form={form}
          name="add-Labour_register"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onEditInLabourRegisterFinish}
          onFinishFailed={onEditInLabourRegisterFinishFailed}
        >
          <Form.Item
            name="labour_name"
            label="Labour Name"
            rules={[
              { required: true, message: "Please enter Name!" },
            ]}
            className="input-field"
          >
            <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>

          <Form.Item
            name="category_id"
            label="Work Category Name"
            rules={[
              { required: true, message: "Please select Work Category Name!" },
            ]}
            className="input-field"
          >
            <Select
              // mode="multiple"

              style={{ width: "100%" }}
              placeholder="Please select Work Category Name"
              onChange={(vendorId) => handelEditVendor(vendorId)}
            >
              {" "}
              {AllWorkCategory.filter(
                (item) => item.resource_type === "Labour"
              ).map((getViewAllCategory) => (
                <option
                  key={getViewAllCategory.id}
                  value={getViewAllCategory.id}
                >
                  {getViewAllCategory.category_name}
                </option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="sub_contractor_id"
            label="Vendor Name"
            rules={[
              { required: true, message: "Please select Vendor Name!" },
            ]}
            className="input-field"
          >
            <Select
              // mode="multiple"
              onChange={(id) => setSelectVendor(id)}
              style={{ width: "100%" }}
              placeholder="Please select Vendor Name"
            >
              {allSubContractor
                .filter((item) => item.category_id == selectEditVendor)
                .map((getAllcontrator) => (
                  <option key={getAllcontrator.id} value={getAllcontrator.id}>
                    {getAllcontrator.sub_contractor_name}
                  </option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="city_town"
            label="City/Town"
            rules={[
              { required: true, message: "Please enter City/Town nane!" },
            ]}
            className="input-field"
          >
            <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>

          <Form.Item
            name="state_id"
            label="State"
            rules={[{ required: true, message: "Please select State" }]}
            className="input-field"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Select State"
            >
              {children}
            </Select>
          </Form.Item>

          <Form.Item
            name="contact"
            label="Contact No"
            rules={[
              {
                required: false,
                message: "Please enter Contact Number !",
              },
              {
                pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                // pattern: /^[\d]{10}$/,
                // message: "Contact number should be 10 digits",
                message: "Please Enter Correct Contact Number",
              },
            ]}
            className="input-field"
          >
            <Input
              type="number"
              placeholder="Phone number"
              onKeyDown={(e) => onKeydown(e)}
            />
          </Form.Item>

          <Form.Item
            name="alter_contact"
            label="Alternate Contact No"
            rules={[
              {
                required: false,
                message: "Please enter Alternate Contact !",
              },
              {
                pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                // pattern: /^[\d]{10}$/,
                // message: "Contact number should be 10 digits",
                message: "Please enter Correct Contact Number",
              },
            ]}
            className="input-field"
          >
            <Input
              type="number"
              placeholder="Phone number"
              onKeyDown={(e) => onKeydown(e)}
            />
          </Form.Item>

          <Form.Item
            name="pan_no"
            label="PAN No"
            rules={[
              { required: false, message: "Please enter PAN Number!" },
              {
                pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                message: "Please enter Valid PAN Number!",
              },
            ]}
            className="input-field"
          >
            <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>
          <Form.Item
            name="image"
            label="Upload Image"
            valuePropName="fileList"
            getValueFromEvent={normFiles}
            className="input-field"
          >
            {/* action="/upload.do" */}
            <Upload name="logo" 
            listType="picture" 
            accept=".jpg,.jpeg,.png" 
            onPreview={handlePreview}
            maxCount={"1"}
            beforeUpload={(file, fileList) => {
              return false;
            }}
            >
              <Button icon={<UploadOutlined />}>Choosen File</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            className="input-field"
            name="aadhaar_no"
            label="Aadhar Card"
            rules={[
              {
                required: false,
                message: "Please enter Valid Adhar Card Number",
              },
            ]}
          >
            <Input onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>
          <Form.Item
            name="commnication_address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter Address!",
              },
            ]}
            className="input-field"
          >
            <Input.TextArea
              style={{ maxHeight: "70px", minHeight: "70px" }}
              onKeyDown={(e) => onKeydown(e)}
            />
          </Form.Item>
          <Form.Item>
            <div className="btn-list_labourReg">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>

              <Button onClick={clear_fields} className="btn-cancel">
                Reset
              </Button>
            </div>
          </Form.Item>
          <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelmodal}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        </Form>
      </Modal>
      <br />
      <Button className="copy" onClick={deleteSelected}>
        Delete All Selected
      </Button>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ListLabourregister);
