import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { getAllUploadMmpFile } from '../../../redux-core/settings/actions';
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';
import { selectorGetUploadFile } from '../../../redux-core/settings/selectors';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA64FD'];

const Progress = ({ projectId, onBack, backButtonPosition = { vertical: 'top', horizontal: 'left' } }) => {
  const [projectCounts, setProjectCounts] = useState([0, 0, 0, 0, 0]);
  const [taskProgress, setTaskProgress] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const scheduleData = useSelector(selectorGetUploadFile);

  useEffect(() => {
    const fetchProjectSchedule = async () => {
      try {
        await dispatch(getAllUploadMmpFile(projectId, orgId));
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProjectSchedule();
  }, [dispatch, projectId, orgId]);

  useEffect(() => {
    if (scheduleData) {
      const counts = [0, 0, 0, 0, 0]; // Started, Ended, Due, Delayed, Planned
      const today = new Date();
      const progressData = scheduleData.map(item => ({
        task_name: item.task_name,
        progress: item.progress || 0 // Handle null progress
      }));
      setTaskProgress(progressData);

      scheduleData.forEach(item => {
        const startDate = item.start ? new Date(item.start) : null;
        const finishDate = item.finish ? new Date(item.finish) : null;
        const actualStartDate = item.actual_start_date ? new Date(item.actual_start_date) : null;
        const actualEndDate = item.actual_end_date ? new Date(item.actual_end_date) : null;

        if (actualEndDate) {
          counts[1] += 1; // Ended
        } else if (actualStartDate) {
          counts[0] += 1; // Started
        } else if (!actualStartDate && finishDate && finishDate > today) {
          counts[2] += 1; // Due
        } else if (!actualStartDate && finishDate && finishDate <= today) {
          counts[3] += 1; // Delayed
        } else {
          counts[4] += 1; // Planned
        }
      });
      setProjectCounts(counts);
      setLoading(false);
    }
  }, [scheduleData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const total = projectCounts.reduce((acc, count) => acc + count, 0);
  const data = [
    { name: 'Started', value: projectCounts[0] },
    { name: 'Ended', value: projectCounts[1] },
    { name: 'Due', value: projectCounts[2] },
    { name: 'Delayed', value: projectCounts[3] },
    { name: 'Planned', value: projectCounts[4] },
  ].map(item => ({
    ...item,
    percentage: ((item.value / total) * 100).toFixed(2)
  }));

  const backButtonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    alignSelf: backButtonPosition.vertical === 'top' ? 'flex-start' : 'flex-end',
    marginLeft: backButtonPosition.horizontal === 'left' ? '0' : 'auto',
    marginRight: backButtonPosition.horizontal === 'right' ? '0' : 'auto',
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div style={{ padding: '0', position: 'relative', width: '100%' }}>
      <h1 style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontWeight: 'bold', fontSize: '2em' }}>
        Progress
      </h1>
      {/* <div style={{ width: '100%', display: 'flex', justifyContent: backButtonPosition.horizontal === 'left' ? 'flex-start' : 'flex-end' }}>
        <button onClick={() => onBack(null)} style={backButtonStyle}>
          Back
        </button>
      </div> */}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <div>
          <h1 style={{ textAlign: 'center' }}>Project Schedule</h1>
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value, name, props) => [`${props.payload.percentage}%`, name]} />
            <Legend verticalAlign="center" align="center" height={36} />
          </PieChart>
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '50%' }}>
          <h1 style={{ textAlign: 'center' }}>Task Progress</h1>
          <div style={{ overflowX: 'auto', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: taskProgress.length*40, height: 300, justifyContent: 'center'}}>
              <ResponsiveContainer width="125%" height="100%">
                <BarChart data={taskProgress} barSize={100}>
                  <CartesianGrid strokeDasharray="2 3" />
                  <XAxis dataKey="task_name" interval={0} angle={-45} textAnchor="end" />
                  <YAxis />
                  <Tooltip />
                  
                  <Bar dataKey="progress" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
