import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  message,
  notification,
} from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import { selectorGetItemInCurrentStockProjectWise } from "../../../../redux-core/materials/selectors";
import useSearch from "../../../../hooks/useSearch";
import {
  updateItemIssueQty,
  getCurrentStockProjectIdWise,
  insertItemIssueData,
  getAllIssueData,
  getCountIssueNumber,
  createIssueNumbers,
} from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import ShowIssueReportForm from "./ShowIssueReportForm";
import { selectorCountIssueNumber } from "../../../../redux-core/materials/selectors";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { selectorGetProjByProjId } from "../../../../redux-core/settings/selectors";
import moment from "moment";
import { materialsApi, settingsapi } from "../../../../cognisite-api";
import { getAllWorkOrders } from "../../../../redux-core/contracts/actions";
import { selectorAllWorkOrderDetails } from "../../../../redux-core/contracts/selectors";
import { MdEdit } from "react-icons/md";
import { FaSave } from "react-icons/fa";
import toast from "react-hot-toast";

const { showProjectByProjId } = settingsapi;
const { getIssueNumberCount } = materialsApi;

const actionDispatch = (dispatch) => ({
  updateItemIssueQty: (issueItem) => dispatch(updateItemIssueQty(issueItem)),
  getCurrentStockProjectIdWise: (projId) =>
    dispatch(getCurrentStockProjectIdWise(projId)),
  getAllIssueData: (projId) => dispatch(getAllIssueData(projId)),
  getCountIssueNumber: (projId) => dispatch(getCountIssueNumber(projId)),
  getProjectByprojectId: (projId) => dispatch(getProjectByprojectId(projId)),
  insertItemIssueData: (issueData) => dispatch(insertItemIssueData(issueData)),
  createIssueNumbers: (issueData) => dispatch(createIssueNumbers(issueData)),
  getAllWorkOrders: (projectID) => dispatch(getAllWorkOrders(projectID)),
});
function MaterialIssueFrom(props) {
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );

  const {
    updateItemIssueQty,
    getCurrentStockProjectIdWise,
    insertItemIssueData,
    getAllIssueData,
    getCountIssueNumber,
    createIssueNumbers,
    getAllWorkOrders,
  } = actionDispatch(useDispatch());
  const [array, setArray] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const getColumnSearchProps = useSearch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [issueDate, setIssueDate] = useState();
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueNumberCreateModal, setIssueNumberCreateModal] = useState(false);
  const [issueNumberCreateDate, setIssueNumberCreateDate] = useState();
  const countIssueNumber = useSelector(selectorCountIssueNumber);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const [issueNumber, setIssueNumber] = useState();
  const [agencyName, setAgencyName] = useState();
  const [recieverName, setRecieverName] = useState();
  const [passIssueDate, setPassIssueDate] = useState();
  const [projectCode, setProjectCode] = useState([]);
  const [countsIssueNumber, setCountsIssueNumber] = useState([]);
  const workOrderDetails = useSelector(selectorAllWorkOrderDetails);
  const [storeWorkOrderNos, setWorkOrderNos] = useState();
  const [workOrderid, setWorkOrderId] = useState(null);
  console.log("passIssueDate", passIssueDate);

  useEffect(() => {
    getCountIssueNumber(props.projectID);
    getProjectByprojectId(props.projectID);

    getProjectCode(props.projectID);
    getNoOfIssue(props.projectID);
    getAllWorkOrders(props.projectID);
  }, [props.projectID]);

  // fetch api for Getting Project code
  const getProjectCode = async (projectID) => {
    const responce = await showProjectByProjId(projectID);

    if (responce.status === 200) {
      const result = responce.project.map((item) => item.project_code);
      setProjectCode(result);
    } else {
      setProjectCode([]);
    }
  };

  const getNoOfIssue = async (projectID) => {
    const Response = await getIssueNumberCount(projectID);

    if (Response.status === 200) {
      const Results = Response.materials.map((items) => items.no_of_Issue_no);
      setCountsIssueNumber(Results);
    } else {
      setCountsIssueNumber([]);
    }
  };

  const tableData = [];
  for (let i = 0; i < getShowItemInCurrentStockProjectWise.length; i++) {
    tableData.push({
      sl: i + 1,
      id: getShowItemInCurrentStockProjectWise[i].id,
      item_code: getShowItemInCurrentStockProjectWise[i].item_code,
      Item_name: getShowItemInCurrentStockProjectWise[i].Item_name,
      issue_qty: getShowItemInCurrentStockProjectWise[i].issue_qty,
      total_qty: getShowItemInCurrentStockProjectWise[i].total_qty,
      item_rate: getShowItemInCurrentStockProjectWise[i].item_rate,
      Issue_qty_value: getShowItemInCurrentStockProjectWise[i].Issue_qty_value,
    });
  }
  const handleIssueDate = (value, dateString) => {
    setIssueDate(dateString);
  };
  useEffect(() => {
    form.setFieldsValue({
      issue_number: issueNumber,
      issued_agency_name: agencyName,
      reciever_name: recieverName,
      issue_date: moment(passIssueDate, "DD-MM-YYYY"),
    });
  }, [issueNumber, agencyName, recieverName, passIssueDate]);
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "sl",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      ...getColumnSearchProps("item_code"),
      width: 150,
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
      ...getColumnSearchProps("Item_name"),
      width: 380,
    },
    {
      title: "Current Stock",
      dataIndex: "total_qty",
      width: 150,
    },
    {
      title: "Issue Number",
      dataIndex: "issue_number",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name={`issue_number`} initialValue={issueNumber}>
              <Input
                style={{ width: 200 }}
                placeholder="Issue Number"
                disabled
              />
            </Form.Item>
          );
        }
      },
      width: 150,
    },
    {
      title: "Issue Qty",
      dataIndex: "issue_qty",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name={`issue_qty`}
              rules={[
                {
                  validator: (_, value) => {
                    // Convert value to a number
                    const issueQty = parseInt(value, 10);
                    const totalQty = record.total_qty;

                    if (isNaN(issueQty) || issueQty < 0) {
                      return Promise.reject(
                        "Issue Qty must be a positive number."
                      );
                    }

                    if (issueQty > totalQty) {
                      return Promise.reject(
                        "Issue Qty cannot exceed Total Qty."
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input style={{ width: 100 }} placeholder="Issue Qty" />
            </Form.Item>
          );
        }
      },
      width: 150,
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="issue_date"
              initialValue={moment(passIssueDate, "DD-MM-YYYY")}
            >
              <DatePicker
                name="issue_date"
                format="DD-MM-YYYY"
                // defaultValue={moment()}
                onChange={handleIssueDate}
                placeholder="Enter Date"
                style={{ width: 150 }}
                disabled
              />
            </Form.Item>
          );
        }
      },
      width: 200,
    },

    {
      title: "Item issued to (Agency Name)",
      dataIndex: "issued_agency_name",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name={`issued_agency_name`} initialValue={agencyName}>
              <Input
                style={{ width: 200 }}
                placeholder="Item issued to (Agency Name) "
                disabled
              />
            </Form.Item>
          );
        }
      },
    },
    {
      title: "Receiver  Name",
      dataIndex: "reciever_name",
      render: (text, record, index) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name={`reciever_name`} initialValue={recieverName}>
              <Input
                style={{ width: 200 }}
                placeholder="Enter Reciever Name "
                disabled
              />
            </Form.Item>
          );
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => {
        return (
          <div>
            <Button
              onClick={() => {
                setEditingRow(record.id);
                form.resetFields();
              }}
            >
              Edit
            </Button>
            <Button
              htmlType="submit"
              onClick={() => {
                setEditingRow();
                form.submit();
                onFinish(form.getFieldsValue(), record);
              }}
            >
              Save
            </Button>
          </div>
        );
      },
    },
  ];

  const onFinish = async (value, record) => {
    const work_order_number = workOrderDetails?.project?.find(
      (item) => item.work_order_id == workOrderid
    );

    const Value = Number(record.item_rate) / Number(record.total_qty);

    const issueQtyValue = Number(Value) * Number(value.issue_qty);

    const itemValue = Number(record.item_rate) - Number(issueQtyValue);

    const DataIssue = {
      id: record.id,
      issue_qty: Number(record.issue_qty) + Number(value.issue_qty),
      total_qty: Number(record.total_qty) - Number(value.issue_qty),
      item_rate: itemValue,
      Issue_qty_value: Number(record.Issue_qty_value) + Number(issueQtyValue),
    };

    const issueData = {
      project_id: props.projectID,
      item_id: record.id,
      issue_date: value.issue_date,
      issue_qty: value.issue_qty,
      issued_agency_name: value.issued_agency_name,
      reciever_name: value.reciever_name,
      issue_number: value.issue_number,
      orgID: orgId,
      created_by: userId,
      work_order_id: work_order_number.work_order_number,
    };

    if (issueData.issue_number === undefined) {
      notification.error({
        message: "New Issue Number Create first",

        onClick: () => {},
      });
    } else {
      const Response = await insertItemIssueData(issueData);
      if (Response) {
        if (Response.success === true) {
          await updateItemIssueQty(DataIssue);
        }
        getCurrentStockProjectIdWise(props.projectID);
      }
    }
  };

  // open issue report modal
  const HandelShowIssueModal = () => {
    setIsModalOpen(true);
    getAllIssueData(props.projectID);
  };

  const ModalClose = () => {
    setIsModalOpen(false);
    setIssueNumberCreateModal(false);
  };

  const openIssueModal = () => {
    setIssueNumberCreateModal(true);
  };

  const handleIssuesDate = (value, dateString) => {
    setIssueNumberCreateDate(dateString);
  };

  const [formData, setFormData] = useState({
    agency_name: "",
    issue_numbers: "",
    issues_date: "",
    reciever: "",
    work_order_number: "",
  });

  const HandelNewIssueNumberSubmit = async (e) => {
    e.preventDefault();

    const data = {
      // issue_numbers: value.issue_numbers,
      // issues_date: value.issues_date,
      // agency_name: value.agency_name,
      // work_order_number: value.work_order_number,
      // reciever: value.reciever,
      ...formData,
      project_id: props.projectID,
      orgID: orgId,
      created_by: userId,
    };

    // console.log("Data: ", data);
    // return;

    const response = await createIssueNumbers(data);

    if (response) {
      if (response.success === true) {
        setFormData({
          agency_name: "",
          issue_numbers: "",
          issues_date: "",
          project_id: "",
          reciever: "",
          work_order_number: "",
        });
        toast.success("Success..");
        // console.log("response: ", response);
        ModalClose();
        setIssueNumber(response.materials.issue_numbers);
        setAgencyName(response.materials.agency_name);
        setRecieverName(response.materials.reciever);
        setWorkOrderId(response.materials.work_order_number);
        setPassIssueDate(
          moment(response.materials.issues_date).format("DD-MM-YYYY")
        );
        getCountIssueNumber(props.projectID);
      }
      form2.setFieldsValue({
        issues_date: "",
        agency_name: "",
        work_order_number: "",
        reciever: "",
      });
      getCountIssueNumber(props.projectID);
    }
  };

  let count_number =
    countsIssueNumber[0] === 0 ? 1 : Number(countsIssueNumber[0]) + 1;

  const ISSUE_NUMBER = "ISP" + "/" + projectCode[0] + "/" + count_number;

  useEffect(() => {
    form2.setFieldsValue({
      issue_numbers: ISSUE_NUMBER,
    });
    setFormData({
      ...formData,
      issue_numbers: ISSUE_NUMBER,
    });
  }, [ISSUE_NUMBER]);

  const backToAllProject = () => {
    props.backProject();
  };

  const workOrderNo = [];
  const HandelSelectContarctor = (value) => {
    console.log("value", value);
    const workOrderNo = workOrderDetails?.project?.filter(
      (item) => item?.contractor_name === value
    );
    console.log("workOrderNo", workOrderNo);
    setWorkOrderNos(workOrderNo);
  };

  // useEffect(() => {
  //   console.log("Form data: ", formData);
  // }, [formData]);

  return (
    <>
      {/* <div className="btn__">
        <Button className="button-61" onClick={HandelShowIssueModal}>
          View Issue Report
        </Button>
        <Button
          className="button-61"
          style={{ float: "right" }}
          onClick={openIssueModal}
        >
          Create Issue Number
        </Button>
      </div>
      <Button onClick={() => backToAllProject()}>All Projects</Button> <br />{" "}
      <br /> */}

      {/* new headers shivam kashyap 26 june 2024 */}
      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="   font-montserrat">
            <button
              onClick={() => backToAllProject()}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              All Projects
            </button>
          </div>
          {/* <div className=" font-semibold  text-2xl">Material Issue</div> */}

          <button
            onClick={HandelShowIssueModal}
            type="button"
            class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            View Issue Report
          </button>

          <button
            onClick={openIssueModal}
            type="button"
            class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Create Issue Number
          </button>
        </div>
      </div>
      <div>
        <Form form={form}>
          {/* <Table
            scroll={{ x: "100vw" }}
            columns={columns} //dataSource={tableData}
            dataSource={Array.isArray(array) ? array : tableData}
            pagination={10}
          /> */}

          {/* new table shivam kashyap 26 june 2024  */}

          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[10%]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Sl No.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[20%]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Item Name
                  </p>

                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Item Code
                  </p>
                </div>
                <div className="flex flex-col items-start w-[20%]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Current Stock
                  </p>
                </div>
                <div className="flex flex-col items-start w-[20%]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Issue Qty
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Issue Number (Issue Date)
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[25%]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Item issued to (Agency Name)
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Receiver Name
                  </p>
                </div>
                <div className="flex flex-col items-start w-[10%]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Action
                  </p>{" "}
                </div>
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
            >
              {tableData && tableData.length && tableData[0] != null
                ? tableData.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[10%]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.sl ? item.sl : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[20%]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.Item_name ? item.Item_name : "-"}
                        </p>

                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.item_code ? item.item_code : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[20%]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.total_qty ? item.total_qty : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[20%] font-montserrat">
                        {editingRow === item.id ? (
                          <Form.Item
                            name={`issue_qty`}
                            rules={[
                              {
                                validator: (_, value) => {
                                  // Convert value to a number
                                  const issueQty = parseInt(value, 10);
                                  const totalQty = item.total_qty;

                                  if (isNaN(issueQty) || issueQty < 0) {
                                    return Promise.reject(
                                      "Issue Qty must be a positive number."
                                    );
                                  }

                                  if (issueQty > totalQty) {
                                    return Promise.reject(
                                      "Issue Qty cannot exceed Total Qty."
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              style={{ width: 100 }}
                              placeholder="Issue Qty"
                            />
                          </Form.Item>
                        ) : (
                          <p className="text-sm font-semibold text-gray-900 w-full">
                            Issue Qty
                          </p>
                        )}

                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Issue Number (Issue Date)
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[25%]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Item issued to (Agency Name)
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Receiver Name
                        </p>
                      </div>
                      <div className="flex  gap-x-1 items-start w-[10%]">
                        <a
                          onClick={() => {
                            setEditingRow(item.id);
                          }}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Edit"
                        >
                          <MdEdit
                            className=" text-top_nav_blue-300 "
                            size={18}
                          />
                        </a>{" "}
                        <a
                          onClick={() => {
                            setEditingRow();
                          }}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Save"
                        >
                          <FaSave
                            className=" text-top_nav_blue-300 "
                            size={18}
                          />
                        </a>{" "}
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>
        </Form>
      </div>
      {/* modals  */}
      <Modal
        visible={isModalOpen}
        width={1300}
        footer={null}
        onCancel={ModalClose}
      >
        <ShowIssueReportForm />
      </Modal>
      <Modal
        visible={issueNumberCreateModal}
        footer={null}
        onCancel={ModalClose}
        width={1400}
      >
        {/* <Form
          form={form2}
          name="create_Issue_number"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={HandelNewIssueNumberSubmit}
        >
          <Form.Item
            name="issue_numbers"
            label="Issue Number"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Issue Number !",
              },
            ]}
            initialValue={ISSUE_NUMBER}
          >
            <Input placeholder="Issue Number" disabled />
          </Form.Item>
          <Form.Item
            name="issues_date"
            label="Issue Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter GRN Date !",
              },
            ]}
          >
            <DatePicker
              name="issues_date"
              format="DD-MM-YYYY"
              onChange={handleIssuesDate}
              placeholder="Enter Date"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item
            name="agency_name"
            label="Item issued to (Agency Name)"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Item issued to (Agency Name)!",
              },
            ]}
            //initialValue={GRN_NUMBER}
          >
            <Select
              onChange={(contractorName) =>
                HandelSelectContarctor(contractorName)
              }
            >
              {workOrderDetails?.project?.map((item) => (
                <option key={item.contractor_name} value={item.contractor_name}>
                  {item.contractor_name}
                </option>
              ))}
            </Select>
        
          </Form.Item>
          <Form.Item
            label="Work Order Number"
            name="work_order_number"
            className="input-field"
          >
            <Select>
              {storeWorkOrderNos?.map((item) => (
                <Select.Option
                  key={item?.work_order_id}
                  value={item?.work_order_id}
                >
                  {item?.work_order_number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="reciever"
            label="Receiver  Name"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Receiver  Name!",
              },
            ]}
            //initialValue={GRN_NUMBER}
          >
            <Input placeholder="Receiver  Name" />
          </Form.Item>
          <Form.Item>
            <div className="btn-createAdmin">
              <Button type="primary" htmlType="submit" className="btn-submit">
                <strong>Submit</strong>
              </Button>

             
            </div>
          </Form.Item>
        </Form> */}

        {/* new form shivam kashyap  */}
        <div className=" font-semibold  mb-4 text-2xl font-montserrat">
          Create Issue Number
        </div>
        <form
          className="font-montserrat flex flex-col items-center  gap-y-3"
          onSubmit={HandelNewIssueNumberSubmit}
        >
          {/* 1a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="job_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Receiver Name
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="reciever"
                  value={formData.reciever}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reciever: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2  */}
            <div className="mt w-[300px]">
              <label
                htmlFor="agency_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Item issued to (Agency Name)
              </label>
              <select
                required
                name="agency_name"
                value={formData.agency_name}
                onChange={(e) => {
                  HandelSelectContarctor(e.target.value);
                  setFormData({
                    ...formData,
                    agency_name: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option disabled value="">
                  Select
                </option>
                {workOrderDetails?.project?.map((item) => (
                  <option
                    key={item.contractor_name}
                    value={item.contractor_name}
                  >
                    {item.contractor_name}
                  </option>
                ))}
              </select>
            </div>
            {/* 3 date */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="issues_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Issue Date
              </label>
              <input
                required
                type="date"
                name="issues_date"
                value={formData.issues_date}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    issues_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
          </div>{" "}
          {/* 2a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            <div className="mt w-[300px]">
              <label
                htmlFor="work_order_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Work Order Number
              </label>
              <select
                required
                name="work_order_number"
                value={formData.work_order_number}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    work_order_number: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option disabled value="">
                  Select
                </option>
                {/* {workOrderDetails?.project?.map((item) => (
                  <option
                    key={item.contractor_name}
                    value={item.contractor_name}
                  >
                    {item.contractor_name}
                  </option>
                ))} */}
                {storeWorkOrderNos?.map((item) => (
                  <option key={item?.work_order_id} value={item?.work_order_id}>
                    {item?.work_order_number}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt w-[300px]"></div>
            <div className="mt w-[300px]"></div>
          </div>
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
            <button
              onClick={() => {
                setFormData({
                  agency_name: "",
                  issue_numbers: "",
                  issues_date: "",
                  project_id: "",
                  reciever: "",
                  work_order_number: "",
                });
              }}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default MaterialIssueFrom;
