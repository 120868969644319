// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-template{
   float: right;
   background-color: #FFFF00;
   color: black;
   border:#FFFF00;
   margin-top: -5%;
   margin-right: 5%;
}
.btn-ok {
   background-color:#ffa426;;
   color: black;
   border: #ffc107;
   width: 20%;
   display: block;
 margin:  auto;
}

.btn-ok:hover {
   background-color: #ffa426;
   color: #f1e206;
   border: 1px solid #f1e206;
}`, "",{"version":3,"sources":["webpack://./src/components/resources/content/Forms/css/BulkLabourRegisterForm.css"],"names":[],"mappings":"AAAA;GACG,YAAY;GACZ,yBAAyB;GACzB,YAAY;GACZ,cAAc;GACd,eAAe;GACf,gBAAgB;AACnB;AACA;GACG,wBAAwB;GACxB,YAAY;GACZ,eAAe;GACf,UAAU;GACV,cAAc;CAChB,aAAa;AACd;;AAEA;GACG,yBAAyB;GACzB,cAAc;GACd,yBAAyB;AAC5B","sourcesContent":[".btn-template{\n   float: right;\n   background-color: #FFFF00;\n   color: black;\n   border:#FFFF00;\n   margin-top: -5%;\n   margin-right: 5%;\n}\n.btn-ok {\n   background-color:#ffa426;;\n   color: black;\n   border: #ffc107;\n   width: 20%;\n   display: block;\n margin:  auto;\n}\n\n.btn-ok:hover {\n   background-color: #ffa426;\n   color: #f1e206;\n   border: 1px solid #f1e206;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
