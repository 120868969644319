import { Badge, Button, Modal, Radio, Spin, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import {
  createNewOrg,
  getSignupResquestData,
} from "../../../redux-core/settings/actions";
import { selectorGetAllSignUpdataState } from "../../../redux-core/settings/selectors";
import { FileProtectOutlined } from "@ant-design/icons";
import useSearch from "../../../hooks/useSearch";
import { useState } from "react";
import { settingsapi } from "../../../cognisite-api";

const actionDispatch = (dispatch) => ({
  getSignupResquestData: (orgId) => dispatch(getSignupResquestData(orgId)),
  newOrg: (newOrg) => dispatch(createNewOrg(newOrg)),
});
const { createAdmin, updateSignupApprovalById } = settingsapi;
function SignUpRequest() {
  const Spindata = useSelector((item) => item.spinreducer);
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const getAllSignupResquest = useSelector(selectorGetAllSignUpdataState);
  const { getSignupResquestData, newOrg } = actionDispatch(useDispatch());
  const getColumnSearchProps = useSearch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordData, setRecordData] = useState();

  // api trigger by useeffect
  useEffect(() => {
    getSignupResquestData();
  }, []);

  console.log("getAllSignupResquest", getAllSignupResquest);
  // loop for table
  const datatable = [];

  for (let i = 0; i < getAllSignupResquest?.length; i++) {
    datatable.push({
      sl_no: i + 1,
      id: getAllSignupResquest?.[i]?.id,
      organisation_name: getAllSignupResquest?.[i]?.organisation_name,
      name: getAllSignupResquest?.[i]?.admin_name,
      phone: getAllSignupResquest?.[i]?.phone,
      email: getAllSignupResquest?.[i]?.email,
      address: getAllSignupResquest?.[i]?.address,
      pan: getAllSignupResquest?.[i]?.pan,
      gst_number: getAllSignupResquest?.[i]?.gst_number,
      is_approval: getAllSignupResquest?.[i]?.is_approval,
    });
  }

  // colunm for table
  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",

      width: "100px",
    },
    {
      title: "Organisation Name",
      dataIndex: "organisation_name",
      ...getColumnSearchProps("organisation_name"),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Address",
      dataIndex: "address",
      // ...getColumnSearchProps("address"),
    },
    {
      title: "PAN No.",
      dataIndex: "pan",
      ...getColumnSearchProps("pan"),
    },
    {
      title: "GST Number",
      dataIndex: "gst_number",
      ...getColumnSearchProps("gst_number"),
    },
    {
      title: "Status",
      dataIndex: "is_approval",

      render: (text) => {
        let statusColor;

        switch (text) {
          case 1:
            statusColor = "blue";
            text = "Pending";
            break;
          case 2:
            statusColor = "green";
            text = "Approved";
            break;
          case 3:
            statusColor = "red";
            text = "Rejected";
            break;
          default:
            statusColor = "default";
        }

        return <Badge status={statusColor} text={text} />;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Request Approval Process">
              <Button onClick={() => HandelAction(record)}>
                <FileProtectOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // Handel Action on signup request
  const HandelAction = (record) => {
    console.log("record", record);
    setRecordData(record);
    setIsModalOpen(true);
  };

  // handel close modal
  const HandelCancelModal = () => {
    setIsModalOpen(false);
  };

  // Handel Select radio button
  const HandelSelectRadioButton = (e) => {
    console.log("value", e?.target?.value);

    if (e?.target?.value === "APPROVE") {
      HandelCreateOrganisationAndAdmin();
    } else if (e?.target?.value === "REJECT") {
      console.log("Hello");
      const updateData = {
        id: recordData?.id,
        is_approval: 3,
      };
      console.log("updateData", updateData);

      updateSignupApprovalById(updateData);
      HandelCancelModal();
    }
  };

  const createDate = new Date();
  createDate.setDate(createDate.getDate());

  // create organisation and admin function
  const HandelCreateOrganisationAndAdmin = async () => {
    console.log("first");
    const orgData = {
      orgName: recordData?.organisation_name,
      status: "Active",
      createdBy: userId,
      createdOn: createDate.toISOString().slice(0, 10),
      updatedBy: userId,
      updatedOn: createDate.toISOString().slice(0, 10),
      owner: userId,
      themeID: 1,
      contact: recordData?.phone,
      gst_number: recordData?.gst_number,
      pan_no: recordData?.pan,
      address: recordData?.address,
      contact_person: recordData?.name,
    };
    console.log("OrgData:", orgData);
    let orgResponse = await newOrg(orgData);
    console.log("orgResponse", orgResponse);
    if (orgResponse) {
      let data = {
        // ...value,
        // "orgID": orgId,
        name: recordData?.name,
        email: recordData?.email,
        username: recordData?.name,
        password: "Cognisite@1234",
        phone: recordData?.phone,
        address: recordData?.address,
        orgID: orgResponse?.data?.id,
        createdBy: userId,
        attempt: "2",
        del_status: "0",
        role: "ADMIN",
        status: "Active",
      };
      let adminResponse = await createAdmin(data);
      console.log("adminResponse", adminResponse);

      const updateData = {
        id: recordData?.id,
        is_approval: 2,
      };
      console.log("updateData", updateData);

      updateSignupApprovalById(updateData);
      getSignupResquestData();
      HandelCancelModal();
    }
  };

  const isDisabled =
    recordData?.is_approval == 2 || recordData?.is_approval == 3;
  return (
    <div>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">Signup request</div>
        <Table
          className="table"
          scroll={{ x: "80vw", y: 550 }}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={datatable}
          // onChange={(pagination, filter, sorter, currentTable) =>
          //   onTableChange(filter, sorter, currentTable)
          // }
          pagination={10}
        />
      </Spin>
      <Modal
        open={isModalOpen}
        onCancel={HandelCancelModal}
        footer={null}
        centered={true}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Radio.Group
            defaultValue="PENDING"
            buttonStyle="solid"
            onChange={HandelSelectRadioButton}
          >
            <Radio.Button value="APPROVE" disabled={isDisabled}>
              APPROVE
            </Radio.Button>
            <Radio.Button value="REJECT" disabled={isDisabled}>
              REJECT
            </Radio.Button>
            <Radio.Button value="PENDING" disabled>
              PENDING
            </Radio.Button>
          </Radio.Group>
        </div>
      </Modal>
    </div>
  );
}

export default SignUpRequest;
