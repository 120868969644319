import { ClockCircleOutlined, ContainerOutlined } from '@ant-design/icons';
import './css/Dashboard.css';
import { useSelector } from 'react-redux';
import { selectorCount } from '../../../redux-core/qcc/selectors';
import React from 'react';
function Dashboard() {
    const countData = useSelector(selectorCount);
    return (
        <>
            <div className="dashboard-root1">
                {/* <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData[3].no_of_tasks}</span>
                        <span className="right__icon"><ContainerOutlined /></span>
                    </div>
                    <div className="bottom__title">No Of Tasks</div>
                </div>
                <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData[2].no_of_activities}</span>
                        <span className="right__icon"><ClockCircleOutlined /></span>
                    </div>
                    <div className="bottom__title">No Of Activities</div>
                </div>  */}
            </div>
        </>
    );
}

export default React.memo(Dashboard);