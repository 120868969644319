// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
    width: 45%;
    margin-right: 5%;
    display: inline-block;
}

.dropdown-selector {
    width: 95%;
}

.btn-labour {
    text-align: center;
}

.btn-project {
    text-align: center;
}

.btn-labour .btn-submit {
    margin-right: 10px;
    background-color: #FFFF00;
    color: black;
    border: #FFFF00;
    /* width: 20%; */
    /* border-radius: 20px; */
}

.btn-submit:hover {
    background-color: white;
    color: #f1e206;
    border: 1px solid #f1e206;
}

.btn-labour .btn-cancel {
    margin-left: 10px;
    border-radius: 10px;

}

.btn-labour .btn-cancel:hover {
    color: #f1e206;
    border: 1px solid #f1e206;
}


.btn-bulk {
    background-color: #54ca68;
    border-color: #54ca68;
    box-shadow: 0 2px 6px #8edc9c;
    color: #54ca68;
    border-radius: 5px;
    float: right;
    margin-top: -5%;
    margin-right: 5%;
}

@media screen and (max-width:600px) {
    .input-field {
        width: 100%;
        margin-right: 0;
        display: block;
    }

    .btn-labour .btn-submit {
        width: 50%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/resources/content/Forms/css/AddLabourRegisterForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;;AAEvB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;;AAGA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;QACX,eAAe;QACf,cAAc;IAClB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".input-field {\n    width: 45%;\n    margin-right: 5%;\n    display: inline-block;\n}\n\n.dropdown-selector {\n    width: 95%;\n}\n\n.btn-labour {\n    text-align: center;\n}\n\n.btn-project {\n    text-align: center;\n}\n\n.btn-labour .btn-submit {\n    margin-right: 10px;\n    background-color: #FFFF00;\n    color: black;\n    border: #FFFF00;\n    /* width: 20%; */\n    /* border-radius: 20px; */\n}\n\n.btn-submit:hover {\n    background-color: white;\n    color: #f1e206;\n    border: 1px solid #f1e206;\n}\n\n.btn-labour .btn-cancel {\n    margin-left: 10px;\n    border-radius: 10px;\n\n}\n\n.btn-labour .btn-cancel:hover {\n    color: #f1e206;\n    border: 1px solid #f1e206;\n}\n\n\n.btn-bulk {\n    background-color: #54ca68;\n    border-color: #54ca68;\n    box-shadow: 0 2px 6px #8edc9c;\n    color: #54ca68;\n    border-radius: 5px;\n    float: right;\n    margin-top: -5%;\n    margin-right: 5%;\n}\n\n@media screen and (max-width:600px) {\n    .input-field {\n        width: 100%;\n        margin-right: 0;\n        display: block;\n    }\n\n    .btn-labour .btn-submit {\n        width: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
