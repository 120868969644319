import "antd/dist/antd.min.css";
import { Form, Input, Button, Select } from "antd";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProj } from "../../../../redux-core/settings/actions";
import { newStageCreate } from "../../../../redux-core/qcc/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import toast from "react-hot-toast";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  newStage: (stage) => dispatch(newStageCreate(stage)),
});

const StageForm = ({ setOpenModal }) => {
  const [form] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const { getProj, newStage } = actionDispatch(useDispatch());
  useEffect(() => {
    getProj(orgId, userId);
  }, []);
  const allProjData = useSelector(makeSelectAllProj);
  const children = [];
  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option key={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  const [stage, setStage] = useState("");
  const onStageDetailsFinish = async (e) => {
    e.preventDefault();
    let data = {
      stage_name: stage,
      orgID: orgId,
      createdBy: userId,
    };

    // console.log("stageData: ", data);
    // return;
    try {
      let response = await newStage(data);
      if (response.success) {
        console.log("res: ", response);
        toast.success("Success..");
        setStage("");
        setOpenModal(false);
        // await clear_field();
      }
    } catch (err) {
      console.log("Err: ", err);
      toast.error("Failed..");
    }
  };

  const onStageDetailsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_field = () => {
    form.setFieldsValue({
      stage_name: "",
      project_id: [],
    });
  };
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };
  return (
    <>
      {/* <Form
        form={form}
        name="master_task"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onStageDetailsFinish}
        onFinishFailed={onStageDetailsFinishFailed}
      >
        <Form.Item
          name="stage_name"
          label="Stage Name"
          pattern="[^\s]+"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the Stage Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input
            placeholder="Stage Name"
            onKeyDown={(e) => onKeydown(e)}
            style={{ width: "300px" }}
          />
        </Form.Item>

        

        <Form.Item>
          <div className="btn-project">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
              //  className="button-3"
            >
              <strong>Submit</strong>
            </Button>

            <Button
              onClick={clear_field}
              className="btn-cancel"
              //  className="button-reset"
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form> */}
      <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
        <div className="font-montserrat   flex justify-start ml-20  font-semibold  text-2xl">
          New Stage
        </div>
        <form className="font-montserrat" onSubmit={onStageDetailsFinish}>
          {/* 1a */}
          <div className="flex justify-evenly w-[30vw]">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Stage Name
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="description"
                  // value={formData.description}
                  onChange={(e) => {
                    setStage(e.target.value);
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
          </div>
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
            <button
              onClick={() => setStage("")}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default StageForm;
