import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload, Space, DatePicker } from "antd";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getAllProject: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjectDetail: (orgId, userId) =>
    dispatch(getAllProjDetails(orgId, userId)),
  // AllSummaryHeader:(orgId,userId)=>dispatch(getAllSummaryHeader(orgId,userId)),
  // getAllIdSummaryHeader:(data)=>dispatch(getAllIdSummaryHeader(data)),
});

const EquipmentDeplouSummaryForm = () => {
  const { getAllProject, getAllProjectDetail } = actionDispatch(useDispatch());
  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [form] = Form.useForm();
  const [value, onChanges] = useState(new Date());
  const [selectData, setSelectData] = useState();

  useEffect(() => {
    getAllProject(orgId, userId);
    getAllProjectDetail(selectedProjectId, orgId, userId);
  }, [selectedProjectId]);

  //   const options = [
  //     { value: 'Created' },
  //     { value: 'Work In Progress' },
  //     { value: 'Completed' },
  // ];

  const onAddWorkCategoryFinish = async (values) => {
    //   console.log("values",values)
    // setShowTable(true);
    // const Data ={
    //     orgID:orgId,
    //     created_by:userId,
    //     project_id: values.project_id,
    //     location: values.location,
    //     create_date:moment(values.create_date).format('YYYY-MM-DD'),
    //      progress_status: values.progress_status,
    // }
    // let response= await getAllIdSummaryHeader(Data)
    //    // console.log("Value",response)
    //    console.log(allIdDeploymentHeader)
    // setSelectData([values]);
  };

  return (
    <>
      <Form
        form={form}
        name="add-labourDeployment"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddWorkCategoryFinish}
        // onFinishFailed={onAddWorkCategoryFinishFailed}
      >
        <Form.Item
          name="project_id"
          label="Project Name"
          rules={[{ required: true, message: "Please select Project!" }]}
          className="input-field"
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Project Name"
            onChange={(projectId) => setSelectedProjectId(projectId)}
          >
            {allProjects.map((getAllProject) => (
              <option
                key={getAllProject.project_id}
                value={getAllProject.project_id}
              >
                {getAllProject.project_name}
              </option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="location"
          label="Project  Location"
          rules={[
            { required: true, message: "Please select Project Loaction!" },
          ]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Project Work Location Name"
          >
            {allProjDetails.map((detail) => (
              <Option key={detail.project_details_id} value={detail.block}>
                {detail.block}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
                    name="progress_status"
                    label="Project Status"
                    rules={[{ required: true, message: 'Please select Project Status!' }]}
                    className="input-field">
                    <Select
                        placeholder="Select Status"
                        value={options.value}
                        options={options}
                        defaultValue={options['']}
                    >
                    </Select>
                </Form.Item> */}

        <Form.Item
          name="create_date"
          label="Current Date"
          className="input-field"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <DatePicker
            onChange={onChanges}
            value={value}
            status="error"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item>
          <div className="btn-deployment">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>

            <Button
              //  onClick={clear_fields_Deployment}

              className="btn-cancel"
              style={{ borderRadius: "10px" }}
            >
              <strong>Reset</strong>
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* {showTable && <DeploymentSummaryTable data={selectData} />} */}
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default EquipmentDeplouSummaryForm;
