import "./css/ListSubContractor.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  message,
  Upload,
  Space,
  Image,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ShowDeleteConfirm } from "../../popupmodal";
import { useDispatch, useSelector } from "react-redux";
import { redirectAction } from "../../../redux-core/login/actions";

import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import {
  createNewContractor,
  getAllcategory,
  getAllcontrator,
  deleteByContractorId,
  updateByContractorId,
  getState,
  getResourcesDashboard,
} from "../../../redux-core/resources/actions";
import {
  selectorgetAllWorkState,
  selectorgetAllSubContractorState,
  selectorgetAllStateState,
} from "../../../redux-core/resources/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  newSubContractor: (createNewContractors) =>
    dispatch(createNewContractor(createNewContractors)),
  getAllCategory: (orgId, userId) => dispatch(getAllcategory(orgId, userId)),
  getAllContractor: (orgId, userId) => dispatch(getAllcontrator(orgId, userId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
  deleByContractorId: (id) => dispatch(deleteByContractorId(id)),
  updateByContractorsId: (newSubContractor) =>
    dispatch(updateByContractorId(newSubContractor)),
  count: (orgId) => dispatch(getResourcesDashboard(orgId)),
  getState: () => dispatch(getState()),
});

const ListSubContractor = () => {
  // console.log("List Sub Contractor");
  const {
    newSubContractor,
    getAllCategory,
    getAllContractor,
    deleByContractorId,
    updateByContractorsId,
    getState,
    count,
  } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const AllWorkCategory = useSelector(selectorgetAllWorkState);
  const allState = useSelector(selectorgetAllStateState);
  const allSubContractor = useSelector(selectorgetAllSubContractorState);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const getColumnSearchProps = useSearch();
  const [filteredData, setFilteredData] = useState([]);
  const [keys, setKeys] = useState();
  const [SubContractorRow, setSubContractorRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  //    console.log("allSubContractor",allSubContractor)

  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  useEffect(() => {
    getAllCategory(orgId, userId);
    getAllContractor(orgId, userId);
    // console.log(allSubContractor);
    getState();
  }, []);

  const children = [];
  for (let i = 0; i < allState.length; i++) {
    children.push(
      <Option key={allState[i].id} value={allState[i].state}>
        {allState[i].state}
      </Option>
    );
  }

  ///
  // Adhar or CIN
  const [cardType, setCardType] = useState("");

  const handleCardTypeChange = (value) => {
    setCardType(value);
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const normFile = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const listSubCatModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({
      category_id: "",
      sub_contractor_name: "",
      commnication_address: "",
      city_town: "",
      state_id: "",
      corporate_address: "",
      description: "",
      email_id: "",
      contact: "",
      alter_contact: "",
      pan_no: "",
      gst_per: "",
      cin_aadhaar: "Please select CIN / AADHAAR",
      remark: "",
      aadhaar: "",
      cin_no: "",
      sub_contractor_photo: "",
    });
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOkk = () => {
    setVisible(false);
  };

  const handleCancels = () => {
    setVisible(false);
    clear_sub_contractor_field();
  };

  //---------------- modal and forms-----------------------------

  const onEditInSubContractorFinish = async (values) => {
    //conversion of array of string to array of numbers
    let data = {
      category_id: selectEditCategory,
      sub_contractor_name: values.sub_contractor_name,
      city_town: values.city_town,
      commnication_address: values.commnication_address,
      state_id: values.state_id,
      corporate_address: values.corporate_address,
      description: values.description,
      email_id: values.email_id,
      contact: values.contact,
      alter_contact: values.alter_contact,
      pan_no: values.pan_no,
      gst_per: values.gst_per,
      cin_aadhaar: values.cin_aadhaar,
      remark: values.remark,
      aadhaar: values.aadhaar,
      cin_no: values.cin_no,
      id: SubContractorRow.key,
    };
    if (values.image && values.image.length < 1) {
      delete data.image;
    } else {
      data.image = values.image[0].size
        ? values.image
        : values.image[0].thumbUrl;
    }
    let response = await updateByContractorsId(data);
    if (response) {
      await setSubContractorRow(data);
      await setVisible(false);
      await getAllContractor(
        logindetails.organisation.orgID,
        logindetails.user.id
      );
    }
    // console.log('Success:', values);
    // console.log('data:', data);
  };
  const onEditInSiteChargeFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const showModal = (data) => {
  //     setFilteredData([]);
  //     setSubContractorRow(data);
  //     if (data.id.length === 1) setFilteredData(prevState => [...prevState, `${data.id}`]);
  //     else {
  //         let contractorID = data.id.split(",");
  //         console.log(contractorID);
  //         contractorID.map((item) => {
  //             console.log(item);
  //             allSubContractor.filter(items => items.id == item)
  //                 .map((filteredItems) => {
  //                     setFilteredData(prevState =>
  //                         [...prevState, `${filteredItems.id}`])
  //                 })
  //         })
  //     }
  //     setIsModalVisible(true);
  // };
  //Edit Row of Item List Work Category

  const showModal = (data1) => {
    setSelectEditCategory(data1.category_id);
    setSubContractorRow(data1);
    setVisible(true);
    form.setFieldsValue({
      category_id: data1.category_name,
      sub_contractor_name: data1.sub_contractor_name,
      commnication_address: data1.commnication_address,
      city_town: data1.city_town,
      state_id: data1.state_id,
      corporate_address: data1.corporate_address,
      description: data1.description,
      email_id: data1.email_id,
      contact: data1.contact,
      alter_contact: data1.alter_contact,
      pan_no: data1.pan_no,
      gst_per: data1.gst_per,
      cin_aadhaar: data1.cin_aadhaar,
      remark: data1.remark,
      aadhaar: data1.aadhaar,
      cin_no: data1.cin_no,
      image: data1.image ? [{ thumbUrl: data1.image }] : [],
    });
  };

  const onAddSubContractorFinish = async (values) => {
    let data = {
      ...values,
      ...{
        orgID: orgId,
        created_by: userId,
      },
    };
    let response = await newSubContractor(data);
    if (response) {
      await clear_fields();
    }
    // console.log("success: "    ,values)
  };

  const clear_fields = () => {
    form.setFieldsValue({
      category_id: "",
      sub_contractor_name: "",
      commnication_address: "",
      city_town: "",
      state_id: "",
      corporate_address: "",
      description: "",
      email_id: "",
      contact: "",
      alter_contact: "",
      pan_no: "",
      gst_per: "",
      cin_aadhaar: "Please select CIN / AADHAAR",
      remark: "",
      aadhaar: "",
      cin_no: "",
      image: [],
    });
  };
  const clear_sub_contractor_field = () => {
    form.setFieldsValue({
      sub_contractor_name: SubContractorRow.sub_contractor_name,
      category_id: SubContractorRow.category_name,
      commnication_address: SubContractorRow.commnication_address,
      city_town: SubContractorRow.city_town,
      state_id: SubContractorRow.state_id,
      corporate_address: SubContractorRow.corporate_address,
      description: SubContractorRow.description,
      email_id: SubContractorRow.email_id,
      contact: SubContractorRow.contact,
      alter_contact: SubContractorRow.alter_contact,
      pan_no: SubContractorRow.pan_no,
      gst_per: SubContractorRow.gst_per,
      cin_aadhaar: SubContractorRow.cin_aadhaar,
      remark: SubContractorRow.remark,
      aadhaar: SubContractorRow.aadhaar,
      cin_no: SubContractorRow.cin_no,
      image: SubContractorRow.image
        ? [{ thumbUrl: SubContractorRow.image }]
        : [],
    });
  };
  // console.log("first",allSubContractor)
  let tableData = [];
  for (let i = 0; i < allSubContractor.length; i++) {
    tableData.push({
      key: allSubContractor[i].id,
      sub_contractor_name: allSubContractor[i].sub_contractor_name,
      category_id: allSubContractor[i].category_id,
      commnication_address: allSubContractor[i].commnication_address,
      city_town: allSubContractor[i].city_town,
      state_id: allSubContractor[i].state_id,
      corporate_address: allSubContractor[i].corporate_address,
      description: allSubContractor[i].description,
      contact: allSubContractor[i].contact,
      alter_contact: allSubContractor[i].alter_contact,
      pan_no: allSubContractor[i].pan_no,
      gst_per: allSubContractor[i].gst_per,
      cin_aadhaar: allSubContractor[i].cin_aadhaar,
      remark: allSubContractor[i].remark,
      aadhaar: allSubContractor[i].aadhaar,
      cin_no: allSubContractor[i].cin_no,
      category_name: allSubContractor[i].category_name,
      email_id: allSubContractor[i].email_id,
      image: allSubContractor[i].image,
    });
  }

  // console.log("tableData",tableData)

  const columns = [
    {
      title: "SL Number",
      dataIndex: "key",
      ...getColumnSearchProps("key"),
    },
    {
      title: "Vendor Name",
      dataIndex: "sub_contractor_name",
      ...getColumnSearchProps("sub_contractor_name"),
    },
    {
      title: "State",
      dataIndex: "state_id",
      ...getColumnSearchProps("state_id"),
    },
    {
      title: "Email",
      dataIndex: "email_id",
      ...getColumnSearchProps("email_id"),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      ...getColumnSearchProps("contact"),
    },
    {
      title: "Work Category",
      dataIndex: "category_name",
      ...getColumnSearchProps("category_id"),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const deleteRow = (record) => {
    // console.log("record", record)
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };

  const onConfirm = async (key) => {
    // console.log("key", key)
    await deleByContractorId(key);
    await getAllContractor(orgId, userId);
    await count(orgId);
  };

  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else if (keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  const data = [];
  for (let i = 0; i < allSubContractor.length; i++) {
    data.push({
      // key: allSubContractor[i].id,

      category_id: allSubContractor[i].category_id,
      sub_contractor_name: allSubContractor[i].sub_contractor_name,
      commnication_address: allSubContractor[i].commnication_address,
      city_town: allSubContractor[i].city_town,
      state_id: allSubContractor[i].state_id,
      corporate_address: allSubContractor[i].corporate_address,
      description: allSubContractor[i].description,
      email_id: allSubContractor[i].email_id,
      contact: allSubContractor[i].contact,
      alter_contact: allSubContractor[i].alter_contact,
      pan_no: allSubContractor[i].pan_no,
      gst_per: allSubContractor[i].gst_per,
      cin_aadhaar: allSubContractor[i].cin_aadhaar,
      remark: allSubContractor[i].remark,
      aadhaar: allSubContractor[i].aadhaar,
      cin_no: allSubContractor[i].cin_no,
      image: allSubContractor[i].image,
    });
    // console.log("DATA",data)
  }

  // const [selectEditVendor, setSelectEditVendor] = useState(null);

  // const handeleditvendor = (editId) =>{
  //     setSelectEditVendor(editId)

  // }
  // console.log("selectEditVendor", selectEditVendor)

  // const options = [
  //     { value: 'Labour', label: 'Labour' },
  //     { value: 'Equipment', label: 'Equipment' },
  // ];

  // const [selectVendor, setSelectVendor] = useState(null)
  // const handelVendor = (vendor) => {
  //     setSelectVendor(vendor)
  // }

  const [selectEditCategory, setSelectEditCategory] = useState(null);

  const handelEditCategory = (categoryId) => {
    setSelectEditCategory(categoryId);
    // console.log("categoryId",categoryId)
  };
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const handleCancelmodal = () => setPreviewvisible(false);

  return (
    <>
      <div className="title-show-project">List of Vendor</div>
      <br />
      <div className="btn__">
        <Button className="excel">
          <CSVLink
            filename={"list_of_subcontractor.csv"}
            data={data}
            className="excel"
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to Excel
          </CSVLink>
        </Button>
        <div>
          {/* <Button className="btn-add" onClick={listSubCatModal}> Add Sub Contractor</Button> */}
          {/* <Button className="btn-bulk">+ Bulk of Sub Contractor</Button> */}
        </div>
      </div>
      <Table
        className="table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={0}
        scroll={{ x: "90vw", y: "300px" }}
      />
      <Button className="copy" onClick={deleteSelected}>
        Delete All Selected
      </Button>
      <div className="modal-content">
        <Modal
          width="75%"
          style={{ top: 80 }}
          visible={isModalVisible}
          s // Model and Form of List Work Category
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="edit-in__charge">Add Vendor</div>
          <br />
          <Form
            form={form}
            name="add-subcontractor"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onAddSubContractorFinish}
            onFinishFailed={(error) => {
              console.log({ error });
            }}
          >
            <Button className="btn-bulk">+ Bulk of Vendor</Button>
            {/* <Form.Item
                    name="sub_contractor_id"
                    label="Sub-Contractor ID"
                    className="input-field"
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}>
                    <Input disabled placeholder="" onKeyDown={(e) => onKeydown(e)} />
                </Form.Item> */}
            <Form.Item
              name="category_id"
              label="Work Category Name"
              rules={[
                {
                  required: true,
                  message: "Please select Work Category Name!",
                },
              ]}
              className="input-field"
            >
              <Select
                // mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Work Category Name"
              >
                {AllWorkCategory.map((getAllCategory) => (
                  <option key={getAllCategory.id} value={getAllCategory.id}>
                    {getAllCategory.category_name}
                  </option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="sub_contractor_name"
              label="Sub-Contractor Name"
              rules={[
                { required: true, message: "Please input a valid username!" },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            <Form.Item
              name="commnication_address"
              label="Communication Address"
              rules={[
                {
                  required: true,
                  message: "Please Fill Communication Address Field!",
                },
              ]}
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>

            <Form.Item
              name="city_town"
              label="City/Town"
              rules={[
                { required: true, message: "Please Fill City Town Field!" },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="state_id"
              label="State"
              rules={[{ required: true, message: "Please select State" }]}
              className="input-field"
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select State"
              >
                {allState.map((getState) => (
                  <option key={getState.id} value={getState.state}>
                    {getState.state}
                  </option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="corporate_address"
              label="Corporate Address"
              rules={[
                {
                  required: true,
                  message: "Please Fill Communication Address Field!",
                },
              ]}
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>
            <Form.Item
              name="email_id"
              label="Email ID"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                  pattern: "/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/",
                },
              ]}
              hasFeedback
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="contact"
              label="Contact"
              rules={[
                {
                  required: true,
                  message: "Please enter your Contact Number !",
                },
                {
                  pattern: /^[\d]{10}$/,
                  message: "Phone Number must contain 10 digits only",
                },
              ]}
              className="input-field"
            >
              <Input
                type="number"
                placeholder="Phone number"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="alter_contact"
              label="Alternate Contact"
              rules={[
                {
                  required: true,
                  message: "Please enter your Alternate Contact !",
                },
                {
                  pattern: /^[\d]{10}$/,
                  message: "Phone Number must contain 10 digits only",
                },
              ]}
              className="input-field"
            >
              <Input
                type="number"
                placeholder="Phone number"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="pan_no"
              label="PAN"
              rules={[
                { required: true, message: "Enter PAN Number!" },
                {
                  pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                  message: "Please Valid PAN Number!",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="gst_per"
              label="GST"
              rules={[
                { required: true, message: "Enter GST Number!" },
                {
                  pattern:
                    "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                  message: "Please Valid GST Number!",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            <Form.Item
              name="image"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="input-field"
            >
              {/* action="/upload.do" */}
              <Upload name="logo" listType="picture" accept=".jpg,.jpeg,.png">
                <Button icon={<UploadOutlined />}>Choosen File</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              className="input-field"
              name="cin_aadhaar"
              label="Adhar/CIN"
              rules={[
                {
                  required: true,
                  message: "Please Select Adhar / CIN",
                },
              ]}
            >
              <Select onChange={handleCardTypeChange}>
                <Option value="aadhar">Aadhar</Option>
                <Option value="cin">CIN</Option>
              </Select>
            </Form.Item>

            {cardType === "aadhar" && (
              <Form.Item
                className="input-field"
                name="aadhaar"
                label="Aadhar Card"
                rules={[
                  {
                    required: true,
                    message: "Please Fill Valid Adhar Card Number",
                    pattern: /^[1-9]{1}[0-9]{11}$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {cardType === "cin" && (
              <Form.Item
                name="cin_no"
                className="input-field"
                label="CIN "
                rules={[
                  {
                    required: true,
                    message: "Please Valid CIN Number",
                    pattern:
                      "^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="remark"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: "Please Fill Communication Address Field!",
                },
              ]}
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-subcontractor">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Submit</strong>
                </Button>
                <Button onClick={clear_fields} className="btn-cancel">
                  Reset
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <br />

      {/* --------------------------Edit Modal---------------------------- */}
      <div className="modal-content">
        <Modal
          width="75%"
          style={{ top: 80 }}
          visible={visible}
          s // Model and Form of List Work Category
          onOk={handleOkk}
          onCancel={handleCancels}
          footer={null}
        >
          <div className="edit-in__charge"> Edit List Vendor</div>
          <br />
          <Form
            form={form}
            name="add-subcontractor"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditInSubContractorFinish}
            onFinishFailed={onEditInSiteChargeFinishFailed}
          >
            {/* <Form.Item
                    name="sub_contractor_id"
                    label="Sub-Contractor ID"
                    className="input-field"
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}>
                    <Input disabled placeholder="" onKeyDown={(e) => onKeydown(e)} />
                </Form.Item> */}
            {/* <Form.Item
                    name="resource_type"
                    label="Vendor Type"
                    rules={[{ required: true, message: 'Please select Work Vendor Type!' }]}
                    className="input-field">
            <Select
              placeholder="Please select Vendor Type"
              value={options.value}
              options={options}
              defaultValue={options['']}
              onChange={(vendor)=>handelVendor(vendor)}
            >
 
            </Select>
                </Form.Item> */}

            <Form.Item
              name="category_id"
              label="Work Category Name"
              rules={[
                {
                  required: true,
                  message: "Please select Work Category Name!",
                },
              ]}
              className="input-field"
            >
              <Select
                // mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Work Category Name"
                onChange={(categoryId) => handelEditCategory(categoryId)}
              >
                {AllWorkCategory.map((getAllCategory) => (
                  <option key={getAllCategory.id} value={getAllCategory.id}>
                    {getAllCategory.category_name}
                  </option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="sub_contractor_name"
              label="Vendor Name"
              rules={[
                { required: true, message: "Please enter Venor Name!" },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            <Form.Item
              name="commnication_address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter Address!",
                },
              ]}
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>

            <Form.Item
              name="city_town"
              label="City/Town"
              rules={[
                { required: true, message: "Please enter City/Town!" },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="state_id"
              label="State"
              rules={[{ required: true, message: "Please select State" }]}
              className="input-field"
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select State"
              >
                {children}
              </Select>
            </Form.Item>
            {/* <Form.Item
                    name='corporate_address'
                    label="Corporate Address"
                    rules={[{ required: true, message: 'Please Fill Communication Address Field!' }]}
                    className="input-field">
                    <Input.TextArea style={{ maxHeight: '70px', minHeight: '70px' }} onKeyDown={(e) => onKeydown(e)} />
                </Form.Item> */}

            <Form.Item
              name="description"
              label="Description"
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>
            <Form.Item
              name="email_id"
              label="Email ID"
              rules={[
                {
                  required: true,
                  message: "Please enter Email",
                },
                {
                  type: "email",
                  message: "Please enter a Valid Email",
                  pattern: "/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/",
                },
              ]}
              hasFeedback
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="contact"
              label="Contact No"
              rules={[
                {
                  required: true,
                  message: "Please enter Contact Number !",
                },
                {
                  pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                  // pattern: /^[\d]{10}$/,
                  // message: "Contact number should be 10 digits",
                  message: "Please Enter Correct Contact Number",
                },
              ]}
              className="input-field"
            >
              <Input
                type="number"
                placeholder="Phone number"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="alter_contact"
              label="Alternate Contact No"
              rules={[
                {
                  required: false,
                  message: "Please enter Alternate Contact !",
                },
                {
                  pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                  // pattern: /^[\d]{10}$/,
                  // message: "Contact number should be 10 digits",
                  message: "Please Enter Correct Alternate Contact Number",
                },
              ]}
              className="input-field"
            >
              <Input
                type="number"
                placeholder="Phone number"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="pan_no"
              label="PAN"
              rules={[
                { required: true, message: "Please enter PAN Number!" },
                {
                  pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                  message: "Please enter Valid PAN Number!",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="gst_per"
              label="GST"
              rules={[
                { required: true, message: "Please enter GST Number!" },
                {
                  pattern:
                    "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
                  message: "Please enter Valid GST Number!",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            <Form.Item
              name="image"
              label="Upload Image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="input-field"
            >
              {/* action="/upload.do" */}
              <Upload
                name="logo"
                listType="picture"
                accept=".jpg,.jpeg,.png"
                beforeUpload={(file, fileList) => {
                  return false;
                }}
                maxCount={"1"}
                onPreview={handlePreview}
              >
                <Button icon={<UploadOutlined />}>Choosen File</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              className="input-field"
              name="cin_aadhaar"
              label="Adhar/CIN"
              rules={[
                {
                  required: true,
                  message: "Please Select Adhar / CIN",
                },
              ]}
            >
              <Select onChange={handleCardTypeChange}>
                <Option value="aadhar">Aadhar</Option>
                <Option value="cin">CIN</Option>
              </Select>
            </Form.Item>

            {cardType === "aadhar" && (
              <Form.Item
                className="input-field"
                name="aadhaar"
                label="Aadhar Card"
                rules={[
                  {
                    required: true,
                    message: "Please enter Valid Adhar Card Number",
                    pattern: /^[1-9]{1}[0-9]{11}$/,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            {cardType === "cin" && (
              <Form.Item
                name="cin_no"
                className="input-field"
                label="CIN "
                rules={[
                  {
                    required: true,
                    message: "Please enter Valid CIN Number",
                    pattern:
                      "^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="remark"
              label="Remarks"
              rules={[
                {
                  required: true,
                  message: "Please enter Address!",
                },
              ]}
              className="input-field"
            >
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-subcontractor">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
                <Button
                  onClick={clear_sub_contractor_field}
                  className="btn-cancel"
                  style={{ borderRadius: "10px" }}
                >
                  Reset
                </Button>
              </div>
            </Form.Item>
            <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelmodal}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
          </Form>
        </Modal>
      </div>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ListSubContractor);
