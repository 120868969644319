// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field,.input-field-showLevel1{
    width: 45%;
    margin-right: 5%;
    display: inline-block;
}

.input-field-images{
    /* width: -10%; */
    margin-right: 5%;
    display: -webkit-inline-flex;
    flex-direction: row;
}



@media screen and (max-width:600px){
    .input-field,.input-field-showLevel1,.input-field-images{
        width: 100%;
        margin-right: 0;
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/settings/contents/Forms/css/AddProjectDetailsForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,4BAA4B;IAC5B,mBAAmB;AACvB;;;;AAIA;IACI;QACI,WAAW;QACX,eAAe;QACf,cAAc;IAClB;AACJ","sourcesContent":[".input-field,.input-field-showLevel1{\n    width: 45%;\n    margin-right: 5%;\n    display: inline-block;\n}\n\n.input-field-images{\n    /* width: -10%; */\n    margin-right: 5%;\n    display: -webkit-inline-flex;\n    flex-direction: row;\n}\n\n\n\n@media screen and (max-width:600px){\n    .input-field,.input-field-showLevel1,.input-field-images{\n        width: 100%;\n        margin-right: 0;\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
