// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
    width: 45%;
    margin-right: 5%;
    display: inline-block;
    /* pointer-events: none; */
  }
  
  .firstRow,
  .secondRow,
  .thirdRow,
  .fourthRow {
    display: inline-block;
    width: 25%;
  }
  @media screen and (max-width: 600px) {
    .input-field {
      width: 100% !important;
      margin-right: 0;
      display: block;
    }
  
    .firstRow,
    .secondRow,
    .thirdRow,
    .fourthRow {
      display: block;
      width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Risk/contents/Forms/css/ShowMsProject.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;IACrB,0BAA0B;EAC5B;;EAEA;;;;IAIE,qBAAqB;IACrB,UAAU;EACZ;EACA;IACE;MACE,sBAAsB;MACtB,eAAe;MACf,cAAc;IAChB;;IAEA;;;;MAIE,cAAc;MACd,WAAW;IACb;AACJ","sourcesContent":[".input-field {\n    width: 45%;\n    margin-right: 5%;\n    display: inline-block;\n    /* pointer-events: none; */\n  }\n  \n  .firstRow,\n  .secondRow,\n  .thirdRow,\n  .fourthRow {\n    display: inline-block;\n    width: 25%;\n  }\n  @media screen and (max-width: 600px) {\n    .input-field {\n      width: 100% !important;\n      margin-right: 0;\n      display: block;\n    }\n  \n    .firstRow,\n    .secondRow,\n    .thirdRow,\n    .fourthRow {\n      display: block;\n      width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
