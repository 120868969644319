import { ClockCircleOutlined, ContainerOutlined } from "@ant-design/icons";
import "./css/Dashboard.css";
import { useSelector } from "react-redux";
import { selectorGetResourcesDashboard } from "../../../redux-core/resources/selectors";
import React, { useState } from "react";
import { Button } from "antd";
import ListSubContractor from "./ListSubContractor";
import ListWorkCategory from "./ListWorkCategory";
import ListEquipmentDeployment from "./ListEquipmentDeployment";
import Deploymenetsummary from "../content/Deploymentsummary";
import ListLabourRegister from "./ListLabourRegister";
import ListEquipmentRegister from "./ListEquipmentRegister";

function Dashboard() {
  const countData = useSelector(selectorGetResourcesDashboard);
  const [hideDashboard, setHideDashboard] = useState(true);
  const [hideBackbutton, setHideBackbutton] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [showWorkCategory, setShowCategory] = useState(false);
  const [showLabourDeploye, setShowLabourDeploye] = useState(false);
  const [showEquipmentDeploye, setShowEquipmentDeploye] = useState(false);
  const [showLabour, setShowLabour] = useState(false);
  const [showEquipment, setShowEquipment] = useState(false);
  // console.log(countData);

  const handelClickVendor = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowVendor(true);
  };

  const handelClickWorkCategory = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowCategory(true);
  };

  const handelClickLabourDeploye = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowLabourDeploye(true);
  };

  const handelClickEquipmentDeploye = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowEquipmentDeploye(true);
  };

  const handelClickLabour = () =>{
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowLabour(true);
  }

  const handelClickEquipment = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowEquipment(true)
  }

  const handelBack = () => {
    setHideDashboard(true);
    setHideBackbutton(false);
    setShowVendor(false);
    setShowCategory(false);
    setShowLabourDeploye(false);
    setShowEquipmentDeploye(false);
    setShowLabour(false);
    setShowEquipment(false)
  };

  // console.log(
  //   "countData[0]?.no_of_sub_contractor",
  //   countData[0]?.no_of_sub_contractor
  // );

  return (
    <>
      {hideBackbutton && (
        <Button
          type="primary"
          style={{ borderRadius: "10px", width: "7%" }}
          onClick={() => handelBack()}
        >
          Back
        </Button>
      )}
      {/* <br/>
        <br/> */}
      {hideDashboard && (
        <div className="dashboard-root1">
          <div className="sub-main" onClick={() => handelClickWorkCategory()}>
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[1]?.no_of_work_category}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Work Category</div>
          </div>

          <div className="sub-main" onClick={() => handelClickVendor()}>
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[0]?.no_of_sub_contractor}
              </span>
              <span className="right__icon">
                <ContainerOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Vendors</div>
          </div>

          {/* <div className="sub-main">
                    <div className="top">
                        <span className="left">14</span>
                        <span className="right"><DashboardOutlined /></span>
                    </div>
                    <div className="bottom">No Of UOM</div>
                </div> */}
          <div className="sub-main" onClick={() => handelClickLabourDeploye()}>
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[2]?.no_of_deployment}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Labour Deployment</div>
          </div>
          <div
            className="sub-main"
            onClick={() => handelClickEquipmentDeploye()}
          >
            <div className="top">
              <span className="left__value">
                {countData.length > 0 &&
                  countData[3]?.no_of_euipment_deployment}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Equipments Deployment</div>
          </div>
          <div
            className="sub-main"
            onClick={() => handelClickLabour()}
          >
            <div className="top">
              <span className="left__value">
                {countData.length > 0 &&
                  countData[4]?.no_of_labour}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Labours</div>
          </div>
          <div
            className="sub-main"
            onClick={() => handelClickEquipment()}
          >
            <div className="top">
              <span className="left__value">
                {countData.length > 0 &&
                  countData[5]?.no_of_equipment}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Equipments</div>
          </div>
        </div>
      )}
      {showVendor && <ListSubContractor />}
      {showWorkCategory && <ListWorkCategory />}

      {showEquipmentDeploye && <ListEquipmentDeployment />}
      {showLabourDeploye && <Deploymenetsummary />}
      {showLabour && <ListLabourRegister />}
      {showEquipment && <ListEquipmentRegister />}
    </>
  );
}

export default React.memo(Dashboard);
