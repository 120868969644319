import "./css/ListEquipmentRegister.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  message,
  DatePicker,
  Upload,
  Space,
  Image,
} from "antd";
import { ShowDeleteConfirm } from "../../popupmodal";
import { UploadOutlined } from "@ant-design/icons";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getAllcategory,
  getAllEquipment,
  updateByequipmentId,
  deleteByEquipmentId,
} from "../../../redux-core/resources/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import {
  selectorgetAllSubContractorState,
  selectorgetAllWorkState,
  selectorgetEquipmentRagisterState,
} from "../../../redux-core/resources/selectors";
import { useDispatch, useSelector } from "react-redux";
import { redirectAction } from "../../../redux-core/login/actions";
import AddEquipmentRegister from "./Forms/AddEquipmentRegisterForm";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import moment from "moment";

const actionDispatch = (dispatch) => ({
  getAllEquipmentRagister: (orgId, userId) =>
    dispatch(getAllEquipment(orgId, userId)),
  getAllCategory: (orgId, userId) => dispatch(getAllcategory(orgId, userId)),
  updateEquipment: (equipmentId) => dispatch(updateByequipmentId(equipmentId)),
  //count: (orgId) => dispatch(countAction(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
  delectEquipment: (id) => dispatch(deleteByEquipmentId(id)),
});

const ListEquipmentRegister = () => {
  // console.log("List Equipment Register");
  const logindetails = useSelector(makeSelectLoginDetail);
  const AllWorkCategory = useSelector(selectorgetAllWorkState);
  const allSubContractor = useSelector(selectorgetAllSubContractorState);
  const allEquipmentRagister = useSelector(selectorgetEquipmentRagisterState);
  const {
    getAllCategory,
    getAllEquipmentRagister,
    updateEquipment,
    delectEquipment,
  } = actionDispatch(useDispatch());
  const getColumnSearchProps = useSearch();
  //const [filteredData, setFilteredData] = useState([]);
  const [keys, setKeys] = useState();
  const [equipmentRow, setequipmentRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [datePiker, setDatePiker] = useState();
  const [selectCateId, setSelectCateId] = useState(null);

  useEffect(() => {
    getAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    getAllEquipmentRagister(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  }, []);

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  //---------------- modal and forms-----------------------------

  const onEditInEquipmentragisterFinish = async (values) => {
    //conversion of array of string to array of numbers
    // console.log("date;;", datePiker);
    let data = {
      equipment_name: values.equipment_name,
      work_category_id: selectCateId,
      supplier_id: selectEditVendor,
      calib_due_date: moment(values.calib_due_date).format("YYYY-MM-DD"),
      id: equipmentRow.key,
    };
    // console.log("data", data);
    if (values.image && values.image.length < 1) {
      delete data.image;
    } else {
      data.image = values.image[0].size
        ? values.image
        : values.image[0].thumbUrl;
    }
    let response = await updateEquipment(data);
    if (response) {
      await setequipmentRow(data);
      await setIsModalVisible(false);
      await getAllEquipmentRagister(
        logindetails.organisation.orgID,
        logindetails.user.id
      );
    }
    console.log("data", data);
    console.log("response", response)
  };
  const onEditEquipmentFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const normFile = (e) => {
    // console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const handleCancel = () => {
    clear_fields();
    setIsModalVisible(false);
  };

  const clear_fields = () => {
    form.setFieldsValue({
      equipment_name: equipmentRow.equipment_name,
      work_category_id: equipmentRow.category_name,
      supplier_id: equipmentRow.sub_contractor_name,
      //calib_due_date:equipmentRow.calib_due_date
      calib_due_date: moment(equipmentRow.calib_due_date),
      image: equipmentRow.image ? [{ thumbUrl: equipmentRow.image }] : [],
    });
  };

  form.setFieldsValue({});

  const [visible, setVisible] = useState(false);

  const handleFinish = (values) => {
    // console.log("Form values:", values);
    setVisible(false);
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };

  // console.log("allEquipmentRagister", allEquipmentRagister);
  let tableData = [];
  for (let i = 0; i < allEquipmentRagister.length; i++) {
    //     let dueDate = new Date(allEquipmentRagister[i].calib_due_date);
    // console.log("dueDate",dueDate)
    //     // Add one day to the date
    //     dueDate.setDate(dueDate.getDate());

    // let dueDate = new Date(allEquipmentRagister[i].calib_due_date);
    // const timeZoneOffset = dueDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // dueDate.setMinutes(dueDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedResponseDate = dueDate.toISOString().slice(0, 10);

    tableData.push({
      key: allEquipmentRagister[i].id,
      equipment_name: allEquipmentRagister[i].equipment_name,
      work_category_id: allEquipmentRagister[i].work_category_id,
      category_name: allEquipmentRagister[i].category_name,
      supplier_id: allEquipmentRagister[i].supplier_id,
      sub_contractor_name: allEquipmentRagister[i].sub_contractor_name,
      calib_due_date: moment(allEquipmentRagister[i].calib_due_date).format("DD-MM-YYYY"),
      //calib_due_date: allEquipmentRagister[i].calib_due_date.slice(0, 10),
      image: allEquipmentRagister[i].image,
    });
  }

  let excelData = [];

  for (let i = 0; i < allEquipmentRagister.length; i++) {
    //     let dueDate = new Date(allEquipmentRagister[i].calib_due_date);
    // console.log("dueDate",dueDate)
    //     // Add one day to the date
    //     dueDate.setDate(dueDate.getDate());

    // let dueDate = new Date(allEquipmentRagister[i].calib_due_date);
    // const timeZoneOffset = dueDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // dueDate.setMinutes(dueDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedResponseDate = dueDate.toISOString().slice(0, 10);

    excelData.push({
      sl_no: i + 1,
      id: allEquipmentRagister[i].id,
      equipment_name: allEquipmentRagister[i].equipment_name,
      //work_category_id: allEquipmentRagister[i].work_category_id,
      category_name: allEquipmentRagister[i].category_name,
      // supplier_id: allEquipmentRagister[i].supplier_id,
      sub_contractor_name: allEquipmentRagister[i].sub_contractor_name,
      calib_due_date: moment(allEquipmentRagister[i].calib_due_date).format("DD-MM-YYYY"),
      //calib_due_date: allEquipmentRagister[i].calib_due_date.slice(0, 10),
      image: allEquipmentRagister[i].image,
    });
  }

  const handelCategory = (categoryId) => {
    // console.log("categoryId", categoryId);
    setSelectCateId(categoryId);
  };
  // console.log("selectCateId", selectCateId);

  const [selectEditVendor, setSelectEditVendor] = useState(null);

  const handelVendor = (vendorId) => {
    setSelectEditVendor(vendorId);
  };

  const columns = [
    {
      title: "Equipmenet Name",
      dataIndex: "equipment_name",
      ...getColumnSearchProps("equipment_name"),
    },
    {
      title: "Vendor Name",
      dataIndex: "sub_contractor_name",
      ...getColumnSearchProps("sub_contractor_name"),
    },
    {
      title: "Work Category Name",
      dataIndex: "category_name",
      ...getColumnSearchProps("category_name"),
    },
    {
      title: "Calibration Date",
      dataIndex: "calib_due_date",
      ...getColumnSearchProps("calib_due_date"),
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          <Space size="middle">
            {" "}
            <Image src={text} style={{ width: "20px", height: "20px" }} />
          </Space>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];
  const showModal = (data1) => {
    // console.log("data1",data1);
    setDatePiker(moment(data1.calib_due_date));
    setequipmentRow(data1);
    setIsModalVisible(true);
    setSelectCateId(data1.work_category_id);
    setSelectEditVendor(data1.supplier_id);
    form.setFieldsValue({
      equipment_name: data1.equipment_name,
      work_category_id: data1.category_name,
      supplier_id: data1.sub_contractor_name,
      calib_due_date: moment(data1.calib_due_date, "DD-MM-YYYY"),
      image: data1.image ? [{ thumbUrl: data1.image }] : [],
    });
  };

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };
  const onConfirm = async (keys) => {
    await delectEquipment(keys);
    await getAllEquipmentRagister(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
    //await count(logindetails.organisation.orgID)
  };

  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else if (keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };
  // const data = []
  // for (let i = 0; i < selectorgetEquipmentRagisterState.length; i++) {
  //     data.push({
  //         key: selectorgetEquipmentRagisterState[i].id,
  //         id: selectorgetEquipmentRagisterState[i].id,
  //         equipment_name: selectorgetEquipmentRagisterState[i].equipment_name,
  //         work_category_id: selectorgetEquipmentRagisterState[i].work_category_id,
  //         supplier_id: selectorgetEquipmentRagisterState[i].supplier_id,
  //         calib_due_date: selectorgetEquipmentRagisterState[i].calib_due_date,

  //     })
  // }
  const onChange = (date, dateString) => {
    // console.log("------", date, dateString);
    setDatePiker(dateString);
  };

  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const handleCancelmodal = () => setPreviewvisible(false);

  return (
    <>
      <div className="title-show-project">List Equipmenet Register</div>
      <br />
      <div className="btn__">
        <Button className="excel">
          <CSVLink
            filename={"List_of_equipment_reg.csv"}
            data={excelData}
            className="excel"
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to Excel
          </CSVLink>
        </Button>
        <div>
          {/* <Button className="btn-add" onClick={handleShowModal} > Add Equipment Register</Button> */}
          <Modal
            visible={visible}
            onCancel={handleHideModal}
            footer={null}
            width="75%"
            style={{ top: 80 }}
          >
            <AddEquipmentRegister onFinish={handleFinish} />
          </Modal>
        </div>
      </div>
      <Table
        className="table"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={tableData}
        pagination={10}
        scroll={{ x: "90vw" }}
      />
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        width={700}
        centered={true}
        footer={null}
      >
        <div className="edit-in__charge">Edit Equipment Register</div>
        <br />
        <Form
          form={form}
          name="list-equipment"
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={onEditInEquipmentragisterFinish}
          onFinishFailed={onEditEquipmentFinishFailed}
        >
          <Form.Item
            name="equipment_name"
            label="Equipment Name"
            rules={[
              { required: true, message: "Please enter Equipment Name!" },
            ]}
            className="input-field"
          >
            <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>
          <Form.Item
            name="work_category_id"
            label="Work Category Name"
            rules={[
              { required: true, message: "Please select Work Category Name!" },
            ]}
            className="input-field"
          >
            <Select
              // mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Work Category Name"
              onChange={(categoryId) => handelCategory(categoryId)}
            >
              {AllWorkCategory.filter(
                (item) => item.resource_type === "Equipment"
              ).map((getAllCategory) => (
                <option key={getAllCategory.id} value={getAllCategory.id}>
                  {getAllCategory.category_name}
                </option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="supplier_id"
            label="Vendor Name"
            rules={[{ required: true, message: "Please enter Vendor Name!" }]}
            className="input-field"
          >
            {/* <Input placeholder="" onKeyDown={(e) => onKeydown(e)} /> */}
            <Select
              // mode="multiple"

              style={{ width: "100%" }}
              placeholder="Please select Vendor Name"
              onChange={(vendorId) => handelVendor(vendorId)}
            >
              {allSubContractor
                .filter((item) => item.category_id == selectCateId)
                .map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.sub_contractor_name}
                  </option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="calib_due_date"
            label="Calibration Date"
            rules={[{ required: true, message: "Please enter Calibration Date" }]}
            className="input-field"
          >
            {/* <Space direction="vertical" style={{ width: '100%' }}>
                        <DatePicker status="error" style={{ width: '100%' }} onKeyDown={(e) => onKeydown(e)} />
                    </Space> */}

            {/* <DatePicker id="calib_due_date" style={{ width: '100%' }} type="date" name="calib_due_date" format={"DD-MM-YYYY"} onKeyDown={(e) => onKeydown(e)} /> */}
            <DatePicker
            format="DD-MM-YYYY"
              onChange={onChange}
              id="calib_due_date"
              style={{ width: "100%" }}
              type="date"
              name="calib_due_date"
              onKeyDown={(e) => onKeydown(e)}
            />
          </Form.Item>

          <Form.Item
            name="image"
            label="Equipment Photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="input-field"
            
          >
            {/* action="/upload.do" */}
            <Upload name="logo" listType="picture" accept=".jpg,.jpeg,.png" onPreview={handlePreview}
            maxCount={"1"}   beforeUpload={(file, fileList) => {
              return false;
            }}>
              <Button icon={<UploadOutlined />}>Choosen File</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <div className="btn-listequipment">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>

              <Button
                onClick={clear_fields}
                className="btn-cancel"
                style={{ borderRadius: "10px" }}
              >
                Reset
              </Button>
            </div>
          </Form.Item>
          <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelmodal}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        </Form>
      </Modal>
      <br />
      <Button className="copy" onClick={deleteSelected}>
        Delete All Selected
      </Button>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ListEquipmentRegister);
