// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-deployment{
   text-align:center;
}
.btn-deployment.btn-cancel{
   margin-left: 10px;
   border-radius: 10px;

}
.btn-deployment.btn-cancel:hover{
   color: #f1e206;
   border:1px solid #f1e206;
}`, "",{"version":3,"sources":["webpack://./src/components/resources/content/Forms/css/DeploymentSummaryTable.css"],"names":[],"mappings":"AAAA;GACG,iBAAiB;AACpB;AACA;GACG,iBAAiB;GACjB,mBAAmB;;AAEtB;AACA;GACG,cAAc;GACd,wBAAwB;AAC3B","sourcesContent":[".btn-deployment{\n   text-align:center;\n}\n.btn-deployment.btn-cancel{\n   margin-left: 10px;\n   border-radius: 10px;\n\n}\n.btn-deployment.btn-cancel:hover{\n   color: #f1e206;\n   border:1px solid #f1e206;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
