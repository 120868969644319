// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div_box{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

@media screen and (max-width: 600px){
    .div_box{
        box-shadow: none;
        padding: 0px;
    }
}
.input-field {
    width: 45%;
    margin-right: 5%;
    display: inline-block;
}

.firstRow1,
.secondRow1 {
    display: inline-block;
    width: 46%;
}

.thirdRow1 {
    display: inline-block;
    width: 4%;
    
}

@media screen and (max-width: 600px) {
    .input-field {
        width: 100% !important;
        margin-right: 0;
        display: block;
    }

    .firstRow1,
    .secondRow1{
        display: block;
        width: 100%;
    }

    .thirdRow1 {
        display: block;
        width: 100%;
       
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/qcc/content/css/RequestForInspection.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;QACI,gBAAgB;QAChB,YAAY;IAChB;AACJ;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;;IAEI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,SAAS;;AAEb;;AAEA;IACI;QACI,sBAAsB;QACtB,eAAe;QACf,cAAc;IAClB;;IAEA;;QAEI,cAAc;QACd,WAAW;IACf;;IAEA;QACI,cAAc;QACd,WAAW;;IAEf;AACJ","sourcesContent":[".div_box{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n    margin-bottom: 30px;\n}\n\n@media screen and (max-width: 600px){\n    .div_box{\n        box-shadow: none;\n        padding: 0px;\n    }\n}\n.input-field {\n    width: 45%;\n    margin-right: 5%;\n    display: inline-block;\n}\n\n.firstRow1,\n.secondRow1 {\n    display: inline-block;\n    width: 46%;\n}\n\n.thirdRow1 {\n    display: inline-block;\n    width: 4%;\n    \n}\n\n@media screen and (max-width: 600px) {\n    .input-field {\n        width: 100% !important;\n        margin-right: 0;\n        display: block;\n    }\n\n    .firstRow1,\n    .secondRow1{\n        display: block;\n        width: 100%;\n    }\n\n    .thirdRow1 {\n        display: block;\n        width: 100%;\n       \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
