import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Select, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPurchaseOrders,
  getGrnQty,
  getGrnItemRcvRejQty,
  createPoReplacementItemData,
  getCurrentStockItemCodeWise,
  insertPoReplacementItems,
  updateReplaceItemQtyInStock,
  updateReplaceQtyInGrnTable,
  getPoReplacementItemData,
  createGrnNumbers,
} from "../../../../redux-core/materials/actions";
import {
  selectorGetPurchaseOrder,
  selectorGetGrnQty,
  selectorGrnItemsRecRejQty,
  selectorGetItemInCurrentStockItemCodeWise,
  selectorCountGrnNumber,
} from "../../../../redux-core/materials/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { getCountGrnNumber } from "../../../../redux-core/materials/actions";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { selectorGetProjByProjId } from "../../../../redux-core/settings/selectors";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getAllPurchaseOrders: (projId) => dispatch(getAllPurchaseOrders(projId)),
  getGrnQty: (poNumber) => dispatch(getGrnQty(poNumber)),
  getGrnItemRcvRejQty: (grnNumber) => dispatch(getGrnItemRcvRejQty(grnNumber)),
  createPoReplacementItemData: (replaceData) =>
    dispatch(createPoReplacementItemData(replaceData)),
  getCurrentStockItemCodeWise: (itemCode, projectID) =>
    dispatch(getCurrentStockItemCodeWise(itemCode, projectID)),
  insertPoReplacementItems: (replace) =>
    dispatch(insertPoReplacementItems(replace)),
  updateReplaceItemQtyInStock: (replace) =>
    dispatch(updateReplaceItemQtyInStock(replace)),
  updateReplaceQtyInGrnTable: (replace) =>
    dispatch(updateReplaceQtyInGrnTable(replace)),
  getPoReplacementItemData: (projectID) =>
    dispatch(getPoReplacementItemData(projectID)),
  getProjectByprojectId: (projeID) => dispatch(getProjectByprojectId(projeID)),
  getCountGrnNumber: (projeID) => dispatch(getCountGrnNumber(projeID)),
  createGrnNumbers: (grn) => dispatch(createGrnNumbers(grn)),
});

const { Option } = Select;
function PoItemsReplaceFrom(props) {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const {
    getAllPurchaseOrders,
    getGrnQty,
    getGrnItemRcvRejQty,
    createPoReplacementItemData,
    getCurrentStockItemCodeWise,
    insertPoReplacementItems,
    updateReplaceItemQtyInStock,
    updateReplaceQtyInGrnTable,
    getPoReplacementItemData,
    getProjectByprojectId,
    getCountGrnNumber,
    createGrnNumbers,
  } = actionDispatch(useDispatch());
  const getPurchaseOrder = useSelector(selectorGetPurchaseOrder);
  const getAllGrnQty = useSelector(selectorGetGrnQty);
  const getGrnitemsRcvRejQty = useSelector(selectorGrnItemsRecRejQty);
  const getShowItemInCurrentStockItemCodeWise = useSelector(
    selectorGetItemInCurrentStockItemCodeWise
  );
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [poNumber, setPoNumber] = useState();
  const [replaceDate, setReplaceDate] = useState();
  const [selectGrn, setSelectGrn] = useState();
  const [selectItem, setSelectItem] = useState();
  const [replaceNumber, setReplaceNumber] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const countGrnNumber = useSelector(selectorCountGrnNumber);
  const [hideForm, setHideForm] = useState(false);

  //useEffect write

  useEffect(() => {
    getCountGrnNumber(props.projectID);
    getProjectByprojectId(props.projectID);
    getAllPurchaseOrders(props.projectID);
    getGrnQty(poNumber);
    getGrnItemRcvRejQty(selectGrn);
  }, [props.projectID, poNumber, selectGrn]);

  //search enegine function write here
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  // In Date picker dateString find
  const handleReplaceDate = (value, dateString) => {
    setReplaceDate(dateString);
  };

  // find the Item Code in Grn Item Reject table
  const itemCode = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.item_code);

  //find the Qty in Grn Item Reject table
  const rejQty = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.reject_qty);

  //find the Id in Grn Item Reject table
  const GrnId = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.id);

  // show grn table item rate
  const ItemRate = getGrnitemsRcvRejQty
    ?.filter((item) => item.item_id === selectItem)
    ?.map((item) => item.item_rate);

  //find item id in current stock by item code wise
  const StockItemId = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.id);

  //find item total qty in current stock by item code wise
  const StockItemQty = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.total_qty);

  //find item Replace qty in current stock by item code wise
  const StockReplaceItemQty = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.replace_qty);

  const StockItemValue = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.item_rate);

  const replace_value = getShowItemInCurrentStockItemCodeWise
    ?.filter((item) => item.item_code === itemCode[0])
    ?.map((item) => item.replace_value);

  // useEffect Write For show initial Value

  useEffect(() => {
    form2.setFieldsValue({
      item_code: itemCode[0],
      reject_qty: rejQty[0],
    });
    getCurrentStockItemCodeWise(itemCode[0], props.projectID);
  }, [itemCode[0], rejQty[0]]);

  const resetValue = () => {
    form2.setFieldsValue({
      item_id: "",
      item_code: "",
      reject_qty: "",
      replace_qty: "",
    });
  };

  // po item Replacement submit function
  const HandelReplacementItems = async (value) => {
    console.log("Valuse: ", value);
    // return;
    const itemRates = Number(ItemRate?.[0]) * Number(value.replace_qty);
    const updateItemStock = {
      id: StockItemId[0],
      replace_qty: Number(StockReplaceItemQty[0]) + Number(value.replace_qty),
      total_qty: Number(StockItemQty[0]) + Number(value.replace_qty),
      item_rate: Number(StockItemValue?.[0]) + Number(itemRates),
      replace_value: Number(replace_value?.[0]) + Number(itemRates),
    };

    const updateInGrn = {
      id: GrnId[0],
      reject_qty: Number(rejQty) - Number(value.replace_qty),
    };

    const addReplaceItem = {
      project_id: props.projectID,
      replace_grn_no: replaceNumber,
      grn_no: value.grn_no,
      item_id: selectItem,
      item_code: value.item_code,
      reject_qty: value.reject_qty,
      replace_qty: value.replace_qty,
      orgID: orgId,
      created_by: userId,
    };
    let response = await insertPoReplacementItems(addReplaceItem);
    if (response) {
      if (response.success === true) {
        await updateReplaceItemQtyInStock(updateItemStock);
        await updateReplaceQtyInGrnTable(updateInGrn);
      }
      resetValue();
      getPoReplacementItemData(props.projectID);
    }
  };
  const resetAllValue = () => {
    setHideForm(false);
    form.resetFields();
    form2.resetFields();
    setIsDisable(false);
    getCountGrnNumber(props.projectID);
  };

  let count_number =
    countGrnNumber[0].no_of_grn_no === 0
      ? "001"
      : (Number(countGrnNumber[0].no_of_grn_no) + 1)
          .toString()
          .padStart(3, "0");
  const GRN_NUMBER =
    "GRN" + "/" + getProjectByProjId[0].project_code + "/" + count_number;

  useEffect(() => {
    form.setFieldsValue({
      replace_grn_no: GRN_NUMBER,
    });
    setReplacementFormData({
      ...replacementFormData,
      replace_grn_no: GRN_NUMBER,
    });
  }, [GRN_NUMBER]);

  //   replacement data submit
  const HandelReplacementPo = async (e) => {
    e.preventDefault();
    console.log("Formdata: ", replacementFormData);
    // return;
    setReplaceNumber(replacementFormData.replace_grn_no);
    const replaceData = {
      po_number: replacementFormData.po_number,
      replace_date: replacementFormData.replace_date,
      replace_grn_no: replacementFormData.replace_grn_no,
      project_id: props.projectID,
      orgID: orgId,
      created_by: userId,
    };

    try {
      let response = await createPoReplacementItemData(replaceData);
      if (response) {
        toast.success("Success..");
        setHideForm(true);
        setIsDisable(true);
      }

      const Grn_number_insert = {
        project_id: props.projectID,
        grn_number: replacementFormData.replace_grn_no,
        grn_date: replacementFormData.replace_date,
        orgID: orgId,
        created_by: userId,
      };
      await createGrnNumbers(Grn_number_insert);
    } catch (err) {
      toast.error("Failed..");
      console.log("Error: ", err);
    }
  };

  const [replacementFormData, setReplacementFormData] = useState({
    po_number: "",
    replace_date: "",
    replace_grn_no: "",
  });

  useEffect(() => {
    console.log("getAllGrnQty: ", getAllGrnQty);
  }, [getAllGrnQty]);

  return (
    <>
      <div className=" font-montserrat">
        {/* <Form
          name="po_replacement"
          form={form}
          size="large"
          initialValues={{
            remember: true,
          }}
          onFinish={HandelReplacementPo}
        >
          <Form.Item
            name="po_number"
            label="Purchase Order Number"
            rules={[{ required: true, message: "Please select Po Number!" }]}
            className="input-field"
          >
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Po Number"
              onChange={(poNum) => setPoNumber(poNum)}
              filterOption={handleSearch}
              disabled={isDisable}
            >
              {getPurchaseOrder?.materials?.map((item) => (
                <Option key={item.id} value={item.po_number}>
                  {item.po_number}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="replace_date"
            label="Replace Date"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Replace Date",
              },
            ]}
          >
            <DatePicker
              name="replace_date"
              format="DD-MM-YYYY"
              onChange={handleReplaceDate}
              placeholder="Enter Date"
              disabled={isDisable}
            />
          </Form.Item>

          <Form.Item
            name="replace_grn_no"
            label="Replace GRN No."
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Grn Number Create",
              },
            ]}
            initialValue={GRN_NUMBER}
          >
            <Input placeholder="Enter Grn Number Create" disabled />
          </Form.Item>

          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                disabled={isDisable}
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>
            </div>
          </Form.Item>
        </Form> */}

        {/* new form shivam kashyap 13 june 2024  */}
        <div className=" font-semibold  text-2xl mb-4">
          New PO Item Replacement
        </div>
        <form
          className="font-montserrat flex flex-col items-center  gap-y-3"
          onSubmit={HandelReplacementPo}
        >
          {/* 1a  */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="replace_grn_no"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Replace GRN No.
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="replace_grn_no"
                  value={replacementFormData.replace_grn_no}
                  onChange={(e) => {
                    setReplacementFormData({
                      ...replacementFormData,
                      replace_date: e.target.value,
                    });
                  }}
                  disabled
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="po_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Purchase Order Number
              </label>
              <select
                required
                name="po_number"
                value={replacementFormData.po_number}
                onChange={(e) => {
                  setReplacementFormData({
                    ...replacementFormData,
                    po_number: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option defaultChecked value="">
                  Select
                </option>
                {getPurchaseOrder?.materials?.map((item) => (
                  <option key={item.id} value={item.po_number}>
                    {item.po_number}
                  </option>
                ))}
              </select>
            </div>
            {/* 3 date */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="replace_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Replace Date
              </label>
              <input
                required
                type="date"
                name="replace_date"
                value={replacementFormData.invoice_date}
                onChange={(e) => {
                  setReplacementFormData({
                    ...replacementFormData,
                    replace_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                // placeholder="Select a date"
              />
            </div>
          </div>
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
            <button
              onClick={() => {
                setReplacementFormData({
                  ...replacementFormData,

                  po_number: "",
                  replace_date: "",
                  // replace_grn_no: "",
                });
                // setImageFile([]);
              }}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      {hideForm && (
        <div className="">
          <Form
            name="replacement_item"
            form={form2}
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={HandelReplacementItems}
          >
            <Form.Item
              name="grn_no"
              label="GRN Number"
              rules={[{ required: true, message: "Please select GRN Number!" }]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select GRN Number"
                onChange={(grn) => setSelectGrn(grn)}
                filterOption={handleSearch}
              >
                {getAllGrnQty?.materials?.map((item) => (
                  <Option key={item.id} value={item.grn_no}>
                    {item.grn_no}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="item_id"
              label="Item Name"
              rules={[{ required: true, message: "Please select Item Name!" }]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Item Name"
                onChange={(item_id) => setSelectItem(item_id)}
                filterOption={handleSearch}
              >
                {getGrnitemsRcvRejQty?.map((item) => (
                  <Option key={item.id} value={item.item_id}>
                    {item.material_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="item_code"
              label="Item Code"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Item Code",
                },
              ]}
              initialValue={itemCode[0]}
            >
              <Input placeholder="Enter Item Code" disabled />
            </Form.Item>

            <Form.Item
              name="reject_qty"
              label="Reject QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Reject Qty",
                },
              ]}
              initialValue={rejQty[0]}
            >
              <Input placeholder="Enter Reject Qty" disabled />
            </Form.Item>

            <Form.Item
              name="replace_qty"
              label="Replace QTY"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Replace Qty",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const rejectQty = getFieldValue("reject_qty");
                    if (
                      !rejectQty ||
                      parseFloat(value) <= parseFloat(rejectQty)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Replace Qty cannot exceed Reject Qty"
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Enter Replace Qty" />
            </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>

          <div className="btn-project">
            <Button
              onClick={() => resetAllValue()}
              className="btn-cancel"
              style={{ marginRight: 25 }}
            >
              <strong>Reset</strong>
            </Button>
          </div>

          {/* new form shivamkashyap  13 june 2024*/}
          {/* <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={HandelReplacementItems}
          ></form> */}
        </div>
      )}
    </>
  );
}

export default PoItemsReplaceFrom;
