// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .dashboard-root{
    padding: 10px;
    /* display: inline; 
} */

.dashboard-root1 .sub-main{
    /* border:1px solid black; */
    display: inline-block;
    padding: 20px;
    margin:20px;
    margin-right: 40px;
    font-weight: bold;
    font-size: large;
    box-shadow: 5px 5px 10px #534040;
    border-radius: 10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.top{
    padding: 10px;
    width: 100%;
    /* display: inline; */
}

.top .left__value{
    color: #2b2835;
}

.top .right__icon{
    float: right;
    display: table;
}
.top .right__icon svg{
    font-size: xx-large;
    width: 105%;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #ffff00;
    border-radius: 100%;
    padding: 5px;
    box-shadow: 7px 5px 5px rgb(38, 38, 51);
}

.bottom__title{
    padding-right: 90px;
    width: 100%;
    color:rgb(88, 82, 82);
}


@media screen and (max-width: 600px){
    .dashboard-root1 .sub-main{
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        font-size: large;
        /* padding-top: px; */
    }
    .bottom__title{
        padding-right: 40px;
    }
    .top .right__icon svg{
        font-size: x-large;
    }   
}`, "",{"version":3,"sources":["webpack://./src/components/qcc/content/css/Dashboard.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,4BAA4B;IAC5B,qBAAqB;IACrB,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,gCAAgC;IAChC,mBAAmB;IACnB,sHAAsH;AAC1H;;AAEA;IACI,aAAa;IACb,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,uCAAuC;AAC3C;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,qBAAqB;AACzB;;;AAGA;IACI;QACI,eAAe;QACf,cAAc;QACd,WAAW;QACX,gBAAgB;QAChB,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["/* .dashboard-root{\n    padding: 10px;\n    /* display: inline; \n} */\n\n.dashboard-root1 .sub-main{\n    /* border:1px solid black; */\n    display: inline-block;\n    padding: 20px;\n    margin:20px;\n    margin-right: 40px;\n    font-weight: bold;\n    font-size: large;\n    box-shadow: 5px 5px 10px #534040;\n    border-radius: 10px;\n    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n}\n\n.top{\n    padding: 10px;\n    width: 100%;\n    /* display: inline; */\n}\n\n.top .left__value{\n    color: #2b2835;\n}\n\n.top .right__icon{\n    float: right;\n    display: table;\n}\n.top .right__icon svg{\n    font-size: xx-large;\n    width: 105%;\n    height: fit-content;\n    background-color: #ffff00;\n    border-radius: 100%;\n    padding: 5px;\n    box-shadow: 7px 5px 5px rgb(38, 38, 51);\n}\n\n.bottom__title{\n    padding-right: 90px;\n    width: 100%;\n    color:rgb(88, 82, 82);\n}\n\n\n@media screen and (max-width: 600px){\n    .dashboard-root1 .sub-main{\n        margin-right: 0;\n        margin-left: 0;\n        width: 100%;\n        font-size: large;\n        /* padding-top: px; */\n    }\n    .bottom__title{\n        padding-right: 40px;\n    }\n    .top .right__icon svg{\n        font-size: x-large;\n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
