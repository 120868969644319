import "antd/dist/antd.css";
import "./css/AddWorkCategoryForm.css";
import { Form, Input, Button, Select } from "antd";
import {
  createNewCategory,
  getResourcesDashboard,
  getUom,
} from "../../../../redux-core/resources/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectorgetAllUomState } from "../../../../redux-core/resources/selectors";

import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  newWorkCategory: (newWork) => dispatch(createNewCategory(newWork)),
  uom: () => dispatch(getUom()),
  getResourcesDashboard: (orgId) => dispatch(getResourcesDashboard(orgId)),
});

// Resource Type
const options = [
  { value: "Labour", label: "Labour" },
  { value: "Equipment", label: "Equipment" },
];

//Select UOM
// const option = [
//     // { value: 'sqm' },
//     // { value: 'MT' },
//     // { value: 'sft' },
//     // { value: 'sqin' },
//     // { value: 'foot' },
//     // { value: 'cms' },
//     // { value: 'No.of Labours' },
//     // { value: 'Inches' }

// ];

const AddWorkCategoryForm = () => {
  const { newWorkCategory, uom, getResourcesDashboard } = actionDispatch(
    useDispatch()
  );
  const [form] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const getAllUom = useSelector(selectorgetAllUomState);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;

  useEffect(() => {
    uom();
    // console.log(getAllUom)
  }, []);
  const { Option } = Select;
  const children = [];
  for (let i = 0; i < getAllUom.length; i++) {
    children.push(
      <Option key={getAllUom[i].id} value={getAllUom[i].uom_name}>
        {getAllUom[i].uom_name}
      </Option>
    );
  }

  const onAddWorkCategoryFinish = async (values) => {
    let data = {
      ...values,
      ...{
        orgID: orgId,
        created_by: userId,
      },
    };
    let response = await newWorkCategory(data);
    if (response) {
      await clear_work_category_field();
      getResourcesDashboard(orgId);
    }
    // console.log("success: "    ,values)
  };
  const clear_work_category_field = () => {
    form.setFieldsValue({
      work_category_id: "",
      category_name: "",
      resource_type: "Select Resource Type",
      uom: "Select UOM",
      remarks: "",
    });
  };

  const onAddWorkCategoryFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Form
        form={form}
        name="add-workCategory"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddWorkCategoryFinish}
        onFinishFailed={onAddWorkCategoryFinishFailed}
      >
        <Form.Item
          name="category_name"
          label="Work Category Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the Work Category Name!",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          name="resource_type"
          label="Vendor Type"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please Select Vendor Type!",
            },
          ]}
        >
          <Select
            placeholder="Please select Vendor Type"
            value={options.value}
            options={options}
            defaultValue={options[""]}
          ></Select>
        </Form.Item>

        <Form.Item
          name="uom"
          label="Select UOM"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please Select UOM",
            },
          ]}
        >
          <Select placeholder="Please select UOM">
            {/* {getAllUom.map(uom => (
                            <option key={uom.id} value={uom.name}>{uom.uom_name}</option>
                        ))} */}
            {children}
          </Select>
        </Form.Item>
        <Form.Item name="remarks" label="Remarks" className="input-field">
          <Input.TextArea style={{ maxHeight: "70px", minHeight: "70px" }} />
        </Form.Item>

        <Form.Item>
          <div className="btn-addwork">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_work_category_field} className="btn-cancel">
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};
export default AddWorkCategoryForm;
