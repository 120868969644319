import "./css/LabourDeployment.css";
import "antd/dist/antd.css";
import React from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import EquiupmentDeploymentForm from "./Forms/EquiupmentDeploymentForm";
function EquipmentDeployment() {
  const Spindata = useSelector((item) => item.spinreducer);
  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="labour-deployment">
          <div className="sub-title">Equipment Deployment</div>
          <br />
          <EquiupmentDeploymentForm />
        </div>
      </Spin>
    </>
  );
}

export default React.memo(EquipmentDeployment);
