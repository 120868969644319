export const formatDate = (dateString) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formatNumberWithCommas = (number) => {
  if (typeof number === "string") {
    const parsedNumber = parseFloat(number);
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toLocaleString("en-US");
    } else {
      return "-";
    }
  } else if (typeof number === "number") {
    return number.toLocaleString("en-US");
  } else {
    return "-";
  }
};
