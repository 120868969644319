import React, { useEffect, useState } from "react";
import "./css/ListEquipmentDeploymentForm.css";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Select } from "antd";
import { getAllProj } from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import ListEquipmentDeploymwntTable from "./ListEquipmentDeploymwntTable";
import { selectorgetEquipmentSummary } from "../../../../redux-core/resources/selectors";
import {
  getAllEquipmentDeployHeader,
  getEquipmentDeploymentSummary,
} from "../../../../redux-core/resources/actions";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  getAllProject: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllEquipmentDeployHeader: (orgID, projID) =>
    dispatch(getAllEquipmentDeployHeader(orgID, projID)),
});

const ListEquipmentDeploymentForm = () => {
  const { getAllProject, getAllEquipmentDeployHeader } = actionDispatch(
    useDispatch()
  );
  const [form] = Form.useForm();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const allProjects = useSelector(makeSelectAllProj);

  useEffect(() => {
    getAllProject(orgId, userId);
  }, []);

  const onFinish = async (values) => {
    // console.log(values)
    setShowTable(true);
    await getAllEquipmentDeployHeader(orgId, values.project_id);
    setSelectedProjectId(values.project_id);
  };
  // console.log(selectedProjectId)
  return (
    <>
      <Form
        form={form}
        name="project-details"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        // onFinishFailed={onProjectDetailFinishFailed}
      >
        <Form.Item
          name="project_id"
          label="Select Project"
          className="input-fields"
          rules={[
            {
              required: true,
              message: "Please select Project !",
            },
          ]}
        >
          <Select placeholder="Select Project" allowClear>
            {/* {children}  */}
            {allProjects.map((getAllProject) => (
              <option
                key={getAllProject.project_id}
                value={getAllProject.project_id}
              >
                {getAllProject.project_name}
              </option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <div className="btn-list_labour">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Submit</strong>
            </Button>

            {/* <Button
                        //  onClick={clear_ProjectDetails_field} 
                         className="btn-cancel" style={{borderRadius: "10px"}}>
                          <strong>Reset</strong>
                          </Button>                                            */}
          </div>
        </Form.Item>
      </Form>
      {showTable && (
        <ListEquipmentDeploymwntTable projectId={selectedProjectId} />
      )}
    </>
  );
};

export default ListEquipmentDeploymentForm;
