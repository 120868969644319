import { ClockCircleOutlined, ContainerOutlined } from "@ant-design/icons";
import "./css/Dashboard.css";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { selectorCount } from "../../../redux-core/qcc/selectors";
import React, { useState } from "react";
import TaskShow from "./TaskShow";
import ActivityShow from "./ActivityShow";
import ShowRfiTable from "./Forms/ShowRfiTable";
function Dashboard() {
  const countData = useSelector(selectorCount);
  const [hideDashboard, setHideDashboard] = useState(true);
  const [hideBackbutton, setHideBackbutton] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [showRfi, setShowRfi] = useState(false);
  console.log("countData",countData)

  const handelClickTask = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowTask(true);
  };

  const handelClickActivity = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowActivity(true);
  };

  const handelClickRfi = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowRfi(true)
  }

  const handelBack = () => {
    setHideDashboard(true);
    setHideBackbutton(false);
    setShowTask(false);
    setShowActivity(false);
    setShowRfi(false)
  };
  return (
    <>
      {hideBackbutton && (
        <Button
          type="primary"
          style={{ borderRadius: "10px", width: "7%" }}
          onClick={() => handelBack()}
        >
          Back
        </Button>
      )}
      {hideDashboard && (
        <div className="dashboard-root1">
          <div className="sub-main" onClick={() => handelClickTask()}>
            <div className="top">
              <span className="left__value">{countData[4]?.no_of_tasks}</span>
              <span className="right__icon">
                <ContainerOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Tasks</div>
          </div>
          <div className="sub-main" onClick={() => handelClickActivity()}>
            <div className="top">
              <span className="left__value">
                {countData[3]?.no_of_activities}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Activities</div>
          </div>
          <div className="sub-main" onClick={() => handelClickRfi()}>
            <div className="top">
              <span className="left__value">
                {countData[0]?.no_of_rfi}
              </span>
              <span className="right__icon">
                <ClockCircleOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of RFI</div>
          </div>
          {/* <div className="sub-main">
                    <div className="top">
                        <span className="left">14</span>
                        <span className="right"><DashboardOutlined /></span>
                    </div>
                    <div className="bottom">No Of UOM</div>
                </div> */}
          {/* <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData[1].no_of_master_activities}</span>
                        <span className="right__icon"><ClockCircleOutlined /></span>
                    </div>
                    <div className="bottom__title">No Of Master Activities</div>
                </div> */}
          {/* <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData[0].no_of_master_tasks}</span>
                        <span className="right__icon"><ContainerOutlined /></span>
                    </div>
                    <div className="bottom__title">No Of Master Tasks</div>
                </div> */}
        </div>
      )}
      {showTask && <TaskShow />}
      {showActivity && <ActivityShow />}
      {showRfi && <ShowRfiTable />}
    </>
  );
}

export default React.memo(Dashboard);
