import React from "react";
import { selectorUserDetail } from "../../../redux-core/userManagement/selectors";
import { useSelector } from "react-redux";
import { Card, Table } from "antd";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import "./UserModal.css";
const UserModal = () => {
  const getUserList = useSelector(selectorUserDetail);
  const logindetails = useSelector(makeSelectLoginDetail);

  return (
    <div style={{ backgroundColor: "#e6e6e6" }}>
      <Card
        hoverable
        style={{
          backgroundColor: "#e6fff9",
          width: 280,
          borderRadius: 8,
          boxShadow: " 5px 5px 5px 5px grey",
          height: 190,
        }}
      >
        {/* <div className="logo_img">
        { logindetails.organisation.image === null ? <h4>{logindetails.organisation.orgName}</h4> :
         <img
        className="logoImg"
        // src={logindetails.organisation.image}
         // src="cognisie_logo_new.png"
         alt={logindetails.organisation.orgName}
         />
  }
         </div> */}

        <p className="field" style={{ margin: "0.5rem 0" }}>
          Org Name:{"   "}
          <span className="field_item">
            {logindetails.organisation.orgName}
          </span>
        </p>

        <p className="field" style={{ margin: "0.5rem 0" }}>
          Name:{"   "}
          <span className="field_item">{logindetails.user.name}</span>
        </p>
        <p className="field" style={{ margin: "0.5rem 0" }}>
          Email: <span className="field_item">{logindetails.user.email}</span>
          {"   "}
        </p>
        <p className="field" style={{ margin: "0.5rem 0" }}>
          Phone:{"   "}
          <span className="field_item">{logindetails.user.phone}</span>
        </p>
        <p className="field">
          Role:{"   "}
          <span className="field_item">{logindetails.user.role}</span>
        </p>
      </Card>
    </div>
  );
};

export default UserModal;
