import { Image, Space, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectorGrnItemsRecRejQty } from "../../../../redux-core/materials/selectors";

function ShowPoNumberWiseItemsForm() {
  const getGrnitemsRcvRejQty = useSelector(selectorGrnItemsRecRejQty);

  const data = [];
  for (let i = 0; i < getGrnitemsRcvRejQty?.length; i++) {
    data.push({
      id: getGrnitemsRcvRejQty[i].id,
      sl_no: getGrnitemsRcvRejQty[i].sl_no,
      item_code: getGrnitemsRcvRejQty[i].item_code,
      material_name: getGrnitemsRcvRejQty[i].material_name,
      hsn_code: getGrnitemsRcvRejQty[i].hsn_code,
      rcv_qty: getGrnitemsRcvRejQty[i].rcv_qty,
      quality_check_image: getGrnitemsRcvRejQty[i].quality_check_image,
      accept_qty: getGrnitemsRcvRejQty[i].accept_qty,
      reject_qty: getGrnitemsRcvRejQty[i].reject_qty,
    });
  }

  const columns = [
    {
      title: "ID.",
      dataIndex: "id",
    },
    {
      title: "Sl No.",
      dataIndex: "sl_no",
    },

    {
      title: "Item No.",
      dataIndex: "item_code",
    },
    {
      title: "Material",
      dataIndex: "material_name",
    },
    {
      title: "HSN Code",
      dataIndex: "hsn_code",
    },

    {
      title: "Rcv Qty",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quality Inspection Record Document",
      dataIndex: "quality_check_image",
      render: (photo) => (
        <Space size="middle">
          <Image
            src={photo}
            alt="Quality Inspection Photo"
            className="checklist-image"
          />
        </Space>
      ),
    },
    {
      title: "Accepted Quantity",
      dataIndex: "accept_qty",
    },
    {
      title: "Rejected Quantity",
      dataIndex: "reject_qty",
    },
  ];
  return (
    <>
      {/* <Table
        className="custom-table"
        columns={columns}
        pagination={false}
        dataSource={data}
      /> */}

      {/* new table shivam kashyap 4 july 2024 */}
      <div className=" font-semibold  text-2xl font-montserrat">View GRN Item</div>
      <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
        <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
          <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
            {" "}
            <div className="flex flex-col items-start w-[15%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Sl No.
              </p>
            </div>
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Material
              </p>

              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Item No.
              </p>
            </div>
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                HSN Code
              </p>
            </div>
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Rcv Qty
              </p>
              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Quality Inspection Record Document
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[20%]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Accepted Quantity
              </p>
              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Rejected Quantity
              </p>
            </div>{" "}
          </li>
        </ul>{" "}
        <ul
          role="list"
          className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
        >
          {data && data.length && data[0] != null
            ? data.map((item, key) => (
                <li
                  key={key}
                  className={`flex justify-between items-center px-5 py-2 ${
                    key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                  }`}
                >
                  <div className="flex flex-col items-start w-[15%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      Sl No.
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      Material
                    </p>

                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      Item No.
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      HSN Code
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      Rcv Qty
                    </p>
                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      Quality Inspection Record Document
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[20%]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      Accepted Quantity
                    </p>
                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      Rejected Quantity
                    </p>
                  </div>{" "}
                </li>
              ))
            : "No records found..."}
        </ul>
      </div>
    </>
  );
}

export default ShowPoNumberWiseItemsForm;
