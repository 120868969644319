import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  Table,
  Tooltip,
  InputNumber,
} from "antd";
import "antd/dist/antd.min.css";
import "./Forms/css/AddProjectDetailsForm.css";
import {
  createNewTypesOfProject,
  getAllProj,
  getAllTypeOfProject,
} from "../../../redux-core/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  selectorCreateTypeOfProject,
  selectorGetTypeOfProject,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import useSearch from "../../../hooks/useSearch";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => getAllProj(orgId, userId),
  typeOfProject: (projectType) =>
    dispatch(createNewTypesOfProject(projectType)),
  getAllTypesProject: (project_id, orgId) =>
    dispatch(getAllTypeOfProject(project_id, orgId)),
});

export const TypesOfProject = (props) => {
  const { getProj, typeOfProject, getAllTypesProject } = actionDispatch(
    useDispatch()
  );
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const getColumnSearchProps = useSearch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const createTypesProject = useSelector(selectorCreateTypeOfProject);
  // const getAllTypesOfProjects = useSelector(selectorGetTypeOfProject)
  const children = [];
  const [typeProject, setTypeProject] = useState("");
  const [showProjectTypesModal, setShowProjectTypesModal] = useState(false);
  const [buildArea, setBuildArea] = useState("");
  const [showTables, setShowTables] = useState(false);
  const [makeHide, setMakeHide] = useState(true);
  const [isDisableInput, setIsDisableInput] = useState(false);
  // const [addInfo, setAddInfo] = useState("");

  useEffect(() => {
    getProj(orgId, userId);
  }, []);

  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}-
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }

  const handleProjectTypes = (value) => {
    setTypeProject(value);
    setShowProjectTypesModal(true);
    getProj(orgId, userId);
  };

  const handlebuilduparea = (values) => {
    setBuildArea(values);
  };

  const modalCancel = () => {
    setShowProjectTypesModal(false);
    form1.setFieldsValue({
      type_of_project: "select",
    });
  };

  const typesOfProjectFinish = async (e) => {
    e.preventDefault();
    setShowTables(true);
    setShowProjectTypesModal(false);
    // setMakeHide(false)
    // setIsDisableInput(true)
    let data = {
      ...formData,
      type_of_project: typeProject,
      orgID: orgId,
      createdBy: userId,
      Project_id: props.projectId,
    };

    if (data.buildup_area == "totalbuilduparea") {
      data.total_buildup_area = data.total_area;
      delete data.total_area;
    } else {
      data.total_saleable_area = data.total_area;
      delete data.total_area;
    }
    // console.log("data before submission", data);
    // return;

    try {
      let res = await typeOfProject(data);
      if (res.success) {
        toast.success("Success..");
        console.log("Res: ", res);
        setDescriptionCount(0);
        setFormData({
          buildup_area: "",
          // total_saleable_area: "",
          // total_buildup_area: "",
          total_area: "",
          basement: "",
          select_UOM: "",
          ground: "",
          floor: "",
          description1: "",
          description2: "",
          description3: "",
          description4: "",
          description5: "",
          description6: "",
          description7: "",
          description8: "",
          description9: "",
          description10: "",
        });
      }

      form.resetFields();
      setAdditionalFields([]);
      getAllTypesProject(props.projectId, orgId);
      form1.setFieldsValue({
        type_of_project: "",
      });
    } catch (err) {
      toast.error("Failed..");
      console.log("Err: ", err);
    }
  };

  const typesOfProjectFinishFaild = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showButton = () => {
    setShowTables(false);
    setIsDisableInput(false);
    form.setFieldsValue({
      // Project_id: "",
      buildup_area: [],
      total_buildup_area: "",
      total_saleable_area: "",
      select_UOM: "",
      basement: "",
      ground: "",
      floor: "",
    });
  };

  const [addInfo, setAddInfo] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const maxFields = 10;
  const addMoreInfo = () => {
    if (additionalFields.length < maxFields) {
      setAddInfo(true);
      setAdditionalFields((prevFields) => [...prevFields, {}]);
    }
  };

  const [formData, setFormData] = useState({
    buildup_area: "",
    // total_saleable_area: "",
    // total_buildup_area: "",
    total_area: "",
    basement: "",
    select_UOM: "",
    ground: "",
    floor: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    description6: "",
    description7: "",
    description8: "",
    description9: "",
    description10: "",
  });

  const [descriptionCount, setDescriptionCount] = useState(0);

  // useEffect(() => {
  //   console.log("Form data: ", formData);
  // }, [formData]);

  return (
    <>
      <Form form={form1}>
        <Form.Item name="type_of_project" label="Types of Project">
          <Select
            allowClear
            style={{ width: "40%" }}
            placeholder="Select Types of Project"
            onChange={handleProjectTypes}
            defaultValue={"select"}
          >
            <option value="select">Select Types of Project</option>
            <option value="building">Building</option>
            <option value="infrastructure">Infrastructure</option>
          </Select>
        </Form.Item>
      </Form>

      {typeProject === "building" && (
        <Modal
          width="90%"
          visible={showProjectTypesModal}
          onCancel={modalCancel}
          footer={null}
        >
          {/* <Form
            form={form}
            onFinish={typesOfProjectFinish}
            onFinishFailed={typesOfProjectFinishFaild}
          >
            <Form.Item
              name="buildup_area"
              label="Build Up Areas"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Area Type",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select Type of Area"
                onChange={handlebuilduparea}
                // disabled={isDisableInput}
              >
                <option value="totalbuilduparea">BuildUp Area</option>
                <option value="totalsaleablearea">Saleable Area</option>
              </Select>
            </Form.Item>
            {buildArea === "totalbuilduparea" && (
              <Form.Item
                className="input-field"
                name="total_buildup_area"
                label="Total BuilUp Area"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Total Buildup Area",
                  },
                ]}
              >
                <InputNumber type="number" style={{ width: "100%" }} />
              </Form.Item>
            )}
            {buildArea === "totalsaleablearea" && (
              <Form.Item
                className="input-field"
                name="total_saleable_area"
                label="Total Saleable Area"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Total Saleable Area",
                  },
                ]}
              >
                <InputNumber type="number" style={{ width: "100%" }} />
              </Form.Item>
            )}

            <Form.Item
              name="select_UOM"
              label="Select UOM"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Total Saleable Area",
                },
              ]}
            >
              <Select
                placeholder="Select UOM"
                // disabled={isDisableInput}
              >
                <option value="sq.mt">Sq.mt</option>
                <option value="sq.ft">Sq.ft</option>
              </Select>
            </Form.Item>
            <Form.Item
              name="basement"
              label="Basement"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Basement No",
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Enter the Basement No"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="ground"
              label="Ground Level"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Ground No",
                },
              ]}
            >
         
              <Select>
                <option value="ground">Ground</option>
                <option value="stilt">Stilt</option>
              </Select>
            </Form.Item>
            <Form.Item
              name="floor"
              label="No of Floors"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Enter Floor No",
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder="Enter No of Floors"
                style={{ width: "100%" }}
              />
            </Form.Item>
            {addInfo && (
              <>
                {additionalFields.map((_, index) => (
                  <Form.Item
                    key={index}
                    className="input-field"
                    name={`description${index + 1}`} // Use a dynamic name to avoid conflicts
                    label={`Description ${index + 1}`}
                    rules={[
                      {
                        required: false,
                        message: `Please Enter field${index + 1}`,
                      },
                    ]}
                  >
                    <Input placeholder={`Enter description ${index + 1}`} />
                  </Form.Item>
                ))}
              </>
            )}
            <Form.Item>
              {additionalFields.length < maxFields && (
                <Button
                  type="primary"
                  className="btn-submit"
                  onClick={addMoreInfo}
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Add More Details</strong>
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  // disabled={isDisableInput}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}

          {/* new form - shivam kashyap 27 may 2024  */}
          <div className=" font-semibold  text-2xl font-montserrat">
            Add Building details
          </div>
          <br />

          <form
            className="font-montserrat flex flex-col items-center "
            onSubmit={typesOfProjectFinish}
          >
            {/* 1a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="buildup_area"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Build Up Areas
                </label>
                <select
                  required
                  name="buildup_area"
                  onChange={(e) => {
                    // console.log("setting values");
                    setFormData({ ...formData, buildup_area: e.target.value });
                  }}
                  // value={formData.buildup_area}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  placeholder="select type of area"
                >
                  <option value="">Select</option>
                  <option value="totalbuilduparea">BuildUp Area</option>
                  <option value="totalsaleablearea">Saleable Area</option>
                </select>
              </div>
              {/* 2  */}
              <div className="">
                <label
                  htmlFor="total_area"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Total Area
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="number"
                    name="total_area"
                    value={formData.total_area}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        total_area: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 3 */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="select_UOM"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Select UOM
                </label>
                <select
                  required
                  name="select_UOM"
                  onChange={(e) => {
                    // console.log("setting values");
                    setFormData({ ...formData, select_UOM: e.target.value });
                  }}
                  // value={formData.buildup_area}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  placeholder="select type of area"
                >
                  <option value="">Select</option>
                  <option value="sq.mt">Sq.mt</option>
                  <option value="sq.ft">Sq.ft</option>
                </select>
              </div>
            </div>
            {/* 2a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1  */}
              <div className="">
                <label
                  htmlFor="basement"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Basement No.
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="number"
                    name="basement"
                    value={formData.basement}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        basement: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2 */}
              <div className="mt w-[300px]">
                <label
                  htmlFor="select_UOM"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Ground Level
                </label>
                <select
                  required
                  name="ground"
                  onChange={(e) => {
                    // console.log("setting values");
                    setFormData({ ...formData, ground: e.target.value });
                  }}
                  value={formData.ground}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  placeholder="select type of area"
                >
                  <option value="">Select</option>
                  <option value="ground">Ground</option>
                  <option value="stilt">Stilt</option>
                </select>
              </div>
              {/* 3  */}
              <div className="">
                <label
                  htmlFor="floor"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  No of Floor
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="number"
                    name="floor"
                    value={formData.floor}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        floor: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
            </div>
            {/* 3a descriptions  */}
            {/* 3a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1  */}
              {descriptionCount > 0 && (
                <div className="">
                  <label
                    htmlFor="description1"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 1
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description1"
                      value={formData.description1}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description1: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
              {/* 2  */}
              {descriptionCount > 1 && (
                <div className="">
                  <label
                    htmlFor="description1"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 2
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description2"
                      value={formData.description2}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description2: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}

              {/* 3  */}
              {descriptionCount > 2 && (
                <div className="">
                  <label
                    htmlFor="description3"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 3
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description3"
                      value={formData.description3}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description3: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* 4a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1  */}
              {descriptionCount > 3 && (
                <div className="">
                  <label
                    htmlFor="description4"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 4
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description4"
                      value={formData.description4}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description4: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
              {/* 2  */}
              {descriptionCount > 4 && (
                <div className="">
                  <label
                    htmlFor="description5"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 5
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description5"
                      value={formData.description5}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description5: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}

              {/* 3  */}
              {descriptionCount > 5 && (
                <div className="">
                  <label
                    htmlFor="description6"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 6
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description6"
                      value={formData.description6}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description6: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* 5a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1  */}
              {descriptionCount > 6 && (
                <div className="">
                  <label
                    htmlFor="description7"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 7
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description7"
                      value={formData.description7}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description7: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
              {/* 2  */}
              {descriptionCount > 7 && (
                <div className="">
                  <label
                    htmlFor="description8"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 8
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description8"
                      value={formData.description8}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description8: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}

              {/* 3  */}
              {descriptionCount > 8 && (
                <div className="">
                  <label
                    htmlFor="description9"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 9
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description9"
                      value={formData.description9}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description9: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* 6a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1  */}
              {descriptionCount > 9 && (
                <div className="">
                  <label
                    htmlFor="description10"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Description 10
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      // required
                      type="text"
                      name="description10"
                      value={formData.description10}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          description10: e.target.value,
                        });

                        // HandeleCreateProjectCode(e.target.value);
                      }}
                      // placeholder="select total area"
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
              {descriptionCount < 10 && (
                <button
                  onClick={() => {
                    setDescriptionCount((prev) => prev + 1);
                  }}
                  type="button"
                  class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  data-te-ripple-init
                >
                  Add description {descriptionCount + 1}
                </button>
              )}

              <button
                onClick={() => {
                  setFormData({
                    buildup_area: "",
                    total_area: "",
                    basement: "",
                    select_UOM: "",
                    ground: "",
                    floor: "",
                    description1: "",
                    description2: "",
                    description3: "",
                    description4: "",
                    description5: "",
                    description6: "",
                    description7: "",
                    description8: "",
                    description9: "",
                    description10: "",
                  });
                }}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>

          {/* {showTables ? (
<Table  dataSource={data} columns={columns} style={{marginTop:"20px"}}
scroll={{x:"90vw"}} />
      ) : (
        ""
      )} */}
        </Modal>
      )}
    </>
  );
};
