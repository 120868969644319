// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-61 {
   /* background: linear-gradient(to bottom right, #EF4765, #FF9A5A); */
   background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
   /* border: 0;
   border-radius: 6px; */
   box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
   margin-right: 10px;
   border: 0;
   border-radius: 12px;
   color: #FFFFFF;
   cursor: pointer;
   display: inline-block;
   font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
   font-size: 19px;
   font-weight: 500;
   line-height: 2.5;
   outline: transparent;
   padding: 0 1rem;
   text-align: center;
   text-decoration: none;
   transition: box-shadow .2s ease-in-out;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   white-space: nowrap;
 }
 .button-61:not([disabled]):focus {
   box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(58, 65, 111, .5), .125rem .125rem 1rem rgba(58, 65, 111, .5);
 }
 
 .button-61:not([disabled]):hover {
   box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(58, 65, 111, .5), .125rem .125rem 1rem rgba(58, 65, 111, .5);
 }`, "",{"version":3,"sources":["webpack://./src/components/materials/content/css/ShowMaterialStock.css"],"names":[],"mappings":"AAAA;GACG,oEAAoE;GACpE,+EAA+E;GAC/E;wBACqB;GACrB,oHAAoH;GACpH,kBAAkB;GAClB,SAAS;GACT,mBAAmB;GACnB,cAAc;GACd,eAAe;GACf,qBAAqB;GACrB,iFAAiF;GACjF,eAAe;GACf,gBAAgB;GAChB,gBAAgB;GAChB,oBAAoB;GACpB,eAAe;GACf,kBAAkB;GAClB,qBAAqB;GACrB,sCAAsC;GACtC,iBAAiB;GACjB,yBAAyB;GACzB,0BAA0B;GAC1B,mBAAmB;CACrB;CACA;GACE,mIAAmI;CACrI;;CAEA;GACE,mIAAmI;CACrI","sourcesContent":[".button-61 {\n   /* background: linear-gradient(to bottom right, #EF4765, #FF9A5A); */\n   background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);\n   /* border: 0;\n   border-radius: 6px; */\n   box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;\n   margin-right: 10px;\n   border: 0;\n   border-radius: 12px;\n   color: #FFFFFF;\n   cursor: pointer;\n   display: inline-block;\n   font-family: -apple-system,system-ui,\"Segoe UI\",Roboto,Helvetica,Arial,sans-serif;\n   font-size: 19px;\n   font-weight: 500;\n   line-height: 2.5;\n   outline: transparent;\n   padding: 0 1rem;\n   text-align: center;\n   text-decoration: none;\n   transition: box-shadow .2s ease-in-out;\n   user-select: none;\n   -webkit-user-select: none;\n   touch-action: manipulation;\n   white-space: nowrap;\n }\n .button-61:not([disabled]):focus {\n   box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(58, 65, 111, .5), .125rem .125rem 1rem rgba(58, 65, 111, .5);\n }\n \n .button-61:not([disabled]):hover {\n   box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(58, 65, 111, .5), .125rem .125rem 1rem rgba(58, 65, 111, .5);\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
