import 'antd/dist/antd.min.css'
import {Modal } from 'antd';
const { confirm } = Modal;
export function ShowDeleteConfirm(props){
    confirm({
        title: 'Are you sure want to delete..?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          props.onConfirm();
        },
      });
};

export function ShowSubmitGRNConfirm(props) {

  const { onConfirms } = props;
 
  confirm({
    title: `${onConfirms} Numbers of Items received and entered in this GRN. Proceed  to create GRN ?`,
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      props.onConfirm();
    },
  });
}  

export function CreateGrn(props) {
  confirm({
    title: 'Proceed to show PO Items ? ',
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      props.onConfirm();
    },
  });
}
export function approveitem(props) {
  confirm({
    title: 'Show Items for Approval ',
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      props.onConfirm();
    },
  });
}
export function checkitem(props) {
  confirm({
    title: 'Show Items For Check',
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      props.onConfirm();
    },
  });
}

export function Showlogout(props) {
  confirm({
    title: 'Are you sure want to Logout..?',
    okText: 'Yes',
    okType: 'primary',
    cancelText: 'No',
    onOk() {
      props.onConfirm();
      
    },
  });
}

export function ShowUploadMms(props) {
  confirm({
    title: 'Please follow this Sample Excel Format for Upload Your Project Schedule',
    okText: 'Ok',
    okType: 'primary',
    cancelText: 'Cancel',
    onOk() {
      props.onConfirm();
      
    },
  });
}

export function ShowTaskUpload(props) {
  confirm({
    title: 
    "Please follow this sample Excel format for uploading tasks. If you want to enter multiple stage IDs, please use this format: '166,158  ",
    okText: 'Ok',
    okType: 'primary',
    cancelText: 'Cancel',
    onOk() {
      props.onConfirm();
      
    },
  });
}

export function notApproveMessage(props) {
  confirm({
    title: 
    "Approval not granted because your linked work order has not been approved! Please review and approve the linked work order before proceeding.",
    okText: 'Ok',
    okType: 'primary',
    cancelText: 'Cancel',
    onOk() {
      props.onConfirm();
      
    },
  });
}

export function qtyValidateMassage(props) {
  confirm({
    title: 
    "Total Quantity should not exceed the BOQ Quantity. Quantity will be limited to BOQ and accept will be shown in variation statement.",
    okText: 'Ok',
    okType: 'primary',
    cancelText: 'Cancel',
    onOk() {
      props.onConfirm();
      
    },
  });
}

