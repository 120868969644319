import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import "./css/AddProjectForm.css";
import { Form, Button, Upload } from "antd";
import { bulkProjectDetail, getAllProjDetails } from "../../../../redux-core/settings/actions";
import * as XLSX from "xlsx";
// import Axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

// const props = {
//     name: 'file',
//     action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//     headers: {
//         authorization: 'authorization-text',
//     },
//     onChange(info) {
//         if (info.file.status !== 'uploading') {
//             console.log(info.file, info.fileList);
//         }
//         if (info.file.status === 'done') {
//             message.success(`${info.file.name} file uploaded successfully`);
//         } else if (info.file.status === 'error') {
//             message.error(`${info.file.name} file upload failed.`);
//         }
//     },
// };

const actionDispatch = (dispatch) => ({
  bulkProjectDetail: (bulk) => dispatch(bulkProjectDetail(bulk)),
  getAllProjDetails: (projectId, orgId, userId) =>
  dispatch(getAllProjDetails(projectId, orgId, userId)),
});

const UploadProjectDetailsZipForm = (props) => {
  const { bulkProjectDetail,getAllProjDetails } = actionDispatch(useDispatch());
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState(false);
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;

  const onFinish = async (values) => {
    if (values?.projectdetails_excel) {
      let projectDetailsBase64 = await getBase64(
        values?.projectdetails_excel?.[0]?.originFileObj
      );
      let UpdatedProjectdetailExcel = {
        ...values.projectdetails_excel[0],
        thumbUrl: projectDetailsBase64,
      };
      var UpdatedProjectdetailsExcelDoc = [
        UpdatedProjectdetailExcel,
        ...values.projectdetails_excel.slice(1),
      ];
    }
    const data = {
      projectdetails_excel: UpdatedProjectdetailsExcelDoc,
      orgID: orgId,
      project_id:props.projectId
    };
    await bulkProjectDetail(data);
    getAllProjDetails(props.projectId,orgId,userId)
    form.setFieldsValue({
      projectdetails_excel: [],
    });
  };
  const onFinishFailed = (error) => {
    console.log(error);
  };

  const normFileForprojectdetailsDocExcel = (e) => {
    if (e && e.fileList.length > 0) {
      setSelectedFile({ ...selectedFile, projectdetails_excel: true });
    } else {
      setSelectedFile({ ...selectedFile, projectdetails_excel: false });
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const downloadSampleFile = () => {
    const fileName = "projectDetailsSample.xlsx";
    const data = [
      {
       // ProjectId: "7",
        Description: "",
      },
    ];
    const ws = XLSX?.utils?.json_to_sheet(data);
    const wb = XLSX?.utils?.book_new();
    XLSX?.utils?.book_append_sheet(wb, ws, "test");
    XLSX?.writeFile(wb, fileName);
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        name="project-details-bulk"
        size="large"
        initialValues={{
          remember: true,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Form.Item
          style={{ textAlign: "center",paddingBottom:20 }}
          name="projectdetails_excel"
          label="Upload Project Detail"
          valuePropName="fileList"
          getValueFromEvent={normFileForprojectdetailsDocExcel}
          rules={[
            {
              required: true,
              message: "Please Upload Excel File !",
            },
          ]}
          help="Upload only .xlsx, .xls files"
        >
          <Upload
            name="doc"
            listType="projectdetails_excel"
            accept=".xlsx,.xls"
            beforeUpload={(file, fileList) => {
              return false;
            }}
            maxCount={"1"}
            
          >
            <Button icon={<UploadOutlined />}>Choose File</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Upload</strong>
            </Button>

            <Button
              type="primary"
              className="btn-submit"
              onClick={downloadSampleFile}
            >
              <strong>Sample</strong>
            </Button>
          </div>
        </Form.Item>
        </div>
      </Form>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default UploadProjectDetailsZipForm;
