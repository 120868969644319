import "./css/ShowProjectDetails.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Input,
  notification,
  Image,
  Modal,
  Upload,
  Select,
  Form,
  message,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../redux-core/settings/selectors";
import {
  countAction,
  deleteProjDetailsById,
  getAllProjDetails,
  updateProjDetailsById,
} from "../../../redux-core/settings/actions";
import { redirectAction } from "../../../redux-core/login/actions";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";
import { PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import AddProjectDetailsForm from "./Forms/AddProjectDetailsForm";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  delProjDetailsById: (id) => dispatch(deleteProjDetailsById(id)),
  getAllProjDetails: (projectId, orgId, userId) =>
    dispatch(getAllProjDetails(projectId, orgId, userId)),
  updateProjDetails: (newProjDetails) =>
    dispatch(updateProjDetailsById(newProjDetails)),
  count: (orgId) => dispatch(countAction(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const ShowProjectDetails = (props) => {
  const getColumnSearchProps = useSearch();
  const [projRow, setProjRow] = useState({});
  const [selectedKeys, setSelectedKeys] = useState();
  const [imagecard, setImagecard] = useState({
    image_1: false,
    // image_2: false,
    // image_3: false,
    // image_4: false,
    // image_5: false,
  });
  const [img1, setImg1] = useState(false);
  // const [img2, setImg2] = useState(false);
  // const [img3, setImg3] = useState(false);
  // const [img4, setImg4] = useState(false);
  // const [img5, setImg5] = useState(false);
  const [activedata, setActivedata] = useState(true);

  const { delProjDetailsById, getAllProjDetails, updateProjDetails, count } =
    actionDispatch(useDispatch());
  const [formProjectDetails] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const children = [];
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const allProjDetailsSelectorData = useSelector(makeSelectGetAllProjDetails);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const Spindata = useSelector((item) => item.spinreducer);

  useEffect(() => {
    getAllProjDetails(
      props.projectID,
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  }, [props.projectID, logindetails.organisation.orgID, logindetails.user.id]);

  // edit function
  const onProjectDetailFinish = async (e) => {
    e.preventDefault();
    // projRow.project_id = values.project_id;
    // projRow.description = values.block;
    projRow.orgID = logindetails.organisation.orgID;

    // if (values.image_1 && values.image_1.length < 1) delete projRow.image_1;
    // else
    //   projRow.image_1 = values.image_1[0].size
    //     ? values.image_1
    //     : values.image_1[0].thumbUrl;

    console.log("Project roiw: ", projRow);
    // return;
    await setProjRow(projRow);
    await updateProjDetails(projRow);
    await setIsModalVisible(false);
    await getAllProjDetails(
      props.projectID,
      logindetails.organisation.orgID,
      logindetails.user.id
    );
  };

  const onProjectDetailFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_ProjectDetails_field = async () => {
    formProjectDetails.setFieldsValue({
      project_id: projRow.project_id,
      block: projRow.description,
      image_1: projRow.image.image_1
        ? [{ thumbUrl: projRow.image.image_1 }]
        : [],
      // image_2: projRow.image.image_2
      //   ? [{ thumbUrl: projRow.image.image_2 }]
      //   : [],
      // image_3: projRow.image.image_3
      //   ? [{ thumbUrl: projRow.image.image_3 }]
      //   : [],
      // image_4: projRow.image.image_4
      //   ? [{ thumbUrl: projRow.image.image_4 }]
      //   : [],
      // image_5: projRow.image.image_5
      //   ? [{ thumbUrl: projRow.image.image_5 }]
      //   : [],
    });
    {
      projRow.image.image_1 == null ||
      projRow.image.image_1 == undefined ||
      projRow.image.image_1 == ""
        ? setImagecard((prevstate) => ({ ...prevstate, image_1: false }))
        : setImagecard((prevstate) => ({ ...prevstate, image_1: true }));
    }
    // {
    //   projRow.image.image_2 == null ||
    //   projRow.image.image_2 == undefined ||
    //   projRow.image.image_2 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_2: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_2: true }));
    // }
    // {
    //   projRow.image.image_3 == null ||
    //   projRow.image.image_3 == undefined ||
    //   projRow.image.image_3 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_3: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_3: true }));
    // }
    // {
    //   projRow.image.image_4 == null ||
    //   projRow.image.image_4 == undefined ||
    //   projRow.image.image_4 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_4: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_4: true }));
    // }
    // {
    //   projRow.image.image_5 == null ||
    //   projRow.image.image_5 == undefined ||
    //   projRow.image.image_5 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_5: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_5: true }));
    // }
  };

  const normFileForImg1 = (e) => {
    if (e.fileList.length > 0)
      setImagecard((prevstate) => ({ ...prevstate, image_1: true }));
    else setImagecard((prevstate) => ({ ...prevstate, image_1: false }));
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  // const normFileForImg2 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_2: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_2: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg3 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_3: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_3: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg4 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_4: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_4: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const normFileForImg5 = (e) => {
  //   if (e.fileList.length > 0)
  //     setImagecard((prevstate) => ({ ...prevstate, image_5: true }));
  //   else setImagecard((prevstate) => ({ ...prevstate, image_5: false }));
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  const showModal = (data) => {
    setProjRow(data);
    setIsModalVisible(true);
    formProjectDetails.setFieldsValue({
      project_id: data.project_id,
      block: data.description,
      image_1: data.image.image_1 ? [{ thumbUrl: data.image.image_1 }] : [],
      // image_2: data.image.image_2 ? [{ thumbUrl: data.image.image_2 }] : [],
      // image_3: data.image.image_3 ? [{ thumbUrl: data.image.image_3 }] : [],
      // image_4: data.image.image_4 ? [{ thumbUrl: data.image.image_4 }] : [],
      // image_5: data.image.image_5 ? [{ thumbUrl: data.image.image_5 }] : [],
    });
    {
      data.image.image_1 == null ||
      data.image.image_1 == undefined ||
      data.image.image_1 == ""
        ? setImagecard((prevstate) => ({ ...prevstate, image_1: false }))
        : setImagecard((prevstate) => ({ ...prevstate, image_1: true }));
    }
    // {
    //   data.image.image_2 == null ||
    //   data.image.image_2 == undefined ||
    //   data.image.image_2 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_2: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_2: true }));
    // }
    // {
    //   data.image.image_3 == null ||
    //   data.image.image_3 == undefined ||
    //   data.image.image_3 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_3: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_3: true }));
    // }
    // {
    //   data.image.image_4 == null ||
    //   data.image.image_4 == undefined ||
    //   data.image.image_4 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_4: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_4: true }));
    // }
    // {
    //   data.image.image_5 == null ||
    //   data.image.image_5 == undefined ||
    //   data.image.image_5 == ""
    //     ? setImagecard((prevstate) => ({ ...prevstate, image_5: false }))
    //     : setImagecard((prevstate) => ({ ...prevstate, image_5: true }));
    // }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
      width: "40%",
    },
    // {
    //   title: "Project Details ID",
    //   dataIndex: "project_details_id",
    //   ...getColumnSearchProps("project_details_id"),
    //   width:"10%",
    // },
    // {
    //   title: "Project ID",
    //   dataIndex: "project_id",
    //   ...getColumnSearchProps("project_id"),
    //   width:"10%",
    // },
    // {
    //   title: "Project Name",
    //   dataIndex: "project_name",
    //   ...getColumnSearchProps("project_name"),
    //   width: "30%",
    // },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      width: "50%",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (image) => {
        return (
          <>
            <Image
              src={image.image_1}
              style={{ width: "20px", height: "20px" }}
            />
            {/* <Image
              src={image.image_2}
              style={{ width: "20px", height: "20px" }}
            />
            <Image
              src={image.image_3}
              style={{ width: "20px", height: "20px" }}
            />
            <Image
              src={image.image_4}
              style={{ width: "20px", height: "20px" }}
            />
            <Image
              src={image.image_5}
              style={{ width: "20px", height: "20px" }}
            /> */}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const deleteRow = (record) => {
    ShowDeleteConfirm({
      onConfirm: () => onConfirm([record.project_details_id]),
    });
  };
  const onConfirm = async (key) => {
    await delProjDetailsById(key);
    await getAllProjDetails(
      props.projectID,
      logindetails.organisation.orgID,
      logindetails.user.id
    );
    await count(logindetails.organisation.orgID);
  };
  const deleteSelected = () => {
    if (selectedKeys === undefined)
      notification.open({
        message: "Select items to delete.",
      });
    else if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  for (let i = 0; i < allProjectsSelectorData.length; i++) {
    children.push(
      <Option value={allProjectsSelectorData[i].project_id} key={i}>
        {allProjectsSelectorData[i].project_name}-
        {allProjectsSelectorData[i].project_id}
      </Option>
    );
  }

  const data = [];
  for (let i = 0; i < allProjDetailsSelectorData.length; i++) {
    data.push({
      sl_no: i + 1,
      // key: allProjDetailsSelectorData[i].project_details_id,
      project_id: allProjDetailsSelectorData[i].project_id,
      project_details_id: allProjDetailsSelectorData[i].project_details_id,
      project_name: allProjDetailsSelectorData[i].project_name,
      // project_name_Id: allProjDetailsSelectorData[i].project_name + "-" + allProjDetailsSelectorData[i].project_id,
      description: allProjDetailsSelectorData[i].block,
      image: {
        image_1: allProjDetailsSelectorData[i].image_1,
        // image_2: allProjDetailsSelectorData[i].image_2,
        // image_3: allProjDetailsSelectorData[i].image_3,
        // image_4: allProjDetailsSelectorData[i].image_4,
        // image_5: allProjDetailsSelectorData[i].image_5,
      },
    });
  }
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );

  const handleCancelmodal = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  useEffect(() => {
    console.log("children data: ", data);
  }, [data]);
  const [imageFile, setImageFile] = useState([]);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"default"}>
        <div>
          <div className="font-semibold  text-2xl font-montserrat">
            List Of Project Location
          </div>
          <br />
          <div className="  font-montserrat flex justify-between">
            {/* <Button className="pdf"> Export to PDF</Button> */}

            <CSVLink
              filename={"Project_Details_Table.csv"}
              data={data}
              className=" ml-28"
              onClick={() => {
                toast.success("The file is downloading..");
                // message.success("The file is downloading");
              }}
            >
              <button
                // onClick={() => setShowAddProject(true)}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Export to Excel
              </button>
            </CSVLink>

            {/* <Button className="excel">
            <CSVLink
              filename={"Project_Details_Table.csv"}
              data={data}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button> */}
            {/* <Button type="primary" 
            style={{backgroundColor:"teal", borderRadius:"5px"}}
            onClick={() => window.print()}>
              {" "}
              <PrinterOutlined />
              Print
            </Button> */}
            {/* <Button className="print">Print</Button>
                <Button className="copy">Copy</Button> */}
            <button
              onClick={() => setShowAddLocationModal(true)}
              type="button"
              class="flex  justify-evenly mr-28 items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              <span>
                <IoIosAdd size={20} />
              </span>
              Add Location
            </button>
          </div>
          {/* <Table
          className="table"
          scroll={{ x: "20vw" }}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={10}
          rowKey="project_details_id"
        /> */}

          {/* new table shivam kashyap 24 may 2024  */}
          <div className=" font-montserrat mt-5  ml-4    flex flex-col items-center  justify-center -green-300">
            <ul role="list" className="divide-y w-[1200px] divide-[#F5F5F5]">
              <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
                {/* Header Content */}
                <div className="flex flex-col items-start -yellow-200 w-[200px]">
                  <p className="text-sm font-semibold text-gray-900">Sl no.</p>
                </div>

                <div className="flex flex-col blue-200 items-start w-[300px]">
                  <p className="text-sm font-semibold text-gray-900">
                    {" "}
                    Description
                  </p>
                </div>
                <div className="flex flex-col blue-200 items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900">Image</p>
                </div>
                <div className="flex flex-col blue-200 items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900">Action</p>
                </div>
              </li>
            </ul>
            <ul
              role="list"
              className="divide-y w-[1200px]  divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[62vh] "
            >
              {data && data.length
                ? data.map((item, key) => (
                    <li
                      className={`flex justify-between ${
                        key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }  items-start px-5  py-2`}
                    >
                      <div className="flex flex-col items-start -yellow-200 w-[200px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.sl_no ? item.sl_no : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col blue-200 items-start w-[300px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {" "}
                          {item?.description ? item.description : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col blue-200 items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900">
                          {item?.image ? (
                            <img
                              src={item?.image.image_1}
                              className="block mx-auto my-auto w-[50px] h-[50px]"
                            />
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      <div className="flex  gap-x-2 blue-200 items-start w-[150px]">
                        <a
                          // onClick={() => onBillingWorkOrder(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Edit"
                        >
                          <MdEdit
                            className=" text-top_nav_blue-300 "
                            size={18}
                            onClick={() => showModal(item)}
                          />
                          {/* <RiBillLine
                         
                        /> */}
                        </a>{" "}
                        <a
                          onClick={() => deleteRow(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="Delete"
                        >
                          <MdDelete
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={() => deleteRow(item)}
                          />
                          {/* <RiBillLine
                         
                        /> */}
                        </a>{" "}
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>
          <br />
          {/* onOk={handleOk} */}
          <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={handleCancel}
            width={1400}
            centered={true}
          >
            <div className=" font-semibold  text-2xl font-montserrat">
              Edit Project Location
            </div>
            <br />
            {/* <Form
              form={formProjectDetails}
              name="project-details"
              size="large"
              initialValues={{
                remember: true,
              }}
              onFinish={onProjectDetailFinish}
              onFinishFailed={onProjectDetailFinishFailed}
              //autoComplete="off"
            >
              <Form.Item
                name="project_id"
                label="Select Project"
                className="input-field"
                rules={[
                  { required: true, message: "Please select one project" },
                ]}
              >
                <Select placeholder="Select Project" allowClear>
                  {children}
                </Select>
              </Form.Item>

              <Form.Item
                className="input-field-showLevel1"
                label="Description"
                name="block"
                rules={[
                  {
                    required: true,
                    message: "Please enter the description",
                  },
                  {
                    pattern: /^(.{0,300})$/,
                    message: "please enter less than 300 characters only",
                  },
                ]}
              >
                <Input
                  placeholder="Ex: Level1:foundation, Level2:Slab"
                  onKeyDown={(e) => onKeydown(e)}
                />
              </Form.Item>

              <Form.Item
                name="image_1"
                label="Upload"
                valuePropName="fileList"
                getValueFromEvent={normFileForImg1}
                className="input-field-images"
              >
                <Upload
                  name="logo"
                  listType="picture-card"
                  accept=".jpg,.jpeg,.png"
                  onPreview={handlePreview}
                  beforeUpload={(file, fileList) => {
                    return false;
                  }}
                >
                  {!imagecard.image_1 && uploadButton}
                </Upload>
              </Form.Item>

              <Form.Item>
                <div className="btn-project">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-submit"
                    style={{ borderRadius: "10px" }}
                  >
                    <strong>Submit</strong>
                  </Button>

                  <Button
                    onClick={clear_ProjectDetails_field}
                    className="btn-cancel"
                  >
                    <strong>Reset</strong>
                  </Button>
                </div>
              </Form.Item>
            </Form> */}

            {/* new form shivam kashyap  */}
            <form
              className="font-montserrat flex flex-col items-center "
              onSubmit={onProjectDetailFinish}
            >
              {/* 1a */}
              <div className="flex justify-evenly w-[90vw]">
                {/* 1 */}
                <div className="mt w-[300px]">
                  <label
                    htmlFor="project_id"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Select Project
                  </label>
                  <select
                    required
                    name="project_id"
                    // value={formData.project_id}
                    // onChange={handleInputChange}
                    // onChange={(projectId) => handelProjectId(projectId)}
                    onChange={(e) => {
                      // console.log("setting values");
                      setProjRow({ ...projRow, project_id: e.target.value });
                    }}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  >
                    <option disabled value="">
                      Select
                    </option>

                    {children &&
                      children.map((item) => (
                        <option
                          key={item.props.children[2]}
                          value={item.props.children[2]}
                        >
                          {item.props.children[0] +
                            item.props.children[1] +
                            item.props.children[2]}
                        </option>
                      ))}

                    {/* {allProjects.map((getAllProj) => (
                  <option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </option>
                ))} */}
                  </select>
                </div>
                {/* 2 description  */}
                <div className="">
                  <label
                    htmlFor="problem_area"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Description
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      required
                      type="text"
                      name="problem_area"
                      value={projRow.description}
                      onChange={(e) => {
                        setProjRow({ ...projRow, description: e.target.value });
                      }}
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>{" "}
                {/* 3  */}
                <div className="w-[300px] ">
                  <label
                    htmlFor="image"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {/* <span className="text-red-600">*</span> */}
                    Upload
                  </label>
                  <input
                    // required
                    class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    id="image"
                    type="file"
                    accept="image/svg+xml, image/png, image/jpeg, image/gif"
                    onChange={async (event) => {
                      const fileInput = event.target;
                      const file = fileInput.files[0];

                      if (file) {
                        setImageFile(file);
                        // console.log("filse set: ", imageFile);
                        let imageBase64String = "";
                        if (imageFile) {
                          try {
                            imageBase64String = await getBase64(file);
                          } catch (error) {
                            console.error(
                              "Error converting image to base64:",
                              error
                            );
                            toast.error(
                              "An error occurred while processing the image. Please try again."
                            );
                            return;
                          }
                        }

                        const imageObject = {
                          lastModified: file.lastModified,
                          lastModifiedDate: file.lastModifiedDate,
                          name: file.name,
                          originFileObj: file,
                          percent: 0, // Set percent as needed
                          size: file.size,
                          thumbUrl: imageBase64String, // Set thumbUrl as needed
                          type: file.type,
                          uid: "rc-upload-" + Date.now(), // Generate unique uid
                        };
                        imageObject.thumbUrl = imageObject.thumbUrl.replace(
                          "data:image/jpeg;",
                          "data:image/png;"
                        );

                        setProjRow({
                          ...projRow,
                          image_1: [imageObject],
                        });
                      }
                    }}
                  />
                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    SVG, PNG, JPG or GIF .
                  </p>
                </div>
              </div>
              {/* buttons  */}
              <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
                <button
                  // onClick={resetAllValue}
                  type="button"
                  class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  data-te-ripple-init
                >
                  Reset
                </button>
                <button
                  type="submit"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal>

          {/* {data.length > 0 && (
          <Button
            type="primary"
            style={{
              display: activedata ? "" : "none",
              borderRadius: "5px",
              backgroundColor: "teal",
            }}
            className="deleteallbutton_copy"
            onClick={deleteSelected}
          >
            Delete All Selected
          </Button>
        )} */}
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={handleCancelmodal}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          <Modal
            visible={showAddLocationModal}
            footer={null}
            onCancel={() => setShowAddLocationModal(false)}
            width={1000}
          >
            <AddProjectDetailsForm
              projectId={props.projectID}
              setShowAddLocationModal={setShowAddLocationModal}
            />
          </Modal>
        </div>
      </Spin>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ShowProjectDetails);
