import React from "react";

const UserManual = () => {
  return (
    <div class="absolute  top-1/2 left-1/2 bg-pink-500  justify-center items-center">
      <div class="bg-pink-300 w-500 h-500">i am user manual</div>
    </div>
  );
};

export default UserManual;
