import React from "react";

const ButtonPrimary = (props) => {
  const { data, label } = props;
  return (
    <button
      onClick={data}
      type="button"
      class="mb-2 font-montserrat max-w-[200px] block w-full rounded bg-blue-900 px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white"
    >
      {label}
    </button>
  );
};

export default ButtonPrimary;
