import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAssignProjectId } from '../../../redux-core/userManagement/actions';
import { getAllProj, getAllProjDetails } from '../../../redux-core/settings/actions';
import { selectorGetAllUserWiseAssignProjects } from '../../../redux-core/userManagement/selectors';
import { makeSelectAllProj, makeSelectGetAllProjDetails } from '../../../redux-core/settings/selectors';
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';

const ProjectList = ({ onNavigate }) => {
  const dispatch = useDispatch();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const userRole = logindetails.user.role;

  const projects = useSelector(userRole === 'ADMIN' ? makeSelectAllProj : selectorGetAllUserWiseAssignProjects);
  const projectDetails = useSelector(makeSelectGetAllProjDetails);

  useEffect(() => {
    if (userRole === 'ADMIN') {
      dispatch(getAllProj(orgId, userId));
    } else {
      dispatch(getAllAssignProjectId(orgId, userId));
    }
  }, [dispatch, orgId, userId, userRole]);

  useEffect(() => {
    if (projects && projects.length > 0) {
      projects.forEach(project => {
        dispatch(getAllProjDetails(project.project_id, orgId, userId));
      });
    }
  }, [dispatch, projects, orgId, userId]);

  const handleProjectClick = (projectId) => {
    onNavigate('DashboardImages', projectId);
  };

  const findProjectDetails = (projectId) => {
    return projectDetails.find(details => details.project_id === projectId);
  };

  return (
    <div>
      {projects.length > 0 ? (
        <>
          <h1 style={{ textAlign: 'center', marginTop: '20px', fontWeight: 'bold', fontSize: '2em' }}>Projects</h1>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {projects.map((project) => {
              const details = findProjectDetails(project.project_id);
              return (
                <li key={project.project_id} onClick={() => handleProjectClick(project.project_id)} style={{ cursor: 'pointer', margin: '10px 0', padding: '5px', backgroundColor: '#f1f1f1', borderRadius: '5px' }}>
                  {details ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h3 style={{ marginRight: '20px' }}>{details.project_name}</h3>
                      <p style={{ marginRight: '20px' }}><strong>Project Id:</strong> {details.project_id}</p>
                      <p style={{ marginRight: '20px' }}><strong>Location:</strong> {details.location_with_ID}</p>
                      <p style={{ marginRight: '20px' }}><strong>Start Date:</strong> {details.project_start_date}</p>
                      <p style={{ marginRight: '20px' }}><strong>End Date:</strong> {details.project_end_date}</p>
                      <img src={details.image} alt="Project Image" style={{ width: '50px', height: 'auto', borderRadius: '5px', marginRight: '20px' }} />
                    </div>
                  ) : (
                    <p>Loading details...</p>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p>Loading projects...</p>
      )}
    </div>
  );
};

export default ProjectList;
