import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload, Space, DatePicker } from "antd";
import "./css/DeploymentsummaryForm.css";
import DeploymentSummaryTable from "./DeploymentSummaryTable";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../../redux-core/settings/selectors";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllSummaryHeader,
  getAllIdSummaryHeader,
} from "../../../../redux-core/resources/actions";
import {
  selectorgetAllDeploymentHeaderState,
  selectorgetAllIdDeploymentHeaderState,
} from "../../../../redux-core/resources/selectors";
import moment from "moment";
import { getAllAssignProjectId } from "../../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../../redux-core/userManagement/selectors";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getAllProject: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjectDetail: (projID, orgId, userId) =>
    dispatch(getAllProjDetails(projID, orgId, userId)),
  AllSummaryHeader: (orgId, userId) =>
    dispatch(getAllSummaryHeader(orgId, userId)),
  getAllIdSummaryHeader: (data) => dispatch(getAllIdSummaryHeader(data)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
});

const DeploymentSummary = () => {
  const {
    getAllProject,
    getAllProjectDetail,
    AllSummaryHeader,
    getAllIdSummaryHeader,
    getAllAssignProjectId,
  } = actionDispatch(useDispatch());
  const allDeploymentHeader = useSelector(selectorgetAllDeploymentHeaderState);
  const allProjects = useSelector(makeSelectAllProj);
  const allIdDeploymentHeader = useSelector(
    selectorgetAllIdDeploymentHeaderState
  );
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [form] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectData, setSelectData] = useState();
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );

  useEffect(() => {
    getAllProject(orgId, userId);
    getAllAssignProjectId(orgId, userId);

    AllSummaryHeader(orgId, userId);
    //    getAllIdSummaryHeader(orgId, userId,logindetails.organisation.project_id,logindetails.organisation.location,logindetails.organisation.create_date,logindetails.organisation.progress_status);
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });

  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjectDetail(projectId, orgId, userId);
  };

  //console.log("Data------",allDeploymentHeader)

  const onAddWorkCategoryFinish = async (values) => {
   
    setShowTable(true);
    const Data = {
      orgID: orgId,
      created_by: userId,
      project_id: values.project_id,
      location: values.location,
      create_date: moment(values.create_date).format("YYYY-MM-DD"),
      progress_status: values.progress_status,
    };
    let response = await getAllIdSummaryHeader(Data);
    console.log("Value",response)
    console.log("Data", Data)
    console.log("values.create_date", values.create_date)
    //  console.log(allIdDeploymentHeader)

    setSelectData([values]);
  };
  const onAddWorkCategoryFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // clear Fields

  const clear_fields_Deployment = () => {
    form.setFieldsValue({
      project_name: "",
      project_location: "",
      project_status: "",
      current_date: "",
    });
  };
  const options = [
    { value: "Created" },
    { value: "Work In Progress" },
    { value: "Completed" },
  ];
  console.log("value")
  const onChanges=(value)=>{
    const formattedDate = moment(value).format("YYYY-MM-DD");
    console.log("onChanges", formattedDate);
  }
 // const [value, onChanges] = useState(new Date());
  return (
    <>
      <Form
        form={form}
        name="add-labourDeployment"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddWorkCategoryFinish}
        onFinishFailed={onAddWorkCategoryFinishFailed}
      >
        {logindetails.user.role === "ADMIN" ? (
          <Form.Item
            name="project_id"
            label="Project Name"
            rules={[{ required: true, message: "Please select Project!" }]}
            className="input-field"
          >
            {/* <select className='form-control' onChange={handleCountry}>
                        <option>Select Project</option>

                        {countries.map(ctr => (
                            <option value={ctr.name}>{ctr.name}</option>
                        ))}
                    </select> */}
            <Select
              // mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Project Name"
              //onChange={projectId => setSelectedProjectId(projectId)}
              onChange={(projectId) => handelProjectId(projectId)}
            >
              {allProjects.map((getAllProject) => (
                <option
                  key={getAllProject.project_id}
                  value={getAllProject.project_id}
                >
                  {getAllProject.project_name}
                </option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            name="project_id"
            label="Project Name"
            rules={[{ required: true, message: "Please select Project!" }]}
            className="input-field"
          >
            {/* <select className='form-control' onChange={handleCountry}>
                        <option>Select Project</option>

                        {countries.map(ctr => (
                            <option value={ctr.name}>{ctr.name}</option>
                        ))}
                    </select> */}
            <Select
              // mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select Project Name"
              //onChange={projectId => setSelectedProjectId(projectId)}
              onChange={(projectId) => handelProjectId(projectId)}
            >
              {projectData.map((getAllProject) => (
                <option
                  key={getAllProject.project_id}
                  value={getAllProject.project_id}
                >
                  {getAllProject.project_name}
                </option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="location"
          label="Project  Location"
          rules={[
            { required: true, message: "Please select Project Loaction!" },
          ]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Project Location"
          >
            {allProjDetails
              .filter((detail) => detail.project_id === selectedProjectId)
              .map((detail) => (
                <Option key={detail.project_details_id} value={detail.block}>
                  {detail.block}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="progress_status"
          label="Project Status"
          rules={[{ required: true, message: "Please select Project Status!" }]}
          className="input-field"
        >
          <Select
            placeholder="Select Status"
            value={options.value}
            options={options}
            defaultValue={options[""]}
          ></Select>
        </Form.Item>

        <Form.Item
          name="create_date"
          label="Current Date"
          className="input-field"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <DatePicker
          format= "DD-MM-YYYY"
            onChange={onChanges}
           
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item>
          <div className="btn-deployment">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>

            <Button
              onClick={clear_fields_Deployment}
              className="btn-cancel"
              style={{ borderRadius: "10px" }}
            >
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
      {showTable && <DeploymentSummaryTable data={selectData} />}
    </>
  );
};
export default DeploymentSummary;
