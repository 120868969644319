import "./css/ActivityCreate.css";
import "antd/dist/antd.min.css";
import React from "react";
import ActivityForm from "./Forms/ActivityForm";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import UploadActivityExcelForm from "./Forms/UploadActivityExcelForm";
function ActivityCreate({ setshowCreateActivityModal }) {
  const Spindata = useSelector((item) => item.spinreducer);

  // ------------------------------ADD PROJECT---------------------------------------
  // const [form] = Form.useForm();

  // const onUploadActivities = (values) => {
  //     console.log('Success:', values);
  // };

  // const onUploadActivitiesFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className=" bg-white w-[1100px] ">
          {/* -------------------------ADD ACTIVITY-------------------------------- */}
          <div className="activity ]">
            <div className="font-semibold  text-2xl">Activity Create</div>
            <br />
            <ActivityForm
              setshowCreateActivityModal={setshowCreateActivityModal}
              className=" "
            />
          </div>
          <br></br>

          {/* commented on 10 may 2024 - shivam kashyap  */}
          {/* <div className="upload-file activity">
            <div className="font-semibold  text-2xl">Upload Activities From Excel File</div>
            <br />
            <UploadActivityExcelForm />
          </div> */}

          {/* --------------------UPLOAD EXCEL FILE---------------------------------------
        <div className="master-task">

            <div className="sub-title">Upload Activities From Excel File</div>

            <Form
            form={form}
            name="upload_activity"
            size="large"
            initialValues={{
                remember: true,
            }}
            onFinish={onUploadActivities}
            onFinishFailed={onUploadActivitiesFailed}
            //autoComplete="off"
            >

                <Form.Item
                    name="file"
                    label="Upload Activities"
                    className="input-field"                   
                    rules={[
                    {
                        required: true,
                        message: 'Please select the file',
                    },
                    ]}
                >
                    <Upload name="file" >
                        <Button icon={<UploadOutlined />} >Choose file</Button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <div className="btn-project">
                        <Button type="primary" htmlType="submit" className="btn-submit">
                        Upload
                        </Button>

                        <Button onClick={clear_field} className="btn-cancel">Sample</Button>
                    </div>
                </Form.Item>
            </Form>
        </div> */}
        </div>
      </Spin>
    </>
  );
}

export default React.memo(ActivityCreate);
