import React, { useState } from "react";
import {
  FundProjectionScreenOutlined,
  LikeOutlined,
  FileAddOutlined,
  InboxOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import "./css/Dashboard.css";
import { useSelector } from "react-redux";
import { selectorRiskCount } from "../../../redux-core/risk/selectors";
import { Button } from "antd";
import EhsShow from "./EhsShow";

function Dashboard() {
  const countData = useSelector(selectorRiskCount);
  //console.log(countData);
  const [hideDashboard, setHideDashboard] = useState(true);
  const [hideBackbutton, setHideBackbutton] = useState(false);
  const [showObservation, setShowObservation] = useState(false);

  const handelClickObservation = () => {
    setHideDashboard(false);
    setHideBackbutton(true);
    setShowObservation(true);
  };

  const handelBack = () => {
    setHideDashboard(true);
    setHideBackbutton(false);
    setShowObservation(false);
  };

  return (
    <>
      {/* {countData.length>0 && countData[1]?.no_of_ehs} */}
      {hideBackbutton && (
        <Button
          type="primary"
          style={{ borderRadius: "10px", width: "7%" }}
          onClick={() => handelBack()}
        >
          Back
        </Button>
      )}
      {hideDashboard && (
        <div className="dashboard-root1">
          <div className="sub-main" onClick={() => handelClickObservation()}>
            <div className="top">
              <span className="left__value">
                {countData.length > 0 && countData[1]?.no_of_ehs}
              </span>
              <span className="right__icon">
                <FundProjectionScreenOutlined />
              </span>
            </div>
            <div className="bottom__title">No Of Observation</div>
          </div>

          {/* {countData.length>0 && countData[0]?.no_of_critical_issue} */}
          {/* <div className="sub-main">
                    <div className="top">
                        <span className="left__value">{countData.length>0 && countData[0]?.no_of_critical_issue}</span>
                        <span className="right__icon"><LikeOutlined /></span>
                    </div>
                    <div className="bottom__title">No of Critical Issue</div>
                </div> */}
        </div>
      )}
      {showObservation && <EhsShow />}
    </>
  );
}
export default React.memo(Dashboard);
