import "./css/AddWorkCategory.css";
import "antd/dist/antd.min.css";
import React from "react";
import DeploymentsummaryForm from "./Forms/DeploymentsummaryForm";

function Deploymenetsummary() {
  return (
    <>
      <div className="work-category">
        <div className="sub-title">Labour Deployment Summary</div>
        <br />
        <DeploymentsummaryForm />
      </div>
    </>
  );
}
export default React.memo(Deploymenetsummary);
