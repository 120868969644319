import React, { useEffect, useState } from "react";
import { Form, Button, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectAllProj,
  selectorUploadMmpFile,
} from "../../../redux-core/settings/selectors";
import {
  createUploadMmpFile,
  getAllProj,
} from "../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowUploadMms } from "../../popupmodal";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  UploadMmpFile: (uploadMmp) => dispatch(createUploadMmpFile(uploadMmp)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
});

const UploadMsProject = ({
  showUploadScheduleModal,
  setShowUploadScheduleModal,
}) => {
  const { UploadMmpFile, getAllProj, getAllAssignProjectId } = actionDispatch(
    useDispatch()
  );
  const children = [];
  const children2 = [];
  const [form] = Form.useForm();
  // const [projectData, setProjectData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(false);
  const allProjSelectorData = useSelector(makeSelectAllProj);
  const createUploadMmp = useSelector(selectorUploadMmpFile);
  const { Option } = Select;
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const [file, setFile] = useState(null);
  const [project, setProject] = useState(null);

  for (let i = 0; i < allProjSelectorData.length; i++) {
    children.push(
      <Option value={allProjSelectorData[i].project_id} key={i}>
        {allProjSelectorData[i].project_name}
        {allProjSelectorData[i].project_id}
      </Option>
    );
  }

  useEffect(() => {
    getAllProj(orgId, userId);
    getAllAssignProjectId(orgId, userId);
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjSelectorData.filter(
      (items) => items.project_id == item.Project_id
    );
  });

  for (let i = 0; i < projectData.length; i++) {
    children2.push(
      <Option value={projectData[i].project_id} key={i}>
        {projectData[i].project_name}
        {projectData[i].project_id}
      </Option>
    );
  }
  // const onFinish = async (values) => {

  //   for(let i =1; i<projectData.length; i++){
  //     let obj = projectData[i];
  //      const objData = {
  //       project_id: values.Project_id,
  //       task_name:obj.Name,
  //       duration: obj.Duration,
  //       start: obj.Start,
  //       finish: obj.Finish,
  //       predecessors: obj.Predecessors,
  //       successors: obj.Outline_Level,
  //       resources_name: obj.Notes,
  //       orgID: orgId,
  //      }
  //     // await UploadMmpFile(objData)
  //   }
  // };

  const onFinish = async (e) => {
    e.preventDefault();
    // let d = {};
    // d.uploadproject_ms = file;
    // console.log("Btn values:", values);
    let file = excelFile;
    excelFile.uid = "rc-upload-" + Date.now();

    // const ofo = {
    //   lastModified: excelFile.lastModified,
    //   lastModifiedDate: excelFile.lastModifiedDate,
    //   name: excelFile.name,
    //   originFileObj: { ...excelFile, uid: "rc-upload-" + Date.now() },
    //   percent: 0,
    //   size: excelFile.size,
    //   type: excelFile.type,
    //   uid: "rc-upload-" + Date.now(), // Generate unique uid
    // };
    const ofo = {
      lastModified: excelFile.lastModified,
      lastModifiedDate: excelFile.lastModifiedDate,
      name: excelFile.name,
      originFileObj: excelFile,
      percent: 0,
      size: excelFile.size,
      type: excelFile.type,
      uid: "rc-upload-" + Date.now(), // Generate unique uid
    };

    // ofo.originFileObj={ofo.originFileObj , uid: "rc-upload-" + Date.now() }
    console.log("file values:", ofo);

    // console.log("File values:", d);
    // return;
    // if (values?.uploadproject_ms) {
    //   let uploadMmpFileBase64 = await getBase64(
    //     values?.uploadproject_ms?.[0]?.originFileObj
    //   );
    //   let UpdatedUploadmmpfileExcel = {
    //     ...values.uploadproject_ms[0],
    //     thumbUrl: uploadMmpFileBase64,
    //   };
    //   var UpdatedUploadMmpExcelDoc = [
    //     UpdatedUploadmmpfileExcel,
    //     ...values.uploadproject_ms.slice(1),
    //   ];
    // }

    if (ofo) {
      let uploadMmpFileBase64n = await getBase64(ofo.originFileObj);
      var UpdatedUploadmmpfileExceln = {
        ...ofo,
        thumbUrl: uploadMmpFileBase64n,
      };
      // var UpdatedUploadMmpExcelDocn = [
      //   UpdatedUploadmmpfileExceln,
      //   ...values.uploadproject_ms.slice(1),
      // ];
    }

    // console.log("new values:", [UpdatedUploadmmpfileExceln]);
    // console.log("old ui values:", UpdatedUploadMmpExcelDoc);

    // return;
    const data = {
      Project_id: proj,
      uploadproject_ms: [UpdatedUploadmmpfileExceln],
      orgID: orgId,
    };

    console.log("final data values:", data);
    // return;
    let res = await UploadMmpFile(data);

    // 
    if (res.success) {
      setShowUploadScheduleModal(false);
      toast.success("File Uploaded...");
      console.log("Res: ", res);
      form.setFieldsValue({
        uploadproject_ms: [],
      });
      form.setFieldsValue({
        uploadproject_ms: [],
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Failed...");
    console.log("Failed:", errorInfo);
  };

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  //   const customRequest = ({ file, onSuccess, onError }) => {
  //     const reader = new FileReader();

  //     reader.onload = (e) => {

  //       try {
  //         const workbook = XLSX.read(e.target.result, { type: 'binary' });
  //         const firstSheetName = workbook.SheetNames[0];
  //         const worksheet = workbook.Sheets[firstSheetName];
  //         const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //         const headerRow = data[0];
  //         const subheaderRow = data[1];

  //         var headerObj = {};
  //         headerRow.forEach((row, rowIndex) => (headerObj[rowIndex]= rowIndex));
  //         subheaderRow.forEach((row,rowIndex) =>(headerObj[rowIndex]= rowIndex))
  //  const mainArray = [];

  //  data.map((row, rowIndex)=>{
  //   const mainData = {};
  //   mainData.ID = row[headerObj[0]];
  //   mainData.Active = row[headerObj[1]];
  //   mainData.Task_Mode = row[headerObj[2]];
  //   mainData.Name = row[headerObj[3]];
  //   mainData.Duration = row[headerObj[4]];
  //   mainData.Start = row[headerObj[5]];
  //   mainData.Finish = row[headerObj[6]];
  //   mainData.Predecessors = row[headerObj[7]];
  //   mainData.Outline_Level = row[headerObj[8]];
  //   mainData.Notes = row[headerObj[9]];

  //   mainArray.push(Object.assign({},mainData))
  //  }
  // )
  //         setProjectData(mainArray);
  //         onSuccess();
  //       } catch (error) {
  //         console.error('XLSX parsing error:', error);
  //         onError(error);
  //       }
  //     };

  //     reader.onerror = (error) => {
  //       console.error('File reader error:', error);
  //       onError(error);
  //     };

  //     reader.readAsBinaryString(file);
  //   };

  const normFile = (e) => {
    if (e && e.fileList.length > 0) {
      setSelectedFile({ ...selectedFile, uploadproject_ms: true });
    } else {
      setSelectedFile({ ...selectedFile, uploadproject_ms: false });
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const DownloadSampleMms = () => {
    ShowUploadMms({ onConfirm: () => onConfirm([]) });
  };

  const onConfirm = () => {
    const fileName = "uploadScheduleSample.xlsx";
    const data = [
      {
        ID: "1",
        Active: "YES/NO",
        TaskMode: "Auto Scheduled",
        Name: "HIGH-RISE RESIDENTIAL PROJECT",
        Duration: "833days",
        Start: "Fri 12/10/21",
        Finish: "Tue 8/06/24",
        Predecessors: "7SS+10 days / 5",
        OutlineLevel: "5",
        Notes: "Work commenced from 11th Septembar 2023.",
      },
    ];
    const ws = XLSX?.utils?.json_to_sheet(data);
    const wb = XLSX?.utils?.book_new();
    XLSX?.utils?.book_append_sheet(wb, ws, "test");
    XLSX?.writeFile(wb, fileName);
  };

  // useEffect(() => {
  //   console.log("i am children", children);
  // }, [children]);

  const [excelFile, setExcelFile] = useState([]);
  const [proj, setProj] = useState("");

  return (
    <>
      {/* <div className="upload-file font-montserrat bg-white add-project"> */}
      {/* <div className="font-montserrat      font-semibold  text-2xl">
          Upload Schedule
        </div> */}
      {/* <br /> */}
      {/* <Form
        form={form}
        name="project-details-bulk"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className=" flex"
      >
        <div className=" flex flex-col">
          <div className=" flex">
            {" "}
            {logindetails.user.role === "ADMIN" ? (
              <div className=" font-montserrat flex">
                <label
                  htmlFor="project_id"
                  className="block text-xs w-[150px] mr-2 font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Select Project
                </label>
                <Form.Item
                  name="Project_id"
                  // label="Select Project"
                  className="input-field"
                  rules={[
                    {
                      required: true,
                      message: "Please Select The Project",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Select Project Name"
                    // disabled={isDisableInput}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </div>
            ) : (
              <div className=" font-montserrat flex">
                <label
                  htmlFor="project_id"
                  className="block mr w-[150px] text-xs font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Select Project
                </label>
                <Form.Item
                  name="Project_id"
                  // label="Select Project"
                  className="input-field"
                  rules={[
                    {
                      required: true,
                      message: "Please Select The Project",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Select Project Name"
                    // disabled={isDisableInput}
                  >
                    {children2}
                  </Select>
                </Form.Item>
              </div>
            )}
            <div className=" font-montserrat flex">
              <label
                htmlFor="project_id"
                className="block mr-2 w-[100px] text-xs font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Upload File
              </label>
            </div>
            <Form.Item
              //style={{ textAlign: "center",paddingBottom:20 }}
              className="input-field"
              name="uploadproject_ms"
              // label="Upload File"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please Upload File in Excel format!",
                },
              ]}
              maxCount={"1"}
              // help="Upload only .xlsx, .xls files"
            >
              <Upload
                // customRequest={customRequest}
                accept={".xlsx, .xls"}
                beforeUpload={(file, fileList) => {
                  return false;
                }}
                maxCount={"1"}
              >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Form.Item>
          </div>
          <div>
            {" "}
            <Form.Item>
              <div className="btn-project">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Upload</strong>
                </Button>
                <Button
                  type="primary"
                  className="btn-submit"
                  onClick={DownloadSampleMms}
                >
                  <strong>Sample</strong>
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form> */}
      {/* </div> */}

      {/* ntw form shivam kashyap 1 may 2024 */}
      <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
        <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
          Upload Schedule
        </div>

        <form className="font-montserrat" onSubmit={onFinish}>
          {/* 1a */}
          <div className="flex justify-evenly w-[60vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="project_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Select Project
              </label>
              <select
                required
                name="project_id"
                // value={proj}
                onChange={(e) => setProj(e.target.value)}
                // onChange={(projectId) => handelProjectId(projectId)}
                // onChange={(projectId) => {
                //   handelProjectId(projectId.target.value),
                //     setFormData({
                //       ...formData,
                //       project_id: projectId.target.value,
                //     });
                // }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>
                {logindetails.user.role === "ADMIN"
                  ? children.map((item) => (
                      <option
                        value={item.props.children[1]}
                        key={item.props.children[1]}
                      >
                        {item.props.children[0]}
                      </option>
                    ))
                  : children2.map((item) => (
                      <option
                        value={item.props.children[1]}
                        key={item.props.children[1]}
                      >
                        {item.props.children[0]}
                      </option>
                    ))}
              </select>
            </div>

            {/* 2 file  */}
            <div className="w-[300px] ">
              <label
                htmlFor="image"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Upload File
              </label>
              <input
                required
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="image"
                type="file"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(event) => {
                  const fileInput = event.target;
                  const file = fileInput.files[0];

                  if (file) {
                    setExcelFile(file);
                    // console.log("filse set: ", excelFile);
                  }
                }}
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                xls, xlsx .
              </p>
            </div>
          </div>
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
            <button
              onClick={DownloadSampleMms}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Download Sample
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default UploadMsProject;

// import React, { useState } from 'react';
// import { Form, Button, Upload } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import { parseMpp } from 'microsoft-project-parser';

// const UploadMsProject = () => {
//   const [form] = Form.useForm();
//   const [projectData, setProjectData] = useState(null);

//   const onFinish = (values) => {
//     console.log('Form values:', values);
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };

//   const customRequest = ({ file, onSuccess, onError }) => {
//     try {
//       const reader = new FileReader();

//       reader.onload = async (e) => {
//         try {
//           const arrayBuffer = e.target.result;
//           const parsedData = await parseMpp(arrayBuffer);
//           setProjectData(parsedData);
//           onSuccess();
//         } catch (error) {
//           console.error('Error parsing .mpp file:', error);
//           onError(error);
//         }
//       };

//       reader.readAsArrayBuffer(file);
//     } catch (error) {
//       console.error('Error reading .mpp file:', error);
//       onError(error);
//     }
//   };

//   return (
//     <>
//       <Form
//         form={form}
//         name="project-details-bulk"
//         size="large"
//         initialValues={{
//           remember: true,
//         }}
//         onFinish={onFinish}
//         onFinishFailed={onFinishFailed}
//       >
//         <Form.Item
//           style={{ textAlign: 'center' }}
//           name="projectdetails_excel"
//           label=""
//           rules={[
//             {
//               required: true,
//               message: 'Please Upload MS project File!',
//             },
//           ]}
//         >
//           <Upload customRequest={customRequest} accept=".mpp">
//             <Button icon={<UploadOutlined />}>Choose File</Button>
//           </Upload>
//         </Form.Item>

//         <Form.Item>
//           <div className="btn-project">
//             <Button type="primary" htmlType="submit" className="btn-submit">
//               <strong>Upload</strong>
//             </Button>
//           </div>
//         </Form.Item>
//       </Form>
//     </>
//   );
// };

// export default UploadMsProject;
