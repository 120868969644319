import "./css/TaskShow.css";
import {
  Button,
  Table,
  Input,
  notification,
  Form,
  Select,
  Modal,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  countActionQCC,
  delTaskById,
  getAllStages,
  getAllTasks,
  newStageCreate,
  updateTasksById,
} from "../../../redux-core/qcc/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirectAction } from "../../../redux-core/login/actions";
import {
  selectorStageDetail,
  selectorTaskDetail,
} from "../../../redux-core/qcc/selectors";

import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";

import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import RequestForInspection from "./RequestForInspection";
import { IoIosAdd, IoMdClose } from "react-icons/io";
import TaskCreate from "./TaskCreate";
import moment from "moment";
import toast from "react-hot-toast";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  delTask: (id) => dispatch(delTaskById(id)),
  newStage: (stage) => dispatch(newStageCreate(stage)),
  updateTask: (newTask) => dispatch(updateTasksById(newTask)),
  getTasks: (orgID) => dispatch(getAllTasks(orgID)),

  getAllStages: (orgID) => dispatch(getAllStages(orgID)),
  count: (orgId) => dispatch(countActionQCC(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const TaskShow = () => {
  const getColumnSearchProps = useSearch();
  const [form] = Form.useForm();
  const [filteractivity] = Form.useForm();
  const [formTaskDetails] = Form.useForm();
  const [taskRow, setTaskRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState();
  const {
    getAllStages,
    count,
    getTasks,
    newStage,
    updateTask,
    delTask,
    redirect,
  } = actionDispatch(useDispatch());
  const allTasksData = useSelector(selectorTaskDetail);

  const stageDetails = useSelector(selectorStageDetail);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [activedata, setActivedata] = useState(true);

  const data = [];
  const orgID = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const Spindata = useSelector((item) => item.spinreducer);
  const [searchid, setSearchid] = useState({
    min: "",
    max: "",
  });
  const [array, setArray] = useState("");

  useEffect(() => {
    getTasks(orgID);
    getAllStages(orgID);
    console.log("Task details data:", data);
  }, []);

  function handleProjChange(value) {
    formTaskDetails.setFieldsValue({
      stage_id: [],
    });

    // getStageById(parseInt(value), orgID);
  }

  const onEditStageDetailsFinish = async (values) => {
    let data = {
      ...values,
      orgID: orgID,
      createdBy: userId,
    };
    let response = await newStage(data);
    if (response) {
      await clear_field();
      getAllStages(orgID);
    }
  };

  const onEditStageDetailsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_field = () => {
    form.setFieldsValue({
      stage_name: "",
      project_id: [],
    });
  };

  const onEditTaskDetailsFinish = async (values) => {
    let data = {
      stage_id: Array.isArray(values.stage_id)
        ? values.stage_id
        : [values.stage_id],
      stage_name: values.stage_name,
      task_name: values.task_name,
      orgID: orgID,
      createdBy: userId,
      level: 4,
      task_id: taskRow.task_id,
    };
    console.log("Data before submit: ", data);
    console.log("  taskrow: ", taskRow);
    return;
    let response = await updateTask(data);
    if (response) {
      await setIsModalVisible(false);
      await getTasks(orgID);
      toast.success("Success..");
    }
  };

  const onEditTaskDetailsFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_field2 = () => {
    formTaskDetails.setFieldsValue({
      task_name: taskRow.task_name,

      stage_id: taskRow.stage_id,
    });
  };

  const showModal = async (data) => {
    // console.log("taskrow on edit click: ", taskRow);
    // console.log("taskrow on edit click: ", data);
    setTaskName(data.task_name);
    const tempFilteredData = [];
    if (data.stage_id) {
      const stageID = data.stage_id.split(",");
      stageID.forEach((item) => {
        const matchingStage = stageDetails.stage.find(
          (items) => items.stage_id == item
        );
        if (matchingStage) {
          tempFilteredData.push(`${matchingStage.stage_id}`);
        }
      });
    }

    await setTaskRow(data);
    // console.log("taskrow on edit click: ", tempFilteredData);
    setStageList(tempFilteredData);
    console.log("taskrow on edit click: ", stageList, taskName);
    setIsModalVisible(true);
    formTaskDetails.setFieldsValue({
      task_name: data.task_name,

      stage_id: tempFilteredData,
    });
  };
  useEffect(() => {}, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // form.setFieldsValue({
  //     stage_name: taskRow.stage_name,
  //     // project_id: filteredData
  // })

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
    },
    {
      title: "Task ID",
      dataIndex: "task_id",
      ...getColumnSearchProps("task_id"),
    },
    {
      title: "Task Name",
      dataIndex: "task_name",
      ...getColumnSearchProps("task_name"),
    },
    {
      title: "Stage ID",
      dataIndex: "stage_id",
      ...getColumnSearchProps("stage_id"),
    },

    {
      title: "Stage Name",
      dataIndex: "stage_name",
      ...getColumnSearchProps("stage_name"),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button
              onClick={() => {
                showModal(record), console.log("I am recor: ", record);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };
  const dataTable = [];
  for (let i = 0; i < allTasksData.length; i++) {
    dataTable.push({
      sl_no: i + 1,
      // key: allTasksData[i].id,
      task_id: allTasksData[i].id,
      task_name: allTasksData[i].task,
      stage_id: allTasksData[i].stage_id,
      stage_name: allTasksData[i].stage_name,
    });
  }

  for (let i = 0; i < allTasksData.length; i++) {
    data.push({
      sl_no: i + 1,
      key: allTasksData[i].id,
      task_id: allTasksData[i].id,
      task_name: allTasksData[i].task,
      stage_id: allTasksData[i].stage_id,
      stage_name: allTasksData[i].stage_name,
    });
  }

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.task_id]) });
  };
  const onConfirm = async (key) => {
    await delTask(key);
    await getTasks(orgID);
    await count(orgID);
  };
  const deleteSelected = () => {
    if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  const [showCreateTaskModal, setshowCreateTaskModal] = useState(false);

  // const handleChange = (e) => {
  //     setSearchid({
  //         ...searchid,
  //         [e.target.name]: e.target.value
  //     })
  // }

  // const filter = () => {
  //     var filtered = data.filter(o => {
  //         if (searchid.min && o.task_id < searchid.min) {
  //             return false;
  //         }
  //         if (searchid.max && o.task_id > searchid.max) {
  //             return false;
  //         }
  //         return true;
  //     });
  //     setArray(filtered)
  // }

  // const Reset = () => {
  //     setSearchid({
  //         min: '',
  //         max: ''
  //     })
  //     setArray(data);
  //     filteractivity.resetFields();
  // }

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  //     const children =[];
  //     for(let i = 0; i< stageDetails.stage.length; i++){
  //         children.push(
  //             <Option key={stageDetails.stage[i].stage_id}>
  // {stageDetails.stage[i].stage_name}-{stageDetails.stage[i].stage_id}
  //             </Option>
  //         )
  //     }

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    // console.log("i am date: ", dateString);
    const date = moment.utc(dateString, "DD-MM-YYYY");
    return date.format("DD MMM YYYY");
  };

  const [stageList, setStageList] = useState([]);

  const [taskName, setTaskName] = useState("");

  useEffect(() => {
    console.log("stageDetails: ", stageDetails);
  }, [stageDetails]);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        {/* <div className="title-show-project">List Of Task Details</div>
        <br /> */}
        {/* <Form
                form={filteractivity}
                name="filter_activity"
                size="medium"
                className='show_project'
                initialValues={{
                    remember: true,
                }}
                onFinish={filter}
            onFinishFailed={onStageDetailsFinishFailed}
            >

                <Form.Item
                    name="Activity_ID"
                    label="Task ID"
                    className="input-field"
                >
                    <Input.Group size="large">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Input type="number" placeholder="Min TaskID" name="min" value={searchid.min} onChange={(e) => handleChange(e)} />
                            </Col>
                            <Col span={12}>
                                <Input type="number" placeholder="Max TaskID" name="max" value={searchid.max} onChange={(e) => handleChange(e)} />
                            </Col>
                        </Row>
                    </Input.Group>

                </Form.Item>

                <Form.Item className="input-field">
                    <div>
                        <Button htmlType="submit" className="btn-submit">Filter</Button>&emsp;
                        <Button onClick={Reset} className="btn-submit">Reset</Button>
                    </div>
                </Form.Item>

            </Form> */}
        {/* <div className="btn__">
          <Button className="pdf">Export to PDF</Button>
          <Button className="excel">
            <CSVLink
              filename={"Tasks_Table.csv"}
              data={Array.isArray(array) ? array : dataTable}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          <Button className="print">Print</Button>
                <Button className="copy">Copy</Button>
        </div> */}
        {/* <Table
          className="table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={Array.isArray(array) ? array : data}
          pagination={10}
          scroll={{ x: "100vw", y: "300px" }}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
        /> */}

        {/* new table shivam kashyap 29 april 2024  */}

        <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
          <div className=" flex justify-between mt-3  mx-11 ">
            <div className="  invisible font-montserrat">
              {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

              <button
                // onClick={onUploadWorkBtn}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <span>{/* <IoIosAdd size={20} /> */}</span>
                New RFI
              </button>
            </div>
            <div className=" font-semibold  text-2xl">List Of Task Details</div>
            <div className=" font-montserrat">
              {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

              <button
                onClick={() => setshowCreateTaskModal(true)}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <span>
                  <IoIosAdd size={20} />
                </span>
                Add Task
              </button>
            </div>
          </div>

          {/* table  */}
          <div className=" font-montserrat mt-5 items-start ml-4    flex flex-col  justify-start">
            <ul role="list" className="divide-y w-[1215px] divide-[#F5F5F5]">
              <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
                {/* Header Content */}
                <div className="flex flex-col items-start -yellow-200 w-[170px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Serial Number
                  </p>
                </div>

                <div className="flex flex-col blue-200 items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Task Name
                  </p>
                  <p className="mt-1 text-xs  leading-5 text-gray-500">
                    Task Id
                  </p>
                </div>
                <div className="flex flex-col blue-200 items-start w-[210px]">
                  <p className="text-sm font-semibold text-gray-900">
                    Stage Name
                  </p>
                  <p className="mt-1 text-xs  leading-5 text-gray-500">
                    Stage Id
                  </p>
                </div>

                <div className="flex richblue-200 flex-col items-start w-[160px]">
                  <p className="text-sm  font-semibold text-gray-900">Action</p>
                </div>
              </li>
            </ul>
            {/* Child Items */}
            <div className=" overflow-y-auto max-h-[430px]   ">
              {data && data.length
                ? data.map((item, key) => (
                    <ul
                      role="list"
                      className="divide-y w-[1200px] divide-[#F5F5F5]"
                    >
                      <li
                        className={`flex justify-between ${
                          key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                        }  items-start px-5  py-2`}
                      >
                        {/* Header Content */}
                        <div className="flex flex-col items-start -yellow-200 w-[170px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item.sl_no ? item.sl_no : "-"}
                          </p>
                        </div>

                        <div className="flex flex-col blue-200 items-start w-[200px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item.task_name ? item.task_name : "-"}
                          </p>
                          <p className="mt-1 text-xs  leading-5 text-gray-500">
                            {item.task_id ? item.task_id : "-"}
                          </p>
                        </div>
                        <div className="flex flex-col blue-200 items-start w-[200px]">
                          <p className="text-sm font-semibold text-gray-900">
                            {item.stage_name ? item.stage_name : "-"}
                          </p>
                          <p className="mt-1 text-xs  leading-5 text-gray-500">
                            {item.stage_id ? item.stage_id : "-"}
                          </p>
                        </div>

                        <div className="flex richblue-300   gap-x-2 items-start w-[150px]">
                          <a
                            // onClick={() => onViewWorkOrder(item)}
                            href="#"
                            class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="Drawing"
                          >
                            {/* <MdOutlineRateReview
                              className=" text-top_nav_blue-300 "
                              size={18}
                              // onClick={}
                              onClick={() => HandelView(item.drawing_file)}
                            /> */}
                          </a>{" "}
                          <a
                            // onClick={() => onBillingWorkOrder(item)}
                            href="#"
                            class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="Edit"
                          >
                            <MdEdit
                              className=" text-top_nav_blue-300 "
                              size={18}
                              onClick={() => showModal(item)}
                            />
                            {/* <RiBillLine
                         
                        /> */}
                          </a>{" "}
                          <a
                            // onClick={() => handelAdvanceRequest(item)}
                            href="#"
                            class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="Delete"
                          >
                            <MdDelete
                              className=" text-top_nav_blue-300 "
                              size={18}
                              onClick={() => deleteRow(item)}
                            />

                            {/* <FaIndianRupeeSign
                         
                        /> */}
                          </a>
                        </div>
                      </li>
                    </ul>
                  ))
                : "No records found..."}
            </div>
          </div>
        </div>

        {/* edit task  odal  */}
        {/* <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={700}
          centered={true}
          footer={null}
        >
          <div className="edit__task">Edit Task Details</div>
          <br />
          <Form
            form={formTaskDetails}
            name="task_details"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditTaskDetailsFinish}
            onFinishFailed={onEditTaskDetailsFinishFailed}
          >
            <Form.Item
              name="task_name"
              label="Task Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Task Name !",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 characters only",
                },
              ]}
            >
              <Input placeholder="Task Name" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="stage_id"
              label="Select Stages"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please select one or more Stages !",
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Stage Name"
              >
                {stageDetails.stage?.map((getAllStages) => (
                  <Option key={getAllStages?.stage_id}>
                    {getAllStages?.stage_name}--{getAllStages?.stage_id}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_field2} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal> */}
      </Spin>
      {showCreateTaskModal && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setshowCreateTaskModal(false)}
            className=" cursor-pointer absolute top-14 right-52"
          />

          <TaskCreate
            getTasks={getTasks}
            orgID={orgID}
            showCreateTaskModal={showCreateTaskModal}
            setshowCreateTaskModal={setshowCreateTaskModal}
          />
        </div>
      )}
      {isModalVisible && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setIsModalVisible(false)}
            className=" cursor-pointer absolute top-14 right-52"
          />

          <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
            <div className="font-montserrat   flex justify-start ml-20  font-semibold  text-2xl">
              Edit Task Details
            </div>
            <form
              className="font-montserrat"
              onSubmit={onEditTaskDetailsFinish}
            >
              {/* 1a */}
              <div className="flex justify-evenly w-[60vw]">
                {/* 1 */}
                <div className="">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Task Name
                  </label>
                  <div className="mt- w-[300px]">
                    <input
                      required
                      type="text"
                      name="description"
                      value={taskName}
                      onChange={(e) => {
                        setTaskName(e.target.value);
                      }}
                      className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>{" "}
                {/* 2  */}
                <div className="mt w-[300px]">
                  <label
                    htmlFor="stage_id"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <span className="text-red-600">*</span>
                    Select Stage(Multiple)
                  </label>
                  <select
                    required
                    name="stage_id"
                    // value={formData.project_id}
                    // onChange={handleInputChange}
                    // onChange={(e) => {
                    //   setFormData({
                    //     ...formData,
                    //     stage_id: [...formData.stage_id, e.target.value],
                    //   });
                    // }}
                    // onFocus={allStages}
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                  >
                    <option value="">Select</option>
                    {stageDetails.stage?.map((getAllStages) => (
                      <option
                        value={getAllStages?.stage_id}
                        key={getAllStages?.stage_id}
                      >
                        {getAllStages?.stage_name}--{getAllStages?.stage_id}
                      </option>
                    ))}
                  </select>
                  {stageList &&
                    stageList.map((stage) => (
                      <p
                        class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        
                      >
                        {stage}
                      </p>
                    ))}
                </div>
              </div>
              {/* buttons  */}
              <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
                <button
                  // onClick={() => setStage("")}
                  type="button"
                  class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  data-te-ripple-init
                >
                  Reset
                </button>
                <button
                  type="submit"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(TaskShow);
