import "./css/ActivityShow.css";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  DatePicker,
  message,
  Image,
  Row,
  Col,
  Upload,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirectAction } from "../../../redux-core/login/actions";
import {
  selectorActivityDetail,
  selectorStageDetailById,
  selectorTaskDetailById,
} from "../../../redux-core/qcc/selectors";
import {
  countActionQCC,
  deleteActivityById,
  findStageDetailById,
  findTaskDetailById,
  getAllActivities,
  updateActById,
} from "../../../redux-core/qcc/actions";
import { getAllProj } from "../../../redux-core/settings/actions";
import { findSupervisorDetailById } from "../../../redux-core/userManagement/actions";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { selectorSupervisorDetailById } from "../../../redux-core/userManagement/selectors";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { ShowDeleteConfirm } from "../../popupmodal";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import ActivityPrediction from "./ActivityPrediction";
import { IoIosAdd, IoMdClose } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import ActivityCreate from "./ActivityCreate";

const actionDispatch = (dispatch) => ({
  delActivity: (id) => dispatch(deleteActivityById(id)),
  updateActivity: (newActivity) => dispatch(updateActById(newActivity)),
  getProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  taskById: (projId, orgId) => dispatch(findTaskDetailById(projId, orgId)),
  supervisorById: (projId, orgId) =>
    dispatch(findSupervisorDetailById(projId, orgId)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
  getActivities: (orgId) => dispatch(getAllActivities(orgId)),
  count: (orgID) => dispatch(countActionQCC(orgID)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});
const { Option } = Select;

const ActivityShow = () => {
  const getColumnSearchProps = useSearch();
  const [addActivity] = Form.useForm();
  const [filteractivity] = Form.useForm();
  const [date, setDate] = useState("");
  const [selectedKeys, setSelectedKeys] = useState();
  const [activityRow, setActivityRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    getProj,
    taskById,
    supervisorById,
    getStageById,
    getActivities,
    delActivity,
    updateActivity,
    count,
    redirect,
  } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const Spindata = useSelector((item) => item.spinreducer);
  const [passDate, setPassDate] = useState();
  useEffect(() => {
    getActivities(orgId);
  }, []);
  const allProjData = useSelector(makeSelectAllProj);
  const supervisorData = useSelector(selectorSupervisorDetailById);
  const stageDetailsData = useSelector(selectorStageDetailById);
  const taskDetailsData = useSelector(selectorTaskDetailById);
  const allActvityData = useSelector(selectorActivityDetail);
  const children_supervisor = [];
  const children_stages = [];
  const children_tasks = [];
  const children = [];
  const data = [];
  const [imagecard, setImagecard] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const [searchid, setSearchid] = useState({
    min: "",
    max: "",
  });
  const [mindate, setMindate] = useState(null);
  const [maxdate, setMaxdate] = useState(null);
  const [activedata, setActivedata] = useState(true);
  const [feedback, setFeedback] = useState({
    image: "",
    state: false,
  });

  useEffect(() => {
    taskById(orgId);
  }, []);

  const [array, setArray] = useState("");
  for (let i = 0; i < taskDetailsData.length; i++) {
    children_tasks.push(
      <Option value={taskDetailsData[i].id}>
        {taskDetailsData[i].task}-{taskDetailsData[i].id}
      </Option>
    );
  }

  for (let i = 0; i < supervisorData.length; i++) {
    children_supervisor.push(
      <Option value={supervisorData[i].supervisor_id}>
        {supervisorData[i].name}-{supervisorData[i].supervisor_id}
      </Option>
    );
  }

  for (let i = 0; i < stageDetailsData.length; i++) {
    children_stages.push(
      <Option value={stageDetailsData[i].stage_id}>
        {stageDetailsData[i].stage_name}-{stageDetailsData[i].stage_id}
      </Option>
    );
  }
  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option value={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  const onEditActivityFinish = async (values) => {
    let data = {
      ...values,
      date: date,
      orgID: orgId,
      createdBy: userId,
      latitude: 122.7,
      longitude: 107.5,
      isAdmin: 0,
      isSyncAdmin: 0,
      dateSuper: "11/18/2022",
      no_of_checking: 2,
      comment: "na",
      is_approve: 0,
      rating: 1,
      isDone: 0,
      activity_id: activityRow.activity_id,
    };
    // if (values.image && values.image.length < 1) {
    //   delete data.image
    // } else {
    //   data.image = values.image[0].size ? values.image : values.image[0].thumbUrl;
    // }
    let response = await updateActivity(data);
    if (response) {
      await setIsModalVisible(false);
      await getActivities(orgId);
    }
  };

  const handleDate = (value, dateString) => {
    setDate(dateString);
  };
  const onEditActivityFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function handleProjChange(value) {
    addActivity.setFieldsValue({
      // task_name: '',
      task_id: "",
      supervisor_id: "",
      stage_id: "",
    });
    taskById(orgId);
    supervisorById(parseInt(value), orgId);
    //getStageById(parseInt(value), orgId);
  }
  const callStage = (taskId) => {
    getStageById(orgId, taskId);
  };
  const clear_field = () => {
    taskById(orgId);

    getStageById(activityRow.task_id, orgId);
    addActivity.setFieldsValue({
      description: activityRow.description,
      date: moment(activityRow.date),
      task_id: [activityRow.task_id],
      stage_id: [activityRow.stage_id],
      // image: activityRow.image ? [{ thumbUrl: activityRow.image }] : []
    });
    // { activityRow.image == null || activityRow.image === undefined || activityRow.image === "" ? setImagecard(false) : setImagecard(true) }
  };
  const showModal = (data1) => {
    setIsModalVisible(true);
    getStageById(orgId, data1.task_id);
    const originalDate = data1.date;
    console.log("originalDate", originalDate);
    const momentDate = moment(originalDate, "DD-MM-YYYY");
    addActivity.setFieldsValue({
      description: data1.description,
      stage_id: data1.stage_id,
      task_id: data1.task_id,
      date: momentDate,
      activity_id: data1.activity_id,
      image: data1.image ? [{ thumbUrl: data1.image }] : [],
    });
    setActivityRow(data1);
    // taskById(parseInt(data.project_id), orgId);

    // getStageById(parseInt(data.project_id), orgId);
    //setActivityRow(data);
    // setIsModalVisible(true);
    // addActivity.setFieldsValue({
    //   description: data.description,

    //   stage_id: data.stage_id,
    //   task_id: data.task_id,
    //   date: moment(data.date),
    //   activity_id: data.activity_id,
    //   image: data.image ? [{ thumbUrl: data.image }] : []
    // })
    setDate(originalDate);
    {
      data.image == null || data.image === undefined || data.image === ""
        ? setImagecard(false)
        : setImagecard(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: " Sl No",
      dataIndex: "sl_no",
    },
    {
      title: "Activity ID",
      dataIndex: "activity_id",
    },
    {
      title: "Activity Name",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "Date",
      dataIndex: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Task Name",
      dataIndex: "task",
      ...getColumnSearchProps("task"),
    },
    {
      title: "Stage Name",
      dataIndex: "stage",
      ...getColumnSearchProps("stage"),
    },
    // {
    //   title: 'Stage ID',
    //   dataIndex: 'stage_id',
    //   ...getColumnSearchProps('stage_id')
    // },
    // {
    //   title: 'Task ID',
    //   dataIndex: 'task_id',
    //   ...getColumnSearchProps('task_id')
    // },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  for (let i = 0; i < allActvityData.length; i++) {
    data.push({
      sl_no: i + 1,
      key: allActvityData[i].parameter_id,
      activity_id: allActvityData[i].parameter_id,
      task: allActvityData[i].task,
      date: allActvityData[i].date,
      description: allActvityData[i].description,
      stage: allActvityData[i].stage_name,
      //   image: allActvityData[i].image,
      stage_id: allActvityData[i].stage_id,
      task_id: allActvityData[i].id,
      // feedback:allActvityData[i].feedback,
    });
  }

  // export excel
  const dataTable = [];

  for (let i = 0; i < allActvityData.length; i++) {
    dataTable.push({
      sl_no: i + 1,
      activity_id: allActvityData[i].parameter_id,
      task: allActvityData[i].task,
      date: allActvityData[i].date,
      activity: allActvityData[i].description,
      stage: allActvityData[i].stage_name,
      //   image: allActvityData[i].image,
      // stage_id: allActvityData[i].stage_id,
      // task_id: allActvityData[i].id,
      // feedback:allActvityData[i].feedback,
    });
  }

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.activity_id]) });
  };

  const onConfirm = async (key) => {
    await delActivity(key);
    await count(orgId);
    await getActivities(orgId);
  };

  const deleteSelected = () => {
    if (selectedKeys === undefined)
      notification.open({
        message: "Select items to delete.",
      });
    else if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };

  // const handleChange = (e, dateString) => {
  //   setSearchid({
  //     ...searchid,
  //     [e.target.name]: e.target.value
  //   })
  // }

  // const filter = () => {
  //   var filtered = data.filter(o => {
  //     if (searchid.min && o.activity_id < searchid.min) {
  //       return false;
  //     }
  //     if (searchid.max && o.activity_id > searchid.max) {
  //       return false;
  //     }
  //     if (mindate && o.date < mindate) {
  //       return false;
  //     }
  //     if (maxdate && o.date > maxdate) {
  //       return false;
  //     }
  //     return true;
  //   });
  //   setArray(filtered)
  // }
  // const Reset = () => {
  //   setSearchid({
  //     min: '',
  //     max: ''
  //   })
  //   setArray(data);
  //   setMaxdate('');
  //   setMindate('');
  //   filteractivity.resetFields();
  // }
  // const handlemindate = (value, dateString) => {
  //   setMindate(dateString);
  // }
  // const handlemaxdate = (value, dateString) => {
  //   setMaxdate(dateString)
  // }

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  // const normFile = (e) => {
  //   if (e.fileList.length > 0) setImagecard(true)
  //   else setImagecard(false)
  //   if (Array.isArray(e)) {
  //     return e;
  //   }

  //   return e && e.fileList;
  // };

  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div style={{ marginTop: 8 }}> Click to Upload</div>
  //   </div>
  // );

  const handleCancelmodal = () => setPreviewvisible(false);

  // const handlePreview = async file => {
  //   if (!file.url && !file.preview && file.size) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl)
  //   setPreviewvisible(true)
  // };

  // const handlefeedback = (record) => {
  //   setFeedback({
  //     image: record,
  //     state: true
  //   })
  // }

  const backtoactivity = () => {
    setFeedback({
      image: "",
      state: false,
    });
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  // const disabledStartDate = (current) => {
  //   // Disable dates before today
  //   return current && current.isBefore(Date.now(), 'day');
  // };

  const dateFormatList = ["DD-MM-YYYY"];
  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(Date.now(), "day");
  };

  // useEffect(() => {
  //   console.log("Activity data: ", data);
  // }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    // console.log("i am date: ", dateString);
    const date = moment.utc(dateString, "DD-MM-YYYY");
    return date.format("DD MMM YYYY");
  };

  const [showCreateActivityModal, setshowCreateActivityModal] = useState(false);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        {!feedback.state ? (
          <>
            {/* <div className="title-show-project">List Of Activity Details</div>
            <br /> */}

            {/* <Form
        form={filteractivity}
        name="filter_activity"
        size="medium"
        initialValues={{
          remember: true,
        }}
        onFinish={filter}
      // onFinishFailed={onStageDetailsFinishFailed}
      >

        <Form.Item
          // wrapperCol={{ sm: 20 }} style={{ marginRight: 0 }}
          name="Activity_ID"
          label="Activity_ID"
          className="input-field"
        >
          <Input.Group size="large">
            <Row gutter={12}>
              <Col span={12}>
                <Input type='number' name='min' placeholder="Min ActivityID" value={searchid.min} onChange={(e) => handleChange(e)} />
              </Col>
              <Col span={12}>
                <Input type='number' name='max' placeholder="Max ActivityID" value={searchid.max} onChange={(e) => handleChange(e)} />
              </Col>
            </Row>
          </Input.Group>

        </Form.Item>

        <Form.Item
          wrapperCol={{ sm: 13 }} style={{ marginRight: 0 }}
          name="date"
          label="Date"
          className="input-field"
        >
          <Row gutter={12}>
            <Col span={12}>
              <DatePicker name='minDate' format='YYYY-MM-DD' onChange={handlemindate} placeholder='MinDate' />
            </Col>
            <Col span={12}>
              <DatePicker name='maxDate' format='YYYY-MM-DD' onChange={handlemaxdate} placeholder='MaxDate' />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item >
          <div className="btn-project">
            <Button htmlType="submit" className="btn-submit">Filter</Button>&emsp;
            <Button onClick={Reset} className="btn-submit">Reset</Button>
          </div>
        </Form.Item>

      </Form> */}
            {/* <div className="btn__">
              <Button className="pdf">Export to PDF</Button>
              <Button className="excel">
                <CSVLink
                  filename={"Activity_Table.csv"}
                  data={Array.isArray(array) ? array : dataTable}
                  className="excel"
                  onClick={() => {
                    message.success("The file is downloading");
                  }}
                >
                  Export to Excel
                </CSVLink>
              </Button>
              <Button className="print">Print</Button>
        <Button className="copy">Copy</Button>
            </div> */}
            {/* <Table
              className="table"
              scroll={{ x: "110vw", y: "300px" }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={Array.isArray(array) ? array : data}
              pagination={10}
              onChange={(pagination, filter, sorter, currentTable) =>
                onTableChange(filter, sorter, currentTable)
              }
            /> */}

            {/* new ui shivam kashyap  */}
            <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
              <div className=" flex justify-between mt-3  mx-11 ">
                <div className="  invisible font-montserrat">
                  {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

                  <button
                    // onClick={onUploadWorkBtn}
                    type="button"
                    class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    <span>{/* <IoIosAdd size={20} /> */}</span>
                    New RFI
                  </button>
                </div>
                <div className=" font-semibold  text-2xl">
                  List Of Activity Details
                </div>
                <div className=" font-montserrat">
                  {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

                  <button
                    onClick={() => setshowCreateActivityModal(true)}
                    type="button"
                    class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  >
                    <span>
                      <IoIosAdd size={20} />
                    </span>
                    Add Activity
                  </button>
                </div>
              </div>{" "}
              {/* table  */}
              <div className=" font-montserrat mt-5 items-start ml-4    flex flex-col  justify-start">
                <ul
                  role="list"
                  className="divide-y w-[1215px] divide-[#F5F5F5]"
                >
                  <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
                    {/* Header Content */}
                    <div className="flex flex-col items-start w-[165px] 300">
                      <p className="text-sm font-semibold text-gray-900">
                        Serial Number
                      </p>
                    </div>

                    <div className="flex flex-col blue-200 items-start w-[200px] 200">
                      <p className="text-sm font-semibold text-gray-900">
                        Activity Name
                      </p>
                      <p className="mt-1 text-xs  leading-5 text-gray-500 300">
                        Activity Id
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[200px] 100">
                      <p className="text-sm font-semibold text-gray-900">
                        Date
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[210px] 300">
                      <p className="text-sm font-semibold text-gray-900">
                        Task Name
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[205px] 100">
                      <p className="text-sm font-semibold text-gray-900">
                        Stage Name
                      </p>
                    </div>

                    <div className="flex richblue-200 flex-col items-start w-[160px] 300">
                      <p className="text-sm  font-semibold text-gray-900">
                        Action
                      </p>
                    </div>
                  </li>
                </ul>
              </div>{" "}
              {/* child items  */}
              <div className=" overflow-y-auto max-h-[430px]    flex items-start flex-col ml-4 p-0">
                {data && data.length
                  ? data.map((item, key) => (
                      <ul
                        role="list"
                        className="divide-y w-[1200px] divide-[#F5F5F5]"
                      >
                        <li
                          className={`flex justify-between ${
                            key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                          }  items-start px-5  py-2`}
                        >
                          {/* Header Content */}
                          <div className="flex flex-col items-start w-[170px] 300">
                            <p className="text-sm font-semibold text-gray-900">
                              {item.sl_no ? item.sl_no : "-"}
                            </p>
                          </div>

                          <div className="flex flex-col blue-200 items-start w-[210px] 200">
                            <p className="text-sm font-semibold text-gray-900">
                              {item.description ? item.description : "-"}
                            </p>
                            <p className="mt-1 text-xs  leading-5 text-gray-500 300">
                              {item.activity_id ? item.activity_id : "-"}
                            </p>
                          </div>
                          <div className="flex flex-col blue-200 items-start w-[210px] 100">
                            <p className="text-sm font-semibold text-gray-900">
                              {item.date ? formatDate(item.date) : "-"}
                            </p>
                          </div>
                          <div className="flex flex-col blue-200 items-start w-[220px] 300">
                            <p className="text-sm font-semibold text-gray-900">
                              {item.task ? item.task : "-"}
                            </p>
                          </div>
                          <div className="flex flex-col blue-200 items-start w-[210px] 100">
                            <p className="text-sm font-semibold text-gray-900">
                              {item.stage ? item.stage : "-"}
                            </p>
                          </div>

                          <div className="flex richblue-200  pl-2 gap-x-1 items-start w-[160px] 300">
                            <a
                              // onClick={() => onBillingWorkOrder(item)}
                              href="#"
                              class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                              data-te-toggle="tooltip"
                              title="Edit"
                            >
                              <MdEdit
                                className=" text-top_nav_blue-300 "
                                size={18}
                                onClick={() => showModal(item)}
                              />
                              {/* <RiBillLine
                         
                        /> */}
                            </a>{" "}
                            <a
                              // onClick={() => handelAdvanceRequest(item)}
                              href="#"
                              class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                              data-te-toggle="tooltip"
                              title="Delete"
                            >
                              <MdDelete
                                className=" text-top_nav_blue-300 "
                                size={18}
                                onClick={() => deleteRow(item)}
                              />

                              {/* <FaIndianRupeeSign
                         
                        /> */}
                            </a>
                          </div>
                        </li>
                      </ul>
                    ))
                  : "No records found..."}
              </div>
            </div>

            <Modal
              visible={isModalVisible}
              onCancel={handleCancel}
              width={700}
              centered={true}
              footer={null}
            >
              <div className="edit__activity">Edit Activity Details</div>
              <br />
              <Form
                form={addActivity}
                name="master-task"
                size="large"
                initialValues={{
                  remember: true,
                }}
                onFinish={onEditActivityFinish}
                onFinishFailed={onEditActivityFinishFailed}
              >
                <Form.Item
                  name="description"
                  label="Activity Name"
                  className="input-field"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Activity Name !",
                    },
                    {
                      pattern: /^(.{0,100})$/,
                      message: "please enter less than 100 characters only",
                    },
                  ]}
                >
                  <Input
                    placeholder="Activity Name"
                    onKeyDown={(e) => onKeydown(e)}
                  />
                </Form.Item>

                <Form.Item
                  name="date"
                  label="Date"
                  // valuePropName="fileList"
                  // getValueFromEvent={normFile}
                  className="input-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select Date !",
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormatList}
                    disabledDate={disabledStartDate}
                    onChange={handleDate}
                    //  disabledDate={disabledStartDate}
                    onKeyDown={(e) => onKeydown(e)}
                  />
                </Form.Item>

                <Form.Item
                  name="task_id"
                  label="Select Task"
                  className="input-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select any Task !",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Task"
                    onChange={callStage}
                    allowClear
                  >
                    {children_tasks}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="stage_id"
                  label="Select Stage"
                  className="input-field"
                  rules={[
                    {
                      required: true,
                      message: "Please select any Stage !",
                    },
                  ]}
                >
                  <Select placeholder="Select Stage" allowClear>
                    {children_stages}
                  </Select>
                </Form.Item>
                {/* <Form.Item
            name="image"
            label="Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="input-field"
          >
            <Upload name="logo" listType="picture-card" accept=".jpg,.jpeg,.png" maxCount={'1'} onPreview={handlePreview} >
              {!imagecard && uploadButton}
            </Upload>
          </Form.Item> */}

                <Form.Item>
                  <div className="btn-project">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn-submit"
                      style={{ borderRadius: "10px" }}
                    >
                      <strong>Submit</strong>
                    </Button>

                    <Button onClick={clear_field} className="btn-cancel">
                      {" "}
                      <strong>Reset</strong>
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
            {/* <br /> */}
            {/* {Array.isArray(array)
              ? array.length > 0 && (
                  <Button
                    style={{ display: activedata ? "" : "none" }}
                    className="deleteallbutton copy"
                    onClick={deleteSelected}
                  >
                    Delete All Selected
                  </Button>
                )
              : data.length > 0 && (
                  <Button
                    style={{ display: activedata ? "" : "none" }}
                    className="deleteallbutton copy"
                    onClick={deleteSelected}
                  >
                    Delete All Selected
                  </Button>
                )} */}
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={handleCancelmodal}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        ) : (
          <ActivityPrediction image={feedback.image} back={backtoactivity} />
        )}
      </Spin>

      {showCreateActivityModal && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setshowCreateActivityModal(false)}
            className=" cursor-pointer absolute top-10 right-40 "
          />
          <ActivityCreate
            showCreateActivityModal={showCreateActivityModal}
            setshowCreateActivityModal={setshowCreateActivityModal}
          />
        </div>
      )}
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default React.memo(ActivityShow);
