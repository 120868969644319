import { Button, Col, Row, message } from "antd";
import { useNavigate } from "react-router-dom";
import history from "../../utils/history";
import "./IconsNotification.css";
import { makeSelectLoginDetail } from "../../redux-core/login/selectors";
import { useSelector } from "react-redux";
const style = {
  padding: "8px 0",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};
const IconsNotification = () => {
  const logindetails = useSelector(makeSelectLoginDetail);
  let navigate = useNavigate();
  const onLogOut = () => {
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("refreshToken");
    navigate("/login");
    history.push("/login");
    document.location.reload(true);
  };

  const settingsPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/settings");
      history.push("/settings");
    } else if (logindetails.user.role === "SUPERADMIN") {
      navigate("/settings");
      history.push("/settings");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };
  // const userManagementPageHandler = () => {
  //     navigate('/usermanagement');
  //     history.push('/usermanagement');
  // }

  const qccPageHandler = () => {
    if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "ADMIN") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/qcc");
      history.push("/qcc");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/qcc");
      history.push("/qcc");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const resourcesPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/resources");
      history.push("/resources");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/resources");
      history.push("/resources");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/resources");
      history.push("/resources");
    }  else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/resources");
      history.push("/resources");
    }else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const riskPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/risk");
      history.push("/risk");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/risk");
      history.push("/risk");
    }else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const drawingPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/drawings");
      history.push("/drawings");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/drawings");
      history.push("/drawings");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/drawings");
      history.push("/drawings");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/drawings");
      history.push("/drawings");
    } else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const contractsPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/contracts");
      history.push("/contracts");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/contracts");
      history.push("/contracts");
    }else if (logindetails.user.role === "BILLINGENGINEER") {
      navigate("/contracts");
      history.push("/contracts");
    }
    else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const materialsPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/materials");
      history.push("/materials");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/materials");
      history.push("/materials");
    }else {
      message.error(
        "You are not authorized to access this module. Please Contact admin. !!"
      );
    }
  };

  const reportsPageHandler = () => {
    if (logindetails.user.role === "ADMIN") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "SITEINCHARGE") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "SUPERADMIN") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "SUPERVISOR") {
      navigate("/reports");
      history.push("/reports");
    } else if (logindetails.user.role === "DRAWINGARCHITECTURE") {
      navigate("/reports");
      history.push("/reports");
    }else {
      message.error(
        "You are not athorized to access this module. Please Contact admin!!"
      );
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {logindetails.user.role === "SUPERADMIN" ?  (
          <>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="setting_color.png"
                    width={80}
                    height={80}
                    onClick={settingsPageHandler}
                  />
                </div>
                <div>
                  <p>Settings</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="report_color.png"
                    width={80}
                    height={80}
                    onClick={reportsPageHandler}
                  />
                </div>
                <div>
                  <p>Reports</p>
                </div>
              </div>
            </Col>
          </>
        ): logindetails.user.role === "ADMIN" ?(
            <>
             <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="setting_color.png"
                    width={80}
                    height={80}
                    onClick={settingsPageHandler}
                  />
                </div>
                <div>
                  <p>Settings</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="qcc_color.png"
                    width={80}
                    height={80}
                    onClick={qccPageHandler}
                  />
                </div>
                <div>
                  <p>QCC</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="schedule_color.png"
                    width={80}
                    height={80}
                    onClick={resourcesPageHandler}
                  />
                </div>
                <div>
                  <p>Resources</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="report_color.png"
                    width={80}
                    height={80}
                    onClick={riskPageHandler}
                  />
                </div>
                <div>
                  <p>Progress</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="drawing.png"
                    width={80}
                    height={80}
                    onClick={drawingPageHandler}
                  />
                </div>
                <div>
                  <p>Documents</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="contract_color.png"
                    width={80}
                    height={80}
                    onClick={contractsPageHandler}
                  />
                </div>
                <div>
                  <p>Contracts</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="material_color.png"
                    width={80}
                    height={80}
                    onClick={materialsPageHandler}
                  />
                </div>
                <div>
                  <p>Materials</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="report_color.png"
                    width={80}
                    height={80}
                    onClick={reportsPageHandler}
                  />
                </div>
                <div>
                  <p>Reports</p>
                </div>
              </div>
            </Col>
            </>
        ) : (
            <>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="qcc_color.png"
                    width={80}
                    height={80}
                    onClick={qccPageHandler}
                  />
                </div>
                <div>
                  <p>QCC</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="schedule_color.png"
                    width={80}
                    height={80}
                    onClick={resourcesPageHandler}
                  />
                </div>
                <div>
                  <p>Resources</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="report_color.png"
                    width={80}
                    height={80}
                    onClick={riskPageHandler}
                  />
                </div>
                <div>
                  <p>Progress</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="drawing.png"
                    width={80}
                    height={80}
                    onClick={drawingPageHandler}
                  />
                </div>
                <div>
                  <p>Documents</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="contract_color.png"
                    width={80}
                    height={80}
                    onClick={contractsPageHandler}
                  />
                </div>
                <div>
                  <p>Contracts</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="material_color.png"
                    width={80}
                    height={80}
                    onClick={materialsPageHandler}
                  />
                </div>
                <div>
                  <p>Materials</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <div>
                  <img
                    alt="example"
                    src="report_color.png"
                    width={80}
                    height={80}
                    onClick={reportsPageHandler}
                  />
                </div>
                <div>
                  <p>Reports</p>
                </div>
              </div>
            </Col>
            </>
        )}

        {/* <Col className="gutter-row" span={12}>
                    <div style={style}>
                        <div>
                            <img alt="example" src="usermanage_color.png" width={80} height={80} onClick={userManagementPageHandler} />
                        </div>
                        <div><p> User Management</p></div>
                    </div>
                </Col> */}
        {/* {logindetails.user.role === "ADMIN" && ( */}
       
          {/* <Col className="gutter-row" span={12}>
                    <div style={style}>
                        <div>
                            <img alt="example" src="setting_color.png" width={80} height={80} onClick={settingsPageHandler} />
                        </div>
                        <div><p>Settings</p></div>
                    </div>
                </Col> */}
{/* {logindetails.user.role === "ADMIN" ? (
    <>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="qcc_color.png"
                  width={80}
                  height={80}
                  onClick={qccPageHandler}
                />
              </div>
              <div>
                <p>QCC</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="schedule_color.png"
                  width={80}
                  height={80}
                  onClick={resourcesPageHandler}
                />
              </div>
              <div>
                <p>Resources</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="report_color.png"
                  width={80}
                  height={80}
                  onClick={riskPageHandler}
                />
              </div>
              <div>
                <p>Progress</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="drawing.png"
                  width={80}
                  height={80}
                  onClick={drawingPageHandler}
                />
              </div>
              <div>
                <p>Drawings</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="contract_color.png"
                  width={80}
                  height={80}
                  onClick={contractsPageHandler}
                />
              </div>
              <div>
                <p>Contracts</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="material_color.png"
                  width={80}
                  height={80}
                  onClick={materialsPageHandler}
                />
              </div>
              <div>
                <p>Materials</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="report_color.png"
                  width={80}
                  height={80}
                  onClick={reportsPageHandler}
                />
              </div>
              <div>
                <p>Reports</p>
              </div>
            </div>
          </Col>
          </>
): logindetails.user.role === "SITEINCHARGE" ? (
<>
<Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="qcc_color.png"
                  width={80}
                  height={80}
                  onClick={qccPageHandler}
                />
              </div>
              <div>
                <p>QCC</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="schedule_color.png"
                  width={80}
                  height={80}
                  onClick={resourcesPageHandler}
                />
              </div>
              <div>
                <p>Resources</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="report_color.png"
                  width={80}
                  height={80}
                  onClick={riskPageHandler}
                />
              </div>
              <div>
                <p>Risk</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="drawing.png"
                  width={80}
                  height={80}
                  onClick={drawingPageHandler}
                />
              </div>
              <div>
                <p>Drawings</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="contract_color.png"
                  width={80}
                  height={80}
                  onClick={contractsPageHandler}
                />
              </div>
              <div>
                <p>Contracts</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="material_color.png"
                  width={80}
                  height={80}
                  onClick={materialsPageHandler}
                />
              </div>
              <div>
                <p>Materials</p>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={style}>
              <div>
                <img
                  alt="example"
                  src="report_color.png"
                  width={80}
                  height={80}
                  onClick={reportsPageHandler}
                />
              </div>
              <div>
                <p>Reports</p>
              </div>
            </div>
          </Col>
</>
): logindetails.user.role === "SUPERVISOR" ? (
<>
    <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="qcc_color.png"
                      width={80}
                      height={80}
                      onClick={qccPageHandler}
                    />
                  </div>
                  <div>
                    <p>QCC</p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="schedule_color.png"
                      width={80}
                      height={80}
                      onClick={resourcesPageHandler}
                    />
                  </div>
                  <div>
                    <p>Resources</p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="report_color.png"
                      width={80}
                      height={80}
                      onClick={riskPageHandler}
                    />
                  </div>
                  <div>
                    <p>Risk</p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="drawing.png"
                      width={80}
                      height={80}
                      onClick={drawingPageHandler}
                    />
                  </div>
                  <div>
                    <p>Drawings</p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="contract_color.png"
                      width={80}
                      height={80}
                      onClick={contractsPageHandler}
                    />
                  </div>
                  <div>
                    <p>Contracts</p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="material_color.png"
                      width={80}
                      height={80}
                      onClick={materialsPageHandler}
                    />
                  </div>
                  <div>
                    <p>Materials</p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={12}>
                <div style={style}>
                  <div>
                    <img
                      alt="example"
                      src="report_color.png"
                      width={80}
                      height={80}
                      onClick={reportsPageHandler}
                    />
                  </div>
                  <div>
                    <p>Reports</p>
                  </div>
                </div>
              </Col>
    </>) : (<></>)} */}
    

        {/* <Col className="gutter-row" span={24}>
                    <div className='gutter-btn'>
                        <Button type="primary" htmlType="submit" className="logout"
                            size="large" onClick={onLogOut}>Logout</Button>
                    </div>
                </Col> */}
      </Row>
    </>
);
            };

export default IconsNotification;
