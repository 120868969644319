import "./TopNavbar.css";
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  HomeOutlined,
  HomeTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Space, message } from "antd";
import "antd/dist/antd.min.css";
import { Link, useNavigate } from "react-router-dom";
import history from "../../../utils/history";
// import HomePage from "../../afterLogin/HomePage";
import IconsNotification from "../../IconsNotification/IconsNotification";
import { MenuFoldOutlined } from "@ant-design/icons";
import Expand from "../../expand";
import { useEffect, useState } from "react";
import { MdWavingHand } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { selectorUserDetail } from "../../../redux-core/userManagement/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { userDetails } from "../../../redux-core/userManagement/actions";
import UserModal from "./UserModal";
import { MdLogout } from "react-icons/md";
import { FaInfo } from "react-icons/fa";
import {
  Collapse,
  Dropdown,
  Tooltip,
  Ripple,
  initTE,
  Modal,
} from "tw-elements";
import { TiHomeOutline } from "react-icons/ti";
import { IoIosMail } from "react-icons/io";
import { Showlogout } from "../../popupmodal";
import { IoIosCall } from "react-icons/io";
import UserManual from "../../modal/UserManual";
import { IoMdClose } from "react-icons/io";
import { IoMdChatboxes } from "react-icons/io";
import toast from "react-hot-toast";
// import { Showlogout } from "../popupmodal";
const { Header } = Layout;

const actionDispatch = (dispatch) => ({
  getUsers: (orgId) => dispatch(userDetails(orgId)),
});

function TopNavbar(props) {
  const { getUsers } = actionDispatch(useDispatch());
  const getUserList = useSelector(selectorUserDetail);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userID = logindetails.organisation.userID;
  const [viewDocumentModal, setViewDocumentModal] = useState(false);

  const handleOpenDocument = (e) => {
    e.preventDefault();
    setViewDocumentModal(true);
    console.log("i am clicked", viewDocumentModal);
  };

  useEffect(() => {
    initTE({ Collapse, Dropdown, Tooltip, Ripple, Modal });
    // console.log(getUsers);
    // console.log(getUserList);
    // console.log(logindetails);
    // console.log(orgId);
    // console.log(userID);
  }, []);

  const [madalVisible, setModalVisible] = useState(false);
  let navigate = useNavigate();
  const homePageHandler = () => {
    navigate("/home");
    history.push("/home");
  };
  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <IconsNotification />
      </Menu.Item>
    </Menu>
  );

  const menuu = (
    <Menu>
      <Menu.Item key={2}>
        <UserModal />
      </Menu.Item>
    </Menu>
  );

  // const showUserProfileModal = async () => {
  //   // setModalVisible(true);
  //  await getUsers(orgId);
  // };

  // const handleUserProfileCancel = () => {
  //   setModalVisible(false);
  // };

  // logout logic

  const handleLogout = () => {
    Showlogout({ onConfirm: () => onConfirm([]) });
  };

  const onConfirm = () => {
    onLogOut();
  };

  const onLogOut = () => {
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("userToken");
    window.localStorage.removeItem("refreshToken");
    navigate("/login");
    history.push("/login");
    document.location.reload(true);
    // message.success("Logout Succesfull");
    toast.success("Logout Succesfull..");
  };

  // const handleChatClick = () => {
  //   localStorage.setItem(
  //     "CognitoIdentityServiceProvider.3ejta5lbcaio3fmopca3o0tghh.49193d03-4a5e-4654-a19a-df1a27bffe61.clockDrift",
  //     "477"
  //   );
  //   localStorage.setItem(
  //     "CognitoIdentityServiceProvider.3ejta5lbcaio3fmopca3o0tghh.49193d03-4a5e-4654-a19a-df1a27bffe61.accessToken",
  //     "eyJraWQiOiI2b2x3a21tWStUR3ZIVUJVdWFqREo1UWNQbklmb0VKd0pzQmZEZlpwS2c4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0OTE5M2QwMy00YTVlLTQ2NTQtYTE5YS1kZjFhMjdiZmZlNjEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9MTVc5UGhBbTMiLCJjbGllbnRfaWQiOiIzZWp0YTVsYmNhaW8zZm1vcGNhM28wdGdoaCIsIm9yaWdpbl9qdGkiOiIyM2M3OTRlNi0yYjBhLTQ2YmUtYTdmZC04YWYxZTZiOGFjYjIiLCJldmVudF9pZCI6ImZmNzZmN2MxLWU5ZmUtNDljNC05MWZkLWIzZWZkNGRmNWUyYSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MTE0MzE3MjYsImV4cCI6MTcxMTQ0ODI3NiwiaWF0IjoxNzExNDQ0Njc2LCJqdGkiOiIxYzhjMDk5ZS02MDAxLTRkNDEtYmFlZC0yMWMwYmIzOWVjZWQiLCJ1c2VybmFtZSI6IjQ5MTkzZDAzLTRhNWUtNDY1NC1hMTlhLWRmMWEyN2JmZmU2MSJ9.cq0kl6o-gsc1UqTeKC0Qs4C1JqQnTe5S64MaMXtL1s06KJBf_TarSpQmYFSeZKZGtDTMbuj1pzCpPYu5i055LrNBSHs3tBhk1QUPpbp4uJnRw2kpzM1tRr7JZFaf3Lm6W1vQqn1dJU4K4qrhA2-QCCmmZF8aBwXAxAyi8jzWzHW3CyqipLmuN5uNpyU-WMdLUSAYmEXbJjlZ5emH_ArgQVn1PGYBGxVxjokSkUWBO_aioNsupt61OjPY17SfcH7Lexry1lvpHrXDNwBhjQmP_uQ35nsBjWq7FurqSJrEct7OqbZxvlhZGmyU9CLiaJbaALPqZYr1apKzSxrCaYb8ag"
  //   );
  //   localStorage.setItem(
  //     "CognitoIdentityServiceProvider.3ejta5lbcaio3fmopca3o0tghh.49193d03-4a5e-4654-a19a-df1a27bffe61.idToken",
  //     "eyJraWQiOiJGKzkxWGZQbDRxQkRxMkszaE1yUDV2NVFGYVJpVGVUUDFFbVZzdm1rMGdzPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0OTE5M2QwMy00YTVlLTQ2NTQtYTE5YS1kZjFhMjdiZmZlNjEiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfTE1XOVBoQW0zIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6IjQ5MTkzZDAzLTRhNWUtNDY1NC1hMTlhLWRmMWEyN2JmZmU2MSIsIm9yaWdpbl9qdGkiOiIyM2M3OTRlNi0yYjBhLTQ2YmUtYTdmZC04YWYxZTZiOGFjYjIiLCJhdWQiOiIzZWp0YTVsYmNhaW8zZm1vcGNhM28wdGdoaCIsImV2ZW50X2lkIjoiZmY3NmY3YzEtZTlmZS00OWM0LTkxZmQtYjNlZmQ0ZGY1ZTJhIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MTE0MzE3MjYsIm5hbWUiOiJSYWprdW1hciIsInBob25lX251bWJlciI6IisrOTE5Njc3MDA1MjYwIiwiZXhwIjoxNzExNDQ4Mjc2LCJpYXQiOjE3MTE0NDQ2NzYsImp0aSI6IjIxZjEwZDA3LWIzMGQtNDU1Yy04MmRjLTRiMzFkNGRjNjc2NiIsImVtYWlsIjoicmFqa3VtYXJAY29nbmlzaXRlLmluIn0.nEo5nbJ6xqu8JCnBI4y-_UVrGcGml9IXgD7rcTUx8fPMikis7Nf3CvXcnbqgYJflEIgERoRcJnkmR85xhBLQjHgQ5-Ll77drKqFmTmGCFadvDdtOq1ZKokjZ68GK5GyBZjuXViN-S9XovWoaapw1pzPP_2v7jxiXOqO5BUeoJCBqKYgYYlqi-C4vORnfjIvNK8LjUVrRHe6k322HjbNGjKHc_2fC77t6yoQ1mU4rmj5ctaB4EzU4LwgiXIKQV1RQQuHuNBbyrb5MMVkIDoqG_mPulorX1Wvd_6VkehdR9AO3hzOnqL6AkKAB4jIBKCH4-JPaWG_guCyRwTrH9676hg"
  //   );
  //   localStorage.setItem(
  //     "CognitoIdentityServiceProvider.3ejta5lbcaio3fmopca3o0tghh.49193d03-4a5e-4654-a19a-df1a27bffe61.refreshToken",
  //     "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.rdIZiWskkTMtV6LkBb53IXCKheAt26x9NumXudPZqwNjGwgPpjySDY2WT3QIRVHfCB--ekckxyUFqC9W6wkT544L5-A_TB3ZuP7tdaaFqBl5HavyowwLIvo8MEIiwyL-se-34rPu8wAWTONO4ylYQMSl2hFcA1lKqbltLh-TTQkgXMvHS-Fdo_BKi7Q8Q3YU49fqD_pLjipvG98QDi_Z9rTcT9bc_B_l2czH5NvPI4bMoOmBsbW4D9HLUFCruISPQdYApy2e6wLWHZjmhsM65IIdAnpwP2GcB3GEqgKCGFIKW7uKGxBBh-zWyJIm8zp4IWSsw97gdoCtOBhZhZUQkw.Do_x8btxPyiasEd5.bG3LagLrU7jQTktBsbqD0jyWnYm0zE0-_GgP2GFjoM63ukrvfSVMYREPpC-fvutbn5MN_44ECzxHePHvzQ_55JxF_BGvk1c2yLSxFTGDAz2uAbZJCGaTj7FKNptkBs3MySsxVL0Cfgq_2BBwMaV4HRVCyzLT0khmBfL0LlM-Y7D2YPywtWBv67F-ay3V3RrNyCMLvOnWlcAlyji3ToVmv3Q-ThUzvpl5_WS5TRATF_BgyHzbS9eS_J4iCbOIcjO93yk42Z2GcNzyaDlDLR0kus7LtHM1wHoTwrHyuVI_U-VOsXP-eJoQk9vGsR-8JL9dXydX9jqA0kE4EisUVPh3nu1jpIKmZq_mV7V4S9Z6thLP_s9aftkAmA8iNpCL__-uOUVfjr2ieK6BRgpz7xx8e7hIhNlM56sRIA-Zjy2Ado90Td4p4mnHcKMfUYeKN-JiKzNjigzDwwhzX9ZxxX6FmIQ4EDLtxCWN_QeVUlTWxLmcn_-XYMn6NFd95fZ7E5nPYgw3_rYZIHvFGPsr95DksmlIqzP-dii_wKIp5q_Lc5ewY5oOGNJCgd7xRmgcl3cVOJtcOlxcj4ph9HvumtiL3Njb5N1SI_s9h1vd9TM2FP0exxEiHoij5DIAx74mdlIAjN7qYpQ--lQsKG8gw8jFGaQ1FLtvicRyaioZTdkEjcHrS4ufYLcJgoDhvblB4jZw4QFtuHtYyQnF7X1gfQb-4RBsuK1uZEKlVo3014oOU4UQSWdXWCakWdsAVpo_clf2mpD9b6DWiITmtd2CAuNI6MzsqtRQogPUAMaywpXIDgkJbJJRGa5en56pSP5npe1SVocCV_vIvi0uI12OdSO6qkIuGszFw-oYyCUWI4AWaa2UeL26T_lCLUOJk7yr4B_fWVqxXfXhLlNwlNTEqIQ7VXvdxAw-ibWPt4bYm1wLxbXC8gXvdDCdhZkGxOm-cs86nJLSUn2_Jea6JQt9WlTDdswXqvb0vzm0NMLsqubMhAiQ8mpjeg70hGgbCBjPCc3FXb_88zFnzfUh9UC9vYcvyGI3_4uxg7oDcIMfUTKL6JvOfTuLanZdENRGfk8XEb0RcuMoXAyg-aWaXjoUq9THpNIFbfF4_sqarabF57AvshrU3BhHdYJjgoW-2DC9j5C4Wi58zF57hu0N9wHXIj_lG9wtP_SJ_L7r-1pRy1Nfr8etVofYUIOOC3zy7zxcPcOiw7XXw6yxFwUCyi_eoLY6bN5XiQcg1xgtrhXxcBhDlG5Aql7tyz_rOu8jgQJoSur3TlbSA6lVGmU6SIe-wo3ieXG0vuN6fdJmrejfNckxBMhj8smA49fdkaBQ5A.5iPW4ti5AqWTbB3qIRYGGw"
  //   );
  //   localStorage.setItem(
  //     "CognitoIdentityServiceProvider.3ejta5lbcaio3fmopca3o0tghh.LastAuthUser",
  //     "49193d03-4a5e-4654-a19a-df1a27bffe61"
  //   );

  //   window.open(
  //     "https://imagineview.com/kb-miner?title=KNOWLEDGE%20BASE%20MINER",
  //     "_blank"
  //   );
  // };

  return (
    <>
      {viewDocumentModal && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setViewDocumentModal(false)}
            className=" cursor-pointer absolute  top-16  right-24"
          />
          <iframe
            width="85%"
            height="75%"
            src="https://cognisite-cs-image-prod.s3.amazonaws.com/CONTRACTS.pdf"
            title="YouTube video player"
            frameborder="5"
          ></iframe>
        </div>
      )}

      <nav className="flex-no-wrap relative h-[90px] flex w-full items-center justify-between bg-[#F5F5F5] py-2  w-fit   lg:flex-wrap lg:justify-start lg:py-4">
        <div className="flex w-full flex-wrap items-center justify-between px-3">
          {/* <!-- Collapsible navigation container --> */}
          <div
            className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
            id="navbarSupportedContent12"
            data-te-collapse-item
          >
            {/* <!-- Left navigation links --> */}
            <div className=" flex flex-col ">
              <div className=" font-montserrat text-xl font-semibold flex text-black ">
                Welcome {logindetails?.user?.name}{" "}
                <span>
                  {/* <MdWavingHand className=" ml-2 text-[#FEDB5B]" size={22} /> */}
                </span>
              </div>
              <div className=" font-montserrat text-[#051A1A] text-sm ">
                {logindetails?.user?.role +
                  " at " +
                  logindetails?.organisation?.orgName}
              </div>
            </div>
          </div>

          {/* <!-- Right elements --> */}
          <div className="relative   w-fit mr-10   px-3 py-2 flex items-center">
            {/* ..... */}

            {/* ..... */}
            {/* <a
              // href="mailto:abc@gmail.com"
              onClick={handleChatClick}
              className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
            >
              <IoMdChatboxes
                size={22}
                className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
              />
            </a> */}
            <a
              // href="mailto:abc@gmail.com"
              onClick={handleOpenDocument}
              className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
            >
              <FaInfo
                size={22}
                className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
              />
            </a>
            <a
              href="mailto:support@cognisite.in"
              className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
            >
              <IoIosMail
                size={25}
                className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
              />
            </a>
            <a
              href="tel:+918035655959"
              className="p-2 rounded-full mx-2 w-fit bg-white flex justify-center items-center"
            >
              <IoIosCall
                size={25}
                className="hover:bg-top_nav_blue-5 rounded-md hover:text-richblack-50 text-richblack-400 cursor-pointer"
              />
            </a>
            <Link to={"/"}>
              <div className="p-2 rounded-full mr-2 w-fit bg-white flex justify-center items-center">
                <TiHomeOutline
                  size={25}
                  className="w-fit text-richblack-400 hover:bg-top_nav_blue-5 hover:text-richblack-50 rounded-md cursor-pointer"
                />
              </div>
            </Link>

            {/* <Link */}
            {/* // className="h-[50px] w-[50px] rounded-full  bg-white p-2 rounded-full  flex justify-center items-center "
            to={"/"}
          > */}

            {/* </Link> */}

            {/* <!-- Container with two dropdown menus --> */}
            <div
              className="relative "
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              {/* <!-- First dropdown trigger bell icon --> */}
              {/* <a
                className="hidden-arrow mr-4 text-white flex items-center rounded-md text-secondary-500 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-secondary-400 disabled:text-black/30 motion-reduce:transition-none"
                href="#"
                id="dropdownMenuButton1"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                
                <span className="[&gt;svg]:w-5 flex items-center justify-center">
                  <div className=" p-2 bg-white rounded-full">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-5 hover:bg-top_nav_blue-5 hover:text-richblack-50 rounded-md text-richblack-400 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </span>
             
                <span className="absolute -mt-4 ml-2.5 rounded-full bg-danger px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white">
                  10
                </span>
              </a> */}

              <ul
                className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base  dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton1"
                data-te-dropdown-menu-ref
              >
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Action
                  </a>
                </li>
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Another action
                  </a>
                </li>
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    Something else here
                  </a>
                </li>
              </ul>
            </div>

            {/* <!-- Second dropdown container --> */}
            <div
              className="relative  "
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              {/* <!-- Second dropdown trigger --> */}
              <a
                className="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
                href="#"
                id="dropdownMenuButton2"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                {/* <!-- User avatar --> */}

                <a
                  href="#"
                  class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                  data-te-toggle="tooltip"
                  data-te-html="true"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  title={`<div style="text-align: left;">ORG Name: ${logindetails?.organisation?.orgName}</div><div style="text-align: left;">Name: ${logindetails?.user?.name}</div><div style="text-align: left;">Email: ${logindetails?.user?.email}</div><div style="text-align: left;">Phone: ${logindetails?.user?.phone}</div><div style="text-align: left;">Role: ${logindetails?.user?.role}</div>`}
                ></a>
              </a>
            </div>
            <div class="inline-block h-[45px]  w-[1px] self-stretch bg-richblack-200 opacity-100 dark:opacity-50"></div>
            <div
              className="relative "
              data-te-dropdown-ref
              data-te-dropdown-alignment="end"
            >
              {/* <!-- First dropdown trigger --> */}
              <a
                className="hidden-arrow mr-4 ml-3 text-white flex items-center rounded-md text-secondary-500 transition duration-200 hover:text-secondary-400 hover:ease-in-out focus:text-secondary-400 disabled:text-black/30 motion-reduce:transition-none"
                href="#"
                id="dropdownMenuButton1"
                role="button"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                {/* <!-- Dropdown trigger icon --> */}

                {/* <img
                src={`https://api.dicebear.com/5.x/initials/svg?seed=${logindetails?.user?.name}`}
                className="rounded-full  border-richblack-5 border"
                style={{ height: "40px", width: "40px" }}
                alt=""
                loading="lazy"
              /> */}
                <div
                  className="rounded-full bg-top_nav_blue-500 text-white items-center justify-center flex font-semibold text-xl mb-1  border-richblack-5 border"
                  style={{ height: "40px", width: "40px" }}
                >
                  {logindetails?.user?.name
                    .split(" ")
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase()
                    .substring(0, 2)}
                </div>
              </a>
              {/* <!-- First dropdown menu --> */}
              <ul
                className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-2l border-none bg-white bg-clip-padding text-left text-base  dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton1"
                data-te-dropdown-menu-ref
              >
                {/* <!-- First dropdown menu items --> */}
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#"
                    data-te-dropdown-item-ref
                  >
                    <button
                      onClick={handleLogout}
                      type="button"
                      class="inline-block rounded-full border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                      data-te-ripple-init
                    >
                      Logout
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="h-[1.5px]  mt-2  w-full flex justify-center items-center  bg-richblack-200 border-0 "></hr>
      </nav>
    </>
  );
}

export default TopNavbar;

// {/* <Header className="site-layout-background" style={{ padding: 0 }}>
// <div className="main-div">
//   <div
//     className="menuicon"
//     style={{ display: "inline", float: "left" }}
//   >
//     {props.collapsed ? (
//       <>
//         <Space>
//           {/* <Tooltip title="Minimize"> */}
//           <MenuFoldOutlined onClick={props.toggle} />
//           {/* </Tooltip> */}
//           <span
//             style={{ fontSize: "13px", cursor: "pointer" }}
//             onClick={props.toggle}
//           >
//             Minimize
//           </span>
//         </Space>
//         &emsp;
//       </>
//     ) : (
//       ""
//     )}
//     <div
//       className={props.collapsed ? "" : "expand"}
//       style={{ display: "inline" }}
//     >
//       <Expand />
//     </div>
//   </div>
//   <span className="title__settings">
//     {props.name === "QCC"
//       ? "Quality Control Checklist"
//       : props.name === "Drawings"
//       ? "Documents"
//       : props.name === "Risk Management"
//       ? "Progress"
//       : props.name}
//   </span>

//   <div
//     className="home-app"
//     style={{ display: "inline", float: "right" }}
//   >
//     {logindetails.user.role === "SUPERADMIN" ? (
//       <>
//         <Tooltip title="Home">
//           <HomeOutlined onClick={homePageHandler} />
//         </Tooltip>
//       </>
//     ) : (
//       <>
//         <Dropdown overlay={menuu} overlayStyle={{ width: 310 }}>
//           <UserOutlined style={{ cursor: "pointer" }} />
//         </Dropdown>
//         <Tooltip title="Home">
//           <HomeOutlined onClick={homePageHandler} />
//         </Tooltip>

//         <Dropdown overlay={menu} overlayStyle={{ width: 250 }}>
//           <AppstoreOutlined style={{ cursor: "pointer" }} />
//         </Dropdown>
//       </>
//     )}
//   </div>
// </div>
// </Header> */}
