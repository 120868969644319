import "./css/AddWorkCategory.css";
import "antd/dist/antd.min.css";
import React from "react";
import EquipmentDeplouSummaryForm from "./Forms/EquipmentDeplouSummaryForm";

function EquipmentDeploymenetsummary() {
  return (
    <>
      <div className="work-category">
        <div className="sub-title">Equipment Deployment Summary</div>
        <br />
        <EquipmentDeplouSummaryForm />
      </div>
    </>
  );
}
export default React.memo(EquipmentDeploymenetsummary);
