import "./css/AddProject.css";
import "antd/dist/antd.min.css";
import React from "react";
import AddProjectForm from "./Forms/AddProjectForm";
import AddProjectDetailsForm from "./Forms/AddProjectDetailsForm";
import { useSelector } from "react-redux";
import { Spin } from "antd";

function AddProject({ showAddProject, setShowAddProject }) {
  const Spindata = useSelector((item) => item.spinreducer);
  // // -------------------------------UPLOAD DETAILS FROM ZIP FILE-----------------------------------------
  // const [formUploadDetails] = Form.useForm();

  // const onUploadDetailFinish = (values) => {
  //     console.log('Success:', values);
  // };

  // const onUploadDetailFinishFailed = (errorInfo) => {
  //     console.log('Failed:', errorInfo);
  // };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className=" ">
          {/* <div className="sub-title">Add Project</div>
          <br /> */}
          <AddProjectForm
            showAddProject={showAddProject}
            setShowAddProject={setShowAddProject}
          />
        </div>

        {/* -------------------------PROJECT DETAILS-------------------------------- */}
        {/* <div className="project-detail">

                    <div className="sub-title">Project Details</div>
                    <br />
                    <AddProjectDetailsForm />
                </div> */}

        {/* ---------------------UPLOAD DETAILS FROM ZIP FILE------------------------
            <div className="project-details">

                <div className="sub-title">Upload Details From Zip File</div>

                <Form
                    form={formUploadDetails}
                    name="upload-details-zip-file"
                    size="large"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onUploadDetailFinish}
                    onFinishFailed={onUploadDetailFinishFailed}
                //autoComplete="off"
                >

                    <Form.Item
                        name="upload"
                        label="Upload Project Details"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true }]}
                        style={{ width: '50%' }}
                    >
                        <Upload name="file" >
                            <Button icon={<UploadOutlined />} >Choose File</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <div className="btn-project">
                            <Button type="primary" htmlType="submit" className="btn-submit">
                                Upload
                            </Button>

                            <Button className="btn-cancel">Sample</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div> */}
      </Spin>
    </>
  );
}

export default React.memo(AddProject);
