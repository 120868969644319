import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Modal,
  InputNumber,
  Space,
  message,
  DatePicker,
  Card,
  Select,
} from "antd";
import AddCreateIntendForm from "./AddCreateIntendForm";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorAllWorkOrderDetails,
  selectorAllWorkOrderDocuments,
} from "../../../../redux-core/contracts/selectors";
import {
  selectorCountIndentNumber,
  selectorGetPurchaseOrder,
  selectorNewIndentMaterial,
} from "../../../../redux-core/materials/selectors";
import {
  getAllWorkOrders,
  getBOQSheetNames,
} from "../../../../redux-core/contracts/actions";
import {
  countIndentNumberForm,
  getAllPurchaseOrders,
  getCurrentStockProjectIdWise,
  newIndentMaterial,
} from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import moment from "moment";
import { getAllProj } from "../../../../redux-core/settings/actions";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getBOQSheetNames: (workorderId) => dispatch(getBOQSheetNames(workorderId)),
  getAllWorkOrders: (projectID) => dispatch(getAllWorkOrders(projectID)),
  getCurrentStockProjectIdWise: (projectID) =>
    dispatch(getCurrentStockProjectIdWise(projectID)),
  createIndentMaterial: (data) => dispatch(newIndentMaterial(data)),
  getAllPurchaseOrders: (projectID) =>
    dispatch(getAllPurchaseOrders(projectID)),
  countIndentNumberForm: (projectId, orgId) =>
    dispatch(countIndentNumberForm(projectId, orgId)),
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
});

const CreateIndentForm = (props) => {
  const {
    getAllWorkOrders,
    getBOQSheetNames,
    createIndentMaterial,
    getAllPurchaseOrders,
    countIndentNumberForm,
    projects,
  } = actionDispatch(useDispatch());
  const [form] = Form.useForm();
  const workOrderDetails = useSelector(selectorAllWorkOrderDetails);
  // States //
  const [showForm, setShowForm] = useState(false);
  const [storeIndent, setStoreIndent] = useState();
  const [sheetName, setSheetName] = useState();
  const [selectPurchaseDate, setselectPurchaseDate] = useState();
  const [sendResponse, setSendResponse] = useState();
  const [isDiable, setIsDisable] = useState(false);
  const [openPurchaseDate, setOpenPurchaseDate] = useState(false);
  // selectors //
  const getPurchaseOrder = useSelector(selectorGetPurchaseOrder);
  const createIndent = useSelector(selectorNewIndentMaterial);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const countIndentNumber = useSelector(selectorCountIndentNumber);
  const allProjectsSelectorData = useSelector(makeSelectAllProj);

  //console.log("allProjectsSelectorData",allProjectsSelectorData)
  console.log("countIndentNumber", countIndentNumber?.[0]?.no_of_Indent);

  const findProject = allProjectsSelectorData.find(
    (item) => item.project_id == props.projectID
  );
  //console.log("findProject",findProject?.project_name)

  const projectName = findProject?.project_name.substring(0, 5).toUpperCase();

  let count_number =
    countIndentNumber?.[0]?.no_of_Indent === 0
      ? "001"
      : (Number(countIndentNumber?.[0]?.no_of_Indent) + 1)
          .toString()
          .padStart(3, "0");
  console.log("count_number", count_number);

  const indent_code = "SM/" + projectName + "/" + "INDENT/" + count_number;
  console.log("indent_code", indent_code);

  //console.log("workOrderDetails",workOrderDetails.project)
  //console.log("allPurchaseOrder",getPurchaseOrder)

  useEffect(() => {
    getAllWorkOrders(props.projectID);
    getCurrentStockProjectIdWise(props.projectID);
    countIndentNumberForm(props.projectID, orgId);
    projects(orgId, userId);

    // form.setFieldsValue({
    //   last_purchase_order_date: moment(selectPurchaseDate?.po_date),
    //   indent_no: indent_code,
    // });
    // console.log("selectPurchaseDate?.po_date: ", selectPurchaseDate);
    setFormData({
      ...formData,
      indent_no: indent_code,
      last_purchase_order_date: moment(selectPurchaseDate?.po_date),
    });
  }, [props.projectID, selectPurchaseDate, indent_code]);

  const children = [];
  for (let i = 0; i < workOrderDetails.project.length; i++) {
    children.push(
      <option
        key={workOrderDetails.project[i].id}
        value={workOrderDetails.project[i].work_order_number}
      >
        {workOrderDetails.project[i].work_order_number}
      </option>
    );
  }

  const [formData, setFormData] = useState({
    indent_date: "",
    indent_no: "",
    last_purchase_order_date: "",
    work_order_no: "",
    last_purchase_order_no: "",
  });

  const onAddIndentFinish = async (e) => {
    e.preventDefault();

    let data = {
      ...formData,
      project_id: props.projectID,
      orgID: orgId,
      created_by: userId,
    };
    let response = await createIndentMaterial(data);
    if (response) {
      console.log("Res: ", response);
      toast.success("Success..");
      setShowForm(true);
      setIsDisable(true);
      setSendResponse(response?.materials);
      setStoreIndent(formData);
      setFormData({
        indent_date: "",
        work_order_no: "",
        last_purchase_order_no: "",
        last_purchase_order_date: "",
      });
    } else {
      toast.error("Error..");
    }

    //  //console.log("value", value)
  };

  const onAddIndentFinishFailed = (error) => {
    //console.log("failed", error)
  };

  const clear_Indent_field = () => {
    setShowForm(false);
    setIsDisable(false);
    setOpenPurchaseDate(false);
    countIndentNumberForm(props.projectID, orgId);
    form.setFieldsValue({
      indent_date: "",
      work_order_no: "",
      last_purchase_order_no: "",
      last_purchase_order_date: "",
    });
  };

  const backToAllProject = () => {
    props.backProject();
  };

  const handelSelectWorkOrder = (values) => {
    //console.log("values", values)
    setSheetName(values);
    getBOQSheetNames(values);
  };

  const handelPurchaseDate = (value) => {
    const purchaseDate = getPurchaseOrder?.materials.find(
      (items) => items.po_number == value
    );
    setselectPurchaseDate(purchaseDate);
    setOpenPurchaseDate(true);
  };

  // useEffect(() => {
  //   console.log("formData: ", formData);
  // }, [formData]);

  return (
    <>
      {/* <Button onClick={() => backToAllProject()}>All Projects</Button>
      <br /> <br />
      <div className="title-show-project">Indent of Material</div> <br /> */}
      {/* <Card hoverable>
        <Form
          form={form}
          name="add-project"
          size="large"
          initialValues={{
            remember: true,
          }}
          // onFocus={focusHandler}
          onFinish={onAddIndentFinish}
          onFinishFailed={onAddIndentFinishFailed}
        >
          <Form.Item
            name="indent_no"
            label="Indent No"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Indent No!",
              },
            ]}
            initialValue={indent_code}
          >
            <Input placeholder="Indent No" disabled />
          </Form.Item>
          <Form.Item
            name="indent_date"
            label="Date"
            className="firstRow"
            rules={[
              {
                required: true,
                message: "Please select Date !",
              },
            ]}
          >
            <DatePicker
              format="DD-MM-YYYY"
              //  disabledDate={disabledStartDate}
              // onChange={handleStartDateChange}
              disabled={isDiable}
            />
          </Form.Item>

          <Form.Item
            name="work_order_no"
            label="Work Order No"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Work Order No !",
              },
            ]}
          >
            <Select
              // placeholder="Select Work Order No"
              allowClear
              style={{ width: "100%" }}
              onChange={(work_order_number) =>
                handelSelectWorkOrder(work_order_number)
              }
              disabled={isDiable}
            >
      
 
              {children}
            </Select>
          </Form.Item>

          <Form.Item
            name="last_purchase_order_no"
            label="Last Purchase Order No"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please enter Last Purchase Order No !",
              },
            ]}
          >
            <Select
              // placeholder="Select Work Order No"
              allowClear
              style={{ width: "100%" }}
              onChange={(po_number) => handelPurchaseDate(po_number)}
              disabled={isDiable}
            >
             
              {getPurchaseOrder?.materials.map((items) => (
                <option key={items.id} value={items.po_number}>
                  {items.po_number}
                </option>
              ))}
            </Select>
          </Form.Item>
          {openPurchaseDate && (
            <Form.Item
              name="last_purchase_order_date"
              label="Last Purchase Order Date"
              className="firstRow"
              rules={[
                {
                  required: false,
                  message: "Please select Date !",
                },
              ]}
              initialValue={moment(selectPurchaseDate?.po_date)}
            >
              <DatePicker
                format="DD-MM-YYYY"
                //  disabledDate={disabledStartDate}
                // onChange={handleStartDateChange}
                style={{ width: "250%" }}
                disabled={isDiable}
              />
            </Form.Item>
          )}

          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                disabled={isDiable}
              >
                <strong>Submit</strong>
              </Button>

              <Button onClick={clear_Indent_field} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card> */}

      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col  ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="   font-montserrat">
            <button
              onClick={() => backToAllProject()}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              All Projects
            </button>
          </div>
          <div className=" font-semibold  text-2xl">Indent of Material</div>
          <div></div>
        </div>
      </div>
      <div className=" w-full  mt-7">
        {/* new form shivamkasyap 2 july 2024 */}
        <form
          className="font-montserrat flex flex-col items-center  gap-y-3"
          onSubmit={onAddIndentFinish}
        >
          {" "}
          {/* 1a  */}
          <div className="flex justify-evenly  w-[70vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="work_order_no"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Work Order No
              </label>
              <select
                required
                name="work_order_no"
                value={formData.work_order_no}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    work_order_no: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option disabled value="">
                  Select
                </option>
                {children.length &&
                  children.map((item) => (
                    <option value={item.props.value}>{item.props.value}</option>
                  ))}
              </select>
            </div>
            {/* 2  */}
            <div className="mt w-[300px]">
              <label
                htmlFor="last_purchase_order_no"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Last Purchase Order No
              </label>
              <select
                required
                name="last_purchase_order_no"
                value={formData.last_purchase_order_no}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    last_purchase_order_no: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option disabled value="">
                  Select
                </option>
                {getPurchaseOrder?.materials.map((items) => (
                  <option key={items.id} value={items.po_number}>
                    {items.po_number}
                  </option>
                ))}
              </select>
            </div>
            {/* 3 date */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="indent_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Date
              </label>
              <input
                required
                type="date"
                name="indent_date"
                value={formData.indent_date}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    indent_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
          </div>{" "}
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
            <button
              onClick={() => {
                setFormData({
                  indent_date: "",
                  work_order_no: "",
                  last_purchase_order_no: "",
                  last_purchase_order_date: "",
                });
              }}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>{" "}
        {showForm ? (
          <AddCreateIntendForm
            data={storeIndent}
            projectId={props.projectID}
            sendResponse={sendResponse}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default CreateIndentForm;
