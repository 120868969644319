// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-User{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
}

.sub__title{
    font-size: x-large;
    font-weight: bold;
    /* font-style: italic; */
}

@media screen and (max-width:600px){
    .add-User{
        box-shadow: none;
        padding: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/settings/contents/css/UserCreate.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI;QACI,gBAAgB;QAChB,YAAY;IAChB;AACJ","sourcesContent":[".add-User{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n}\n\n.sub__title{\n    font-size: x-large;\n    font-weight: bold;\n    /* font-style: italic; */\n}\n\n@media screen and (max-width:600px){\n    .add-User{\n        box-shadow: none;\n        padding: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
