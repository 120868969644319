import "./css/ListLabourDeployment.css";
import "antd/dist/antd.min.css";
import React from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import ListEquipmentDeploymentForm from "./Forms/ListEquipmentDeploymentForm";

function ListEquipmentDeployment() {
  const Spindata = useSelector((item) => item.spinreducer);
  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="labour-deployment">
          <div className="sub-title">List Equipment Deployment</div>
          <br />
          <ListEquipmentDeploymentForm />
        </div>
      </Spin>
    </>
  );
}

export default React.memo(ListEquipmentDeployment);
