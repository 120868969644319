import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { selectorGetAllReplacePoItems } from "../../../../redux-core/materials/selectors";

function PoReplaceItemsFrom() {
  const getAllReplacePoItems = useSelector(selectorGetAllReplacePoItems);

  const data = [];

  for (let i = 0; i < getAllReplacePoItems.length; i++) {
    data.push({
      id: i + 1,
      material_name: getAllReplacePoItems[i].material_name,
      grn_no: getAllReplacePoItems[i].grn_no,
      item_code: getAllReplacePoItems[i].item_code,
      reject_qty: getAllReplacePoItems[i].reject_qty,
      replace_qty: getAllReplacePoItems[i].replace_qty,
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "GRN No.",
      dataIndex: "grn_no",
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Reject Qty",
      dataIndex: "reject_qty",
    },
    {
      title: "Replace Qty",
      dataIndex: "replace_qty",
    },
  ];

  return (
    <>
      <div className="">
        {/* <Table
          scroll={{ x: "90vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        /> */}
        <div className=" font-semibold  text-2xl font-montserrat">
          View Replace Item
        </div>
        <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
              <div className="flex flex-col items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900 w-full">ID</p>
              </div>
              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  GRN No.
                </p>
              </div>
              <div className="flex flex-col items-start w-[350px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Name
                </p>
              </div>
              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Code
                </p>
              </div>
              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Reject Qty
                </p>
              </div>{" "}
              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Replace Qty
                </p>
              </div>
            </li>
          </ul>
          <ul
            role="list"
            className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
          >
            {data && data.length
              ? data.map((item, key) => (
                  <li
                    key={key}
                    className={`flex justify-between items-center px-5 py-2 ${
                      key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }`}
                  >
                    <div className="flex flex-col items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        ID
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        GRN No.
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[350px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        Item Name
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        Item Code
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        Reject Qty
                      </p>
                    </div>{" "}
                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        Replace Qty
                      </p>
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>
      </div>
    </>
  );
}

export default PoReplaceItemsFrom;
