import "antd/dist/antd.css";
import "./css/AddLabourRegisterForm.css";
import { Form, Input, Button, Select, Upload, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  selectorgetAllWorkState,
  selectorgetAllSubContractorState,
  selectorgetAllStateState,
} from "../../../../redux-core/resources/selectors";
import {
  createNewLabour,
  getAllcategory,
  getAllcontrator,
  getState,
} from "../../../../redux-core/resources/actions";
import BulkLabourRegister from "../BulkLabourRegister";
import { useState } from "react";

const { Option } = Select;
const actionDispatch = (dispatch) => ({
  getViewAllCategory: (orgId, userId) =>
    dispatch(getAllcategory(orgId, userId)),
  createLabour: (createlabourRagister) =>
    dispatch(createNewLabour(createlabourRagister)),
  getAllcontrator: (orgId, userId) => dispatch(getAllcontrator(orgId, userId)),
  getState: () => dispatch(getState()),
});

const AddLabourRegister = () => {
  const [form] = Form.useForm();
  const { getViewAllCategory, createLabour, getAllcontrator, getState } =
    actionDispatch(useDispatch());
  const AllWorkCategory = useSelector(selectorgetAllWorkState);
  const allState = useSelector(selectorgetAllStateState);
  const allSubContractor = useSelector(selectorgetAllSubContractorState);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);

  // console.log("first", allSubContractor)

  useEffect(() => {
    getViewAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    getAllcontrator(logindetails.organisation.orgID, logindetails.user.id);
    getState();
    // console.log(AllWorkCategory);
  }, []);

  const children = [];
  for (let i = 0; i < allState.length; i++) {
    children.push(
      <Option key={allState[i].id} value={allState[i].state}>
        {allState[i].state}
      </Option>
    );
  }

  const onAddlabourRagisterFinish = async (values) => {
    let data = {
      ...values,
      ...{
        orgID: logindetails.organisation.orgID,
        created_by: logindetails.user.id,
      },
    };
    if (data.image && data.image.length < 1) {
      delete data.image;
    }
    //   console.log("data", data)
    let response = await createLabour(data);
    if (response) {
      await clear_fields();
    }
    // console.log("success: " ,response)
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const normFiles = (e) => {
    // console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  const handlePreview = async (file) => {
    // console.log("file.preview",!file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewimage(file.url || file.preview);

    setPreviewvisible(true);
  };
  const handleCancel = () => setPreviewvisible(false);

  // Clear Fields

  const clear_fields = () => {
    form.setFieldsValue({
      labour_name: "",
      category_id: "",
      sub_contractor_id: "",
      city_town: "",
      state_id: "",
      contact: "",
      alter_contact: "",
      pan_no: "",
      aadhaar_no: "",
      commnication_address: "",
      image: [],
      labour_qual: "",
    });
  };

  // bulk  labour
  const [visible, setVisible] = useState(false);

  const showModalbuton = () => {
    setVisible(true);
  };
  const onOkButton = () => {
    setVisible(false);
  };
  const onCancele = () => {
    setVisible(false);
  };

  const [selectCategoryid, setSelectCategoryid] = useState(null);

  const handleWorkCategory = (categoryId) => {
    // console.log("first", categoryId)

    setSelectCategoryid(categoryId);
  };
  //   console.log("first", selectCategoryid)

  return (
    <>
      {/* <Button onClick={showModalbuton} className="btn-bulk">+ Bulk of Labour Register</Button> */}
      <Modal
        width="75%"
        onCancel={onCancele}
        onOk={onOkButton}
        visible={visible}
      >
        <BulkLabourRegister />
      </Modal>
      <Form
        form={form}
        name="add-labourReg"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddlabourRagisterFinish}
        onFinishFailed={(error) => {
          console.log({ error });
        }}
      >
        <Form.Item
          name="labour_name"
          label="Labour Name"
          rules={[
            { required: true, message: "Please enter Labour Name!" },
          ]}
          className="input-field"
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>

        <Form.Item
          name="category_id"
          label="Work Category Name"
          rules={[
            { required: true, message: "Please select Work Category Name!" },
          ]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            onChange={(categoryId) => handleWorkCategory(categoryId)}
            style={{ width: "100%" }}
            placeholder="Please select Work Category Name"
          >
            {AllWorkCategory.filter(
              (item) => item.resource_type === "Labour"
            ).map((getViewAllCategory) => (
              <option key={getViewAllCategory.id} value={getViewAllCategory.id}>
                {getViewAllCategory.category_name}
              </option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="sub_contractor_id"
          label="Vendor Name"
          rules={[{ required: true, message: "Please select Vendor Name!" }]}
          className="input-field"
        >
          <Select
            // mode="multiple"

            style={{ width: "100%" }}
            placeholder="Please select Vendor Name"
          >
            {allSubContractor
              .filter((item) => item.category_id == selectCategoryid)
              .map((item) => (
                <option key={item.id} value={item.id}>
                  {item.sub_contractor_name}
                </option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="city_town"
          label="City/Town"
          rules={[{ required: true, message: "Please enter City/Town!" }]}
          className="input-field"
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>

        <Form.Item
          name="state_id"
          label="State"
          rules={[{ required: true, message: "Please select State" }]}
          className="input-field"
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Select State"
          >
            {children}
          </Select>
        </Form.Item>

        <Form.Item
          name="contact"
          label="Contact No"
          rules={[
            { required: false, message: "Please enter Contact Number !" },
            {
              pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
              // pattern: /^[\d]{10}$/,
              // message: "Contact number should be 10 digits",
              message: "Please Enter Correct Contact Number",
            },
          ]}
          className="input-field"
        >
          <Input
            type="number"
            placeholder="Phone number"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="alter_contact"
          label="Alternate Contact"
          rules={[
            {
              required: false,
              message: "Please enter Alternate Contact !",
            },
            {
              pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
              // pattern: /^[\d]{10}$/,
              // message: "Contact number should be 10 digits",
              message: "Please Enter Correct Alternate Contact Number",
            },
          ]}
          className="input-field"
        >
          <Input
            type="number"
            placeholder="Phone number"
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="pan_no"
          label="PAN No"
          rules={[
            { required: false, message: "Enter PAN Number!" },
            {
              pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
              message: "Please enter Valid PAN Number!",
            },
          ]}
          className="input-field"
        >
          <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="image"
          label="Upload Labour Image"
          rules={[{ required: false, message: "Please  Upload Labour Image" }]}
          valuePropName="fileList"
          getValueFromEvent={normFiles}
          className="input-field"
        >
          {/* action="/upload.do" */}
          <Upload
            name="logo"
            listType="picture"
            accept=".jpg,.jpeg,.png"
            beforeUpload={(file, fileList) => {
              return false;
            }}
            onPreview={handlePreview}
            maxCount={"1"}
          >
            <Button icon={<UploadOutlined />}>Choosen File</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          className="input-field"
          name="aadhaar_no"
          label="Aadhar Card"
          rules={[
            {
              required: false,
              message: "Please enter Valid Adhar Card Number",
            },
          ]}
        >
          <Input onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="commnication_address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please enter Address!",
            },
          ]}
          className="input-field"
        >
          <Input.TextArea
            style={{ maxHeight: "70px", minHeight: "70px" }}
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="labour_qual"
          label="Qualification"
          rules={[{ required: false, message: "Please select Qualification" }]}
          className="input-field"
        >
          <Select
            allowClear
            style={{ width: "50%" }}
            placeholder="Select Qualification"
          >
            <option value="skilled">Skilled</option>
            <option value="unskilled">UnSkilled</option>
          </Select>
        </Form.Item>

        <Form.Item>
          <div className="btn-labour">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>
            <Button onClick={clear_fields} className="btn-cancel">
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default AddLabourRegister;
