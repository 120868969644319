import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import { Form, Button, message, Upload, Modal } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import Axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import "./css/BulkLabourRegisterForm.css";

function BulkLabourRegisterForm() {
  const [form] = Form.useForm();

  // upload Bulk of Sub contractor File or xlsx

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    accept: ".zip, .xlsx",
    maxCount: 1,
    showUploadList: {
      showDownloadIcon: false,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // Sample File Functionality

  const downloadExcelFile = () => {
    // Define the data to be exported
    const data = [
      [
        "Labour_name",
        "Work_Category_ID",
        "Sub_Contractor_ID",
        "Communication_Address",
        "City_Town",
        "State_id",
        "Contact",
        "Alternate_Contact",
        "PAN",
        "Aadhaar",
      ],
    ];

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the Excel file and trigger the download
    const excelFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelFile], { type: "application/octet-stream" });
    saveAs(blob, "labour_register.xlsx");
  };
  // Button bulk
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleBulkLabourreg = () => {
    setIsModalVisible(true);
  };
  return (
    <>
      <Button
        className="btn-template"
        icon={<DownloadOutlined />}
        onClick={handleBulkLabourreg}
      >
        Download Template
      </Button>

      <Modal
        title="Instructions"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <ul>
          <li>
            <p>Do not add / delete/ modify any columns</p>
          </li>
          <li>
            {" "}
            <p>Do not rename the excel file</p>
          </li>
          <li>
            <p>Do not attach any image files</p>
          </li>
          <li>
            {" "}
            <p>No macros & Pivot tables are allowed</p>
          </li>
        </ul>
        <Button onClick={downloadExcelFile} className="btn-ok">
          OK
        </Button>
      </Modal>
      <Form
        onFinish={onFinish}
        style={{ textAlign: "center" }}
        form={form}
        name="upload-file"
        size="large"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item name="file" label="" rules={[{ required: true }]}>
          <h5 style={{ fontFamily: "Arial", fontSize: "16px" }}>
            Bulk Of Labour Register
          </h5>
          <br></br>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Choose File</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Upload</strong>
            </Button>

            <Button onClick={downloadExcelFile} className="btn-cancel">
              <strong>Cancel</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
export default BulkLabourRegisterForm;
