import React from 'react';
import { Col, Row } from 'antd';
import Login from './Login'
import "./Login.css";

function Main(props) {
    return (
        <div>
            {/* <Row style={{width:'100%'}} className='row'> */}
                {/* <Col className='col-lg-8 col-sm-0'>
                <img src='coverPic.png' style={{ height:'100vh',width:'105%'}} alt='coverpage' />
                </Col> */}
                {/* <Col className='col-lg-4 col-sm-12'> */}
                <Login/>
                {/* </Col> */}
            {/* </Row> */}
        </div>
    );
}

export default Main;