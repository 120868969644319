import { ClockCircleOutlined, ContainerOutlined } from "@ant-design/icons";
import "./css/Dashboard.css";
import { useSelector } from "react-redux";
import { selectorCount } from "../../../redux-core/settings/selectors";
import { selectorCountDrawingState } from "../../../redux-core/drawing/selector";
import React, { useState } from "react";
import { Button } from "antd";
import ShowProjects from "../../settings/contents/ShowProjects";

function Dashboard() {
  const countData = useSelector(selectorCount);
  const countDrawing = useSelector(selectorCountDrawingState);
  // const [hideDashboard, setHideDashboard] = useState(true);
  // const [hideBackbutton, setHideBackbutton] = useState(false);
  // const [showProject, setShowProject] = useState(false);
  // const [showDrawing, setShowDrawing] = useState(false);

  // const handelBack = () => {
  //     setHideDashboard(true);
  //     setHideBackbutton(false);
  //     setShowProject(false);
  //   };
  //   const handelClickProject = () =>{
  //     setShowProject(true);
  //     setHideDashboard(false);
  // setHideBackbutton(true);
  //   }

  return (
    <>
      {/* {hideBackbutton && <Button type="primary" style={{borderRadius: "10px", width:"7%"}} onClick={() => handelBack()}>Back</Button>} */}
      {/* {hideDashboard && ( */}

      <div className="dashboard-root1">
        <div
          className="sub-main"
          //  onClick={() => handelClickProject()}
        >
          <div className="top">
            <span className="left__value">
              {countData.length > 0 && countData[1]?.no_of_projects}
            </span>
            <span className="right__icon">
              <ContainerOutlined />
            </span>
          </div>
          <div className="bottom__title">No Of Projects</div>
        </div>
        <div className="sub-main">
          <div className="top">
            <span className="left__value">
              {countDrawing.length > 0 && countDrawing[0]?.no_of_drawing}
            </span>
            <span className="right__icon">
              <ClockCircleOutlined />
            </span>
          </div>
          <div className="bottom__title">No Of Drawings</div>
        </div>
      </div>
      {/* )}
             {showProject && <ShowProjects />} */}
    </>
  );
}

export default React.memo(Dashboard);
