import React from 'react';
import ProjectList from './ProjectList';
import Progress from './Progress';
import QCC from './QCC';
import Material from './Material';
import Resources from './Resources';
import Contracts from './Contracts';
import './Dashboard.css';

class Dashboard extends React.Component {
  state = {
    currentPage: 'ProjectList',
    selectedProjectId: null,
    selectedContent: null,
  };

  render() {
    return (
      <div className="dashboard-container">
        {this.state.currentPage === 'ProjectList' && (
          <ProjectList onNavigate={this.handleNavigate} />
        )}
        {this.state.currentPage === 'DashboardImages' && (
          <div>
            <div className="image-container">
              <button
                onClick={() => this.openPage('ProjectList')}
                className="back-button"
              >
                Back to Projects
              </button>
              <img
                src="https://codejet-prod.s3.waw.io.cloud.ovh.net/1375009846666757477/1bd1d8a4-7a60-417e-adf6-6f24e8a5fb68/assets/png/desktop-1-background-ewtfw8.png"
                className="dashboard-image"
                onClick={() => this.setContent('Contracts')}
                alt="Dashboard 1"
              />
              <img
                src="https://codejet-prod.s3.waw.io.cloud.ovh.net/1375009846666757477/702fcf89-3483-4db1-8791-29936e6014c6/assets/png/desktop-1-background-18sa5it.png"
                className="dashboard-image"
                onClick={() => this.setContent('Progress')}
                alt="Dashboard 2"
              />
              <img
                src="https://codejet-prod.s3.waw.io.cloud.ovh.net/1375009846666757477/1bd1d8a4-7a60-417e-adf6-6f24e8a5fb68/assets/png/desktop-1-background-ewtfne.png"
                className="dashboard-image"
                onClick={() => this.setContent('QCC')}
                alt="Dashboard 3"
              />
              <img
                src="https://codejet-prod.s3.waw.io.cloud.ovh.net/1375009846666757477/1bd1d8a4-7a60-417e-adf6-6f24e8a5fb68/assets/png/desktop-1-background-ewtfkh.png"
                className="dashboard-image"
                onClick={() => this.setContent('Material')}
                alt="Dashboard 4"
              />
              <img
                src="https://codejet-prod.s3.waw.io.cloud.ovh.net/1375009846666757477/1bd1d8a4-7a60-417e-adf6-6f24e8a5fb68/assets/png/desktop-1-background-ewtfn5.png"
                className="dashboard-image"
                onClick={() => this.setContent('Resources')}
                alt="Dashboard 5"
              />
            </div>
            <div className="content-container">
              {this.renderContent()}
            </div>
          </div>
        )}
      </div>
    );
  }

  openPage = (pageName) => {
    this.setState({ currentPage: pageName, selectedContent: null });
  };

  setContent = (contentName) => {
    this.setState({ selectedContent: contentName });
  };

  handleNavigate = (pageName, projectId) => {
    this.setState({ currentPage: 'DashboardImages', selectedProjectId: projectId });
  };

  renderContent = () => {
    const { selectedContent, selectedProjectId } = this.state;

    switch (selectedContent) {
      case 'Progress':
        return <Progress projectId={selectedProjectId} />;
      case 'QCC':
        return <QCC projectId={selectedProjectId} />;
      case 'Material':
        return <Material projectId={selectedProjectId} />;
      case 'Resources':
        return <Resources projectId={selectedProjectId} />;
      case 'Contracts':
        return <Contracts projectId={selectedProjectId} />;
      default:
        return <div>Please select an image to view content.</div>;
    }
  };
}

export default Dashboard;
