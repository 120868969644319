import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Layout, Menu, Spin } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
// import './SideNavResources.css';
import "./SideNavRisk.css";
import "antd/dist/antd.min.css";
import MediaQuery from "react-responsive";

import Dashboard from "../contents/Dashboard";
import TopNavbar from "../../settings/topnavbar/TopNavbar";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import ShowMsprojectFile from "../contents/ShowMsprojectFile";
import UploadMsProject from "../../settings/contents/UploadMsProject";
import RequestForInspection from "../../qcc/content/RequestForInspection";
import ShowRfiTable from "../../qcc/content/Forms/ShowRfiTable";
import { useParams } from "react-router";

const EhsCreate = React.lazy(() => import("../contents/EhsCreate"));
const EhsShow = React.lazy(() => import("../contents/EhsShow"));
const CriticalCreate = React.lazy(() => import("../contents/CriticalCreate"));
const CriticalCreateShow = React.lazy(() =>
  import("../contents/CriticalCreateShow")
);
const { Sider, Content } = Layout;
const { SubMenu } = Menu;

function SideNavRisk() {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [content, setContent] = useState("3");
  const [val, setval] = useState("");

  const onMenuClick = useCallback((value) => {
    setContent(value);
  }, []);

  const [urlParams, setUrlParams] = useState({});
  const params = useParams();
  useEffect(() => {
    setUrlParams(params);

    // console.log("params: ", urlParams);
  }, [params]);

  return (
    <div className=" bg-[#F5F5F5]">
      {/* <Layout style={{ height: "100vh" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minWidth: "100px" }}
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => onMenuClick(e.key)}
          >
            <SubMenu
              key="sub1"
              icon={
                <img
                  src="Site_incharge.png"
                  className="icon_img"
                  alt="ehscreate"
                />
              }
              title="Status Monitor"
            >
              <Menu.Item key="2">Site Observation</Menu.Item>
              <Menu.Item key="3">Observation shows</Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub3"
              icon={
                <img
                  src="Supervisor_create.png"
                  className="icon_img"
                  alt="critical"
                />
              }
              title="Project Schedule"
            >
              <Menu.Item key="5">Upload Schedule</Menu.Item>
              <Menu.Item key="4">Schedule shows</Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub4"
              icon={
                <img
                  src="Supervisor_create.png"
                  className="icon_img"
                  alt="critical"
                />
              }
              title="RFI"
            >
              <Menu.Item key="6">Create RFI</Menu.Item>
              <Menu.Item key="7">Show RFI</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>

        <div>
          i am {urlParams.status} + content- {content}
        </div>

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger"
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt="logo"
                />
              )}
            </div>
          </MediaQuery>

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              overflow: "scroll",
            }}
          >
            {content === "1" ? <Dashboard /> : <></>}

            {content === "2" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <EhsCreate />
              </Suspense>
            ) : (
              <></>
            )}

            {content === "3" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <EhsShow />
              </Suspense>
            ) : (
              <></>
            )}

            {content === "4" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ShowMsprojectFile />
              </Suspense>
            ) : (
              <></>
            )}

            {content === "5" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <UploadMsProject />
              </Suspense>
            ) : (
              <></>
            )}

            {content === "6" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <RequestForInspection />
              </Suspense>
            ) : (
              <></>
            )}

            {content === "7" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ShowRfiTable />
              </Suspense>
            ) : (
              <></>
            )}
          </Content>
        </Layout>
      </Layout> */}

      {/* new ui shivam kashyap  */}
      {urlParams.status == "rfi" && (
        <div>
          <Suspense
            fallback={
              <Spin
                size="large"
                spinning={dataspin.spin}
                className="spin__component"
              />
            }
          >
            <ShowRfiTable />
          </Suspense>
        </div>
      )}
      {urlParams.status == "schedule" && (
        <div>
          <Suspense
            fallback={
              <Spin
                size="large"
                spinning={dataspin.spin}
                className="spin__component"
              />
            }
          >
            <ShowMsprojectFile />
          </Suspense>
          {/* <Suspense
            fallback={
              <Spin
                size="large"
                spinning={dataspin.spin}
                className="spin__component"
              />
            }
          >
            <UploadMsProject />
          </Suspense> */}
        </div>
      )}
      {urlParams.status == "status" && (
        <Suspense
          fallback={
            <Spin
              size="large"
              spinning={dataspin.spin}
              className="spin__component"
            />
          }
        >
          <EhsShow />
          {/* <EhsCreate /> */}
        </Suspense>
      )}
    </div>
  );
}

export default SideNavRisk;
