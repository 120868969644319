// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-file{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    /* width: 1500px;
    margin-left: 150px; */
  }
   .input-field1 {
      width: 95%;
      margin-right: 0;
      display: block;
    }
    .custom-upload:hover {
      /* Set properties to disable hover effect */
      /* For example, you might use pointer-events: none; */
      pointer-events: none;
    }
    

  @media screen and (max-width: 600px) {
    .input-field {
      width: 100%;
      margin-right: 0;
      display: block;
    }
    .add-file{
        box-shadow: 5px 10px 18px #534040;
        border-radius: 10px;
        padding: 20px;
        width: 1500px;
      }
  
    .btn-submit {
      width: 50%;
    }
  }
  
  @media screen and (max-width: 480px) {
    
    
    .btn-submit {
      width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/materials/content/css/WithOutPoInvoceCreateFrom.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB;yBACqB;EACvB;GACC;MACG,UAAU;MACV,eAAe;MACf,cAAc;IAChB;IACA;MACE,2CAA2C;MAC3C,qDAAqD;MACrD,oBAAoB;IACtB;;;EAGF;IACE;MACE,WAAW;MACX,eAAe;MACf,cAAc;IAChB;IACA;QACI,iCAAiC;QACjC,mBAAmB;QACnB,aAAa;QACb,aAAa;MACf;;IAEF;MACE,UAAU;IACZ;EACF;;EAEA;;;IAGE;MACE,WAAW;IACb;EACF","sourcesContent":[".add-file{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n    margin-top: 20px;\n    /* width: 1500px;\n    margin-left: 150px; */\n  }\n   .input-field1 {\n      width: 95%;\n      margin-right: 0;\n      display: block;\n    }\n    .custom-upload:hover {\n      /* Set properties to disable hover effect */\n      /* For example, you might use pointer-events: none; */\n      pointer-events: none;\n    }\n    \n\n  @media screen and (max-width: 600px) {\n    .input-field {\n      width: 100%;\n      margin-right: 0;\n      display: block;\n    }\n    .add-file{\n        box-shadow: 5px 10px 18px #534040;\n        border-radius: 10px;\n        padding: 20px;\n        width: 1500px;\n      }\n  \n    .btn-submit {\n      width: 50%;\n    }\n  }\n  \n  @media screen and (max-width: 480px) {\n    \n    \n    .btn-submit {\n      width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
