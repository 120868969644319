import { Button, DatePicker, Form, Input, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../content/css/RequestForInspection.css";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../redux-core/settings/actions";
import {
  getAllAssignProjectId,
  userDetails,
} from "../../../redux-core/userManagement/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import {
  selectorGetAllUserWiseAssignProjects,
  selectorUserDetail,
} from "../../../redux-core/userManagement/selectors";
import { getProjectIdByUsers } from "../../../redux-core/risk/actions";
import { selectorUsersProjectIdWiseState } from "../../../redux-core/risk/selectors";
import {
  countRfiDatas,
  createNewRfi,
  findStageDetailById,
  findTaskDetailById,
  getAllActivities,
} from "../../../redux-core/qcc/actions";
import {
  selectorActivityDetail,
  selectorCountRfiData,
  selectorStageDetailById,
  selectorTaskDetailById,
} from "../../../redux-core/qcc/selectors";
import { getFiles } from "../../../redux-core/drawing/action";
import { selectorGetUploadFileState } from "../../../redux-core/drawing/selector";
import { EyeOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projID, orgId, userId) =>
    dispatch(getAllProjDetails(projID, orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getProjectIdByUsers: (projectId, orgID) =>
    dispatch(getProjectIdByUsers(projectId, orgID)),
  getActivities: (orgId) => dispatch(getAllActivities(orgId)),
  getFiles: (orgId, userId) => dispatch(getFiles(orgId, userId)),
  createNewRfi: (rfiData) => dispatch(createNewRfi(rfiData)),
  countRfiDatas: (projectID, orgID) =>
    dispatch(countRfiDatas(projectID, orgID)),
  getUsers: (orgID) => dispatch(userDetails(orgID)),
  taskById: (projId, orgId) => dispatch(findTaskDetailById(projId, orgId)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
});
const { Option } = Select;
function RequestForInspection({ showCreateRfiModal, setShowCreateRfiModal }) {
  const Spindata = useSelector((item) => item.spinreducer);
  const [form] = Form.useForm();
  const {
    getAllProj,
    getAllAssignProjectId,
    getAllProjDetails,
    getProjectIdByUsers,
    getActivities,
    getFiles,
    createNewRfi,
    countRfiDatas,
    getUsers,
    taskById,
    getStageById,
  } = actionDispatch(useDispatch());
  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const getUserProjectWise = useSelector(selectorUsersProjectIdWiseState);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const allActvityData = useSelector(selectorActivityDetail);
  const getAllUploadFile = useSelector(selectorGetUploadFileState);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectDate, setSelectDate] = useState();
  const countRfiData = useSelector(selectorCountRfiData);
  const allUsersData = useSelector(selectorUserDetail);
  const taskDetailsData = useSelector(selectorTaskDetailById);
  const stageDetailsData = useSelector(selectorStageDetailById);

  // useEffect use For page rendering
  useEffect(() => {
    getAllProj(orgId, userId);
    getAllAssignProjectId(orgId, userId);
    getFiles(orgId, userId);
    getActivities(orgId);
    getUsers(orgId);
    taskById(orgId);
    console.log("allProjDetails------", allProjects);
    // console.log("allProjDetails date------", allProjects[0]);
  }, []);

  // push project id
  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  //user id wise assign project
  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });

  // select project Id Function
  const handelProjectId = (projectId) => {
    console.log("project id=========:", Number(projectId.target.value));
    let pId = Number(projectId.target.value);
    // let pId= projectData.filter((i) => i.project_name == projectId.target.value)[0].project_id;
    // console.log(
    //   "Pdata: ",
    //   projectData.filter((i) => i.project_name == projectId.target.value)[0].project_id
    // );

    // return;
    setSelectedProjectId(pId);
    getAllProjDetails(pId, orgId, userId);
    getProjectIdByUsers(pId, orgId);
    countRfiDatas(pId, orgId);
    // getStageById(orgId, formData.task_id);
    console.log("countRfiData: ", countRfiData);

    let RfiCode;
    const ProjectName = allProjects.find((item) => item?.project_id == pId);
    const projectShotName = ProjectName?.project_name.substring(0, 5);
    let countRfi =
      countRfiData?.[0]?.rfi_code_count == 0
        ? "001"
        : (Number(countRfiData?.[0]?.rfi_code_count) + 1)
            .toString()
            .padStart(3, "0");

    if (ProjectName?.project_name === undefined) {
      RfiCode = "";
    } else {
      RfiCode = projectShotName + "/" + "RFI" + "/" + countRfi;
    }
    // console.log("rfi code data: ", RfiCode, countRfi, countRfiData);
    setFormData({ ...formData, project_id: pId, rfi_code: RfiCode });
  };

  // form.setFieldsValue({
  //   rfi_code: RfiCode,
  // });

  // search function in select dropdown
  const handleSearch = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  //project wise Drwaing file
  const DrawingFile = getAllUploadFile.filter(
    (item) => item.project_id == selectedProjectId
  );

  //when select file then buttion activate
  const handleDrawingSelect = (value) => {
    setSelectedDrawing(value);
  };
  const handelCancel = () => {
    setIsOpenModal(false);
  };

  const HandelView = () => {
    setIsOpenModal(true);
  };

  const uploadButton = (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      onClick={HandelView}
    >
      <div
        style={{
          width: "40px", // Set the width
          height: "30px", // Set the height
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px dashed #ccc", // Add a dashed border
          borderRadius: "4px", // Rounded corners
          cursor: "pointer",
        }}
      >
        <EyeOutlined style={{ fontSize: "24px", color: "#aaa" }} />{" "}
        {/* Icon size and color */}
      </div>
    </div>
  );

  const HandelDateSelect = (value, dateString) => {
    setSelectDate(dateString);
  };

  // form submit function
  const HandelOnFinish = async (value) => {
    const data = {
      ...value,
      // rfi_date: selectDate,
      orgID: orgId,
      created_by: userId,
    };
    await createNewRfi(data);
    clear_field();
  };

  // clear field
  const clear_field = () => {
    form.setFieldsValue({
      project_id: "",
      project_detail_id: "",
      qc_engineer: "",
      activity_id: "",
      rfi_date: "",
      description: "",
      drawing_file: [],
      rfi_code: "",
    });
    setSelectedDrawing();
  };

  //find project name
  // const ProjectName = allProjects.find(
  //   (item) => item?.project_id == selectedProjectId
  // );
  // const projectShotName = ProjectName?.project_name.substring(0, 5);
  // let countRfi =
  //   countRfiData?.[0]?.rfi_code_count == 0
  //     ? "001"
  //     : (Number(countRfiData?.[0]?.rfi_code_count) + 1)
  //         .toString()
  //         .padStart(3, "0");
  // let RfiCode;
  // if (ProjectName?.project_name === undefined) {
  //   RfiCode = "";
  // } else {
  //   RfiCode = projectShotName + "/" + "RFI" + "/" + countRfi;
  // }

  //rfi code show in field
  // form.setFieldsValue({
  //   rfi_code: RfiCode,
  // });

  const qcEngineerList = allUsersData.filter(
    (item) => item.role === "QCENGINEER"
  );

  const qcEngineerUser = getUserProjectWise.filter((qcUser) =>
    qcEngineerList.some((engineer) => engineer.admin_id == qcUser.user_id)
  );

  //  selected task wise stages
  const HandelSelectTask = (value) => {
    // console.log("HandelSelectTask value: ", value);
    getStageById(orgId, value);
  };

  const [formData, setFormData] = useState({
    project_id: "",
    project_detail_id: "",
    qc_engineer: "",
    activity_id: "",
    rfi_date: "",
    description: "",
    drawing_file: [],
    rfi_code: "",
    created_by: "",
  });

  const submithandler = async (e) => {
    e.preventDefault();

    if (
      // formData.activity_id === "" ||
      formData.project_detail_id === "" ||
      formData.project_id === "" ||
      formData.qc_engineer === "" ||
      formData.rfi_code === "" ||
      formData.rfi_date === "" ||
      formData.task_id === ""
    ) {
      toast.error("Fill all required fields");
      return;
    }

    try {
      const data = {
        ...formData,
        created_by: logindetails.user.id,
        orgID: logindetails.organisation.orgID,
      };

      console.log("values before submission: ", data);

      let res = await createNewRfi(data);
      console.log("Rfi create res", res);

      // toast.success("RFI Created...");

      // Clear the form after successful submission
      setFormData({
        project_id: "",
        project_detail_id: "",
        qc_engineer: "",
        activity_id: "",
        rfi_date: "",
        description: "",
        drawing_file: [],
        rfi_code: "",
        created_by: "",
      });
      setShowCreateRfiModal(!showCreateRfiModal);
    } catch (error) {
      // Handle any errors here
      console.error("Error creating RFI:", error);
      // Optionally, you can show an error message to the user
      toast.error("An error occurred while creating RFI. Please try again.");
    }
  };

  const resetAllValue = () => {
    setFormData({
      project_id: "",
      project_detail_id: "",
      qc_engineer: "",
      activity_id: "",
      rfi_date: "",
      description: "",
      drawing_file: [],
      rfi_code: "",
      created_by: "",
    });
  };

  useEffect(() => {
    console.log("curr form data create rfi: ", formData);
    // console.log("logindetails: ", logindetails);
  }, [formData, setFormData]);

  return (
    <>
      {/* <Spin spinning={Spindata.spin} className=" bg-white" size={"large"}>
        <div className=" bg-white  border-l">
          <div className="title-show-project">Request For Inspection</div>
          <br />
          <br />
          <Form
            name="request_for_inspection"
            form={form}
            initialValues={{ remember: true }}
            onFinish={HandelOnFinish}
          >
            <Form.Item name="rfi_code" label="RFI Code" className="input-field">
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="project_id"
              label="Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Name !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                onChange={(projectId) => handelProjectId(projectId)}
                placeholder="Search or select Project"
              >
                {logindetails.user.role === "ADMIN"
                  ? allProjects.map((getAllProj) => (
                      <Option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </Option>
                    ))
                  : projectData.map((getAllProj) => (
                      <Option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="project_detail_id"
              label="Project Location"
              rules={[
                {
                  required: true,
                  message: "Please Select  Project Location !",
                },
              ]}
              className="input-field"
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Project Location"
              >
                {allProjDetails
                  .filter(
                    (getAllProjDetails) =>
                      getAllProjDetails.project_id === selectedProjectId
                  )
                  .map((getAllProjDetails) => (
                    <Option
                      key={getAllProjDetails.project_details_id}
                      value={getAllProjDetails.project_details_id}
                    >
                      {getAllProjDetails.block}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="qc_engineer"
              label="QC Engineer"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select QC Engineer !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select QC Engineer"
              >
                {qcEngineerUser.map((item) => (
                  <Option key={item.user_id} value={item.user_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="task_id"
              label="Task"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Task !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Task"
                onChange={HandelSelectTask}
              >
                {taskDetailsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.task}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="stage_id"
              label="Stage"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please select Stage  !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Stage"
              >
                {stageDetailsData.map((item) => (
                  <Option key={item.stage_id} value={item.stage_id}>
                    {item.stage_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="rfi_date"
              label="Date"
              className="firstRow1"
              rules={[
                {
                  required: true,
                  message: "Please Select Date !",
                },
              ]}
            >
              <DatePicker onChange={HandelDateSelect} format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              name="drawing_file"
              label="Drawing"
              className="secondRow1"
              rules={[
                {
                  required: false,
                  message: "Please Select  Drawing File !",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                filterOption={handleSearch}
                placeholder="Search or select Drawing File "
                onSelect={handleDrawingSelect}
              >
                {DrawingFile.map((item) => (
                  <Option key={item.id} value={item.file}>
                    {item.file_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item className="thirdRow1">
              {selectedDrawing && uploadButton}
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="input-field"
              rules={[
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 charactersonly",
                },
              ]}
            >
              <Input.TextArea
                style={{ maxHeight: "130px", minHeight: "100px" }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-ehs">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_field} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <Modal
          title="Drawing Preview"
          visible={isOpenModal}
          footer={null}
          width={1500}
          onCancel={handelCancel}
        >
          <iframe
            className={"application/pdf"}
            width="100%"
            height="700"
            frameborder="10"
            src={selectedDrawing}
          />
        </Modal>
      </Spin> */}

      {/* ntw form shivam kashyap 22 april 2024 */}

      <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
        <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
          Create New RFI
        </div>

        {/* .... */}

        <form className="font-montserrat" onSubmit={submithandler}>
          {/* 1a */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Description
              </label>
              <div className="mt- w-[300px]">
                <input
                  // required
                  type="text"
                  name="description"
                  // value={formData.description}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      description: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="project_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Project
              </label>
              <select
                required
                name="project_id"
                // value={formData.project_id}
                // onChange={handleInputChange}
                onChange={(projectId) => handelProjectId(projectId)}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>
                {logindetails.user.role === "ADMIN"
                  ? allProjects.map((getAllProj) => (
                      <option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </option>
                    ))
                  : projectData.map((getAllProj) => (
                      <option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </option>
                    ))}
              </select>
            </div>
            {/* 3  */}
            <div className="mt w-[300px]">
              <label
                htmlFor="project_detail_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Location
              </label>
              <select
                required
                name="project_detail_id"
                // value={formData.project_id}
                // onChange={handleInputChange}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    project_detail_id: e.target.value,
                  })
                }
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>

                {allProjDetails
                  .filter(
                    (getAllProjDetails) =>
                      getAllProjDetails.project_id === formData.project_id
                  )
                  .map((getAllProjDetails) => (
                    <option
                      key={getAllProjDetails.project_details_id}
                      value={getAllProjDetails.project_details_id}
                    >
                      {getAllProjDetails.block}
                    </option>
                  ))}

                {/* {logindetails.user.role === "ADMIN"
                  ? allProjects.map((getAllProj) => (
                      <option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </option>
                    ))
                  : projectData.map((getAllProj) => (
                      <option
                        key={getAllProj.project_id}
                        value={getAllProj.project_id}
                      >
                        {getAllProj.project_name}
                      </option>
                    ))} */}
              </select>
            </div>
          </div>
          {/* ---------------------------------- */}
          {/* 2a */}
          <div className="flex my-3 justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="qc_engineer"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                QCC Engineer
              </label>
              <select
                required
                name="qc_engineer"
                // value={formData.project_id}
                // onChange={handleInputChange}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    qc_engineer: e.target.value,
                  })
                }
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>

                {qcEngineerUser.map((item) => (
                  <option key={item.user_id} value={item.user_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {/* 2 */}

            <div className="mt w-[300px]">
              <label
                htmlFor="task_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Task
              </label>
              <select
                required
                name="task_id"
                // value={formData.task_id}

                onChange={(e) => {
                  setFormData({
                    ...formData,
                    task_id: e.target.value,
                  });

                  HandelSelectTask(e.target.value);
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>

                {taskDetailsData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.task}
                  </option>
                ))}
              </select>
            </div>

            {/* 3 date */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="rfi_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                RFI Date
              </label>
              <input
                required
                type="date"
                name="rfi_date"
                // value={formData.completion_date}
                // onChange={handleInputChange}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    rfi_date: e.target.value,
                  })
                }
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
          </div>
          {/* ---------------------------- */}
          {/* 3a */}
          <div className="flex my-3 justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="stage_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Stage
              </label>
              <select
                required
                name="stage_id"
                // value={formData.task_id}

                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stage_id: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>

                {stageDetailsData.map((item) => (
                  <option key={item.stage_id} value={item.stage_id}>
                    {item.stage_name}
                  </option>
                ))}
              </select>
            </div>
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="stage_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Drawing
              </label>
              <select
                // required
                name="drawing_file"
                // value={formData.task_id}

                onChange={(e) => {
                  setFormData({
                    ...formData,
                    drawing_file: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>

                {DrawingFile.map((item) => (
                  <option key={item.id} value={item.file}>
                    {item.file_name}
                  </option>
                ))}
              </select>
            </div>
            {/* 3  */}
            <div className=" opacity-0">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Description
              </label>
              <div className="mt- w-[300px]">
                <input
                  // required
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      description: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
          </div>

          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
            <button
              onClick={resetAllValue}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
        {/* .......... */}
      </div>
    </>
  );
}

export default RequestForInspection;
