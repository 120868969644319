import { Button, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectorgetAllGrnItemCodeWise,
  selectorGetItemCodeWiseRecivingProcess,
  selectorGetMaterialManagementData,
} from "../../../../redux-core/materials/selectors";
import { formatDate } from "../../../../utils/functions";

const { Option } = Select;
function ShowItemsWiseStockQtyFrom() {
  const getGrnItemCodeWise = useSelector(selectorgetAllGrnItemCodeWise);
  const getItemRecivingProcess = useSelector(
    selectorGetItemCodeWiseRecivingProcess
  );
  const [array, setArray] = useState("");
  const getMaterialManagementData = useSelector(
    selectorGetMaterialManagementData
  );

  const data = [];

  for (let i = 0; i < getItemRecivingProcess.length; i++) {
    data.push({
      item_code: getItemRecivingProcess[i].item_code,
      Item_name: getItemRecivingProcess[i].Item_name,
      accept_qty: getItemRecivingProcess[i].accept_qty,
      rcv_qty: getItemRecivingProcess[i].rcv_qty,
      issue_qty: getItemRecivingProcess[i].issue_qty,
      replace_qty: getItemRecivingProcess[i].replace_qty,
      site_transfer_qty: getItemRecivingProcess[i].site_transfer_qty,
      transfer_to_other_site: getItemRecivingProcess[i].transfer_to_other_site,
      without_po_qty: getItemRecivingProcess[i].without_po_qty,
      total_qty: getItemRecivingProcess[i].total_qty,
    });
  }

  //---------write the function for item code wise comes data  sum value shows--
  const tableData = [];
  let rcvQtySum = 0;
  let accQtySum = 0;
  for (let i = 0; i < getGrnItemCodeWise.length; i++) {
    const item = getGrnItemCodeWise[i];
    const grnDateObject = new Date(item.grn_date);
    tableData.push({
      index: i + 1,
      item_code: item.item_code,
      material_name: item.material_name,
      po_number: item.po_number,
      po_date: item.po_date,
      grn_no: item.grn_no,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      rcv_qty: item.rcv_qty,
      accept_qty: item.accept_qty,
    });
    rcvQtySum += item.rcv_qty;
    accQtySum += item.accept_qty;
  }

  // Add sum row
  tableData.push({
    index: "Total",
    rcv_qty: rcvQtySum,
    accept_qty: accQtySum,
  });

  // table colunm
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "index",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
    },
    {
      title: "PO Number",
      dataIndex: "po_number",
    },
    {
      title: "PO Date",
      dataIndex: "po_date",
    },
    {
      title: "GRN NO.",
      dataIndex: "grn_no",
    },
    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Quantity Received",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quantity Accepted",
      dataIndex: "accept_qty",
    },
  ];

  const columns2 = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Material Name",
      dataIndex: "Item_name",
    },
    {
      title: "Receiving Quantity",
      dataIndex: "rcv_qty",
    },
    {
      title: "Accept Quantity",
      dataIndex: "accept_qty",
    },
    {
      title: "Issue Quantity",
      dataIndex: "issue_qty",
    },
    {
      title: "Local Purchase Receiving Quantity",
      dataIndex: "without_po_qty",
    },
    {
      title: "Replace Quantity",
      dataIndex: "replace_qty",
    },
    {
      title: "Other Site Receiving Quantity",
      dataIndex: "site_transfer_qty",
    },
    {
      title: "Other Site Transfer Quantity",
      dataIndex: "transfer_to_other_site",
    },
    {
      title: "Total Balance Quantity",
      dataIndex: "total_qty",
    },
  ];

  const columns3 = [
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
    },
    {
      title: "Po Number",
      dataIndex: "po_number",
    },
    {
      title: "GRN Number",
      dataIndex: "grn_no",
    },
    {
      title: "Receive Quantity",
      dataIndex: "rcv_qty",
    },
    {
      title: "Local Purchase Invoice Number",
      dataIndex: "invoice_number",
    },
    {
      title: "Local Purchase QTY",
      dataIndex: "qty",
    },
    {
      title: "Replace Item GRN Number ",
      dataIndex: "replace_grn_no",
    },
    {
      title: "Replace Item GRN Qty ",
      dataIndex: "replace_qty",
    },
    {
      title: "Site Transfer Number ",
      dataIndex: "transfer_number",
    },
    {
      title: "Site Transfer QTY ",
      dataIndex: "transfer_qty",
    },
  ];

  const [hideTable, setHideTable] = useState(false);
  const [cardType, setCardType] = useState("");
  const handleCardTypeChange = (value) => {
    setCardType(value);
    setHideTable(true);
  };

  // useEffect(() => {
  //   console.log("data: ", data);
  // }, [data]);

  return (
    <>
      <div className=" flex w-full  justify-between px-4">
        {" "}
        <div className=" font-semibold  mb-4 text-2xl font-montserrat">
          Item Receipts Statement
        </div>{" "}
        <div className="mt w-[300px] font-montserrat">
          <label
            htmlFor=""
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            <span className="text-red-600">*</span>
            Select The Process
          </label>
          <select
            // required
            name=""
            placeholder="Select the process"
            // value={formData.po_status}
            onChange={(e) => {
              handleCardTypeChange(e.target.value);
            }}
            className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
          >
            <option defaultChecked value="">
              Select
            </option>{" "}
            <option value="Material_manegment">Material Management Data</option>
            <option value="Item_History">Po wise Item history</option>
            <option value="Other_material_received">
              Other Process Material Received QTY
            </option>
          </select>
        </div>
      </div>
      {/* 
      <Select
        onChange={handleCardTypeChange}
        className="input-field"
        placeholder="Select The Process"
      >
        <Option value="Material_manegment">Material Management Data</Option>
        <Option value="Item_History">Po wise Item history</Option>
        <Option value="Other_material_received">
          Other Process Material Received QTY
        </Option>
      </Select> */}

      {cardType === "Item_History" && (
        <div className=" m-0 p-0">
          <div className=" font-semibold  mb-4 text-2xl font-montserrat ml-6">
            List of PO Wise Item History
          </div>{" "}
          {/* <Table
            className="custom-table"
            dataSource={Array.isArray(array) ? array : tableData}
            columns={columns}
            pagination={10}
            scroll={{ x: "80vw" }}
            bordered
            size="large"
          /> */}
          {/* new table shivam kashyap 25 june 2024  */}
          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Sl No.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Item Name
                  </p>

                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Item Code
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    PO Number
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    PO Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN Number
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    GRN Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Quantity Received
                  </p>{" "}
                </div>{" "}
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Quantity Accepted
                  </p>{" "}
                </div>
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
            >
              {tableData && tableData.length && tableData[0] != null
                ? tableData.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.index ? item.index : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.material_name ? item.material_name : "-"}
                        </p>

                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.item_code ? item.item_code : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_number ? item.po_number : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.po_date ? formatDate(item.po_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_no ? item.grn_no : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.grn_date ? formatDate(item.grn_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.rcv_qty ? item.rcv_qty : "-"}
                        </p>{" "}
                      </div>{" "}
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.accept_qty ? item.accept_qty : "-"}
                        </p>{" "}
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>{" "}
        </div>
      )}

      {cardType === "Other_material_received" && (
        <div className=" m-0 p-0">
          <div className=" font-semibold  mb-4 text-2xl font-montserrat ml-6">
            List of Other Process Material Received QTY
          </div>{" "}
          {/* <Table
            className="custom-table"
            dataSource={data}
            columns={columns2}
            pagination={10}
            scroll={{ x: "80vw" }}
            bordered
            size="large"
          /> */}
          {/* new table shivam kashyap 25 june 2024  */}
          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Material Name
                  </p>

                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Material Code
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Receiving Quantity
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Local Purchase Receiving Quantity
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Other Site Receiving Quantity
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Accept Quantity
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Replace Quantity
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Issue Quantity
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Other Site Transfer Quantity
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Total Balance Quantity
                  </p>
                </div>
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
            >
              {data && data.length && data[0] != null
                ? data.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Material Name
                        </p>

                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Material Code
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Receiving Quantity
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Local Purchase Receiving Quantity
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Other Site Receiving Quantity
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Accept Quantity
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Replace Quantity
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Issue Quantity
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Other Site Transfer Quantity
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Total Balance Quantity
                        </p>
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>{" "}
        </div>
      )}

      {cardType === "Material_manegment" && (
        <div className=" m-0 p-0">
          <div className=" font-semibold  mb-4 text-2xl font-montserrat ml-6">
            List of Material Management Data
          </div>{" "}
          {/* <Table
            className="custom-table"
            dataSource={getMaterialManagementData}
            columns={columns3}
            pagination={10}
            scroll={{ x: "80vw" }}
            bordered
            size="large"
          /> */}
          {/* new table shivam kashyap 25 june 2024  */}
          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Item Name
                  </p>

                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Item Code
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    PO Number
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Received Quantity
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    GRN Number Accepted
                  </p>{" "}
                </div>
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Local Purchase QTY
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Local Purchase Invoice Number
                  </p>{" "}
                </div>{" "}
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Replace Item GRN Qty
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Replace Item GRN Number{/*  */}
                  </p>{" "}
                </div>{" "}
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Site Transfer QTY
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Site Transfer Number
                  </p>{" "}
                </div>{" "}
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
            >
              {getMaterialManagementData &&
              getMaterialManagementData.length &&
              getMaterialManagementData[0] != null
                ? getMaterialManagementData.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Item Name
                        </p>

                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Item Code
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          PO Number
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Received Quantity
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          GRN Number Accepted
                        </p>{" "}
                      </div>
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Local Purchase QTY
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Local Purchase Invoice Number
                        </p>{" "}
                      </div>{" "}
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Replace Item GRN Qty
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Replace Item GRN Number{/*  */}
                        </p>{" "}
                      </div>{" "}
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          Site Transfer QTY
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          Site Transfer Number
                        </p>{" "}
                      </div>{" "}
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>{" "}
        </div>
      )}
    </>
  );
}

export default ShowItemsWiseStockQtyFrom;
