import "./css/ShowProject.css";
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Image,
  Modal,
  Upload,
  message,
  Spin,
  DatePicker,
  Tooltip,
  InputNumber,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  EyeOutlined,
  PrinterOutlined,
  FileDoneOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  countAction,
  deleteByProjId,
  getAllProj,
  updateByProjId,
} from "../../../redux-core/settings/actions";
import { redirectAction } from "../../../redux-core/login/actions";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { ShowDeleteConfirm } from "../../popupmodal";
import { PlusOutlined } from "@ant-design/icons";
import ShowProjectDetails from "./ShowProjectDetails";
import TypesOfProjectTable from "./TypesOfProjectTable";
import moment from "moment";
import AddProjectDetails from "./AddProjectDetails";
import { IoIosAdd, IoMdClose } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import { LuClipboardType } from "react-icons/lu";
import { FaEye, FaFileUpload } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import AddProject from "./AddProject";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  delProjById: (id) => dispatch(deleteByProjId(id)),
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  updateProj: (newProj) => dispatch(updateByProjId(newProj)),
  count: (orgId) => dispatch(countAction(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const ShowProjects = () => {
  const getColumnSearchProps = useSearch();
  const [selectedKeys, setSelectedKeys] = useState();
  const [projRow, setProjRow] = useState({});
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isdetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isTypesModalVisible, setIsTypesModalVisible] = useState(false);

  const { delProjById, projects, updateProj, count } = actionDispatch(
    useDispatch()
  );
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const logindetails = useSelector(makeSelectLoginDetail);
  const [imagecard, setImagecard] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const [activedata, setActivedata] = useState(true);
  const [passedProjectId, setPassedProjectId] = useState("");
  const [typesofProject, setTypesOfProject] = useState("");
  const Spindata = useSelector((item) => item.spinreducer);
  const [storeActualStartDate, setStoreActualStartDate] = useState();
  const [openProjectDetail, setOpenProjectDetail] = useState(false);
  const [passProjectId, setPassPojectId] = useState();
  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
  }, []);
  // modal create
  const [visible, setVisible] = useState(false);
  const [showAddProject, setShowAddProject] = useState(false);

  const handleFinish = (values) => {
    setVisible(false);
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleHideModal = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "sl_no",
      width: "5%",
      key: "sl_no",
    },
    // {
    //   title: "Project ID",
    //   dataIndex: "project_id",
    //   ...getColumnSearchProps("project_id"),
    //   // width:"5%",
    // },
    {
      title: "Project Name",
      dataIndex: "project_name",
      ...getColumnSearchProps("project_name"),
      // width: "15%",
    },
    {
      title: "Project Code",
      dataIndex: "project_code",
      ...getColumnSearchProps("project_code"),
      // width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      // width: "20%",
    },
    // {
    //   title: "Paying Company",
    //   dataIndex: "paying_company",
    //   ...getColumnSearchProps("paying_company"),
    //   // width: "10%",
    // },
    {
      title: "Project Start Date",
      dataIndex: "project_start_date",
      ...getColumnSearchProps("project_start_date"),
      // width: "10%",
    },
    {
      title: "Project End Date",
      dataIndex: "project_end_date",
      ...getColumnSearchProps("project_end_date"),
      // width: "10%",
    },
    {
      title: "Actual Start Date",
      dataIndex: "actual_start_date",
      ...getColumnSearchProps("actual_start_date"),
      // width: "10%",
    },
    {
      title: "Actual End Date",
      dataIndex: "actual_end_date",
      ...getColumnSearchProps("actual_end_date"),
      // width: "10%",
    },
    // {
    //   title: "Budget",
    //   dataIndex: "original_budget",
    //   ...getColumnSearchProps("original_budget"),
    //   // width: "10%",
    // },
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <>
          {" "}
          <Image src={text} style={{ width: "20px", height: "20px" }} />{" "}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "15%",
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Show Types of Project">
              <Button onClick={() => showTypesModal(record)}>
                <FileDoneOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Show Project Details">
              <Button onClick={() => showDetailModal(record)}>
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="add project location">
              <Button onClick={() => openProjectDetailModal(record)}>
                <FormOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Edit">
              <Button onClick={() => showModal(record)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                onClick={() => deleteRow(record)}
                style={{ marginLeft: "5px" }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const openProjectDetailModal = (data2) => {
    setOpenProjectDetail(true);
    setPassPojectId(data2.project_id);
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: onSelectChange,
  };

  const [editprojectFormData, setEditProjectFormData] = useState({
    project_name: "",
    description: "",
    actual_start_date: "",
    actual_end_date: "",
    original_budget: "",
  });
  const [imageFile, setImageFile] = useState([]);
  //---------------------- modal and forms ----------------

  const onEditProjectFinish = async (e) => {
    e.preventDefault();
    let data = {
      // project_name: values.project_name,
      // description: values.description,
      // actual_start_date: values.actual_start_date
      //   ? moment(values.actual_start_date).format("YYYY-MM-DD")
      //   : null,
      // actual_end_date: values.actual_end_date
      //   ? moment(values.actual_end_date).format("YYYY-MM-DD")
      //   : null,
      // original_budget: values.original_budget,
      ...editprojectFormData,
      ...imageFile,
      orgID: logindetails.organisation.orgID,
      created_by: logindetails.user.id,
      project_id: projRow.project_id,
    };

    if (!data.actual_end_date) {
      delete data.actual_end_date;
    }
    if (!data.actual_start_date) {
      delete data.actual_start_date;
    }
    // if (values.image && values.image.length < 1) {
    //   delete data.image;
    // } else {
    //   data.image = values.image[0].size
    //     ? values.image
    //     : values.image[0].thumbUrl;
    // }
    console.log("Edit project data beforwe submission: ", data);
    // return;
    try {
      let response = await updateProj(data);
      if (response.success) {
        setEditProjectFormData({
          project_name: "",
          description: "",
          actual_start_date: "",
          actual_end_date: "",
          original_budget: "",
        });
        console.log("Edit project nes: ", response);
        toast.success("Success..");
        await setProjRow(data);
        await setIsModalVisible(false);
        await projects(logindetails.organisation.orgID, logindetails.user.id);
      }
    } catch (err) {
      console.log("Error while editing project: ", err);
      toast.error("Failed..");
    }
  };

  const onEditProjectFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_Project_field = () => {
    form.setFieldsValue({
      project_name: projRow.project_name,
      actual_start_date:
        projRow.actual_start_date === null
          ? ""
          : moment(projRow.actual_start_date),
      actual_end_date:
        projRow.actual_end_date === null ? "" : moment(projRow.actual_end_date),
      image: projRow.image ? [{ thumbUrl: projRow.image }] : [],
      description: projRow.description,
      original_budget: projRow.original_budget,
    });
    {
      projRow.image == null ||
      projRow.image === undefined ||
      projRow.image === ""
        ? setImagecard(false)
        : setImagecard(true);
    }
  };

  const normFile = (e) => {
    if (e.fileList.length > 0) setImagecard(true);
    else setImagecard(false);
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const showModal = (data1) => {
    setProjRow(data1);
    setIsModalVisible(true);
    setStoreActualStartDate(moment(data1.actual_start_date));
    form.setFieldsValue({
      project_name: data1.project_name,
      description: data1.description,
      original_budget: data1.original_budget,
      //paying_company: data1.paying_company,
      actual_start_date:
        data1.actual_start_date === null
          ? ""
          : moment(data1.actual_start_date, "DD-MM-YYYY"),
      actual_end_date:
        data1.actual_end_date === null
          ? ""
          : moment(data1.actual_end_date, "DD-MM-YYYY"),
      // original_budget: data1.original_budget,
      image: data1.image ? [{ thumbUrl: data1.image }] : [],
    });
    {
      data1.image == null || data1.image === undefined || data1.image === ""
        ? setImagecard(false)
        : setImagecard(true);
    }
  };

  /**
   * modal project details
   */
  const showTypesModal = (record) => {
    setIsTypesModalVisible(true);
    setTypesOfProject(record.project_id);
  };

  const showDetailModal = (record) => {
    setIsDetailModalVisible(true);
    setPassedProjectId(record.project_id);
  };

  const handleCancel = () => {
    setIsDetailModalVisible(false);
    setIsModalVisible(false);
    setTypesOfProject(false);
    setIsTypesModalVisible(false);
    setOpenProjectDetail(false);
    clear_Project_field();
  };
  //------------------------project details ---------------------

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.project_id]) });
  };
  const onConfirm = async (key) => {
    await delProjById(key);
    await projects(logindetails.organisation.orgID, logindetails.user.id);
    await count(logindetails.organisation.orgID);
  };

  const deleteSelected = () => {
    if (selectedKeys === undefined)
      notification.open({
        message: "Select items to delete.",
      });
    else if (selectedKeys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(selectedKeys) });
    }
  };
  const data = [];
  for (let i = 0; i < allProjectsSelectorData.length; i++) {
    let startDate = new Date(allProjectsSelectorData[i].project_start_date);
    startDate.setDate(startDate.getDate());
    let endDate = new Date(allProjectsSelectorData[i].project_end_date);
    endDate.setDate(endDate.getDate());
    let acStartDate = new Date(allProjectsSelectorData[i].actual_start_date);
    acStartDate.setDate(acStartDate.getDate());
    let acEndDate = new Date(allProjectsSelectorData[i].actual_end_date);
    acEndDate.setDate(acEndDate.getDate());

    data.push({
      sl_no: i + 1,
      // key: allProjectsSelectorData[i].project_id,
      project_id: allProjectsSelectorData[i].project_id,
      project_name: allProjectsSelectorData[i].project_name,
      project_code: allProjectsSelectorData[i].project_code,
      // project_name_ID:
      //   allProjectsSelectorData[i].project_name +
      //   "  -- " +
      //   allProjectsSelectorData[i].project_id,
      description: allProjectsSelectorData[i].description,
      //paying_company: allProjectsSelectorData[i].paying_company,
      project_start_date: moment(startDate.toISOString().slice(0, 10)).format(
        "DD-MM-YYYY"
      ),
      project_end_date: moment(endDate.toISOString().slice(0, 10)).format(
        "DD-MM-YYYY"
      ),
      actual_start_date:
        allProjectsSelectorData[i].actual_start_date === null
          ? allProjectsSelectorData[i].actual_start_date
          : moment(allProjectsSelectorData[i].actual_start_date).format(
              "DD-MM-YYYY"
            ),
      actual_end_date:
        allProjectsSelectorData[i].actual_end_date === null
          ? allProjectsSelectorData[i].actual_end_date
          : moment(allProjectsSelectorData[i].actual_end_date).format(
              "DD-MM-YYYY"
            ),
      original_budget: allProjectsSelectorData[i].original_budget,
      image: allProjectsSelectorData[i].image,
    });
  }

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );

  const handleCancelmodal = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview && file.size) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.size ? file.url || file.preview : file.thumbUrl);
    setPreviewvisible(true);
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };
  const handleActualEndDateChange = (endDateValue) => {
    console.log("endDateValue", endDateValue);
    const actualStartDate = form.getFieldValue("actual_start_date");

    if (
      actualStartDate &&
      endDateValue &&
      endDateValue.isSameOrBefore(actualStartDate, "day")
    ) {
      message.error(
        "Actual Project End date must be greater than to Actual project Start date"
      );
      form.setFieldsValue({ actual_end_date: null });
    }
  };

  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(Date.now(), "day");
  };
  const handleactualStartDateChange = (actualStartDate) => {
    const projectStartDate = storeActualStartDate;

    if (
      actualStartDate &&
      projectStartDate &&
      actualStartDate.isBefore(projectStartDate, "day")
    ) {
      message.error(
        "Actual Project start date must be greater than Project start date"
      );
      form.setFieldsValue({ actual_start_date: null });
      console.log("actualStartDate", actualStartDate);
    }
  };

  // adding colsole log - shivam kashyap 19 march testing
  // useEffect(() => {
  //   console.log("list of projects-----", data);
  //   console.log("all project data -----", allProjectsSelectorData);
  // }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    // console.log("i am date: ", dateString);
    const date = moment.utc(dateString, "DD-MM-YYYY");
    return date.format("DD MMM YYYY");
  };

  useEffect(() => {
    console.log("editprojectFormData: ", editprojectFormData);
    console.log("image: ", imageFile);
  }, [editprojectFormData, setEditProjectFormData]);

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
          <div className=" flex justify-between mt-3  mx-11 ">
            <div className="  invisible font-montserrat">
              {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

              <button
                // onClick={onUploadWorkBtn}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <span>{/* <IoIosAdd size={20} /> */}</span>
                New RFI
              </button>
            </div>
            <div className=" font-semibold  text-2xl">List Of Projects</div>
            <div className=" font-montserrat">
              {/* <ButtonPrimary data={onUploadWorkBtn} label="+ New Work Order" /> */}

              <button
                onClick={() => setShowAddProject(true)}
                type="button"
                class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                <span>
                  <IoIosAdd size={20} />
                </span>
                Add Project
              </button>
            </div>
          </div>
        </div>
        {/* <div className="title-show-project">List Of Projects </div>
        <br /> */}
        <div className="btn__">
          {/* <Button className="pdf">Export to PDF</Button> */}

          {/* <Button className="excel">
            <CSVLink
              filename={"Projects_Table.csv"}
              data={data}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button> */}

          {/* <div>
            <Button type="primary" 
            style={{backgroundColor:"teal", borderRadius:"5px"}}
            onClick={() => window.print()}>
              {" "}
              <PrinterOutlined />
              Print
            </Button>
          </div> */}
          {/* <Button className="print">Print</Button> */}
          {/* <Button className="copy">Copy</Button> */}
        </div>

        {/*Show Project Details Modal  */}
        <div>
          <Modal
            visible={visible}
            onCancel={handleHideModal}
            footer={null}
            width="75%"
            style={{ top: 80 }}
          >
            <ShowProjectDetails onFinish={handleFinish} />
          </Modal>
        </div>
        {/* old table  */}
        {/* <Table
          className="table"
          scroll={{ x: "130vw", y: 550 }}
          rowSelection={rowSelection}
          columns={columns}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          dataSource={data}
          pagination={10}
          rowKey="project_id"
          style={{ paddingTop: "10px" }}
        /> */}

        {/* new table shivam kashyap 20 may 2024  */}
        <div className=" font-montserrat mt-5  ml-4    flex flex-col items-center  justify-center -green-300">
          <ul role="list" className="divide-y w-[1200px] divide-[#F5F5F5]">
            <li className="flex justify-between  items-start px-5 bg-[#F5F5F5] py-2">
              {/* Header Content */}
              <div className="flex flex-col items-start -yellow-200 w-[200px]">
                <p className="text-sm font-semibold text-gray-900">
                  Project Name
                </p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Project Code
                </p>
              </div>

              <div className="flex flex-col blue-200 items-start w-[300px]">
                <p className="text-sm font-semibold text-gray-900">
                  {" "}
                  Description
                </p>
              </div>
              <div className="flex flex-col blue-200 items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900">
                  Planned Start Date
                </p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Planned End Date
                </p>
              </div>
              <div className="flex flex-col blue-200 items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900">
                  Actual Start Date
                </p>
                <p className="mt-1 text-xs  leading-5 text-gray-500">
                  Actual End Date
                </p>
              </div>
              <div className="flex flex-col blue-200 items-start w-[100px]">
                <p className="text-sm font-semibold text-gray-900"> Image</p>
              </div>

              <div className="flex richblue-200 flex-col items-start w-[160px]">
                <p className="text-sm  font-semibold text-gray-900">Action</p>
              </div>
            </li>
          </ul>
          <ul
            role="list"
            className="divide-y w-[1215px] ml-4 divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[62vh] "
          >
            {data && data.length
              ? data.map((item, key) => (
                  <li
                    className={`flex justify-between ${
                      key % 2 != 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }  items-start px-5  py-2`}
                  >
                    <div className="flex flex-col items-start -yellow-200 w-[200px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.project_name ? item.project_name : "-"}
                      </p>
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {item?.project_code ? item.project_code : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col blue-200 items-start w-[300px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {" "}
                        {item?.description ? item.description : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.project_start_date
                          ? formatDate(item.project_start_date)
                          : "-"}
                      </p>
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {item?.project_end_date
                          ? formatDate(item.project_end_date)
                          : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {item?.actual_start_date
                          ? formatDate(item.actual_start_date)
                          : "-"}
                      </p>
                      <p className="mt-1 text-xs  leading-5 text-gray-500">
                        {item?.actual_end_date
                          ? formatDate(item.actual_end_date)
                          : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col blue-200 items-start w-[100px]">
                      <p className="text-sm font-semibold text-gray-900">
                        {" "}
                        {item?.image ? (
                          <img
                            src={item?.image}
                            className="block mx-auto my-auto w-[50px] h-[50px]"
                          />
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>

                    <div className="flex richblue-300   gap-x-2 items-start w-[160px]">
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Show type of project"
                      >
                        <LuClipboardType
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => showTypesModal(item)}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Bulk upload"
                      >
                        <FaFileUpload
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => openProjectDetailModal(item)}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="project location"
                      >
                        <FaLocationDot
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => {
                            showDetailModal(item);
                          }}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Edit"
                      >
                        <MdEdit
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={async () => {
                            showModal(item);
                            console.log("item: ", item);
                            await setEditProjectFormData({
                              project_name: item.project_name,
                              description: item.description,
                              actual_start_date: item.actual_start_date
                                ? item.actual_start_date
                                    .split("-")
                                    .reverse()
                                    .join("-")
                                : "",
                              actual_end_date: item.actual_end_date
                                ? item.actual_end_date
                                    .split("-")
                                    .reverse()
                                    .join("-")
                                : "",
                              original_budget: item.original_budget,
                            });
                          }}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                      <a
                        // onClick={() => onBillingWorkOrder(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="Delete"
                      >
                        <MdDelete
                          className=" text-top_nav_blue-300 "
                          size={18}
                          onClick={() => deleteRow(item)}
                        />
                        {/* <RiBillLine
                         
                        /> */}
                      </a>{" "}
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>

        {/* </div> */}
        <Modal
          width="95%"
          className="showTypesProjectModal"
          visible={isTypesModalVisible}
          onCancel={handleCancel}
          centered={true}
          footer={null}
        >
          <TypesOfProjectTable projectID={typesofProject} />
        </Modal>

        <Modal
          className="showProjectModal"
          visible={isdetailModalVisible}
          onCancel={handleCancel}
          centered={true}
          footer={null}
          // width={600}
        >
          <ShowProjectDetails projectID={passedProjectId} />
        </Modal>

        <Modal
          visible={openProjectDetail}
          onCancel={handleCancel}
          width={"80%"}
          centered={true}
          footer={null}
        >
          <AddProjectDetails projectID={passProjectId} />
        </Modal>

        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={1400}
          centered={true}
          footer={null}
        >
          <div className=" font-semibold  text-2xl font-montserrat">
            Edit Project
          </div>
          <br />
          {/* <Form
            form={form}
            name="add-project"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditProjectFinish}
            onFinishFailed={onEditProjectFinishFailed}
            //autoComplete="off"
          >
            <Form.Item
              name="project_name"
              label="Project Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter the Project Name !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 character only",
                },
              ]}
            >
              <Input
                placeholder="Project Name"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="actual_start_date"
              label="Actual Start Date"
              className="input-field"
              rules={[
                {
                  required: false,
                  message: "Please select Actual Start Date !",
                },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
              
                onChange={handleactualStartDateChange}
              />
            </Form.Item>
            <Form.Item
              name="actual_end_date"
              label="Actual End Date"
              className="input-field"
              rules={[
                {
                  required: false,
                  message: "Please select Actual End Date !",
                },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                //  disabledDate={disabledStartDate}
                onChange={handleActualEndDateChange}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              className="input-field"
              rules={[
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 character only",
                },
              ]}
            >
              <Input.TextArea
                style={{ maxHeight: "130px", minHeight: "130px" }}
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>

            <Form.Item
              name="image"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="input-field"
            >
              
              <Upload
                name="logo"
                listType="picture-card"
                accept=".jpg,.jpeg,.png"
                maxCount={"1"}
                onPreview={handlePreview}
                beforeUpload={(file, fileList) => {
                  return false;
                }}
              >
                {!imagecard && uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
              name="original_budget"
              label="Budget"
              className="input-field"
              rules={[
                // {
                //   required: false,
                //   message: "Please enter the Original Budget !",
                // },
                {
                  pattern: /\d{5}/,
                  message: "Value must be greater than equal to 10000",
                },
              ]}
            >
            
              <InputNumber type="number" style={{ width: "100%" }} />

           
            </Form.Item>

            <Form.Item>
              <div className="btn-project">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_Project_field} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}

          {/* new form shivam kashyap 30 may 2024  */}
          <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={onEditProjectFinish}
          >
            {/* 1a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="">
                <label
                  htmlFor="project_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Project Name
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="project_name"
                    value={editprojectFormData.project_name}
                    onChange={(e) => {
                      setEditProjectFormData({
                        ...editprojectFormData,
                        project_name: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2  */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="completion_date"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Actual Start Date
                </label>
                <input
                  // required
                  type="date"
                  name="completion_date"
                  value={editprojectFormData.actual_start_date}
                  onChange={(e) => {
                    setEditProjectFormData({
                      ...editprojectFormData,
                      actual_start_date: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>{" "}
              {/* 3 date */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="completion_date"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Actual End Date
                </label>
                <input
                  // required
                  type="date"
                  name="completion_date"
                  value={editprojectFormData.actual_end_date}
                  onChange={(e) => {
                    setEditProjectFormData({
                      ...editprojectFormData,
                      actual_end_date: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>
            </div>

            {/* 2a  */}
            <div className="flex justify-evenly w-[90vw]">
              {/* 1 */}
              <div className="">
                <label
                  htmlFor="project_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Description
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="text"
                    name="project_name"
                    value={editprojectFormData.description}
                    onChange={(e) => {
                      setEditProjectFormData({
                        ...editprojectFormData,
                        description: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2  */}
              <div className="">
                <label
                  htmlFor="project_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Budget
                </label>
                <div className="mt- w-[300px]">
                  <input
                    // required
                    type="number"
                    name="project_name"
                    value={editprojectFormData.original_budget}
                    onChange={(e) => {
                      setEditProjectFormData({
                        ...editprojectFormData,
                        original_budget: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>{" "}
              {/* 2 image */}
              <div className="w-[300px] ">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {/* <span className="text-red-600">*</span> */}
                  Upload
                </label>
                <input
                  // required
                  class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="image"
                  type="file"
                  accept="image/svg+xml, image/png, image/jpeg, image/gif"
                  onChange={async (event) => {
                    const fileInput = event.target;
                    const file = fileInput.files[0];

                    if (file) {
                      setImageFile(file);
                      // console.log("filse set: ", imageFile);
                      let imageBase64String = "";
                      if (imageFile) {
                        try {
                          imageBase64String = await getBase64(file);
                        } catch (error) {
                          console.error(
                            "Error converting image to base64:",
                            error
                          );
                          toast.error(
                            "An error occurred while processing the image. Please try again."
                          );
                          return;
                        }
                      }

                      const imageObject = {
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                        name: file.name,
                        originFileObj: file,
                        percent: 0, // Set percent as needed
                        size: file.size,
                        thumbUrl: imageBase64String, // Set thumbUrl as needed
                        type: file.type,
                        uid: "rc-upload-" + Date.now(), // Generate unique uid
                      };
                      imageObject.thumbUrl = imageObject.thumbUrl.replace(
                        "data:image/jpeg;",
                        "data:image/png;"
                      );

                      setImageFile({
                        image: [imageObject],
                      });
                    }
                  }}
                />
                <p
                  class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="file_input_help"
                >
                  SVG, PNG, JPG or GIF .
                </p>
              </div>
            </div>
            {/* buttons  */}
            <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
              <button
                onClick={() => {
                  setEditProjectFormData({
                    project_name: "",
                    description: "",
                    actual_start_date: "",
                    actual_end_date: "",
                    original_budget: "",
                  });
                }}
                type="button"
                class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
              >
                Reset
              </button>
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Submit
              </button>
            </div>
          </form>

          
        </Modal>

      
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelmodal}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Spin>
      {showAddProject && (
        <div className="fixed m-6 select-none   inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-opacity-10  backdrop-blur-sm   ">
          <IoMdClose
            size={22}
            onClick={() => setShowAddProject(false)}
            className=" cursor-pointer absolute top-28 right-10 "
          />
          <AddProject
            showAddProject={showAddProject}
            setShowAddProject={setShowAddProject}
          />
        </div>
      )}
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default React.memo(ShowProjects);
