import { ADD_COLOR } from "./constant";
const initialstate = {
  color:{}
};


function addcolorreducer(state = initialstate, action) {
  switch (action.type) {
    case ADD_COLOR:
      return { color: action.payload };
    default:
      return state;
  }
}

function spinreducer(state = false, action) {
  switch (action.type) {
    case 'spin':
      return { spin: action.payload };
    default:
      return state;
  }
}

export { addcolorreducer ,spinreducer};
