import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Modal,
  InputNumber,
  Space,
  message,
  DatePicker,
  Card,
  Select,
  Table,
} from "antd";
import {
  selectorAllBOQExcelData,
  selectorAllSheetNamesData,
} from "../../../../redux-core/contracts/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  getBOQExcelData,
  getBOQSheetNames,
} from "../../../../redux-core/contracts/actions";
import { selectorGetItemInCurrentStockProjectWise } from "../../../../redux-core/materials/selectors";
import {
  getCurrentStockProjectIdWise,
  newIndentMaterialDetails,
} from "../../../../redux-core/materials/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import moment from "moment";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getAllBOQExcelData: (workOrderNumber, sheetName) =>
    dispatch(getBOQExcelData(workOrderNumber, sheetName)),

  getCurrentStockProjectIdWise: (projectID) =>
    dispatch(getCurrentStockProjectIdWise(projectID)),

  createIndentMaterialDetails: (data) =>
    dispatch(newIndentMaterialDetails(data)),
});

const AddCreateIntendForm = (props) => {
  const {
    getAllBOQExcelData,
    getCurrentStockProjectIdWise,
    createIndentMaterialDetails,
  } = actionDispatch(useDispatch());
  const [form] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  // States //
  const [openNill, setOpenNill] = useState("available");
  const [selectedBoqItem, setSelectedBoqItem] = useState();
  const [selectAvailableDesc, setSelectAvailableDesc] = useState();
  const [showIndentTable, setShowIndentTable] = useState(false);
  const [storeFormData, setStoreFormData] = useState([]);
  const [sendResponseDetails, setSendResponseDetails] = useState();

  console.log("storeFormData", storeFormData);
  // Selectors //
  const boqExcelData = useSelector(selectorAllBOQExcelData);
  const boqSheetNames = useSelector(selectorAllSheetNamesData);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const [formData, setFormData] = useState({
    boq_quantity: "",
    item_description: "",
    item_specification: "",
    quantity_in_stock: "",
    quantity_ordered_so_far: "",
    quantity_required_now: "",
    required_date: "",
    sheet: "",
    types_of_received_material: "",
    uom: "",
    work_order_boq_item_no: "",
    work_order_sheet_description: "",
  });

  useEffect(() => {
    getCurrentStockProjectIdWise(props.projectId);
    form.setFieldsValue({
      work_order_boq_item_no: selectedBoqItem?.sno,
      boq_quantity: selectedBoqItem?.quantity,
      uom: selectedBoqItem?.unit,
      quantity_in_stock: selectAvailableDesc?.total_qty,
      quantity_ordered_so_far: selectAvailableDesc?.accept_qty,
    });

    setFormData({
      ...formData,
      work_order_boq_item_no: selectedBoqItem?.sno,
      boq_quantity: selectedBoqItem?.quantity,
      uom: selectedBoqItem?.unit,
      quantity_in_stock: selectAvailableDesc?.total_qty,
      quantity_ordered_so_far: selectAvailableDesc?.accept_qty,
    });

    console.log("-------------------------", props);
  }, [
    props.projectId,
    selectedBoqItem,
    formData.work_order_sheet_description,
    selectAvailableDesc,
  ]);
  console.log("selectedBoqItem?.sno", selectedBoqItem?.sno);

  const handelSheet = (value) => {
    console.log("Props: ", props, value);
    getAllBOQExcelData(props.sendResponse.work_order_no, value);
  };

  const handelOpenNill = (values) => {
    setOpenNill(values);
    // getCurrentStockProjectIdWise(values)
  };

  const HandelBoqItemSelect = (valuess) => {
    const itemDetails = boqExcelData?.allBOQExcelData.find(
      (items) => items.id == valuess
    );

    setSelectedBoqItem(itemDetails);
  };

  const handelAvailableStocks = (value) => {
    console.log("value}}}-------------------", value);
    setFormData({
      ...formData,
      item_description: value,
    });
    const stockDetails = getShowItemInCurrentStockProjectWise?.find(
      (items) => items.id == value
    );

    if (stockDetails) {
      console.log(
        "stockDetails!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
        stockDetails
      );
      setSelectAvailableDesc(stockDetails);
    } else {
      // Handle the case when getShowItemInCurrentStockProjectWise is empty or undefined
      console.log("Stock details not found");
      setSelectAvailableDesc(null); // You can set it to null or handle it differently
    }
  };
  console.log("props.sendResponse", props.sendResponse);

  const columns = [
    {
      title: "Work Order Sheet Name in BOQ",
      dataIndex: "sheet",
    },
    {
      title: "Work Order Item Description in BOQ",
      dataIndex: "work_order_sheet_description",
    },
    {
      title: "Work Order BOQ Item No.",
      dataIndex: "work_order_boq_item_no",
    },
    {
      title: "Is Item Available In Master List",
      dataIndex: "types_of_received_material",
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
    {
      title: "BOQ Quantity",
      dataIndex: "boq_quantity",
    },
    {
      title: "Item Specification",
      dataIndex: "item_specification",
    },
    {
      title: "Quantity Ordered so far",
      dataIndex: "quantity_ordered_so_far",
    },
    {
      title: "Quantity in stock",
      dataIndex: "quantity_in_stock",
    },
    {
      title: "Quantity required now",
      dataIndex: "quantity_required_now",
    },
    {
      title: "Required Buy Date",
      dataIndex: "required_date",
    },
  ];

  const onAddIndentFormFinish = async (e) => {
    // console.log("Formdata:", value);
    e.preventDefault();

    // setShowIndentTable(true);

    let data = {
      ...formData,
      ...{
        indent_header_id: props?.sendResponse?.id,
        project_id: props.projectId,
        orgID: orgId,
        created_by: userId,
      },
    };
    console.log("Data bs: ", data);
    // return;
    try {
      let response = await createIndentMaterialDetails(data);
      const itemName = getShowItemInCurrentStockProjectWise.find(
        (item) => item.id == formData.item_description
      );
      console.log("itemName", itemName?.Item_name);
      const boqItemName = boqExcelData?.allBOQExcelData.find(
        (item) => item.id == formData.work_order_sheet_description
      );
      console.log("boqItemName", boqItemName?.description);
      let TableData = {
        ...formData,
        ...{
          indent_header_id: props?.sendResponse?.id,
          project_id: props.projectId,
          orgID: orgId,
          created_by: userId,
          item_description:
            itemName?.Item_name === undefined
              ? formData.item_description
              : itemName?.Item_name,
          work_order_sheet_description: boqItemName?.description,
        },
      };
      if (response) {
        setFormData({
          boq_quantity: "",
          item_description: "",
          item_specification: "",
          quantity_in_stock: "",
          quantity_ordered_so_far: "",
          quantity_required_now: "",
          required_date: "",
          sheet: "",
          types_of_received_material: "",
          uom: "",
          work_order_boq_item_no: "",
          work_order_sheet_description: "",
        });
        console.log("response: ", response);
        toast.success("Success..");
        handelData([TableData]);
        // setSendResponseDetails(response?.materials)
        clear_Project_field();
      }
    } catch (err) {
      toast.error("Failed..");
      console.log("Err: ", err);
    }

    // setStoreFormData(value)
    console.log("showIndent", formData);
  };

  const handelData = (responseData) => {
    console.log("responsedata", responseData);
    if (!Array.isArray(responseData)) {
      return;
    }
    for (let i = 0; i < responseData.length; i++) {
      // console.log("hours",hours)

      let newData = responseData.map((item, index) => ({
        project_id: item.project_id,
        indent_header_id: item.indent_header_id,
        sheet: item.sheet,
        work_order_sheet_description: item.work_order_sheet_description,
        work_order_boq_item_no: item.work_order_boq_item_no,
        types_of_received_material: item.types_of_received_material,
        item_description: item.item_description,
        uom: item.uom === null ? 0 : item.uom,
        boq_quantity: item.boq_quantity,
        item_specification: item.item_specification,
        quantity_ordered_so_far: item.quantity_ordered_so_far,
        quantity_in_stock: item.quantity_in_stock,
        quantity_required_now: item.quantity_required_now,
        required_date: item.required_date.toISOString().split("T")[0],
      }));
      setStoreFormData((prevData) => [...prevData, ...newData]);
      console.log("newData}}", newData);
    }
  };

  const onAddIndentFinishFailed = (error) => {
    console.log("faild", error);
  };

  const clear_Project_field = () => {
    form.setFieldsValue({
      sheet: "",
      work_order_sheet_description: "",
      work_order_boq_item_no: "",
      types_of_received_material: "",
      item_description: "",
      uom: "",
      boq_quantity: "",
      item_specification: "",
      quantity_ordered_so_far: "",
      quantity_in_stock: "",
      quantity_required_now: "",
      required_date: "",
    });
  };

  useEffect(() => {
    console.log("Formdata: ", formData);
  }, [formData]);

  useEffect(() => {
    console.log("boqSheetNames: ", boqSheetNames);
  }, [boqSheetNames]);

  return (
    <>
      {/* <Card hoverable style={{ borderTop: "1px solid black" }}>
        <Form
          form={form}
          name="add-project"
          size="large"
          onFinish={onAddIndentFormFinish}
          onFinishFailed={onAddIndentFinishFailed}
        >
          <Form.Item
            name="sheet"
            label="Work Order Sheet Name in BOQ"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please select Sheet !",
              },
            ]}
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              onChange={(sheetname) => handelSheet(sheetname)}
            >
              {boqSheetNames.sheetNames.map((item) => (
                <option value={item.sheetname}>{item.sheetname}</option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="work_order_sheet_description"
            label="Work Order Item Description in BOQ"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please select Desription !",
              },
            ]}
          >
            <Select
              onChange={(id) => HandelBoqItemSelect(id)}
              allowClear
              style={{ width: "100%" }}
            >
              {boqExcelData?.allBOQExcelData?.map((item) => (
                <option value={item?.id}>{item?.description}</option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="work_order_boq_item_no"
            label="Work Order BOQ Item No."
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please select Work Order BOQ Item No. !",
              },
            ]}
            defaultValue={selectedBoqItem?.sno}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="types_of_received_material"
            label="Is Item Available In Master List"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please select Types of Received Materials !",
              },
            ]}
          >
            <Select
              placeholder="Select Item Description"
              onChange={handelOpenNill}
            >
              <option value="nill">NA</option>
              <option value="available">Available</option>
            </Select>
          </Form.Item>
          {openNill === "nill" && (
            <Form.Item
              name="item_description"
              label="Item Description"
              className="input-field"
              rules={[
                {
                  required: false,
                  message: "Please select Item Description !",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {openNill === "available" && (
            <Form.Item
              name="item_description"
              label="Item Description"
              className="input-field"
              rules={[
                {
                  required: false,
                  message: "Please select Item Description !",
                },
              ]}
            >
              <Select
                placeholder="Select Item Description"
                allowClear
                style={{ width: "100%" }}
                onChange={(id) => handelAvailableStocks(id)}
              >
                {getShowItemInCurrentStockProjectWise.map((item) => (
                  <option value={item.id}>{item.Item_name}</option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="uom"
            label="UOM"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please select UOM !",
              },
            ]}
            defaultValue={selectedBoqItem?.unit}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="boq_quantity"
            label="BOQ Quantity"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please select UOM !",
              },
            ]}
            defaultValue={selectedBoqItem?.quantity}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="item_specification"
            label="Item Specification"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please select Item Specification !",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="quantity_ordered_so_far"
            label="Quantity Ordered so far"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please enter Quantity Ordered so far !",
              },
            ]}
            defaultValue={selectAvailableDesc?.accept_qty}
          >
            <Input placeholder="Enter Quantity Ordered so far" />
          </Form.Item>

          <Form.Item
            name="quantity_in_stock"
            label="Quantity in stock"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please enter Quantity in stock !",
              },
            ]}
            defaultValue={selectAvailableDesc?.total_qty}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="quantity_required_now"
            label="Quantity required now"
            className="input-field"
            rules={[
              {
                required: false,
                message: "Please enterQuantity required now !",
              },
            ]}
          >
            <Input placeholder="Enter Quantity required now" />
          </Form.Item>

          <Form.Item
            name="required_date"
            label="Required Buy Date"
            className="firstRow"
            rules={[
              {
                required: true,
                message: "Please select Required Date !",
              },
            ]}
          >
            <DatePicker
              format="DD-MM-YYYY"
              //  disabledDate={disabledStartDate}
              // onChange={handleStartDateChange}
            />
          </Form.Item>

          <Form.Item>
            <div className="btn-project">
              <Button type="primary" htmlType="submit" className="btn-submit">
                <strong>Submit</strong>
              </Button>

              <Button onClick={clear_Project_field} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card> */}

      <div className=" w-full ">
        {/* new form shivamkasyap 2 july 2024 */}
        <form
          className="font-montserrat flex flex-col items-center  gap-y-3"
          onSubmit={onAddIndentFormFinish}
        >
          {/* 1a  */}
          <div className="flex justify-evenly  w-[70vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="sheet"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Work Order Sheet Name in BOQ
              </label>
              <select
                required
                name="sheet"
                value={formData.sheet}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    sheet: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option disabled value="">
                  Select
                </option>{" "}
                {boqSheetNames.sheetName &&
                  boqSheetNames.sheetNames.map((item) => (
                    <option value={item.sheetname}>{item.sheetname}</option>
                  ))}
              </select>
            </div>
            {/* 2  */}
            <div className="mt w-[300px]">
              <label
                htmlFor="work_order_sheet_description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Work Order Item Description in BOQ
              </label>
              <select
                required
                name="work_order_sheet_description"
                value={formData.work_order_sheet_description}
                onChange={(e) => {
                  HandelBoqItemSelect(Number(e.target.value));
                  setFormData({
                    ...formData,
                    work_order_sheet_description: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option disabled value="">
                  Select
                </option>{" "}
                {boqExcelData?.allBOQExcelData?.map((item) => (
                  <option value={item?.id}>{item?.description}</option>
                ))}
              </select>
            </div>
            {/* 3  */}
            <div className="mt w-[300px]">
              <label
                htmlFor="openNill"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Is Item Available In Master Lis
              </label>
              <select
                required
                name="openNill"
                value={openNill}
                onChange={(e) => {
                  setOpenNill(e.target.value);
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="nill">NA</option>
                <option value="available">Available</option>
              </select>
            </div>
          </div>{" "}
          {/* 2a  */}
          <div className="flex justify-evenly  w-[70vw]">
            {/* 1 */}
            {openNill === "available" && (
              <div className="mt w-[300px]">
                <label
                  htmlFor="sheet"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Item Description
                </label>
                <select
                  required
                  name="sheet"
                  value={formData.sheet}
                  onChange={(e) => {
                    handelAvailableStocks(e.target.value);
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
                >
                  {getShowItemInCurrentStockProjectWise.map((item) => (
                    <option value={item.id}>{item.Item_name}</option>
                  ))}
                </select>
              </div>
            )}{" "}
            {openNill === "nill" && (
              <div className="">
                <label
                  htmlFor="job_number"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Item Description
                </label>
                <div className="mt- w-[300px]">
                  <input
                    required
                    type="text"
                    name="reciever"
                    value={formData.item_description}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        item_description: e.target.value,
                      });

                      // HandeleCreateProjectCode(e.target.value);
                    }}
                    // placeholder="select total area"
                    className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
            {/* 2  */}
            <div className="">
              <label
                htmlFor="item_specification"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Item Specification
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="item_specification"
                  value={formData.item_specification}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      item_specification: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* 3  */}
            <div className="">
              <label
                htmlFor="quantity_ordered_so_far"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Quantity Ordered so far
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="number"
                  name="quantity_ordered_so_far"
                  value={formData.quantity_ordered_so_far}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      quantity_ordered_so_far: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>{" "}
          {/* 2a  */}
          <div className="flex justify-evenly  w-[70vw]">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="item_specification"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                BOQ Quantity
              </label>
              <div className="mt- w-[300px] cursor-help">
                <input
                  required
                  disabled
                  type="text"
                  name="item_specification"
                  defaultValue={selectedBoqItem?.quantity}
                  value={formData.boq_quantity}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* 2  */}
            <div className="">
              <label
                htmlFor="quantity_required_now"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Quantity required now
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="number"
                  name="quantity_required_now"
                  value={formData.quantity_required_now}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      quantity_required_now: e.target.value,
                    });

                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  // placeholder="select total area"
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* 3  */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="required_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Required Buy Date
              </label>
              <input
                required
                type="date"
                name="required_date"
                value={formData.required_date}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    required_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
          </div>{" "}
          {/* 3a */}
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end w-full ">
            <button
              onClick={() => {
                setFormData({
                  sheet: "",
                  work_order_sheet_description: "",
                  work_order_boq_item_no: "",
                  types_of_received_material: "",
                  item_description: "",
                  uom: "",
                  boq_quantity: "",
                  item_specification: "",
                  quantity_ordered_so_far: "",
                  quantity_in_stock: "",
                  quantity_required_now: "",
                  required_date: "",
                });
              }}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>{" "}
      </div>
      <div>
        {" "}
        {showIndentTable
          ? // <Table
            //   scroll={{ x: "50vw" }}
            //   columns={columns}
            //   dataSource={storeFormData}
            //   pagination={10}
            // />
            ""
          : ""}
      </div>
    </>
  );
};

export default AddCreateIntendForm;
