import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css";
import CreateIndentForm from "./Forms/CreateIndentForm";
import { Spin, Button, Card, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { makeSelectAllProj } from "../../../redux-core/settings/selectors";
import { useCallback } from "react";
import {
  getAllProj,
  getProjectByprojectId,
} from "../../../redux-core/settings/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../redux-core/userManagement/selectors";
import {
  getAllPurchaseOrders,
  getCurrentStockProjectIdWise,
} from "../../../redux-core/materials/actions";
import {
  getAllWorkOrders,
  getBOQExcelData,
  getBOQSheetNames,
} from "../../../redux-core/contracts/actions";
import { getAllAssignProjectId } from "../../../redux-core/userManagement/actions";
import { selectorAllWorkOrderDetails } from "../../../redux-core/contracts/selectors";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { formatDate, formatNumberWithCommas } from "../../../utils/functions";

const actionDispatch = (dispatch) => ({
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getProjectByprojectId: (projectID) =>
    dispatch(getProjectByprojectId(projectID)),
  getAllPurchaseOrders: (projectID) =>
    dispatch(getAllPurchaseOrders(projectID)),
  getAllWorkOrders: (projectID) => dispatch(getAllWorkOrders(projectID)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getCurrentStockProjectIdWise: (projectID) =>
    dispatch(getCurrentStockProjectIdWise(projectID)),
});

const CreateIndent = () => {
  const {
    getProjectByprojectId,
    getAllPurchaseOrders,
    getAllWorkOrders,
    projects,
    getAllAssignProjectId,
  } = actionDispatch(useDispatch());
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  // const Spindata = useSelector((item) => item.spinreducer);
  const [content, setContent] = useState("1");
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const allProjectsSelectorData = useSelector(makeSelectAllProj);
  const [projectId, setProjectId] = useState();

  console.log("allProjectsSelectorData", allProjectsSelectorData);

  useEffect(() => {
    projects(logindetails.organisation.orgID, logindetails.user.id);
    getAllAssignProjectId(orgId, userId);
    getAllWorkOrders(projectId);
    getAllPurchaseOrders(projectId);
    getCurrentStockProjectIdWise(projectId);
  }, [projectId]);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }

  const projectData = ProjID.flatMap((item) => {
    return allProjectsSelectorData.filter(
      (items) => items.project_id == item.Project_id
    );
  });

  const backProject = () => {
    setContent("1");
  };

  const onAddPurchaseBtn = useCallback((projectID) => {
    getAllWorkOrders(projectID);
    setContent("2");
    setProjectId(projectID);
    getProjectByprojectId(projectID);
    getAllPurchaseOrders(projectID);
    getCurrentStockProjectIdWise(projectID);
  }, []);

  return (
    <>
      {logindetails.user.role === "ADMIN" ? (
        <span className="card-container">
          {content === "1"
            ? allProjectsSelectorData.map((data) => {
                return (
                  <Card
                    className="projectCard"
                    // hoverable
                    style={{ width: 250, borderRadius: "5%" }}
                    key={data.project_id}
                    onClick={() => onAddPurchaseBtn(data.project_id)}
                  >
                    <div className="sub-main">
                      <div className="top">
                        <span className="left__value">
                          <b>Project Name: </b>
                          {data.project_name}
                        </span>
                        <span className="right__icon">
                          <Image width={50} src={data.image} alt="No Image" />
                        </span>
                      </div>
                      <div className="bottom__title">
                        <b>Description: </b>
                        {data.description}
                      </div>
                    </div>
                  </Card>
                );
              })
            : ""}
        </span>
      ) : (
        <div
          className="flex items-center justify-center flex-col mt-5"
          // ref={containerRef}
        >
          {content === "1" && (
            <li className="flex px-5 justify-between bg-[#F5F5F5] gap-x-6 py-2 w-[1000px]">
              <div className="min-w-0 flex ">
                <p className="text-sm font-semibold text-gray-900">
                  Project Name
                </p>
              </div>
              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm font-semibold text-gray-900">
                  Actual Dates
                </p>
                <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                  Planned Dates
                </p>
              </div>
            </li>
          )}
          {content === "1"
            ? projectData.map((person, key) => {
                return (
                  // <Card
                  //   className="projectCard"
                  //   // hoverable
                  //   style={{ width: 250, borderRadius: "5%" }}
                  //   key={data.project_id}
                  //   onClick={() => onAddPurchaseBtn(data.project_id)}
                  // >
                  //   <div className="sub-main">
                  //     <div className="top">
                  //       <span className="left__value">
                  //         <b>Project Name: </b>
                  //         {data.project_name}
                  //       </span>
                  //       <span className="right__icon">
                  //         <Image width={50} src={data.image} alt="No Image" />
                  //       </span>
                  //     </div>
                  //     <div className="bottom__title">
                  //       <b>Description: </b>
                  //       {data.description}
                  //     </div>
                  //   </div>
                  // </Card>
                  <ul
                    role="list"
                    className="divide-y  w-[1000px]   divide-[#F5F5F5]"
                  >
                    <li
                      onClick={() => onAddPurchaseBtn(person.project_id)}
                      key={key}
                      className={`flex cursor-pointer bg px-5 justify-between   ${
                        key % 2 == 0 ? "bg-[#FFF]" : "bg-[#F5F5F5]"
                      }  gap-x-6 py-2`}
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <img
                          className="h-12 w-12 flex-none rounded-full  bg-transparent"
                          src={person?.image}
                          alt="Project image"
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold  text-gray-900">
                            {person?.project_name}{" "}
                            <span className=" text-xs italic text-richblack-500 ">
                              {" "}
                              (CODE: {person?.project_short_cut})
                            </span>
                          </p>
                          <div className=" flex items-center justify-start w-[150px]">
                            <p>{`Budget: ${formatNumberWithCommas(
                              person?.original_budget
                            )}`}</p>
                            <span
                              key={key}
                              data-tooltip-target="tooltip-default"
                              type="button"
                              className=" pl-2  text-blue-400 cursor-pointer "
                            >
                              <IoMdInformationCircleOutline
                                className="my-anchor-element"
                                size={20}
                              />
                              <Tooltip
                                anchorSelect=".my-anchor-element"
                                place="top"
                              >
                                {person?.description}
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p className="text-sm leading-6 text-gray-900">
                          {`Actual: ${formatDate(
                            person?.actual_start_date
                          )} to ${formatDate(person?.actual_end_date)}`}
                        </p>
                        <p className="mt-1 text-xs max-w-[300px] leading-5 text-gray-500">
                          {`Planned dates: ${formatDate(
                            person?.project_start_date
                          )} to ${formatDate(person?.project_end_date)}`}
                        </p>
                      </div>
                    </li>
                  </ul>
                );
              })
            : ""}
        </div>
      )}

      {content === "2" ? (
        <CreateIndentForm projectID={projectId} backProject={backProject} />
      ) : (
        ""
      )}
    </>
  );
};

export default CreateIndent;
