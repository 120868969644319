// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.sub-title{
    font-size: x-large;
    font-weight: bold;
    /* font-style: italic; */
    /* border-bottom: 0.5px solid rgb(190, 228, 22);
    border-width: medium;
    width:fit-content; */
}

@media screen and (max-width:600px){
    .activity{
        box-shadow: none;
        padding: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/qcc/content/css/ActivityCreate.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;IACxB;;wBAEoB;AACxB;;AAEA;IACI;QACI,gBAAgB;QAChB,YAAY;IAChB;AACJ","sourcesContent":[".activity{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n    margin-bottom: 30px;\n}\n\n.sub-title{\n    font-size: x-large;\n    font-weight: bold;\n    /* font-style: italic; */\n    /* border-bottom: 0.5px solid rgb(190, 228, 22);\n    border-width: medium;\n    width:fit-content; */\n}\n\n@media screen and (max-width:600px){\n    .activity{\n        box-shadow: none;\n        padding: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
