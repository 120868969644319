// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 10px;
  left: 10px;
}

.image-container {
  position: relative;
  padding-top: 50px; /* Adjusted to make space for the back button */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.dashboard-image {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  width: 150px;
  height: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/components/reports/content/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,wCAAwC;EACxC,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,iBAAiB,EAAE,+CAA+C;EAClE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,aAAa;AACf","sourcesContent":[".back-button {\r\n  margin-top: 10px;\r\n  padding: 10px 20px;\r\n  font-size: 16px;\r\n  background-color: #007bff;\r\n  color: #fff;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  position: absolute;\r\n  top: 10px;\r\n  left: 10px;\r\n}\r\n\r\n.image-container {\r\n  position: relative;\r\n  padding-top: 50px; /* Adjusted to make space for the back button */\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.dashboard-image {\r\n  display: inline-block;\r\n  margin: 10px;\r\n  cursor: pointer;\r\n  width: 150px;\r\n  height: 150px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
