import "./css/ActivityForm.css";
import "antd/dist/antd.min.css";
import { makeSelectAllProj } from "../../../../redux-core/settings/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProj } from "../../../../redux-core/settings/actions";
import { redirectAction } from "../../../../redux-core/login/actions";
import { Form, Input, Button, Select, DatePicker, Modal, Upload } from "antd";
import { findSupervisorDetailById } from "../../../../redux-core/userManagement/actions";
import { selectorSupervisorDetailById } from "../../../../redux-core/userManagement/selectors";
import { selectorTaskDetail } from "../../../../redux-core/qcc/selectors";
import {
  findStageDetailById,
  newActivity,
  findTaskDetailById,
  countActionQCC,
  getAllTasks,
} from "../../../../redux-core/qcc/actions";
import {
  selectorStageDetailById,
  selectorTaskDetailById,
} from "../../../../redux-core/qcc/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { PlusOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  getProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllTasks: (orgId, userId) => dispatch(getAllTasks(orgId, userId)),
  taskById: (projId, orgId) => dispatch(findTaskDetailById(projId, orgId)),
  supervisorById: (projId, orgId) =>
    dispatch(findSupervisorDetailById(projId, orgId)),
  getStageById: (orgId, taskId) => dispatch(findStageDetailById(orgId, taskId)),
  newActivityCreate: (activity) => dispatch(newActivity(activity)),
  count: (orgID) => dispatch(countActionQCC(orgID)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
});

const { Option } = Select;

const ActivityForm = ({ setshowCreateActivityModal }) => {
  const [addActivity] = Form.useForm();
  const [date, setDate] = useState("");
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const {
    getProj,
    taskById,
    supervisorById,
    getStageById,
    newActivityCreate,
    count,
    redirect,
    getAllTasks,
  } = actionDispatch(useDispatch());
  useEffect(() => {
    getProj(orgId, userId);
    getAllTasks(orgId, userId);
    taskById(orgId);
  }, []);
  const allProjData = useSelector(makeSelectAllProj);
  const supervisorData = useSelector(selectorSupervisorDetailById);
  const stageDetailsData = useSelector(selectorStageDetailById);
  const taskDetailsData = useSelector(selectorTaskDetailById);
  const taskDetails = useSelector(selectorTaskDetail);
  const children_supervisor = [];
  const children_stages = [];
  const children_tasks = [];
  const [imagecard, setImagecard] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);

  for (let i = 0; i < taskDetailsData.length; i++) {
    children_tasks.push(
      <Option key={taskDetailsData[i].id}>
        {taskDetailsData[i].task}-{taskDetailsData[i].id}
      </Option>
    );
  }

  for (let i = 0; i < supervisorData.length; i++) {
    children_supervisor.push(
      <Option key={supervisorData[i].supervisor_id}>
        {supervisorData[i].name}-{supervisorData[i].supervisor_id}
      </Option>
    );
  }

  for (let i = 0; i < stageDetailsData.length; i++) {
    children_stages.push(
      <Option key={stageDetailsData[i].stage_id}>
        {stageDetailsData[i].stage_name}-{stageDetailsData[i].stage_id}
      </Option>
    );
  }
  const onAddActivityFinish = async (e) => {
    e.preventDefault();
    // console.log("Activity values: ", values);
    // return;
    // values.date = date;
    let data = {
      ...formData,
      orgID: orgId,
      createdBy: userId,
      // latitude: 122.7,
      // longitude: 107.5,
      isAdmin: 0,
      isSyncAdmin: 0,
      // dateSuper: "11/18/2022",
      no_of_checking: 1,
      // comment: "na",
      is_approve: 0,
      rating: 0,
      isDone: 0,
    };

    console.log("final data before submition: ", data);
    // return

    // if (data.image && data.image.length < 1) {
    //   delete data.image;
    // }
    try {
      let response = await newActivityCreate(data);
      if (response.success) {
        await clear_field();
        await count(orgId);
        setFormData({
          task_id: "",
          supervisor_id: "",
          stage_id: "",
        });
        setshowCreateActivityModal(false);

        console.log("Res activity create: ", response);
        toast.success("Success..");
      }
    } catch (err) {
      console.log("some error while creating activity occurred: ", err);
      toast.err("Failed..");
    }
  };

  const handleDate = (value, dateString) => {
    setDate(dateString);
  };

  const onAddActivityFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const children = [];
  for (let i = 0; i < allProjData.length; i++) {
    children.push(
      <Option key={allProjData[i].project_id}>
        {allProjData[i].project_name}-{allProjData[i].project_id}
      </Option>
    );
  }

  function handleProjChange(value) {
    addActivity.setFieldsValue({
      // task_name: '',
      task_id: "",
      supervisor_id: "",
      stage_id: "",
    });
    taskById(orgId);
    supervisorById(parseInt(value), orgId);
    getStageById(parseInt(value), orgId);
  }

  const clear_field = () => {
    addActivity.setFieldsValue({
      description: "",
      date: "",
      task_id: [],
      supervisor_id: [],
      stage_id: [],
      project_id: [],
      image: [],
    });
    setDate("");
    setImagecard(false);
  };

  const normFile = (e) => {
    if (e.fileList.length > 0) setImagecard(true);
    else setImagecard(false);
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Click to Upload</div>
    </div>
  );
  const handleCancel = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.url || file.preview);
    setPreviewvisible(true);
  };

  const callStage = (taskId) => {
    getStageById(orgId, formData.task_id);
  };
  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(Date.now(), "day");
  };

  // ...ActivityForm.........
  const [formData, setFormData] = useState({
    task_id: "",
    stage_id: "",
    description: "",
    date: "",
  });

  useEffect(() => {
    console.log("formdata: ", formData);
    console.log("cs: ", { children_stages });
  }, [formData, setFormData]);

  const dateFormatList = ["DD-MM-YYYY"];
  return (
    <>
      {/* old ui hidden by shivam kashyap 7 may 2024  */}
      <Form
        className=" hidden"
        form={addActivity}
        name="activity"
        size="large"
        initialValues={{
          remember: true,
        }}
        onFinish={onAddActivityFinish}
        onFinishFailed={onAddActivityFinishFailed}
        //autoComplete="off"
      >
        <Form.Item
          name="description"
          label="Activity Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter Activity Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input placeholder="Activity Name" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>

        <Form.Item
          name="date"
          label="Date"
          // valuePropName="fileList"
          // getValueFromEvent={normFile}
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select Date !",
            },
          ]}
        >
          <DatePicker
            onChange={handleDate}
            disabledDate={disabledStartDate}
            format={dateFormatList}
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        {/* commented for time being by sachin
        <Form.Item
          name="project_id"
          label="Select Project"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select any Project !",
            },
          ]}
        >
          <Select
            placeholder="Select Project"
            onChange={handleProjChange}
            allowClear
          >
            {allProjData &&
              allProjData.map((items, i) => {
                return (
                  <Option value={items.project_id} key={i}>
                    {items.project_name}-{items.project_id}
                  </Option>
                );
              })}
          </Select>
        </Form.Item> */}

        <Form.Item
          name="task_id"
          label="Select Task"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select any Task !",
            },
          ]}
        >
          <Select onChange={callStage} placeholder="Select Task" allowClear>
            {/* {taskDetails.task &&
    taskDetails.task?.map((getAllTasks) => (
      <Option key={getAllTasks?.id} value={getAllTasks?.id}>
        {getAllTasks?.task}--{getAllTasks?.id}
      </Option>
    ))} */}
            {children_tasks}
          </Select>
        </Form.Item>

        {/* commented for time being by sachin */}
        {/* <Form.Item
          name="supervisor_id"
          label="Select Supervisor"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select any Supervisor !",
            },
          ]}
        >
          <Select placeholder="Select Supervisor" allowClear>
            {children_supervisor}
          </Select>
        </Form.Item> */}

        <Form.Item
          name="stage_id"
          label="Select Stage"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please select any Stage !",
            },
          ]}
        >
          <Select placeholder="Select Stage" allowClear>
            {children_stages}
          </Select>
        </Form.Item>

        {/* <Form.Item
          name="image"
          label="Upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="input-field"
        >
          <Upload
            name="logo"
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            onPreview={handlePreview}
            beforeUpload={(file,fileList)=>{
              return false
             }}
          >
            {!imagecard && uploadButton}
          </Upload>
        </Form.Item> */}

        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Submit</strong>
            </Button>

            <Button onClick={() => clear_field()} className="btn-cancel">
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>

      {/* new ui - shivam kashyap 7 may 2024 */}

      <form className="font-montserrat" onSubmit={onAddActivityFinish}>
        {/* 1a */}
        <div className="flex  justify-start ml-10 gap-x-10 w-[90vw]">
          {/* 1 */}
          <div className="">
            <label
              htmlFor="task_id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Activity Name
            </label>
            <div className="w-[300px]">
              <input
                required
                // disabled
                type="text"
                title="Please enter text only"
                name="task_id"
                value={formData.description}
                onChange={(e) => {
                  setFormData({ ...formData, description: e.target.value });
                }}
                // pattern="[0-9]*"
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>{" "}
          {/* 2  */}
          <div className="mt w-[300px]">
            <label
              htmlFor="stage_id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Select Task
            </label>
            <select
              required
              name="stage_id"
              value={formData.task_id}
              // onChange={handleInputChange}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  task_id: e.target.value,
                });
                callStage;
              }}
              // onFocus={allStages}
              className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
            >
              <option disabled value="">
                Select
              </option>
              {children_tasks &&
                children_tasks.map((task) => (
                  <option
                    value={task.props.children[2]}
                    key={task.props.children[2]}
                  >
                    {task.props.children[0]}
                  </option>
                ))}
            </select>
          </div>
          {/* 3  */}
          <div className="mt w-[300px]">
            <label
              htmlFor="stage_id"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Select Stage
            </label>
            <select
              required
              name="stage_id"
              value={formData.stage_id}
              // onChange={handleInputChange}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  stage_id: e.target.value,
                });
              }}
              // onFocus={allStages}
              className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
            >
              <option disabled value="">
                Select
              </option>
              {children_stages &&
                children_stages.map((task) => (
                  <option
                    value={task.props.children[2]}
                    key={task.props.children[2]}
                  >
                    {task.props.children[0]}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {/* 2a  */}
        <div className="flex  justify-start ml-10 gap-x-10 w-[90vw]">
          {/* 3 date */}
          <div class="relative  w-[300px] h-[35px]">
            <label
              for="date"
              class="block text-sm mb- font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              RFI Date
            </label>
            <input
              required
              type="date"
              name="date"
              // value={formData.completion_date}
              // onChange={handleInputChange}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  date: e.target.value,
                })
              }
              class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
              placeholder="Select a date"
            />
          </div>
        </div>
        {/* buttons  */}
        <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
          <button
            // onClick={resetAllValue}
            onClick={() => {
              setFormData({
                task_id: "",
                supervisor_id: "",
                stage_id: "",
              });
            }}
            type="button"
            class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"

            data-te-ripple-init
          >
            Reset
          </button>
          <button
            type="submit"
            data-te-ripple-init
            data-te-ripple-color="light"
            class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Submit
          </button>
        </div>
      </form>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default ActivityForm;
