import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Layout, Menu, Spin, Tabs } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import "./SideNavMaterials.css";
import "antd/dist/antd.min.css";
import TopNavbar from "../../settings/topnavbar/TopNavbar";
import MediaQuery from "react-responsive";
// import Dashboard from "../content/Dashboard";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import StoreMaterial from "../content/StoreMaterial";
import ReceiptMaterial from "../content/ReceiptMaterial";
import ProjectMaterialStatement from "../content/ProjectMaterialStatement";
import MaterialIssueInCurrentStock from "../content/MaterialIssueInCurrentStock";
import ShowGrnList from "../content/ShowGrnList";
import CreateIndent from "../content/CreateIndent";
import ShowIndent from "../content/ShowIndent";
import { useParams } from "react-router";
import TabPane from "antd/lib/tabs/TabPane";

const MyProjects = React.lazy(() => import("../content/MyProjects"));
const { Sider, Content } = Layout;

const { SubMenu } = Menu;

const SideNavMaterials = () => {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [content, setContent] = useState("1");

  const onMenuClick = useCallback((value) => {
    setContent(value);
  }, []);

  useEffect(() => {
    console.log("Content: ", content);
  }, [content]);

  const params = useParams();

  // change content based on params
  useEffect(() => {
    // console.log("params: ", params);
    if (params.store == "receive") {
      if (key == "1") {
        setContent("1");
      } else {
        setContent(key);
      }
    } else if (params.store == "store") {
      if (key == "4") {
        setContent("4");
      } else {
        setContent(key);
      }
    } else {
      if (key == "7") {
        setContent("7");
      } else {
        setContent(key);
      }
    }
  }, [params]);

  const tabClick = async (key) => {
    await setKey(key);
    console.log("Tab clicked: ", key);
    setContent(key);
  };

  const [key, setKey] = useState("1");

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        {/* <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minWidth: "100px" }}
        >
          <div className="logo">
            {collapsed ? (
              <MenuUnfoldOutlined
                style={{ paddingTop: 18 }}
                className="trigger"
                onClick={toggle}
              />
            ) : logindetails.organisation.image === null ? (
              <h4>{logindetails.organisation.orgName}</h4>
            ) : (
              <img
                width="70px"
                src={logindetails.organisation.image}
                // src= "cognisie_logo_new.png"
                alt={logindetails.organisation.orgName}
              />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => onMenuClick(e.key)}
          >
            <SubMenu
              key="sub1"
              icon={
                <img src="task_create.png" className="icon_img" alt="task" />
              }
              title="Material Receive"
            >
              <Menu.Item
                key="1"
                icon={
                  <img
                    src="assignment_Add.png"
                    className="icon_img"
                    alt="addproject"
                  />
                }
                style={{ lineHeight: "1" }}
              >
                Against Purchase Order
              </Menu.Item>
              <Menu.Item
                key="2"
                icon={
                  <img
                    src="AddProject.png"
                    className="icon_img"
                    alt="addproject"
                  />
                }
                style={{ lineHeight: "0.9" }}
              >
                Without Purchase Order
              </Menu.Item>
              <Menu.Item
                key="3"
                icon={
                  <img
                    src="room_preferences_FILL0_wght700_GRAD200_opsz48.png"
                    className="icon_img"
                    alt="materialReciept"
                  />
                }
                style={{ lineHeight: "1" }}
              >
                Summary Of Material
              </Menu.Item>
              <Menu.Item
                key="6"
                icon={
                  <img
                    src="room_preferences_FILL0_wght700_GRAD200_opsz48.png"
                    className="icon_img"
                    alt="PurchaseGrnReport"
                  />
                }
                style={{ lineHeight: "1" }}
              >
                List Of GRN
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub2"
              icon={
                <img src="task_create.png" className="icon_img" alt="task" />
              }
              title="Store Management"
            >
              <Menu.Item
                key="4"
                icon={
                  <img
                    src="room_preferences_FILL0_wght700_GRAD200_opsz48.png"
                    className="icon_img"
                    alt="addproject"
                  />
                }
              >
                Current Stock
              </Menu.Item>
              <Menu.Item
                key="5"
                icon={
                  <img
                    src="room_preferences_FILL0_wght700_GRAD200_opsz48.png"
                    className="icon_img"
                    alt="addproject"
                  />
                }
              >
                Material Issue
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={
                <img src="task_create.png" className="icon_img" alt="task" />
              }
              title="Indent"
            >
              <Menu.Item
                key="7"
                icon={
                  <img
                    src="room_preferences_FILL0_wght700_GRAD200_opsz48.png"
                    className="icon_img"
                    alt="addproject"
                  />
                }
              >
                Create Indent
              </Menu.Item>
              <Menu.Item
                key="8"
                icon={
                  <img
                    src="room_preferences_FILL0_wght700_GRAD200_opsz48.png"
                    className="icon_img"
                    alt="addproject"
                  />
                }
              >
                Show Indent
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider> */}

        {/* new tabs ui - shivam kashyap 10n june 2024  */}

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger"
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt="logo"
                />
              )}
            </div>
          </MediaQuery>

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              overflow: "scroll",
            }}
          >
            {" "}
            {params.store == "receive" ? (
              <Tabs onChange={tabClick} defaultActiveKey="1" centered>
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Against Purchase"}
                    </div>
                  }
                  key="1"
                >
                  {content === "1" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      {/* <div>billu don</div> */}
                      <MyProjects />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Without Purchase"}
                    </div>
                  }
                  key="2"
                >
                  {content === "2" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <ReceiptMaterial />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Summary of Material"}
                    </div>
                  }
                  key="3"
                >
                  {content === "3" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <ProjectMaterialStatement />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"List of GRN"}
                    </div>
                  }
                  key="6"
                >
                  {content === "6" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <ShowGrnList />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>
              </Tabs>
            ) : (
              ""
            )}
            {params.store == "store" ? (
              <Tabs onChange={tabClick} defaultActiveKey="4" centered>
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Current Stock"}
                    </div>
                  }
                  key="4"
                >
                  {content === "4" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <StoreMaterial />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Material Issue"}
                    </div>
                  }
                  key="5"
                >
                  {content === "5" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <MaterialIssueInCurrentStock />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
              </Tabs>
            ) : (
              ""
            )}
            {params.store == "indent" ? (
              <Tabs onChange={tabClick} defaultActiveKey="7" centered>
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Create Indent"}
                    </div>
                  }
                  key="7"
                >
                  {content === "7" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <CreateIndent />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
                <TabPane
                  tab={
                    <div className=" font-montserrat text-top_nav_blue-500">
                      {"Show Indent"}
                    </div>
                  }
                  key="8"
                >
                  {content === "8" ? (
                    <Suspense
                      fallback={
                        <Spin
                          size="large"
                          spinning={dataspin.spin}
                          className="spin__component"
                        />
                      }
                    >
                      <ShowIndent />
                    </Suspense>
                  ) : (
                    <>Select an tab..</>
                  )}
                </TabPane>{" "}
              </Tabs>
            ) : (
              ""
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default SideNavMaterials;
