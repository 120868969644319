import React from "react";
import Sidebar from "../Core/Sidebar";
import Dashboard from "../dashboard/Dashbard";
import TopNavbar from "../settings/topnavbar/TopNavbar";

const ComingSoon = () => {
  return (
    <div className=" flex">
      <div>
        {/* <Sidebar /> */}
      </div>
      <div className=" flex flex-col w-full h-fit ">
       {/* <TopNavbar/> */}
        <div class=" h-[80vh] flex flex-col justify-center w-full items-center">
          <img
            src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
            alt="Logo"
            class="mb-8 h-40"
          />
          <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 dark:text-white mb-4 font-montserrat">
            Coming Soon
          </h1>
          <p class="text-center text-gray-500 dark:text-gray-300 text-lg md:text-xl lg:text-2xl mb-8  font-montserrat">
            We're working hard to improve the app. Stay tuned!
          </p>
          {/* <div class="flex space-x-4">
          <a
            href="#"
            class="bg-gray-800 hover:bg-gray-700 text-white font-bold py-3 px-6 rounded dark:bg-gray-700 dark:hover:bg-gray-600"
          >
            Contact Us
          </a>
          <a
            href="#"
            class="border-2 border-gray-800 text-black font-bold py-3 px-6 rounded dark:text-white dark:border-white"
          >
            Reload
          </a>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
