import React, { useEffect, useState } from "react";
import { Form, Button, Select, DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  createEquipmentSummaryHeader,
  getResourcesDashboard,
} from "../../../../redux-core/resources/actions";
import AddEquipmentDeploymentForm from "./AddEquipmentDeploymentForm";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  createEquipmentSummaryHeader: (data) =>
    dispatch(createEquipmentSummaryHeader(data)),
  getAllProject: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjectDetail: (orgId, userId) =>
    dispatch(getAllProjDetails(orgId, userId)),
  getResourcesDashboard: (orgId) => dispatch(getResourcesDashboard(orgId)),
});

const EquiupmentDeploymentForm = () => {
  const {
    getAllProject,
    getAllProjectDetail,
    createEquipmentSummaryHeader,
    getResourcesDashboard,
  } = actionDispatch(useDispatch());
  const [date, setDate] = useState();
  const [showTables, setShowTables] = useState(false);
  const [form] = Form.useForm();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [id, setId] = useState();
  const [projectId, setProjetID] = useState();
  const [isDisable, setIsDisable] = useState(true);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;

  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);

  useEffect(() => {
    getAllProject(orgId, userId);
    getAllProjectDetail(selectedProjectId, orgId, userId);
  }, [selectedProjectId]);

  const onChange = (date, dateString) => {
    console.log("dateString", dateString)
    setDate(dateString);
  };

  // console.log(allProjects)

  const onFinished = async (value) => {
    let data = {
      project_id: value.project_id,
      project_location_id: value.project_location_id,
      // project_status: value.project_status,
     deployment_date: value.deployment_date,
      orgID: orgId,
      created_by: userId,
    };
    let response = await createEquipmentSummaryHeader(data);
    if (response) {
      setId(response.data.id);
      setProjetID(response.data.project_id);
      getResourcesDashboard(orgId);
      setShowTables(true);
      setIsDisable(false);
      setIsDisableInput(true);
    }
    // console.log("response", response);
    // console.log("succees", response.data.id);
    console.log("data", data)
  };

  return (
    <>
      <Form form={form} onFinish={onFinished}>
        <Form.Item
          name="project_id"
          label="Project Name"
          rules={[{ required: true, message: "Please select Project!" }]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select Project Name"
            onChange={(projectId) => setSelectedProjectId(projectId)}
            disabled={isDisableInput}
          >
            {allProjects.map((getAllProject) => (
              <option
                key={getAllProject.project_id}
                value={getAllProject.project_id}
              >
                {getAllProject.project_name}
              </option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="project_location_id"
          label="Project  Location"
          rules={[
            { required: true, message: "Please select Project Loaction!" },
          ]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            allowClear
            style={{ width: "100%" }}
            disabled={isDisableInput}
            placeholder="Please select Project Work Location Name"
          >
            {allProjDetails
              .filter((detail) => detail.project_id === selectedProjectId)
              .map((detail) => (
                <Option key={detail.project_details_id} value={detail.block}>
                  {detail.block}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="project_status"
          label="Project Status"
          rules={[{ message: "Please select Project Status!" }]}
          className="input-field"
        >
          <Select
            // mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="select"
            disabled={isDisableInput}
            //  disabled
            //defaultValue={defaultValues}
          >
            <Option value="Created">Created</Option>
            <Option value="WorkInProgress">Work In Progress</Option>
            <Option value="Completed">Completed</Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          name="deployment_date"
          label="Current Date"
          className="input-field"
        >
          <DatePicker format= "DD-MM-YYYY" onChange={onChange} disabled={isDisableInput} />
        </Form.Item>

        <Form.Item>
          <div className="btn-labour">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              disabled={isDisableInput}
              style={{ borderRadius: "10px" }}
            >
              <strong>Submit</strong>
            </Button>
          </div>
        </Form.Item>
      </Form>
      <div>
        {" "}
        {showTables ? (
          <AddEquipmentDeploymentForm
            data={id}
            projectId={projectId}
            // location={forLocation}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default EquiupmentDeploymentForm;
