import React, { useEffect, useState } from "react";
import "./css/createAdmin.css";
import {
  Button,
  Table,
  message,
  Spin,
  Form,
  Modal,
  Input,
  Select,
  notification,
  Badge,
  Radio,
} from "antd";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
//import { settingsapi } from "../../../cognisite-api";
import { useSelector, useDispatch } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { ShowDeleteConfirm } from "../../popupmodal";
import {
  deleteAdmins,
  getAdmins,
  getOrganisation,
  updateAdminsData,
} from "../../../redux-core/settings/actions";
import {
  selectorGetAdmin,
  selectorGetOrganisation,
} from "../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { settingsapi } from "../../../cognisite-api";
import { updateUsersPassword } from "../../../redux-core/userManagement/actions";
const { updateByAdminId, deleteByAdminId,updateAdminStatus } = settingsapi;

const actionDispatch = (dispatch) => ({
  getAdmins: () => dispatch(getAdmins()),
  getOrganisation: () => dispatch(getOrganisation()),
  updateAdminsData: (newAdmins) => dispatch(updateAdminsData(newAdmins)),
  deleteAdmins: (Admins) => dispatch(deleteAdmins(Admins)),
  updateUsersPassword: (id) => dispatch(updateUsersPassword(id)),
});

const { getAdmin, deleteAdmin } = settingsapi;

function ShowAdmin(props) {
  const { getAdmins, updateAdminsData, deleteAdmins, updateUsersPassword } =
    actionDispatch(useDispatch());
  const organisation = useSelector(selectorGetOrganisation);
  const getColumnSearchProps = useSearch();
  const [selectedKeys, setSelectedKeys] = useState();
  const [activedata, setActivedata] = useState(true);
  const [admin, setAdmin] = useState();
  const [adminrow, setAdminrow] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isdetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orgdata, setOrgdata] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { Option } = Select;
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgID = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const Spindata = useSelector((item) => item.spinreducer);
  const admins = useSelector(selectorGetAdmin);
  const [resetModal, setResetModal] = useState(false);
  const [rowAdminId, setRowAdminId] = useState();
  const [rowName, setRowName] = useState();
  const [rowMail, setRowMail] = useState();
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedAdminId,setSelectedAdminId]=useState()

  useEffect(() => {
    getAdmins();
    getOrganisation();
    // getOrg();
  }, []);

  // const getOrg = async () => {
  //     const response = await getAdmin();

  //      if(response.status===200){
  //         const result = response.data.map(value => ({ ...value, key: value.admin_id }));
  //     setAdmin(result)
  //      }else{
  //         setAdmin([]);
  //      }
  // }

  const deleteAdm = async (id) => {
    await deleteAdmin(id);
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handelBlockModal = (data) => {
    setSelectedValue(data.status);
    setSelectedAdminId(data.admin_id)
    setOpenBlockModal(true);
  };
  
  const modalCancel = () => {
    setOpenBlockModal(false);
  };
  const handleRadioChange =async (e) => {
    setSelectedValue(e.target.value);
   
    const updateStatus={
      admin_id:selectedAdminId,
      status:e.target.value
    }
    
   let response=  await updateAdminStatus(updateStatus)
   
   getAdmins();
   modalCancel()

  }
  const columns = [
    // {
    //   title: "AdminId",
    //   dataIndex: "admin_id",
    //   ...getColumnSearchProps("admin_id"),
    // },
    // {
    //     title: 'Role',
    //     dataIndex: 'role',
    //     ...getColumnSearchProps('role'),
    // },
    {
      title: "Sl No",
      dataIndex: "sl_no",
      ...getColumnSearchProps("sl_no"),
    },
    {
      title: "Admin Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    // {
    //   title: "Username",
    //   dataIndex: "username",
    //   ...getColumnSearchProps("username"),
    // },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Organization",
      dataIndex: "orgName",
      ...getColumnSearchProps("orgName"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Address",
      dataIndex: "address",
      ...getColumnSearchProps("address"),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
      render: (text) => {
        let statusColor;
        switch (text) {
          case "Block":
            statusColor = "red";
            break;
          case "Active":
            statusColor = "green";
            break;
          default:
            statusColor = "default";
        }

        return <Badge status={statusColor} text={text} />;
      },
  },
  {
    title: "Status Update",
    dataIndex: "action",
    render: (text, record) => {
      return (
        <div>
          {/* <Button><EditOutlined /></Button> */}
          <Button onClick={() => handelBlockModal(record)}>
            <PoweroffOutlined />
          </Button>
        </div>
      );
    },
  },
    {
      title: "Reset Password",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => resetPasword(record)}>
              Reset
              <EllipsisOutlined />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => editRow(record)}>
              <EditOutlined />
            </Button>
            <Button onClick={() => deleteRow(record)}>
              {" "}
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const resetPasword = (data) => {
    setResetModal(true);
    setRowAdminId(data.admin_id);
    setRowName(data.name);
    setRowMail(data.email);
    form2.setFieldValue({
      name: data.name,
      email: data.email,
    });
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  useEffect(() => {
    form2.setFieldsValue({
      name: rowName,
      email: rowMail,
    });
  }, [rowName, rowMail]);

  const onResetPasswordFinish = async (value) => {
    let data = {
      admin_id: rowAdminId,
      password: value.password,
    };
    await updateUsersPassword(data);
    form2.resetFields();
    setResetModal(false);
  };
  const onResetPasswordFinishFailed = (error) => {
    console.log("ERROR", error);
  };

  const editRow = (data1) => {
    setIsModalVisible(true);
    setAdmin(data1.admin_id);
    form.setFieldsValue({
      admin_id: data1.admin_id,
      name: data1.name,
      address: data1.address,
      // username: data1.username,
      //password: data1.password,
      email: data1.email,
      phone: data1.phone,
      // role: "ADMIN",
      orgID: data1.orgID,
    });
  };
  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.admin_id]) });
  };

  //  direct call from api for delete

  const onConfirm = async (key) => {
    // let delresponse= await deleteAdmins(key);
    let delresponse = await deleteByAdminId(key);
    getAdmins();
  };
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };
  const handleCancel = () => {
    setIsDetailModalVisible(false);
    setIsModalVisible(false);
    setResetModal(false);
    getAdmins();
  };

  // const onSelectChange = (selectedRowKeys) => {
  //   setSelectedKeys(selectedRowKeys);
  // };

  // const rowSelection = {
  //   onChange: onSelectChange,
  // };
  // const onTableChange = (sorter) => {
  //   if (sorter.currentDataSource.length > 0) {
  //     setActivedata(true);
  //   } else {
  //     setActivedata(false);
  //   }
  // };

  const data = [];
  for (let i = 0; i < admins.length; i++) {
    data.push({
      sl_no: i + 1,
      admin_id: admins[i].admin_id,
      role: admins[i].role,
      name: admins[i].name,
      // nameId:admins[i].name + "-" + admins[i].admin_id,
      // username:admins[i].username,
      email: admins[i].email,
      orgID: admins[i].orgID,
      phone: admins[i].phone,
      address: admins[i].address,
      orgName: admins[i].orgName,
      status: admins[i].status,

    });
  }

  const onEditAdminFinish = async (values) => {
    let data = {
      admin_id: admin,
      name: values.name,
      email: values.email,
      //  username:values.username,
      password: values.password ? values.password : "",
      phone: values.phone,
      address: values.address,
    };

    //  direct call from Api for edit

    let response = await updateByAdminId(data);
    getAdmins();

    setIsModalVisible(false);
    // let response = await updateAdmins(data);
    // if (response) {
    //   await setAdminrow(data);
    //   await setIsModalVisible(false);
    // }
  };

  const onEditAdminFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //   const clear_fields = () => {
  //     // getAdmins(orgID, userId);
  //     form.setFieldsValue({
  //         name: adminrow.name,
  //         email: adminrow.email,
  //         username: adminrow.username,
  //         password: adminrow.password,
  //         phone: adminrow.phone,
  //         address: adminrow.address,
  //         orgID:adminrow.orgID,
  //     });
  // }

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">List Of Admin </div>
        <br />
        <div className="btn__">
          <Button className="excel">
            <CSVLink
              filename={"Admin_Table.csv"}
              data={data}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
        </div>
        <Table
          className="table"
          scroll={{ x: "80vw", y: "500px" }}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={10}
          style={{ paddingTop: "10px" }}
          // onChange={(filter, sorter, currentTable) =>
          //   onTableChange(filter, sorter, currentTable)
          // }
        />
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={700}
          centered={true}
          footer={null}
        >
          <div className="edit-project">Edit Admin</div>
          <br />
          <Form
            form={form}
            name="Edit-Admin"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditAdminFinish}
            onFinishFailed={onEditAdminFinishFailed}
          >
            <Form.Item
              name="name"
              label="Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter your Name !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
            >
              <Input placeholder="Name" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              className="input-field"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail !",
                },
                {
                  required: true,
                  message: "Please enter your E-mail !",
                },
              ]}
            >
              <Input placeholder="Email" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>

            {/* <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid Username !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Username" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item> */}

            {/* <Form.Item
              name="password"
              label="Password"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter your Password !",
                },
                {
                  pattern: /^(.{0,20})$/,
                  message: "please enter less than 20 characters only",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Password"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item> */}

            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please enter your Phonenumber !" },
                {
                  pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                  // pattern: /^[\d]{10}$/,
                  // message: "Contact number should be 10 digits",
                  message: "Please Enter The Correct Contact Number",
                },
              ]}
              className="input-field"
            >
              <Input
                type="number"
                placeholder="Phonenumber"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter your Address !",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 characters only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Address" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            {/* <Form.Item name="role" label="Select Role" className="input-field">
              <Select
                allowClear
                disabled
                defaultValue="ADMIN"
                style={{ width: "100%" }}
                placeholder="Please select Projects"
                options={[
                  {
                    value: "ADMIN",
                    label: "ADMIN",
                  },
                ]}
              >
                <Option key="ADMIN">ADMIN</Option>
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              name="orgID"
              label="Select organization"
              className="input-field"
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select Organization"
              >
                {organisation.map((item) => (
                  <Option value={item.orgID} key={item.orgID}>
                    {item.orgName}-{item.orgID}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item>
              <div className="btn-createAdmin">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
                {/* <Button type="primary" htmlType="submit" className="btn-submit"
                style={{borderRadius:"10px"}} 
                onClick={clear_fields} 
                >
                  <strong>Reset</strong>
                </Button> */}
              </div>
            </Form.Item>
          </Form>
          <br />
        </Modal>
        <Modal
          visible={resetModal}
          footer={null}
          onCancel={handleCancel}
          width={800}
        >
          <div className="edit__user">Reset User Password</div>
          <Form
            form={form2}
            name="reset_password"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onResetPasswordFinish}
            onFinishFailed={onResetPasswordFinishFailed}
          >
            {/* <Form.Item
              name="name"
              label="Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
              initialValue={rowName}
            >
                
              <Input placeholder="Name" disabled/>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              className="input-field"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your E-mail!",
                },
              ]}
              initialValue={rowMail}
            >
              <Input placeholder="Email" disabled/>
            </Form.Item> */}
            <Form.Item
              name="password"
              label="Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/,
                  message:
                    "Password must contain at least  uppercase letter,  lowercase letter, and  number, and be at least 6 to 12 characters long",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                validateConfirmPassword,
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
              <div className="btn-user">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ alignSelf: "center", borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          open={openBlockModal}
          onCancel={modalCancel}
          footer={null}
          title="Block Organization"
          width={400}
          centered={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Radio.Group 
            onChange={handleRadioChange}
            buttonStyle="solid" style={{ width: "45%" }}
            >
            <Radio.Button value="Block" style={{ background: selectedValue === 'Block' ? 'red' : '' }}>
            Block
          </Radio.Button>
          <Radio.Button value="Active" style={{ background: selectedValue === 'Active' ? 'green' : '' }}>
            Active
          </Radio.Button>
            </Radio.Group>
          </div>
        </Modal>
      </Spin>
    </>
  );
}

export default ShowAdmin;
