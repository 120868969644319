// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-title{
    font-size: x-large;
    font-weight: bold;
}

.work-category{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 10px;
}


@media screen and (max-width:600px){
    .work-category{
        box-shadow: none;
    }
}

main.ant-layout-content.site-layout-background{
    border-radius: 20px
}
`, "",{"version":3,"sources":["webpack://./src/components/resources/content/css/AddWorkCategory.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;AACjB;;;AAGA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".sub-title{\n    font-size: x-large;\n    font-weight: bold;\n}\n\n.work-category{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 10px;\n}\n\n\n@media screen and (max-width:600px){\n    .work-category{\n        box-shadow: none;\n    }\n}\n\nmain.ant-layout-content.site-layout-background{\n    border-radius: 20px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
