// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field{
    width: 45%;
    margin-right: 5%;
    display: inline-block;
}

.master-task{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.sub-title{
    font-size: x-large;
    font-weight: bold;
    /* font-style: italic; */
}

.btn-project{
    text-align:center;
}

.btn-project .btn-submit{
    margin-right: 10px;
    background-color: #FFFF00;
    color: black;
    border:#FFFF00;
    /* border-radius: 20px; */
}
.btn-submit:hover{
    background-color: white;
    color: #f1e206;
    border:1px solid #f1e206;
}

.btn-project .btn-cancel{
    margin-left: 10px;
    border-radius: 10px;
}
.btn-project .btn-cancel:hover{
    color: #f1e206;
    border:1px solid #f1e206;
}

.project-details{
    box-shadow: 5px 10px 18px #534040;
    border-radius: 10px;
    padding: 20px;
    margin-top: 50px;
}

.btn-add-level{
    border:1px solid #f1e206;
}
.btn-add-level:hover{
    border:1px solid #f1e206;
    color: #f1e206;
}

@media screen and (max-width:600px){
    .input-field{
        width: 100%;
        margin-right: 0;
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/qcc/content/css/Checklist.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,yBAAyB;AAC7B;AACA;IACI,uBAAuB;IACvB,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;QACX,eAAe;QACf,cAAc;IAClB;AACJ","sourcesContent":[".input-field{\n    width: 45%;\n    margin-right: 5%;\n    display: inline-block;\n}\n\n.master-task{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n    margin-bottom: 30px;\n}\n\n.sub-title{\n    font-size: x-large;\n    font-weight: bold;\n    /* font-style: italic; */\n}\n\n.btn-project{\n    text-align:center;\n}\n\n.btn-project .btn-submit{\n    margin-right: 10px;\n    background-color: #FFFF00;\n    color: black;\n    border:#FFFF00;\n    /* border-radius: 20px; */\n}\n.btn-submit:hover{\n    background-color: white;\n    color: #f1e206;\n    border:1px solid #f1e206;\n}\n\n.btn-project .btn-cancel{\n    margin-left: 10px;\n    border-radius: 10px;\n}\n.btn-project .btn-cancel:hover{\n    color: #f1e206;\n    border:1px solid #f1e206;\n}\n\n.project-details{\n    box-shadow: 5px 10px 18px #534040;\n    border-radius: 10px;\n    padding: 20px;\n    margin-top: 50px;\n}\n\n.btn-add-level{\n    border:1px solid #f1e206;\n}\n.btn-add-level:hover{\n    border:1px solid #f1e206;\n    color: #f1e206;\n}\n\n@media screen and (max-width:600px){\n    .input-field{\n        width: 100%;\n        margin-right: 0;\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
