import React, { useState } from "react";
import { Button, Table, Modal, message } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorShowCurrentStock,
  selectorGetItemInCurrentStockProjectWise,
} from "../../../../redux-core/materials/selectors";
import {
  getItemCodeWiseGrn,
  getItemCodeWiseRecievedprocess,
  getStockManagementData,
} from "../../../../redux-core/materials/actions";
import useSearch from "../../../../hooks/useSearch";
import ShowItemsWiseStockQtyFrom from "./ShowItemsWiseStockQtyFrom";
import { CSVLink } from "react-csv";
import { formatNumberWithCommas } from "../../../../utils/functions";
import { FaFileInvoice } from "react-icons/fa";

const actionDispatch = (dispatch) => ({
  getItemCodeWiseGrn: (itemCode, approved, projectID) =>
    dispatch(getItemCodeWiseGrn(itemCode, approved, projectID)),
  getItemCodeWiseRecievedprocess: (itemCode, projectID) =>
    dispatch(getItemCodeWiseRecievedprocess(itemCode, projectID)),
  getStockManagementData: (itemCode, projectID) =>
    dispatch(getStockManagementData(itemCode, projectID)),
});

const ShowMaterialStock = (props) => {
  const currentStockShow = useSelector(selectorShowCurrentStock);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [array, setArray] = useState("");
  const {
    getItemCodeWiseGrn,
    getItemCodeWiseRecievedprocess,
    getStockManagementData,
  } = actionDispatch(useDispatch());
  const getColumnSearchProps = useSearch();

  //------------------------------------ write the for lop for table data show ---------------------------------
  const tableData = [];
  for (let i = 0; i < getShowItemInCurrentStockProjectWise.length; i++) {
    tableData.push({
      // id:getShowItemInCurrentStockProjectWise[i].id,
      id: i + 1,
      //item_id:getShowItemInCurrentStockProjectWise[i].item_id,
      item_code: getShowItemInCurrentStockProjectWise[i].item_code,
      Item_name: getShowItemInCurrentStockProjectWise[i].Item_name,

      rcv_qty:
        Number(getShowItemInCurrentStockProjectWise[i].rcv_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].without_po_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].site_transfer_qty),
      accept_qty:
        Number(getShowItemInCurrentStockProjectWise[i].accept_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].without_po_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].replace_qty) +
        Number(getShowItemInCurrentStockProjectWise[i].site_transfer_qty),
      issue_qty: getShowItemInCurrentStockProjectWise[i].issue_qty,
      total_qty: getShowItemInCurrentStockProjectWise[i].total_qty,
      item_rate: getShowItemInCurrentStockProjectWise[i].item_rate,
      // without_po_qty: getShowItemInCurrentStockProjectWise[i].without_po_qty,
      // replace_qty: getShowItemInCurrentStockProjectWise[i].replace_qty,
      // site_transfer_qty: getShowItemInCurrentStockProjectWise[i].site_transfer_qty,
      // transfer_to_other_site: getShowItemInCurrentStockProjectWise[i].transfer_to_other_site,
    });
  }

  // let tableData = [];
  // let itemCodeMap = {};
  // for (let i = 0; i < currentStockShow.length; i++) {
  //   const currentItem = currentStockShow[i];
  //   const itemCode = currentItem.item_code;
  //   if (itemCodeMap.hasOwnProperty(itemCode)) {
  //     // Item code already exists, update the quantities
  //     itemCodeMap[itemCode].rcv_qty += currentItem.rcv_qty;
  //     itemCodeMap[itemCode].accept_qty += currentItem.accept_qty;
  //     itemCodeMap[itemCode].issue_qty += currentItem.issue_qty;
  //     itemCodeMap[itemCode].total_qty += currentItem.total_qty;
  //   } else {
  //     // Item code does not exist, add it to the map
  //     itemCodeMap[itemCode] = {
  //       //id: currentItem.id,
  //       id: i + 1,
  //       item_id: currentItem.item_id,
  //       item_code: itemCode,
  //       material_name: currentItem.material_name,
  //       hsn_code: currentItem.hsn_code,
  //       uom: currentItem.uom,
  //       rcv_qty: currentItem.rcv_qty,
  //       accept_qty: currentItem.accept_qty,
  //       issue_qty: currentItem.issue_qty,
  //       total_qty: currentItem.total_qty,
  //     };
  //   }
  // }
  // Convert the itemCodeMap to an array of objects
  // for (const itemCode in itemCodeMap) {
  //   tableData.push(itemCodeMap[itemCode]);
  // }
  //

  //-------------write the function for table data item code wise Grn data show ---------------

  const HandelOpenItemWiseGrn = (record) => {
    setIsModalOpen(true);
    getItemCodeWiseGrn(record, 1, props.projectID);
    getItemCodeWiseRecievedprocess(record, props.projectID);
    getStockManagementData(record, props.projectID);
  };

  //--modalclose function-----

  const modalClose = () => {
    setIsModalOpen(false);
  };

  //----table Colunm--------
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Receipts Statement",
      dataIndex: "item_code",
      render: (record) => {
        return (
          <Button
            className="button-61"
            onClick={() => HandelOpenItemWiseGrn(record)}
          >
            Item Receipts Statement
          </Button>
        );
      },
      width: 200,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      ...getColumnSearchProps("item_code"),
    },
    {
      title: "Item Name",
      dataIndex: "Item_name",
      ...getColumnSearchProps("Item_name"),
    },

    {
      title: "Quantity Received",
      dataIndex: "rcv_qty",
    },
    {
      title: "Quantity Accepted",
      dataIndex: "accept_qty",
    },
    {
      title: "Quantity Issued",
      dataIndex: "issue_qty",
    },
    {
      title: "Quantity Balance",
      dataIndex: "total_qty",
    },
    {
      title: "Total Amount",
      dataIndex: "item_rate",
    },

    // {
    //   title: "WithOut Po QTY",
    //   dataIndex: "without_po_qty",
    // },
    // {
    //   title: "Replace QTY",
    //   dataIndex: "replace_qty",
    // },
    // {
    //   title: "Recive Other Site QTY",
    //   dataIndex: "site_transfer_qty",
    // },
    // {
    //   title: "Transfer Other Site QTY",
    //   dataIndex: "transfer_to_other_site",
    // },
  ];

  const backToAllProject = () => {
    props.backProject();
  };

  return (
    <>
      {/* <div className="btn__">
        <Button className="excel">
          <CSVLink // CSV Of List Work Category
            filename={"Project Material Stock.csv"}
            data={tableData}
            className="excel"
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export to Excel
          </CSVLink>
        </Button>
      </div>
      <div>
        <div className="title-show-project">Current Stock</div> <br />
        <Button onClick={() => backToAllProject()}>All Projects</Button>
        <br /> <br />
        <Table
          scroll={{ x: "100vw" }}
          columns={columns}
          dataSource={Array.isArray(array) ? array : tableData}
          pagination={10}
        />
      </div> */}

      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="   font-montserrat">
            <button
              onClick={() => backToAllProject()}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              All Projects
            </button>
          </div>
          <div className=" font-semibold  text-2xl">Current Stock</div>
          <div className="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
            <CSVLink // CSV Of List Work Category
              filename={"Project Material Stock.csv"}
              data={tableData}
              className=""
              // onClick={() => {
              //   message.success("The file is downloading");
              // }}
            >
              Export to Excel
            </CSVLink>
          </div>
        </div>
      </div>
      {/* new table shivam kashyap 21 june 2024  */}
      <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
        <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
          <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
            <div className="flex flex-col items-start w-[150px]">
              <p className="text-sm font-semibold text-gray-900 w-full">ID</p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Item Name
              </p>

              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Item Code
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Item Receipts Statement
              </p>
            </div>
            <div className="flex flex-col items-start w-[200px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Quantity Received
              </p>{" "}
              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Quantity Accepted
              </p>{" "}
              <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                Quantity Issued
              </p>
            </div>
            <div className="flex flex-col items-start w-[250px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Quantity Balance
              </p>
            </div>{" "}
            <div className="flex flex-col items-start w-[250px]">
              <p className="text-sm font-semibold text-gray-900 w-full">
                Total Amount
              </p>
            </div>
          </li>
        </ul>{" "}
        <ul
          role="list"
          className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
        >
          {tableData && tableData.length
            ? tableData.map((item, key) => (
                <li
                  key={key}
                  className={`flex justify-between items-center px-5 py-2 ${
                    key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                  }`}
                >
                  <div className="flex flex-col items-start w-[150px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.id ? item.id : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.Item_name ? item.Item_name : "-"}
                    </p>

                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      {item?.item_code ? item.item_code : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <a
                      onClick={() => HandelOpenItemWiseGrn(item.upload_invoice)}
                      href="#"
                      class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                      data-te-toggle="tooltip"
                      title="Item Receipts Statement"
                    >
                      <FaFileInvoice
                        className=" text-top_nav_blue-300 "
                        size={18}
                      />
                    </a>
                  </div>
                  <div className="flex flex-col items-start w-[200px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.rcv_qty
                        ? formatNumberWithCommas(item.rcv_qty)
                        : "-"}
                    </p>{" "}
                    <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                      {item?.accept_qty
                        ? formatNumberWithCommas(item.accept_qty)
                        : "-"}
                    </p>{" "}
                    <p className="mt-1 text-xs leading-5  text-red-500 w-full">
                      {item?.issue_qty
                        ? formatNumberWithCommas(item.issue_qty)
                        : "-"}
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-[250px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.total_qty
                        ? formatNumberWithCommas(item.total_qty)
                        : "-"}
                    </p>
                  </div>{" "}
                  <div className="flex flex-col items-start w-[250px]">
                    <p className="text-sm font-semibold text-gray-900 w-full">
                      {item?.item_rate
                        ? formatNumberWithCommas(item.item_rate)
                        : "-"}
                    </p>
                  </div>
                </li>
              ))
            : "No records found..."}
        </ul>
      </div>

      <Modal
        width={1950}
        footer={null}
        visible={isModalOpen}
        onCancel={modalClose}
      >
        <ShowItemsWiseStockQtyFrom />
      </Modal>
    </>
  );
};

export default ShowMaterialStock;
