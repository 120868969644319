import React, { useState } from "react";
import "antd/dist/antd.min.css";
import "./css/createAdmin.css";
import { Form, Input, Button, Select, Spin, option } from "antd";
import { useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import axios from "axios";
import { settingsapi } from "../../../cognisite-api";

const { createAdmin, getOrganization } = settingsapi;
function CreateAdmin() {
  const logindetails = useSelector(makeSelectLoginDetail);

  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [form] = Form.useForm();
  const { Option } = Select;
  const Spindata = useSelector((item) => item.spinreducer);
  const [orgdata, setOrgdata] = useState([]);
  const getOrganizationdetails = async () => {
    let response = await getOrganization();
    setOrgdata(response.organisation);
  };
  React.useEffect(() => {
    getOrganizationdetails();
  }, []);
  const OnAddCreateAdminFinish = async (value) => {
    let data = {
      // ...value,
      // "orgID": orgId,
      name: value.name,
      email: value.email,
      username: value.username,
      password: value.password,
      phone: value.phone,
      address: value.address,
      orgID: value.orgID,
      createdBy: userId,
      attempt: "2",
      del_status: "0",
      role: "ADMIN",
      status:"Active"
    };
    const response = await createAdmin(data);
    // console.log("response", response);
    if (response) {
      await clear_fields();
    }
  };
  const onAddCreateAdminFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const clear_fields = () => {
    // console.log("dhcbh");
    form.setFieldsValue({
      name: "",
      address: "",
      username: "",
      password: "",
      confirm_password: "",
      email: "",
      phone: "",
      role: "ADMIN",
      orgID: [],
    });
  };
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  // search function in select dropdown
  const handleSearch = (input, option) => {
    const optionText = option.children.toString(); // Convert to string
    return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="add-project">
          <div className="title-show-project">Create Admin</div>
          <Form
            form={form}
            name="add-createAdmin"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={OnAddCreateAdminFinish}
            onFinishFailed={onAddCreateAdminFailed}
          >
            <Form.Item
              name="name"
              label="Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter your Name !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
            >
              <Input placeholder="Name" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              className="input-field"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail !",
                },
                {
                  required: true,
                  message: "Please enter your E-mail !",
                },
              ]}
            >
              <Input placeholder="Email" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            {/* <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid Username !",
                },
                {
                  pattern: /^(.{0,100})$/,
                  message: "please enter less than 100 characters only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Username" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item> */}
            <Form.Item
              name="password"
              label="Password"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,12}$/,
                  message:
                    "Password must contain at least  uppercase letter,  lowercase letter, and  number, and be at least 6 to 12 characters long",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Password"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              className="input-field"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                validateConfirmPassword,
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone No"
              rules={[
                { required: true, message: "Please enter your Phonenumber !" },
                {
                  pattern: /^(?:\+91|0)?[2-9]\d{9}$/, // Indian landline pattern
                  // pattern: /^[\d]{10}$/,
                  // message: "Contact number should be 10 digits",
                  message: "Please Enter The Correct Contact Number",
                },
              ]}
              className="input-field"
            >
              <Input
                type="number"
                placeholder="Phonenumber"
                onKeyDown={(e) => onKeydown(e)}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please enter your Address !",
                },
                {
                  pattern: /^(.{0,300})$/,
                  message: "please enter less than 300 characters only",
                },
              ]}
              className="input-field"
            >
              <Input placeholder="Address" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            {/* <Form.Item name="role" label="Role" className="input-field">
              <Select
                allowClear
                disabled
                defaultValue="ADMIN"
                style={{ width: "100%" }}
                placeholder="Please select Projects"
                options={[
                  {
                    value: "ADMIN",
                    label: "ADMIN",
                  },
                ]}
              >
                <Option key="ADMIN">ADMIN</Option>
              </Select>
            </Form.Item> */}
            <Form.Item
              name="orgID"
              label="Select organization"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Organization!",
                },
              ]}
            >
              <Select
                 showSearch
                 allowClear
                 filterOption={handleSearch}
                style={{ width: "100%" }}
                placeholder="Please select Organization"
              >
                {orgdata.map((item) => (
                  <Option value={item.orgID} key={item.orgID}>
                    {item.orgName}--{item.orgID}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <div className="btn-createAdmin">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button onClick={clear_fields} className="btn-cancel">
                  <strong>Reset</strong>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </>
  );
}
export default CreateAdmin;
