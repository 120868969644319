import React, { Suspense, useCallback, useState } from "react";
import { Layout, Menu, Spin } from "antd";
import {
  MenuUnfoldOutlined,
  // MenuFoldOutlined,
  // DesktopOutlined,
  // TeamOutlined,
  // FileAddOutlined,
  // LikeOutlined,
  // HomeOutlined
} from "@ant-design/icons";
import "./SideNavResources.css";
import "antd/dist/antd.min.css";
import MediaQuery from "react-responsive";

import Dashboard from "../content/Dashboard";
import TopNavbar from "../../settings/topnavbar/TopNavbar";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";
import EquipmentDeployment from "../content/EquipmentDeployment";
import ListEquipmentDeployment from "../content/ListEquipmentDeployment";
import EquipmentDeploymenetsummary from "../content/EquipmentDeployementSummary";

const AddWorkCategory = React.lazy(() => import("../content/AddWorkCategory"));
const ListWorkCategory = React.lazy(() =>
  import("../content/ListWorkCategory")
);
const AddSubContractor = React.lazy(() =>
  import("../content/AddSubContractor")
);
const ListSubContractor = React.lazy(() =>
  import("../content/ListSubContractor")
);
const AddLabourRegister = React.lazy(() =>
  import("../content/AddLabourRegister")
);
const ListLabourRegister = React.lazy(() =>
  import("../content/ListLabourRegister")
);
const AddEquipmentRegister = React.lazy(() =>
  import("../content/AddEquipmentRegister")
);
const ListEquipmentRegister = React.lazy(() =>
  import("../content/ListEquipmentRegister")
);
// const LabourDeployment = React.lazy(() => import('../content/LabourDeployment'));
const LabourDeployment = React.lazy(() =>
  import("../content/LabourDeployment")
);
const ListLabourDeployment = React.lazy(() =>
  import("../content/ListLabourDeployment")
);
const Deploymentsummary = React.lazy(() =>
  import("../content/Deploymentsummary")
);

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

function SideNavResources() {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [content, setContent] = useState("1");

  const onMenuClick = useCallback((value) => {
    setContent(value);
  }, []);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minWidth: "100px" }}
        >
          <div className="logo">
            {collapsed ? (
              <MenuUnfoldOutlined
                style={{ paddingTop: 18 }}
                className="trigger"
                onClick={toggle}
              />
            ) : logindetails.organisation.image === null ? (
              <h4>{logindetails.organisation.orgName}</h4>
            ) : (
              <img
                width="70px"
                src={logindetails.organisation.image}
                // src= "cognisie_logo_new.png"
                alt={logindetails.organisation.orgNam}
              />
            )}
            {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                          
                           
                        })} */}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => onMenuClick(e.key)}
          >
            <Menu.Item
              key="1"
              icon={
                <img src="Dashboard.png" className="icon_img" alt="Dashboard" />
              }
              style={{ textAlign: "center" }}
            >
              <div>Dashboard</div>
            </Menu.Item>

            <SubMenu
              key="sub1"
              icon={
                <img
                  src="Site_incharge.png"
                  className="icon_img"
                  alt="workcategory"
                />
              }
              title="Work Category"
            >
              <Menu.Item key="2">Add Category</Menu.Item>
              <Menu.Item key="3">List Category</Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub2"
              icon={
                <img
                  src="Supervisor_create.png"
                  className="icon_img"
                  alt="subcontractor"
                />
              }
              title="Vendor"
            >
              <Menu.Item key="4">Add Vendor</Menu.Item>
              <Menu.Item key="5">List of Vendor</Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub3"
              icon={
                <img
                  src="drawing_user.png"
                  className="icon_img"
                  alt="labourregister"
                />
              }
              title="Labour Register"
            >
              <Menu.Item key="6">Add Labour Register</Menu.Item>
              <Menu.Item key="7">List Labour Register</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub4"
              icon={
                <img
                  src="drawing_user.png"
                  className="icon_img"
                  alt="labourregister"
                />
              }
              title="Equipment Register"
            >
              <Menu.Item key="8">Add Equipment Register</Menu.Item>
              <Menu.Item key="9">List Equipment Register</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub5"
              icon={
                <img
                  src="drawing_user.png"
                  className="icon_img"
                  alt="labourdeployment"
                />
              }
              title="Labour Deployment"
            >
              <Menu.Item key="10">Add Labour Deployment</Menu.Item>
              <Menu.Item key="11">List Labour Deployment</Menu.Item>
              <Menu.Item key="12">Deployment Summary</Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub6"
              icon={
                <img
                  src="drawing_user.png"
                  className="icon_img"
                  alt="labourdeployment"
                />
              }
              title="Equipment Deployment"
            >
              <Menu.Item key="13">Add Equipment Deployment</Menu.Item>
              <Menu.Item key="14">List Equipment Deployment</Menu.Item>
              {/* <Menu.Item key="15">Equipment Deployment Summary</Menu.Item> */}
            </SubMenu>

            {/* <SubMenu key="sub6" icon={<img src="task_create.png"className='icon_img' alt='labourdeployment' /> } title="Employee Deployment">
                            <Menu.Item key="13">Add Employee Deployment</Menu.Item>
                            <Menu.Item key="14">List Employee Deployment</Menu.Item>
                            <Menu.Item key="15">Deployment Summary</Menu.Item>
                        </SubMenu> */}

            {/* <SubMenu key="sub4" icon={<HomeOutlined />} title="User">
                            <Menu.Item key="8">User Create</Menu.Item>
                            <Menu.Item key="9">Users Show</Menu.Item>
                        </SubMenu> */}
          </Menu>
        </Sider>

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger"
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt="logo"
                />
              )}
            </div>
          </MediaQuery>
          {/* <TopNavbar name="Resources" collapsed={!collapsed} toggle={toggle} /> */}
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              overflow: "scroll",
            }}
          >
            {content === "1" ? <Dashboard /> : <></>}
            {content === "2" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AddWorkCategory />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "3" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListWorkCategory />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "4" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AddSubContractor />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "5" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListSubContractor />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "6" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AddLabourRegister />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "7" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListLabourRegister />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "8" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <AddEquipmentRegister />
              </Suspense>
            ) : (
              <></>
            )}
            {content === "9" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListEquipmentRegister />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "10" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <LabourDeployment />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "11" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListLabourDeployment />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "12" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <Deploymentsummary />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "13" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <EquipmentDeployment />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "14" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <ListEquipmentDeployment />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {content === "15" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                <EquipmentDeploymenetsummary />{" "}
              </Suspense>
            ) : (
              <></>
            )}
            {/* {content === "13" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><Deploymentsummary /> </Suspense> : <></>} */}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default SideNavResources;
