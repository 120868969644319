import React, { Suspense, useCallback, useState } from 'react';
import { Layout, Menu, Spin } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DesktopOutlined,
    TeamOutlined,
    FileAddOutlined,
    LikeOutlined,
    HomeOutlined
} from '@ant-design/icons';
import './SideNavUserManagement.css';
import 'antd/dist/antd.min.css'
import MediaQuery from 'react-responsive';

import Dashboard from '../content/Dashboard';
import TopNavbar from '../../settings/topnavbar/TopNavbar';
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';
import {  useSelector } from 'react-redux';

const SiteInChargeCreate = React.lazy(() => import('../content/SiteInChargeCreate'));
const SiteInChargeShow = React.lazy(() => import('../content/SiteInChargeShow'));
const SupervisorCreate = React.lazy(() => import('../content/SupervisorCreate'));
const SupervisorShow = React.lazy(() => import('../content/SupervisorShow'));
const CreateDrawingUser = React.lazy(() => import('../content/CreateDrawingUser'));
const ListDrawingUser = React.lazy(() => import('../content/ListDrawingUser'));
const UserCreate = React.lazy(() => import('../content/UserCreate'));
const UsersShow = React.lazy(() => import('../content/UsersShow'));
const { Sider, Content } = Layout;
const { SubMenu } = Menu;

function SideNavUserManagement() {
    const [collapsed, setCollapsed] = useState(false);
    const logindetails = useSelector(makeSelectLoginDetail);
    const dataspin = useSelector((state)=>state.spinreducer);



    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const [content, setContent] = useState("1");

    const onMenuClick = useCallback((value) => {
        setContent(value);
    }, []);
    return (
        <>
            <Layout style={{ height: '100vh' }}>
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ minWidth: '100px' }}>
                <div className="logo" >
                        {collapsed?(
                            <MenuUnfoldOutlined style={{paddingTop:18}} className= 'trigger' onClick={toggle} />
                        ):(
                        <img width="70px" src={logindetails.organisation.image} alt="logo" />
                        )}
                        {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                          
                           
                        })} */}
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={(e) => onMenuClick(e.key)}>

                        <Menu.Item key="1" icon={<img src="Dashboard.png" className='icon_img' alt='Dashboard'/>} style={{ textAlign: 'center' }}><div>Dashboard</div></Menu.Item>

                        <SubMenu key="sub1" icon={<img src="Site_incharge.png" className='icon_img' alt='siteincharge'/>} title="Site In-Charge">
                            <Menu.Item key="2">Site In-Charge create</Menu.Item>
                            <Menu.Item key="3">Site In-Charge show</Menu.Item>
                        </SubMenu>

                        <SubMenu key="sub2" icon={<img src="Supervisor_create.png"  className='icon_img' alt='supervisor' />} title="Supervisor">
                            <Menu.Item key="4">Supervisor create</Menu.Item>
                            <Menu.Item key="5">Supervisor show</Menu.Item>
                        </SubMenu>

                        <SubMenu key="sub3" icon={<img src="drawing_user.png"className='icon_img' alt='drawinguser' /> } title="Drawing User">
                            <Menu.Item key="6">Create Drawing User</Menu.Item>
                            <Menu.Item key="7">List Drawing User</Menu.Item>
                        </SubMenu>

                        {/* <SubMenu key="sub4" icon={<HomeOutlined />} title="User">
                            <Menu.Item key="8">User Create</Menu.Item>
                            <Menu.Item key="9">Users Show</Menu.Item>
                        </SubMenu> */}
                    </Menu>
                </Sider>

                <Layout className="site-layout">
                    <MediaQuery maxWidth={600}>
                    <div className="open-btn">
                        {collapsed?(
                            <MenuUnfoldOutlined style={{paddingTop:18}} className= 'trigger' onClick={toggle} />
                        ):
                        <img className='logo' width="70px" src={logindetails.organisation.image} alt="logo" />
                        }
                        </div>
                    </MediaQuery>
                    <TopNavbar name="User Management" collapsed={!collapsed} toggle={toggle}/>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                            overflow: 'scroll' 
                        }}
                    >
                        {content === "1" ? <Dashboard /> : <></>}
                        {content === "2" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><SiteInChargeCreate /></Suspense> : <></>}
                        {content === "3" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><SiteInChargeShow /></Suspense> : <></>}
                        {content === "4" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><SupervisorCreate /></Suspense> : <></>}
                        {content === "5" ? <Suspense fallback={<Spin size='large'  spinning={dataspin.spin}className='spin__component' />}><SupervisorShow /></Suspense> : <></>}
                        {content === "6" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><CreateDrawingUser /></Suspense> : <></>}
                        {content === "7" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><ListDrawingUser /></Suspense> : <></>}
                        {content === "8" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><UserCreate /></Suspense> : <></>}
                        {content === "9" ? <Suspense fallback={<Spin size='large' spinning={dataspin.spin} className='spin__component' />}><UsersShow /> </Suspense> : <></>}
                    </Content>
                </Layout>

            </Layout>
        </>
    );
}

export default SideNavUserManagement;