import React, { useState } from "react";
import { Button, Modal, Table } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
//import { getEquipmentDeploymentSummary } from "../../../../redux-core/resources/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorGetEquipmentDeployHeader,
  selectorGetEquipmentDeployList,
} from "../../../../redux-core/resources/selectors";
import { useEffect } from "react";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import ShowEquipmentDeployTable from "../ShowEquipmentDeployTable";
import { getEquipmentDeployTableList } from "../../../../redux-core/resources/actions";
import moment from "moment";

const actionDispatch = (dispatch) => ({
  getEquipmentList: (orgID, headerID) =>
    dispatch(getEquipmentDeployTableList(orgID, headerID)),
});

const ListEquipmentDeploymwntTable = (props) => {
  const { getEquipmentList } = actionDispatch(useDispatch());
  // console.log("}}}}}}}}}",props.projectId)
  const getEquipmentDeployList = useSelector(selectorGetEquipmentDeployList);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const allEquipmentDeployHeader = useSelector(
    selectorGetEquipmentDeployHeader
  );
  // console.log("--555555--", orgId);

  // console.log("allEquipmentDeployHeader", allEquipmentDeployHeader);

  const tableData = [];
  for (let i = 0; i < allEquipmentDeployHeader.length; i++) {
    // let dueDate = new Date(allEquipmentDeployHeader[i].deployment_date);
    // dueDate.setDate(dueDate.getDate() + 1);

    // let dueDate = new Date(allEquipmentDeployHeader[i].deployment_date);
    // const timeZoneOffset = dueDate.getTimezoneOffset(); // Get the time zone offset in minutes
    // dueDate.setMinutes(dueDate.getMinutes() - timeZoneOffset); // Adjust the date by the offset
    // const formattedDate = dueDate.toISOString().slice(0, 10);

    tableData.push({
      id: allEquipmentDeployHeader[i].id,
      deployment_date: moment(allEquipmentDeployHeader[i].deployment_date).format ("DD-MM-YYYY"),
      project_id: allEquipmentDeployHeader[i].project_id,
      project_name: allEquipmentDeployHeader[i].project_name,
      project_location_id: allEquipmentDeployHeader[i].project_location_id,
    });
  }

  console.log("tableData", tableData)
  const [visibleDeploymenetPaage, setVisibleDeploymenetPaage] = useState(false);

  const showModalDeploymenet = async (value) => {
    setVisibleDeploymenetPaage(true);
    // console.log(value);
    await getEquipmentList(orgId, value.id);
  };

  const handleDeploymenetCancel = () => {
    setVisibleDeploymenetPaage(false);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "deployment_date",
      key: "deployment_date",
    },
    {
      title: "Project",
      dataIndex: "project_name",
      key: "project_id",
    },
    {
      title: "Location",
      dataIndex: "project_location_id",
      key: "project_location_id",
    },
    // {
    //   title: "Status",
    //   dataIndex: "progress_status",
    //   key: "progress_status",
    // }

    {
      title: "Action",
      dataIndex: "id",

      render: (text, record) => (
        <div>
          <Button onClick={() => showModalDeploymenet(record)}>
            <EyeOutlined />
          </Button>
          <Modal
            width="85%"
            style={{ top: 80 }}
            visible={visibleDeploymenetPaage}
            onCancel={handleDeploymenetCancel}
            footer={null}
            destroyOnClose={true}
          >
            <ShowEquipmentDeployTable />
          </Modal>
          {/* <Button >
      <DownloadOutlined 
      // onClick={() => generatePDF(ProjIdByDeploymentList)}
      />
        </Button> */}
        </div>
      ),
    },
  ];
  return (
    <>
      <div>
        <br />
        <br />
      </div>
      <Table
        pagination={10}
        dataSource={tableData}
        columns={columns}
        scroll={{ x: "90vw" }}
      />
      <br />

      {/* <Button
        // onClick={ReloadPage}
        className="btn-cancel"
      >
        Cancel
      </Button> */}
    </>
  );
};

export default ListEquipmentDeploymwntTable;
