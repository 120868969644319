// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field{
    width: 45%;
    margin-right: 5%;
    display: inline-block;
}

.dropdown-selector{
    width:95%;
}

.btn-ehs{
    text-align:center;
}

.btn-ehs .btn-submit{
    margin-right: 10px;
    background-color: #FFFF00;
    color: black;
    border:#FFFF00;
    /* width: 20%; */
    /* border-radius: 20px; */
}
.btn-submit:hover{
    background-color: white;
    color: #f1e206;
    border:1px solid #f1e206;
}

.btn-ehs .btn-cancel{
    margin-left: 10px;
    border-radius: 10px;

}
.btn-ehs .btn-cancel:hover{
    color: #f1e206;
    border:1px solid #f1e206;
}

@media screen and (max-width:600px){
    .input-field{
        width: 100%;
        margin-right: 0;
        display: block;
    }

    /* .btn-ehs .btn-submit{
        width: 50%;
    } */
}`, "",{"version":3,"sources":["webpack://./src/components/Risk/contents/Forms/css/EhsCreateForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,yBAAyB;AAC7B;AACA;IACI,uBAAuB;IACvB,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;;AAEvB;AACA;IACI,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI;QACI,WAAW;QACX,eAAe;QACf,cAAc;IAClB;;IAEA;;OAEG;AACP","sourcesContent":[".input-field{\n    width: 45%;\n    margin-right: 5%;\n    display: inline-block;\n}\n\n.dropdown-selector{\n    width:95%;\n}\n\n.btn-ehs{\n    text-align:center;\n}\n\n.btn-ehs .btn-submit{\n    margin-right: 10px;\n    background-color: #FFFF00;\n    color: black;\n    border:#FFFF00;\n    /* width: 20%; */\n    /* border-radius: 20px; */\n}\n.btn-submit:hover{\n    background-color: white;\n    color: #f1e206;\n    border:1px solid #f1e206;\n}\n\n.btn-ehs .btn-cancel{\n    margin-left: 10px;\n    border-radius: 10px;\n\n}\n.btn-ehs .btn-cancel:hover{\n    color: #f1e206;\n    border:1px solid #f1e206;\n}\n\n@media screen and (max-width:600px){\n    .input-field{\n        width: 100%;\n        margin-right: 0;\n        display: block;\n    }\n\n    /* .btn-ehs .btn-submit{\n        width: 50%;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
