import React, { useState, useEffect, Children } from "react";
import { Button, Table, Modal } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import { selectorShowCurrentStock } from "../../../../redux-core/materials/selectors";
import ItemReceiptsStatement from "./ItemReceiptsStatement";
import {
  getItemCodeWiseGrn,
  getItemWiseCurrentStock,
  insertItemInCurrentStock,
  getCurrentStockItemCodeWise,
} from "../../../../redux-core/materials/actions";
import useSearch from "../../../../hooks/useSearch";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { CiReceipt } from "react-icons/ci";
import { FaReceipt } from "react-icons/fa";

const actionDispatch = (dispatch) => ({
  getItemCodeWiseGrn: (itemCode, approved, projectID) =>
    dispatch(getItemCodeWiseGrn(itemCode, approved, projectID)),
  getItemWiseCurrentStock: (itemCode, projectID) =>
    dispatch(getItemWiseCurrentStock(itemCode, projectID)),
  getCurrentStockItemCodeWise: (itemCode, projectID) =>
    dispatch(getCurrentStockItemCodeWise(itemCode, projectID)),
  insertItemInCurrentStock: (itemCurrentStock) =>
    dispatch(insertItemInCurrentStock(itemCurrentStock)),
});

function ShowMaterialList(props) {
  const currentStockShow = useSelector(selectorShowCurrentStock);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [array, setArray] = useState("");
  const {
    getItemCodeWiseGrn,
    getItemWiseCurrentStock,
    insertItemInCurrentStock,
    getCurrentStockItemCodeWise,
  } = actionDispatch(useDispatch());
  const getColumnSearchProps = useSearch();
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [itemCode, setItemCode] = useState();

  let tableData = [];
  let itemCodeMap = {};

  for (let i = 0; i < currentStockShow.length; i++) {
    const currentItem = currentStockShow[i];
    const itemCode = currentItem.item_code;

    if (itemCodeMap.hasOwnProperty(itemCode)) {
      // Item code already exists, update the quantities
      itemCodeMap[itemCode].rcv_qty += currentItem.rcv_qty;
      itemCodeMap[itemCode].accept_qty += currentItem.accept_qty;
      itemCodeMap[itemCode].issue_qty += currentItem.issue_qty;
      itemCodeMap[itemCode].total_qty += currentItem.total_qty;
    } else {
      // Item code does not exist, add it to the map
      itemCodeMap[itemCode] = {
        //id: currentItem.id,
        id: i + 1,
        item_id: currentItem.item_id,
        item_code: itemCode,
        material_name: currentItem.material_name,
        hsn_code: currentItem.hsn_code,
        uom: currentItem.uom,
        rcv_qty: currentItem.rcv_qty,
        accept_qty: currentItem.accept_qty,
        issue_qty: currentItem.issue_qty,
        total_qty: currentItem.total_qty,
      };
    }
  }

  // Convert the itemCodeMap to an array of objects
  for (const itemCode in itemCodeMap) {
    tableData.push(itemCodeMap[itemCode]);
  }

  useEffect(() => {
    getItemWiseCurrentStock(itemCode, props.projectID);
    getCurrentStockItemCodeWise(itemCode, props.projectID);
  }, [itemCode]);

  const HandelOpenItemWiseGrn = async (record) => {
    setIsModalOpen(true);
    getItemCodeWiseGrn(record, 1, props.projectID);
    getCurrentStockItemCodeWise(record, props.projectID);
    setItemCode(record);
    const StoreData = {
      item_code: record,
      orgID: orgId,
      project_id: props.projectID,
    };
    console.log("Storedata", StoreData);
    await insertItemInCurrentStock(StoreData);
    getItemWiseCurrentStock(record, props.projectID);
  };
  const modalClose = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Receipts Statement",
      dataIndex: "item_code",
      render: (record) => {
        // //console.log("-----",record)
        return (
          <Button
            className="button-61"
            onClick={() => HandelOpenItemWiseGrn(record)}
          >
            Item Receipts Statement
          </Button>
        );
      },
      width: 200,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      ...getColumnSearchProps("item_code"),
    },
    {
      title: "Item Name",
      dataIndex: "material_name",
      ...getColumnSearchProps("material_name"),
    },
    {
      title: "Hsn Code",
      dataIndex: "hsn_code",
      ...getColumnSearchProps("hsn_code"),
    },
    {
      title: "UOM",
      dataIndex: "uom",
    },
  ];
  const backToAllProject = () => {
    props.backProject();
  };

  useEffect(() => {
    console.log("tableData", tableData);
  }, [tableData]);

  return (
    <>
      <div>
        {/* <Button onClick={() => backToAllProject()}>All Projects</Button> */}
        <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col ">
          <div className=" flex justify-between mt-3  mx-11 ">
            <div className="   font-montserrat flex flex-col gap-y-2">
              <button
                onClick={() => backToAllProject()}
                type="button"
                class="flex  w-fit justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                All Projects
              </button>
            </div>
            <div className=" font-semibold  text-2xl"></div>
            <div className=" font-montserrat"></div>
          </div>
        </div>
        {/* <Table
          scroll={{ x: "50vw" }}
          columns={columns} //dataSource={tableData}
          dataSource={Array.isArray(array) ? array : tableData}
          pagination={10}
        /> */}
        {/* new table shivam kashyap 19 june 2024  */}
        <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
              <div className="flex flex-col items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900 w-full">ID</p>
              </div>
              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Receipts Statement
                </p>
              </div>
              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Code
                </p>
              </div>
              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Name
                </p>
              </div>
              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Hsn Code
                </p>
              </div>{" "}
              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  UOM
                </p>
              </div>
            </li>
          </ul>{" "}
          <ul
            role="list"
            className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[60vh] "
          >
            {tableData && tableData.length
              ? tableData.map((item, key) => (
                  <li
                    key={key}
                    className={`flex justify-between items-center px-5 py-2 ${
                      key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }`}
                  >
                    <div className="flex flex-col items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.id ? item.id : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[250px]">
                      <a
                        onClick={() => HandelOpenItemWiseGrn(item)}
                        href="#"
                        class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                        data-te-toggle="tooltip"
                        title="View Receipt Statement"
                      >
                        <FaReceipt
                          className=" text-top_nav_blue-300 "
                          size={18}
                        />
                      </a>{" "}
                    </div>
                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.item_code ? item.item_code : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.material_name ? item.material_name : "-"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.hsn_code ? item.hsn_code : "-"}
                      </p>
                    </div>{" "}
                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item?.uom ? item.uom : "-"}
                      </p>
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>{" "}
      </div>

      {/* modals  */}
      <Modal
        width={1950}
        footer={null}
        visible={isModalOpen}
        onCancel={modalClose}
      >
        <ItemReceiptsStatement
          itemCode={itemCode}
          projectID={props.projectID}
        />
      </Modal>
    </>
  );
}

export default ShowMaterialList;
