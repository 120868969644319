import "./css/ListWorkCategory.css";
import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Table,
  Input,
  notification,
  Modal,
  Select,
  message,
  Spin,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { ShowDeleteConfirm } from "../../popupmodal";
import { useEffect } from "react";
import { redirectAction } from "../../../redux-core/login/actions";
import {
  createNewCategory,
  getAllcategory,
  updateByWorkId,
  deleteByWorkId,
  getUom,
  getResourcesDashboard,
} from "../../../redux-core/resources/actions";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import AddWorkCategory from "./AddWorkCategory";
import {
  selectorgetAllUomState,
  selectorgetAllWorkState,
} from "../../../redux-core/resources/selectors";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  newWorkCategory: (newWork) => dispatch(createNewCategory(newWork)),
  delworkCategoryById: (id) => dispatch(deleteByWorkId(id)),
  getViewAllCategory: (orgId, userId) =>
    dispatch(getAllcategory(orgId, userId)),
  updateCateogry: (workId) => dispatch(updateByWorkId(workId)),
  count: (orgId) => dispatch(getResourcesDashboard(orgId)),
  redirect: (props, path) => dispatch(redirectAction(props, path)),
  uom: () => dispatch(getUom()),
});

const ListWorkCategory = () => {
  const {
    newWorkCategory,
    delworkCategoryById,
    getViewAllCategory,
    updateCateogry,
    uom,
    count,
  } = actionDispatch(useDispatch());
  const logindetails = useSelector(makeSelectLoginDetail);
  const getAllUom = useSelector(selectorgetAllUomState);
  const AllWorkCategory = useSelector(selectorgetAllWorkState);
  const getColumnSearchProps = useSearch();
  const [filteredData, setFilteredData] = useState([]);
  const [keys, setKeys] = useState();
  const [listWorkRow, setWorkCatRow] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const Spindata = useSelector((item) => item.spinreducer);
  const [activedata, setActivedata] = useState(true);
  const targetRef = useRef(null);

  useEffect(() => {
    const handleResize = (entries) => {
      window.requestAnimationFrame(() => {
        // Place your ResizeObserver handling code here
        entries.forEach((entry) => {
          const target = entry.target;
          // Update the size of the target element or perform other tasks
        });
      });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onSelectChange = (selectedRowKeys) => {
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  useEffect(() => {
    getViewAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    uom();
    count(logindetails.organisation.orgID);
    //console.log(AllWorkCategory);
  }, []);
  const { Option } = Select;

  /// Modal Form Ok and Cancel

  const listWorkCatModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({
      work_category_id: "",
      work_category_name: "",
      resource_type: "",
      uom: "",
      remark: "",
    });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onAddWorkCategoryFinish = async (values) => {
    // let category_id = values.category_id.map(Number);
    // values.category_id = category_id;
    // listWorkRow.category_name = values.category_name;
    // listWorkRow.resource_type = values.resource_type;
    // listWorkRow.uom = values.uom;
    // listWorkRow.remarks = values.remarks;

    let data = {
      ...values,
      ...{
        orgID: logindetails.organisation.orgID,
        created_by: logindetails.user.id,
      },
    };
    let response = await newWorkCategory(data);
    if (response) {
      await setVisible(false);
      await clear_work_category_field();
    }

    // console.log("data", data)
    // console.log("success: ", values)
  };
  const handleCancels = () => {
    setVisible(false);
  };
  const handleOkk = () => {
    setVisible(false);
  };

  const onAddWorkCategoryFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const onEditWorkCategoryFinish = async (values) => {
  //      let id = values.id.map(Number);
  //     values.id = id;
  //     // listWorkRow.category_name = values.category_name;
  //     // listWorkRow.resource_type = values.resource_type;
  //     // listWorkRow.uom = values.uom;
  //     // listWorkRow.remarks = values.remarks;
  //     let data = {

  //             id:id,
  //             category_name : values.category_name,
  //             resource_type : values.resource_type,
  //             uom : values.uom,
  //             remarks : values.remarks,
  //           orgID: logindetails.organisation.orgID,
  //           created_by: logindetails.user.id,

  //       };
  //       let response= await updateCateogry(data);
  //       if(response){
  //         await clear_work_category_field();

  //       }
  //     console.log("success: ", values)
  // };
  const onEditWorkCategoryFinish = async (values) => {
    let data = {
      id: values.id,
      category_name: values.category_name,
      resource_type: values.resource_type,
      uom: values.uom,
      remarks: values.remarks,
    };

    let response = await updateCateogry(data);
    if (response) {
      clear_work_category_field();
      getViewAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    }
    getViewAllCategory(logindetails.organisation.orgID, logindetails.user.id);
    //console.log(response)
    // console.log("success: ", values);
    setVisible(false);

    // console.log("data",data)
  };

  const onEditWorkCategoryFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Resource Type
  const options = [
    { value: "Labour", label: "Labour" },
    { value: "Equipment", label: "Equipment" },
  ];

  console.log("AllWorkCategory", AllWorkCategory);
  let tableData = [];
  for (let i = 0; i < AllWorkCategory.length; i++) {
    tableData.push({
      sl_no: i + 1,
      key: AllWorkCategory[i].id,
      category_name: AllWorkCategory[i].category_name,
      resource_type: AllWorkCategory[i].resource_type,
      uom: AllWorkCategory[i].uom,
      remark: AllWorkCategory[i].remarks,
    });
  }

  // Pagination And Filter of List Work Category

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };
  const clear_work_category_field = () => {
    form.setFieldsValue({
      work_category_id: "",
      category_name: "",
      resource_type: "Select Resource Type",
      uom: "Select UOM",
      remarks: "",
    });
  };
  const clear_Edit_work_category_field = () => {
    form.setFieldsValue({
      work_category_id: listWorkRow.work_category_id,
      category_name: listWorkRow.category_name,
      resource_type: listWorkRow.resource_type,
      uom: listWorkRow.uom,
      remarks: listWorkRow.remarks,
    });
  };

  // Table Columns of List Work Category
  const columns = [
    {
      title: "SL Number",
      dataIndex: "sl_no",
      //  ...getColumnSearchProps('id'),
    },
    {
      title: "Work Category Name",
      dataIndex: "category_name",
      ...getColumnSearchProps("category_name"),
    },
    {
      title: "Vendor Type",
      dataIndex: "resource_type",
      ...getColumnSearchProps("category_name"),
    },
    {
      title: "UOM",
      dataIndex: "uom",
      ...getColumnSearchProps("category_name"),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      // ...getColumnSearchProps('category_name')
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            <Button onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
            <Button
              onClick={() => deleteRow(record)}
              style={{ marginLeft: "5px" }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  //Edit Row of Item List Work Category
  const showModal = (data1) => {
    setWorkCatRow(data1);
    setVisible(true);
    form.setFieldsValue({
      id: data1.key,
      category_name: data1.category_name,
      resource_type: data1.resource_type,
      uom: data1.uom,
      remarks: data1.remark,
    });
  };

  // Delete Row Of Items List Work Category

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.key]) });
  };
  const onConfirm = async (key) => {
    await delworkCategoryById(key);
    await getViewAllCategory(
      logindetails.organisation.orgID,
      logindetails.user.id
    );
    await count(logindetails.organisation.orgID);
  };

  const deleteSelected = () => {
    if (keys === undefined || keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else if (keys.length === 0)
      notification.open({
        message: "Select items to delete.",
      });
    else {
      ShowDeleteConfirm({ onConfirm: () => onConfirm(keys) });
    }
  };

  const data = [];
  for (let i = 0; i < AllWorkCategory.length; i++) {
    data.push({
      key: AllWorkCategory[i].id,
      category_name: AllWorkCategory[i].category_name,
      resource_type: AllWorkCategory[i].resource_type,
      uom: AllWorkCategory[i].uom,
      remarks: AllWorkCategory[i].remarks,
    });
  }

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  return (
    <div  ref={targetRef}>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">List Work Catgory</div>
        <br />

        <div className="btn__">
          <Button className="excel">
            <CSVLink // CSV Of List Work Category
              filename={"list_work_category.csv"}
              data={data}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
          {/* <Button className="btn-modal" onClick={listWorkCatModal}> Add Work Category</Button> */}
        </div>
        {/* {console.log(data)} */}
        <Table
          className="table"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData && tableData ? tableData : []}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          pagination={10}
          scroll={{ x: "100vw", y: "300px" }}
        />

        <Modal
          visible={isModalVisible} // Model and Form of List Work Category
          onOk={handleOk}
          onCancel={handleCancel}
          width="75%"
          style={{ top: 80 }}
        >
          <div className="edit-in__charge">Add Work Category</div>
          <br />

          <Form
            form={form}
            name="add-workCategory"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onAddWorkCategoryFinish}
            onFinishFailed={onAddWorkCategoryFinishFailed}
          >
            <Form.Item
              name="category_name"
              label="Work Category Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please input the Work Category Name!",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="resource_type"
              label="Resource Type"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Resource Type!",
                },
              ]}
            >
              <Select
                placeholder="Please select Resource Type"
                value={options.value}
                options={options}
                defaultValue={options[""]}
              ></Select>
            </Form.Item>

            <Form.Item
              name="uom"
              label="Select UOM"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter input field",
                },
              ]}
            >
              <Select placeholder="Please select UOM">
                {getAllUom.map((uom) => (
                  <option key={uom.id} value={uom.name}>
                    {uom.uom_name}
                  </option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="remarks" label="Remarks" className="input-field">
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>

            <Form.Item>
              <div className="btn-addwork">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Submit</strong>
                </Button>

                <Button
                  onClick={clear_work_category_field}
                  className="btn-cancel"
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        {/* ----------------------Edit Modal-----------------------           */}

        <Modal
          visible={visible} // Model and Form of List Work Category
          onOk={handleOkk}
          onCancel={handleCancels}
          width="75%"
          style={{ top: 80 }}
          footer={null}
        >
          <div className="edit-in__charge">Edit Work Category</div>
          <br />

          <Form
            form={form}
            name="edit-workCategory"
            size="large"
            initialValues={{
              remember: true,
            }}
            onFinish={onEditWorkCategoryFinish}
            onFinishFailed={onEditWorkCategoryFinishFailed}
          >
            <Form.Item name="id" className="input-field">
              <Input placeholder="" hidden />
            </Form.Item>
            <Form.Item
              name="category_name"
              label="Work Category Name"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter the Work Category Name!",
                },
              ]}
            >
              <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
            </Form.Item>
            <Form.Item
              name="resource_type"
              label="Vendor Type"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Vendor Type!",
                },
              ]}
            >
              <Select
                placeholder="Please select Vendor Type"
                value={options.value}
                options={options}
                defaultValue={options[""]}
                onKeyDown={(e) => onKeydown(e)}
              ></Select>
            </Form.Item>

            <Form.Item
              name="uom"
              label="Select UOM"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select UOM",
                },
              ]}
            >
              <Select
                placeholder="Please select UOM"
                onKeyDown={(e) => onKeydown(e)}
              >
                {getAllUom.map((uom) => (
                  <option key={uom.id} value={uom.uom_name}>
                    {uom.uom_name}
                  </option>
                ))}
                {/* {children} */}
              </Select>
            </Form.Item>
            <Form.Item name="remarks" label="Remarks" className="input-field">
              <Input.TextArea
                style={{ maxHeight: "70px", minHeight: "70px" }}
              />
            </Form.Item>

            <Form.Item>
              <div className="btn-addwork">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  style={{ borderRadius: "10px" }}
                >
                  <strong>Submit</strong>
                </Button>

                <Button
                  onClick={clear_Edit_work_category_field}
                  className="btn-cancel"
                >
                  Reset
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        <br />
        {AllWorkCategory.length > 0 && (
          <Button
            style={{ display: activedata ? "" : "none" }}
            className="deleteallbutton copy"
            onClick={deleteSelected}
          >
            Delete All Selected
          </Button>
        )}
      </Spin>
    </div>
  );
};
export default React.memo(ListWorkCategory);
