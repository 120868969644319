// Contracts.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { makeSelectLoginDetail } from '../../../redux-core/login/selectors';
import { contractsAPI } from '../../../cognisite-api';
import { selectorAllWorkOrderDetails, selectorAllBOQExcelAndBillingDataForReports, selectorBillDates } from '../../../redux-core/contracts/selectors';
import {
  getAllWorkOrders,
  fetchBillDates,
  getBOQExcelAndBillingDataForReports,
} from '../../../redux-core/contracts/actions';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement);

const Contracts = ({ projectId, onBack }) => {
  const [chartData, setChartData] = useState(null);
  const [barData, setBarData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [billCounts, setBillCounts] = useState([]);

  const dispatch = useDispatch();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const dates = useSelector(selectorBillDates);
  const allBOQExcelAndBillingData = useSelector(selectorAllBOQExcelAndBillingDataForReports);

  useEffect(() => {
    dispatch(getAllWorkOrders(projectId));
  }, [dispatch, projectId]);

  const workOrders = useSelector(selectorAllWorkOrderDetails);

  const processWorkOrders = async (projectId, orgId) => {
    const billCountsPerMonthYear = {};

    try {
      await dispatch(fetchBillDates(projectId, orgId));
      for (const bill of dates) {
        try {
          const createdDate = new Date(bill.createdAt);
          const checkedDate = bill.checkedAt ? new Date(bill.checkedAt) : null;
          const approvedDate = bill.approvedAt ? new Date(bill.approvedAt) : null;

          const createdMonthYear = `${createdDate.getMonth() + 1}-${createdDate.getFullYear()}`;
          const checkedMonthYear = checkedDate ? `${checkedDate.getMonth() + 1}-${checkedDate.getFullYear()}` : null;
          const approvedMonthYear = approvedDate ? `${approvedDate.getMonth() + 1}-${approvedDate.getFullYear()}` : null;

          if (!billCountsPerMonthYear[createdMonthYear]) {
            billCountsPerMonthYear[createdMonthYear] = { created: 1, checked: 0, approved: 0 };
          } else {
            billCountsPerMonthYear[createdMonthYear].created++;
          }

          if (checkedMonthYear) {
            if (!billCountsPerMonthYear[checkedMonthYear]) {
              billCountsPerMonthYear[checkedMonthYear] = { created: 0, checked: 1, approved: 0 };
            } else {
              billCountsPerMonthYear[checkedMonthYear].checked++;
            }
          }

          if (approvedMonthYear) {
            if (!billCountsPerMonthYear[approvedMonthYear]) {
              billCountsPerMonthYear[approvedMonthYear] = { created: 0, checked: 0, approved: 1 };
            } else {
              billCountsPerMonthYear[approvedMonthYear].approved++;
            }
          }
        } catch (error) {
          console.error(`Error processing bill ${bill.billId}:`, error);
        }
      }
    } catch (error) {
      console.error(`Error fetching bill dates for project ${projectId} and org ${orgId}:`, error);
    }

    const countsArray = Object.entries(billCountsPerMonthYear).map(([key, value]) => ({
      monthYear: key,
      ...value,
    }));
    setBillCounts(countsArray);
  };

  useEffect(() => {
    const processAndFetchData = async () => {
      await processWorkOrders(projectId, orgId);
      await getPieData(projectId, orgId);
    };

    processAndFetchData();
  }, [workOrders]);

  const getPieData = async (projectId, orgId) => {
    const categories = {};

    try {
      const sheetName = 'Format 2';
      await dispatch(getBOQExcelAndBillingDataForReports(projectId, orgId, sheetName));
      let currentCategory = '';
      let skipNextRow = false;
      let previousRowWasNullSno = false;
      console.log('allBOQExcel data:', allBOQExcelAndBillingData);
      allBOQExcelAndBillingData.boqExcelWithBillData.forEach((row) => {
        if (skipNextRow) {
          skipNextRow = false;
          return;
        }

        if (row.sno === 'S.No.') {
          skipNextRow = true;
          return;
        }

        const description = row.description?.toString().toLowerCase().trim();

        if (row.sno === null) {
          if (previousRowWasNullSno) {
            delete categories[currentCategory];
          }
          previousRowWasNullSno = true;
          currentCategory = description;
          if (!categories[currentCategory]) {
            categories[currentCategory] = 0;
          }
        } else {
          previousRowWasNullSno = false;
        }

        const totalAmount = parseFloat(row.totalamount) || 0;
        if (currentCategory) {
          categories[currentCategory] += totalAmount;
        }
      });

      setChartData({
        labels: Object.keys(categories),
        datasets: [
          {
            data: Object.values(categories),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
          },
        ],
      });
    } catch (err) {
      console.error('Error fetching BOQ data:', err);
    }
  };

  useEffect(() => {
    const barMockData = {
      labels: ['Bills Generated', 'Bills Checked', 'Bills Approved'],
      datasets: [
        {
          label: 'Total Bills',
          data: [
            billCounts.reduce((acc, curr) => acc + curr.created, 0),
            billCounts.reduce((acc, curr) => acc + curr.checked, 0),
            billCounts.reduce((acc, curr) => acc + curr.approved, 0),
          ],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        },
      ],
    };

    const lineMockData = {
      labels: billCounts.map((count) => count.monthYear),
      datasets: [
        {
          label: 'Bills Generated',
          data: billCounts.map((count) => count.created),
          borderColor: '#FF6384',
          fill: false,
        },
        {
          label: 'Bills Checked',
          data: billCounts.map((count) => count.checked),
          borderColor: '#36A2EB',
          fill: false,
        },
        {
          label: 'Bills Approved',
          data: billCounts.map((count) => count.approved),
          borderColor: '#FFCE56',
          fill: false,
        },
      ],
    };

    setBarData(barMockData);
    setLineData(lineMockData);
  }, [billCounts]);

  return (
    <div>
      {/* <button onClick={onBack} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>Back</button> */}
      <h1 style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontWeight: 'bold', fontSize: '2em' }}>Contracts</h1>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '50%' }}>
          {chartData && (
            <div style={{ width: '400px', height: '400px' }}>
              <h1 style={{ textAlign: 'center' }}>Total Cost of each work</h1>
              <Pie data={chartData} options={{ maintainAspectRatio: false }} />
            </div>
          )}
          {!chartData && <div style={{ width: '400px', height: '400px' }}></div>}
        </div>
        <div style={{ width: '50%' }}>
          {barData && (
            <div style={{ width: '600px', height: '400px' }}>
              <Bar data={barData} options={{ maintainAspectRatio: false }} />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
        {lineData && (
          <div style={{ width: '600px', height: '400px', margin: '0 auto' }}>
            <Line data={lineData} options={{ maintainAspectRatio: false }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Contracts;
