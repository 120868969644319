import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector } from "react-redux";
import { selectorGetSiteTransferItems } from "../../../../redux-core/materials/selectors";

function ViewTransferItemsFroms() {
  const GetSiteTransferItem = useSelector(selectorGetSiteTransferItems);

  const data = [];

  for (let i = 0; i < GetSiteTransferItem.length; i++) {
    data.push({
      // id:GetSiteTransferItem[i].id,
      id: i + 1,
      Item_name: GetSiteTransferItem[i].Item_name,
      item_code: GetSiteTransferItem[i].item_code,
      current_qty: GetSiteTransferItem[i].current_qty,
      transfer_qty: GetSiteTransferItem[i].transfer_qty,
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Name.",
      dataIndex: "Item_name",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "This Time Availble Qty",
      dataIndex: "current_qty",
    },
    {
      title: "Transfer Qty",
      dataIndex: "transfer_qty",
    },
  ];

  useEffect(() => {
    console.log("data: ", data);
  }, [data]);

  return (
    <>
      <div className="">
        {/* <Table
          scroll={{ x: "70vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        /> */}
        <div className=" font-semibold  text-2xl font-montserrat">
          View Transfer Item
        </div>
        <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
          <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
            <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
              <div className="flex flex-col items-start w-[150px]">
                <p className="text-sm font-semibold text-gray-900 w-full">ID</p>
              </div>

              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Name
                </p>
              </div>

              <div className="flex flex-col items-start w-[350px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Item Code
                </p>
              </div>

              <div className="flex flex-col items-start w-[200px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  This Time Availble Qty
                </p>
              </div>

              <div className="flex flex-col items-start w-[250px]">
                <p className="text-sm font-semibold text-gray-900 w-full">
                  Transfer Qty
                </p>
              </div>
            </li>
          </ul>{" "}
          <ul
            role="list"
            className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
          >
            {data && data.length
              ? data.map((item, key) => (
                  <li
                    key={key}
                    className={`flex justify-between items-center px-5 py-2 ${
                      key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                    }`}
                  >
                    <div className="flex flex-col items-start w-[150px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item.id ? item.id : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item.Item_name ? item.Item_name : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[350px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item.item_code ? item.item_code : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[200px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item.current_qty ? item.current_qty : "-"}
                      </p>
                    </div>

                    <div className="flex flex-col items-start w-[250px]">
                      <p className="text-sm font-semibold text-gray-900 w-full">
                        {item.transfer_qty ? item.transfer_qty : "-"}
                      </p>
                    </div>
                  </li>
                ))
              : "No records found..."}
          </ul>
        </div>
      </div>
    </>
  );
}

export default ViewTransferItemsFroms;
