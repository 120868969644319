// import React from "react";
// import Sidebar from "../Core/Sidebar";
// import TopNavbar from "../settings/topnavbar/TopNavbar";
// import { Outlet } from "react-router";

// const Dashbard = () => {
//   return (
//     <div className=" flex h-auto ">
//       <Sidebar className=" flex h-auto " />
//       <div className=" flex w-[100vw]  flex-col ">
//         <TopNavbar />
//         <Outlet className=" " />
//       </div>
//     </div>
//   );
// };

// export default Dashbard;
import React, { useEffect } from "react";
import Sidebar from "../Core/Sidebar";
import TopNavbar from "../settings/topnavbar/TopNavbar";
import { Outlet, useLocation, useParams } from "react-router";
import HomePage from "../afterLogin/HomePage";

const Dashboard = () => {
  const param = useParams();
  const location = useLocation();
  useEffect(() => {
    // console.log("dashboard params---------", param);
    // console.log("Pathname:", location.pathname.split("/")[2]);
  }, [param, location]);

  return (
    // <div className=" flex flex-col ">
    <div className="flex ">
      {(location.pathname.split("/")[2] == "reports" ||
        location.pathname.split("/")[2] == "contracts" ||
        location.pathname.split("/")[2] == "risk" ||
        location.pathname.split("/")[2] == "qcc" ||
        location.pathname.split("/")[2] == "settings" ||
        location.pathname.split("/")[2] == "materials" ||
        location.pathname.split("/")[2] == "comingsoon" ||
        location.pathname.split("/")[2] == undefined) && <Sidebar />}
      <div className="flex flex-col h-screen w-full">
        <TopNavbar />
        <Outlet className=" font-montserrat" />
      </div>
    </div>
    // <HomePage/>
    // </div>
  );
};

export default Dashboard;
