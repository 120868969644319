import "./css/ShowProject.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Image,
  message,
  notification,
  Spin,
  Modal,
  Radio,
  Badge,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import useSearch from "../../../hooks/useSearch";
import { ShowDeleteConfirm } from "../../popupmodal";
import { settingsapi } from "../../../cognisite-api";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useDispatch, useSelector } from "react-redux";
import { countAction } from "../../../redux-core/settings/actions";

const actionDispatch = (dispatch) => ({
  countSettings: (orgId) => dispatch(countAction(orgId)),
  
});

const { getOrganization, deleteOrganization,orgStatusUpdate } = settingsapi;
const ShowOrganization = () => {
  const getColumnSearchProps = useSearch();
  const [selectedKeys, setSelectedKeys] = useState();
  const [org, setOrg] = useState([]);
  const [activedata, setActivedata] = useState(true);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedOrgId,setSelectedOrgId]=useState()
  const Spindata = useSelector((item) => item.spinreducer);
  const { countSettings } = actionDispatch(useDispatch());
  useEffect(() => {
    getOrg();
  }, []);

  const getOrg = async () => {
    const response = await getOrganization();
    if (response.status === 200) {
      const result = response.organisation.map((value) => ({
        ...value,
        key: value.orgID,
      }));
      setOrg(result);
    } else {
      setOrg([]);
    }
  };

  const deleteOrg = async (id) => {
    await deleteOrganization(id);
  };
  const columns = [
    // {
    //     title: 'Sl No',
    //     dataIndex: 'sl_no',
    //     //...getColumnSearchProps('sl_no'),
    // },
    {
      title: "OrgID",
      dataIndex: "orgID",
      ...getColumnSearchProps("orgID"),
    },

    {
      title: "Organization Name",
      dataIndex: "orgName",
      ...getColumnSearchProps("orgName"),
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      //...getColumnSearchProps("contact_person"),
    },
    {
      title: "Contact Number",
      dataIndex: "contact",
      ...getColumnSearchProps("contact"),
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
    },
    
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <Image src={text} style={{ width: "20px", height: "20px" }} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
      render: (text) => {
        let statusColor;
        switch (text) {
          case "Block":
            statusColor = "red";
            break;
          case "Active":
            statusColor = "green";
            break;
          default:
            statusColor = "default";
        }

        return <Badge status={statusColor} text={text} />;
      },
  },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <div>
            {/* <Button><EditOutlined /></Button> */}
            <Button onClick={() => handelBlockModal(record)}>
              <PoweroffOutlined />
            </Button>
          </div>
        );
      },
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'action',
    //     render: (text, record) => {
    //         return (
    //             <div>
    //                 {/* <Button><EditOutlined /></Button> */}
    //                 <Button onClick={() => deleteRow(record)}><DeleteOutlined /></Button>
    //             </div >
    //         )
    //     }

    // },
  ];

  const deleteRow = (record) => {
    ShowDeleteConfirm({ onConfirm: () => onConfirm([record.orgID]) });
  };
  const onConfirm = async (key) => {
    await deleteOrg(key);
    await getOrg();
    await countSettings(key);
  };

  // const deleteSelected = () => {
  //     if (selectedKeys === undefined) notification.open({
  //         message: 'Select items to delete.'
  //     });
  //     else if (selectedKeys.length === 0) notification.open({
  //         message: 'Select items to delete.'
  //     });
  //     else {
  //         ShowDeleteConfirm({onConfirm:()=>onConfirm(selectedKeys)})
  //     }
  // };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
  };

  const onTableChange = (pagination, filter, sorter) => {
    if (sorter.currentDataSource.length > 0) {
      setActivedata(true);
    } else {
      setActivedata(false);
    }
  };

  const TableData = [];
  for (let i = 0; i < org.length; i++) {
    TableData.push({
      sl_no: i + 1,
      orgID: org[i]?.orgID,
      orgName: org[i]?.orgName,
      contact: org[i]?.contact,
      contact_person: org[i]?.contact_person,
      gst_number: org[i]?.gst_number,
      pan_no: org[i]?.pan_no,
      createdOn: org[i]?.createdOn,
      description: org[i]?.description,
      address: org[i]?.address,
      status: org[i]?.status,
    });
  }

  const handelBlockModal = (data) => {
    setSelectedValue(data.status);
    setSelectedOrgId(data.orgID)
    setOpenBlockModal(true);
  };

  const modalCancel = () => {
    setOpenBlockModal(false);
  };
  const handleRadioChange =async (e) => {
    setSelectedValue(e.target.value);
   
    const updateStatusValue={
      orgID:selectedOrgId,
      status:e.target.value
    }
   
    let response=await orgStatusUpdate(updateStatusValue)
    getOrg();
    modalCancel()
  // console.log("response",response)
  };

  return (
    <>
      <Spin spinning={Spindata.spin} size={"large"}>
        <div className="title-show-project">List Of Organization </div>
        <br />
        <div className="btn__">
          <Button className="excel">
            <CSVLink
              filename={"Organization_Table.csv"}
              data={TableData}
              className="excel"
              onClick={() => {
                message.success("The file is downloading");
              }}
            >
              Export to Excel
            </CSVLink>
          </Button>
        </div>
        <Table
          className="table"
          scroll={{ x: "50vw" }}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={org}
          onChange={(pagination, filter, sorter, currentTable) =>
            onTableChange(filter, sorter, currentTable)
          }
          pagination={10}
          style={{ paddingTop: "10px" }}
        />
        {/* {org.length>0&&
            <Button style={{display:activedata?'':'none'}} className="deleteallbtn copy" onClick={deleteSelected}>Delete All Selected</Button>
            } */}
        <Modal
          open={openBlockModal}
          onCancel={modalCancel}
          footer={null}
          title="Block Organization"
          width={400}
          centered={true}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Radio.Group 
            onChange={handleRadioChange}
            buttonStyle="solid" style={{ width: "45%" }}
            >
            <Radio.Button value="Block" style={{ background: selectedValue === 'Block' ? 'red' : '' }}>
            Block
          </Radio.Button>
          <Radio.Button value="Active" style={{ background: selectedValue === 'Active' ? 'green' : '' }}>
            Active
          </Radio.Button>
            </Radio.Group>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default React.memo(ShowOrganization);
