import "./css/BulklabourRegister.css";

import "antd/dist/antd.css";
import React from "react";
import BulkLabourRegisterForm from "./Forms/BulkLabourRegisterForm";
function BulkLabourRegister() {
  return (
    <>
      <div className="add-bulk">
        <div className="sub-title1">Bulk Labour Register</div>
        <br />
        <BulkLabourRegisterForm />
      </div>
    </>
  );
}

export default React.memo(BulkLabourRegister);
