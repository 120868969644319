import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
import { Form, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { bulkTask } from "../../../../redux-core/qcc/actions";
import { ShowTaskUpload } from "../../../popupmodal";

const actionDispatch = (dispatch) => ({
  bulkTask: (bulk) => dispatch(bulkTask(bulk)),
});

function UploadTaskExcelForm({ setshowCreateTaskModal }) {
  const { bulkTask } = actionDispatch(useDispatch());
  const [form] = Form.useForm();
  const loginDetails = useSelector(makeSelectLoginDetail);
  const orgId = loginDetails.organisation.orgID;
  const userId = loginDetails.user.id;
  const [selectedFile, setSelectedFile] = useState(false);

  const onFinish = async (e) => {
    e.preventDefault();
    // console.log("Received values of form: ", values);
    console.log("Excel file: ", excelFile);

    const fileObject = {
      lastModified: excelFile.lastModified,
      lastModifiedDate: excelFile.lastModifiedDate,
      name: excelFile.name,
      originFileObj: excelFile,
      percent: 0,
      size: excelFile.size,
      type: excelFile.type,
      uid: "rc-upload-" + Date.now(),
    };

    // fileObject.originFileObj = {
    //   ...fileObject.originFileObj,
    //   uid: "rc-upload-" + Date.now(),
    // };
    // return;

    // if (values?.task_excel) {
    //   let taskExcelBase64 = await getBase64(
    //     values?.task_excel?.[0]?.originFileObj
    //   );
    //   let UpdatedTaskExcel = {
    //     ...values.task_excel[0],
    //     thumbUrl: taskExcelBase64,
    //   };
    //   var UpdatedTaskExcelDoc = [
    //     UpdatedTaskExcel,
    //     ...values.task_excel.slice(1),
    //   ];
    // }

    if (fileObject) {
      let taskExcelBase64 = await getBase64(fileObject.originFileObj);
      let UpdatedTaskExcel = {
        ...fileObject,
        thumbUrl: taskExcelBase64,
      };
      var UpdatedTaskExcelDoc = [UpdatedTaskExcel];
    }

    let data = {
      orgID: orgId,
      createdBy: userId,
      task_excel: UpdatedTaskExcelDoc,
    };
    console.log("Final excel form data: ", data);
    // return;
    try {
      let response = await bulkTask(data);
      if (response) {
        setshowCreateTaskModal(false);
      }
      console.log("Response Data::::::", response);
    } catch (error) {
      console.log("error", error);
    }

    console.log("Data :::::", data);
    if (data.task_excel && data.task_excel.length < 1) delete data.task_excel;

    form.setFieldsValue({
      task_excel: [],
    });
    setExcelFile([]);
  };
  const onFinishFailed = (error) => {
    console.log(error);
  };

  const normFileForActivitiesDocExcel = (e) => {
    if (e && e.fileList.length > 0) {
      setSelectedFile({ ...selectedFile, activities_excel: true });
    } else {
      setSelectedFile({ ...selectedFile, activities_excel: false });
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const downloadExcelFile = () => {
    ShowTaskUpload({ onConfirm: () => onConfirm([]) });
  };

  const onConfirm = () => {
    //File Name
    const fileName = "taskSample.xlsx";
    //Execl Header
    const data = [
      {
        TaskName: "Task 1",
        StageId: "'166",
      },
      {
        TaskName: "Task 2",
        StageId: "'166,158",
      },
    ];
    const ws = XLSX?.utils?.json_to_sheet(data);
    const wb = XLSX?.utils?.book_new();
    XLSX?.utils?.book_append_sheet(wb, ws, "test");
    XLSX?.writeFile(wb, fileName);
  };
  const [excelFile, setExcelFile] = useState([]);

  return (
    <>
      {/* <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        name="project-details-bulk"
        size="large"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          style={{ textAlign: "center" }}
          name="task_excel"
          label=""
          valuePropName="fileList"
          getValueFromEvent={normFileForActivitiesDocExcel}
          rules={[
            {
              required: true,
              message: "Please Upload File in Excel format!",
            },
          ]}
          maxCount={"1"}
        >
          <Upload
            name="doc"
            listType="task_excel"
            accept=".xlsx,.xls"
            beforeUpload={(file, fileList) => {
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Choose File</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="btn-project">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              style={{ borderRadius: "10px" }}
            >
              <strong>Upload</strong>
            </Button>

            <Button onClick={downloadExcelFile} className="btn-cancel">
              <strong>Sample</strong>
            </Button>
          </div>
        </Form.Item>
      </Form> */}

      {/* new ui shivam kashyapp  */}
      <form className="font-montserrat" onSubmit={onFinish}>
        <div className="w-[300px] ml-10 ">
          <label
            htmlFor="image"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            <span className="text-red-600">*</span>
            Choose Excel File
          </label>
          <input
            required
            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="file_input_help"
            id="image"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(event) => {
              const fileInput = event.target;
              const file = fileInput.files[0];

              if (file) {
                setExcelFile(file);
                console.log("filse set: ", excelFile);
              }
            }}
          />
          <p
            class="mt-1 text-sm text-gray-500 dark:text-gray-300"
            id="file_input_help"
          >
            XLS, XLSX.
          </p>
        </div>

        {/* buttons  */}
        <div className=" flex gap-x-2 mr-5 justify-end">
          <button
            // onClick={resetAllValue}
            type="button"
            class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"

            data-te-ripple-init
          >
            Reset
          </button>
          <button
            type="submit"
            data-te-ripple-init
            data-te-ripple-color="light"
            class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

function getBase64(file) {
  // console.log("file::",file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default UploadTaskExcelForm;
