import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Table } from "antd";
import "../css/ShowMaterialStock.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectorGetSiteTransferItems,
  selectorGetItemInCurrentStockProjectWise,
  selectorGetSiteTransferDataTByTransferNumber,
} from "../../../../redux-core/materials/selectors";
import {
  getCurrentStockProjectIdWise,
  updateStockInSiteTransferGrnQty,
  updateSiteTransferData,
  getSiteTransferDataTransferNumberWise,
  createGrnNumbers,
} from "../../../../redux-core/materials/actions";
import moment from "moment";
import { getCountGrnNumber } from "../../../../redux-core/materials/actions";
import { getProjectByprojectId } from "../../../../redux-core/settings/actions";
import { selectorCountGrnNumber } from "../../../../redux-core/materials/selectors";
import { selectorGetProjByProjId } from "../../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";

const actionDispatch = (dispatch) => ({
  showCurrentStock: (projID) => dispatch(getCurrentStockProjectIdWise(projID)),
  updateStockInSiteTransferGrnQty: (Items) =>
    dispatch(updateStockInSiteTransferGrnQty(Items)),
  updateSiteTransferData: (Items) => dispatch(updateSiteTransferData(Items)),
  getSiteTransferDataTransferNumberWise: (transferNumber) =>
    dispatch(getSiteTransferDataTransferNumberWise(transferNumber)),
  getProjectByprojectId: (projeID) => dispatch(getProjectByprojectId(projeID)),
  getCountGrnNumber: (projeID) => dispatch(getCountGrnNumber(projeID)),
  createGrnNumbers: (grn) => dispatch(createGrnNumbers(grn)),
});

function CreateSiteTransferItemGrn(props) {
  const [form] = Form.useForm();
  const GetSiteTransferItem = useSelector(selectorGetSiteTransferItems);
  const getShowItemInCurrentStockProjectWise = useSelector(
    selectorGetItemInCurrentStockProjectWise
  );
  const getSiteTransferDataByTransferNumber = useSelector(
    selectorGetSiteTransferDataTByTransferNumber
  );
  const [grnDate, setGrnDate] = useState();
  const {
    showCurrentStock,
    updateStockInSiteTransferGrnQty,
    updateSiteTransferData,
    getSiteTransferDataTransferNumberWise,
    getProjectByprojectId,
    getCountGrnNumber,
    createGrnNumbers,
  } = actionDispatch(useDispatch());
  const [isCheckedClick, setIsCheckedClick] = useState(false);
  const getProjectByProjId = useSelector(selectorGetProjByProjId);
  const countGrnNumber = useSelector(selectorCountGrnNumber);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;

  let count_number =
    countGrnNumber[0].no_of_grn_no === 0
      ? "001"
      : (Number(countGrnNumber[0].no_of_grn_no) + 1)
          .toString()
          .padStart(3, "0");
  const GRN_NUMBER =
    "GRN" + "/" + getProjectByProjId[0].project_code + "/" + count_number;

  // useEffect
  useEffect(() => {
    showCurrentStock(props.projectID);
    getProjectByprojectId(props.projectID);
    getCountGrnNumber(props.projectID);
    form.setFieldsValue({
      transfer_item_grn_no: GRN_NUMBER,
      grn_date: moment(
        getSiteTransferDataByTransferNumber[0]?.transfer_grn_date
      ),
      item_grn_no: getSiteTransferDataByTransferNumber[0]?.transfer_item_grn_no,
    });
  }, [
    GRN_NUMBER,
    getSiteTransferDataByTransferNumber[0]?.transfer_grn_date,
    props.projectID,
    getSiteTransferDataByTransferNumber[0]?.transfer_item_grn_no,
  ]);

  // Data Wreite For Transfer Item Show In Table
  const data = [];
  for (let i = 0; i < GetSiteTransferItem.length; i++) {
    data.push({
      // id:GetSiteTransferItem[i].id,
      id: i + 1,
      Item_name: GetSiteTransferItem[i].Item_name,
      item_code: GetSiteTransferItem[i].item_code,
      current_qty: GetSiteTransferItem[i].current_qty,
      transfer_qty: GetSiteTransferItem[i].transfer_qty,
    });
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Item Name.",
      dataIndex: "Item_name",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      title: "This Time Availble Qty",
      dataIndex: "current_qty",
    },
    {
      title: "Transfer Qty",
      dataIndex: "transfer_qty",
    },
  ];

  // Handele GRN Date picker GRN Date Funcrtion
  const handleTransferGrnDate = (value, dateString) => {
    setGrnDate(dateString);
  };

  // Handel submit New Grn Create and Items Insert In Stock
  const HandelGrnCreateSiteTransfer = async (value) => {
    setIsCheckedClick(true);
    const addGrn = {
      id: getSiteTransferDataByTransferNumber[0]?.id,
      transfer_item_grn_no: value.transfer_item_grn_no,
      transfer_grn_date: value.transfer_grn_date,
      isChecked: 1,
    };
    await updateSiteTransferData(addGrn);

    const grn_number_insert = {
      project_id: props.projectID,
      grn_number: value.transfer_item_grn_no,
      grn_date: grnDate,
      orgID: orgId,
      created_by: userId,
    };
    await createGrnNumbers(grn_number_insert);

    if (GetSiteTransferItem) {
      for (let i = 0; i < GetSiteTransferItem.length; i++) {
        const dataObj = GetSiteTransferItem[i];
        const DataObj = getShowItemInCurrentStockProjectWise.filter(
          (item) => item.item_code === dataObj.item_code
        );

        const data = {
          id: DataObj[0].id,
          total_qty:
            Number(DataObj[0].total_qty) + Number(dataObj.transfer_qty),
          site_transfer_qty:
            Number(DataObj?.[0]?.site_transfer_qty) +
            Number(dataObj.transfer_qty),
          item_rate:
            Number(DataObj?.[0]?.item_rate) + Number(dataObj.item_rate),
          recieve_transfer_qty:
            Number(DataObj?.[0]?.recieve_transfer_qty) +
            Number(dataObj.item_rate),
        };
        await updateStockInSiteTransferGrnQty(data);
      }
    }
    getSiteTransferDataTransferNumberWise(props.TransferNumber);
  };

  return (
    <>
      <div className="add-file">
        <Form
          name="create_grn_for_site_transfer_items"
          form={form}
          onFinish={HandelGrnCreateSiteTransfer}
        >
          {getSiteTransferDataByTransferNumber[0]?.isChecked === 1 ||
          isCheckedClick === true ? (
            <Form.Item
              name="item_grn_no"
              label="Transfer Items GRN No."
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter New GRN Number",
                },
              ]}
              initialValue={
                getSiteTransferDataByTransferNumber[0]?.transfer_item_grn_no
              }
            >
              <Input placeholder="Enter New GRN Number" disabled />
            </Form.Item>
          ) : (
            <Form.Item
              name="transfer_item_grn_no"
              label="Transfer Items GRN No."
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter New GRN Number",
                },
              ]}
              initialValue={GRN_NUMBER}
            >
              <Input placeholder="Enter New GRN Number" disabled />
            </Form.Item>
          )}

          {getSiteTransferDataByTransferNumber[0]?.isChecked === 1 ||
          isCheckedClick === true ? (
            <Form.Item
              name="grn_date"
              label="Transfer Item GRN  Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Transfer Item GRN Date",
                },
              ]}
              initialValue={moment(
                getSiteTransferDataByTransferNumber[0]?.transfer_grn_date
              )}
            >
              <DatePicker
                name="transfer_grn_date"
                format="DD-MM-YYYY"
                onChange={handleTransferGrnDate}
                placeholder="Enter Transfer Item GRN Date"
                disabled={
                  getSiteTransferDataByTransferNumber[0]?.isChecked === 1 ||
                  isCheckedClick === true
                    ? true
                    : false
                }
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="transfer_grn_date"
              label="Transfer Item GRN  Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Transfer Item GRN Date",
                },
              ]}
            >
              <DatePicker
                name="transfer_grn_date"
                format="DD-MM-YYYY"
                onChange={handleTransferGrnDate}
                placeholder="Enter Transfer Item GRN Date"
              />
            </Form.Item>
          )}

          <Form.Item>
            <div className="btn-project">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ marginLeft: 15 }}
                disabled={
                  getSiteTransferDataByTransferNumber[0]?.isChecked === 1 ||
                  isCheckedClick === true
                    ? true
                    : false
                }
              >
                <strong>
                  {" "}
                  {getSiteTransferDataByTransferNumber[0]?.isChecked === 1 ||
                  isCheckedClick === true
                    ? `Submited`
                    : `Submit`}
                </strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="add-file">
        <Table
          scroll={{ x: "70vw" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        />
      
      </div>
    </>
  );
}

export default CreateSiteTransferItemGrn;
