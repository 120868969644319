import { Button, Table } from "antd";
import React, { useRef } from "react";
import "../css/ShowIssueReportForm.css";
import { useSelector } from "react-redux";
import { selectorGetIssueReportByIssueNumber } from "../../../../redux-core/materials/selectors";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";

function ShowItemIssueReportForm() {
  const getIssueReportByIssueNumber = useSelector(
    selectorGetIssueReportByIssueNumber
  );
  const conponentPDF = useRef();
  console.log("getIssueReportByIssueNumber {{{",getIssueReportByIssueNumber)

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "ITEM CODE",
      dataIndex: "item_code",
    },
    {
      title: "MATERIAL NAME",
      dataIndex: "Item_name",
    },
    {
      title: "ISSUE QTY",
      dataIndex: "issue_qty",
    },
  ];

  const OrgName = getIssueReportByIssueNumber?.[0]?.orgName;
  const ProjectName = getIssueReportByIssueNumber?.[0]?.project_name;
  const IssueNumber = getIssueReportByIssueNumber?.[0]?.issue_number;
  const AgencyName = getIssueReportByIssueNumber?.[0]?.issued_agency_name;
  const WorkOrderNumber = getIssueReportByIssueNumber?.[0]?.work_order_id;

  const RecieversName = getIssueReportByIssueNumber?.[0]?.reciever_name;
  const issueDate = new Date(getIssueReportByIssueNumber?.[0]?.issue_date);
  const day = issueDate.getDate().toString().padStart(2, "0");
  const month = (issueDate.getMonth() + 1).toString().padStart(2, "0");
  const year = issueDate.getFullYear();

  // Format the date in DDMMYYYY format
  const formattedIssueDate = `${day}-${month}-${year}`;

  const tableData = [];
  for (let i = 0; i < getIssueReportByIssueNumber.length; i++) {
    tableData.push({
      id: i + 1,
      item_code: getIssueReportByIssueNumber[i].item_code,
      Item_name: getIssueReportByIssueNumber[i].Item_name,
      issue_qty: getIssueReportByIssueNumber[i].issue_qty,
    });
  }

  return (
    <>
      <div className="add-file">
      <ReactToPrint
            trigger={() => (
              <div className="checklist-print" style={{ marginBottom: "80px" }}>
                <Button
                  className="button-61"
                  style={{ float: "right", marginRight: 20, marginTop: 10}}
                >
                  {" "}
                  <PrinterOutlined />
                  Print Report
                </Button>
              </div>
            )}
            content={() => conponentPDF.current}
            pageStyle={`@page { size: 260mm 280mm; }`} // Set the page size
            bodyClass="printable-content"
          ></ReactToPrint>
        <div>
       
          <div ref={conponentPDF} style={{ width: "100%",marginBlock:"100px" }}>
            <div className="image-checklist" style={{display:"flex",justifyContent:"normal",alignItems:"flex-end", gap:"50px"}}>
              <div>
              <img
                src={getIssueReportByIssueNumber?.[0]?.image}
                alt="logo"
                className="img-chk"
              />
              </div>
              <div style={{ overflow: "hidden", display:"flex",justifyContent:"space-evenly", alignItems:"center",gap:"50px", marginBottom:"25px"}}>
                <div className="checklist-detail">
                  <p style={{width:"300px"}}>
                    <strong>Organisation Name:</strong> {OrgName}
                  </p>
                  <p>
                    <strong>Issue Number:</strong> {IssueNumber}
                  </p>
                  <p>
                    <strong>Item issued to (Agency Name):</strong> {AgencyName}
                  </p>
                  <p>
                    <strong>Work Order Number:</strong> {WorkOrderNumber}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Project Name:</strong> {ProjectName}
                  </p>
                  <p>
                    <strong>Issue Date:</strong> {formattedIssueDate}
                  </p>
                  <p>
                    <strong>Recievers Name:</strong> {RecieversName}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                className="checklist-table"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowItemIssueReportForm;
