import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

.ant-menu,.main-div,.ant-layout-sider,.ant-menu.ant-menu-dark,.ant-menu-inline.ant-menu-sub,.ant-layout site-layout{
    background:${({ theme }) => theme.bgColor} !important ;
}


.ant-menu-item-selected,.ant-table-thead >tr >th,.right__icon svg,.logout,
.ant-upload-list-picture-card .ant-upload-list-item-info:before ,.ant-image-mask{
    background:${({ theme }) => theme.hoverColor} !important ;
}
.bottom__title,.left__value,.ant-menu-title-content,.ant-card-meta-title,.ant-btn,.ant-form-item-required,.ant-input,
.ant-select-selection-search,.ant-form-item-label > label,.ant-select-selection-item,.ant-select,
.ant-select-selection-search,.datepicker,.ant-picker ant-picker-large datepicker ant-picker-focused{
    font-size: ${({ theme }) => theme.textSize + 'px'} !important;
  }
  .title__settings,.bottom__title,.left__value,.ant-menu-title-content,.ant-card-meta-title,.ant-btn,.ant-form-item-required,.ant-table-cell,.title-show-project,
  .sub-title,.add-project,.title-show-project,.ant-input,.ant-select-selection-search,.anticon,.ant-form-item-label > label,.ant-modal-body,
  .ant-dropdown-open,.title-show__project,.sub__title,.datepicker,.edit__activity,.edit-project,.edit__stage,.edit__task,.edit-in__charge,.edit__supervisor,.edit__user,.edit__drawing,.sub-title1,
  input::placeholder,.ant-upload.ant-upload-select-picture-card>.ant-upload,.ant-btn,.ant-picker ant-picker-large datepicker,.ant-modal-confirm-title,ant-select-selector,.ant-select-selection-search,.edit-project__details
  ,.ant-select-selection-placeholder,.ant-select-selector,.ant-picker-input>input,.gutter-row,.ant-menu-submenu-arrow{
    font-family: ${({ theme }) =>theme.fontFamily} !important;
    color:${({ theme }) => theme.fontColor} !important ;
  }
  .btn-submit,.btn-cancel,.excel{
    background:${({ theme }) => theme.buttonColor} !important ;
}
.title-show-project,.sub-title,.title-show__project,.sub__title,.sub-title1,.edit-project,.edit__activity,.edit__stage,.edit__task,.edit-in__charge,.edit__supervisor,.edit__user,.edit__drawing,.edit-project__details{
  border-bottom:7px solid	#FFC0CB !important;
  border-width: medium !important;
  width:fit-content !important;
}
.excel{
  text-decoration:none !important;
}
.ant-upload-list-picture-card .ant-upload-list-item,.ant-upload-list-picture .ant-upload-list-item
,.ant-upload-list-picture .ant-upload-list-item-name,.ant-upload.ant-upload-select-picture-card{
  border-color:${({ theme }) => theme.bgColor} !important ;
  color: ${({ theme }) => theme.fontColor} !important ;
  border: 1px dashed ${({ theme }) => theme.bgColor} !important ;
}
`;






