import React, { useState } from "react";
import { Button, DatePicker, Form, Modal, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectorGetDateFilterGrnList,
  selectorGetDateFilterGrnNumber,
  selectorGetGrnNumber,
  selectorGetPurchaseOrder,
} from "../../../../redux-core/materials/selectors";
import {
  displayGrnNumber,
  getDateFilterAllGrnNo,
  getGrnDateFilterWise,
  getGrnItemRcvRejQty,
} from "../../../../redux-core/materials/actions";
import { EyeOutlined } from "@ant-design/icons";
import ShowPoNumberWiseItemsForm from "./ShowPoNumberWiseItemsForm";
import {
  formatDate,
  formatNumberWithCommas,
} from "../../../../utils/functions";
import { FaEye } from "react-icons/fa";

const actionDispatch = (dispatch) => ({
  displayGrnNumber: (poNo) => dispatch(displayGrnNumber(poNo)),
  getGrnItemRcvRejQty: (grnNo) => dispatch(getGrnItemRcvRejQty(grnNo)),
  getGrnDateFilterWise: (dateFilterData) =>
    dispatch(getGrnDateFilterWise(dateFilterData)),
  getDateFilterAllGrnNo: (dateFilterData) =>
    dispatch(getDateFilterAllGrnNo(dateFilterData)),
});

const { Option } = Select;
function ShowListOfGrnForm(props) {
  const getPurchaseOrder = useSelector(selectorGetPurchaseOrder);
  const {
    displayGrnNumber,
    getGrnItemRcvRejQty,
    getGrnDateFilterWise,
    getDateFilterAllGrnNo,
  } = actionDispatch(useDispatch());
  const getAllGrnNumber = useSelector(selectorGetGrnNumber);
  const [cardType, setCardType] = useState("");
  const [showGrnItemForm, setShowGrnItemForm] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const getDateFilterGrn = useSelector(selectorGetDateFilterGrnList);
  const getDateFilterGrnNumber = useSelector(selectorGetDateFilterGrnNumber);

  // select the type of table
  const handleCardTypeChange = (value) => {
    setCardType(value);
    displayGrnNumber();
    getGrnDateFilterWise();
    getDateFilterAllGrnNo();
  };

  // click button
  const viewGrnItems = (record) => {
    getGrnItemRcvRejQty(record.grn_no);
    setShowGrnItemForm(true);
  };
  const handelcancel = () => {
    setShowGrnItemForm(false);
  };

  //po number wise grn colunm
  const columns = [
    {
      title: "ID.",
      dataIndex: "key",
    },
    {
      title: "View Of GRN Items.",
      dataIndex: "grn_no",
      render: (text, record, index) => {
        return (
          <div>
            <Button className="button-29" onClick={() => viewGrnItems(record)}>
              View GRN Items
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "GRN No.",
      dataIndex: "grn_no",
      //   render: (text, record, index) => {
      //     return (
      //       <div>
      //         <Button className="button-29" onClick={() => viewGrnItems(record)}>
      //           View GRN No. {record.grn_no}{" "}
      //           <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
      //         </Button>
      //       </div>
      //     );
      //   },
    },
    {
      title: "Purchase Order No.",
      dataIndex: "po_number",
    },
    {
      title: "Po Date",
      dataIndex: "po_date",
    },

    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Supplier Name",
      dataIndex: "vender_name",
    },
    {
      title: "Purchase Order Value",
      dataIndex: "taxable_value",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
    },
    {
      title: "Invoice Value",
      dataIndex: "invoice_amount",
    },
    {
      title: "Grn Note",
      dataIndex: "grn_note",
      width: "30%",
    },
    {
      title: "Reject Comment",
      dataIndex: "reject_comment",
      width: "30%",
    },
  ];

  // date filter wise grn list
  const columns2 = [
    {
      title: "ID.",
      dataIndex: "key",
    },
    {
      title: "View Of GRN Items.",
      dataIndex: "grn_no",
      render: (text, record, index) => {
        return (
          <div>
            <Button className="button-29" onClick={() => viewGrnItems(record)}>
              View GRN Items
              <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "GRN No.",
      dataIndex: "grn_no",
      //   render: (text, record, index) => {
      //     return (
      //       <div>
      //         <Button className="button-29" onClick={() => viewGrnItems(record)}>
      //           View GRN No. {record.grn_no}{" "}
      //           <EyeOutlined style={{ fontSize: "20px", color: "#08c" }} />
      //         </Button>
      //       </div>
      //     );
      //   },
    },
    {
      title: "Purchase Order No.",
      dataIndex: "po_number",
    },
    {
      title: "Po Date",
      dataIndex: "po_date",
    },

    {
      title: "GRN Date",
      dataIndex: "grn_date",
    },
    {
      title: "Supplier Name",
      dataIndex: "vender_name",
    },

    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
    },
    {
      title: "Taxable Amout",
      dataIndex: "taxable_amount",
    },
    {
      title: "I GST",
      dataIndex: "i_gst",
    },
    {
      title: "C GST",
      dataIndex: "c_gst",
    },
    {
      title: "S GST",
      dataIndex: "s_gst",
    },

    {
      title: "Total Value",
      dataIndex: "invoice_amount",
    },

    {
      title: "Grn Note",
      dataIndex: "grn_note",
      width: "30%",
    },
  ];

  const columns3 = [
    {
      title: "Sl Numbers",
      dataIndex: "sl_no",
    },
    {
      title: "GRN Numbers",
      dataIndex: "grn_number",
    },
    {
      title: "GRN Dates",
      dataIndex: "grn_date",
    },
    {
      title: "Prepared Names",
      dataIndex: "name",
    },
  ];

  const handlePoSelect = (value) => {
    displayGrnNumber(value);
  };
  const data = [];
  for (let i = 0; i < getAllGrnNumber?.length; i++) {
    // po date comes but show 1 day less so add 1day
    const poDateObject = new Date(getAllGrnNumber[i].po_date);
    // poDateObject.setDate(poDateObject.getDate())

    // Grn date comes but show 1 day less so add 1day
    const grnDateObject = new Date(getAllGrnNumber[i].grn_date);
    //grnDateObject.setDate(grnDateObject.getDate())grn_date
    data.push({
      id: getAllGrnNumber[i].id,
      key: i + 1,
      po_number: getAllGrnNumber[i].po_number,
      po_date: poDateObject.toLocaleDateString("en-GB"),
      grn_no: getAllGrnNumber[i].grn_no,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      vender_name: getAllGrnNumber[i].vender_name,
      taxable_value: getAllGrnNumber[i].taxable_value,
      invoice_no: getAllGrnNumber[i].invoice_no,
      invoice_amount: getAllGrnNumber[i].invoice_amount,
      grn_note: getAllGrnNumber[i].grn_note,
      reject_comment: getAllGrnNumber[i].reject_comment,
    });
  }

  const dataTable = [];
  for (let i = 0; i < getDateFilterGrn.length; i++) {
    const poDateObject = new Date(getDateFilterGrn[i].po_date);
    const grnDateObject = new Date(getDateFilterGrn[i].grn_date);
    dataTable.push({
      id: getDateFilterGrn[i].id,
      key: i + 1,
      grn_no: getDateFilterGrn[i].grn_no,
      po_number: getDateFilterGrn[i].po_number,
      invoice_no: getDateFilterGrn[i].invoice_no,
      invoice_amount: getDateFilterGrn[i].invoice_amount,
      taxable_amount: getDateFilterGrn[i].taxable_amount,
      i_gst: getDateFilterGrn[i].i_gst,
      c_gst: getDateFilterGrn[i].c_gst,
      s_gst: getDateFilterGrn[i].s_gst,
      grn_note: getDateFilterGrn[i].grn_note,
      vender_name: getDateFilterGrn[i].vender_name,
      po_date: poDateObject.toLocaleDateString("en-GB"),
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
    });
  }

  const GrnNumberData = [];
  for (let i = 0; i < getDateFilterGrnNumber.length; i++) {
    const grnDateObject = new Date(getDateFilterGrnNumber[i].grn_date);
    GrnNumberData.push({
      sl_no: i + 1,
      id: getDateFilterGrnNumber[i].id,
      grn_number: getDateFilterGrnNumber[i].grn_number,
      grn_date: grnDateObject.toLocaleDateString("en-GB"),
      name: getDateFilterGrnNumber[i].name,
    });
  }

  const handleStartDate = (value, dateString) => {
    setStartDate(dateString);
  };
  const handleEndDate = (value, dateString) => {
    setEndDate(dateString);
  };

  const onFinishDate = async (e) => {
    e.preventDefault();
    const filterDate = {
      ...formData,
      project_id: props.projectID,
    };

    // endDate: "2024-06-11";
    // project_id: 319;
    // startDate: "2020-06-18";

    console.log("filterDate: ", filterDate);
    await getGrnDateFilterWise(filterDate);
  };

  const [formData, setFormData] = useState({
    endDate: "",
    project_id: "",
    startDate: "",
  });

  const onFinishDateWiseGrnNumber = async (e) => {
    e.preventDefault();
    const filterDate = {
      ...formData2,
      project_id: props.projectID,
    };

    await getDateFilterAllGrnNo(filterDate);
  };

  const [formData2, setFormData2] = useState({
    endDate: "",
    project_id: "",
    startDate: "",
  });
  const backToAllProject = () => {
    props.backProject();
  };
  return (
    <div className=" flex flex-col items-center">
      {/* <Form.Item label="Select The Type Of Grn Report">
        <Select
          // className="input-field"
          style={{ width: "400px" }}
          placeholder="Select The Type"
          onChange={handleCardTypeChange}
        >
          <Option value="POWISEGRNLIST">Purchase Order Wise GRN List </Option>
          <Option value="DATEGRNLIST">
            Date Wise GRN List All Purchase Order{" "}
          </Option>
          <Option value="DATEGRNNUMBER">Date Wise All GRN Number </Option>
        </Select>
      </Form.Item> */}

      {/* new table shivam kashyap - 5 June 2024 */}
      <div className=" font-montserrat  bg-white rounded-2xl ml-3 flex flex-col  w-full">
        <div className=" flex justify-between mt-3  mx-11 ">
          <div className="   font-montserrat">
            <button
              onClick={() => backToAllProject()}
              type="button"
              class="flex  justify-evenly items-center rounded-full bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              All Projects
            </button>
          </div>
          <div className=" font-semibold  text-2xl">Show All GRN List</div>
          <div className=" font-montserrat">
            <div className="mt w-[300px]">
              <label
                htmlFor=""
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Select The Type Of Grn Report
              </label>
              <select
                // required
                name=""
                placeholder="Select the process"
                // value={formData.po_status}
                onChange={(e) => {
                  setCardType(e.target.value);
                  displayGrnNumber();
                  getGrnDateFilterWise();
                  getDateFilterAllGrnNo();
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option defaultChecked value="">
                  Select
                </option>

                <option value="POWISEGRNLIST">
                  Purchase Order Wise GRN List{" "}
                </option>
                <option value="DATEGRNLIST">
                  Date Wise GRN List All Purchase Order{" "}
                </option>
                <option value="DATEGRNNUMBER">Date Wise All GRN Number </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {cardType === "POWISEGRNLIST" && (
        <div className=" font-montserrat   w-full ">
          <div className="mt w-[300px]">
            <label
              htmlFor=""
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              <span className="text-red-600">*</span>
              Selcte Purchase Order Number
            </label>
            <select
              // required
              name=""
              placeholder="Select the process"
              // value={formData.po_status}
              onChange={(e) => {
                displayGrnNumber(e.target.value);
              }}
              className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
            >
              <option defaultChecked value="">
                Select
              </option>

              {getPurchaseOrder.materials.map((item) => (
                <option key={item.id} value={item.po_number}>
                  {item.po_number}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {cardType === "DATEGRNLIST" && (
        <div className="  w-full">
          {/* <Form form={form} onFinish={onFinishDate}>
            <Form.Item
              name="startDate"
              label="Start Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Start Date!",
                },
              ]}
            >
              <DatePicker
                name="startDate"
                onChange={handleStartDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter End Date!",
                },
              ]}
            >
              <DatePicker
                name="endDate"
                onChange={handleEndDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-createAdmin">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Filter</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}

          {/* new form shivam kashyap  */}
          <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={onFinishDate}
          >
            <div className="flex  justify-start gap-x-5 mb-10   w-full ">
              {/* 3 date */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="startDate"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Start Date
                </label>
                <input
                  required
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      startDate: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>{" "}
              {/* 2 */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="endDate"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  End Date
                </label>
                <input
                  required
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      endDate: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>
              {/* btn  */}
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block  mt-4 rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Filter
              </button>
            </div>
          </form>
        </div>
      )}
      {cardType === "DATEGRNNUMBER" && (
        <div className=" w-full">
          {/* <Form form={form2} onFinish={onFinishDateWiseGrnNumber}>
            <Form.Item
              name="startDate"
              label="Start Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter Start Date!",
                },
              ]}
            >
              <DatePicker
                name="startDate"
                onChange={handleStartDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please enter End Date!",
                },
              ]}
            >
              <DatePicker
                name="endDate"
                onChange={handleEndDate}
                placeholder="Enter Date"
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item>
              <div className="btn-createAdmin">
                <Button type="primary" htmlType="submit" className="btn-submit">
                  <strong>Filter</strong>
                </Button>
              </div>
            </Form.Item>
          </Form> */}
          {/* new form shivam kashyap  */}
          <form
            className="font-montserrat flex flex-col items-center  gap-y-3"
            onSubmit={onFinishDateWiseGrnNumber}
          >
            {" "}
            <div className="flex  justify-start gap-x-5    w-full ">
              {/* 3 date */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="startDate"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  Start Date
                </label>
                <input
                  required
                  type="date"
                  name="startDate"
                  value={formData2.startDate}
                  onChange={(e) => {
                    setFormData2({
                      ...formData2,
                      startDate: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>{" "}
              {/* 2 */}
              <div class="relative  w-[300px] h-[35px]">
                <label
                  for="endDate"
                  class="block text-sm mb- font-medium leading-6 text-gray-900"
                >
                  <span className="text-red-600">*</span>
                  End Date
                </label>
                <input
                  required
                  type="date"
                  name="endDate"
                  value={formData2.endDate}
                  onChange={(e) => {
                    setFormData2({
                      ...formData2,
                      endDate: e.target.value,
                    });
                  }}
                  class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                  placeholder="Select a date"
                />
              </div>
              {/* btn  */}
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="inline-block  mt-4 rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Filter
              </button>
            </div>
          </form>
        </div>
      )}
      {cardType === "POWISEGRNLIST" && (
        <div className=" m-0 p-0 flex flex-col">
          {/* <div className="font-montserrat    w-[1200px] overflow-x-scroll  mt-5 ml-4 flex flex-col  items-start justify-center ">
            <ul role="list" className="divide-y divide-[#f8dcdc] ">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[100px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    ID
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    View Of GRN Items.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[300px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN No.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Purchase Order No.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Po Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Supplier Name
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Purchase Order Value
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Invoice Number
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Invoice Value
                  </p>
                </div>
                <div className="flex flex-col items-start w-[350px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Grn Note
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Reject Comment
                  </p>
                </div>
              </li>
            </ul>
            <ul
              role="list"
              className="divide-y   divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[60vh] "
            >
              {data && data.length
                ? data.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[100px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.id ? item.id : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <a
                          onClick={() => viewGrnItems(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="View GRN Item"
                        >
                          <FaEye
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={() => deleteRow(item)}
                          />
                        </a>{" "}
                      </div>
                      <div className="flex flex-col items-start w-[300px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_no ? item.grn_no : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_number ? item.po_number : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_date ? formatDate(item.po_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_date ? formatDate(item.grn_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.vender_name ? item.vender_name : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.taxable_value
                            ? formatNumberWithCommas(item.taxable_value)
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_no ? item.invoice_no : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_amount
                            ? formatNumberWithCommas(item.invoice_amount)
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[350px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_note ? item.grn_note : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.reject_comment ? item.reject_comment : "-"}
                        </p>
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div> */}

          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[100px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    ID
                  </p>
                </div>
                <div className="flex flex-col items-start w-[350px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN No.
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    GRN Date
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    View Of GRN Items
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    PO Number
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    PO Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Purchase Order Value
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Supplier name
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Invoice Number
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Invoice Value
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Grn Note
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Reject Comment
                  </p>
                </div>
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
            >
              {data && data.length && data[0] != null
                ? data.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[100px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.id ? item.id : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[350px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_no ? item.grn_no : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.grn_date ? formatDate(item.grn_date) : "-"}
                        </p>{" "}
                        <a
                          onClick={() => viewGrnItems(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="View GRN Item"
                        >
                          <FaEye
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={() => deleteRow(item)}
                          />
                        </a>{" "}
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_number ? item.po_number : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.po_date ? formatDate(item.po_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.taxable_value ? item.taxable_value : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.vender_name ? item.vender_name : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_no ? item.invoice_no : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.invoice_amount ? item.invoice_amount : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_note ? item.grn_note : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.reject_comment ? item.reject_comment : "-"}
                        </p>
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>
        </div>
      )}
      {cardType === "DATEGRNLIST" && (
        <div className=" flex flex-col items-center m-0 p-0">
          {/* <Table
            scroll={{ x: "80vw" }}
            columns={columns2}
            dataSource={dataTable}
            pagination={10}
          /> */}

          {/* new table shivam kashyap 21 june 2024  */}
          {/* <div className="font-montserrat    w-[1200px] overflow-x-scroll  mt-5 ml-4 flex flex-col  items-start justify-center ">
            <ul role="list" className="divide-y divide-[#f8dcdc] bg-green-400 ">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[100px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    ID
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    View Of GRN Items.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[300px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN No.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Purchase Order No.
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Po Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Supplier Name
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Invoice Number
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Taxable Amout
                  </p>
                </div>
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    I Gst
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    C Gst
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    S Gst
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Total Value
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Grn Note
                  </p>
                </div>
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y   divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[60vh] "
            >
              {dataTable && dataTable.length
                ? dataTable.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[100px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.id ? item.id : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <a
                          onClick={() => viewGrnItems(item)}
                          href="#"
                          class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                          data-te-toggle="tooltip"
                          title="View GRN Item"
                        >
                          <FaEye
                            className=" text-top_nav_blue-300 "
                            size={18}
                            // onClick={() => deleteRow(item)}
                          />
                        </a>{" "}
                      </div>
                      <div className="flex flex-col items-start w-[300px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_no ? item.grn_no : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_number ? item.po_number : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_date ? formatDate(item.po_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_date ? formatDate(item.grn_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.vender_name ? item.vender_name : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_no ? item.invoice_no : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.taxable_amount
                            ? formatNumberWithCommas(item.taxable_amount)
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.i_gst
                            ? formatNumberWithCommas(item.i_gst)
                            : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.c_gst
                            ? formatNumberWithCommas(item.c_gst)
                            : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.s_gst
                            ? formatNumberWithCommas(item.s_gst)
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_amount
                            ? formatNumberWithCommas(item.invoice_amount)
                            : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_note ? item.grn_note : "-"}
                        </p>
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div> */}

          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[100px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    ID
                  </p>
                </div>
                <div className="flex flex-col items-start w-[350px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN No.
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    GRN Date
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    View Of GRN Items
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    PO Number
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    PO Date
                  </p>
                </div>
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Invoice Number
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    Supplier Name
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Taxable Amout
                  </p>
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    I GST
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    C GST
                  </p>{" "}
                  <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                    S GST
                  </p>
                </div>{" "}
                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Total Value
                  </p>
                </div>
                <div className="flex flex-col items-start w-[250px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Grn Note
                  </p>
                </div>{" "}
              </li>
            </ul>{" "}
            <ul
              role="list"
              className="divide-y w-[1200px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] "
            >
              {dataTable && dataTable.length
                ? dataTable.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[100px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.id ? item.id : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[350px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_no ? item.grn_no : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.grn_date ? formatDate(item.grn_date) : "-"}
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          View Of GRN Items
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.po_number ? item.po_number : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.po_date ? formatDate(item.po_date) : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_no ? item.invoice_no : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.vender_name ? item.vender_name : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.taxable_amount
                            ? formatNumberWithCommas(item.taxable_amount)
                            : "-"}
                        </p>
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.i_gst ? item.i_gst : "-"}
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.c_gst ? item.c_gst : "-"}
                        </p>{" "}
                        <p className="mt-1 text-xs leading-5 text-gray-500 w-full">
                          {item?.s_gst ? item.s_gst : "-"}
                        </p>
                      </div>{" "}
                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.invoice_amount
                            ? formatNumberWithCommas(item.invoice_amount)
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[250px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_note ? item.grn_note : "-"}
                        </p>
                      </div>{" "}
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>
        </div>
      )}
      {cardType === "DATEGRNNUMBER" && (
        <div className=" flex flex-col items-center m-0 p-0">
          {/* <Table
            scroll={{ x: "80vw" }}
            columns={columns3}
            dataSource={GrnNumberData}
            pagination={10}
          /> */}
          {/* new table shivam kashyap 21 june 2024  */}
          <div className="font-montserrat mt-5 ml-4 flex flex-col items-center justify-center">
            <ul role="list" className="divide-y w-[1200px] divide-[#f8dcdc]">
              <li className="flex justify-between items-center px-5 bg-[#F5F5F5] py-2">
                <div className="flex flex-col items-start w-[150px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Sl Number
                  </p>
                </div>

                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN Number
                  </p>
                </div>

                <div className="flex flex-col items-start w-[350px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    GRN Date
                  </p>
                </div>

                <div className="flex flex-col items-start w-[200px]">
                  <p className="text-sm font-semibold text-gray-900 w-full">
                    Prepared Names
                  </p>
                </div>
              </li>
            </ul>{" "}
            {/* Content Section */}
            <ul
              role="list"
              className="divide-y w-[1215px] divide-[#F5F5F5] overflow-y-auto max-h-[450px] max-h-[61vh] ml-[15px]"
            >
              {GrnNumberData && GrnNumberData.length
                ? GrnNumberData.map((item, key) => (
                    <li
                      key={key}
                      className={`flex justify-between items-center px-5 py-2 ${
                        key % 2 !== 0 ? "bg-[#F5F5F5]" : "bg-[#FFF]"
                      }`}
                    >
                      <div className="flex flex-col items-start w-[150px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.sl_no ? item.sl_no : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_number ? item.grn_number : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col items-start w-[350px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.grn_date ? formatDate(item.grn_date) : "-"}
                        </p>
                      </div>

                      <div className="flex flex-col items-start w-[200px]">
                        <p className="text-sm font-semibold text-gray-900 w-full">
                          {item?.name ? item.name : "-"}
                        </p>
                      </div>
                    </li>
                  ))
                : "No records found..."}
            </ul>
          </div>{" "}
        </div>
      )}

      {/* modals  */}
      <Modal
        visible={showGrnItemForm}
        footer={null}
        width={1500}
        onCancel={handelcancel}
      >
        <ShowPoNumberWiseItemsForm />
      </Modal>
    </div>
  );
}

export default ShowListOfGrnForm;
