// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-list_labour{
   display: flex;
   justify-content: center;
   align-items: center;
}

.btn-submit:hover {
   background-color: white;
   color: #f1e206;
   border: 1px solid #f1e206;
 }

 .btn-list_labour .btn-submit {
   margin-right: 10px;
   background-color: #FFFF00;
   color: black;
   border: #FFFF00;
   /* width: 20%; */
   border-radius: 10px;
 }`, "",{"version":3,"sources":["webpack://./src/components/resources/content/Forms/css/ListEquipmentDeploymentForm.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;AACtB;;AAEA;GACG,uBAAuB;GACvB,cAAc;GACd,yBAAyB;CAC3B;;CAEA;GACE,kBAAkB;GAClB,yBAAyB;GACzB,YAAY;GACZ,eAAe;GACf,gBAAgB;GAChB,mBAAmB;CACrB","sourcesContent":[".btn-list_labour{\n   display: flex;\n   justify-content: center;\n   align-items: center;\n}\n\n.btn-submit:hover {\n   background-color: white;\n   color: #f1e206;\n   border: 1px solid #f1e206;\n }\n\n .btn-list_labour .btn-submit {\n   margin-right: 10px;\n   background-color: #FFFF00;\n   color: black;\n   border: #FFFF00;\n   /* width: 20%; */\n   border-radius: 10px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
