import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.min.css";
// import './css/AddProjectForm.css';
import {
  Form,
  Input,
  Button,
  Upload,
  Modal,
  InputNumber,
  Space,
  message,
  DatePicker,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  createNewProj,
  countAction,
  getAllProj,
  countNoOfProject,
} from "../../../../redux-core/settings/actions";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import { PlusOutlined } from "@ant-design/icons";
import "../Forms/css/AddProjectForm.css";
import { selectorCountProj } from "../../../../redux-core/settings/selectors";
import moment from "moment";
import toast from "react-hot-toast";

const actionDispatch = (dispatch) => ({
  newProj: (newProject) => dispatch(createNewProj(newProject)),
  count: (orgId) => dispatch(countAction(orgId)),
  projects: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  countNoOfProject: (orgId) => dispatch(countNoOfProject(orgId)),
});

const AddProjectForm = ({ showAddProject, setShowAddProject }) => {
  const { newProj, count, projects, countNoOfProject } = actionDispatch(
    useDispatch()
  );
  const [form] = Form.useForm();
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const orgName = logindetails.organisation.orgName;
  const userId = logindetails.user.id;
  const [imagecard, setImagecard] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  const [previewImage, setPreviewimage] = useState(false);
  const [passProjectName, setPassProjectName] = useState();
  const countProject = useSelector(selectorCountProj);

  // date picker
  const [account, setAccount] = React.useState({
    startdate: "",
    enddate: "",
    actual_startdate: "",
    actual_enddate: "",
    reason: "",
    leavetype: "",
  });
  function handleChangeDatePicker(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAccount({ ...account, [name]: value });
  }

  useEffect(() => {
    console.log("Org id------", orgId);
    console.log("User id------", userId);
  }, []);

  // const [isFocus, setIsFocus] = useState(false);
  useEffect(() => {
    projects(orgId, userId);
    countNoOfProject(orgId);
  }, []);

  // form finish
  // const onAddProjectFinish = async (e) => {
  //   e.preventDefault();

  //   // set project code ]

  //   // await HandeleCreateProjectCode();

  //   let data = await {
  //     ...formData,
  //     ...{
  //       orgID: orgId,
  //       created_by: userId,
  //     },
  //   };

  //   // project code
  //   const inputValue = formData.project_name;

  //   const projecetChara = orgName.substring(0, 5).toUpperCase();
  //   let count_number =
  //     countProject[0].no_of_Proj === 0
  //       ? "001"
  //       : (Number(countProject[0].no_of_Proj) + 1).toString().padStart(3, "0");
  //   const shortCode =
  //     "SM/" + projecetChara + "/" + inputValue + "/" + count_number;
  //   // setPassProjectName(shortCode);
  //   await setFormData({
  //     ...formData,
  //     project_code: (shortCode),
  //   });

  //   console.log("Formdata: ", data, "........", orgId, userId,shortCode);

  //   return;
  //   if (
  //     data &&
  //     data.actual_start_date &&
  //     moment(data.actual_start_date).isValid()
  //   ) {
  //     data.actual_start_date = moment(data.actual_start_date).format(
  //       "YYYY-MM-DD HH:mm:ss"
  //     );
  //   }

  //   if (
  //     data &&
  //     data.actual_end_date &&
  //     moment(data.actual_end_date).isValid()
  //   ) {
  //     data.actual_end_date = moment(data.actual_end_date).format(
  //       "YYYY-MM-DD HH:mm:ss"
  //     );
  //   }

  //   if (data.image && data.image.length < 1) {
  //     delete data.image;
  //   }

  //   // console.log("Final project creatrion data: ", data);
  //   return;
  //   let response = await newProj(data);
  //   if (response) {
  //     await clear_Project_field();
  //     await count(orgId);
  //     await projects(orgId, userId);
  //   }
  //   // await newProj(data);
  //   console.log("date ----  : ", data?.actual_end_date?._d);
  //   console.log("full data ----  : ", data);
  //   console.log("res : ", response);
  // };
  const onAddProjectFinish = async (e) => {
    e.preventDefault();

    // Generate project code
    const inputValue = formData.project_name.toUpperCase();
    const projecetChara = orgName.substring(0, 5).toUpperCase();
    let count_number =
      countProject[0].no_of_Proj === 0
        ? "001"
        : (Number(countProject[0].no_of_Proj) + 1).toString().padStart(3, "0");
    const shortCode =
      "SM/" + projecetChara + "/" + inputValue + "/" + count_number;

    // Update formData with project_code and wait for it to complete
    await new Promise((resolve) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        project_code: shortCode,
      }));
      resolve();
    });

    let imageBase64String = "";

    if (imageFile) {
      try {
        imageBase64String = await getBase64(imageFile);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error(
          "An error occurred while processing the image. Please try again."
        );
        return;
      }
    }

    const imageObject = {
      lastModified: imageFile.lastModified,
      lastModifiedDate: imageFile.lastModifiedDate,
      name: imageFile.name,
      originFileObj: imageFile,
      percent: 0,
      size: imageFile.size,
      thumbUrl: imageBase64String,
      type: imageFile.type,
      uid: "rc-upload-" + Date.now(), // Generate unique uid
    };
    imageObject.thumbUrl = imageObject.thumbUrl.replace(
      "data:image/jpeg;",
      "data:image/png;"
    );

    // Prepare the data object

    let data = {
      ...formData,
      image: [imageObject],
      project_code:
        "SM/" +
        formData.project_name.substring(0, 4).toUpperCase() +
        "/" +
        String(Date.now()).slice(-4),
      ...{
        orgID: orgId,
        created_by: userId,
      },
    };

    // let data = {
    //   ...formData,
    //   orgID: orgId,
    //   created_by: userId,
    // };

    console.log("Formdata: ", data, "........", orgId, userId, shortCode);

    // return;

    // Handle date formatting
    // if (data.actual_start_date && moment(data.actual_start_date).isValid()) {
    //   data.actual_start_date = moment(data.actual_start_date).format(
    //     "YYYY-MM-DD HH:mm:ss"
    //   );
    // }

    // if (data.actual_end_date && moment(data.actual_end_date).isValid()) {
    //   data.actual_end_date = moment(data.actual_end_date).format(
    //     "YYYY-MM-DD HH:mm:ss"
    //   );
    // }

    if (data.image && data.image.length < 1) {
      delete data.image;
    }

    try {
      let response = await newProj(data);
      if (response.success) {
        console.log("res...........", response);
        toast.success("Success..");
        setShowAddProject(false);
        await clear_Project_field();
        await count(orgId);
        await projects(orgId, userId);
      }

      console.log("date ----  : ", data?.actual_end_date?._d);
      console.log("full data ----  : ", data);
      console.log("res : ", response);
    } catch (err) {
      toast.error("Failed..");
      console.log("Some error occurred while creating project: ", err);
    }

    // Send the project creation request
  };

  // end

  const onAddProjectFinishFailed = (errorInfo) => {
    console.log("Failed : ", errorInfo);
  };

  const clear_Project_field = () => {
    form.setFieldsValue({
      project_name: "",
      //paying_company: "",
      description: "",
      image: [],
      project_start_date: "",
      project_end_date: "",
      actual_start_date: "",
      // actual_end_date: "",
      original_budget: "",
      project_short_cut: "",
      project_code: "",
    });
    setImagecard(false);
  };

  const normFile = (e) => {
    if (e.fileList.length > 0) setImagecard(true);
    else setImagecard(false);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      {/* <div 
      style={{ marginTop: 8 }}
      > Click to Upload</div> */}
    </div>
  );

  const handleCancel = () => setPreviewvisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewimage(file.url || file.preview);
    setPreviewvisible(true);
  };

  // Date Validation and Function  Project Start Date, Actual Start Date, Actual End Date, Project End Date
  const disabledStartDate = (current) => {
    // Disable dates before today
    return current && current.isBefore(Date.now(), "day");
  };

  // Project Start Date and Project Ende Date
  const handleEndDateChange = (endDate) => {
    const startDate = form.getFieldValue("project_start_date");

    if (startDate && endDate && endDate.isSameOrBefore(startDate, "day")) {
      message.error("Project End date must be greater than project Start date");
      form.setFieldsValue({ project_end_date: null });
    }
  };

  const handleStartDateChange = (startDate) => {
    const endDate = form.getFieldValue("project_end_date");
    if (startDate && endDate && startDate.isSame(endDate, "day")) {
      message.error("Project start date must be Smaller than project end date");
      form.setFieldsValue({ project_start_date: null });
    }
  };
  // Actual Project Start Date and Actual Project End date

  const handleActualEndDateChange = (endDateValue) => {
    const actualStartDate = form.getFieldValue("actual_start_date");

    if (
      actualStartDate &&
      endDateValue &&
      endDateValue.isSameOrBefore(actualStartDate, "day")
    ) {
      message.error(
        "Actual Project End date must be greater than or equal to Actual project Start date"
      );
      form.setFieldsValue({ actual_end_date: null });
    }
  };

  const handleactualStartDateChange = (actualStartDate) => {
    const projectStartDate = form.getFieldValue("project_start_date");
    const projectEndDate = form.getFieldValue("project_end_date");

    if (
      actualStartDate &&
      projectStartDate &&
      actualStartDate.isBefore(projectStartDate, "day")
    ) {
      message.error(
        "Actual Project start date must be on or after Project start date"
      );
      form.setFieldsValue({ actual_start_date: null });
    } else if (
      actualStartDate &&
      projectEndDate &&
      actualStartDate.isAfter(projectEndDate, "day")
    ) {
      message.error(
        "Actual Project start date must be on or before Project end date"
      );
      form.setFieldsValue({ actual_start_date: null });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      project_code: passProjectName,
    });
  }, [passProjectName]);

  const HandeleCreateProjectCode = async (val) => {
    // console.log("Project code function called");
    // const inputValue = event.target.value.toUpperCase();
    const inputValue = formData.project_name;

    const projecetChara = orgName.substring(0, 5).toUpperCase();
    let count_number =
      countProject[0].no_of_Proj === 0
        ? "001"
        : (Number(countProject[0].no_of_Proj) + 1).toString().padStart(3, "0");
    const shortCode =
      "SM/" + projecetChara + "/" + inputValue + "/" + count_number;
    // setPassProjectName(shortCode);
    await setFormData({
      ...formData,
      project_code: toString(shortCode),
    });

    // console.log("Project code function called", shortCode, formData);
  };

  const [formData, setFormData] = useState({
    project_code: "",
    project_name: "",
    actual_end_date: "",
    actual_start_date: "",
    created_by: "",
    description: "",
    orgID: "",
    original_budget: "",
    project_end_date: "",
    project_start_date: "",
    project_short_cut: "",
    // image: [],
  });
  const [imageFile, setImageFile] = useState([]);
  // useEffect(() => {
  //   console.log("Form data: ", formData);
  // }, [formData]);

  return (
    <>
      {/* <Form
        form={form}
        name="add-project"
        size="large"
        initialValues={{
          remember: true,
        }}
        // onFocus={focusHandler}
        onFinish={onAddProjectFinish}
        onFinishFailed={onAddProjectFinishFailed}
      >
        <Form.Item
          name="project_name"
          label="Project Name"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the Project Name !",
            },
            {
              pattern: /^(.{0,100})$/,
              message: "please enter less than 100 characters only",
            },
          ]}
        >
          <Input placeholder="Project Name" onKeyDown={(e) => onKeydown(e)} />
        </Form.Item>
        <Form.Item
          name="project_short_cut"
          label="Project Short Form"
          className="input-field"
          rules={[
            {
              required: true,
              message: "Please enter the Project Short Form !",
            },
            {
              pattern: /^.{0,5}$/, // Regex pattern for a maximum of 5 characters
              message: "Maximum 5 characters allowed!",
            },
          ]}
        >
          <Input
            placeholder="Project Short Form For  Code Generate"
            onBlur={HandeleCreateProjectCode}
          />
        </Form.Item>
        <Form.Item
          name="project_code"
          label="Project Code"
          className="input-field"
          rules={[
            {
              required: false,
              message: "Please enter the Project Code !",
            },
          ]}
          initialValue={passProjectName}
          //help="It's a combination of Sitemate, Organization, and Shortcut Code, with an added serial number for the project within this organization."
        >
          <Input placeholder="Project Code" disabled />
        </Form.Item>

        <Form.Item
          name="project_start_date"
          label="Project Start Date"
          className="firstRow"
          rules={[
            {
              required: true,
              message: "Please select Project Start Date !",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            //  disabledDate={disabledStartDate}
            onChange={handleStartDateChange}
          />
        </Form.Item>
        <Form.Item
          name="project_end_date"
          label="Project End Date"
          className="secondRow"
          rules={[
            {
              required: true,
              message: "Please select Project End Date !",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            //disabledDate={disabledStartDate}
            onChange={handleEndDateChange}
          />
        </Form.Item>

        <Form.Item
          name="actual_start_date"
          label="Actual Start Date"
          className="thirdRow"
          rules={[
            {
              required: false,
              message: "Please select Actual Start Date !",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            //  disabledDate={disabledStartDate}
            onChange={handleactualStartDateChange}
          />
        </Form.Item>
        <Form.Item
          name="actual_end_date"
          label="Actual End Date"
          className="fourthRow"
          rules={[
            {
              required: false,
              message: "Please select Actual End Date !",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            //   disabledDate={disabledStartDate}

            onChange={handleActualEndDateChange}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          className="input-field"
          rules={[
            {
              pattern: /^(.{0,300})$/,
              message: "please enter less than 300 charactersonly",
            },
          ]}
        >
          <Input.TextArea
            style={{ maxHeight: "130px", minHeight: "130px" }}
            onKeyDown={(e) => onKeydown(e)}
          />
        </Form.Item>

        <Form.Item
          name="image"
          label="Upload"
          // hovarable
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="input-field"
        >
        

          <Upload
            name="logo"
            // hovarable
            listType="picture-card"
            accept=".jpg,.jpeg,.png"
            maxCount="1"
            beforeUpload={(file, fileList) => {
              return false;
            }}
            onPreview={handlePreview}
            // showUploadList={false}
          >
            {!imagecard && uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          name="original_budget"
          label="Budget"
          className="input-field"
          rules={[
            // {
            //   required: false,
            //   message: "Please enter the Original Budget !",
            // },
            {
              pattern: /\d{5}/,
              message: "Value must be greater than equal to 10000",
            },
          ]}
        >
        
          <InputNumber type="number" style={{ width: "100%" }} />

        
        </Form.Item>

        <Form.Item>
          <div className="btn-project">
            <Button type="primary" htmlType="submit" className="btn-submit">
              <strong>Submit</strong>
            </Button>

            <Button onClick={clear_Project_field} className="btn-cancel">
              <strong>Reset</strong>
            </Button>
          </div>
        </Form.Item>
      </Form> */}
      {/* new form shivam kashyap 21 may 2024 */}
      <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
        <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
          Create New Project
        </div>

        {/* form  */}
        <form className="font-montserrat" onSubmit={onAddProjectFinish}>
          {/* 1a */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1  */}
            <div className="">
              <label
                htmlFor="problem_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Project Name
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="project_name"
                  value={formData.project_name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      project_name: e.target.value,
                    });
                    // HandeleCreateProjectCode(e.target.value);
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2  */}
            <div className="">
              <label
                htmlFor="problem_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Project Short Form
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="project_name"
                  value={formData.project_short_cut}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      project_short_cut: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 3  */}
            <div className="">
              <label
                htmlFor="problem_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Budget
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="number"
                  name="original_budget"
                  value={formData.original_budget}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      original_budget: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
          </div>

          {/* 2a  */}
          <div className="flex my-3 justify-evenly w-[90vw]">
            {/* 1 */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="target_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                project Start Date
              </label>
              <input
                required
                type="date"
                name="project_start_date"
                value={formData.project_start_date}
                // onChange={handleInputChange}
                onChange={(e) => {
                  // HandeleCreateProjectCode();
                  setFormData({
                    ...formData,
                    project_start_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
            {/* 2 */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="target_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                project End Date
              </label>
              <input
                required
                type="date"
                name="project_end_date"
                value={formData.project_end_date}
                // onChange={handleInputChange}
                onChange={(e) => {
                  // setTargetDate(e.target.value),
                  setFormData({
                    ...formData,
                    project_end_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
            {/* 3 */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="target_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Actual Start Date
              </label>
              <input
                // required
                type="date"
                name="actual_start_date"
                value={formData.actual_start_date}
                // onChange={handleInputChange}
                onChange={(e) => {
                  // setTargetDate(e.target.value),
                  setFormData({
                    ...formData,
                    actual_start_date: e.target.value,
                  });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
          </div>

          {/* 3a */}
          <div className="flex my-10 justify-evenly w-[90vw]">
            {/* 1  */}
            <div className="">
              <label
                htmlFor="problem_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Description
              </label>
              <div className="mt- w-[300px]">
                <input
                  // required
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      description: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* 2 */}

            <div className="w-[300px] ">
              <label
                htmlFor="image"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Project Image
              </label>
              <input
                // required
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="image"
                type="file"
                accept="image/svg+xml, image/png, image/jpeg, image/gif"
                onChange={(event) => {
                  const fileInput = event.target;
                  const file = fileInput.files[0];

                  if (file) {
                    setImageFile(file);
                    console.log("filse set: ", imageFile);
                  }
                }}
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                SVG, PNG, JPG or GIF .
              </p>
            </div>

            {/* 3  */}
            <div className="w-[300px] "></div>
          </div>
          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
            <button
              // onClick={resetAllValue}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default AddProjectForm;
