import React, { Suspense, useCallback, useState } from "react";
import { Layout, Menu, Spin } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import "./SideNavReports.css";
import "antd/dist/antd.min.css";
import TopNavbar from "../../settings/topnavbar/TopNavbar";
import MediaQuery from "react-responsive";
import Dashboard from "../content/Dashboard";
import { makeSelectLoginDetail } from "../../../redux-core/login/selectors";
import { useSelector } from "react-redux";

// const MyProjects = React.lazy(() => import("../content/MyProjects"));
const { Sider, Content } = Layout;

// const { SubMenu } = Menu;

const SideNavReports = () => {
  const [collapsed, setCollapsed] = useState(false);
  const logindetails = useSelector(makeSelectLoginDetail);
  const dataspin = useSelector((state) => state.spinreducer);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [content, setContent] = useState("1");

  const onMenuClick = useCallback((value) => {
    setContent(value);
  }, []);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        {/* side bar removed for reports page by - shivam  */}
        {/* <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ minWidth: "100px" }}
        >
          <div className="logo">
            {collapsed ? (
              <MenuUnfoldOutlined
                style={{ paddingTop: 18 }}
                className="trigger"
                onClick={toggle}
              />
            ) : logindetails.organisation.image === null ? (
              <h4>{logindetails.organisation.orgName}</h4>
            ) : (
              <img
                width="70px"
                src={logindetails.organisation.image}
                // src= "cognisie_logo_new.png"
                alt={logindetails.organisation.orgName}
              />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={(e) => onMenuClick(e.key)}
          >
            <Menu.Item
              key="1"
              icon={
                <img src="Dashboard.png" className="icon_img" alt="dashboard" />
              }
              style={{ textAlign: "center" }}
            >
              <div>Dashboard</div>
            </Menu.Item>

      
          </Menu>
        </Sider> */}

        <Layout className="site-layout">
          <MediaQuery maxWidth={600}>
            <div className="open-btn">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ paddingTop: 18 }}
                  className="trigger"
                  onClick={toggle}
                />
              ) : (
                <img
                  className="logo"
                  width="70px"
                  src={logindetails.organisation.image}
                  alt={logindetails.organisation.orgName}
                />
              )}
            </div>
          </MediaQuery>

          {/* top nav also removed because now we are using a global top nav  */}
          {/* <TopNavbar name="Reports" collapsed={!collapsed} toggle={toggle} /> */}

          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              overflow: "scroll",
            }}
          >
            {content === "1" ? <Dashboard /> : <></>}
            {content === "2" ? (
              <Suspense
                fallback={
                  <Spin
                    size="large"
                    spinning={dataspin.spin}
                    className="spin__component"
                  />
                }
              >
                {/* <MyProjects /> */}
              </Suspense>
            ) : (
              <></>
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default SideNavReports;
