import "antd/dist/antd.min.css";
import "./css/EhsCreateForm.css";
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  DatePicker,
  Upload,
  message,
  Modal,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProj,
  getAllProjDetails,
} from "../../../../redux-core/settings/actions";
import {
  makeSelectAllProj,
  makeSelectGetAllProjDetails,
} from "../../../../redux-core/settings/selectors";
import { makeSelectLoginDetail } from "../../../../redux-core/login/selectors";
import {
  createNewEhs,
  getProjectIdByUsers,
  riskCountAction,
} from "../../../../redux-core/risk/actions";
import { getAllAssignProjectId } from "../../../../redux-core/userManagement/actions";
import { selectorGetAllUserWiseAssignProjects } from "../../../../redux-core/userManagement/selectors";
import { selectorUsersProjectIdWiseState } from "../../../../redux-core/risk/selectors";
import emailjs from "@emailjs/browser";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import toast from "react-hot-toast";

const { Option } = Select;

const actionDispatch = (dispatch) => ({
  createNewEhs: (newEhs) => dispatch(createNewEhs(newEhs)),
  riskCountAction: (orgId) => dispatch(riskCountAction(orgId)),
  getAllProj: (orgId, userId) => dispatch(getAllProj(orgId, userId)),
  getAllProjDetails: (projID, orgId, userId) =>
    dispatch(getAllProjDetails(projID, orgId, userId)),
  getAllAssignProjectId: (orgId, userID) =>
    dispatch(getAllAssignProjectId(orgId, userID)),
  getProjectIdByUsers: (projectId, orgID) =>
    dispatch(getProjectIdByUsers(projectId, orgID)),
});

const EhsCreateForm = ({ onSubmit, setNewSiteObservation }) => {
  const [form] = Form.useForm();
  const {
    getAllProj,
    getAllProjDetails,
    createNewEhs,
    riskCountAction,
    getAllAssignProjectId,
    getProjectIdByUsers,
  } = actionDispatch(useDispatch());
  const allProjects = useSelector(makeSelectAllProj);
  const allProjDetails = useSelector(makeSelectGetAllProjDetails);
  const logindetails = useSelector(makeSelectLoginDetail);
  const orgId = logindetails.organisation.orgID;
  const userId = logindetails.user.id;
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const Spindata = useSelector((item) => item.spinreducer);
  const getAllAssignProjectIds = useSelector(
    selectorGetAllUserWiseAssignProjects
  );
  const getUserProjectWise = useSelector(selectorUsersProjectIdWiseState);
  const [toName, setToName] = useState(null);
  const [toMail, setToMail] = useState(null);
  const [targetDate, setTargetDate] = useState();
  const [previewImage, setPreviewimage] = useState(false);
  const [previewVisible, setPreviewvisible] = useState(false);
  // console.log("logindetails",logindetails)

  const handlePreview = async (file) => {
    // console.log("file.preview",!file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewimage(file.url || file.preview);

    setPreviewvisible(true);
  };
  const handleCancel = () => setPreviewvisible(false);

  useEffect(() => {
    getAllProj(orgId, userId);
    getAllAssignProjectId(orgId, userId);
  }, []);

  const ProjID = [];
  for (let i = 0; i < getAllAssignProjectIds.length; i++) {
    ProjID.push({
      Project_id: getAllAssignProjectIds[i].project_id,
    });
  }
  //console.log(ProjID);

  const projectData = ProjID.flatMap((item) => {
    return allProjects.filter((items) => items.project_id == item.Project_id);
  });
  //console.log("projectData", projectData);
  // console.log(allProjects);

  const handelProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    getAllProjDetails(projectId, orgId, userId);
    getProjectIdByUsers(projectId, orgId);
  };

  // console.log(targetDate)
  // Submit success
  const onFinish = async (values) => {
    const projectName = allProjects.filter(
      (item) => item.project_id == selectedProjectId
    );
    //console.log("{projectName}",projectName?.[0]?.project_name)
    console.log("values form: ", values);
    // return;
    const EmailSendingData = {
      sender_name: logindetails.user.name,
      sender_email: logindetails.user.email,
      to_name: toName,
      to_email: toMail,
      project_name: projectName?.[0]?.project_name,
      project_detail: values.project_detail_id,
      problem: values.problem_area,
      message: values.remark,
      issue_target_date: targetDate,
    };
    let data = {
      ...values,
      ...{
        orgID: orgId,
        created_by: userId,
        status: "OPEN",
      },
    };
    console.log("data form : ", data);
    return;
    let response = await createNewEhs(data);
    if (response) {
      await clear_fields();
      await riskCountAction(orgId);
      emailjs
        .send(
          "service_bg8rnnq",
          "template_bcyqoqv",
          EmailSendingData,
          "NOvWHUXt_s6AzJYeh"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
    console.log("Success:", values);

    //  console.log("EmailSendingData",EmailSendingData)
    console.log("data", data);
  };
  // submit fail
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clear_fields = () => {
    form.setFieldsValue({
      project_id: "",
      project_detail_id: "",
      problem_area: "",
      correction_action: "",
      response: "",
      response_date: "",
      target_date: "",
      remark: "",
      image: [],
    });
  };
  const onKeydown = (e) => {
    if (e.key === " " && e.target.value.length == 0) {
      e.preventDefault();
    }
  };

  const normFile = (e) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handelUserData = (userID) => {
    console.log("UserID: ", userID);
    const userData = getUserProjectWise.filter(
      (item) => item.user_id == userID
    );
    setToName(userData[0]?.name);
    setToMail(userData[0]?.email);
  };

  const handelTargetDate = (value, dateString, endDate) => {
    console.log("dateString", dateString);
    const formattedDate = moment(dateString).format("DD-MM-YYYY");
    setTargetDate(formattedDate);
  };

  const validateTargetDate = async (_, targetDate) => {
    const responseDate = form.getFieldValue("response_date");

    if (moment(targetDate).isSameOrBefore(responseDate)) {
      throw new Error("Target Date must be greater than Response Date");
    }
  };

  // const handleTargetDate = (endDate) => {
  //   const startDate = form.getFieldValue('response_date');
  //   if (endDate && startDate && endDate.isSameOrBefore(startDate, 'day')) {
  //     message.error('Target date must be greater than as Response date');
  //     form.setFieldsValue({ target_date: null });
  //   }
  // }

  // const handleResponseDateChange = (startDate) => {
  //   const endDate = form.getFieldValue('target_date');
  //   if (startDate && endDate && startDate.isSameOrAfter(endDate, 'day')) {
  //     message.error('Response date must be earlier than or the same as Target date');
  //     form.setFieldsValue({ response_date: null });
  //   }
  // }
  const [formData, setFormData] = useState({
    project_id: "",
    project_detail_id: "",
    problem_area: "",
    correction_action: "",
    response: "",
    response_date: "",
    target_date: "",
    remark: "",
    // image: [],
  });

  const [imageFile, setImageFile] = useState([]);

  const resetAllValue = () => {
    setFormData({
      project_id: "",
      project_detail_id: "",
      problem_area: "",
      correction_action: "",
      response: "",
      response_date: "",
      target_date: "",
      remark: "",
      // image: [],
    });
  };

  const submithandler = async (e) => {
    e.preventDefault();
    console.log("Submit handler called create esh");
    const projectName = allProjects.filter(
      (item) => item.project_id == formData.project_id
    );
    //console.log("{projectName}",projectName?.[0]?.project_name)
    // console.log("values form: ", values);
    // return;
    const EmailSendingData = {
      sender_name: logindetails.user.name,
      sender_email: logindetails.user.email,
      to_name: toName,
      to_email: toMail,
      project_name: projectName?.[0]?.project_name,
      project_detail: formData.project_detail_id,
      problem: formData.problem_area,
      message: formData.remark,
      issue_target_date: targetDate,
    };

    let imageBase64String = "";
    if (imageFile) {
      try {
        imageBase64String = await getBase64(imageFile);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error(
          "An error occurred while processing the image. Please try again."
        );
        return;
      }
    }

    const imageObject = {
      lastModified: imageFile.lastModified,
      lastModifiedDate: imageFile.lastModifiedDate,
      name: imageFile.name,
      originFileObj: imageFile,
      percent: 0, // Set percent as needed
      size: imageFile.size,
      thumbUrl: imageBase64String, // Set thumbUrl as needed
      type: imageFile.type,
      uid: "rc-upload-" + Date.now(), // Generate unique uid
    };
    imageObject.thumbUrl = imageObject.thumbUrl.replace(
      "data:image/jpeg;",
      "data:image/png;"
    );
    // console.log("imageobj: ", imageObject);
    // return;
    let data = {
      ...formData,
      image: [imageObject],
      ...{
        orgID: orgId,
        created_by: userId,
        status: "OPEN",
      },
    };

    try {
      console.log("Data befor sun=mit: ", data);
      // return;
      let response = await createNewEhs(data);
      // console.log("ress: ", response);
      if (response.success == true) {
        toast.success("Site Observation Created..");
        setNewSiteObservation(false);
      }
    } catch (err) {
      console.log("Error occured: ", err);
      toast.error("Sonething went wrong..");
    }

    // if (
    //   formData.activity_id === "" ||
    //   formData.project_detail_id === "" ||
    //   formData.project_id === "" ||
    //   formData.qc_engineer === "" ||
    //   formData.rfi_code === "" ||
    //   formData.rfi_date === "" ||
    //   formData.task_id === ""
    // ) {
    //   toast.error("Fill all required fields");
    //   return;
    // }

    try {
    } catch (error) {
      // Handle any errors here
      console.error("Error creating RFI:", error);
      // Optionally, you can show an error message to the user
      toast.error("An error occurred while creating RFI. Please try again.");
    }
  };

  // useEffect(() => {
  //   console.log("Form data: ", formData);
  // }, [formData]);

  function handleFileSelect(event) {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      // setFormData({ ...formData, image: file });
      setFormData({
        ...formData,
        image: file,
      });
    }
  }

  return (
    <>
      {/* <Spin spinning={Spindata.spin} size={"large"}>
        <Form
          form={form}
          name="add-ehs"
          size="large"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {logindetails.user.role === "ADMIN" ? (
            <Form.Item
              name="project_id"
              label="Select Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Name !",
                },
              ]}
            >
              <Select
                className="input-field"
                onKeyDown={(e) => onKeydown(e)}
                // onChange={(projectId) => setSelectedProjectId(projectId)}
                onChange={(projectId) => handelProjectId(projectId)}
              >
                {allProjects.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              name="project_id"
              label="Select Project"
              className="input-field"
              rules={[
                {
                  required: true,
                  message: "Please Select Project Name !",
                },
              ]}
            >
              <Select
                className="input-field"
                onKeyDown={(e) => onKeydown(e)}
                // onChange={(projectId) => setSelectedProjectId(projectId)}
                onChange={(projectId) => handelProjectId(projectId)}
              >
                {projectData.map((getAllProj) => (
                  <Option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="project_detail_id"
            label="Project Location"
            rules={[
              {
                required: true,
                message: "Please Select Project Location !",
              },
            ]}
            className="input-field"
          >
            <Select className="input-field" onKeyDown={(e) => onKeydown(e)}>
              {allProjDetails
                .filter(
                  (getAllProjDetails) =>
                    getAllProjDetails.project_id === selectedProjectId
                )
                .map((getAllProjDetails) => (
                  <Option
                    key={getAllProjDetails.project_details_id}
                    value={getAllProjDetails.block}
                  >
                    {getAllProjDetails.block}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="problem_area"
            label="Problem Area"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please enter Problem Area !",
              },
              {
                pattern: /^(.{0,100})$/,
                message: "please enter less than 100 characters only",
              },
            ]}
          >
            <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>
          <Form.Item
            name="correction_action"
            label="Correction Action"
            className="input-field"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter Currection Action!",
              // },
              {
                pattern: /^(.{0,100})$/,
                message: "please enter less than 100 characters only",
              },
            ]}
          >
            <Input placeholder="" onKeyDown={(e) => onKeydown(e)} />
          </Form.Item>
          <Form.Item
            name="response"
            label="Response User"
            className="input-field"
            rules={[
              {
                required: true,
                message: "Please select Response User!",
              },
            ]}
          >
            <Select onChange={(userId) => handelUserData(userId)}>
              {getUserProjectWise.map((item) => (
                <Option key={item.user_id} value={item.user_id}>
                  {item.name}-{item.email}
                </Option>
              ))}
            </Select>

           
          </Form.Item>
          <Form.Item
            name="response_date"
            label="Response Date"
            rules={[
              {
                required: true,
                message: "Please select Response Date!",
              },
            ]}
            className="input-field"
          >
            <DatePicker
              format="DD-MM-YYYY"
              // onChange={handleResponseDateChange}
            />
          </Form.Item>
          <Form.Item
            name="target_date"
            label="Target Date"
            rules={[
              {
                required: true,
                message: "Please select Target Date !",
              },
              {
                validator: validateTargetDate, // Use the custom validation function
              },
            ]}
            className="input-field"
          >
            <DatePicker format="DD-MM-YYYY" onChange={handelTargetDate} />
          </Form.Item>
          <Form.Item
            name="image"
            label="Observe Photo"
            rules={[
              { required: true, message: "Please upload Observe Image!" },
            ]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className="input-field"
          >
            
            <Upload
              name="logo"
              listType="picture"
              accept=".jpg,.jpeg,.png"
              beforeUpload={(file, fileList) => {
                return false;
              }}
              onPreview={handlePreview}
              maxCount={"1"}
            >
              <Button icon={<UploadOutlined />}>Choosen File</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="remark" label="Description" className="input-field">
            <Input.TextArea
              style={{ maxHeight: "70px", minHeight: "70px" }}
              placeholder=""
              onKeyDown={(e) => onKeydown(e)}
            />
          </Form.Item>

          <Form.Item>
            <div className="btn-ehs">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-submit"
                style={{ borderRadius: "10px" }}
              >
                <strong>Submit</strong>
              </Button>

              <Button onClick={clear_fields} className="btn-cancel">
                <strong>Reset</strong>
              </Button>
            </div>
          </Form.Item>
        </Form>
        <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Spin> */}

      {/* ntw form shivam kashyap 1 may 2024 */}
      <div className="mt-2 flex flex-col rounded-md bg-white justify-center border border-richblack-900 py-10 ">
        <div className="font-montserrat  ml-28 mb-6    font-semibold  text-2xl">
          Create New Site Observation
        </div>

        {/* .... */}

        <form className="font-montserrat" onSubmit={submithandler}>
          {/* 1a */}
          <div className="flex justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="project_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Select Project
              </label>
              <select
                required
                name="project_id"
                // value={formData.project_id}
                // onChange={handleInputChange}
                // onChange={(projectId) => handelProjectId(projectId)}
                onChange={(projectId) => {
                  handelProjectId(projectId.target.value),
                    setFormData({
                      ...formData,
                      project_id: projectId.target.value,
                    });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>
                {allProjects.map((getAllProj) => (
                  <option
                    key={getAllProj.project_id}
                    value={getAllProj.project_id}
                  >
                    {getAllProj.project_name}
                  </option>
                ))}
              </select>
            </div>
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="project_detail_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Project Location
              </label>
              <select
                required
                name="project_detail_id"
                // value={formData.project_id}
                // onChange={handleInputChange}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    project_detail_id: e.target.value,
                  });
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>
                {allProjDetails
                  .filter(
                    (getAllProjDetails) =>
                      getAllProjDetails.project_id == formData.project_id
                  )
                  .map((getAllProjDetails) => (
                    <option
                      key={getAllProjDetails.project_details_id}
                      value={getAllProjDetails.block}
                    >
                      {getAllProjDetails.block}
                    </option>
                  ))}
              </select>
            </div>
            {/* 3  */}
            <div className="">
              <label
                htmlFor="problem_area"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Problem Area
              </label>
              <div className="mt- w-[300px]">
                <input
                  required
                  type="text"
                  name="problem_area"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      problem_area: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
          </div>
          {/* ---------------------------------- */}
          {/* 2a */}
          <div className="flex my-3 justify-evenly w-[90vw]">
            {/* 1 */}
            <div className="">
              <label
                htmlFor="correction_action"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Correction Action
              </label>
              <div className="mt- w-[300px]">
                <input
                  // required
                  type="text"
                  name="correction_action"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      correction_action: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 2 */}
            <div className="mt w-[300px]">
              <label
                htmlFor="task_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Response User
              </label>
              <select
                required
                name="task_id"
                // value={formData.task_id}

                onChange={(e) => {
                  handelUserData(e.target.value);
                  setFormData({
                    ...formData,
                    response: e.target.value,
                  });

                  // HandelSelectTask(e.target.value);
                }}
                className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm appearance-none"
              >
                <option value="">Select</option>
                {getUserProjectWise.map((item) => (
                  <option key={item.user_id} value={item.user_id}>
                    {item.name}-{item.email}
                  </option>
                ))}
              </select>
            </div>
            {/* 3 date */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="response_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Response Date
              </label>
              <input
                required
                type="date"
                name="response_date"
                // value={formData.completion_date}
                // onChange={handleInputChange}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    response_date: e.target.value,
                  })
                }
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
          </div>
          {/* ---------------------------- */}
          {/* 3a */}
          <div className="flex my-3 justify-evenly w-[90vw]">
            {/* 1 */}
            <div class="relative  w-[300px] h-[35px]">
              <label
                for="target_date"
                class="block text-sm mb- font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Target Date
              </label>
              <input
                required
                type="date"
                name="target_date"
                // value={formData.completion_date}
                // onChange={handleInputChange}
                onChange={(e) => {
                  setTargetDate(e.target.value),
                    setFormData({
                      ...formData,
                      target_date: e.target.value,
                    });
                }}
                class="border-pure-greys-300 block min-h-[auto] w-full rounded bg-transparent px-3 py-[0.32rem] focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                placeholder="Select a date"
              />
            </div>
            {/* 2 */}
            <div className="">
              <label
                htmlFor="remark"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {/* <span className="text-red-600">*</span> */}
                Description
              </label>
              <div className="mt- w-[300px]">
                <input
                  // required
                  type="text"
                  name="remark"
                  value={formData.remark}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      remark: e.target.value,
                    });
                  }}
                  className="block w-full rounded-md border-pure-greys-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>{" "}
            {/* 3  */}
            <div className="w-[300px] ">
              <label
                htmlFor="image"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <span className="text-red-600">*</span>
                Observe Photo
              </label>
              <input
                required
                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="file_input_help"
                id="image"
                type="file"
                accept="image/svg+xml, image/png, image/jpeg, image/gif"
                
                onChange={(event) => {
                  const fileInput = event.target;
                  const file = fileInput.files[0];

                  if (file) {
                    setImageFile(file);
                    console.log("filse set: ", imageFile);
                  }
                }}
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                SVG, PNG, JPG or GIF .
              </p>
            </div>
          </div>

          {/* buttons  */}
          <div className=" flex gap-x-2 mr-5  mt-4 justify-end">
            <button
              onClick={resetAllValue}
              type="button"
              class="inline-block rounded border-2 border-top_nav_blue-500 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-top_nav_blue-500 transition duration-150 ease-in-out hover:border-top_nav_blue-500 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-top_nav_blue-500 focus:border-top_nav_blue-500 focus:text-top_nav_blue-500 focus:outline-none focus:ring-0 active:border-top_nav_blue-500 active:text-top_nav_blue-500 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"

              data-te-ripple-init
            >
              Reset
            </button>
            <button
              type="submit"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="inline-block rounded bg-blue-900 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-900-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-900-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            >
              Submit
            </button>
          </div>
        </form>
        {/* .......... */}
      </div>
    </>
  );
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default EhsCreateForm;
